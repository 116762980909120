import { bodyPart as allBodyPoints } from "../allBodyPoints";
import VasQuestion from "../Modals/VasQuestion";
import EnabledBodyLocationAndAnsweredVasQuestion from "../Modals/EnabledBodyLocationAndAnsweredVasQuestion";
import CONSTANT from "../../../../constants/Constant";

export const getFirstUnAnsweredQuestion = (selectedBodyPointsQuestionId, allQuestions, allAnswers) => {
    let idOfFirstUnAnsweredQuestion = null;
    let firstUnAnsweredQuestion = {};

    for(let i = 0; i < selectedBodyPointsQuestionId.length; i++) {
        let questionId = selectedBodyPointsQuestionId[i];
        if(!allAnswers[questionId]) {
            idOfFirstUnAnsweredQuestion = questionId;
            break;
        }
    }

    allQuestions.map((groupQuestion, index) => {
        groupQuestion.questions.map((question, index) => {
            if(question.questionId === idOfFirstUnAnsweredQuestion) {
                firstUnAnsweredQuestion = question;
            }
        })
    })

    return firstUnAnsweredQuestion;
}

export const isAnsweringLastCCQuestion = (selectedBodyPointsQuestionId, questionId) => {
    let isAnsweringLastQuestion = false;
    let totalQuestions = selectedBodyPointsQuestionId.length;

    if(questionId === selectedBodyPointsQuestionId[totalQuestions - 1] && totalQuestions > 0) {
        isAnsweringLastQuestion = true;
    }

    return isAnsweringLastQuestion;
}

export const extractEnabledBodyLocationsFromLocallyStoredData = (bodyPoints) => {
    // const othersPonints = ["lower_back_middle" , "mid_back_2_middle" , "mid_back_3_middle" , "neck_middle" , "lower_neck_left" , "lower_neck_middle" , "lower_neck_right", "head_back"]
    const enabledPoints = []
    bodyPoints.forEach(point => {

        allBodyPoints.forEach(el => {
            if (point?.BodyLocation === el.bodyLocation) {
                if(point?.Name) {
                    el.subBodyRegion = point?.Name;
                }
                enabledPoints.push({...el})
            }
        })
    })
    // othersPonints.forEach(point => {
    //     allBodyPoints.forEach(el => {
    //         if (point === el.bodyLocation) {
    //             enabledPoints.push({...el})
    //         }
    //     })
    // })

    return enabledPoints
}

export const mapVasQuestionToLocalModal = (vasQuestions) => {
    let tmpQuestion = {}
    vasQuestions.forEach(vaQuestion => {
        let pointFromAllBodyPoint = allBodyPoints.find(point => point.bodyLocation === vaQuestion.BodyLocation);
        if(vaQuestion?.BodyLocation && vaQuestion?.PainValue) {
            tmpQuestion[vaQuestion.BodyLocation] = new VasQuestion({ ...vaQuestion, bodyLocation: vaQuestion?.BodyLocation, name: vaQuestion.Name, side: pointFromAllBodyPoint?.side })
        }
    })

    return tmpQuestion
}

export const mapDataToLocalModal = (data) => {
    const mappedData = []
    data.forEach(el => {
        mappedData.push(new EnabledBodyLocationAndAnsweredVasQuestion(el))
    })
    return mappedData
}

export const createErrorForBodyQuestions = (ids, allAnswers) => {
    let errors = {}
    ids.forEach(id => {
        if(!allAnswers[id]) {
            errors[id] = '*This field is required.'
        }
    })

    return errors;
}

export const prepareAnswer = (question, answer, bodyLocation) => {
    let newAnswer = {
        QuestionId: question.questionId,
        AnswerId: answer?.id || '',
        AnswerValue: answer?.name || '',
        AnswerTitle: answer?.name || '',
        GroupId: CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key,
        VasQuestion: question?.isVasQuestion || false,
        BodyLocation: bodyLocation,
        Color: answer.color || '',
    }

    return newAnswer
}

export const preparePayload = (testId, answers) => {
    const payload = {
        TestId: testId,
        Answers: [],
    }

    for(const key in answers) {
        payload.Answers.push(answers[key])
    }

    return payload;
}
