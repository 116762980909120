export default class Appointment {
    constructor(options) {
        options = options || {};

        this.start_date = options.start_date || new Date().toISOString();
        this.end_date = options.end_date || new Date().toISOString();
        this.provider_id = options.provider_id || '';
        this.owner = options.owner || '';
        this.patient_id = options.patient_id || '';
        this.current_user = options.current_user || '';
        this.is_provider = options.is_provider || false;
        this.is_blocked = options.is_blocked || false;
        this.createdby = options.createdby || '';
        this.event_type = options.event_type || 0;
        this.text = options.text || '';
    }
}
