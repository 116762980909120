import React, {useEffect} from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CONSTANT from "../../../constants/Constant";

const RetakeNotificationModal = ({isShow, retakeMovements, toggleRetakeNotificationModal, onClickRetakeExercise, setShouldOPenRetakeListModal, isRetakeModal, message, isAudioPlayEnabled, playGeneralAudio}) => {

    useEffect(() => {
        if(isAudioPlayEnabled && isShow && retakeMovements.length > 0) {
            playGeneralAudio && !isRetakeModal && playGeneralAudio(playGeneralAudio(CONSTANT.GENERAL_AUDIOS_KEY_TEXT.PLEASE_WAIT_UNTIL_MOVEMENT_COMPLETE));
            playGeneralAudio && isRetakeModal && playGeneralAudio(playGeneralAudio(CONSTANT.GENERAL_AUDIOS_KEY_TEXT.PLEASE_RETAKE_BIOMETRICS));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow, isRetakeModal]);

    const onClickRetakeBtn = (exercise) => {
        toggleRetakeNotificationModal(false);
        onClickRetakeExercise(exercise, true);
    }
    const closeRetakeMovementModal = () => {
        //setShouldOPenRetakeListModal(false);
        toggleRetakeNotificationModal(false);
    }
    return (
        <Modal isOpen={isShow} className="modal-md retake-notification-modal" centered>
            <ModalHeader toggle={closeRetakeMovementModal}>
                <strong>Movement Statistics</strong>{message && message !== '' && (<>: {message}</>)}
            </ModalHeader>
            <ModalBody>
                {retakeMovements && retakeMovements.length > 0 && (
                    <>
                        <div>
                            <div className="retake-notification__item"><span className="font-size-20">{isRetakeModal ? "Please retake the following biometrics" : "Please wait until the following movements are completed"}</span></div>
                            <div>
                                {retakeMovements.map((exercise, index) => {
                                    return (
                                        <div className="retake-notification__item my-3" key={`retake${index}`}>
                                            <div className="retake-notification__container">
                                                <p>{exercise?.ExerciseDisplayName}</p>
                                                {isRetakeModal ? 
                                                <button
                                                    onClick={() => onClickRetakeBtn(exercise)}
                                                    className="btn btn-primary">
                                                    Retake
                                                </button> 
                                                : <span className="badge badge-warning font-medium font-size-sm color-black">{exercise.Status}</span>}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <span>
                    *** You can save the recorded data without performing the retake movements. 
                    Your report might not be corrected.We are recommending to complete all the movements before saving ***
                </span>
            </ModalFooter>
        </Modal>
    );
}

export default RetakeNotificationModal;