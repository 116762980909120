import { createSlice } from "@reduxjs/toolkit";
import { initialDashboardState } from "./dashboardConstant";

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: initialDashboardState,
    reducers: {
        setLastIncompletedAssessments: (state, action) => {
            state.lastIncompletedAssessments = action.payload;
        },
        setSelectedTelemedicineInvitationId: (state, action) => {
            state.selectedTelemedicineInvitationId = action.payload;
        },
        setShowLoadingAtJoinNowBtn: (state, action) => {
            state.showLoadingAtJoinNowBtn = action.payload;
        },
        setOpenBiometricMovementAndCautionQuestionContainer: (state, action) => {
            let openBiometricMovementAndCautionQuestionContainer = action.payload?.openBiometricMovementAndCautionQuestionContainer || false;
            let testId = action.payload?.testId || '';
            let selectedBodyRegions = action.payload?.selectedBodyRegions || [];

            state.biometric.openBiometricMovementAndCautionQuestionContainer = openBiometricMovementAndCautionQuestionContainer;
            state.biometric.testId = testId;
            state.biometric.selectedBodyRegions = selectedBodyRegions;
        },
        setIsShowIsFollowUpPopup: (state, action) => {
            state.isShowIsFollowUpPopup = action.payload;
        },
        setShowDetailInstruction: (state, action) => {
            state.showDetailInstruction = action.payload;
        },
        setShowLoadingInConformTelemedicine: (state, action) => {
            state.showLoadingInConformTelemedicine = action.payload;
        },
        setInvitationListDoNotHaveTestIds: (state, action) => {
            state.invitationListDoNotHaveTestIds = action.payload;
        },
        setFollowUpAssessments: (state, action) => {
            state.followUpAssessments = action.payload;
        }
    },
});

export const {
    setLastIncompletedAssessments,
    setSelectedTelemedicineInvitationId,
    setShowLoadingAtJoinNowBtn,
    setOpenBiometricMovementAndCautionQuestionContainer,
    setIsShowIsFollowUpPopup,
    setShowDetailInstruction,
    setShowLoadingInConformTelemedicine,
    setInvitationListDoNotHaveTestIds,
    setFollowUpAssessments,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
