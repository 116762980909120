import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import CONSTANT from "../../constants/Constant";
import MobileHeader from "../shared/layout/MobileHeader";
import Header from "../shared/layout/Header";
import Footer from "../shared/layout/Footer";
import MyExercisesDetails from "./MyExercisesDetails";
import BodyWithLeftMenu from "../shared/layout/BodyWithLeftMenu";
import AppContext from "../../contexts/AppContext";
import MyExcercisesList from "./MyExcercisesList";
import BodyWithTopMenu from "../shared/layout/BodyWithTopMenu";

const MyExercises = () => {
    const { userInfo } = useContext(AppContext);
    const { EnableFysicalScore, IsPostureAnalyzeEnabled } = userInfo.Permissions;
    let { testId } = useParams();

    return (
        <>
            {/*<MobileHeader/>*/}
            <div className="kt-grid kt-grid--hor kt-grid--root">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                        <Header/>
                        {!EnableFysicalScore && IsPostureAnalyzeEnabled && (
                            <MyExcercisesList />
                        )}
                        {EnableFysicalScore && (
                            <>
                                <BodyWithTopMenu pageName={CONSTANT.PAGE_NAME.MY_EXERCISES}>
                                    <MyExcercisesList />
                                </BodyWithTopMenu>
                                <Footer/>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
};

export default MyExercises;
