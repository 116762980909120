import commonExerciseIcon from "../assets/img/exercises/exerciseIcon-base64/common-exercise"

export default class MyExercise {
    constructor(options) {
        options = options || {};

        this.ExerciseName = options.Exercise.trim() || '';
        this.ExerciseImageUrl = options.ImageUrl[0] || commonExerciseIcon;
        this.AssignReps = parseInt(options.AssignReps) || '0';
        this.CompletedReps = parseInt(options.CompletedReps) || '0';
        this.RepsId = options.RepsId || '0';
        this.AssignSets = parseInt(options.AssignSets) || '0';
        this.CompletedSets = parseInt(options.CompletedSets) || '0';
        this.SetsId = options.SetId || '0';
        this.Status = options.Status || '';
        this.StartDate = options.StartDate || '';
        this.ExerciseId = options.Id || '';
        this.ProtocolId = options.ProtocolId || '';
        this.TestId = options.TestId || '';
        this.ExerciseTrackingDetails = options.ExerciseTrackingDetails || '';
        this.FrequencyName = options.FrequencyName;
        this.ResistanceName = options.ResistanceName;
        this.Tutorial = options.Tutorial;
        this.ExercisePlan = options.ExercisePlan;
        this.LPTVideoUrl = options?.LPTVideoUrl;
        this.HoldInSeconds = options?.HoldInSeconds;
        this.TotalNoOfWrongCount = options?.TotalNoOfWrongCount;
    }
}