import React, { useState } from "react";

import DashboardHeader from "../shared/layout/Header";
import DisplayUrlContent from "../shared/DisplayUrlContent";
import deleteAccountPng from "../../assets/img/deleteAccount/delete_account.png";
import deleteAccountPng2 from "../../assets/img/deleteAccount/delete_account2.png";

function DeleteAccount() {
    const [privacyPolicyModal, setPrivacyPolicyModal] = useState(false);

    const onClickPrivacyPolicyToggle = (e) => {
        e.preventDefault();
        setPrivacyPolicyModal(!privacyPolicyModal);
    };

    return (
        <>
            <div className="kt-grid kt-grid--hor kt-grid--root">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                        <DashboardHeader />

                        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor m-5"
                             id="kt_content">
                            <h1 className="text-center  kt-font-primary font-size-xl mb-3 text-underline">Account Deletion Request for Emma Health</h1>


                            <div className="mb-2">
                                <p className="kt-font-primary font-weight-bold font-size-md">To delete your account on Emma Health by My Medical Hub, please follow these steps:</p>
                                <ol>
                                    <li className="kt-font-primary font-medium font-size-md">
                                        <span className="font-weight-bold">Go to App Settings:  </span>
                                        Open
                                        <span className="font-weight-bold">  Emma Health  </span>
                                        and navigate to
                                        <span className="font-weight-bold">  Settings  </span> >
                                        <span className="font-weight-bold">  Delete Account.  </span>
                                    </li>
                                    <li className="kt-font-primary font-medium font-size-md">
                                        <span className="font-weight-bold">Enter Your Password:  </span>
                                        Enter your password and select
                                        <span className="font-weight-bold">  Yes  </span>
                                        button.
                                    </li>
                                    <li className="kt-font-primary font-medium font-size-md">
                                        <span className="font-weight-bold">Confirm Your Request:  </span>
                                        Follow the prompts to confirm your account deletion request.
                                    </li>
                                    <li className="kt-font-primary font-medium font-size-md">
                                        <span className="font-weight-bold">Contact Support:  </span>
                                        Alternatively, you may email support@mymedicalhub.com with the subject line “Account Deletion Request.”</li>
                                </ol>
                            </div>


                            <div className="mb-2">
                                <p className="kt-font-primary font-weight-bold font-size-md">Data Deletion and Retention Policy:</p>
                                <ul>
                                    <li className="kt-font-primary font-medium font-size-md">Upon account deletion, personal data such as your name, email, and usage history will be permanently erased within 30 days.</li>
                                    <li className="kt-font-primary font-medium font-size-md">Certain non-personal data may be retained for up to 90 days to comply with legal and analytical requirements, as described in our Privacy Policy.</li>
                                </ul>
                            </div>

                            <p className="kt-font-primary font-medium font-size-md">For more information, please refer to our
                                <span
                                    className="cursor-pointer text-decoration-underline-hover text-underline font-weight-bold"
                                    onClick={onClickPrivacyPolicyToggle}>
                                         <span>  Privacy Policy  </span>
                                    </span>
                                link here.
                            </p>

                            <div className="image-container d-flex justify-content-evenly">
                                <img className="object-fit-cover max-w-10vw" src={deleteAccountPng} alt="Account Deletion" />
                                <img className="object-fit-cover max-w-10vw" src={deleteAccountPng2} alt="Account Deletion" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {privacyPolicyModal && (
                <DisplayUrlContent
                    headerTitle={"Privacy Policy"}
                    open={privacyPolicyModal}
                    url="https://vatb.injurycloud.com/account/privacypolicy"
                    onToggleModal={onClickPrivacyPolicyToggle}>
                </DisplayUrlContent>
            )}
        </>
    );
}

export default DeleteAccount;