import React from "react";
import IconCloseButton from "../shared/IconCloseButton";
import {useSelector} from "react-redux";

import {getBodyRegionNames} from "../../utils/Utils";
import CONSTANT from "../../constants/Constant";
import {toast} from "react-toastify";
import {getNoCCQuestion, isCCQuestionAnswered} from "./EditAssessmentUtils";
import routes from "../../constants/Routes";
import {useHistory} from "react-router-dom";
import useEditAssessmentHeaderAndFooter from "./hooks/useEditAssessmentHeaderAndFooter";

const EditAssessmentHeader = () => {
    const { handleOpenMovementModal, showCCOrPainQuestionPopUp } = useEditAssessmentHeaderAndFooter();

    const history = useHistory();
    const editAssessmentState = useSelector(state => state.editAssessment);

    const baseData = editAssessmentState?.baseData || {};
    const testId = baseData?.testId || '';
    const bodyRegionList = baseData?.bodyRegionList || [];
    const invitationType = baseData?.invitationTypeName || '';
    const isReportAvailable = baseData?.isReportAvailable || false;
    const currentGroupId = baseData?.currentGroupId || CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS;
    const ccQuestion = editAssessmentState?.ccQuestion || {};

    const handleOnClickCrossBtn = () => {
        const responses = ccQuestion?.responses;
        if(!responses) {
            toast.error("CC Question response not found!");
            return;
        }

        const noCCQuestion = getNoCCQuestion(responses);
        if(!noCCQuestion) {
            toast.error('No CC question not found');
            return;
        }

        if(!isCCQuestionAnswered(ccQuestion) && currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) {
            showCCOrPainQuestionPopUp();
        }
        else {
            history.push(`${routes.myAssessments}/${false}`)
        }
    }

    return (
        <>
            <div className="edit-assessment-header-container">
                <div className="edit-assessment-header-info-container">
                    {testId && testId !== "" && <p><span className='edit-assessment-header-test-id'>Test ID: </span>{testId}</p>}
                    <div className='edit-assessment-header-region-invitation-container'>
                        {bodyRegionList.length > 0 && <p>Region: {getBodyRegionNames(bodyRegionList)}</p>}
                        {(invitationType && invitationType !== "") && <p>Invitation type: {invitationType}</p>}
                    </div>
                </div>
                <div className="d-flex">

                    {(invitationType && invitationType === "Self") &&
                        <button
                            className="resume-bio-btn-padding movement-save-close bg-color-green"
                            onClick={handleOpenMovementModal}
                            disabled={isReportAvailable}
                        >
                            Resume Biometric
                        </button>
                    }
                    <div onClick={handleOnClickCrossBtn}>
                        <IconCloseButton />
                    </div>
                </div>
            </div>
            <div className='edit-assessment-header-less-1024-px'>
                {bodyRegionList.length > 0 && <p>Region: {getBodyRegionNames(bodyRegionList)}</p>}
                {(invitationType && invitationType !== "") && <p>Type: {invitationType}</p>}
            </div>
        </>
    )
}

export default EditAssessmentHeader;