import React, { useState } from "react";
import { isMobile, isTablet } from "react-device-detect";

const UpgradePlan = () => {

    const [selectedPlan, setSelectedPlan] = useState('startup');
    const [selectedPackage, setSelectedPackage] = useState('monthly')

    const onChangePlan = (value) => () => {
        console.log(value)
        setSelectedPlan(value)
    }

    const getBgColor = (value) => {
        if (value === selectedPlan) {
            return 'bg-secondary rounded';
        } else {
            return '';
        }
    }

    const getBotWidthStyle = () => {
        if (isMobile || isTablet) {
            return 'w-100';
        } else {
            return 'w-49';
        }
    };

    const onClickPackage = (value) => () => {
        setSelectedPackage(value)
    };

    const getPackageButtonColor = (value) => {
        if (value === selectedPackage) {
            return 'btn-success'
        } else {
            return ''
        }
    }

    return (
        <div className="text-center">
            <div className="kt-mb-25">
                <h4>Upgrade a Plan</h4>
                <span>If you need more info, please check Pricing Guidelines.</span>
            </div>
            <div className="d-flex justify-content-center kt-mb-25">
                <label className="kt-option kt-padding-10">
                    <button type="button" className={`btn ${getPackageButtonColor('monthly')}`}
                            onClick={onClickPackage('monthly')}>Monthly
                    </button>
                    <button type="button" className={`btn ${getPackageButtonColor('annual')}`}
                            onClick={onClickPackage('annual')}>Annual
                    </button>
                </label>
            </div>
            <div className="d-flex flex-wrap">
                <div className={`${getBotWidthStyle()} kt-mr-10`}>
                    <div className="kt-widget5 d-flex w-100 flex-wrap">
                        <div
                            className={`kt-widget5__item w-100 flex-row kt-padding-20 align-items-start ${getBgColor('startup')}`}>
                            <label className="kt-radio kt-radio--bold kt-radio--success">
                                <input type="radio" name="m_option_1" value="1"
                                       checked={'startup' === selectedPlan}
                                       onChange={onChangePlan('startup')}/>
                                <span/>
                            </label>
                            <div className="d-flex text-left w-100 justify-content-between">
                                <div>
                                    <h5>Startup</h5>
                                    <span>Best for startups</span>
                                </div>
                                <div>
                                <span>
                                    <span className="kt-font-xl kt-font-bold">$399</span>
                                    <span className="kt-font-sm">/Mon</span>
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`kt-widget5 d-flex flex-wrap ${getBotWidthStyle()} kt-mr-10`}>
                    <div
                        className={`kt-widget5__item w-100 flex-column kt-padding-20 align-items-start ${getBgColor('startup')}`}>
                        <h4>What’s in Startup Plan?</h4>
                        <span>Optimal for 10+ team size and new startup</span>
                        <div className="d-flex flex-column text-left w-100 kt-mt-20">
                            <span className="d-flex w-100 justify-content-between kt-mb-5">
                                <h6>Up to 10 Active Users</h6>
                                <i className="fa fa-check-circle kt-font-success kt-font-xl"/>
                            </span>
                            <span className="d-flex w-100 justify-content-between">
                                <h6>Up to 10 Active Users</h6>
                                <i className="fa fa-times kt-font-danger kt-font-xl"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center kt-mb-25">
                <button type="button" className="btn btn-danger kt-mr-10"
                        onClick={onClickPackage('monthly')}>Cancel
                </button>
                <button type="button" className="btn btn-success"
                        onClick={onClickPackage('annual')}>Upgrade Plan
                </button>
            </div>
        </div>
    )
};

export default UpgradePlan;