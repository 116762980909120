import "./Dashboard.scss"
import {useDispatch, useSelector} from "react-redux";
import React, {forwardRef, useContext, useEffect, useRef, useState} from 'react';
import { Redirect } from "react-router-dom";
import "react-step-progress-bar/styles.css";

import routes from "../../constants/Routes";
import AppContext from "../../contexts/AppContext";
import {getAssessmentCompletion, isEmptyObject} from "../../utils/Utils";
import CONSTANT from "../../constants/Constant";
import DashboardItem from "./DashboardItem";
import {_getInCompleteAssessmentType} from "./dashboardUtills"
import NotificationInfo from "./notification/NotificationInfo";
import ConfirmationDialog from "../shared/Popups/ConfirmationDialog";
import BiometricMovementAndCautionQuestionContainer from "../shared/MovementModal/BiometricMovementAndCautionQuestionContainer";
import ResumeBtn from "./notification/ResumeBtn";
import MyAssessmentsDetail from "../my-assessments/MyAssessmentsDetail";
import SweetAlert from "react-bootstrap-sweetalert";
import StepBar from "../shared/stepbar/StepBar";
import useDashboardBody from "./useDashboardBody";
import {
    setFollowUpAssessments, setInvitationListDoNotHaveTestIds, setIsShowIsFollowUpPopup, setLastIncompletedAssessments,
    setOpenBiometricMovementAndCautionQuestionContainer, setShowDetailInstruction
} from "../../features/dashboard/dashboardSlice";
import useHandleExpiredAndUpcomingMeetingPopupMsg from "../../hooks/useHandleExpiredAndUpcomingMeetingPopupMsg";

const DashboardBody = forwardRef((props, ref) => {
    const { allNotifications, isLoadingNotification } = props;
    const { authenticated } = useContext(AppContext);
    const dispatch = useDispatch();

    const { handleNewUser, handleRemoteAssistance, notificationHandler, handleClickMore } = useDashboardBody();
    const { getAssessmentAvailabilityStatus } = useHandleExpiredAndUpcomingMeetingPopupMsg();

    const dashboardRootState = useSelector(state => state.dashboard);
    const lastIncompletedAssessments = dashboardRootState?.lastIncompletedAssessments || [];
    const showLoadingAtJoinNowBtn = dashboardRootState?.showLoadingAtJoinNowBtn || false;
    const isShowIsFollowUpPopup = dashboardRootState?.isShowIsFollowUpPopup || false;
    const showDetailInstruction = dashboardRootState?.showDetailInstruction || false;
    const showLoadingInConformTelemedicine = dashboardRootState?.showLoadingInConformTelemedicine || false;
    const followUpAssessments = dashboardRootState?.followUpAssessments || [];
    const invitationListDoNotHaveTestIds = dashboardRootState?.invitationListDoNotHaveTestIds || [];
    const selectedTelemedicineInvitationId = dashboardRootState?.selectedTelemedicineInvitationId || '';

    const biometric = dashboardRootState?.biometric || {};
    const openBiometricMovementAndCautionQuestionContainer = biometric?.openBiometricMovementAndCautionQuestionContainer || false;
    const selectedBodyRegionsAndTestId = {
        testId: biometric?.testId || '',
        selectedBodyRegions: biometric?.selectedBodyRegions || []
    }

    const conformationAlertContentRef = useRef({});


    useEffect(() => {
        let tempLastIncompletedAssessments = [];
        let followUpAssessments = [];
        let tempInvitationListDoNotHaveTestId = [];
        if(allNotifications && allNotifications.length > 0) {
            tempLastIncompletedAssessments = allNotifications.filter(notification => {
                let assessmentAvailabilityStatus = getAssessmentAvailabilityStatus(notification?.MeetingStart, notification?.MeetingEnd);
                return notification.IsLastIncomplete
                    &&
                    !notification.IsFollowup &&
                    (
                        notification.InvitationTypeId !== CONSTANT.INVITATION_TYPE.Telemedicine ||
                        (notification.InvitationTypeId === CONSTANT.INVITATION_TYPE.Telemedicine && assessmentAvailabilityStatus !== CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.KEY)
                    ) &&
                    (
                        notification.InvitationTypeId !== CONSTANT.INVITATION_TYPE.InClinic ||
                        (notification.InvitationTypeId === CONSTANT.INVITATION_TYPE.InClinic && assessmentAvailabilityStatus !== CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.KEY)
                    )
            });

            tempInvitationListDoNotHaveTestId = allNotifications.filter(notification => !notification.TestId);
            followUpAssessments = allNotifications.filter(notification => {
                let assessmentAvailabilityStatus = getAssessmentAvailabilityStatus(notification?.MeetingStart, notification?.MeetingEnd);
                return notification.IsFollowup &&
                    (
                        notification.InvitationTypeId !== CONSTANT.INVITATION_TYPE.Telemedicine ||
                        (notification.InvitationTypeId === CONSTANT.INVITATION_TYPE.Telemedicine && assessmentAvailabilityStatus !== CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.KEY)
                    ) &&
                    (
                        notification.InvitationTypeId !== CONSTANT.INVITATION_TYPE.InClinic ||
                        (notification.InvitationTypeId === CONSTANT.INVITATION_TYPE.InClinic && assessmentAvailabilityStatus !== CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.KEY)
                    )
            });

            dispatch(setLastIncompletedAssessments(tempLastIncompletedAssessments));
            dispatch(setInvitationListDoNotHaveTestIds(tempInvitationListDoNotHaveTestId));
            dispatch(setFollowUpAssessments(followUpAssessments));
        }
        handleNewUser(allNotifications);
        handleRemoteAssistance();

    }, [allNotifications]);


    if (!authenticated) {
        return <Redirect to={`${routes.landing}`}/>;
    }

    const stepBar = (assessment = {}) => {
        const tempAssessment = isEmptyObject(assessment) ? lastIncompletedAssessments : assessment;

        return (
            <div className="padding-bottom-1-rem">
                <div className="margin-bottom-1-rem"></div>
                <StepBar groups={getAssessmentCompletion(tempAssessment?.AssessmentStatus)}
                         isShowName={true}
                         isShowFullName={false}
                />
            </div>
        )
    }

    const handleSetOpenBiometricMovementAndCautionQuestionContainer = () => {
        // as from BiometricMovementAndCautionQuestionContainer component we only close the biometric modal
        dispatch(setOpenBiometricMovementAndCautionQuestionContainer({
            openBiometricMovementAndCautionQuestionContainer: false,
            testId: '',
            selectedBodyRegions: []
        }));
    }


    return (
        <>
            {!isLoadingNotification && (
                <div className="col-xl-12">
                    {(invitationListDoNotHaveTestIds.length > 0) ? (
                            <DashboardItem title={`New Assessment Invitation`} largeTitle="NEW ASSESSMENT INVITATION" overflow={true} showOnlyOneImage={false}>
                                <div>
                                    {invitationListDoNotHaveTestIds.map((item, index) => (
                                        <div className="dashboard-item" key={index}>
                                            <div className="">
                                                <div className="d-flex justify-content-between">
                                                    <div className="d-flex justify-content-center align-content-center flex-wrap dashboard-item-wrapper">
                                                        <div className="">
                                                            <NotificationInfo
                                                                notificationData={{
                                                                    MeetingDate: item?.MeetingDate,
                                                                    BodyRegionList: item?.BodyRegionList,
                                                                    InvitationTypeName: item?.InvitationTypeName,
                                                                    InvitationTypeId: item?.InvitationTypeId,
                                                                }}
                                                            >
                                                                <ResumeBtn
                                                                    InvitationTypeId={item?.InvitationTypeId}
                                                                    firstIncompleteGroupName={_getInCompleteAssessmentType(item?.AssessmentStatus)}
                                                                    isLoading={!showLoadingAtJoinNowBtn && selectedTelemedicineInvitationId === item.InvitationId}
                                                                    notificationHandler={notificationHandler}
                                                                    conformationAlertContentRef={conformationAlertContentRef}
                                                                    notificationData={item}
                                                                    IsLastIncomplete={item?.IsLastIncomplete}
                                                                    MeetingStart={item?.MeetingStart}
                                                                    MeetingEnd={item?.MeetingEnd}
                                                                />
                                                            </NotificationInfo>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-item-spepbar-container" style={{height: "1rem"}}></div>
                                        </div>
                                    ))}
                                </div>
                            </DashboardItem>
                        )
                        :
                        null
                    }

                    {lastIncompletedAssessments.length > 0 ? (
                        <DashboardItem title="Last Incompleted Assessment" largeTitle="INCOMPLETE ASSESSMENT"
                                       showOnlyOneImage={false}>
                            {lastIncompletedAssessments.slice(0,2).map((item, index) => (
                                <div className="dashboard-item" key={index}>
                                    <div className="">
                                        <div
                                            className="d-flex justify-content-between align-content-center flex-wrap dashboard-item-wrapper">
                                            <div className="notification wrapper">
                                                <NotificationInfo
                                                    notificationData={{
                                                        MeetingDate: item?.MeetingDate,
                                                        TestId: item?.TestId,
                                                        BodyRegionList: item?.BodyRegionList,
                                                        InvitationTypeName: item.InvitationTypeName,
                                                        InvitationTypeId: item?.InvitationTypeId,
                                                    }}
                                                >
                                                    <ResumeBtn
                                                        InvitationTypeId={item?.InvitationTypeId}
                                                        firstIncompleteGroupName={_getInCompleteAssessmentType(item?.AssessmentStatus)}
                                                        isLoading={showLoadingAtJoinNowBtn && selectedTelemedicineInvitationId === item.InvitationId}
                                                        notificationHandler={notificationHandler}
                                                        conformationAlertContentRef={conformationAlertContentRef}
                                                        notificationData={{
                                                            ...item
                                                        }}
                                                        IsLastIncomplete={item?.IsLastIncomplete}
                                                        MeetingStart={item?.MeetingStart}
                                                        MeetingEnd={item?.MeetingEnd}
                                                    />
                                                </NotificationInfo>
                                            </div>
                                        </div>
                                    </div>
                                    {stepBar(item)}
                                    <div className="dashboard-item-spepbar-container" style={{height: "1rem"}}></div>
                                </div>
                            ))}
                            {lastIncompletedAssessments.length > 2 && (
                                <div className="w-full pb-3 d-flex justify-content-center">
                                    <button
                                        className="button button-dashboard"
                                        onClick={() => handleClickMore(CONSTANT.ASSESSMENT_TYPE.LAST_INCOMPLETE.key)}>
                                        More
                                    </button>
                                </div>
                            )}
                        </DashboardItem>
                    )
                        :
                        null
                    }

                    {followUpAssessments.length > 0 ? (
                        <DashboardItem title="Follow Up Assessment" largeTitle="FOLLOW-UP ASSESSMENT" showOnlyOneImage={false}>
                            {followUpAssessments.slice(0, 2).map((item, index) => (
                                <div className="dashboard-item" key={index}>
                                    <div className="">
                                        <div className="d-flex justify-content-between align-content-center flex-wrap dashboard-item-wrapper">
                                            <div className="notification wrapper">
                                                <NotificationInfo
                                                    notificationData={{
                                                        MeetingDate: item?.MeetingDate,
                                                        TestId: item?.TestId,
                                                        BodyRegionList: item?.BodyRegionList,
                                                        InvitationTypeName: item.InvitationTypeName,
                                                        InvitationTypeId: item?.InvitationTypeId,
                                                    }}
                                                >
                                                    <ResumeBtn
                                                        InvitationTypeId={item?.InvitationTypeId}
                                                        firstIncompleteGroupName={_getInCompleteAssessmentType(item?.AssessmentStatus)}
                                                        isLoading={showLoadingAtJoinNowBtn && selectedTelemedicineInvitationId === item.InvitationId}
                                                        notificationHandler={notificationHandler}
                                                        conformationAlertContentRef={conformationAlertContentRef}
                                                        notificationData={{
                                                            ...item
                                                        }}
                                                        IsLastIncomplete={item?.IsLastIncomplete}
                                                        MeetingStart={item?.MeetingStart}
                                                        MeetingEnd={item?.MeetingEnd}
                                                    />
                                                </NotificationInfo>
                                            </div>
                                        </div>
                                    </div>
                                    {stepBar(item)}
                                    <div className="dashboard-item-spepbar-container" style={{height: "1rem"}}></div>
                                </div>
                            ))}
                            {followUpAssessments.length > 2 && (
                                <div className="w-full pb-3 d-flex justify-content-center">
                                    <button
                                        className="button button-dashboard"
                                        onClick={() => handleClickMore(CONSTANT.ASSESSMENT_TYPE.FOLLOW_UP.key)}>
                                        More
                                    </button>
                                </div>
                            )}
                        </DashboardItem>
                    )
                        :
                        null
                    }

                    <h3 className="color-primary my-3">My Assessments</h3>
                    <MyAssessmentsDetail
                        showCompletedAssessments={false}/>

                </div>
            )}
            <ConfirmationDialog
                headerText={conformationAlertContentRef.current.headerText}
                show={isShowIsFollowUpPopup}
                cancelText={conformationAlertContentRef.current.noBtnText}
                confirmText={conformationAlertContentRef.current.yesBtnText}
                shouldConfirmBtnDisabled={conformationAlertContentRef.current?.shouldConfirmBtnDisabled || false}
                showLoadingInConform={showLoadingInConformTelemedicine}
                onConfirm={conformationAlertContentRef.current.onClickYes}
                toggleDialog={conformationAlertContentRef.current.onClickNo}
                onClickHeaderClose={() => {
                    dispatch(setIsShowIsFollowUpPopup(false));
                    conformationAlertContentRef.current = {};
                }}
                showYesBtn={conformationAlertContentRef.current?.showYesBtn !== undefined ? conformationAlertContentRef.current?.showYesBtn : true }
            >
                {conformationAlertContentRef.current.content}
            </ConfirmationDialog>

            <SweetAlert
                confirmBtnCssClass="btn btn-sm btn-success"
                showConfirm= {true}
                showCancel={false}
                show={showDetailInstruction}
                title=""
                type="info"
                onConfirm={() => dispatch(setShowDetailInstruction(false))}
                confirmBtnText="Ok"
                closeOnClickOutside={false}>
                <div className='tele_sweetalert_message'>
                    If there have been any notable changes to your weight, daily activities, or your acute or chronic chief complaints,
                    please update these entries by clicking “Update My Background”
                </div>
            </SweetAlert>

            {openBiometricMovementAndCautionQuestionContainer &&
                <BiometricMovementAndCautionQuestionContainer
                    data={{
                        testId: selectedBodyRegionsAndTestId?.testId,
                        selectedBodyRegions: {bodyRegionList : selectedBodyRegionsAndTestId?.selectedBodyRegions },
                        openBiometricMovementAndCautionQuestionContainer,
                    }}
                    setOpenBiometricMovementAndCautionQuestionContainer={handleSetOpenBiometricMovementAndCautionQuestionContainer}
                />
            }
        </>
    )
});

export default DashboardBody;
