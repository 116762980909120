import React, { useState, useEffect } from "react";

const RightSidePanel = ({Name, ExerciseMediaUrl, PatientGuidelineContent}) => {
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => { setIsLoading(false )}, 500);
    }, [ExerciseMediaUrl])


    return (
        <div className='bg-color-light-gray height-inherite'>
            <div>
                <div className="movement-modal-guideline-container"> Movement Guideline </div>
            </div>
            <div className="biometric-guideline-name-container">
                <span>{Name}</span>
            </div>
            <div className="biometric-guideline-img-container position-relative">
                <div className="h-100-percent fake-spinner d-flex justify-content-center align-items-center">
                    <i className="fa fa-spinner fa-spin"/>
                </div>
                {!isLoading ?
                    <div className={`h-100-percent img-tra`}>
                        <img className="h-100-percent max-width-100-percent" src={ExerciseMediaUrl} alt="exercise" />
                    </div> : null
                }
            </div>
            <div className="biometric-guideline-guideline-text">
                {PatientGuidelineContent}
            </div>
        </div>
    );
}

export default RightSidePanel;