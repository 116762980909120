import React from "react";
import { useDispatch, useSelector } from "react-redux";

import BiometricMovementAndCautionQuestionContainer
    from "../shared/MovementModal/BiometricMovementAndCautionQuestionContainer";
import {
    setOpenBiometricMovementAndCautionQuestionContainer,
} from "../../features/editAssessment/editAssessmentSlice";
import {Modal} from "reactstrap";
import CONSTANT from "../../constants/Constant";
import useEditAssessmentHeaderAndFooter from "./hooks/useEditAssessmentHeaderAndFooter";
import crossBtn from "../../assets/img/utils/cross.svg";
import {getMarkup} from "../../utils/Utils";

const EditAssessmentDialogContainer = () => {
    const { handleSetShowInstruction } = useEditAssessmentHeaderAndFooter();
    const dispatch = useDispatch();
    const editAssessmentState = useSelector(state => state.editAssessment);
    const baseData = editAssessmentState?.baseData;
    const groups = editAssessmentState?.groups || [];

    const openBiometricMovementAndCautionQuestionContainer = baseData?.openBiometricMovementAndCautionQuestionContainer;
    const testId = baseData?.testId || '';
    const bodyRegionList = baseData?.bodyRegionList || [];
    const showInstruction = baseData?.showInstruction;

    const currentGroupId = baseData?.currentGroupId || CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;
    const currentGroup = groups.find(group => group.groupId === currentGroupId);


    const handleOpenBiometricModal = (value) => {
        dispatch(setOpenBiometricMovementAndCautionQuestionContainer(value));
    }

    return (
        <>
            {openBiometricMovementAndCautionQuestionContainer &&
                <BiometricMovementAndCautionQuestionContainer
                    data={{
                        testId,
                        selectedBodyRegions: { bodyRegionList : bodyRegionList },
                        openBiometricMovementAndCautionQuestionContainer,
                    }}
                    setOpenBiometricMovementAndCautionQuestionContainer={handleOpenBiometricModal}
                />
            }


            <Modal className="modal-lg" isOpen={showInstruction} toggle={()=> handleSetShowInstruction(false)}>
                <div className="edit-assessment-instruction-container">
                    <div className="edit-assessment-instruction-header-content kt-padding-b-30">
                        <p className="edit-assessment-instruction-header-content-title">{currentGroup?.groupName}</p>
                        <img onClick={()=> handleSetShowInstruction(false)} className="cursor-pointer" src={crossBtn} alt="Close Button" />
                    </div>
                    <label className="edit-assessment-instruction-info"  dangerouslySetInnerHTML={getMarkup(currentGroup?.groupTitle)}/>
                </div>
            </Modal>
        </>
    )
}

export default EditAssessmentDialogContainer;