import CONSTANT from "../../constants/Constant";

export const initialSweetAlertState = {
    isVisible: false,
    config: {
        showConfirmBtn: true,
        showOkBtn: false,
        showCancelBtn: true,
        title: '',
        message: '',
        confirmBtnText: 'Yes',
        cancelBtnText: 'No',
        confirmBtnCss: 'btn btn-sm btn-success',
        cancelBtnCss: 'btn btn-sm btn-danger',
        okLoading: false,
        noLoading: false,
        type: CONSTANT.ALERT_TYPE.INFO,
        onConfirmKey: null,
        onCancelKey: null,
        closeOnClickOutside: false,
        textWhileProcessingConfirmBtn: 'Saving...',
        textWhileProcessingCancelBtn: 'Saving...',
    }
};
