class Response {
    constructor(response = {}) {
        this.id = response?.Id || ''
        this.name = response?.Name || ''
        this.title = response?.Title || ''
        this.description = response?.Description || ''
        this.color = response?.Color || ''
    }
}

export default Response;