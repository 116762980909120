import CONSTANT from "../../../constants/Constant";
import Progressbar from "../ProgressBar";
import ChatWelcome from "./ChatWelcome";
import { getBodyRegion } from "./ChatServices";
import GLOBAL from "../../../global";
import VoiceOnOffBtn from "./VoiceOnOffBtn";
import QuestionContainer from "./QuestionContainer/QuestionContainer";
import SavingAnimation from "./SavingAnimation";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import AppContext from "../../../contexts/AppContext";
import useChatbot from "./hooks/useChatbot";

const ChatbotContainer = () => {
    const { isAudioPlayEnabled, userInfo } = useContext(AppContext);
    const { onChangeAudioPlay, prepareChatbotAnswer } = useChatbot();

    const chatBotState = useSelector(state => state.chatBot);
    const botData = chatBotState?.botData || {};
    const chatBotUIState = chatBotState?.chatBotUIState || {};

    const showWelcomePage = chatBotUIState?.showWelcomePage || false;
    let botProgressbarValue = chatBotUIState?.botProgressbarValue || 0;

    const chats = botData?.chats || [];
    const selectedBotInfoObj = botData?.botInfo || {};
    const botInfo = selectedBotInfoObj?.botInfo || {};

    return (
        <div className="kt-portlet__body">
            {
                (
                    (
                        (selectedBotInfoObj?.options?.name === CONSTANT.CHAT_BOTS.MSK_BOT) &&
                        !selectedBotInfoObj?.options?.apiUrl.includes('/chat/posture')
                    ) ||
                    selectedBotInfoObj?.options?.name === CONSTANT.CHAT_BOTS.INTAKE_BOT
                ) &&
                !showWelcomePage
                &&
                (
                    <div className="position-relative">
                        <Progressbar progress={botProgressbarValue}/>
                    </div>
                )
            }

            <ChatWelcome botName={botInfo?.subName}/>

            {chats && chats.length > 0 && !showWelcomePage && (
                <div className={`chat-question font-medium`}>
                    <div className="chat-question__container">

                        <div className="chat">

                            <div className="chat-info">
                                {getBodyRegion(chats) && (
                                    <div className='chat-info-body-region'>
                                        <span className="body-region">{`Body Region: ${getBodyRegion(chats)}`}</span>
                                        <div className="vertical"></div>
                                    </div>
                                )}
                                <div
                                    className={`chat-info-test-id-and-voice ${(!getBodyRegion(chats) && !GLOBAL?.ASSESSMENT?.TestId) ? 'pl-3 border-radius-left-10px' : ''}`}>
                                    {GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['TestId'] && (
                                        <>
                                            <span className="test-id">
                                                <span className="test-id-text">Test ID: </span>
                                                {GLOBAL.ASSESSMENT['TestId']}
                                            </span>
                                            <div className="vertical"></div>
                                        </>
                                    )}
                                    <div className={`voice-toggle`}>
                                        <p className="voice">EMMA Voice</p>
                                        <div
                                            className=" ml-2 button-container d-flex justify-content-end align-items-center">
                                            <VoiceOnOffBtn
                                                isAudioPlayEnabled={isAudioPlayEnabled}
                                                onChangeAudioPlay={onChangeAudioPlay}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="chat-container">
                                <div className="chat-container--details">
                                    <QuestionContainer/>
                                    <SavingAnimation/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)
            }
        </div>
    )
}

export default ChatbotContainer;