import React, { useEffect, useRef, useState } from 'react';
import GLOBAL from "../../../global";
import {getAudioUrl, playAudio} from "../../../utils/Utils";
import CONSTANT from "../../../constants/Constant";
import {playPatientNotStandingFacingLeft, playPatientNotStandingFacingRight} from "./Services/MovementService";
import {doesUserNeedFacingLeft, doesUserNeedFacingRight} from "./utilities/Utility";

const CountDown = ({ onHideCountDownAndStartRecording, selectedExercise, notNeedGridExercises, messageOnVideo = "", isShowCountDown   }) => {
    const [seconds, setSeconds] = useState(5);
    const showCountDownRef = useRef(true);
    const [showCountDown, setShowCountDown] = useState(true);
    const isNotFoundFullBodyAudioPlayedRef = useRef(true);

    const intervalRef = useRef(null);

    useEffect(() => {
        if(!isShowCountDown) {
            return;
        }

        intervalRef.current = setInterval(async () => {
            // console.log(GLOBAL.IsColorRedForFullBox , !notNeedGridExercises.includes(selectedExercise?.ExerciseCodeName))
            if (GLOBAL.IsColorRedForFullBox && !notNeedGridExercises.includes(selectedExercise?.ExerciseCodeName)) {
                await handlePatientIsNotInTheBox();
            } else {
                handlePatientInTheBox();
            }
        }, 1000);

        return () => {
            clearInterval(intervalRef.current);
        }
    }, [onHideCountDownAndStartRecording]);


    const handlePatientIsNotInTheBox = async () => {
        const audioUrlPositionInsideOfBox = getAudioUrl(CONSTANT.GENERAL_AUDIOS_KEY_TEXT.POSITION_IN_THE_BOX);
        const shouldUserFaceLeft = doesUserNeedFacingLeft(selectedExercise);
        const shouldUserFaceRight = doesUserNeedFacingRight(selectedExercise);

        if (showCountDownRef.current) {
            showCountDownRef.current = false;
            setShowCountDown(false);
            setSeconds(6); // reset seconds

            if (isNotFoundFullBodyAudioPlayedRef.current) {
                isNotFoundFullBodyAudioPlayedRef.current = false;
                await playAudio('remove');
                if(!GLOBAL.IS_PATIENT_IN_BOX) {
                    if(audioUrlPositionInsideOfBox) await playAudio(audioUrlPositionInsideOfBox);
                } else if(shouldUserFaceRight) {
                    await playPatientNotStandingFacingRight();
                } else if(shouldUserFaceLeft) {
                    await playPatientNotStandingFacingLeft();
                }


                isNotFoundFullBodyAudioPlayedRef.current = true;
            }
        }
    };

    const handlePatientInTheBox = () => {
        if (isNotFoundFullBodyAudioPlayedRef.current) {
            if (!showCountDownRef.current) {
                showCountDownRef.current = true;
                setShowCountDown(true);
            }

            setSeconds(prev => {
                if (prev === 1) {
                    onHideCountDownAndStartRecording && onHideCountDownAndStartRecording();
                    return 5;  // reset seconds
                }
                return prev - 1;
            });
        }
    };

    return (
        <>
            {
                isShowCountDown
                    // Count down window is shown now recording will start after 5 seconds
                    ? showCountDown
                        ? <span className="z-index-1 color-red-2 count-down-text"><strong>{seconds}</strong></span>
                        :
                            messageOnVideo
                                ? <span className="z-index-1 color-red-2 count-down-waring-text">{messageOnVideo}</span>
                                : <span className="z-index-1 color-red-2 count-down-waring-text">Please position yourself in the box to complete the movement</span>
                    : messageOnVideo
                        ? <span className="z-index-1 color-red-2 count-down-waring-text">{messageOnVideo}</span>
                        : null

            }

        </>

    );
};

export default CountDown;