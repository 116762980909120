import CONSTANT from "../../../../constants/Constant";

const { REACT_APP_CHAT_API_URL } = process.env;
const BOT_URL = REACT_APP_CHAT_API_URL + `/api/${CONSTANT.BOT_API_VERSION}/chat`;

export const createBotOptionFactory = (options, additionalOptions = {}) => {
    return {
        botInfo: options.botInfo,
        options: {
            name: options.name,
            apiUrl: options.apiUrl,
            ...additionalOptions
        }
    };
};

export const createBotOption = (options, additionalOptions = {}) => {
    return createBotOptionFactory(options, additionalOptions);
};

export const mskOptions = {
    botInfo: CONSTANT.MSK_BOT_INFO,
    name: CONSTANT.CHAT_BOTS.MSK_BOT,
    apiUrl: BOT_URL,
};

export const postureOptions = {
    botInfo: CONSTANT.POSTURE_BOT_INFO,
    name: CONSTANT.CHAT_BOTS.POSTURE_BOT,
    apiUrl: BOT_URL,
};

export const intakeOptions = {
    botInfo: CONSTANT.INTAKE_BOT_INFO,
    name: CONSTANT.CHAT_BOTS.INTAKE_BOT,
    apiUrl: BOT_URL,
};