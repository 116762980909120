import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import GLOBAL from "../../../global";
import MovementVoice from "../MovementVoice";
import { Fragment } from "react";
import VideoUnmute from "../../../assets/img/movement/VideoUnmute.svg";
import Info from "../../../assets/img/utils/info.svg"
import RequestTelemedicine from "../../../assets/img/utils/RequestTelemedicine.svg"

const HeaderPanel = ({EnableDetailedVoiceInstruction, onChangeDetailedVoiceInstruction, onClickSaveAssessment,
     processingSaveAssessment}) => {
     // processingSaveAssessment, showAppointmentPop}) => {

    return (
        <div className=''>
            <div className="movement-modal-header">
                <div  className="movement-modal-header-element">
                    Biometric Movements <img id="emma-work-best-info" src={Info} alt="Information"/>
                    <UncontrolledTooltip
                        placement="bottom"
                        target="emma-work-best-info">
                        <span style={{width: "500px"}}>EMMA works best with Google Chrome or Microsoft Edge as your web browser.</span>
                    </UncontrolledTooltip>
                </div>
                <div  className="movement-modal-header-element movement-modal-header-width-39 movement-modal-emma-work-best font-size-16">
                    EMMA works best with &nbsp; <a className="color-white decoration-underline" href="https://www.google.com/chrome/" target="_blank">Google Chrome</a> &nbsp;or&nbsp; <a className="color-white decoration-underline" href="https://www.microsoft.com/en-us/edge?form=MA13FJ" target="_blank">Microsoft Edge</a> &nbsp;as your web browser.
                </div>
                <div  className="movement-modal-header-element2 ">
                    <MovementVoice isAudioPlayEnabled={EnableDetailedVoiceInstruction} onChangeAudioPlay={onChangeDetailedVoiceInstruction}/>
                    {/*<div className="kt-header__topbar-wrapper appoinment-btn">*/}
                    {/*    <button className="movement-save-close bg-color-green" onClick={() => showAppointmentPop(true)}>*/}
                    {/*        <span><img className='movement-modal-request-teleme-icon' src={RequestTelemedicine} alt="video unmute"/></span>*/}
                    {/*        <span className="pl-2 movement-modal-request-teleme-text">Request Telemedicine</span>*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                    <div className="kt-header__topbar-wrapper mmh-logout">
                        <button className="movement-save-close save-close" onClick={onClickSaveAssessment}>
                            {processingSaveAssessment ? (
                                <Fragment>
                                    <i className="fa fa-spinner fa-spin"/>
                                    SAVING
                                </Fragment>
                                ) : (
                                    <Fragment>
                                       <span> Save &</span> Close
                                    </Fragment>
                                )
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderPanel;