import React, {memo, useContext, useEffect, useState, useRef} from 'react';
import {Route, Redirect, useLocation, useHistory} from 'react-router-dom';

import routes from "../../constants/Routes";
import AppContext from '../../contexts/AppContext';
import GLOBAL from "../../global";

const PrivateRoute = memo(props => {
    const { authenticated } = useContext(AppContext);
    const { component: Component, ...otherProps } = props;

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if(!GLOBAL.USER_INFO?.CreditCardCount && GLOBAL.IS_SUBSCRIPTION_ENABLED) {
            if(!location.pathname.includes(`${routes.addCard}`)) {
                history.push(routes.addCard);
            }
        }
    }, [location.pathname]);

    return (
        <Route
            render={props =>
                authenticated ? (
                    <>
                        <Component {...props} />
                    </>

                ) : (
                    <Redirect exact to={`${routes.landing}${location?.search}`}/>
                )
            }
            {...otherProps}
        />
    );
});

export default PrivateRoute;
