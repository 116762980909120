import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CONSTANT from "../../../constants/Constant";
import GLOBAL from "../../../global";

const DeviceSettingsModal = (props) => {
    const {openModal, toggleModal, onSelectChangeDevice, webcamConstraints} = props;
    const [inputDeviceInfo, setInputDeviceInfo] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState(GLOBAL.SELECTED_CAMERA_DEVICE);
    // const [selectedMicrophone, setSelectedMicrophone] = useState(GLOBAL.SELECTED_MICROPHONE_DEVICE);
    // const [selectedSpeaker, setSelectedSpeaker] = useState(GLOBAL.SELECTED_SPEAKER_DEVICE);

    const _handleDevices = useCallback((mediaDevices) => {
        let videoInputDevices = mediaDevices.filter(mediaDevice => mediaDevice.kind === CONSTANT.MEDIA_DEVICES_KIND.VIDEO_INPUT);
        setInputDeviceInfo(videoInputDevices);
    }, []);

    useEffect(() => {
            navigator.mediaDevices.enumerateDevices().then(_handleDevices);
        }, [_handleDevices]
    );

    const onChangeInputDevice = (e) => {
        let device = e.target.value;
        device = JSON.parse(device);

        if(device && device.deviceId){
            GLOBAL.IS_CAMERA_READY_FOR_BIOMETRIC = false;
            _setDeviceInfo(device);
            onSelectChangeDevice && onSelectChangeDevice(device.deviceId);
        }
        toggleModal && toggleModal(e);
    };

    const _setDeviceInfo = (device) => {
        if (device.kind === CONSTANT.MEDIA_DEVICES_KIND.VIDEO_INPUT) {
            GLOBAL.SELECTED_CAMERA_DEVICE = device;
            setSelectedCamera(device);
        } else if (device.kind === CONSTANT.MEDIA_DEVICES_KIND.AUDIO_INPUT) {
            GLOBAL.SELECTED_MICROPHONE_DEVICE = device;
            // setSelectedMicrophone(device);
        } else if (device.kind === CONSTANT.MEDIA_DEVICES_KIND.AUDIO_OUTPUT) {
            GLOBAL.SELECTED_SPEAKER_DEVICE = device;
            // setSelectedSpeaker(device);
        }
    };

    return (
        <Modal isOpen={openModal} className="modal-md">
            <ModalHeader toggle={toggleModal}>
                Camera Settings
            </ModalHeader>
            <ModalBody>
                <div className="form-group">
                    <label htmlFor="exampleSelect1">Camera</label>
                    <select className="form-control" id="exampleSelect1" onChange={onChangeInputDevice} value={JSON.stringify(selectedCamera)}>
                        {/*{inputDeviceInfo.filter(inputDevice => inputDevice.kind === CONSTANT.MEDIA_DEVICES_KIND.VIDEO_INPUT)*/}
                        {inputDeviceInfo
                            .map((inputDevice, index) => {
                                return (
                                    <option key={index} value={JSON.stringify(inputDevice)} selected={inputDevice?.deviceId === webcamConstraints?.deviceId}>
                                        {inputDevice.label}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default DeviceSettingsModal;
