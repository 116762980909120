export const getAllPainQuestions = (exercises) => {
    let allPainQuestions = [];
    exercises.forEach((exercise) => {
        // if(exercise?.PainQuestions?.length > 0) {
            allPainQuestions.push({
                ExerciseDisplayName: exercise?.ExerciseDisplayName,
                ExerciseCodeName: exercise?.ExerciseCodeName,
                painQuestions: exercise?.PainQuestions
            });
        // }
    });

    return allPainQuestions;
}

export const mapRenderedPictureInPainQuestionsObject = (painQuestions, queueStatusOfExercises) => {
    let tempPainQuestions = [...painQuestions];
    queueStatusOfExercises.forEach(queueExercise => {
        painQuestions.forEach((painQuestion, index) => {
          if(painQuestion.ExerciseCodeName === queueExercise.exerciseName) {
            tempPainQuestions[index].rendered_picture = queueExercise?.request_output?.rendered_picture;
          }
        })
    });

    return tempPainQuestions;
}