import React from 'react';

const CheckboxOptionRoot = ({ children, onClick, className, ...props }) => (
    <button
        onClick={onClick}
        className={className}
        {...props}
    >
        {children}
    </button>
);

const CheckboxOptionIcon = ({ isChecked, className, ...props }) => (
    <>
        {isChecked ? <i className={`fa fa-check ${className}`} /> : null}
    </>
);

const CheckboxOptionLabel = ({ children, className, ...props }) => (
    <>
        {children}
    </>
);

const CheckboxOptionItem = ({
                                isChecked,
                                onClick,
                                label,
                                className,
                                iconClassName,
                                labelClassName,
                                disabled = false,
                                ...props
                            }) => (
    <CheckboxOptionRoot
        onClick={onClick}
        className={className}
        disabled={disabled}
        {...props}
    >
        <CheckboxOptionIcon
            isChecked={isChecked}
            className={iconClassName}
        />
        <CheckboxOptionLabel
            className={labelClassName}
        >
            {label}
        </CheckboxOptionLabel>
    </CheckboxOptionRoot>
);

const CheckboxOption = {
    Root: CheckboxOptionRoot,
    Icon: CheckboxOptionIcon,
    Label: CheckboxOptionLabel,
    OptionItem: CheckboxOptionItem,
};

export default CheckboxOption;