import { useDispatch, useSelector } from 'react-redux';
import {
    clearSweetConfig,
    setSweetConfig,
    toggleNoLoading,
    toggleOkLoading
} from "../../../features/sweetAlert/sweetAlertSlice";
import { useCallbackContext } from '../../../contexts/CallbackContext';

const useSweetAlert = () => {
    const dispatch = useDispatch();
    const { registerCallback, unregisterCallback } = useCallbackContext();
    const { isVisible, config } = useSelector(state => state.sweetAlert);

    const showSweetAlert = (options) => {
        const onConfirmKey = `confirm-${Date.now()}`;
        const onCancelKey = `cancel-${Date.now()}`;

        // Register callbacks with the generated keys
        registerCallback(onConfirmKey, options.onConfirm);
        registerCallback(onCancelKey, options.onCancel);

        // Set the configuration for SweetAlert, including the keys
        dispatch(setSweetConfig({
            ...options,
            onConfirmKey,
            onCancelKey,
        }));
    };

    const hideSweetAlert = () => {
        // Unregister callbacks to avoid memory leaks
        if (config.onConfirmKey) unregisterCallback(config.onConfirmKey);
        if (config.onCancelKey) unregisterCallback(config.onCancelKey);

        // Clear the SweetAlert configuration
        dispatch(clearSweetConfig());
    };

    const setOkLoading = (isLoading) => {
        dispatch(toggleOkLoading(isLoading));
    };

    const setNoLoading = (isLoading) => {
        dispatch(toggleNoLoading(isLoading));
    }

    return {
        isVisible,
        config,
        showSweetAlert,
        hideSweetAlert,
        setOkLoading,
        setNoLoading
    };
};

export default useSweetAlert;
