import CONSTANT from "../../../../../constants/Constant";
import { _getWaitingRoomByInvitationInfo } from "../../../../../utils/Utils";
import GLOBAL from "../../../../../global";
import { useContext, useRef } from "react";
import AppContext from "../../../../../contexts/AppContext";
import { useDispatch, useSelector } from "react-redux";
import { redirectTo } from "../../ChatServices";
import routes from "../../../../../constants/Routes";
import useSweetAlert from "../../../sweetAlertComponent/useSweetAlert";
import examService from "../../../../../services/ExamService";
import useChatbot from "../../hooks/useChatbot";
import { clearChatBotProperty } from "../../../../../features/chat/chatbotSlice";
import { saveIntake } from "../../../intake-form/intake-form-service";
import useIntakeFrom from "../../../intake-form/useIntakeFrom";
import useHandleExpiredAndUpcomingMeetingPopupMsg
    from "../../../../../hooks/useHandleExpiredAndUpcomingMeetingPopupMsg";

const useCurrentQuestion = () => {
    const dispatch = useDispatch();
    const chatBotState = useSelector(state => state.chatBot);
    const waitingRoomIdRef = useRef('');

    const isIntakeSigned = GLOBAL?.USER_INFO?.IsIntakeSigned || false;
    const { userInfo, setTelemedicineProperties, resetTelemedicineKeys } = useContext(AppContext);
    const { clearAndCloseChatbot } = useChatbot();
    const { showSweetAlert, setNoLoading, hideSweetAlert, setOkLoading } = useSweetAlert();
    const { handleOpenIntakeForm, handleCloseIntakeForm, handleSetJoiningTelemedicine, handleSetJoiningLaterTelemedicine } = useIntakeFrom();
    const { getAssessmentAvailabilityStatus, handleOpenUpcomingOrExpiredPopupMessage } = useHandleExpiredAndUpcomingMeetingPopupMsg();


    const botData = chatBotState?.botData || {};

    const bodyRegionList = botData?.bodyRegionList || [];
    const invitationTypeName = (botData?.invitationTypeName || '').toLowerCase();
    const invitationIdFromState = botData?.invitationId || '';
    const meetingStart = botData?.meetingStart || '';
    const meetingEnd = botData?.meetingEnd || '';

    const callBackOnClickOkAtPopup = () => {
        closeChatBot();
        window.location.replace(`/#${routes.myAssessments}/false`);
    }

    const handleSubmitIntakeForm = async (values) => {
        await saveIntake(values);
        const assessmentAvailabilityStatus = getAssessmentAvailabilityStatus(meetingStart, meetingEnd);
        handleOpenUpcomingOrExpiredPopupMessage(assessmentAvailabilityStatus, handleCloseIntakeForm, closeChatBot);
    }

    async function conversationEnded() {
        const assessmentAvailabilityStatus = getAssessmentAvailabilityStatus(meetingStart, meetingEnd);

        if (invitationTypeName === CONSTANT.REDIRECT_TYPE.TELEMEDICINE) {
            const {
                invitationId,
                waitingRoomId
            } = await _getWaitingRoomByInvitationInfo(GLOBAL?.ASSESSMENT?.['TestId'], invitationIdFromState);

            if (invitationId && waitingRoomId) {
                waitingRoomIdRef.current = waitingRoomId;
                if (!isIntakeSigned) {
                    if(
                        assessmentAvailabilityStatus === CONSTANT.ASSESSMENT_AVAILABILITY.UPCOMING.KEY ||
                        assessmentAvailabilityStatus === CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.KEY
                    ) {
                        handleOpenIntakeForm({
                            bodyRegionList: bodyRegionList || [],
                            invitationTypeName: invitationTypeName || '',
                            showSubmitBtn: true,
                            onClickSubmitIntakeForm: handleSubmitIntakeForm
                        })
                    } else {
                        handleOpenIntakeForm({
                            bodyRegionList: bodyRegionList || [],
                            invitationTypeName: invitationTypeName || '',
                            onClickJoinTelemedicineNow: onClickJoinTelemedicineNow,
                            onClickJoinTelemedicineLater: onClickJoinTelemedicineLater
                        });
                    }
                } else {
                    if(
                        assessmentAvailabilityStatus === CONSTANT.ASSESSMENT_AVAILABILITY.UPCOMING.KEY ||
                        assessmentAvailabilityStatus === CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.KEY
                    ) {
                        handleOpenUpcomingOrExpiredPopupMessage(assessmentAvailabilityStatus, handleCloseIntakeForm, callBackOnClickOkAtPopup);
                    } else {
                        showSweetAlert({
                            title: 'Question',
                            message: 'Please confirm your telemedicine encounter',
                            type: CONSTANT.ALERT_TYPE.SUCCESS,
                            showConfirmBtn: true,
                            showCancelBtn: true,
                            confirmBtnCss: "btn btn-success btn-wide",
                            cancelBtnCss: "btn btn-danger btn-wide kt-ml-20",
                            confirmBtnText: "JOIN NOW",
                            cancelBtnText: "JOIN LATER",
                            textWhileProcessingConfirmBtn: "Joining...",
                            onConfirm: () => {
                                setOkLoading(true);
                                onClickJoinTelemedicineNow('');
                            },
                            onCancel: () => {
                                setNoLoading(true);
                                onClickJoinTelemedicineLater('');
                            },
                        })
                    }

                }


            } else {
                showSweetAlert({
                    title: '',
                    message: 'Waiting room invitation error',
                    type: CONSTANT.ALERT_TYPE.INFO,
                    closeOnClickOutside: true,
                    showOkBtn: true,
                    onConfirm: onConfirmAlert
                })
            }
        } else if (
            invitationTypeName === CONSTANT.REDIRECT_TYPE.INCLINIC ||
            invitationTypeName === CONSTANT.REDIRECT_TYPE.INCLINIC_ENCOUNTER) {
                showSweetAlert({
                    title: '',
                    message: 'Thank you for completing your assessment questionnaire. Your assessment will be reviewed by your provider during your telemedicine or in-clinic appointment.',
                    type: CONSTANT.ALERT_TYPE.INFO,
                    closeOnClickOutside: true,
                    showOkBtn: true,
                    onConfirm: () => {
                        dispatch(clearChatBotProperty());
                        redirectTo(routes.landing);
                        window.location.reload();
                    }

                })
        }
    }

    const closeChatBot = () => {
        if(!userInfo?.Permissions?.CanPatientCreateAssessment) {
            redirectTo(`${routes.myAssessments}/true`);
        }
        clearAndCloseChatbot();
    }

    const sendEmailForTelemed = async (waitingRoomId) => {
        const payload = { WaitingRoomId: waitingRoomId };
        const response = await examService.sendTelemedEmailForEmma(payload);
        const { success, error } = response;

        if (!success) {
            console.error(error);

            showSweetAlert({
                title: '',
                message: 'No waiting room available.',
                type: CONSTANT.ALERT_TYPE.INFO,
                closeOnClickOutside: true,
                showOkBtn: true,
                onConfirm: onConfirmAlert
            })
        } else {
            showSweetAlert({
                title: '',
                message: 'An Email has been sent to your inbox with telemedicine information.',
                type: CONSTANT.ALERT_TYPE.INFO,
                closeOnClickOutside: true,
                showOkBtn: true,
                onConfirm: onConfirmAlert
            })
        }
    };

    const onOpenTelemedicineModal = () => {
        hideSweetAlert();
    }


    const onClickJoinTelemedicineNow = async (values) => {
        if(!isIntakeSigned) {
            handleSetJoiningTelemedicine(true);
            await saveIntake(values);
        }
        setTelemedicineProperties(invitationIdFromState, bodyRegionList, onOpenTelemedicineModal, onCloseTelemedicineModal);
    }

    const onCloseTelemedicineModal = () => {
        handleCloseIntakeForm();
        resetTelemedicineKeys();
        onConfirmAlert();
        window.location.replace(`/#${routes.myAssessments}/false`);
    }

    const onClickJoinTelemedicineLater = async (values) => {
        if (!isIntakeSigned) {
            handleSetJoiningLaterTelemedicine(true);
        }

        if(values) {
            await saveIntake(values);
        }
        await sendEmailForTelemed(waitingRoomIdRef.current);
        if(values) {
            handleCloseIntakeForm();
        }
        window.location.replace(`/#${routes.myAssessments}/false`);
    };

    const onConfirmAlert = () => {
        handleCloseIntakeForm();
        hideSweetAlert();
        closeChatBot();
    };

    return {
        conversationEnded,
        onClickJoinTelemedicineNow,
        onClickJoinTelemedicineLater,
    };
}

export default useCurrentQuestion;