import "../../assets/sass/components/signUp/_signUp.scss";
import "../../assets/sass/components/signIn/_signIn.scss";

import React, {useState} from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Trans } from "react-i18next";

import GLOBAL from "../../global";
import leftIcon from "../../assets/img/signin/leftIcon.svg";
import doctorIcon from "../../assets/img/signin/doctor-icon.svg";
import topRoundIcon from "../../assets/img/signin/topRound.svg";
import mmhColorLogo from "../../assets/img/signin/mmhLogo.svg";
import bottomRoundIcon from "../../assets/img/signin/bottomRound.svg";
import {CountryOptions} from "./CountryOptions";
import cardService from "../../services/CardService";
import {useHistory} from "react-router-dom";


function CreditCardForm(props) {
    const [errorMessage, setErrorMessage] = useState('');

    const history = useHistory();

    const onSubmitCreditCardForm = async (values, actions) => {
        setErrorMessage('');

        const tempValues = { ...values };

        const response = await cardService.saveCreditCard(tempValues);
        const { success, error } = response;

        if (!success) {
            setErrorMessage(error);
            actions.setSubmitting(false);
            return;
        }
        if(GLOBAL.USER_INFO?.CreditCardCount >=0) {
            GLOBAL.USER_INFO.CreditCardCount += 1;
        }
        history.push('/dashboard');
        // setRedirectToDashboard(true);
    }

    const validationSchema = () => {
        return Yup.object().shape({
            Name: Yup.string()
                .required("Name on card is required")
                .min(4, 'Please enter at least four character'),
            // Email: Yup.string()
            //     .email(i18n.t("common.message.error.invalid-email"))
            //     .required(i18n.t("common.message.error.required-field")),
            AddressLine1: Yup.string()
                .required("AddressLine1 is required")
                .min(4, 'Please enter at least four character'),
            AddressLine2: Yup.string()
                .optional()
                .min(4, 'Please enter at least four character'),
            AddressCity: Yup.string()
                .required("City is required")
                .min(4, 'Please enter at least four character'),
            AddressCountry: Yup.string()
                .required("Country is required")
                .min(2, 'Please enter at least two character'),
            AddressState: Yup.string()
                .required("State is required")
                .min(2, 'Please enter at least two character'),
            AddressZip: Yup.number()
                .required("Zip is required")
                .min(4, 'Please enter at least four character'),

            CardNumber: Yup.number()
                .required("Card number is required"),
                // .max(2, 'Please enter at least two character'),
            ExpirationMonth: Yup.number()
                .required("Expiration month is required")
                .min(1, 'Minimum 1')
                .max(12, 'Maximum 12'),
            ExpirationYear: Yup.number()
                .required("Expiration year is required")
                .min(2023, 'Minimum expiration year is 2023')
                .max(2040, 'Maximum expiration year is 2040'),
            Cvc: Yup.number()
                .required("Cvc is required")
                .max(9999, 'Maximum 4 digit'),
        });
    };

    const initialValuesForCreditCard = {
        Name: "",
        AddressLine1: "",
        AddressLine2: "",
        AddressCity: "",
        AddressCountry: "US",
        AddressState: "",
        AddressZip: "",
        CardNumber: "",
        ExpirationMonth: "",
        ExpirationYear: "",
        Cvc: "",
    };

    // noinspection CheckTagEmptyBody
    return (
        <div className='signup_signin_container'>
            <img alt="icon" src={leftIcon} className='left_icon' />
            <img alt="img" src={doctorIcon} className='doctorimg' />
            <img alt="img" src={topRoundIcon} className='halfround' />
            <img alt="img" src={bottomRoundIcon} className='roundBottom' />
            <div className="signup_signin_form max-w-700px">
                <Formik
                    initialValues={initialValuesForCreditCard}
                    validationSchema={validationSchema()}
                    onSubmit={onSubmitCreditCardForm}>
                    {({
                          handleChange,
                          handleSubmit,
                          handleBlur,
                          values,
                          errors,
                          touched,
                          isSubmitting,
                          setFieldValue
                      }) => <div>
                        <div className="color_logo_mmh">
                            {GLOBAL.LOGO_URL ? (
                                <img src={GLOBAL.LOGO_URL} alt="MMH Logo" />) : (
                                <img src={mmhColorLogo} alt="MMH Logo" />)}
                        </div>
                        <div>
                            <div className="signup_signin_form_header">
                                {/*<p> New {getUserIdentification()} Registration </p>*/}
                                <p> Credit Card Information </p>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="marginBottom2rem">
                                    <div className="sign-up-name-container">
                                        <div className="signup_signin_input-container marginright">
                                            <label className="signup_signin_input-label">Name on Card</label>
                                            <input
                                                className={`signin_input-field color-primary-dark-2`}
                                                type="text"
                                                placeholder="Name on Card"
                                                name="Name"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.Name}
                                            />
                                            {errors.Name && touched.Name && (
                                                <div className="name-error-msg">{errors.Name}</div>
                                            )}
                                        </div>

                                        <div className="signup_signin_input-container marginleft">
                                            <label className="signup_signin_input-label">Card Number</label>
                                            <input
                                                className={`signin_input-field color-primary-dark-2`}
                                                type="number"
                                                min="0"
                                                placeholder="Card Number"
                                                name="CardNumber"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.CardNumber}
                                            />
                                            {errors.CardNumber && touched.CardNumber && (
                                                <div className="name-error-msg">{errors.CardNumber}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="marginBottom2rem">
                                    <div className="sign-up-name-container">
                                        <div className="signup_signin_input-container marginright">
                                            <label className="signup_signin_input-label">Expiration Month</label>
                                            <input
                                                className={`signin_input-field color-primary-dark-2`}
                                                type="number"
                                                min="0"
                                                placeholder="Expiration Month"
                                                name="ExpirationMonth"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.ExpirationMonth}
                                            />
                                            {errors.ExpirationMonth && touched.ExpirationMonth && (
                                                <div className="name-error-msg">{errors.ExpirationMonth}</div>
                                            )}
                                        </div>
                                        <div className="signup_signin_input-container marginright">
                                            <label className="signup_signin_input-label">Expiration Year</label>
                                            <input
                                                className={`signin_input-field color-primary-dark-2`}
                                                type="number"
                                                placeholder="Expiration Year"
                                                name="ExpirationYear"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.ExpirationYear}
                                            />
                                            {errors.ExpirationYear && touched.ExpirationYear && (
                                                <div className="name-error-msg">{errors.ExpirationYear}</div>
                                            )}
                                        </div>
                                        <div className="signup_signin_input-container marginright">
                                            <label className="signup_signin_input-label">Card CVC Number</label>
                                            <input
                                                className={`signin_input-field color-primary-dark-2`}
                                                type="number"
                                                placeholder="Card CVC Number"
                                                name="Cvc"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.Cvc}
                                            />
                                            {errors.Cvc && touched.Cvc && (
                                                <div className="name-error-msg">{errors.Cvc}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="marginBottom2rem">
                                    <div className="sign-up-name-container">
                                        <div className="signup_signin_input-container marginright">
                                            <label className="signup_signin_input-label">Address Line 1</label>
                                            <input
                                                className={`signin_input-field color-primary-dark-2`}
                                                type="text"
                                                placeholder="Please enter your address"
                                                name="AddressLine1"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.AddressLine1}
                                            />
                                            {errors.AddressLine1 && touched.AddressLine1 && (
                                                <div className="name-error-msg">{errors.AddressLine1}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="marginBottom2rem">
                                    <div className="sign-up-name-container">
                                        <div className="signup_signin_input-container marginright">
                                            <label className="signup_signin_input-label">Address Line 2(Optional)</label>
                                            <input
                                                className={`signin_input-field color-primary-dark-2`}
                                                type="text"
                                                placeholder="Please enter your address"
                                                name="AddressLine2"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.AddressLine2}
                                            />
                                            {errors.AddressLine2 && touched.AddressLine2 && (
                                                <div className="name-error-msg">{errors.AddressLine2}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="marginBottom2rem">
                                    <div className="sign-up-name-container">
                                        <div className="signup_signin_input-container marginright">
                                            <label className="signup_signin_input-label">City</label>
                                            <input
                                                className={`signin_input-field color-primary-dark-2`}
                                                type="text"
                                                placeholder="Please Enter City"
                                                name="AddressCity"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.AddressCity}
                                            />
                                            {errors.AddressCity && touched.AddressCity && (
                                                <div className="name-error-msg">{errors.AddressCity}</div>
                                            )}
                                        </div>

                                        <div className="signup_signin_input-container marginleft">
                                            <label className="signup_signin_input-label">State</label>
                                            <input
                                                className={`signin_input-field color-primary-dark-2`}
                                                type="text"
                                                placeholder="Please Enter State"
                                                name="AddressState"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.AddressState}
                                            />
                                            {errors.AddressState && touched.AddressState && (
                                                <div className="name-error-msg">{errors.AddressState}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="marginBottom2rem">
                                    <div className="sign-up-name-container">
                                        <div className="signup_signin_input-container marginright">
                                            <label className="signup_signin_input-label">Zip Code</label>
                                            <input
                                                className={`signin_input-field color-primary-dark-2`}
                                                type="number"
                                                placeholder="Please Enter Zip Code"
                                                name="AddressZip"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.AddressZip}
                                            />
                                            {errors.AddressZip && touched.AddressZip && (
                                                <div className="name-error-msg">{errors.AddressZip}</div>
                                            )}
                                        </div>

                                        <div className="signup_signin_input-container marginleft mb-3">
                                            <div className="signup_signin_input-container marginleft">
                                                <label className="signup_signin_input-label">Country</label>
                                                <select
                                                    className={`${!values.AddressCountry ? "color-secondary": "color-primary-dark-2"} signin_input-field`}
                                                    name="AddressCountry"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.AddressCountry}
                                                >
                                                    <option value="" disabled selected>Select your country</option>
                                                    <CountryOptions />
                                                </select>
                                                {errors.AddressCountry && touched.AddressCountry && (
                                                    <div className="name-error-msg">{errors.AddressCountry}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="marginBottom2rem">
                                    <button
                                        type="submit"
                                        className="signup_signin_submit"
                                        // disabled={isSubmitting || validUser !== USER_STATUS.INVITED}>
                                        disabled={isSubmitting}>
                                        {isSubmitting ? (
                                            <>
                                                <i className="fa fa-spinner fa-spin" />&nbsp;&nbsp;
                                                <Trans i18nKey="common.message.info.please-wait" />
                                            </>
                                        ) : (
                                            "Submit"
                                        )}
                                    </button>
                                    {errorMessage && (
                                        <div className="text-center mt-2">
                                            <span className="text-danger">
                                                {errorMessage}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>}
                </Formik>
                <div className="signup_signin_registered">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default CreditCardForm;