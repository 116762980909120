import React from "react";
import { useParams, useLocation } from "react-router-dom";

import ShowAllQuestionsDetails from "./ShowAllQuestionsDetails";

const ShowAllQuestions = ({ showAllQuestion = false }) => {
    let header = new URLSearchParams(useLocation().search).get('header');
    let { testId, bodyRegionList } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const WaitingRoomIdValue = searchParams.get('w');
    const WaitingRoomId = WaitingRoomIdValue ? WaitingRoomIdValue : 0;
    const tempBodyRegionList = bodyRegionList.split`,`.map(x => +x);

    return (
        <>
            {/*<MobileHeader/>*/}

            <ShowAllQuestionsDetails testId={testId} bodyRegionList={tempBodyRegionList} WaitingRoomIdForTele={WaitingRoomId} />
        </>
    );
}

export default ShowAllQuestions;
