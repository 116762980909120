import { useState } from 'react';


const useForm = (initialValues, onSubmit, validatePageField) => {
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = async (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        setValues(prevValues => {
            const updatedValues = { ...prevValues, [name]: newValue };
            validateField(name, newValue);
            return updatedValues;
        });
    };

    const handleBlur = async (e) => {
        const { name } = e.target;
        await validateField(name, values[name]);
    };

    const validateField = async (name, value) => {
        try {
            // await Yup.reach(validationSchema, name).validate(value);
            const validationResult = validatePageField(name, value);
            if (!validationResult.valid) {
                setErrors(prevErrors => ({ ...prevErrors, [name]: validationResult.message }));
            } else {
                setErrors(prevErrors => {
                    const updatedErrors = { ...prevErrors };
                    delete updatedErrors[name];
                    return updatedErrors;
                });
            }
            setTouched(prevTouched => ({ ...prevTouched, [name]: true }));
        } catch (err) {
            setTouched(prevTouched => ({ ...prevTouched, [name]: true }));
            setErrors(prevErrors => ({ ...prevErrors, [name]: err.message }));
        }
    };

    const setFieldValue = async (name, value, shouldValidate = true) => {
        setValues( (prevValues) => {
            const updatedValues = { ...prevValues, [name]: value };
            if (shouldValidate) {
                validateField(name, value);
            }
            return updatedValues;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const isValid = await validate();
        if (isValid) {
            await onSubmit(values, setIsSubmitting);
        }
        setIsSubmitting(false);
    };

    // const validate = async () => {
    //     try {
    //         await validationSchema.validate(values, { abortEarly: false });
    //         setErrors({});
    //         return true;
    //     } catch (err) {
    //         setAllFieldsTouched();
    //         console.error(err);
    //         const newErrors = err.inner.reduce((acc, current) => {
    //             acc[current.path] = current.message;
    //             return acc;
    //         }, {});
    //         setErrors(newErrors);
    //         return false;
    //     }
    // };


    const validate = async () => {
        const newErrors = {};
        const ignore = ["HearAboutUs", "TimeZone"];
        for (const name in values) {
            const value = values[name];
            if(ignore.includes(name)) {
                continue;
            }

            const validationResult = validatePageField(name, value);
            if (!validationResult.valid) {
                newErrors[name] = validationResult.message;
            }
        }
        setErrors(newErrors);
        setAllFieldsTouched();
        return Object.keys(newErrors).length === 0;
    };

    const setAllFieldsTouched = () => {
        const newTouched = Object.keys(values).reduce((acc, current) => {
            acc[current] = true;
            return acc;
        }, {});
        setTouched(newTouched);
    };

    return {
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        touched,
        isSubmitting,
        setIsSubmitting,
    };
};

export default useForm;