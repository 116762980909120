import InputMask from "react-input-mask";
import React, {Fragment, useContext, useState} from "react";

import {
    deepClone,
    makeSerializable,
    replaceCharactersWithAsterisk
} from "../../../../utils/Utils";
import { getInputType } from "../ChatServices";
import CONSTANT from "../../../../constants/Constant";
import ChatRequestDetail from "../../../../models/ChatRequestDetail";
import {useDispatch, useSelector} from "react-redux";
import useChatbot from "../hooks/useChatbot";
import {setBotAnswerAtIndex, setChatBotErrorMsg} from "../../../../features/chat/chatbotSlice";
import answerValidationService from "../../../../services/answerValidation/AnswerValidationService";


const TextQuestionsAnswers = () => {
    const { prepareChatbotAnswer } = useChatbot();
    const dispatch = useDispatch();
    const chatBotState = useSelector(state => state.chatBot);

    const botData = chatBotState?.botData || {};
    const chats = botData?.chats || [];
    const currentChatIndex = botData?.currentChatIndex ?? -1;
    const chat = chats[currentChatIndex];

    const [heightInFeet, setHeightInFeet] = useState('');
    const [heightInInch, setHeightInInch] = useState('');
    const [allowEnter, setAllowEnter] = useState(true);

    const getHeight = (e, chat, type) => {
        let feet = 0;
        let inch = 0;
        if (type === CONSTANT.HEIGHT.FEET) {
            feet = (e.target.value && parseInt(e.target.value)) || 0;
            setHeightInFeet(e.target.value);
            inch = (heightInInch && parseInt(heightInInch)) || 0;
            chat.answers = [{
                ...new ChatRequestDetail({name: (feet * 12 + inch).toString()})
            }];
        } else if (type === CONSTANT.HEIGHT.INCH) {
            inch = (e.target.value && parseInt(e.target.value)) || 0;
            setHeightInInch(e.target.value);
            feet = (heightInFeet && parseInt(heightInFeet)) || 0;
            chat.answers = [{
                ...new ChatRequestDetail({name: (inch + feet * 12).toString()})
            }];
        }

        setTimeout(() => {
            const inputElement = document.getElementById('input-box-height-' + type + chat.id);
            inputElement && inputElement.focus && inputElement.focus();
        }, 0);
    }

    const onFocusTextArea = (e) => {
        const value = e.target.value;
        e.target.value = '';
        e.target.value = value;
    };

    const onChangeInputText = (e, chat) => {
        let answer = new ChatRequestDetail({ name: e.target.value });
        dispatch(setBotAnswerAtIndex({ index: currentChatIndex, answer: makeSerializable(answer) }));
    };

    // const _isAnswerValid = (chat, chatRequestDetail) => {
    //     let { valid, message } = checkClientValidation(chat, chat.answers[0]?.name, chats);
    //     if(chat.questionId === CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH) {
    //         const res = checkClientValidation(chat.questionId, chat.answers[0]?.name, chat)
    //         valid = res.valid
    //         message = res.message
    //     }
    //     if (!valid) {
    //         dispatch(setChatBotErrorMsg(message));
    //     } else {
    //         dispatch(setChatBotErrorMsg(''));
    //     }
    //
    //     return valid;
    // }

    const onClickAutoInputTextAnswer = (chat) => {
        // if (chat.intent !== CONSTANT.CHATBOT_INTENT.NOTES &&
        //     chat.intent !== CONSTANT.CHATBOT_INTENT.EMAIL_BODY) {
        //     if (!_isAnswerValid(chat, chat.answers[0]?.name)) {
        //         return;
        //     }
        // }
        // if(chat.answers[0]?.name === '') {
        //     dispatch(setChatBotErrorMsg('Enter at least one character'));
        //     return;
        // }

        const deepClonedChat = deepClone(chat);
        const questionId = deepClonedChat?.questionId || 0;
        const answer = deepClonedChat.answers[0]?.name || '';
        const responseType = deepClonedChat?.responseType || '';
        let validation = answerValidationService.validate(questionId, responseType, answer);

        if (!validation?.valid) {
            dispatch(setChatBotErrorMsg(validation?.message || ''));
            return;
        }

        prepareChatbotAnswer(chat);
    };

    const onKeyPressHandleEnter = (e) => {
        const charCode = (typeof e.which == "number") ? e.which : e.keyCode;

        if (allowEnter && charCode === CONSTANT.KEYBOARD_KEYS.ENTER) {
            e.preventDefault();
            onClickAutoInputTextAnswer(chat)
        }
    };

    const onChangeAllowEnter = () => {
        setAllowEnter(!allowEnter);
    };

    return (
        <div className="textQuestionsAnswers">
            {chat && !chat.answered && !chat.chatEnded && (
                <>
                    {!(chat.intent === CONSTANT.CHATBOT_INTENT.NOTES || chat.intent === CONSTANT.CHATBOT_INTENT.EMAIL_BODY) && (
                        <div className="d-flex responses align-items-center inputs-container">
                            <div className={`d-flex align-items-center inputs ${chat.questionId !== CONSTANT.CHATBOT_ACCOUNT.HEIGHT ? " max-w-600px " : ""}`}>
                                {!(chat.intent === CONSTANT.CHATBOT_INTENT.DATETIME_INPUT_MASK) && !(chat.questionId === CONSTANT.CHATBOT_ACCOUNT.HEIGHT) && (
                                <div className="flex-grow-1">
                                    <input
                                        id={'input-box-' + chat.id}
                                        maxLength="50"
                                        type={getInputType(chat)}
                                        placeholder="Enter your answer here"
                                        className="form-control"
                                        value={chat.answers[0]?.name || ""}
                                        ref={input => input && input.focus && input.focus()}
                                        onChange={(e) => onChangeInputText(e, chat)}
                                        onKeyPress={onKeyPressHandleEnter}
                                    />
                                </div>
                                )}
                                {chat.intent === CONSTANT.CHATBOT_INTENT.DATETIME_INPUT_MASK && (
                                    <div className="flex-grow-1">
                                        <InputMask
                                            id={'input-box-' + chat.id}
                                            mask="99/99/9999"
                                            type="text"
                                            placeholder="Please enter your date of birth"
                                            autoComplete="off"
                                            className="form-control"
                                            value={chat.answers[0]?.name || ""}
                                            inputRef={input => input && input.focus && input.focus()}
                                            onChange={(e) => onChangeInputText(e, currentChatIndex)}
                                            onKeyPress={onKeyPressHandleEnter}
                                        />
                                    </div>
                                )}

                                {(chat.questionId === CONSTANT.CHATBOT_ACCOUNT.HEIGHT) && (
                                    <div className="d-flex align-items-center flex-grow-1 height-input-container">
                                        <input
                                            id={'input-box-height-feet' + chat.id}
                                            type={getInputType(chat)}
                                            placeholder="Feet"
                                            maxLength="1"
                                            className="form-control kt-mr-5 flex-grow-1"
                                            value={heightInFeet}
                                            ref={input => input && input.focus && !heightInInch && input.focus()}
                                            onChange={(e) => getHeight(e, chat, CONSTANT.HEIGHT.FEET)}
                                            onKeyPress={onKeyPressHandleEnter}
                                        />
                                        <input
                                            id={'input-box-height-inch' + chat.id}
                                            type={getInputType(chat)}
                                            placeholder="Inch"
                                            maxLength="2"
                                            className="form-control kt-mr-5 flex-grow-1"
                                            value={heightInInch}
                                            onChange={(e) => getHeight(e, chat, CONSTANT.HEIGHT.INCH)}
                                            onKeyPress={onKeyPressHandleEnter}
                                        />
                                        <input
                                            id={'input-box-' + chat.id}
                                            type={getInputType(chat)}
                                            disabled
                                            placeholder="Total height in Inch"
                                            className="form-control flex-grow-1"
                                            value={chat.answers[0]?.name || ""}
                                            onChange={(e) => onChangeInputText(e, chat)}
                                            onKeyPress={onKeyPressHandleEnter}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="d-flex align-items-center">
                                <button type="button" className="btn btn-primary kt-ml-25"
                                        onClick={() => onClickAutoInputTextAnswer(chat)}>
                                    OK
                                </button>
                                <label className="kt-checkbox kt-ml-25 white-space-pre">
                                    {/*<input type="checkbox" checked={allowEnter} disabled={endChat}*/}
                                    <input type="checkbox" checked={allowEnter}
                                           onChange={onChangeAllowEnter}/> Allow Enter
                                    <span/>
                                </label>
                            </div>
                        </div>
                    )}
                    {(chat.intent === CONSTANT.CHATBOT_INTENT.NOTES || chat.intent === CONSTANT.CHATBOT_INTENT.EMAIL_BODY) && (
                        <div className="d-flex responses">
                            <textarea
                                id={'input-box-' + chat.id}
                                placeholder="Enter your answer here"
                                className="form-control w-50"
                                value={chat.answers[0]?.name || ""}
                                ref={input => input && input.focus && input.focus()}
                                onChange={(e) => onChangeInputText(e, chat)}
                                onFocus={(e) => onFocusTextArea(e)}>
                            </textarea>
                            <button type="button" className="btn btn-primary kt-ml-25"
                                    onClick={() => onClickAutoInputTextAnswer(chat)}>
                                OK
                            </button>
                        </div>
                    )}
                </>
            )}
            {chat && chat.answered && (
                <div className="answers kt-font-xl text-truncate">
                    {chat.answers && chat.answers.length > 0 && (
                        chat.answers.map((chatAnswer, indexR) => {
                            return (
                                <Fragment key={indexR}>
                                    {chat.questionId === CONSTANT.CHATBOT_ACCOUNT.PASSWORD && (
                                        <span className="kt-font-brand">
                                            {replaceCharactersWithAsterisk(chatAnswer.name)}
                                        </span>
                                    )}
                                    {chat.questionId === CONSTANT.CHATBOT_ACCOUNT.CONFIRM_PASSWORD && (
                                        <span className="kt-font-brand">
                                            {replaceCharactersWithAsterisk(chatAnswer.name)}
                                        </span>
                                    )}
                                    {chat.questionId !== CONSTANT.CHATBOT_ACCOUNT.PASSWORD &&
                                        chat.questionId !== CONSTANT.CHATBOT_ACCOUNT.CONFIRM_PASSWORD && (
                                            <span className="kt-font-brand">
                                            {chatAnswer.name}
                                        </span>
                                        )}
                                </Fragment>
                            )
                        })
                    )}
                </div>
            )}
        </div>
    )
};

export default TextQuestionsAnswers;