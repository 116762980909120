import React, { useContext } from "react";

import Popup from "../Popup";
import AppContext from "../../../contexts/AppContext";
import IconCloseButton from "../IconCloseButton";
import TakeAssessmentItem from "./TakeAssessmentItem";
import CONSTANT from "../../../constants/Constant";
import GLOBAL from "../../../global";
import {setOpenChatBot} from "../../../features/chat/chatbotSlice";
import {useDispatch} from "react-redux";

const TakeAssessmentModal = () => {
    const dispatch = useDispatch();
    const { showTakeAssessmentModal, toggleShowTakeAssessmentModal, userInfo} = useContext(AppContext);
    const EnableFysicalScore = userInfo?.Permissions?.EnableFysicalScore || false;
    const IsPostureAnalyzeEnabled = userInfo?.Permissions?.IsPostureAnalyzeEnabled || false;
    const InvitationOnlyAssessment = userInfo?.Permissions?.InvitationOnlyAssessment || false;

    const patientId = (GLOBAL.USER_INFO && GLOBAL.USER_INFO["ContactId"]) || "";

    return (
        <>
            {userInfo?.Permissions && (
                <Popup show={showTakeAssessmentModal}>
                    <div className="modal-container-start">
                        <div className="d-flex justify-content-center align-items-center custom-header">
                            <div className="d-flex justify-content-center custom-header-title flex-grow-1">Take Assessment</div>
                            <IconCloseButton closeHandler={toggleShowTakeAssessmentModal} />
                        </div>
                        <div className="modal-container-start__modal-content-container">
                            {EnableFysicalScore && (
                                <TakeAssessmentItem
                                    title="component.page.start-msk-bot"
                                    subTitle="component.page.click-the-start-button-for-msk-bot"
                                    closeModal={toggleShowTakeAssessmentModal}
                                    onClickStart={() => {
                                        dispatch(setOpenChatBot({ invitationData: { InvitationTypeName: 'self' }, patientId }));
                                    }}
                                />
                            )}
                            {(IsPostureAnalyzeEnabled && !InvitationOnlyAssessment) && (
                                <TakeAssessmentItem
                                    title="component.page.posture-screening"
                                    subTitle="component.page.Click-the-button-for-start-posture-screening"
                                    btnloadingTitle="STARTING POSTURE SCREENING"
                                    closeModal={toggleShowTakeAssessmentModal}
                                    onClickStart={() => {
                                        dispatch(setOpenChatBot({ invitationData: { BodyRegionList: [+CONSTANT.BODY_REGIONS.POSTURE.key], InvitationTypeName: 'self' }, patientId }));
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </Popup>
            )}

        </>
    );
}

export default TakeAssessmentModal;
