export const initialDashboardState = {
    biometric: {
        openBiometricMovementAndCautionQuestionContainer: false,
        testId: '',
        selectedBodyRegions: [],
    },
    showLoadingInConformTelemedicine: false,
    selectedTelemedicineInvitationId: '',
    invitationListDoNotHaveTestIds: [],
    followUpAssessments: [],
    lastIncompletedAssessments: [],
    showLoadingAtJoinNowBtn: false,
    showDetailInstruction: false,
    isShowIsFollowUpPopup: false,
}