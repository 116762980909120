import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PainQuestionForm from "./PainQuestionForm/PainQuestionForm";
import ErrorDialog from "../Popups/ErrorDialog";
import { checkAllPainQuestionAnswered } from "./Services/MovementService";
import CONSTANT from "../../../constants/Constant";

const StaticVideoModal = (props) => {

    const {isShow, videoUrl, painQuestionList, setPainQuestionList, exercise, onClickAnalyze, onClickRetakeExercise, isAnalyzingVideo, isAudioPlayEnabled, playGeneralAudio} = props;
    const [doesSubmittedPainQuestionAnswer, setDoesSubmittedPainQuestionAnswer] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        if(doesSubmittedPainQuestionAnswer) {
            setDoesSubmittedPainQuestionAnswer(false);
            clearErrorMsg();
        }

        if(isShow && painQuestionList && painQuestionList.length === 0) {
            setTimeout(() => {
                onClickAnalyzeHandler();
            }, 1000);
        }
        if(isAudioPlayEnabled && isShow && painQuestionList.length > 0) {
            playGeneralAudio && playGeneralAudio(playGeneralAudio(CONSTANT.GENERAL_AUDIOS_KEY_TEXT.ANSWER_PAIN_QUESTION))
        }
    }, [isShow]);

    const clearErrorMsg = () => {
        setErrorMsg("");
    }

    const onClickAnalyzeHandler = async () => {
        await onClickAnalyze(painQuestionList);
        setDoesSubmittedPainQuestionAnswer(true);
        const areAllPainQuesAnswered = checkAllPainQuestionAnswered(painQuestionList);
        if(!areAllPainQuesAnswered) setErrorMsg("Please answer all pain question.");
    }

    return (
        <Modal isOpen={isShow} className="modal-lg h-90 pain-question" centered>
            <ModalHeader>
                <div className="color-primary-dark-1">
                    Review Movement Video
                </div>
            </ModalHeader>
            <ModalBody>
                {painQuestionList && painQuestionList.length > 0 && (
                    <PainQuestionForm
                        painQuestionList={painQuestionList}
                        setPainQuestionList={setPainQuestionList}
                        isClickedOnSubmitBtn={doesSubmittedPainQuestionAnswer}
                        onClickAnalyzeHandler={onClickAnalyzeHandler}
                        isAnalyzingVideo={isAnalyzingVideo}
                    />
                )}
                <div className="row">
                    <video autoPlay controls src={videoUrl} className="w-100"/>
                </div>
            </ModalBody>
            <ModalFooter>
                {
                    !isAnalyzingVideo
                        ? (
                            <button className="btn btn-success" onClick={onClickAnalyzeHandler}>Review & Submit</button>
                        )
                        : (
                            <button className="btn btn-success" onClick={onClickAnalyzeHandler} disabled={isAnalyzingVideo}>
                                <i className="fa fa-spinner fa-spin"/>&nbsp; Analyzing...
                            </button>
                        )

                }
                <button className="btn btn-danger" onClick={() => onClickRetakeExercise(exercise)} disabled={isAnalyzingVideo}>
                    Retake
                </button>
            </ModalFooter>
            <ErrorDialog errorMsg={errorMsg} clearErrorMsg={clearErrorMsg}/>
        </Modal>
    );
}

export default StaticVideoModal;