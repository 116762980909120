import CONSTANT from "../../../../constants/Constant";
import {Modal, ModalHeader} from "reactstrap";
import IntakeForm from "../IntakeForm";
import PrivacyPolicy from "../PrivacyPolicy";
import DisplayUrlContent from "../../DisplayUrlContent";
import Mmh_terms_and_service from "../../../../assets/docs/mmh_terms_of_service.pdf";
import React from "react";
import GLOBAL from "../../../../global";
import {
    toggleShowPolicy,
    toggleShowTermsAndConditionModal
} from "../../../../features/intakeForm/intakeFormSlice";
import {useDispatch, useSelector} from "react-redux";
import {useCallbackContext} from "../../../../contexts/CallbackContext";

const IntakeFormWrapper = () => {
    const dispatch = useDispatch();
    const isIntakeSigned = GLOBAL?.USER_INFO?.IsIntakeSigned || false; ////TODO: HAVE TO MOVE THIS INTO REDUX
    const { getCallback } = useCallbackContext();


    const intakeFormState = useSelector(state => state.intakeForm);
    const showIntakeForm = intakeFormState?.showIntakeForm || false;
    const joinNowBtnConfig = intakeFormState?.joinNowBtnConfig || {};
    const joinLaterBtnConfig = intakeFormState?.joinLaterBtnConfig || {};
    const showPolicy = intakeFormState?.showPolicy || false;
    const showTermsAndConditionModal = intakeFormState?.showTermsAndConditionModal || false;
    const invitationTypeName = intakeFormState?.invitationTypeName || "";
    const isFromCalendar = intakeFormState?.isFromCalendar || false;

    const joiningTelemedicine = joinNowBtnConfig.joiningTelemedicine || false;
    const joinBtnCallbackFnKey = joinNowBtnConfig.joinBtnCallbackFnKey || '';
    const joiningLaterTelemedicine = joinLaterBtnConfig.joiningLaterTelemedicine || false;
    const joinLaterBtnCallbackFnKey = joinLaterBtnConfig.joinLaterBtnCallbackFnKey || '';

    const submitBtnConfig = intakeFormState?.submitBtnConfig || {};
    const showSubmitBtn = submitBtnConfig.showSubmitBtn || false;
    const submitBtnCallbackFnKey = submitBtnConfig.submitBtnCallbackFnKey || '';

    let shouldShowIntakeForm =
            !isIntakeSigned &&
            showIntakeForm &&
            (
                invitationTypeName.toLowerCase() === CONSTANT.REDIRECT_TYPE.TELEMEDICINE ||
                isFromCalendar
            );

    const handleClickSubmitBtn = async (values) => {
        const submitCallback = getCallback(submitBtnCallbackFnKey);
        if (submitCallback) {
            await submitCallback(values);
        }
    };

    const handleClickJoinNowBtn = async (values) => {
        const joinNowCallback = getCallback(joinBtnCallbackFnKey);
        if (joinNowCallback) {
            await joinNowCallback(values);
        }
    }

    const handleClickJoinLaterBtn = async (values) => {
        const joinLaterCallback = getCallback(joinLaterBtnCallbackFnKey);
        if (joinLaterCallback) {
            await joinLaterCallback(values);
        }
    }

    return (
        <>
            {shouldShowIntakeForm && (
                <Modal
                    isOpen={showIntakeForm}
                    backdrop={"static"}
                    className="modal-xl">
                    <ModalHeader>
                        Intake Form
                    </ModalHeader>
                    <div>
                        <IntakeForm
                            handler={() => dispatch(toggleShowPolicy)}
                            onClickJoinTelemedicineNow={handleClickJoinNowBtn}
                            onClickJoinTelemedicineLater={handleClickJoinLaterBtn}
                            termsAndConditionModalHandler={() => dispatch(toggleShowTermsAndConditionModal())}
                            onClickSubmitIntakeForm={handleClickSubmitBtn}
                        >
                            <>
                                {!showSubmitBtn && (
                                    <>
                                        <button
                                            id='JOIN_NOW'
                                            type='submit'
                                            className="btn btn-success btn-wide"
                                            style={{ marginLeft: "1rem" }}
                                            disabled={joiningTelemedicine || joiningLaterTelemedicine}>
                                            {joiningTelemedicine
                                                ? <span><i className="fa fa-spinner fa-spin" /> JOINING...</span>
                                                : <span>JOIN NOW</span>
                                            }
                                        </button>
                                        <button
                                            id='JOIN_LATER'
                                            type='submit'
                                            className="btn btn-danger btn-wide kt-ml-20"
                                            disabled={joiningTelemedicine || joiningLaterTelemedicine}>
                                            {joiningLaterTelemedicine
                                                ? <span><i className="fa fa-spinner fa-spin" /> JOINING LATER</span>
                                                : <span>JOIN LATER</span>
                                            }
                                        </button>
                                    </>
                                )}

                                {showSubmitBtn && (
                                    <button
                                        id='SUBMIT'
                                        type='submit'
                                        className="btn btn-success btn-wide"
                                        style={{ marginLeft: "1rem" }}
                                        disabled={joiningTelemedicine}>
                                        {joiningTelemedicine
                                            ? <span><i className="fa fa-spinner fa-spin" /> Submitting...</span>
                                            : <span>Submit</span>
                                        }
                                    </button>
                                )}
                            </>
                        </IntakeForm>
                    </div>
                </Modal>
            )}

            <PrivacyPolicy show={showPolicy} handler={() => dispatch(toggleShowPolicy)}/>

            <DisplayUrlContent
                open={showTermsAndConditionModal}
                url={Mmh_terms_and_service}
                onToggleModal={() => dispatch(toggleShowTermsAndConditionModal())}>
            </DisplayUrlContent>
        </>
    )
}

export default IntakeFormWrapper;