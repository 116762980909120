import CONSTANT from "../../constants/Constant";
import { isMobile } from "react-device-detect";
import React from "react";
import { useSelector } from "react-redux";
import { getIndexOfCurrentGroup } from "./EditAssessmentUtils";
import useEditAssessmentHeaderAndFooter from "./hooks/useEditAssessmentHeaderAndFooter";

const EditAssessmentQuestionFooter = () => {
    const { handleOnClickNextBtn, handleOnClickPrevBtn, handleOnClickSubmit } = useEditAssessmentHeaderAndFooter();
    const editAssessmentGroup = useSelector(state => state.editAssessment);
    const groups = editAssessmentGroup?.groups;

    const baseData = editAssessmentGroup?.baseData || {};
    const currentGroupId = baseData?.currentGroupId || CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;
    const currentGroupIndex = getIndexOfCurrentGroup(groups, currentGroupId);
    const currentSubGroupIndex = baseData?.currentSubGroupIndex || 0;

    const currentGroup = groups[currentGroupIndex];
    const subGroups = currentGroup?.subGroups || [];
    const currentSubGroup = subGroups[currentSubGroupIndex];
    const currentSubGroupQuestions = currentSubGroup?.questions;

    const groupIdsWillHaveSubmitBtn = [
        CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.key,
        CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key,
        CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key
    ]

    return (
        <>
            {(
                (subGroups && subGroups.length > 0) ||
                (currentGroup?.groupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key)
            ) && (
                <div
                    className={`kt-form__actions kt-mb-10 
                    ${
                        currentGroup?.groupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key &&
                        currentSubGroupQuestions && currentSubGroupQuestions.length === 0 && 
                        (!isMobile) 
                            ? 
                              '' 
                            : 'kt-mt-15'
                    }`}
                >
                    <div className="d-flex justify-content-center flex-grow-1">
                        {
                            !(
                                currentGroupIndex === 0 &&
                                (currentSubGroupIndex === 0 || subGroups.length === 0)
                            ) && (
                                <button
                                    className="button edit-assessment-but edit-assessment-but-pre-next edit-padding-right-4-rem"
                                    onClick={handleOnClickPrevBtn}
                                >
                                    <i className="fa fa-angle-left padding-right-4-rem"/>
                                    Previous
                                </button>
                            )
                        }

                        {(currentGroupIndex !== groups.length - 1) &&
                            (
                                <button
                                    className={`button edit-assessment-but edit-assessment-but-pre-next edit-padding-left-4-rem`}
                                    onClick={handleOnClickNextBtn}
                                >
                                    Next
                                    <i className="fa fa-angle-right padding-left-4-rem"/>
                                </button>
                            )
                        }
                    </div>
                    {groupIdsWillHaveSubmitBtn.includes(currentGroupId) && (
                        <button
                            className={`button edit-assessment-but edit-assessment-but-submit z-10`}
                            onClick={handleOnClickSubmit}
                        >
                            Submit
                        </button>
                    )}
                </div>
            )}
        </>
    )
}

export default EditAssessmentQuestionFooter;