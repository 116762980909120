import React, { useEffect } from "react";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useSelector } from "react-redux";
import { useCallbackContext } from "../../../contexts/CallbackContext";

const SweetAlertContainer = () => {
    const { isVisible, config } = useSelector(state => state.sweetAlert);
    const { getCallback, unregisterCallback } = useCallbackContext();

    useEffect(() => {
        const confirmKey = config.onConfirmKey;
        const cancelKey = config.onCancelKey;

        return () => {
            if (confirmKey) {
                unregisterCallback(confirmKey);
            }
            if (cancelKey) {
                unregisterCallback(cancelKey);
            }
        };
    }, []);

    const Saving = ({ text }) => (
        <><i className="fa fa-spinner fa-spin"/>&nbsp;&nbsp;{text || 'Saving'}</>
    );

    const handleConfirm = async () => {
        const confirmCallback = getCallback(config.onConfirmKey);
        if (confirmCallback) {
            await confirmCallback();
        }
    };

    const handleCancel = async () => {
        const cancelCallback = getCallback(config.onCancelKey);
        if (cancelCallback) {
            await cancelCallback();
        }
    };

    return (
        <>
            {isVisible && (
                <SweetAlert
                    show={isVisible}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                    title={config.title}
                    type={config.type}
                    closeOnClickOutside={config.closeOnOutsideClick}
                    customButtons={
                        <>
                            {config.showOkBtn && (
                                <button
                                    className="btn btn-primary btn-wide"
                                    onClick={handleConfirm}
                                >
                                    OK
                                </button>
                            )}
                            {
                                (config.showConfirmBtn && !config.showOkBtn) && (
                                    <button
                                        className={`btn ${config.confirmBtnCss} btn-wide kt-mr-5`}
                                        disabled={config.okLoading || config.noLoading}
                                        onClick={handleConfirm}
                                    >
                                        {config.okLoading
                                            ? <Saving text={config.textWhileProcessingConfirmBtn} />
                                            : config.confirmBtnText
                                        }
                                    </button>
                                )
                            }

                            {
                                (config.showCancelBtn && !config.showOkBtn) && (
                                    <button
                                        className={`btn ${config.cancelBtnCss} btn-wide kt-mr-5`}
                                        disabled={config.okLoading || config.noLoading}
                                        onClick={handleCancel}
                                    >
                                        {config.noLoading
                                            ? <Saving text={config.textWhileProcessingCancelBtn} />
                                            : config.cancelBtnText
                                        }
                                    </button>
                                )
                            }
                        </>
                    }>
                    {config.message}
                </SweetAlert>
            )}
        </>
    );
};

export default SweetAlertContainer;
