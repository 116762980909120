import { Link } from "react-router-dom";
import routes from "../../constants/Routes";
import React from "react";

const AssessmentNotFound = () => {
    return (
        <div className="alert-text kt-ml-5 kt-mr-5 kt-font-xl kt-font-bold  d-flex-center-center flex-direction-column min-w-90vw min-h-60vh">
            <h1 className="text-center">We apologize, but we were unable to find the assessment you are
                searching for.</h1>
            <Link to={`${routes.dashboard}`} className="text-center text-underline">Back to Dashboard</Link>
        </div>
    )
}

export default AssessmentNotFound;