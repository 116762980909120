import React, { Fragment } from "react";
import { getMarkup, shouldCheckboxOptionDisabled } from "../../../utils/Utils";
import Checkbox from "../form-element/CheckboxOption";
import { UncontrolledTooltip } from "reactstrap";

const CheckboxForEditAssessment = ({
                      isFocused,
                      label,
                      question,
                      checkedOption,
                      options,
                      onChange,
                      errorMessage,
                      cssStyle,
                      error,
                      clickOnThumbnail,
                      checkedOptionsIds,
                      disabled = false
                  }) => {
    const onChangeCheckbox = (
        e,
        answerId,
        answerText,
        index,
        title,
        description
    ) => {
        if(disabled) return;

        let isChecked = e.target.checked;

        onChange &&
        onChange({
            question: question,
            answerId: answerId,
            answerText: answerText,
            answerIndex: index,
            title: title,
            description: description,
            checked: !isChecked,
        });
    };

    const getFocusColor = () => {
        if (isFocused) {
            return { border: "1px solid #1176B4" };
        }
    };

    const isCheckedCurrentOption = (optionId) => {
        return checkedOptionsIds.includes(optionId);
    }


    return (
        <>
            {cssStyle === "row" && (
                <div className="form-group row" style={getFocusColor()}>
                    <label
                        id={`cc-question-${question.questionId}`}
                        className="col-3 col-form-label heading-primary"
                    >
                        {label}
                    </label>
                    <div className="col-9 col-form-label">
                        <div>
                            {options &&
                                options.length > 0 &&
                                options.map((option, index) => {


                                    return (
                                        <Checkbox.OptionItem
                                            key={option.id}
                                            isChecked={isCheckedCurrentOption(option.id)}
                                            onClick={(e) =>
                                                onChangeCheckbox(
                                                    e,
                                                    option.id,
                                                    option.name,
                                                    index,
                                                    option.title,
                                                    option.description
                                                )
                                            }
                                            label={option.name}
                                            className={`edit-assessment-button edit-assessment-checkbox${
                                                isCheckedCurrentOption(option?.id) ? ' selected-button' : ''
                                            }`}
                                            iconClassName="mr-3"
                                            disabled={shouldCheckboxOptionDisabled(question?.singleSelectAnswerId, question?.answerList, option.id)}
                                        />
                                    );
                                })}
                        </div>
                        {errorMessage && (
                            <div className="invalid-feedback d-block">
                                This field is required
                            </div>
                        )}
                        {error && error.questionId === question.questionId && (
                            <span className="form-text text-muted kt-font-danger">
                * {error.message}
              </span>
                        )}
                    </div>
                </div>
            )}
            {cssStyle === "column" && (
                <div className="form-group kt-mb-10 pl-2" style={getFocusColor()}>
                    <div className="d-flex justify-content-between">
                        <div>
                            <label
                                id={`cc-question-${question.questionId}`}
                                className="kt-font-lg"
                                dangerouslySetInnerHTML={getMarkup(label)}
                            />
                            <div className="kt-checkbox-inline">
                                {options &&
                                    options.length > 0 &&
                                    options.map((option, index) => {
                                        return (
                                            <Checkbox.OptionItem
                                                key={index}
                                                isChecked={isCheckedCurrentOption(option.id)}
                                                onClick={() => onChangeCheckbox(option)}
                                                label={option.name}
                                                className="kt-checkbox kt-checkbox--brand"
                                                iconClassName=""
                                                labelClassName=""
                                                id={`btn-${question.questionId}-${option.id}`}
                                                disabled={disabled}
                                            >
                                                {option.title && (
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target={`btn-${question.questionId}-${option.id}`}
                                                    >
                                                        <strong>{option.title}</strong>
                                                        <br />
                                                        {option.description}
                                                    </UncontrolledTooltip>
                                                )}
                                            </Checkbox.OptionItem>
                                        );
                                    })}

                            </div>
                        </div>
                        <div className="image-wrapper">
                            {question?.BodyLocationImageUrl &&(
                                <img style={{width:50,height:50,cursor:"pointer"}} src={question.BodyLocationImageUrl} alt="" onClick={()=>clickOnThumbnail(question?.BodyLocationImageUrl)}/>
                            )}
                        </div>
                    </div>

                    {errorMessage && (
                        <div className="invalid-feedback d-block">
                            This field is required
                        </div>
                    )}
                    {error && error.questionId === question.questionId && (
                        <span className="form-text text-muted kt-font-danger">
              * {error.message}
            </span>
                    )}
                </div>
            )}
        </>
    );
};

export default CheckboxForEditAssessment;