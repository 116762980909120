import React, { useEffect, useState } from "react";

import Popup from "../Popup";
import IconCloseButton from "../IconCloseButton";
import {getBgColorOfExerciseName, getMovements} from "./telemedicineUtils";
import MovementGuideline from "./MovementGuideline";

const MovementGuidelineAllModal = ({ isSelectedTrainingMovementGide, setIsSelectedTrainingMovementGide, bodyRegionList, biometricExercisesGuideline }) => {
    const [movements, setMovements] = useState([]);
    const [selectedMovement, setSelectedMovement] = useState({});

    useEffect(() => {
        const filteredMovement = getMovements(biometricExercisesGuideline, bodyRegionList);
        setMovements(filteredMovement);
        setSelectedMovement(filteredMovement[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Popup show={isSelectedTrainingMovementGide}>
                <div className="movement-guidelines-all w-100-percent">
                    <div className="d-flex justify-content-center align-items-center custom-header">
                        <div className="d-flex justify-content-center custom-header-title flex-grow-1">Movement Guideline</div>
                        <IconCloseButton closeHandler={() => setIsSelectedTrainingMovementGide((prevState) => !prevState)} />
                    </div>
                    <div className="">
                        <div className="row">
                            <div className="col-md-12 col-lg-7 movement-guidelines-all__exercice-name">
                                {movements.map((movement, index) => (
                                    <div key={index} className={`p-3 hover_bottom_border cursor-pointer ${getBgColorOfExerciseName(movement, selectedMovement)}`} onClick={() => setSelectedMovement(movement)}>
                                        {movement.ExerciseName}
                                    </div>
                                ))}
                            </div>
                            <div className="col-md-12 col-lg-5">
                                <MovementGuideline selectedExercise={selectedMovement}/>
                                {/*<img src={selectedMovement?.ExerciseMediaUrlPatient} alt={selectedMovement?.ExerciseName} />*/}
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        </>
    );
}

export default MovementGuidelineAllModal;
