import React, { Fragment } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { getMarkup } from "../../../utils/Utils";
import crossBtn from "../../../assets/img/utils/cross.svg";
import {toast} from "react-toastify";

const defaulfBorderColor = "#3BA7E2"
const RadioButton = ({ isFromCC = false, isFirstNotAnsweredQuestion = false, isFocused, label, question, checkedOption, options, onChange, cssStyle, errorMessage, error, onClickCrossBtn,  disabled = false}) => {

    const onChangeRadio = (e, answerId, answerText, index, title, description) => {
        if(disabled) {
            toast.error("Report already ready can not change the response");
            return;
        }

        e.preventDefault()
        onChange && onChange({
            question: question,
            answerId: answerId,
            answerText: answerText,
            answerIndex: index,
            title: title,
            description: description
        });
    }

    const toggleIsFromCC = () => {
        onClickCrossBtn && onClickCrossBtn();
    }

    const getFocusColor = () => {
        if (isFocused) {

            if(isFromCC && isFirstNotAnsweredQuestion) {
                return { boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", borderRadius: '5px', border: '2px solid #0075B6' }
            }
            return { border: '2px solid #0075B6', borderRadius: "8px", marginRight: "8px" }
        }
    }

    const getBgCol = (bgColor, isChecked) => {
        let backgroundCol = 'white';
        if(isChecked && bgColor) {
            backgroundCol = bgColor;
        } else if(isChecked) backgroundCol = "#3BA7E2";

        return backgroundCol;
    }

    const getBtnTextCol = (optionCol, isChecked) => {
        let textCol = ""; //If no option color is given then text color will be determined from parent element's class
        if(optionCol && isChecked) textCol = "#FFFFFF";
        else if(optionCol) textCol = optionCol;

        return textCol;
    }
    return (
        <>
            {cssStyle === 'row' && (
                <div className="form-group row" style={getFocusColor()}>
                    <label id={`cc-question-${question.questionId}`} className="col-4 col-form-label heading-primary">{label}</label>
                    <div className="col-8 col-form-label">
                        <div>
                            {options && options.length > 0 && (
                                options.map((option, index) =>
                                    <button
                                        className={`edit-assessment-button ${option.id === checkedOption ? 'selected-button' : ''} ${disabled ? ' pointer-none cursor-not-allowed ' : ""}`}
                                        key={index}
                                        onClick={(e) => onChangeRadio(e, option.id, option.name, index, option.title, option.description)}
                                        disabled={option.id === checkedOption ? false : disabled }
                                    >
                                        {option.name}
                                    </button>)
                            )}
                        </div>
                        {errorMessage && (<div className="invalid-feedback d-block">* This field is required</div>)}
                        {error && error?.questionId === question?.questionId && (<span className="form-text text-muted kt-font-danger">* {error?.message}</span>)}
                    </div>
                </div>
            )}
            {cssStyle === 'column' && (
                <div className={`form-group kt-mb-10 pl-2 ${isFirstNotAnsweredQuestion ? " p-4 " : ""}`} style={getFocusColor()}>
                    <div className="d-flex justify-content-between">
                        <label id={`cc-question-${question.questionId}`} className="col-form-label heading-primary" dangerouslySetInnerHTML={getMarkup(label)}/>
                        {isFirstNotAnsweredQuestion && (
                            <div className="ml-1 mt-3 cursor-pointer" onClick={toggleIsFromCC}>
                                <img className="cross-btn" src={crossBtn} alt="cross" />
                            </div>
                        )}
                    </div>

                    <div className="d-flex justify-content-between">
                        <div className="kt-radio-inline">
                            {options && options.length > 0 && (
                                options.map((option, index) =>
                                    <Fragment key={index}>
                                        <button id={`btn-${question.questionId}-${option.id}`}
                                                className={`edit-assessment-button ${option.id === checkedOption ? 'selected-button' : ''} ${disabled ? ' pointer-none cursor-not-allowed ' : ""}`}
                                                style={{
                                                    border: `2px solid ${option.color || defaulfBorderColor}`,
                                                    backgroundColor: getBgCol(option.color, option.id === checkedOption)
                                                }}
                                                disabled={option.id === checkedOption ? false : disabled }
                                                onClick={(e) => onChangeRadio(e, option.id, option.name, index, option.title, option.description)}>
                                            <label className="radio-btn-label" style={{ color: getBtnTextCol(option.color, option.id === checkedOption) }}>{option.name}</label>
                                        </button>
                                        {option?.title && (
                                            <UncontrolledTooltip
                                                placement="top"
                                                target={`btn-${question?.questionId}-${option?.id}`}>
                                                <strong>{option?.title}</strong><br/>
                                                {option?.description}
                                            </UncontrolledTooltip>
                                        )}
                                    </Fragment>)
                            )}
                        </div>
                    </div>

                    {errorMessage && (
                        <div className="invalid-feedback d-block">This field is required</div>
                    )}
                    {error && error?.questionId === question?.questionId && (
                        <span className="form-text text-muted kt-font-danger">* {error?.message}</span>
                    )}
                </div>
            )}
        </>
    )
}

export default RadioButton;
