import React from "react";
import CONSTANT from "../../constants/Constant";

const StepBar = (props) => {
    let { groups, isCompleted, isShowFullName = true, showName=true } = props
    groups = groups.filter(group => group.key !== CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key)

    const _getStyle = () => {
        if(isShowFullName) return "steps-width-normal";
        return "steps-width-my-assessment";
    }

    return(
        <ol className={`steps ${_getStyle()}`}>
            {groups.map(group =>
                <li key={group.key} className={`step ${isCompleted[group.key] ? "is-complete" : ""}`} data-step={group.name[0]}>
                    {showName ? `${isShowFullName ? group.name: group.midName}` : ""}
                </li>)}
        </ol>
    )
}

export default StepBar

