import React from "react";
import IconCloseButton from "../shared/IconCloseButton";

const EditAssessmentHeader = (props) => {
    const {region, invitationType, testId, handler, isReportAvailable = false, handleShouldShowCCQuestionConfirmDialog, ccQuestionRef, currentGroupId} = props
    return (
        <>
            <div className="edit-assessment-header-container">
                <div className="edit-assessment-header-info-container">
                    {testId && testId !== "" && <p><span className='edit-assessment-header-test-id'>Test ID: </span>{testId}</p>}
                    <div className='edit-assessment-header-region-invitation-container'>
                        {region && region !== "" && <p>Region: {region}</p>}
                        {(invitationType && invitationType !== "") && <p>Invitation type: {invitationType}</p>}
                    </div>
                </div>
                <div className="d-flex">

                    {(invitationType && invitationType === "Self") &&
                        <button
                            className="resume-bio-btn-padding movement-save-close bg-color-green"
                            onClick={handler}
                            disabled={isReportAvailable}
                        >
                            Resume Biometric
                        </button>
                    }
                    <div
                        onClick={() => handleShouldShowCCQuestionConfirmDialog(ccQuestionRef, currentGroupId)}>
                        <IconCloseButton />
                    </div>
                    {/*<Link to={`${routes.myAssessments}/false`}><IconCloseButton /></Link>*/}
                </div>
            </div>
            <div className='edit-assessment-header-less-1024-px'>
                {region && region !== "" && <p>Region: {region}</p>}
                {(invitationType && invitationType !== "") && <p>Type: {invitationType}</p>}
            </div>
        </>
    )
}

export default EditAssessmentHeader;