import CONSTANT from "../../../constants/Constant";
import { toast } from "react-toastify";
import { getObjForUpdatingAnswerAtRedux, getQuestionIdsOfOSWScore } from "../EditAssessmentUtils";
import {
    getOSWScore,
    getPScore,
    getResponseCount
} from "../../../features/editAssessment/editAssessmentFeatureService";
import { useDispatch, useSelector } from "react-redux";
import { setQuestionAnswer } from "../../../features/editAssessment/editAssessmentSlice";

const useOSWScore = () => {
    const dispatch = useDispatch();
    const editAssessmentGroup = useSelector(state => state.editAssessment);
    const groups = editAssessmentGroup?.groups || [];

    const baseData = editAssessmentGroup?.baseData || {};
    const currentGroupId = baseData?.currentGroupId || CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;

    const currentGroup = groups.find(group => group.groupId === currentGroupId);

    const handleUpdateOSWScore = (updatedQuestions, subGroupIndex) => {
        if(currentGroup.groupId !== CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key) {
            // console.error('No OSW question found.')
            return;
        }

        let subGroups = currentGroup?.subGroups || [];
        if(subGroups.length <= 0) {
            toast.error('No Subgroup Found');
            console.error('No Subgroup Found');
            return;
        }

        const questionIdsOfOSWScore = getQuestionIdsOfOSWScore(CONSTANT.OUTCOME_SCORE_QUESTIONS);
        const questionsExceptOSW = updatedQuestions.filter(question => !questionIdsOfOSWScore.includes(question.questionId));

        handleOSWScoreOfNeck(questionsExceptOSW, subGroupIndex);
        handleOSWScoreOfArms(questionsExceptOSW, subGroupIndex);
        handleOSWScoreOfBack(questionsExceptOSW, subGroupIndex);
        handleOSWScoreOfLegs(questionsExceptOSW, subGroupIndex);
    }

    const handleOSWScoreOfNeck = (updatedQuestions, subGroupIndex) => {
        if(updatedQuestions.length === 0 || subGroupIndex < 0) {
            console.error("Either questions is empty or invalid subgroup");
            return;
        }

        let header1fSelectedQuestion = currentGroup?.subGroups[subGroupIndex]?.header1 || "";
        if(header1fSelectedQuestion.toLowerCase() !== CONSTANT.BODY_REGIONS.NECK.name.toLowerCase()) {
            return;
        }

        const questionIdOfPScoreAtNeck = CONSTANT.OUTCOME_SCORE_QUESTIONS.Neck.PScore;
        const questionIdOfOSWScoreAtNeck = CONSTANT.OUTCOME_SCORE_QUESTIONS.Neck.OSWScore;

        const pScore = getPScore(updatedQuestions);
        const OSWScore = getOSWScore(updatedQuestions);

        handleUpdateAnswerOnRedux(questionIdOfPScoreAtNeck, "", pScore, subGroupIndex);
        handleUpdateAnswerOnRedux(questionIdOfOSWScoreAtNeck, "", OSWScore + "%", subGroupIndex);
    }

    const handleOSWScoreOfArms = (updatedQuestions, subGroupIndex) => {
        if(updatedQuestions.length === 0 || subGroupIndex < 0) {
            console.error("Either questions is empty or invalid subgroup");
            return;
        }

        let header1fSelectedQuestion = currentGroup?.subGroups[subGroupIndex]?.header1 || "";
        if(header1fSelectedQuestion.toLowerCase() !== CONSTANT.BODY_REGIONS.ARMS.name.toLowerCase()) {
            return;
        }

        const questionIdOfNoneCountAtArms = CONSTANT.OUTCOME_SCORE_QUESTIONS.Arms.None;
        const questionIdOfMidCountAtArms = CONSTANT.OUTCOME_SCORE_QUESTIONS.Arms.Mild;
        const questionIdOfModerateCountAtArms = CONSTANT.OUTCOME_SCORE_QUESTIONS.Arms.Moderate;
        const questionIdOfSevereCountAtArms = CONSTANT.OUTCOME_SCORE_QUESTIONS.Arms.Severe;
        const questionIdOfExtremeCountAtArms = CONSTANT.OUTCOME_SCORE_QUESTIONS.Arms.Extreme;
        const questionIdOfPScoreAtArms = CONSTANT.OUTCOME_SCORE_QUESTIONS.Arms.Score;


        const extremeAnsweredQuestionCount = getResponseCount(updatedQuestions, CONSTANT.ARMS_ASSESSMENT_RESPONSE_TYPES[0]);
        const mildAnsweredQuestionCount = getResponseCount(updatedQuestions, CONSTANT.ARMS_ASSESSMENT_RESPONSE_TYPES[1]);
        const moderateAnsweredQuestionCount = getResponseCount(updatedQuestions, CONSTANT.ARMS_ASSESSMENT_RESPONSE_TYPES[2]);
        const noneAnsweredQuestionCount = getResponseCount(updatedQuestions, CONSTANT.ARMS_ASSESSMENT_RESPONSE_TYPES[3]);
        const serveAnsweredQuestionCount = getResponseCount(updatedQuestions, CONSTANT.ARMS_ASSESSMENT_RESPONSE_TYPES[4]);

        const pScore = getPScore(updatedQuestions);

        handleUpdateAnswerOnRedux(questionIdOfExtremeCountAtArms, "", extremeAnsweredQuestionCount, subGroupIndex);
        handleUpdateAnswerOnRedux(questionIdOfMidCountAtArms, "", mildAnsweredQuestionCount, subGroupIndex);
        handleUpdateAnswerOnRedux(questionIdOfModerateCountAtArms, "", moderateAnsweredQuestionCount, subGroupIndex);
        handleUpdateAnswerOnRedux(questionIdOfNoneCountAtArms, "", noneAnsweredQuestionCount, subGroupIndex);
        handleUpdateAnswerOnRedux(questionIdOfSevereCountAtArms, "", serveAnsweredQuestionCount, subGroupIndex);
        handleUpdateAnswerOnRedux(questionIdOfPScoreAtArms, "", pScore, subGroupIndex);
    }

    const handleOSWScoreOfBack = (updatedQuestions, subGroupIndex) => {
        if(updatedQuestions.length === 0 || subGroupIndex < 0) {
            console.error("Either questions is empty or invalid subgroup");
            return;
        }

        let header1fSelectedQuestion = currentGroup?.subGroups[subGroupIndex]?.header1 || "";
        if(header1fSelectedQuestion.toLowerCase() !== CONSTANT.BODY_REGIONS.BACK.name.toLowerCase()) {
            return;
        }

        const questionIdOfOSWScoreAtBack = CONSTANT.OUTCOME_SCORE_QUESTIONS.Back.OSWScore;
        const questionIdOfPScoreAtBack = CONSTANT.OUTCOME_SCORE_QUESTIONS.Back.PScore;

        const pScore = getPScore(updatedQuestions);
        const OSWScore = getOSWScore(updatedQuestions);

        handleUpdateAnswerOnRedux(questionIdOfPScoreAtBack, "", pScore, subGroupIndex);
        handleUpdateAnswerOnRedux(questionIdOfOSWScoreAtBack, "", OSWScore + "%", subGroupIndex);
    }

    const handleOSWScoreOfLegs = (updatedQuestions, subGroupIndex) => {
        if(updatedQuestions.length === 0 || subGroupIndex < 0) {
            console.error("Either questions is empty or invalid subgroup");
            return;
        }

        let header1fSelectedQuestion = currentGroup?.subGroups[subGroupIndex]?.header1 || "";
        if(header1fSelectedQuestion.toLowerCase() !== CONSTANT.BODY_REGIONS.LEGS.name.toLowerCase()) {
            return;
        }

        const questionIdOfPScoreAtLegs = CONSTANT.OUTCOME_SCORE_QUESTIONS.Legs.Score;

        const pScore = getPScore(updatedQuestions);

        handleUpdateAnswerOnRedux(questionIdOfPScoreAtLegs, "", pScore, subGroupIndex);
    }

    const handleUpdateAnswerOnRedux = (questionId, responseId, responseValue, tempSubGroupIndex) => {
        const answerObjForSetQuestionAnswer = getObjForUpdatingAnswerAtRedux(questionId, responseId, responseValue, tempSubGroupIndex);

        dispatch(setQuestionAnswer(answerObjForSetQuestionAnswer));
        return answerObjForSetQuestionAnswer;
    }

    return {
        handleUpdateOSWScore
    }
}

export default useOSWScore;