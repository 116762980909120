import axios from './Axios';
import { error, success } from "./ResponseHandler";
import GLOBAL from "../global";
import CONSTANT from "../constants/Constant";

const RESPONSE_TYPE = { responseType: 'text' };
const { REACT_APP_BASE_API_URL } = process.env;
const PAIN_LOG_URL = REACT_APP_BASE_API_URL + "/api/painlog/Post";
const QUESTION_URL = REACT_APP_BASE_API_URL + "/api/question/GetQuestions";
const OUTCOME_QUESTION_URL = REACT_APP_BASE_API_URL + "/api/question/GetOutcomeQuestions";
const FIND_DOCTORS_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.BASE_CONTROLLER}/FindDoctors`;
const SEND_EMAIL_URL = REACT_APP_BASE_API_URL + "/api/EventScheduler";
const DOCTOR_AVAILABLE_URL = REACT_APP_BASE_API_URL + "/api/EventScheduler/IsAvailable";
const ALL_PAINLOGS_URL = REACT_APP_BASE_API_URL + "/api/Painlog/GetPainlogs";
const CREATE_PAINLOGS_URL = REACT_APP_BASE_API_URL + "/api/Painlog/Create";

class PainlogService {
    async log(payload = {}) {
        try {
            payload.Tenant = GLOBAL.TENANT;
            const response = await axios.post(PAIN_LOG_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getQuestions(payload = {}) {
        try {
            payload.Tenant = GLOBAL.TENANT;
            const response = await axios.post(QUESTION_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getOutcomeQuestions(payload = {}) {
        try {
            payload.Tenant = GLOBAL.TENANT;
            const response = await axios.post(OUTCOME_QUESTION_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async findDoctors(params = {}) {
        try {
            const payload = {};
            payload.Limit = 10;
            payload.Page = 10;
            payload.Params = params;

            const response = await axios.post(FIND_DOCTORS_URL, payload, RESPONSE_TYPE);
            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async sendEmail(payload = {}) {
        try {
            const response = await axios.post(SEND_EMAIL_URL, payload, RESPONSE_TYPE);

            // if (!response["Successful"]) {
            //     return error(response);
            // }
            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async isAvailable(payload = {}) {
        try {
            payload.Tenant = GLOBAL.TENANT;
            const response = await axios.post(DOCTOR_AVAILABLE_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getPainlogs(payload = {}) {
        try {
            const response = await axios.post(ALL_PAINLOGS_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async createPainlog(payload = {}) {
        try {
            const response = await axios.post(CREATE_PAINLOGS_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }
}

export default new PainlogService();
