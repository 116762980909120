import "./EditAssessment.scss";
import React, { Fragment, useContext, useEffect, useRef, useState, useMemo } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal, ModalHeader } from "reactstrap";
import scrollIntoView from "scroll-into-view-if-needed";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { isValid } from "date-fns";
import { isMobile } from "react-device-detect"

import GLOBAL from "../../global";
import CONSTANT from "../../constants/Constant";
import routes from "../../constants/Routes";

import {
    getBodyRegionNames,
    _getWaitingRoomByInvitationInfo,
    checkClientValidation,
    formatDate,
    getPatientPortalType, getMarkup,
} from "../../utils/Utils";

import examService from "../../services/ExamService";
import Assessment from "../../models/Assessment";
import Chat from "../../models/Chat";
import InputBox from "../shared/form-element/InputBox";
import Checkbox from "../shared/form-element/Checkbox";
import RadioButton from "../shared/form-element/RadioButton";
import { bodyPart } from "../../utils/BodyPart";
// import { postureQuestions } from "./posture-questions"
import MaskInput from "../shared/form-element/MaskInput";
import PainRate from "../shared/PainRate";
import ChiefComplaint from "../shared/chiefComplaintNew/ChiefComplaint"
// import MovementModal from "../shared/MovementModal/MovementModal";
import IntakeForm from "../shared/intake-form/IntakeForm";
import AppContext from "../../contexts/AppContext";
import PrivacyPolicy from "../shared/intake-form/PrivacyPolicy";
import Mmh_terms_and_service from "../../assets/docs/mmh_terms_of_service.pdf";
import DisplayUrlContent from "../shared/DisplayUrlContent";
// import TakeBiometricMovements from "../shared/TakeBiometricMovements";
import StepBar from "./StepBar";
import CloseButton from "../../assets/img/utils/cross-btn.svg"
import Info from "../../assets/img/utils/info.svg"
import EditAssessmentHeader from "./EditAssessmentHeader";
import crossBtn from "../../assets/img/utils/cross.svg";
// import Telemedicine from "../shared/Telemedicine/Telemedicine";
import BiometricMovementAndCautionQuestionContainer
    from "../shared/MovementModal/BiometricMovementAndCautionQuestionContainer";
import ExamService from "../../services/ExamService";
import DropdownItem from "../shared/form-element/DropdownItem";
import {saveDemoInfoAtLocalStorage} from "../../services/LocalStorageService";
import useEditAssessmentService from "./useEditAssessmentService";
import {saveNoCC_Complaint} from "../shared/chatbotNew/ChatServices";
import {toast} from "react-toastify";

const EditAssessmentDetails = (props) => {
    const search = useLocation().search;
    const history = useHistory();
    const tabId=new URLSearchParams(search).get("tab");
    const invitationIdFromUrl = new URLSearchParams(search).get("w");
    const { storeUserInfoToLocalStorage, userInfo, setTelemedicineProperties, resetTelemedicineKeys, assessmentDataForEditAssessment, resetSelectedTabForEditAssessment } = useContext(AppContext);
    const { OutcomeAssessmentEnabled } = userInfo.Permissions;
    const { testId, bodyRegionList, WaitingRoomIdForTele } = props;
    // const [selectedTab, setSelectedTab] = useState(GLOBAL.SELECTED_ASSESSMENT_TYPE);
    const [selectedTab, setSelectedTab] = useState(assessmentDataForEditAssessment?.selectedTab ? assessmentDataForEditAssessment?.selectedTab : CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key);
    const isIntakeSigned = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['IsIntakeSigned']) || '';
    const providerEmail = CONSTANT.PROVIDER_EMAIL;
    const joinNowText = "JOIN NOW";
    const joinLaterText = "JOIN LATER";
    const subscription = useRef(true);
    const [currentGroupId, setCurrentGroupId] = useState(CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key);
    const [currentGroupViewOrderId, setCurrentGroupViewOrderId] = useState(CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.viewOrder);
    const [groupQuestions, setGroupQuestions] = useState([]);
    const [subGroupQuestions, setSubGroupQuestions] = useState([]);
    const [allAnswers, setAllAnswers] = useState([]);
    const [, setForceUpdate] = useState(0);
    const [groups, setGroups] = useState([]);
    const [chat, setChat] = useState(new Chat());
    const options = { name: CONSTANT.CHAT_BOTS.EDIT_ASSESSMENT }
    const [isSaveAssessments, setIsSaveAssessments] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [formErrors, setFormErrors] = useState([]);
    const [isBodyPartSelected, setIsBodyPartSelected] = useState(false);
    const [subBodyRegions, setSubBodyRegions] = useState([]);
    const [loadingQuestions, setLoadingQuestions] = useState(false);
    const [subGroups, setSubGroups] = useState([]);
    const [subGroupId, setSubGroupId] = useState(0);
    const [nextQuestionOrderId, setNextQuestionOrderId] = useState(0);
    const [bodyLocation, setBodyLocation] = useState('');
    const [previousResponse, setPreviousResponse] = useState({});
    const [pageCaptions, setPageCaptions] = useState([]);
    const [pageCaptionId, setPageCaptionId] = useState(0);
    const [outcomeAssessmentBodyRegionKey, setOutcomeAssessmentBodyRegionKey] = useState(props.bodyRegionList);
    const [allQuestions, setAllQuestions] = useState([]);
    const [pageHeaderInfo, setPageHeaderInfo] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [groupTitle, setGroupTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [alertType, setAlertType] = useState(CONSTANT.ALERT_TYPE.INFO);
    const [redirectToMyAssessments, setRedirectToMyAssessments] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [showIntakeForm, setShowIntakeForm] = useState(false);
    const [joinMeetingRoomUrl, setJoinJoinMeetingRoomUrl] = useState('');
    const [invitationId, setInvitationId] = useState('');
    const [showPolicy, setShowPolicy] = useState(false);
    const [termsAndConditionModal, setTermsAndConditionModal] = useState(false);
    const [bodyRegionLists, setBodyRegionLists] = useState('');
    const previousRegion = useRef('');
    const debounceTimerRef  = useRef(null);
    const [outcomeAssessmentQuestionsId, setOutcomeAssessmentQuestionsId] = useState([]);
    //tabCount is the header1 count such as Personal Health History, Family History, Medications, Surgery, Exercise
    //for posture only have Personal Health History, Exercise
    const [tabCount, setTabCount] = useState(0);
    const [medicalHistorySection, setMedicalHistorySection] = useState([]);
    const [takeBiometricMovementModal, setTakeBiometricMovementModal] = useState(false);
    const [isOpening, setIsOpening] = useState(false);
    const [rowValue, setRowValue] = useState(null)
    const [viewImage , setViewImage] = useState("")
    const [isCompleted, setIsCompleted] = useState([]);
    const [assessmentNotFound, setAssessmentNotFound] = useState(false);
    const [showInstruction, setShowInstruction] = useState(false)
    const [joiningTelemedicine, setJoiningTelemedicine] = useState(false);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [openBiometricMovementAndCautionQuestionContainer, setOpenBiometricMovementAndCautionQuestionContainer] = useState(false);
    const selectedExamRef = useRef(null);
    const isReportAvailableRef = useRef(false);
    const ccQuestionRef = useRef({});
    const doesHaveOswestryQuestionRef = useRef(false);
    const waitingRoomIdRef = useRef('');

    const _updateAnswer = (question, answerId, answerText, answerIndex, checked) => {
        if (allAnswers.length > 0) {
            let index = -1;

            if (question?.responseType === CONSTANT.ELEMENT_TYPES.CHECKBOX) {
                index = allAnswers.findIndex((answer) => answer.QuestionId === question.questionId && answer.AnswerId === answerId);
            } else {
                index = allAnswers.findIndex((answer) => answer.QuestionId === question.questionId);
            }

            if (index > -1) {
                if (!answerId && !answerText) {
                    allAnswers.splice(index, 1);

                } else if (!checked && question.responseType === CONSTANT.ELEMENT_TYPES.CHECKBOX) {
                    allAnswers.splice(index, 1);

                } else {
                    allAnswers[index].AnswerId = (answerId && answerId) || '';
                    allAnswers[index].AnswerValue = (answerText && answerText) || '';
                    allAnswers[index].AnswerTitle = (answerText && answerText) || '';
                    allAnswers[index].BodyRegionList = question.bodyRegionList;
                    allAnswers[index].Index = answerIndex;
                }
            }
        }
    };

    const showCCQuestions = async (isProcessAfterSaveMovement = false, closeEditAssessmentPage = false) => {
        setAlertTitle('Question');
        if(doesCCQuestionAnswered(ccQuestionRef)) {
            setAlertMessage('Do you have any other areas of pain?');
            setCustomButtons([
                getButton('No', () => _processAfterSaveAnswers(), 'btn-danger', true)
                , getButton('Yes', () => onExecuteScroll(0), 'btn-success')
            ]);
        } else {
            setAlertMessage(`If you are experiencing acute or chronic pain, you can point to the specific area of discomfort on the skeleton illustration provided. If you don't have any complaints, please proceed by clicking on the "No" button.`);
            setCustomButtons([
                getButton('No', () => handleNoCC(isProcessAfterSaveMovement, closeEditAssessmentPage), 'btn-danger', true, isSaveAssessments)
                , getButton('Yes', () => onExecuteScroll(0), 'btn-success')
            ]);

        }
    }

    const {
        isSavingNoCC,
        handleShouldShowCCQuestionConfirmDialog,
        doesHaveOswestryQuestion,
        setIsSavingNoCC,
        doesCCQuestionAnswered
    } = useEditAssessmentService(testId, setRedirectToMyAssessments, _updateAnswer, allAnswers, showCCQuestions);

    const MemoizeChiefComplaint = useMemo(() => <ChiefComplaint
      testId={testId}
      isReportAvailable={isReportAvailableRef.current}
      ccQuestion={ccQuestionRef.current}
      onChange={onChangeCollectResponse}
      />
      [isReportAvailableRef.current, ccQuestionRef.current]
    )

    const _divideQuestionOfMedicalHistory = (questions, subTab) => {
        const unknown = [];
        const subGroups = _getSubGroups(questions, CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.key);
        let arrays = {};
        subGroups.forEach(subGroup => {
            arrays[subGroup] = [];
        });

        questions.forEach((question) => {
            if (subGroups.length >= 1 && (question.header1 === subGroups[0] || question.header2 === subGroups[0])) {
                arrays[subGroups[0]].push(question);
            } else if (subGroups.length >= 2 && (question.header1 === subGroups[1] || question.header2 === subGroups[1])) {
                arrays[subGroups[1]].push(question);
            } else if (subGroups.length >= 3 && (question.header1 === subGroups[2] || question.header2 === subGroups[2])) {
                arrays[subGroups[2]].push(question);
            } else if (subGroups.length >= 4 && (question.header1 === subGroups[3] || question.header2 === subGroups[3])) {
                arrays[subGroups[3]].push(question);
            } else if (subGroups.length >= 5 && (question.header1 === subGroups[4] || question.header2 === subGroups[4])) {
                arrays[subGroups[4]].push(question);
            } else {
                unknown.push(question);
            }
        });

        let array = [];
        subGroups.forEach(subGroup => {
            array.push(arrays[subGroup])
        });
        array.push(unknown)
        setMedicalHistorySection(array);
        const subTabMaping = {
            Personal_Health_History: 0,
            Family_History: 1,
            Medications: 2,
            Surgery: 3,
            Exercise: 4
        }

        if (subTab === subTabMaping["Personal_Health_History"]) return subGroups.length > 0 ? arrays[subGroups[0]] : [];
        else if (subTab === subTabMaping["Family_History"]) return subGroups.length > 1 ? arrays[subGroups[1]] : [];
        else if (subTab === subTabMaping["Medications"]) return subGroups.length > 2 ? arrays[subGroups[2]] : [];
        else if (subTab === subTabMaping["Surgery"]) return subGroups.length > 3 ? arrays[subGroups[3]] : [];
        else if (subTab === subTabMaping["Exercise"]) return subGroups.length > 4 ? arrays[subGroups[4]] : [];
        else return unknown;
    };

    let onConfirmAlert = () => {
    };
    const [onConfirmAlertFunction, setOnConfirmAlertFunction] = useState(() => () => {
    });
    // let redirectUrl = getPatientPortalType(GLOBAL.TENANT_FEATURES) === CONSTANT.PATIENT_PORTAL_TYPE.Consumer.name ?
    //     `${routes.myAssessments}/${false}` : routes.myMedicalHistory;

    let redirectUrl = `${routes.myAssessments}/${false}`;
    const [openMovementModal, setOpenMovementModal] = useState(false);
    const [customButtons, setCustomButtons] = useState([]);
    let telemedicineInvitationInfoAPICallCount = 0;

    useEffect(() => {
        if(tabId !== null) {
            selectSpecficTabIfFromHome()
        }

        const groups = _getGroups();
        const regionName = getBodyRegionNames(bodyRegionList);
        const group = groups.find((item) => item.key === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key);

        if (group) {
            group.name = regionName;
            if(regionName === CONSTANT.BODY_REGIONS.POSTURE.name) {
                const index = groups.findIndex(group => group.name === CONSTANT.BODY_REGIONS.POSTURE.name);
                index > -1 && groups.splice(index, 1);
            }
        }

        if (regionName && regionName.length > 0) {
            previousRegion.current = regionName.split(',')[0];
        }

        setBodyRegionLists(regionName);
        if (!OutcomeAssessmentEnabled) {
            const index = groups.findIndex(group => group.key === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key);
            index > -1 && groups.splice(index, 1);
        }

        setGroups(groups);
        const updatedChat = { ...chat, mode: false, bodyParts: bodyPart }
        setChat(updatedChat);

        let questionIds = [];
        const propertyNames = Object.keys(CONSTANT.OUTCOME_SCORE_QUESTIONS);
        propertyNames.forEach(name => {
            const singleRegionQuestionIds = Object.values(CONSTANT.OUTCOME_SCORE_QUESTIONS[name]);
            questionIds = [...questionIds, ...singleRegionQuestionIds];
        })

        setOutcomeAssessmentQuestionsId(questionIds);

        if (subscription.current && testId) {
            const fetchQuestionResponsesPageHeaderInfo = async () => {
                let {
                    questions,
                    savedResponses,
                    pageHeaderInfo,
                    isCompleted
                } = await _getQuestionsPreviousResponsesPageHeaderInfo();
                if(bodyRegionList.includes(CONSTANT.BODY_REGIONS.POSTURE.key)) {
                    // questions = questions.filter(question => question.typeName !== CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.name);
                    // questions = [...questions, ...postureQuestions];

                    questions = questions.filter(question => question.bodyRegionList.includes(CONSTANT.BODY_REGIONS.POSTURE.key));
                    questions = [...questions ];
                }
                doesHaveOswestryQuestionRef.current = doesHaveOswestryQuestion(questions);
                if(!doesHaveOswestryQuestionRef.current) {
                    const tempGroups = removeOSWGroup(groups);
                    setGroups(tempGroups);
                }

                setIsCompleted(isCompleted)
                let subBodyRegions = _getSubBodyRegions(savedResponses);
                const portalDisplayQuestions = _getPortalDisplayQuestions(questions);
                _setIsScoreQuestionFieldInQuestions(portalDisplayQuestions);
                const answers = _getSavedResponse(savedResponses, portalDisplayQuestions);
                getScoreAnswers(answers);

                setAllQuestions(portalDisplayQuestions);
                setPageHeaderInfo(pageHeaderInfo);
                setAllAnswers([...answers, { QuestionId: ccQuestionRef.current.questionId, AnswerId: ccQuestionRef.current.AnswerId, AnswerValue: ccQuestionRef.current.AnswerValue }]);
                setSubBodyRegions(subBodyRegions);

                !selectedTab && await _fetchAndPrepareChiefComplaintOrGroupQuestionsForView({
                    questions: portalDisplayQuestions,
                    groupId: tabId !== null ? parseInt(tabId) : currentGroupId,
                    subGroupId: subGroupId
                });
            }

            fetchQuestionResponsesPageHeaderInfo();
        }

        resetSelectedTabForEditAssessment();

        return () => {
            subscription.current = false;
            setAllQuestions([]);
            setAllAnswers([]);
            GLOBAL.ASSESSMENT = null;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps,
    }, []);

    useEffect(() => {
        if (selectedTab && allQuestions.length > 0 && allAnswers.length > 0) {
            let tempSelectedTab = selectedTab;
            if(tempSelectedTab === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key) {
                tempSelectedTab = CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;
            }
            const group = _getSelectedTabsGroup(tempSelectedTab);
            setSelectedTab('');
            onClickChangePage({ group: group });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps,
    }, [selectedTab, allQuestions, allAnswers, subBodyRegions])

    useEffect(() => {
        setTimeout(() => setForceUpdate(Date.now()), 2000);
    }, [subGroupQuestions, chat])

    useEffect(() => {
        const pageHeader = pageHeaderInfo.find((item) => item.GroupId === currentGroupId);
        let groupTitle = currentGroupId !== CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key ? (pageHeader ? pageHeader['Tittle'] : '') : '';
        setGroupTitle(groupTitle);

    }, [currentGroupId, pageHeaderInfo])

    const selectSpecficTabIfFromHome = () => {
        let tabGroup;
        for (const [, value] of Object.entries(CONSTANT.ASSESSMENT_GROUP)) {
            if (value.key === parseInt(tabId)) {
                tabGroup = value;
            }
        }

        setTimeout(() => {
            onClickChangePage({ group: tabGroup })
        }, 1000)
    }

    const showInstructionHeader = () => {
        setShowInstruction(true)
    }

    const _getGroups = () => {
        let groups = [];
        for (const [, value] of Object.entries(CONSTANT.ASSESSMENT_GROUP)) {
            if (value.key !== CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key) {
                groups.push(value);
            }
        }

        if (bodyRegionList.length === 1 && bodyRegionList.includes(CONSTANT.BODY_REGIONS.POSTURE.key)) {
            // for (let i = groups.length - 1; i >= 0; i--) {
            //     if (groups[i].key === CONSTANT.ASSESSMENT_GROUP.ADL.key) {
            //         groups.splice(i, 1);
            //     }
            // }
        }

        return groups;
    }

    const removeOSWGroup = (group) => {
        let tempGroup = [...group];

        if (bodyRegionList.length > 0 && !doesHaveOswestryQuestionRef.current) {
            for (let i = tempGroup.length - 1; i >= 0; i--) {
                if (tempGroup[i].key === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key) {
                    tempGroup.splice(i, 1);
                }
            }
        }

        return tempGroup;
    }

    const _getSelectedTabsGroup = (selectedTab) => {
        const groups = _getGroups();
        return groups.find((item) => item.key === selectedTab);
    }

    const _getPortalDisplayQuestions = (questions) => {
        return questions.filter((question) => question?.['isDisplayOnPortal']);
    }

    const _setIsScoreQuestionFieldInQuestions = (questions) => {
        const scoreQuestionsId = _getScoreQuestionsId();
        questions.forEach((question) => {
            question.isScoreQuestion = scoreQuestionsId.includes(question.questionId);
        })
    }

    const _getScoreQuestionsId = () => {
        let bodyRegions = [];
        for (const [, value] of Object.entries(CONSTANT.BODY_REGIONS)) {
            bodyRegions.push(value);
        }

        let scoreQuestions = [];
        bodyRegions.forEach((region) => {
            if (CONSTANT.OUTCOME_SCORE_QUESTIONS?.[region.name]) {
                for (const [, id] of Object.entries(CONSTANT.OUTCOME_SCORE_QUESTIONS?.[region.name])) {
                    scoreQuestions.push(id);
                }
            }
        });

        return scoreQuestions;
    }

    const _getSavedResponse = (savedResponses, questions) => {
        let answers = [];
        const scoreQuestionsId = _getScoreQuestionsId();
        if (savedResponses && savedResponses?.length > 0) {
            savedResponses.forEach((value) => {
                let question = questions.find((question) => question.questionId === value.QuestionId);
                if (!question) {
                    question = {
                        questionId: value.QuestionId,
                        bodyRegionList: value.BodyRegionList,
                        isScoreQuestion: false
                    }
                }
                const isScoreQuestion = scoreQuestionsId.includes(question?.questionId);
                const answer = _prepareAnswer(question, value.AnswerId, value.AnswerValue, value['ViewOrder'], value.GroupId, isScoreQuestion);

                if (value.QuestionId !== CONSTANT.ASSESSMENT_QUESTIONS.OUTCOME_BODY_REGION_SELECTION) {
                    answers.push(answer);
                }

                if (question?.['hasConditionalQuestion']) {
                    _enableAndGetConditionalQuestionsIfExists(questions, savedResponses, question, value.AnswerId);
                }
            });
        }

        return answers;
    }

    const fetchData = async (testId, groupList, bodyPart) => {
        setLoadingQuestions(true);
        const payload = {
            TestId: testId,
            GroupIdList: groupList,
            BodyLocation: bodyPart,
        };

        const response = await examService.getPreviousResponsesByTestId(payload);
        const { data, success, error } = response;
        setLoadingQuestions(false);

        if (!success) {
            console.error(error);
            if(error === 'Exam is not found.') {
                setAssessmentNotFound(true);
            }
            return;
        }

        isReportAvailableRef.current = data?.IsReportReady ? data?.IsReportReady : false;
        ccQuestionRef.current = data?.ChiefComplaintsQuestionStatus || {};
        ccQuestionRef.current = {
            ...ccQuestionRef.current,
            questionId: ccQuestionRef.current?.QuestionId
        }
        return data || '{}'
    }

    const _removeCCQuestionsOfBodyLocationIfNotAnsweredAny = (bodyLocation) => {
        let isAnsweredAnyQuestion = false;
        const questionIdsOfAnswers = allAnswers.map((item) => item.QuestionId);
        allQuestions.forEach((item) => {
            if (item.bodyLocation === bodyLocation) {
                if (questionIdsOfAnswers.includes(item.questionId)) {
                    isAnsweredAnyQuestion = true;
                }
            }
        });

        if (!isAnsweredAnyQuestion) {
            const questions = allQuestions.filter((item) => item?.bodyLocation !== bodyLocation);
            setAllQuestions(questions);
        }
    }

    const _fetchAndPrepareChiefComplaintOrGroupQuestionsForView = async (
        {
            questions,
            groupId,
            subGroupId,
            bodyPart
        }) => {
        let groupQuestions;

        if (bodyPart) {
            const responseData = await fetchData(testId, [CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key], bodyPart)
            groupQuestions = responseData['Questions'] || [];

            const questions = []
            groupQuestions.forEach((item) => {
                const question = allQuestions.find((question) => question.questionId === item.questionId)
                if (!question) {
                    item.bodyLocation = bodyPart;
                    questions.push(item);
                }
            });

            setAllQuestions((prev) => [...prev, ...questions]);

        } else {
            groupQuestions = _filterQuestionByGroup(questions, groupId);
        }
        await _prepareQuestionsForView(groupQuestions || [], groupId, subGroupId);
    };

    const _filterQuestionByGroup = (questions, groupId) => {
        return questions.filter((question) => question['typeId'] === groupId);
    }

    const _prepareQuestionsForView = (questions, groupId, subGroupId) => {
        if (questions?.length > 0) {
            setGroupQuestions(questions);
            const subGroups = _getSubGroups(questions, groupId);
            if (groupId === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key && questions[0].questionId === CONSTANT.ASSESSMENT_QUESTIONS.OUTCOME_BODY_REGION_SELECTION) {
                subGroups.push("");
            }
            const pageCaptionsOfSubGroup = _getPageCaptionsOfSubGroup(questions, subGroups[subGroupId], groupId);

            setSubGroups(subGroups);
            setPageCaptions(pageCaptionsOfSubGroup);

            if (groupId !== CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) {
                _filterQuestionsBySubGroupPageCaptionCondition(subGroups[subGroupId], pageCaptionsOfSubGroup[0], questions);
            } else {
                let index = 0;
                questions.forEach((item) => {
                    item.orderId = index++;
                });
                setSubGroupQuestions(questions);
                let maxOrderId = Number.MAX_VALUE;
                let questionToFocus = null;

                if (questions && questions.length > 0) {
                    questions.forEach(question => {
                        if (question.orderId < maxOrderId && !question.isDefaultHide) {
                            maxOrderId = question.orderId;
                            questionToFocus = question;
                        }
                    });
                }

                if (questionToFocus) {
                    onExecuteScroll(questionToFocus.questionId, true);
                }
            }

        } else {
            setSubGroups([]);
            setSubGroupQuestions([]);
        }
    }

    const _getSubGroups = (questions, groupId) => {
        let header = 'header1';
        if (groupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) {
            header = 'header2';
        }

        return questions.map((question) => question[header]).filter((value, index, self) => self.indexOf(value) === index);
    }

    const _getPageCaptionsOfSubGroup = (questions, subGroup, groupId) => {
        let header = 'header1';
        if (groupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) {
            header = 'header2';
        }

        const subGroupQuestions = questions.filter((item) => item[header] === subGroup);
        return subGroupQuestions.map((question) => question['pageCaption']).filter((value, index, self) => self.indexOf(value) === index);
    }

    const _getQuestionsPreviousResponsesPageHeaderInfo = async () => {
        setLoadingData(true);
        const responseData = await fetchData(testId,
            [CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key
                , CONSTANT.ASSESSMENT_GROUP.ADL.key
                , CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.key
                , CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key], "");
        setLoadingData(false);

        setPreviousResponse(responseData || {});
        const allQuestions = responseData?.['Questions'] || [];
        let allResponses = responseData?.['ExamResponse'] || [];
        const pageHeaderInfo = responseData?.['PageHeaderInformation'] || [];
        const chiefComplaintVas = responseData?.['ChiefComplaintVas'] || [];
        const isCompleted = [false, responseData?.["DemographicsCompleted"] || false, responseData?.["MedicalHistoryCompleted"] || false, responseData?.["ADLCompleted"] || false,
            responseData?.["ChiefComplaintsCompleted"] || false, false, false, false, responseData?.["OswestryCompleted"] || false];

        //as we decoupled chief complaint component no need to push response in allResponses as chief complaint component will handle saving vas or cc related question answer
        if(allResponses.length > 0) {
            allResponses = allResponses.filter((response) => response?.['GroupId'] !== CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key);
            allResponses = allResponses.filter((response) => response?.['GroupId'] !== CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key);
        }

        return {
            questions: allQuestions,
            savedResponses: allResponses,
            pageHeaderInfo: pageHeaderInfo,
            chiefComplaintVas: chiefComplaintVas,
            isCompleted: isCompleted
        };
    };

    const _getSubBodyRegions = (allAnswer) => {
        return allAnswer.map((answer) => answer['SelectedBodyLocation']).filter((value, index, self) => self.indexOf(value) === index);
    }

    const _getBodyLocations = async () => {
        const payload = {
            bodyRegionList: bodyRegionList,
        }
        const response = await examService.getBodyLocations(payload);
        const { data, success, error } = response;

        if (!success) {
            console.error(error);
            return;
        }

        return data;
    };

    const _getSelectedBodyLocations = (chiefComplaintVas) => {
        let selectedBodyLocations = [];
        let bodyLocationOfEachVasQuestion = [];
        let answers = [];

        if (chiefComplaintVas?.length > 0) {
            chiefComplaintVas.forEach((item) => {
                if (item?.['BodyLocation']?.length > 0) {
                    bodyLocationOfEachVasQuestion.push(item['BodyLocation']?.[0]);
                    item['BodyLocation'].forEach((location) => {
                        selectedBodyLocations.push({
                            bodyLocation: location,
                            labels: [{
                                ResponseText1: item?.['PainValue'],
                                ResponseText2: item?.['Title'],
                                ResponseText3: item?.['Description']
                            }],
                            questionId: item.QuestionId
                        });
                    })
                }
            })
        }

        return {
            selectedBodyLocations: selectedBodyLocations,
            answers: answers,
            bodyLocationOfEachVasQuestion: bodyLocationOfEachVasQuestion
        };
    }

    const _getDisabledBodyLocations = (data) => {
        let disabledBodyLocations = [];
        if (data && data?.length > 0) {
            data.forEach((value) => {
                if (value.disabled) {
                    disabledBodyLocations.push(value.bodyLocation);
                }
            });

            return disabledBodyLocations;
        }
    };

    const onClickChangePage = async ({ group, subGroupId, pageCaptionId, direction }) => {
        if (group.viewOrder > currentGroupViewOrderId) {
            if (checkForGoToNext()) {
                setIsClicked(true);
                setAlertType(CONSTANT.ALERT_TYPE.ERROR);
                setAlertMessage('One or more fields have an error. Please check and try again.');
                setAlertTitle(CONSTANT.ALERT_TITLE.ERROR);
                return;
            }
        }

        setIsClicked(false);
        onExecuteScroll(0);
        onClickSave(false).then();
        if(subGroupQuestions.length > 0)
            setNextQuestionOrderId(subGroupQuestions[0]?.questionId);
        setSuccessMessage('');
        setErrorMessage('');

        if (currentGroupId === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key && direction === 'next') {
            const answer = allAnswers.find((item) => item.QuestionId === CONSTANT.ASSESSMENT_QUESTIONS.OUTCOME_BODY_REGION_SELECTION);
            if (!answer) {
                const index = subGroupQuestions.findIndex((item) => item.questionId === CONSTANT.ASSESSMENT_QUESTIONS.OUTCOME_BODY_REGION_SELECTION);
                subGroupQuestions[index].isRequired = true;
                setForceUpdate(Date.now());
                return;
            }
        }

        setIsBodyPartSelected(false);
        setSubGroupQuestions([]);

        if (pageCaptionId > -1) {
            setPageCaptionId(pageCaptionId);
            _filterQuestionsBySubGroupPageCaptionCondition(subGroups[subGroupId], pageCaptions[pageCaptionId], groupQuestions);

        } else if (subGroupId > -1) {
            if (currentGroupId === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key) {
                await showOutcomeAssessmentQuestions(outcomeAssessmentBodyRegionKey);
            } else {
                setSubGroupId(subGroupId);
                setPageCaptions([])
                const pageCaptionsOfSubGroup = _getPageCaptionsOfSubGroup(groupQuestions, subGroups[subGroupId], currentGroupId);
                setPageCaptions(pageCaptionsOfSubGroup)
                let pageCaptionId;
                if (direction === 'previous') {
                    pageCaptionId = pageCaptionsOfSubGroup?.length - 1;
                } else {
                    pageCaptionId = 0
                }
                setPageCaptionId(pageCaptionId);
                _filterQuestionsBySubGroupPageCaptionCondition(subGroups[subGroupId], pageCaptionsOfSubGroup[pageCaptionId], groupQuestions);
            }

        } else {
            setSubGroups([]);
            setPageCaptions([]);
            setSubGroupId(0);
            setPageCaptionId(0);
            setCurrentGroupId(group.key);
            setCurrentGroupViewOrderId(group.viewOrder);
            _removeCCQuestionsOfBodyLocationIfNotAnsweredAny(bodyLocation);

            if (group.key !== CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key && group.key !== CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key) {
                await _fetchAndPrepareChiefComplaintOrGroupQuestionsForView({
                    questions: allQuestions,
                    groupId: group.key,
                    subGroupId: 0
                });

            } else if (group.key === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key) {
                await showOutcomeAssessmentQuestions(bodyRegionList);

            } else if (group.key === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) {
                const bodyLocations = await _getBodyLocations();
                let {bodyParts} = chat;
                bodyLocations && bodyParts.forEach(item => {
                    let location = bodyLocations.find(x => x.bodyLocation === item.bodyLocation);
                    if(location){
                        item.subBodyRegion = location.subBodyRegion;
                    }
                });
                const disabledBodyLocations = _getDisabledBodyLocations(bodyLocations);
                const {
                    selectedBodyLocations,
                    answers
                } = _getSelectedBodyLocations(previousResponse?.['ChiefComplaintVas']);
                if (selectedBodyLocations.length > 0) {
                    const selectedBodyParts = selectedBodyLocations.filter((item) => {
                        const bodyLocation = chat.selectedBodyParts.find((location) => location.BodyLocation === item.BodyLocation);
                        return !bodyLocation;
                    });
                    const updatedChat = {
                        ...chat,
                        bodyParts,
                        disableBodyPartIds: disabledBodyLocations,
                        selectedBodyParts: [...chat?.selectedBodyParts, ...selectedBodyParts],
                        answers: answers,
                    }

                    setChat(updatedChat);
                    setBodyLocation(selectedBodyLocations?.[0]?.bodyLocation);

                } else {
                    const updatedChat = { ...chat, disableBodyPartIds: disabledBodyLocations }
                    setChat(updatedChat);
                }
            }
        }
    };

    const showOutcomeAssessmentQuestions = (outcomeAssessmentBodyRegionKey) => {
        const questions = []
        const bodyRegionList = _getSelectedBodyRegionObject({ bodyRegionId: outcomeAssessmentBodyRegionKey });

        allQuestions.forEach((item) => {
            if (item?.bodyRegionList?.length > 0 && item.typeId === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key && item.questionId !== CONSTANT.ASSESSMENT_QUESTIONS.OUTCOME_BODY_REGION_SELECTION) {
                item.bodyRegionList.forEach((region) => {
                    if (outcomeAssessmentBodyRegionKey.includes(region)) {
                        item.header1 = bodyRegionList.find(x => x.key === region).name;
                        questions.push(item);
                    }
                })
            }
        })

        _prepareQuestionsForView(questions, CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key, 0);
    }

    const _filterQuestionsBySubGroupPageCaptionCondition = (subGroupTitle, pageCaption, questions) => {
        let allQuestions = questions; // .filter((question) => question.header1 === subGroupTitle && question['pageCaption'] === pageCaption);
        let index = 0;
        allQuestions.forEach((item) => {
            if (!item['isDefaultHide']) {
                item.orderId = index++;
            }
        });

        if (allQuestions.length > 0 && allQuestions[0].typeName === "Medical History") {
            allQuestions = _divideQuestionOfMedicalHistory(allQuestions, tabCount);
        }

        if(allQuestions.length > 0)
            setNextQuestionOrderId(allQuestions[0].questionId);
        setSubGroupQuestions(allQuestions);
    }

    const onClickPrevious = (e) => {
        e.preventDefault();
        if (currentGroupId === CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.key && tabCount > 0) {
            setSubGroupQuestions(medicalHistorySection[tabCount - 1]);
            setTabCount((prevState) => prevState - 1);
            onExecuteScroll(0)
        } else {
            for (let i = groups.length - 1; i < groups.length; i--) {
                if (pageCaptions.length > 0 && pageCaptionId > 0) {
                    onClickChangePage({ subGroupId: subGroupId, pageCaptionId: pageCaptionId - 1 });
                    break;
                } else if (subGroups.length > 0 && subGroupId > 0 && currentGroupId !== CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) {
                    onClickChangePage({ subGroupId: subGroupId - 1, direction: 'previous' });
                    break;
                } else {
                    if (groups[i].viewOrder < currentGroupViewOrderId) {
                        onClickChangePage({ group: groups[i] });
                        break;
                    }
                }
            }
        }
    };

    const checkForGoToNext = (questionID = null) => {
        let flag = false;
        subGroups.forEach(subGroup => {
            for (let i = 0; i < subGroupQuestions.length; i++) {
                if(subGroupQuestions[i].questionId === questionID) continue;

                if(subGroupQuestions[i].responseType === CONSTANT.ELEMENT_TYPES.DROPDOWN) {
                    if(getCheckedOption(subGroupQuestions[i]?.questionId, subGroupQuestions[i]?.responses) === '') {
                        flag = true;
                        break
                    }
                }

                if (((currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key && subGroupQuestions[i].header2 === subGroup) ||
                    (currentGroupId !== CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key)) && !subGroupQuestions[i].isDefaultHide) {
                    if ((subGroupQuestions[i].responseType === CONSTANT.ELEMENT_TYPES.TEXT
                            || subGroupQuestions[i].responseType === CONSTANT.ELEMENT_TYPES.CALCULATION
                            || subGroupQuestions[i].responseType === CONSTANT.ELEMENT_TYPES.NUMBER) &&
                        subGroupQuestions[i].questionId !== CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH) {

                        if (checkValidityForInputBox(subGroupQuestions[i]) !== '') {
                            flag = true;
                            break;
                        }

                    } else if (subGroupQuestions[i].responseType === CONSTANT.ELEMENT_TYPES.CHECKBOX && subGroupQuestions[i].responses && subGroupQuestions[i].responses.length > 0) {
                        if (checkValidityForRadioButton(subGroupQuestions[i])) {
                            flag = true;
                            break;
                        }

                    } else if (subGroupQuestions[i].responseType === CONSTANT.ELEMENT_TYPES.BUTTON && subGroupQuestions[i].responses && subGroupQuestions[i].responses.length > 0) {
                        if (checkValidityForRadioButton(subGroupQuestions[i])) {
                            flag = true;
                            break;
                        }

                    } else if (((subGroupQuestions[i].responseType === CONSTANT.ELEMENT_TYPES.TEXT && subGroupQuestions[i].questionId === CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH)
                        || subGroupQuestions[i].responseType === CONSTANT.ELEMENT_TYPES.DATE_PICKER)) {
                        if (checkValidityForInputBox(subGroupQuestions[i]) !== '') {
                            flag = true;
                            break;
                        }
                    }
                }

            }
        });

        return flag;
    }

    const onClickNext = (e) => {
        e.preventDefault();
        if (currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) {
            if (checkForGoToNext()) {
                setIsClicked(true)
                return;
            }

            setCustomButtons([
                getButton('No', () => goToNext(), 'btn-danger')
                , getButton('Yes', () => onExecuteScroll(0), 'btn-success')]);
            setAlertMessage('Do you have any other areas of pain?');
            setAlertTitle('Question');
        } else {
            if (checkForGoToNext()) {
                setIsClicked(true);
                setAlertType(CONSTANT.ALERT_TYPE.ERROR)
                setAlertMessage('One or more fields have an error. Please check and try again.')
                setAlertTitle(CONSTANT.ALERT_TITLE.ERROR)

            } else {
                setIsClicked(false);
                // if (!bodyRegionList.includes(CONSTANT.BODY_REGIONS.POSTURE.key) && currentGroupId === CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.key && tabCount < 4) {
                let isPostureRegion = bodyRegionList.includes(CONSTANT.BODY_REGIONS.POSTURE.key);
                let maxTabCount = isPostureRegion ? 0 : 3;
                //tabCount is the header1 count such as Personal Health History, Family History, Medications, Surgery, Exercise
                //for posture only have Personal Health History, Exercise
                if (currentGroupId === CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.key && tabCount < maxTabCount) {
                    setSubGroupQuestions(medicalHistorySection[tabCount + 1]);
                    setTabCount((prevState) => prevState + 1);
                    onExecuteScroll(0)
                } else {
                    // goToNext();
                    for (let i = 0; i < groups.length; i++) {
                        if (groups[i].viewOrder > currentGroupViewOrderId) {
                            onClickChangePage({ group: groups[i] });
                            break;
                        }
                    }
                }
            }
        }
    };

    const goToNext = () => {
        for (let i = 0; i < groups.length; i++) {
            if (pageCaptions.length > 0 && pageCaptionId < pageCaptions.length - 1) {
                onClickChangePage({ subGroupId: subGroupId, pageCaptionId: pageCaptionId + 1 });
                break;
            } else if (subGroups.length > 0 && subGroupId < subGroups.length - 1 && currentGroupId !== CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) {
                onClickChangePage({ subGroupId: subGroupId + 1, direction: 'next' });
                break;
            } else {
                if (groups[i].viewOrder > currentGroupViewOrderId) {
                    onClickChangePage({ group: groups[i] });
                    break;
                }
            }
        }
    }

    const _getSelectedBodyRegionObject = ({ bodyRegionName, bodyRegionId }) => {
        let bodyRegions = [];
        for (const [, value] of Object.entries(CONSTANT.BODY_REGIONS)) {
            bodyRegions.push(value);
        }

        const selectedBodyRegion = bodyRegions.filter((item) => {
            if (bodyRegionName) {
                return item.name === bodyRegionName;
            } else if (bodyRegionId && bodyRegionId.length > 0) {
                return bodyRegionId.includes(item.key);
            } else {
                return false;
            }
        });

        return selectedBodyRegion || [];
    }

    const nextQuestionFocus = (question) => {
        if(currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) {
            // const questionListByHeader = new Map();
            // subGroups.forEach(subGroup => {
            //     questionListByHeader.set(subGroup, []);
            // })
            //
            // debugger
            // subGroupQuestions.forEach(el => {
            //     let tempQuestions = questionListByHeader.get(el.header2);
            //     tempQuestions.push(el.questionId);
            // })
            //
            // let currentQuestionHeaderCount = 0;
            // let pos = -1;
            // subGroupQuestions.forEach(el => {
            //     if(el.header2 === question.header2) {
            //         currentQuestionHeaderCount++;
            //     }
            //
            //     if(question.questionId === el.questionId) {
            //         pos = currentQuestionHeaderCount;
            //     }
            // })
            //
            // if(questionListByHeader.get(question.header2).length > pos) {
            //     const tempList = questionListByHeader.get(question.header2);
            //     setNextQuestionOrderId(tempList[pos]);
            // } else if(questionListByHeader.get(question.header2).length === pos) {
            //     let idx = subGroups.findIndex(el => el === question.header2);
            //
            //     if (idx !== -1 && idx < (subGroups.length - 1)) {
            //         const tempList = questionListByHeader.get(subGroups[idx + 1]);
            //         setNextQuestionOrderId(tempList[0]);
            //     } else if ((idx + 1) === subGroups.length) {
            //         setNextQuestionOrderId(-.5)
            //     }
            // }
        } else {
            let indexOfCurrentQuestion  = subGroupQuestions.findIndex(el => el.questionId === question.questionId);
            indexOfCurrentQuestion++;

            while(indexOfCurrentQuestion > -1 && indexOfCurrentQuestion < subGroupQuestions.length) {
                if(!subGroupQuestions[indexOfCurrentQuestion]?.isDefaultHide) {
                    setNextQuestionOrderId(subGroupQuestions[indexOfCurrentQuestion]?.questionId);
                    break;
                }
                indexOfCurrentQuestion++;
            }
        }
    }

    // const saveAnswer = (tempAnswer, isCompletedCopy) => {
    //     onSingleClickSave(tempAnswer, isCompletedCopy)
    // }

    const onChangeCollectResponse = async ({ question, answerId, answerText, answerIndex, title, description, checked, willSave = true, waitUntilSave = false }) => {
        if(isReportAvailableRef.current) {
            return;
        }

        setSuccessMessage('');
        setErrorMessage('');
        let answer = {};
        let allAnswer = [];
        // emptyFieldValidation(question, answerId, answerText, answerIndex, title, description)
        if (answerText) {
            const validation = checkClientValidation(question.questionId, answerText, '');
            const errors = formErrors.filter((formError) => formError.questionId !== question.questionId);

            if (!validation.valid) {
                const error = { questionId: question.questionId, message: validation.message };
                errors.push(error);
                setFormErrors(errors);
                return;
            } else {
                setFormErrors(errors);
            }
        }

        const tempAnswer = _prepareAnswer(question, answerId, answerText, answerIndex, currentGroupId);
        tempAnswer.IsDeleted = !!checked;
        const isCompletedCopy = [...isCompleted];
        if(!isCompletedCopy[currentGroupId]) {
            isCompletedCopy[currentGroupId] = true;
            setIsCompleted(isCompletedCopy);
        }

        // clearTimeout(debounceTimerRef.current);
        // debounceTimerRef.current = setTimeout(() => {
        //     saveAnswer(tempAnswer, isCompletedCopy);
        // }, 500);
        if(willSave) {
            !waitUntilSave && onSingleClickSave(tempAnswer, isCompletedCopy);
            waitUntilSave && await onSingleClickSave(tempAnswer, isCompletedCopy);
        }

        if (question.questionId === CONSTANT.ASSESSMENT_QUESTIONS.OUTCOME_BODY_REGION_SELECTION) {
            question.isRequired = false;
            const bodyRegionList = _getSelectedBodyRegionObject({ bodyRegionName: answerText })
            setOutcomeAssessmentBodyRegionKey(bodyRegionList);
        }

        if (_isAnsweredChanged(question, answerId)) {
            _updateAnswer(question, answerId, answerText, answerIndex, checked);
        } else {
            answer = _prepareAnswer(question, answerId, answerText, answerIndex, currentGroupId)
            allAnswer.push(answer);
        }

        if (question.header2 === "RATE YOUR LEVEL OF PAIN") {
            updateBodyPart(question, answerText, title, description);
        }

        allAnswer.push(...allAnswers);
        let scoreAnswers = [];
        if (currentGroupId === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key) {
            scoreAnswers = getScoreAnswers(allAnswer) || [];
        }
        if (question?.['hasConditionalQuestion']) {
            _enableAndGetConditionalQuestionsIfExists(allQuestions, allAnswers, question, answerId);
        }

        if (Object.keys(answer).length > 0 && scoreAnswers.length > 0) {
            setAllAnswers((answers) => [...answers, answer, ...scoreAnswers]);
        } else if (Object.keys(answer).length > 0 && scoreAnswers.length === 0) {
            setAllAnswers((answers) => [...answers, answer]);
        } else if (Object.keys(answer).length === 0 && scoreAnswers.length > 0) {
            setAllAnswers((answers) => [...answers, ...scoreAnswers]);
        }

        nextQuestionFocus(question)

        //setNextQuestionOrderId(question.orderId + 1);
        setForceUpdate(Date.now());
        question.responseType === CONSTANT.ELEMENT_TYPES.BUTTON && onExecuteScroll(question.questionId);
        if(tempAnswer?.QuestionId === CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH) {
            saveDemoInfoAtLocalStorage(tempAnswer?.QuestionId,
                {
                    ...tempAnswer,
                    AnswerValue: new Date(tempAnswer?.AnswerValue || "")
                }
            );
        }
    };

    const updateBodyPart = (question, answerText, title, description) => {
        const selectedBodyLocation = chat.selectedBodyParts.find((item) => item.bodyLocation === bodyLocation);
        if (selectedBodyLocation?.bodyLocation) {
            const BodyPartsNotToUpdate = chat.selectedBodyParts.filter((item) => item.questionId !== question.questionId);
            const bodyPartsToUpdate = chat.selectedBodyParts.filter((item) => item.questionId === question.questionId);
            bodyPartsToUpdate?.length > 0 && bodyPartsToUpdate.forEach((item) => {
                item.labels = [{
                    ResponseText1: parseInt(answerText),
                    ResponseText2: title,
                    ResponseText3: description
                }]
            })

            const updatedChat = {
                ...chat,
                selectedBodyParts: [...BodyPartsNotToUpdate, ...bodyPartsToUpdate],
            }
            setChat(updatedChat);

        } else {
            const updatedChat = {
                ...chat,
                selectedBodyParts: [...chat?.selectedBodyParts, ...[{
                    bodyLocation: bodyLocation,
                    labels: [{
                        ResponseText1: parseInt(answerText),
                        ResponseText2: title,
                        ResponseText3: description
                    }],
                    questionId: question.questionId
                }]],
            }
            setChat(updatedChat);
        }
    };

    // const _enableAndGetConditionalQuestionsIfExists = (questions, allAnswers, question, answerId) => {
    //     question.responses.forEach((response) => {
    //         const filteredQuestions = questions.filter((question) => response?.['displayQuestionList']?.includes(question.questionId));
    //         filteredQuestions.forEach((filteredQuestion) => {
    //             if (response.id !== answerId) {
    //                 filteredQuestion.isDefaultHide = true;
    //                 const index = allAnswers.findIndex((item) => item?.QuestionId === filteredQuestion.questionId);
    //                 index > -1 && allAnswers.splice(index, 1);
    //             } else {
    //                 filteredQuestion.isDefaultHide = false;
    //             }
    //         });
    //     })
    // }

    const getUniqueQuestionIdsInSecondArray = (existingIds, newIds) => {
        return newIds.filter(id => !existingIds.includes(id));
    }

    const getConditionalQuestions = (questions, selectedQuestion, selectedAnswerId) => {
        let displayQuestionIdsOfSelectedResponse = [];
        let displayQuestionIdsOfNotSelectedResponses = [];

        if (selectedQuestion?.hasConditionalQuestion) {
            for (const response of selectedQuestion.responses) {
                if (response.displayQuestionList.length === 0) continue;

                const newQuestionIds = response.id === selectedAnswerId
                    ? getUniqueQuestionIdsInSecondArray(displayQuestionIdsOfSelectedResponse, response.displayQuestionList)
                    : getUniqueQuestionIdsInSecondArray(displayQuestionIdsOfNotSelectedResponses, response.displayQuestionList);

                if (response.id === selectedAnswerId) {
                    displayQuestionIdsOfSelectedResponse.push(...newQuestionIds);
                } else {
                    displayQuestionIdsOfNotSelectedResponses.push(...newQuestionIds);
                }
            }
        }

        return {
            displayQuestionIdsOfSelectedResponse,
            displayQuestionIdsOfNotSelectedResponses
        };
    }


    const _enableAndGetConditionalQuestionsIfExists = async (questions, allAnswers, currentQuestion, selectedAnswerId) => {
        let { displayQuestionIdsOfSelectedResponse, displayQuestionIdsOfNotSelectedResponses } =
            getConditionalQuestions(questions, currentQuestion, selectedAnswerId);

        // remove questions ids those are in both arrays as do not need to hide question those are inside selected question
        displayQuestionIdsOfNotSelectedResponses = displayQuestionIdsOfNotSelectedResponses
            .filter((questionId) => !displayQuestionIdsOfSelectedResponse.includes(questionId));

        updateVisibility(questions, displayQuestionIdsOfNotSelectedResponses, false);
        updateVisibility(questions, displayQuestionIdsOfSelectedResponse, true);

        if(displayQuestionIdsOfNotSelectedResponses.length > 0) {
            await ExamService.clearAssessments({ TestId: testId, QuestionIds: displayQuestionIdsOfNotSelectedResponses });
        }
    }

    const updateVisibility = (questions, questionIds, willVisible) => {
        questionIds.forEach(questionId => {
            const questionIndex = questions.findIndex(q => q.questionId === questionId);
            if (questionIndex !== -1) {
                questions[questionIndex].isDefaultHide = !willVisible;
                if (!willVisible) {
                    const answerIndex = allAnswers.findIndex(a => a.QuestionId === questionId);
                    if (answerIndex > -1) {
                        allAnswers.splice(answerIndex, 1);
                    }
                }
            }
        });
    };

    const getScoreAnswers = (allAnswer) => {
        const newScoreAnswers = [];
        const scoresAnswers = _calculateAndGetScoresAnswersOfOutcomeAssessmentQuestions(allAnswer);

        if (scoresAnswers?.length > 0) {
            scoresAnswers.forEach((scoreAnswer) => {
                const answer = allAnswers.find((answer) => answer.QuestionId === scoreAnswer.QuestionId);
                if (answer) {
                    const question = {
                        questionId: scoreAnswer.QuestionId,
                        bodyRegionList: outcomeAssessmentBodyRegionKey
                    }

                    _updateAnswer(question, scoreAnswer.AnswerId, scoreAnswer.AnswerTitle);
                } else {
                    newScoreAnswers.push(scoreAnswer);
                }
            })
        }

        return newScoreAnswers;
    }

    const _prepareAnswer = (question, answerId, answerText, answerIndex, groupId, isScoreQuestion) => {
        return {
            QuestionId: question.questionId,
            AnswerId: (answerId && answerId) || '',
            AnswerValue: (answerText && answerText) || '',
            AnswerTitle: (answerText && answerText) || '',
            GroupId: groupId,
            Index: answerIndex,
            BodyRegionList: question.bodyRegionList,
            BodyRegion: question.bodyRegion && question.bodyRegion.length > 0 ? question.bodyRegion[0] : '',
            IsScoreAnswer: question?.isScoreQuestion || isScoreQuestion || false,
        };
    }

    const _calculateAndGetScoresAnswersOfOutcomeAssessmentQuestions = (allAnswer) => {
        const selectedBodyRegionAnswers = _getSelectedOutcomeAssessmentAnswers(allAnswer);
        const answerListExceptScoreAnswers = selectedBodyRegionAnswers.filter((item) => !item.IsScoreAnswer)
        const scores = _getScores(answerListExceptScoreAnswers);
        let distinctGroupTotalAnswers;
        distinctGroupTotalAnswers = _getDistinctGroupTotalScore(answerListExceptScoreAnswers);

        return getScoresAnswers(scores, distinctGroupTotalAnswers, answerListExceptScoreAnswers)
    }

    const _getBodyRegion = (questionId) => {
        let ques;
        subGroupQuestions.forEach(question => {
            if (question.questionId === questionId) {
                ques = question
            }
        })

        return ques && ques.bodyRegion && ques.bodyRegion.length > 0 ? ques.bodyRegion : ''
    }

    const getScoresAnswers = (scores, distinctGroupTotalAnswers = {}, answerListExceptScoreAnswers) => {
        let answers = [];
        let bodyRegions = [];
        for (const [, value] of Object.entries(CONSTANT.BODY_REGIONS)) {
            bodyRegions.push(value);
        }

        let distinctGroupTotalScore = []
        for (const [key, value] of Object.entries(distinctGroupTotalAnswers)) {
            distinctGroupTotalScore.push({ key, value });
        }

        bodyRegions.forEach((region) => {
            if (outcomeAssessmentBodyRegionKey.includes(region.key)) {
                const singleRegionScore = _getScores(answerListExceptScoreAnswers, region.key);
                let outcomeScores = []
                for (const [key, value] of Object.entries(singleRegionScore)) {
                    outcomeScores.push({ key, value });
                }

                let scoreQuestions = [];
                if (CONSTANT.OUTCOME_SCORE_QUESTIONS?.[region.name]) {
                    for (const [key, value] of Object.entries(CONSTANT.OUTCOME_SCORE_QUESTIONS?.[region.name])) {
                        scoreQuestions.push({ key, value });
                    }
                }

                scoreQuestions.forEach((scoreQuestion) => {
                    distinctGroupTotalScore.forEach((score) => {
                        if (score.key === scoreQuestion.key && scoreQuestion.value !== CONSTANT.OUTCOME_SCORE_QUESTIONS.Arms.Score) {
                            const question = {
                                questionId: scoreQuestion.value,
                                bodyRegionList: [outcomeAssessmentBodyRegionKey],
                                isScoreQuestion: true,
                                bodyRegion: _getBodyRegion(scoreQuestion.value)
                            }
                            const answer = _prepareAnswer(question, score.value, score.value, 0, currentGroupId);
                            answers.push(answer);
                        }
                    })
                })

                scoreQuestions.forEach((scoreQuestion) => {
                    outcomeScores.forEach((score) => {
                        if (score.key === scoreQuestion.key) {
                            const question = {
                                questionId: scoreQuestion.value,
                                bodyRegionList: [outcomeAssessmentBodyRegionKey],
                                isScoreQuestion: true,
                                bodyRegion: _getBodyRegion(scoreQuestion.value)
                            }
                            const answer = _prepareAnswer(question, score.value, score.value, 0, currentGroupId);
                            answers.push(answer);
                        }
                    })
                })
            }
        })

        return answers;
    }

    const _getDistinctGroupTotalScore = (answers) => {
        let distinctGroupTotal = {};
        CONSTANT.ARMS_ASSESSMENT_RESPONSE_TYPES.forEach((item) => {
            const totalAnswers = answers.filter((answer) => answer.AnswerValue === item);
            distinctGroupTotal = { ...distinctGroupTotal, [item]: totalAnswers.length };
        })

        return distinctGroupTotal;
    }

    const _getScores = (answers, regionKey) => {
        return _prepareScore(answers, regionKey);
    }

    const _prepareScore = (answers, regionKey) => {
        let OSWScore = 0;
        let scorePoint = 1;

        let singleRegionAnswer = [];
        answers.forEach((answer) => {
            if (answer.BodyRegion === regionKey && answer.QuestionId !== 10000687) {
                singleRegionAnswer.push(answer)
            }
        })

        const earnedScore = _getEarnedScoreOfSelectedBodyRegion(singleRegionAnswer);

        if (earnedScore > -1) {
            scorePoint = singleRegionAnswer.length * 5;
            OSWScore = Math.round((earnedScore / 50) * 100) + '%';
        }

        return { OSWScore: OSWScore, PScore: scorePoint, Score: scorePoint };
    };
    const NECK_UNCALCULATING_QUESTION_ID = 10000687;
    const _getEarnedScoreOfSelectedBodyRegion = (answers) => {
        let earnedScore = 0;
        answers.forEach((answer) => {
            if (answer.Index && answer.questionId !== NECK_UNCALCULATING_QUESTION_ID) {
                earnedScore += answer.Index;
            }
        })

        return earnedScore;
    }

    const _getSelectedOutcomeAssessmentAnswers = (answers) => {
        const filteredAnswers = []
        answers.forEach((answer) => {
            if (answer?.BodyRegionList?.length > 0 && answer.GroupId === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key && answer.QuestionId !== CONSTANT.ASSESSMENT_QUESTIONS.OUTCOME_BODY_REGION_SELECTION) {
                answer.BodyRegionList.forEach((region) => {
                    if (outcomeAssessmentBodyRegionKey.includes(region.key) > -1) {
                        filteredAnswers.push(answer);
                    }
                })
            }
        });

        return filteredAnswers;
    }

    // const onSelectBodyPart = async (e) => {
    //     _removeCCQuestionsOfBodyLocationIfNotAnsweredAny(bodyLocation);
    //     setIsBodyPartSelected(true);
    //     setBodyLocation(e.bodyLocation);
    //
    //     await _fetchAndPrepareChiefComplaintOrGroupQuestionsForView({
    //         questions: allQuestions,
    //         groupId: currentGroupId,
    //         subGroupId: 0,
    //         bodyPart: e.bodyLocation
    //     });
    // };

    const _isAnsweredChanged = (question, answerId) => {
        if (allAnswers.length > 0) {
            let answer;

            if (question.responseType === CONSTANT.ELEMENT_TYPES.CHECKBOX) {
                answer = allAnswers.filter((answer) => answer.QuestionId === question.questionId && answer.AnswerId === answerId);
            } else {
                answer = allAnswers.filter((answer) => answer.QuestionId === question.questionId);
            }

            if (answer.length > 0) {
                return true;
            }
        }

        return false;
    };

    const getValue = (responseType, questionId) => {
        let answer;
        let value = '';
        answer = allAnswers.find((answer) => answer.QuestionId === questionId);

        if (answer) {
            if (responseType === CONSTANT.ELEMENT_TYPES.TEXT || responseType === CONSTANT.ELEMENT_TYPES.CALCULATION
                || responseType === CONSTANT.ELEMENT_TYPES.NUMBER || responseType === CONSTANT.ELEMENT_TYPES.DATE_PICKER) {
                value = (answer.AnswerValue && answer.AnswerValue) || '';
            }
        }

        if (questionId === CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH && isValid(new Date(value))) {
            value = formatDate(new Date(value), CONSTANT.DATE_FORMAT.MM_DD_YYYY);
        }

        return value;
    };

    const getCheckedOption = (questionId, responses) => {
        const answer = allAnswers.filter((answer) => (answer.QuestionId === questionId));
        const answerOption = allAnswers.filter((answer) =>
            responses && responses.length > 0 && responses.find((response) => response.id === answer.AnswerId));

        if (answer.length > 0 && answerOption.length > 0) {
            return answer[0].AnswerId;
        } else {
            return ''
        }
    };

    const _preparePayloadOptions = (option) => {
        let options = {}
        if(option.length >=8) {
            options = {
                DemographicsCompleted: option[CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key] ,
                MedicalHistoryCompleted: option[CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.key],
                ADLCompleted: option[CONSTANT.ASSESSMENT_GROUP.ADL.key],
                ChiefComplaintsCompleted: option[CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key],
                OswestryCompleted: option[CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key]
            }
        }

        return options;
    }

    const _preparePayload = (option = []) => {
        if(option.length === 0) option = isCompleted
        const options =_preparePayloadOptions(option);

        const assessment = new Assessment(options);
        assessment.TestId = testId;
        assessment.ProviderEmail = providerEmail;
        assessment.BodyRegionList = bodyRegionList;
        assessment.Answers = allAnswers;

        return assessment;
    };

    const handleNoCC = async (isProcessAfterSaveMovement = false, closeEditAssessmentPage = false) => {
        setIsSavingNoCC(true);
        setIsSaveAssessments(true);
        await onClickSave(isProcessAfterSaveMovement);
        const response = await saveNoCC_Complaint(testId);
        const { data, error } = response;

        if (error) {
            console.error(error);
            toast.error("Internal Server Error. Please try again")
            return;
        }
        const ChiefComplaintsQuestionStatus = data?.ChiefComplaintsQuestionStatus;
        _updateAnswer(ccQuestionRef.current, ChiefComplaintsQuestionStatus?.AnswerId, ChiefComplaintsQuestionStatus?.AnswerValue);
        setIsSavingNoCC(false);
        setIsSaveAssessments(false);
        if(closeEditAssessmentPage) {
            setRedirectToMyAssessments(true);
        }
    }

    const onClickSubmit = async (e) => {
        e.preventDefault();
        if (checkForGoToNext()) {
            setAlertType(CONSTANT.ALERT_TYPE.ERROR)
            setAlertMessage('One or more fields have an error. Please check and try again.')
            setAlertTitle(CONSTANT.ALERT_TITLE.ERROR)
            setIsClicked(true)
            return;
        }

        if (currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) {
            await showCCQuestions(true);
        } else {
            setCustomButtons(
                [getButton('Submit', () => onClickSave(true), 'btn-success', true, isSaveAssessments),
                    getButton('Complete Other Sections', () => {
                    }, 'btn-danger')]);
            setAlertMessage('Before submitting, please be sure to complete all sections of the intake process.');
            setAlertTitle('Summary');
        }
    }

    const getButton = (title, task, styleClass, waitForSave = false) => {
        return { title: title, task: task, styleClass: styleClass, ...(waitForSave ? { waitForSave: true } : {}) }
    }

    const onSingleClickSave = async (answer, isCompleted) => {
        const payload = _preparePayload(isCompleted);
        if (payload && payload.Answers) {
            delete payload.Answers;
            payload.Answers = [answer];
        }

        const response = await examService.saveAssessment(payload);
        const { success, error } = response;

        if (!success) {
            console.error(error);
        }
    }

    const onClickSave = async (isProcessAfterSaveMovement) => {
        setSuccessMessage('');
        isProcessAfterSaveMovement && setIsSaveAssessments(true);

        const payload = _preparePayload();
        const response = await examService.saveAssessments(payload);
        const { success, error, data } = response;

        if (!success) {
            console.error(error);
            if (isProcessAfterSaveMovement) {
                setIsSaveAssessments(false);
                setAlertType(CONSTANT.ALERT_TYPE.WARNING);
                setAlertTitle(CONSTANT.ALERT_TITLE.WARNING);
                setAlertMessage('Data is not Saved. Please try Again..');
            }
            return;
        }

        isProcessAfterSaveMovement && await _processAfterSaveAnswers();
        isProcessAfterSaveMovement && setIsSaveAssessments(false);
    };

    const _processAfterSaveAnswers = async () => {
        if ((previousResponse?.['InvitationTypeText'])?.toLowerCase() === CONSTANT.REDIRECT_TYPE.SELF) {
            setAlertMessage("Do you want to perform biometric movement?");
            setCustomButtons([
                getButton('Yes', toggleMovementModal, 'btn-success'),
                getButton('No', goToPreviousPage, 'btn-danger')
            ]);

        } else {
            if (invitationIdFromUrl) {
                const {
                    meetingRoomUrl,
                    invitationId,
                    waitingRoomId
                // } = await _getWaitingRoomByInvitationInfo(testId, GLOBAL.URL_PARAMS.INVITATION_ID);
                } = await _getWaitingRoomByInvitationInfo(testId, invitationIdFromUrl);
                telemedicineInvitationInfoAPICallCount++;
                waitingRoomIdRef.current = waitingRoomId;

                if ((previousResponse?.['InvitationTypeText'])?.toLowerCase() === CONSTANT.REDIRECT_TYPE.INCLINIC) {
                    setAlertType(CONSTANT.ALERT_TYPE.SUCCESS);
                    setAlertTitle(CONSTANT.ALERT_TITLE.SUCCESS);
                    setCustomButtons([]);
                    setAlertMessage('Thank you for your response.');
                    setOnConfirmAlertFunction(() => goToPreviousPage);

                } else if (meetingRoomUrl && invitationId) {
                    setJoinJoinMeetingRoomUrl(meetingRoomUrl);
                    setInvitationId(invitationId);
                    if (!isIntakeSigned) {
                        onConfirmAlert();
                        setShowIntakeForm(true);

                    } else {
                        setAlertMessage("Do you want to join now?");
                        setCustomButtons([
                            getButton(joinNowText, () => onClickJoinTelemedicineNow({}, meetingRoomUrl, invitationId), 'btn-primary', true),
                            getButton(joinLaterText, () => onClickJoinTelemedicineLater({}, invitationId), 'btn-danger', true)
                        ]);
                    }
                } else {
                    if (telemedicineInvitationInfoAPICallCount < 2) {
                        setCustomButtons([]);
                        setAlertMessage('Please click OK to try again...');
                        setOnConfirmAlertFunction(() => _processAfterSaveAnswers);

                    } else {
                        setAlertType(CONSTANT.ALERT_TYPE.SUCCESS);
                        setAlertTitle(CONSTANT.ALERT_TITLE.SUCCESS);
                        setCustomButtons([]);
                        setAlertMessage('Thank you for your response.');
                        setOnConfirmAlertFunction(() => goToPreviousPage);
                    }
                }

            } else {
                setAlertType(CONSTANT.ALERT_TYPE.SUCCESS);
                setAlertTitle(CONSTANT.ALERT_TITLE.SUCCESS);
                setCustomButtons([]);
                setAlertMessage('Thank you for your response.');
                setOnConfirmAlertFunction(() => goToPreviousPage);
            }
        }
    }

    const toggleMovementModal = () => {
        GLOBAL.ASSESSMENT = new Assessment({ TestId: testId });
        setRowValue({ TestId: testId, BodyRegionList: bodyRegionList });
        selectedExamRef.current = { TestId: testId, BodyRegionList: bodyRegionList };
        setTakeBiometricMovementModal(true);
        setOpenBiometricMovementAndCautionQuestionContainer(true);
    }

    const getError = (questionId) => {
        const error = formErrors.find((error) => error.questionId === questionId);
        if (error) {
            return error;
        } else {
            return '';
        }
    };

    const onExecuteScroll = (value, flag = false) => {
        try {
            const scrollIntoViewSmoothly =
                'scrollBehavior' in document.documentElement.style
                    ? scrollIntoView
                    : smoothScrollIntoView

            setTimeout(() => {
                const element = document.getElementById(`cc-question-${value}`);

                if (!value) {
                    window.scrollTo(0, 0);
                    return;
                }

                if (flag) {
                    //const elementPosition = document.getElementById(`cc-question-${value}`).offsetTop
                    //window.scrollTo({ top: elementPosition - 140, behavior: "smooth" })
                    scrollIntoViewSmoothly(element, { behavior: "smooth", block: "center" });
                    return;
                }

                if (element) {
                    // noinspection JSIgnoredPromiseFromCall
                    scrollIntoViewSmoothly(element, { behavior: "smooth", block: "start" });
                }
            }, 500);
        } catch (error) {
            console.error(error);
        }
    };

    onConfirmAlert = () => {
        setAlertMessage('');
        setAlertTitle(CONSTANT.ALERT_TITLE.INFO);
        setAlertType(CONSTANT.ALERT_TYPE.INFO);
        setOnConfirmAlertFunction(() => () => {
        })
        setCustomButtons([]);
        setIsSaveAssessments(false);
        setSendingEmail(false);
        setJoiningTelemedicine(false);
    }

    const saveIntake = async (values) => {
        if (isIntakeSigned) return;
        if (values && Object.keys(values).length === 0) return;

        const payload = {
            TestId: testId || '',
            IntakeData: {
                PatientOrRepresentativeSignature: values?.PersonalRepresentative,
                PatientOrRepresentativeSignatureDate: values?.Date,
                PatientSignature: values?.PatientSignature,
                PatientSignatureDate: values?.Today,
                IsIntakeSigned: true,
                IsConsentAgreed: true
            }
        };

        const response = await examService.updateIntake(payload);
        const { data, success, error } = response;

        if (!success) {
            console.error(error);
            return;
        }

        const intake = data || '{}';
        GLOBAL.USER_INFO = {
            ...GLOBAL.USER_INFO,
            IsIntakeSigned: intake.IsIntakeSigned
        };
        storeUserInfoToLocalStorage();
    }

    const showPolicyHandler = () => {
        setShowPolicy(prevState => !prevState)
    }

    const termsAndConditionModalHandler = () => {
        setTermsAndConditionModal((prevState) => !prevState);
    };

    const onClickJoinTelemedicineNow = async (values, url, tempInvitationId = "") => {
        setJoiningTelemedicine(true);
        await saveIntake(values);
        setTelemedicineProperties(invitationId || tempInvitationId, bodyRegionList, afterInitializeTelemedicineModal, afterCloseTelemedicineModal);
        // if(!tempWaitingRoomId) {
        //     setJoiningTelemedicine(false);
        // }
        // window.location.href = url || joinMeetingRoomUrl;
    };

    const afterInitializeTelemedicineModal = () => {
        onConfirmAlert();
    }

    const afterCloseTelemedicineModal = () => {
        resetTelemedicineKeys();
        setShowIntakeForm(false);
        setRedirectToMyAssessments(true);
        setJoiningTelemedicine(false);
    }

    const onClickJoinTelemedicineLater = async (values, wId) => {
        setSendingEmail(true);
        await sendEmailForTelemed(wId || invitationId);
        await saveIntake(values);
        setSendingEmail(false);
        setShowIntakeForm(false);
    };

    const sendEmailForTelemed = async (waitingRoomId) => {
        if(!waitingRoomIdRef.current) {
            toast.error('Waiting Room Id is not available');
            return;
        }

        const payload = { WaitingRoomId: waitingRoomIdRef.current };
        const response = await examService.sendTelemedEmailForEmma(payload);
        const { success, error } = response;

        if (!success) {
            console.error(error);
        }

        setCustomButtons([]);
        setAlertTitle(CONSTANT.ALERT_TITLE.CONGRATULATIONS);
        setAlertType(CONSTANT.ALERT_TYPE.SUCCESS);
        setAlertMessage('You have successfully completed the questionnaire portion of the assessment. Your provider has been notified and will follow up with you in order to complete the last part of the assessment.');
        setOnConfirmAlertFunction(() => goToPreviousPage);
    }

    const goToPreviousPage = () => {
        setRedirectToMyAssessments(true);
    }
    const validationNotRequired = [10000023, 10000668,10000580];
    const checkValidityForRadioButton = (question) => {
        let flag = false;
        if(validationNotRequired.includes(question.questionId))  return flag;
        const id = getCheckedOption(question.questionId, question.responses)
        if (id === '') {
            return !flag;
        }

        const response = question.responses
        for (let i = 0; i < response.length; i++) {
            if (response[i].id === id) {
                flag = true;
                return !flag;
            }
        }

        return !flag;
    }

    const checkValidityForInputBox = (question) => {
        const value = getValue(question.responseType, question.questionId)
        const error = getError(question.questionId)

        if (outcomeAssessmentQuestionsId.includes(question.questionId) || validationNotRequired.includes(question.questionId)) {
            return '';
        }

        if (error) {
            return error;
        }

        if (value === '') {
            const errors = formErrors.filter((formError) => formError.questionId !== question.questionId);
            const error = { questionId: question.questionId, message: 'This field is required.' };
            errors.push(error);
            setFormErrors(errors);
            return error;
        }

        return ''
    }

    const onClickMovementCell = () => () => {
        GLOBAL.ASSESSMENT = null;
        setOpenMovementModal(true);
    };

    const openTakeBiometricMovementHandler = () => {
        setTakeBiometricMovementModal(false);
        setRowValue(null);
    }

    const _checkCurrentRegion = (region) => {
        if (currentGroupId === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key) {
            if (previousRegion.current !== region) {
                previousRegion.current = region;
            }
        }

        return true;
    }


    const clickOnThumbnail = (url) => {
        setViewImage(url)
    }

    const getCurrentTabName = () => {
        for (const [, value] of Object.entries(CONSTANT.ASSESSMENT_GROUP)) {
            if (value.key === currentGroupId) {
                return value.name
            }
        }
        return ""
    }
    const closeBtn = (
        <img src={crossBtn} onClick={() => setTakeBiometricMovementModal(false)} type="button" alt="cross" />
    );

    return (
        <>
            {redirectToMyAssessments && (<Redirect to={redirectUrl}/>)}
            <Modal className="modal-lg" isOpen={showInstruction} toggle={()=> setShowInstruction(false)}>
                <div className="edit-assessment-instruction-container">
                    <div className="edit-assessment-instruction-header-content kt-padding-b-30">
                        <p className="edit-assessment-instruction-header-content-title">{getCurrentTabName()}</p>
                        <img onClick={()=> setShowInstruction(false)} className="cursor-pointer" src={CloseButton} alt="Close Button" />
                    </div>
                    <label className="edit-assessment-instruction-info"  dangerouslySetInnerHTML={getMarkup(groupTitle)}/>
                </div>
            </Modal>



            <Modal isOpen={Boolean(viewImage)} toggle={()=> setViewImage("")} centered>
                <img alt="" src={viewImage} />
            </Modal>



            {loadingData && (
                <div className="edit-assessment-loading-message">
                    <div className="alert-text kt-ml-5 kt-mr-5 kt-font-xl kt-font-bold">
                        <h1>Please wait, loading your assessment... &nbsp;&nbsp;
                            <i className="fa fa-spinner fa-spin kt-font-primary"/>
                        </h1>
                    </div>
                </div>
            )}
            {assessmentNotFound
                ?
                <div className="edit-assessment-loading-message">
                    <div className="alert-text kt-ml-5 kt-mr-5 kt-font-xl kt-font-bold">
                        <h1 className="text-center">We apologize, but we were unable to find the assessment you are searching for.</h1>
                    </div>
                </div>
                :
                <>
                {!loadingData && (
                    <>
                        <EditAssessmentHeader
                            region={bodyRegionLists}
                            invitationType={previousResponse?.['InvitationTypeText']}
                            testId={testId}
                            handler={toggleMovementModal}
                            isReportAvailable={isReportAvailableRef.current}
                            handleShouldShowCCQuestionConfirmDialog={handleShouldShowCCQuestionConfirmDialog}
                            ccQuestionRef={ccQuestionRef}
                            currentGroupId={currentGroupId}
                        />
                        <div className="kt-wizard-v4" style={{margin: "3rem auto 0", width: "96%"}}>
                            <div className="edit-assessment-stepbar-container">
                                <StepBar groups={groups} isCompleted={isCompleted} showName={false} />
                            </div>
                            <div className="kt-wizard-v4__nav">
                                <div className="kt-wizard-v4__nav-items nav">
                                    {groups.length > 0 && (
                                        groups.map((group, index) => {
                                            return (
                                                <div className="kt-wizard-v4__nav-item nav-item cursor-pointer flex-basis-20 clip-path"
                                                     style={(loadingQuestions || isSaveAssessments) ? { pointerEvents: "none" } : { }}
                                                     data-ktwizard-state={group.viewOrder === currentGroupViewOrderId ? 'current' : ''}>
                                                    <div className="kt-wizard-v4__nav-body"
                                                         onClick={() => onClickChangePage({ group: group })}>
                                                        <div className="kt-wizard-v4__nav-number">
                                                            {group.shortName}
                                                        </div>
                                                        <div className="kt-wizard-v4__nav-label">
                                                            <div className="kt-wizard-v4__nav-label-title heading-primary">
                                                                {group.name}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {(currentGroupId === group.key && group.key !== CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key) &&
                                                                <div onClick={showInstructionHeader} className="edit-assessment-info-container kt-ml-10">
                                                                    <img src={Info} alt="Information" />
                                                                    {/*<p style={{color: "red"}}>INGG</p>*/}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )}
                                </div>
                            </div>
                            <div className="kt-portlet h-90 position-relative shadow-none">
                                <div className="kt-portlet__body kt-portlet__body--fit">
                                    <div className="kt-grid bg-white">
                                        <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v4__wrapper">
                                            {loadingQuestions && (
                                                <div className="kt-spinner kt-spinner--lg kt-spinner--info kt-mt-100 kt-mb-100"/>
                                            )}
                                            {!loadingQuestions && (
                                                <form className="kt-form w-98 kt-padding-5" noValidate="novalidate">
                                                    {groups.length > 0 && (
                                                        groups.map((group, index) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    {group.key === 4 && (<>
                                                                        <div className={`edit-assessment-chief-complaint-container w-100 ${currentGroupId != 4 ? 'd-none' : ''}`}>
                                                                            {/*{MemoizeChiefComplaint}*/}
                                                                            <ChiefComplaint
                                                                              testId={testId}
                                                                              isReportAvailable={isReportAvailableRef.current}
                                                                              ccQuestionRef={ccQuestionRef}
                                                                              getCheckedOption={getCheckedOption}
                                                                              onChange={onChangeCollectResponse}
                                                                              updateAnswer={_updateAnswer}
                                                                              isSavingNoCC={isSavingNoCC}
                                                                              isAlreadyCCQuestionAnswered={() => doesCCQuestionAnswered(ccQuestionRef)}
                                                                            />
                                                                        </div>
                                                                    </>)}
                                                                    {currentGroupId && currentGroupViewOrderId && group.viewOrder === currentGroupViewOrderId && (
                                                                        <>
                                                                            {subGroups.length > 0 && (
                                                                                subGroups.map((subGroup, index) => {
                                                                                    return (
                                                                                        <Fragment
                                                                                            key={index}>
                                                                                            {((currentGroupId !== CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key
                                                                                                    && subGroups.indexOf(subGroup) === subGroupId)
                                                                                            ) && (
                                                                                                <>
                                                                                                    <div
                                                                                                        className={`edit-assessment-subgroup`}>
                                                                                                        {currentGroupId === CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.key ? subGroups[tabCount] : subGroup}
                                                                                                    </div>
                                                                                                    {pageCaptions.length > 0 && (
                                                                                                        pageCaptions.map((pageCaption, index) => {
                                                                                                            return (
                                                                                                                <Fragment
                                                                                                                    key={index}>
                                                                                                                    {((currentGroupId !== CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key && pageCaptions.indexOf(pageCaption) === pageCaptionId) || currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) && (
                                                                                                                        <>
                                                                                                                            {pageCaption && (<div className="edit-assessment-page-caption">{pageCaption}</div>)}
                                                                                                                            {subGroupQuestions && subGroupQuestions.length > 0 && (
                                                                                                                                <div className=" kt-mb-0 kt-padding-b-0 kt-mt-10" data-ktwizard-state="current">
                                                                                                                                    {subGroupQuestions.map((question, index) => {
                                                                                                                                            return (
                                                                                                                                                <Fragment
                                                                                                                                                    key={index}>
                                                                                                                                                    {((currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key && question.header2 === subGroup) ||
                                                                                                                                                            (currentGroupId !== CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key)) && !question['isDefaultHide'] &&
                                                                                                                                                        (
                                                                                                                                                            <>
                                                                                                                                                                {subGroup === 'RATE YOUR LEVEL OF PAIN' && (
                                                                                                                                                                    <label
                                                                                                                                                                        className="kt-section__title">
                                                                                                                                                                        <PainRate/>
                                                                                                                                                                    </label>
                                                                                                                                                                )}
                                                                                                                                                                {(currentGroupId === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key && previousRegion.current !== question.header1 &&
                                                                                                                                                                    _checkCurrentRegion(question.header1) && subGroup !== previousRegion.current) && (
                                                                                                                                                                    <div
                                                                                                                                                                        className="edit-assessment-subgroup">
                                                                                                                                                                        {previousRegion.current}
                                                                                                                                                                    </div>
                                                                                                                                                                )}
                                                                                                                                                                {(question.responseType === CONSTANT.ELEMENT_TYPES.TEXT
                                                                                                                                                                        || question.responseType === CONSTANT.ELEMENT_TYPES.CALCULATION
                                                                                                                                                                        || question.responseType === CONSTANT.ELEMENT_TYPES.NUMBER) &&
                                                                                                                                                                    question.questionId !== CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH && (
                                                                                                                                                                        <InputBox
                                                                                                                                                                            disabled={question.isScoreQuestion || isReportAvailableRef.current}
                                                                                                                                                                            label={question.text}
                                                                                                                                                                            placeHolder={question.dialogue}
                                                                                                                                                                            value={getValue(question.responseType, question.questionId)}
                                                                                                                                                                            question={question}
                                                                                                                                                                            error={isClicked && checkValidityForInputBox(question)}
                                                                                                                                                                            onChange={onChangeCollectResponse}/>
                                                                                                                                                                    )}
                                                                                                                                                                {question.responseType === CONSTANT.ELEMENT_TYPES.BUTTON && question.responses && question.responses.length > 0 && (
                                                                                                                                                                    <RadioButton
                                                                                                                                                                        disabled={isReportAvailableRef.current}
                                                                                                                                                                        isFocused={question.questionId === nextQuestionOrderId}
                                                                                                                                                                        label={question.text}
                                                                                                                                                                        question={question}
                                                                                                                                                                        options={question.responses}
                                                                                                                                                                        checkedOption={getCheckedOption(question.questionId, question.responses)}
                                                                                                                                                                        onChange={onChangeCollectResponse}
                                                                                                                                                                        errorMessage={isClicked && checkValidityForRadioButton(question)}
                                                                                                                                                                        cssStyle={currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key || isMobile ? 'column' : 'row'}/>
                                                                                                                                                                )}
                                                                                                                                                                {((question.responseType === CONSTANT.ELEMENT_TYPES.CHECKBOX || question.responseType === CONSTANT.ELEMENT_TYPES.MULTISELECT) && question.responses && question.responses.length > 0) && (
                                                                                                                                                                    <Checkbox
                                                                                                                                                                        disabled={isReportAvailableRef.current}
                                                                                                                                                                        isFocused={question.questionId === nextQuestionOrderId}
                                                                                                                                                                        label={question.text}
                                                                                                                                                                        question={question}
                                                                                                                                                                        options={question.responses}
                                                                                                                                                                        checkedOption={getCheckedOption(question.questionId, question.responses)}
                                                                                                                                                                        onChange={onChangeCollectResponse}
                                                                                                                                                                        errorMessage={isClicked && checkValidityForRadioButton(question)}
                                                                                                                                                                        clickOnThumbnail={clickOnThumbnail}
                                                                                                                                                                        cssStyle={currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key || isMobile ? 'column' : 'row'}/>
                                                                                                                                                                )}
                                                                                                                                                                {((question.responseType === CONSTANT.ELEMENT_TYPES.TEXT && question.questionId === CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH)
                                                                                                                                                                    || question.responseType === CONSTANT.ELEMENT_TYPES.DATE_PICKER) && (
                                                                                                                                                                    <MaskInput
                                                                                                                                                                        disabled={isReportAvailableRef.current}
                                                                                                                                                                        label={question.text}
                                                                                                                                                                        value={getValue(question.responseType, question.questionId)}
                                                                                                                                                                        placeHolder={question.dialogue}
                                                                                                                                                                        question={question}
                                                                                                                                                                        mask={(question.questionId === CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH || question.responseType === CONSTANT.ELEMENT_TYPES.DATE_PICKER) ? '99/99/9999' : '99/99/9999'}
                                                                                                                                                                        error={isClicked && checkValidityForInputBox(question)}
                                                                                                                                                                        onChange={onChangeCollectResponse}/>
                                                                                                                                                                )}
                                                                                                                                                                {question.responseType === CONSTANT.ELEMENT_TYPES.DROPDOWN && (
                                                                                                                                                                    <DropdownItem
                                                                                                                                                                        label={question.text}
                                                                                                                                                                        disabled={isReportAvailableRef.current}
                                                                                                                                                                        // value={getValue(question.responseType, question.questionId)}
                                                                                                                                                                        checkedOption={getCheckedOption(question.questionId, question.responses, true)}
                                                                                                                                                                        value={question.responses}
                                                                                                                                                                        placeHolder={question.dialogue}
                                                                                                                                                                        question={question}
                                                                                                                                                                        mask={(question.questionId === CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH || question.responseType === CONSTANT.ELEMENT_TYPES.DATE_PICKER) ? '99/99/9999' : '99/99/9999'}
                                                                                                                                                                        errorMessage={isClicked && checkValidityForRadioButton(question)}
                                                                                                                                                                        onChange={onChangeCollectResponse}/>
                                                                                                                                                                )}
                                                                                                                                                            </>
                                                                                                                                                        )}
                                                                                                                                                </Fragment>
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                    )}
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </Fragment>
                                                                                                            )
                                                                                                        })
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </Fragment>
                                                                                    )
                                                                                })
                                                                            )}
                                                                            {subGroupQuestions && subGroupQuestions.length === 0 && !loadingQuestions && (
                                                                                <>
                                                                                    {((currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key && isBodyPartSelected) || currentGroupId !== CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) && (
                                                                                        <div className="kt-mt-100 kt-mb-100 text-center">
                                                                                            {
                                                                                                currentGroupId === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key ? (
                                                                                                <>
                                                                                                    {previousResponse?.InvitationTypeText?.toLowerCase() === CONSTANT.REDIRECT_TYPE.SELF ?
                                                                                                        (
                                                                                                            <span className="font-bold">To resume the biometric, click 'Resume Biometric'. To close it, click (X).</span>
                                                                                                        ): <span className="font-bold">If you have finished modifying or viewing, please click the (X) button to close the tab.</span>
                                                                                                    }
                                                                                                </>
                                                                                            ) :
                                                                                                <span>
                                                                                                    No Available Question
                                                                                                </span>
                                                                                            }
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                            {errorMessage && (
                                                                                <div
                                                                                    className="text-center kt-mt-10 kt-mb-10 kt-font-danger kt-font-lg">
                                                                                    {errorMessage}
                                                                                </div>
                                                                            )}
                                                                            {successMessage && (
                                                                                <div
                                                                                    className="text-center kt-mt-10 kt-mb-10 kt-font-success kt-font-lg">
                                                                                    {successMessage}
                                                                                </div>
                                                                            )}
                                                                            {((subGroupQuestions && subGroupQuestions.length > 0) || (group.key === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key)) && (
                                                                                <div
                                                                                    className={`kt-form__actions kt-mb-10 ${group.key === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key &&
                                                                                    subGroupQuestions && subGroupQuestions.length === 0 && (!isMobile) ? '' : 'kt-mt-15'}`}>
                                                                                    <div className="d-flex justify-content-center flex-grow-1">
                                                                                        <button
                                                                                            className={`button edit-assessment-but edit-assessment-but-pre-next edit-padding-right-4-rem
                                                                                            ${currentGroupId === CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key && subGroupId === 0 && pageCaptionId === 0 ? 'd-none' : ''}`}
                                                                                            onClick={onClickPrevious}
                                                                                            disabled={isSaveAssessments}>
                                                                                            <i className="fa fa-angle-left padding-right-4-rem"/>
                                                                                            Previous
                                                                                        </button>
                                                                                        <button
                                                                                            className={`button edit-assessment-but edit-assessment-but-pre-next edit-padding-left-4-rem
                                                                                        ${group.key === groups[groups.length > 0 ? groups.length-1: 0]?.key ? 'd-none': ''}`}
                                                                                            onClick={onClickNext}
                                                                                            disabled={isSaveAssessments}>
                                                                                            Next
                                                                                            <i className="fa fa-angle-right padding-left-4-rem"/>
                                                                                        </button>
                                                                                    </div>
                                                                                    <button
                                                                                        className={`button edit-assessment-but edit-assessment-but-submit z-10 ${((groups.length === 4 && currentGroupViewOrderId === CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.viewOrder && subGroupId === subGroups.length - 1) ||
                                                                                            (groups.length === 5 && currentGroupViewOrderId === groups.length && subGroupId === subGroups.length - 1) ||
                                                                                            (group.key === CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.key) ||
                                                                                            (group.key === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key) ||
                                                                                            (group.key === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key)) ? '' : 'd-none'}`}
                                                                                        disabled={isSaveAssessments || isReportAvailableRef.current}
                                                                                        onClick={onClickSubmit}>
                                                                                        {isSaveAssessments && (
                                                                                            <>
                                                                                                {/*<i className="fa fa-spinner fa-spin"/>*/}
                                                                                                Saving
                                                                                            </>
                                                                                        )}
                                                                                        {!isSaveAssessments && (
                                                                                            <>
                                                                                                {/*<i className="fa fa-check"/>*/}
                                                                                                Submit
                                                                                            </>
                                                                                        )}
                                                                                    </button>
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </Fragment>
                                                            )
                                                        })
                                                    )}
                                                </form>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>}
            {/*{openMovementModal && (*/}
            {/*    <MovementModal*/}
            {/*        open={openMovementModal}*/}
            {/*        selectedRegion={{ bodyRegionList: bodyRegionList }}*/}
            {/*        testId={testId}*/}
            {/*        onClose={() => window.location.replace(`/#${routes.myAssessments}/false`)}/>*/}
            {/*)}*/}
            {/*<Telemedicine*/}
            {/*    onClose={afterCloseTelemedicineModal}*/}
            {/*    afterInitializeTelemedicine={afterInitializeTelemedicineModal}*/}
            {/*/>*/}
            <SweetAlert
                show={alertMessage !== ""} onConfirm={onConfirmAlert} title={alertTitle} type={alertType}
                closeOnClickOutside
                customButtons={
                    <>
                        {customButtons.length === 0 && (
                            <button
                                className="btn btn-primary btn-wide"
                                onClick={() => {
                                    onConfirmAlertFunction();
                                    onConfirmAlert();
                                }}>
                                OK
                            </button>
                        )}
                        {customButtons.length > 0 && (
                            <>
                                {customButtons.map((button, index) => {
                                    return (
                                        <button
                                            key={index} className={`btn ${button.styleClass} btn-wide kt-mr-5`}
                                            disabled={isSaveAssessments|| joiningTelemedicine || sendingEmail}
                                            onClick={() => {
                                                button?.task && button.task();
                                                if(!button?.waitForSave) {
                                                    onConfirmAlert();
                                                }
                                            }}>
                                            {(button?.waitForSave && (isSaveAssessments || (joiningTelemedicine && button.title == joinNowText) || (sendingEmail  && button.title == joinLaterText)))
                                                ? <><i className="fa fa-spinner fa-spin kt-font-primary"/> {' '}</>
                                                : ""
                                            }
                                            {button.title}
                                        </button>
                                    )
                                })}
                            </>
                        )}
                    </>
                }>
                {alertMessage}
            </SweetAlert>
            <Modal
                isOpen={showIntakeForm}
                backdrop={"static"}
                className="modal-xl">
                <ModalHeader>
                    <span className='intake_form_header'>Intake Form</span>
                </ModalHeader>
                <div>
                    <IntakeForm
                        handler={showPolicyHandler}
                        onClickJoinTelemedicineNow={onClickJoinTelemedicineNow}
                        onClickJoinTelemedicineLater={onClickJoinTelemedicineLater}
                        termsAndConditionModalHandler={termsAndConditionModalHandler}>
                        <>
                            <button
                                id='JOIN_NOW'
                                type='submit'
                                className="btn btn-success btn-wide kt-ml-20"
                                disabled={joiningTelemedicine || sendingEmail}>
                                {
                                    joiningTelemedicine
                                        ? <span><i className="fa fa-spinner fa-spin"/> JOINING... </span>
                                        : <span>JOIN NOW</span>
                                }
                            </button>
                            <button
                                id='JOIN_LATER'
                                type='submit'
                                className="btn btn-danger btn-wide kt-ml-20"
                                disabled={joiningTelemedicine || sendingEmail}>
                                {
                                    sendingEmail
                                        ? <span><i className="fa fa-spinner fa-spin"/> JOIN LATER </span>
                                        : <span>JOIN LATER</span>
                                }
                            </button>
                        </>
                    </IntakeForm>
                </div>
            </Modal>
            {/*{takeBiometricMovementModal && (*/}
            {/*    <Modal*/}
            {/*        isOpen={takeBiometricMovementModal}*/}
            {/*        backdrop={'static'}*/}
            {/*        className="modal-xl kt-m-0">*/}
            {/*        <ModalHeader*/}
            {/*            toggle={() => {*/}
            {/*                setTakeBiometricMovementModal(false);*/}
            {/*                setIsOpening(false);*/}
            {/*            }}*/}
            {/*            close={closeBtn}*/}
            {/*            className="d-flex align-items-center text-center movement-caution"*/}
            {/*        >*/}
            {/*            Caution*/}
            {/*        </ModalHeader>*/}
            {/*        <TakeBiometricMovements*/}
            {/*            setIsOpening={setIsOpening}*/}
            {/*            data={rowValue}*/}
            {/*            onClickMovementCell={onClickMovementCell}*/}
            {/*            setTakeBiometricMovementModal={setTakeBiometricMovementModal}*/}
            {/*            openTakeBiometricMovementHandler={openTakeBiometricMovementHandler}>*/}
            {/*            <div className="modal-footer justify-content-center">*/}
            {/*                <button*/}
            {/*                    type="submit" className="btn btn-primary"*/}
            {/*                    disabled={isOpening}>{isOpening ? "Saving..." : "Submit"}*/}
            {/*                </button>*/}
            {/*            </div>*/}
            {/*        </TakeBiometricMovements>*/}
            {/*    </Modal>*/}
            {/*)}*/}
            {openBiometricMovementAndCautionQuestionContainer &&
                <BiometricMovementAndCautionQuestionContainer
                    data={{
                        testId: selectedExamRef.current?.TestId || '',
                        selectedBodyRegions: {bodyRegionList : selectedExamRef.current?.BodyRegionList || [] },
                        openBiometricMovementAndCautionQuestionContainer,
                    }}
                    setOpenBiometricMovementAndCautionQuestionContainer={setOpenBiometricMovementAndCautionQuestionContainer}
                />
            }
            <PrivacyPolicy show={showPolicy} handler={showPolicyHandler}/>
            <DisplayUrlContent
                open={termsAndConditionModal}
                url={Mmh_terms_and_service}
                onToggleModal={termsAndConditionModalHandler}>
            </DisplayUrlContent>
        </>
    )
};

export default EditAssessmentDetails;
