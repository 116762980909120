import CONSTANT from "../constants/Constant";

export default class ChatRequest {
    constructor(options) {
        options = options || {};

        this.id = options.id || 0;
        this.questionId = options.questionId || "";
        this.answers = options.answers || ["hello"];
        this.intent = options.intent || 'initial';
        this.email = options.email || "";
        this.patientId = options.patientId || "";
        this.gender = options.gender || "";
        this.tenant = options.tenant || "";
        this.providerId = options.providerId || "";
        this.sessionId = options.sessionId || 0;
        this.lang = options.lang || CONSTANT.DEFAULT_LANGUAGE;
        this.loggedTime = options.loggedTime || new Date();
        this.demographicsCompleted = options.demographicsCompleted || false;
        this.referEnabled = options.referEnabled || false;
        this.referType = options.referType || '';
        this.employerEnabled = options.employerEnabled || false;
        this.referenceId = options.referenceId || '';
        this.isMobile = options.isMobile || false;
        this.chatEnded = options.chatEnded || false;
    }
}
