import e from "cors";
import React from "react";

const RenderContent = ({onClickViewContent, exercise, text, cssClass}) => {

    const onClickRenderContent = (e) => {
        e.preventDefault();
        onClickViewContent(exercise)
    }

    return (
        <>
            <span className="kt-widget2__time kt-ml-15">
                <a className="kt-link"
                href="javascript:void(0)"
                rel="noopener noreferrer"
                onClick={(e) => onClickRenderContent(e)}>
                    <i className={cssClass}/>&nbsp;{text}
                </a>
            </span>
        </>
    );
}

export default RenderContent;