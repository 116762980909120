import { deepClone, playAudio } from "../../../../utils/Utils";
import ChatRequestDetail from "../../../../models/ChatRequestDetail";
import CONSTANT from "../../../../constants/Constant";
import { setIsLoadingCC, setOpenChiefComplaintModal } from "../../../../features/chat/chatbotSlice";
import { onSaveSingleClick } from "../ChatServices";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import useChatbot from "./useChatbot";

const useChiefComplaintAtBot = () => {
    const dispatch = useDispatch();
    const { prepareChatbotAnswer, onChangeUpdateChatsState } = useChatbot();

    const chiefComplaintModalToggle = async (chat, response, forEdit) => {
        playAudio('remove').then();
        let deepClonedChat = deepClone(chat);

        deepClonedChat.answers = [{
            ...new ChatRequestDetail(response)
        }];

        if (response?.referenceId === CONSTANT.REFERENCE_RESPONSE.NO) { // 2 for I will do later
            onSubmitChiefComplaint(deepClonedChat);
            return;
        }

        // _setBodyRegionList(chats, chat);

        if (forEdit) {
            deepClonedChat.mode = true;
        }

        if (response?.referenceId === CONSTANT.REFERENCE_RESPONSE.YES) {
            dispatch(setIsLoadingCC(true));
            const success = await onSaveSingleClick(deepClonedChat);
            onChangeUpdateChatsState(deepClonedChat);
            dispatch(setIsLoadingCC(false));

            if (!success) {
                toast.error("Something went wrong, please try again later.");
                return;
            }
        }

        dispatch(setOpenChiefComplaintModal((prevState) => !prevState));
    };

    const onCloseChiefComplaint = () => {
        dispatch(setOpenChiefComplaintModal(false));
    };

    const onSubmitChiefComplaint = async (chat) => {
        dispatch(setOpenChiefComplaintModal(false));
        await prepareChatbotAnswer(chat);
    };

    return { chiefComplaintModalToggle, onCloseChiefComplaint, onSubmitChiefComplaint };
}

export default useChiefComplaintAtBot;