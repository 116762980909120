import {MEASURED_EXERCISES_URL} from './UrlManager';
import axios from '../../../../../src/services/Axios';
import GLOBAL from "../../../../global";
import CONSTANT from '../../../../constants/Constant';
import { error, success } from "../../../../services/ResponseHandler";

const RESPONSE_TYPE = {responseType: "text"};

export const getMeasuredExercises = async (testId, bodyRegionList) => {
    const payload = {
        Tenant: GLOBAL.TENANT,//VA PORTAL API
        TestId: testId,
        PatientId: (GLOBAL?.USER_INFO && GLOBAL?.USER_INFO?.['ContactId']) || '',//VA PORTAL API
        BodyRegionList: bodyRegionList || [],
        SelectedExerciseType: [CONSTANT.MOVEMENT_TYPES.ROM]
    };

    try {
        const response = await axios.post(MEASURED_EXERCISES_URL, payload, RESPONSE_TYPE);
        return success(response);
    } catch (e) {
        return error(e);
    }
};