// import "./SignUp.scss";
import "../../assets/sass/components/signUp/_signUp.scss";
import "../../assets/sass/components/signIn/_signIn.scss";

import React, { useEffect, useRef, useState } from "react";
// import { Formik } from "formik";
import * as Yup from "yup";
import { Trans, useTranslation } from "react-i18next";
import debounce from "lodash/debounce";

import GLOBAL from "../../global";
// import LogoPng from "../../assets/img/logo/logo.png";
import { getUserIdentification } from "../../utils/Utils";
import PasswordStrengthChecker from "./PasswordStrengthChecker";
import authService from "../../services/AuthService";
import leftIcon from "../../assets/img/signin/leftIcon.svg";
import doctorIcon from "../../assets/img/signin/doctor-icon.svg";
import topRoundIcon from "../../assets/img/signin/topRound.svg";
import mmhColorLogo from "../../assets/img/signin/mmhLogo.svg";
import bottomRoundIcon from "../../assets/img/signin/bottomRound.svg";
// import { Link } from "react-router-dom";
// import routes from "../../constants/Routes";
import useForm from "../../hooks/useForm";
import {toast} from "react-toastify";
import VerifyInvitationDto from "./dto/VerifyInvitationDto";
import AnswerValidationService from "../../services/answerValidation/AnswerValidationService";
import answerValidationMessagesConstant from "../../services/answerValidation/answerValidationMessagesConstant";
import {
    answerValidationRuleForSpecificQuestionId
} from "../../services/answerValidation/answerValidationRuleForSpecificQuestionId";
import CONSTANT from "../../constants/Constant";

const USER_STATUS = Object.freeze({
    REGISTERED: 1,
    INVITED: 2,
    NOT_FOUND: 3
});

function SignUp1(props) {
    const { onSubmitSignUp, invalidFormElement, onClickTermOfServiceToggle, signUpCreated, errorMessage, onClickPrivacyPolicyToggle } = props;
    const [passwordStrength, setPasswordStrength] = useState(false);
    const [showPasswordStrength, setShowPasswordStrength] = useState('');
    const { i18n } = useTranslation();
    const passwordRef1 = useRef();
    const passwordRef2 = useRef();
    // const [inputType, setInputType] = useState('password');
    const [inputType, setInputType] = useState({ password: true, confirmPassword: true })
    const togglePassword = useRef(false);
    const subscription = useRef(true);
    const [validUser, setValidUser] = useState(0);
    const [checkingInvitation, setCheckingInvitation] = useState(false);

    const validationSchema = () => {
        return Yup.object().shape({
            FirstName: Yup.string()
                .required(i18n.t("common.message.error.required-field"))
                .min(2, 'Please enter at least two character'),
            LastName: Yup.string()
                .required(i18n.t("common.message.error.required-field"))
                .min(2, 'Please enter at least two character'),
            height_feet: Yup.number()
                .transform(value => isNaN(value) ? undefined : value)
                .required(i18n.t("common.message.error.required-field"))
                .integer(i18n.t("common.message.info.height-must-be-digit"))
                .moreThan(0, i18n.t("common.message.info.height-feet-must-be-between-1-t0-8"))
                .lessThan(9, i18n.t("common.message.info.height-feet-must-be-between-1-t0-8")),
            height_inch: Yup.number()
                .transform(value => isNaN(value) ? undefined : value)
                .required(i18n.t("common.message.error.required-field"))
                .integer(i18n.t("common.message.info.height-must-be-digit"))
                .moreThan(-1, i18n.t("common.message.info.height-inch-must-be-between-0-t0-11"))
                .lessThan(12, i18n.t("common.message.info.height-inch-must-be-between-0-t0-11")),
            Weight: Yup.number()
                .transform(value => isNaN(value) ? undefined : value)
                .required(i18n.t("common.message.error.required-field"))
                .integer(i18n.t("common.message.info.weight-must-be-digit"))
                .moreThan(49, i18n.t("common.message.info.weight-must-be-between-50-to-500"))
                .lessThan(501, i18n.t("common.message.info.weight-must-be-between-50-to-500")),
            Email: Yup.string()
                .email(i18n.t("common.message.error.invalid-email"))
                .required(i18n.t("common.message.error.required-field")),
            Password: Yup.string()
                .required(i18n.t("common.message.error.required-field"))
                .matches(/.*[A-Z]/, i18n.t("common.message.info.password-strength-Upper-letter"))
                .matches(/.*[a-z]/, i18n.t("common.message.info.password-strength-Lower-letter"))
                .matches(/.*\d/, i18n.t("common.message.info.password-strength-number"))
                .matches(/.*[\!\@\#\$\%\^\&\*\(\)\-\_\=\+\[\]\{\}\|;:'",<>\?\/\\]/, i18n.t("common.message.info.password-strength-special-character"))
                .min(8, i18n.t("common.message.info.password-strength-alert")),
            ConfirmPassword: Yup.string()
                .required(i18n.t("common.message.error.required-field"))
                .test(
                    'passwords-match',
                    "Password doesn't match",
                    function(value) {
                        return values?.Password === value;
                    }
                ),
                // .oneOf([Yup.ref('Password'), null], `Password doesn't match`),
            TimeZone: Yup.string()
                .required(i18n.t("common.message.error.required-field")),
            AcceptTerms: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required'),
            Gender: Yup.string()
                .required(i18n.t("common.message.error.required-field"))
                // .oneOf(['male', 'female']),
                .test(
                    'gender',
                    "Gender must be male or female",
                    function(value) {
                        const genders = ['male', 'female'];
                        return (values.Gender === "" || genders.includes(values?.Gender)) ? true : false;
                    }
                ),
            DominantHand: Yup.string()
                .required(i18n.t("common.message.error.required-field"))
                // .oneOf(['right', 'left']),
                .test(
                    'dominant hand',
                    "Dominate hand must be right or left",
                    function(value) {
                        const dominatHands = ['right', 'left'];
                        return (values.DominantHand === "" || dominatHands.includes(values?.DominantHand)) ? true : false;
                    }
                ),
        });
    };

    // Helper function to validate a single field
    const validateField = (name, value) => {
        let validationResult;

        switch (name) {
            case 'FirstName':
                validationResult = AnswerValidationService.validate(
                    CONSTANT.CHATBOT_ACCOUNT.FIRST_NAME,
                    answerValidationMessagesConstant.FIRST_AND_LAST_NAME.KEY,
                    value
                );
                return validationResult;

            case 'LastName':
                validationResult = AnswerValidationService.validate(
                    CONSTANT.CHATBOT_ACCOUNT.LAST_NAME,
                    answerValidationMessagesConstant.FIRST_AND_LAST_NAME.KEY,
                    value
                );
                return validationResult;

            case 'Email':
                validationResult = AnswerValidationService.validate(
                    CONSTANT.CHATBOT_ACCOUNT.EMAIL,
                    answerValidationMessagesConstant.EMAIL.KEY,
                    value
                );
                return validationResult;

            case 'Password':
                validationResult = AnswerValidationService.validate(
                    CONSTANT.CHATBOT_ACCOUNT.PASSWORD,
                    answerValidationMessagesConstant.PASSWORD.KEY,
                    value
                );
                return validationResult;

            case 'ConfirmPassword':
                validationResult = AnswerValidationService.validate(
                    CONSTANT.CHATBOT_ACCOUNT.CONFIRM_PASSWORD,
                    answerValidationMessagesConstant.CONFIRM_PASSWORD.KEY,
                    { value, password: values.Password }
                );
                return validationResult;

            case 'height_feet':
                validationResult = AnswerValidationService.validate(
                    CONSTANT.CHATBOT_ACCOUNT.HEIGHT_IN_FEET,
                    answerValidationMessagesConstant.HEIGHT_FEET.KEY,
                    value
                );
                return validationResult;

            case 'height_inch':
                validationResult = AnswerValidationService.validate(
                    CONSTANT.CHATBOT_ACCOUNT.HEIGHT_IN_INCH,
                    answerValidationMessagesConstant.HEIGHT_INCH.KEY,
                    {feet: values['height_feet'], value}
                );
                return validationResult;

            case 'Weight':
                validationResult = AnswerValidationService.validate(
                    CONSTANT.CHATBOT_ACCOUNT.WEIGHT,
                    answerValidationMessagesConstant.WEIGHT.KEY,
                    value
                );
                return validationResult;

            case 'Gender':
                validationResult = AnswerValidationService.validate(
                    CONSTANT.CHATBOT_ACCOUNT.GENDER_AS_SELECT,
                    answerValidationMessagesConstant.WEIGHT.KEY,
                    value
                );
                return validationResult;

            case 'DominantHand':
                validationResult = AnswerValidationService.validate(
                    CONSTANT.CHATBOT_ACCOUNT.DOMINANT_HAND_AS_SELECT,
                    answerValidationMessagesConstant.DOMINANT_HAND_AS_SELECT.KEY,
                    value
                );
                return validationResult;

            case 'AcceptTerms':
                validationResult = AnswerValidationService.validate(
                    CONSTANT.CHATBOT_ACCOUNT.TERMS_AND_CONDITIONS,
                    answerValidationMessagesConstant.TERMS_AND_CONDITIONS.KEY,
                    value
                );
                return validationResult;

            default:
                break;
        }
    };

    const initialValuesForSignUp = {
        FirstName: "",
        LastName: "",
        height_feet: "",
        height_inch: "",
        Weight: "",
        Email: GLOBAL.URL_PARAMS.EMAIL_ADDRESS ? GLOBAL.URL_PARAMS.EMAIL_ADDRESS : '',
        Password: "",
        ConfirmPassword: "",
        HearAboutUs: "",
        // TimeZone: { value: "Eastern Standard Time", label: "(UTC-05:00) Eastern Time (US & Canada)" },
        TimeZone: "Eastern Standard Time",
        AcceptTerms: false,
        Gender: "",
        DominantHand: "",
    };

    const { handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched, isSubmitting, setIsSubmitting } = useForm(initialValuesForSignUp, onSubmitSignUp, validateField);

    useEffect(() => {
        const hash = window.location.hash;
        const params = new URLSearchParams(hash.slice(hash.indexOf('?')));
        const InvGId = params.get('InvGId') || params.get('invgid');

        if (InvGId) {
            // handleChange({ target: { name: 'Email', value: emailAddress, type: 'email' } });
            handleVerifyInvitation(InvGId);
            // setFieldValue('Email', emailAddress);
        }
    }, []);

    const handleClick = e => {
        // noinspection JSUnresolvedFunction
        if (passwordRef1.current.contains(e.target)) {
            setPasswordStrength(true);
            return;
        }
        if (!togglePassword.current) {
            setPasswordStrength(false);
        }
        togglePassword.current = false;
    };

    useEffect(() => {
        document.addEventListener("keypress", handleClick);
        document.addEventListener("click", handleClick);

        return () => {
            subscription.current = false;
            document.removeEventListener("keypress", handleClick);
            document.removeEventListener("click", handleClick);
        };
    }, []);


    const passwordStrengthChecker = (e) => {
        e.preventDefault();
        setShowPasswordStrength(e.target.value);
    };

    const onClickChangeInputType = (passwordType) => {
        const passwordTypeCopy = { ...inputType }
        // passwordTypeCopy[passwordType] = !passwordTypeCopy[passwordType];
        setInputType({ ...inputType, [passwordType]: !inputType[passwordType] })
    }


    const handleVerifyEmail = debounce(async (value, errorMessage) => {
        if (errorMessage || !value || checkingInvitation) return;

        setCheckingInvitation(true);
        const payload = { EmailAddress: value }
        const response = await authService.verifyEmail(payload);
        const { data, success, error } = response;
        setCheckingInvitation(false);

        if (!success) {
            toast.error("Server error: Please try again");
            console.error(error);
            return;
        }

        const { VerifyStatus, InvitationId, FirstName, LastName } = data || '{}';
        if (FirstName && LastName) {
            await setFieldValue('FirstName', FirstName);
            await setFieldValue('LastName', LastName);
        }
        GLOBAL.URL_PARAMS.INVITATION_ID = InvitationId || null;
        setValidUser(VerifyStatus);
    }, 800);

    const handleVerifyInvitation = async (invitationId) => {
        if (!invitationId) return;

        setCheckingInvitation(true);
        const payload = { InvGId: invitationId }
        const response = await authService.verifyInvitation(payload);
        const { data, success, error } = response;
        setCheckingInvitation(false);

        if (!success) {
            toast.error("Server error: Please try again");
            console.error(error);
            return;
        }

        const verifyInvitationDto = new VerifyInvitationDto(data?.EmailAddress, data?.FirstName, data?.LastName, data?.VerifyStatus);

        if (verifyInvitationDto.FirstName && verifyInvitationDto.LastName && verifyInvitationDto.EmailAddress) {
            await setFieldValue('Email', verifyInvitationDto.EmailAddress);
            await setFieldValue('FirstName', verifyInvitationDto.FirstName);
            await setFieldValue('LastName', verifyInvitationDto.LastName);
        }
        GLOBAL.URL_PARAMS.INVITATION_ID = invitationId || null;
        setValidUser(verifyInvitationDto.VerifyStatus);
    };

    const changeFloatToInt = (e, handleBlur, setFieldValue, fieldName) => {
        if(e.target.value == '' || isNaN(e.target.value)) return;
        handleBlur(e);
        const parsedValue = parseFloat(e.target.value.trim());
        const formattedValue = parsedValue.toFixed(0);
        const integerValue = parseInt(formattedValue);
        setFieldValue(fieldName, integerValue);
    }

    const handleOnKeyDown = (e) => {
        if (e.key === 'e' || e.key === '+') {
            e.preventDefault();
        }
    }

    // noinspection CheckTagEmptyBody
    return (
        <div className='signup_signin_container max-w-40vw'>
            <img src={leftIcon} className='left_icon' />
            <img src={doctorIcon} className='doctorimg' />
            <img src={topRoundIcon} className='halfround' />
            <img src={bottomRoundIcon} className='roundBottom' />
            <div className="signup_signin_form">
                {/*<Formik*/}
                {/*    initialValues={initialValuesForSignUp}*/}
                {/*    validationSchema={validationSchema()}*/}
                {/*    onSubmit={onSubmitSignUp}>*/}
                {/*    {({*/}
                {/*          handleChange,*/}
                {/*          handleSubmit,*/}
                {/*          handleBlur,*/}
                {/*          values,*/}
                {/*          errors,*/}
                {/*          touched,*/}
                {/*          isSubmitting,*/}
                {/*          setFieldValue,*/}
                {/*          setFieldTouched,*/}
                {/*          setErrors,*/}
                {/*        setValues,*/}
                {/*        setTouched,*/}
                {/*          validateForm,*/}
                {/*          validateField*/}
                {/*      }) => */}
                    <div>
                        <div className="color_logo_mmh">
                            {GLOBAL.LOGO_URL ? (
                                <img src={GLOBAL.LOGO_URL} alt="MMH Logo" />) : (
                                <img src={mmhColorLogo} alt="MMH Logo" />)}
                        </div>
                        <div>
                            <div className="signup_signin_form_header">
                                <p> New {getUserIdentification()} Registration </p>
                            </div>
                            <div className="margin-bottom-1-rem">
                                {validUser === USER_STATUS.NOT_FOUND && (
                                    <div className="alert alert-danger" role="alert">
                                        <div className="alert-text">Public registration is disabled for now, Please contact
                                            with your provider.
                                        </div>
                                    </div>
                                )}
                                {validUser === USER_STATUS.REGISTERED && (
                                    <div className="alert alert-danger" role="alert">
                                        <p className="alert-text error-message">
                                            You are a registered user. Please log in from the sign-in page by entering your email and password.
                                        </p>
                                    </div>
                                )}
                                {validUser === USER_STATUS.INVITED && (
                                    <div className="alert alert-success" role="alert">
                                        <div className="alert-text">You have been invited to register by your provider.
                                        </div>
                                    </div>
                                )}
                            </div>
                            <form className="position-relative" onSubmit={handleSubmit}>
                            {/*<form className={`${checkingInvitation ? "blurry pointer-none cursor-not-allowed " : ""}`} onSubmit={handleSubmit}>*/}
                                <div>
                                    <p className={`z-10 position-center font-bold color-primary font-size-md ${checkingInvitation ? " d-block ": " d-none "}`}>Verifying your invitation please wait...</p>
                                    <div className={`${checkingInvitation ? "blurry pointer-none cursor-not-allowed " : ""}`}>
                                        <div className="marginBottom2rem">
                                            <label className="signup_signin_input-label">E-mail</label>
                                            <div className="kt-input-icon sign-up-password-input-container kt-input-icon--right">
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                            <span>
                                                <i className="far fa-envelope sign-up-icon"></i>
                                            </span>
                                        </span>
                                                <input
                                                    className={`kt-mt-0 sign-up-password-input`}
                                                    type="email"
                                                    disabled={!!GLOBAL.URL_PARAMS.EMAIL_ADDRESS}
                                                    placeholder="Enter your email address"
                                                    name="Email"
                                                    autoComplete="off"
                                                    onChange={async (e) => {
                                                        await handleChange(e);
                                                        // await handleVerifyEmail(e.target['value'], errors?.Email, setFieldValue, setFieldTouched, validateForm, values, touched);
                                                        // await handleVerifyEmail(e.target['value'], errors?.Email);
                                                    }}
                                                    onBlur={async (e) => {
                                                        await handleBlur(e);
                                                        // handleVerifyEmail(e.target['value'], errors?.Email, setFieldValue, setFieldTouched, validateForm, values, touched);
                                                        await handleVerifyEmail(e.target['value'], errors?.Email);
                                                    }}
                                                    // onKeyUp={(e) => handleVerifyEmail(e.target['value'], errors?.Email)}
                                                    value={values.Email}
                                                />
                                            </div>
                                            {errors.Email && touched.Email && (
                                                <div className="sign-up-error-msg">{errors.Email}</div>
                                            )}
                                            {/*{checkingInvitation && (*/}
                                            {/*    <div className="kt-padding-l-15 kt-font-sm text-success">*/}
                                            {/*        Verifying your invitation*/}
                                            {/*    </div>*/}
                                            {/*)}*/}
                                        </div>
                                        <div className="marginBottom2rem">
                                            <div className="sign-up-name-container">
                                                <div className="signup_signin_input-container marginright">
                                                    <label className="signup_signin_input-label">First Name</label>
                                                    <input
                                                        className={`signin_input-field color-primary-dark-2`}
                                                        type="text"
                                                        placeholder="Enter your first name"
                                                        name="FirstName"
                                                        autoComplete="off"
                                                        onChange={handleChange}
                                                        maxLength='30'
                                                        onBlur={handleBlur}
                                                        value={values.FirstName}
                                                    />
                                                    {errors.FirstName && touched.FirstName && (
                                                        <div className="name-error-msg">{errors.FirstName}</div>
                                                    )}
                                                </div>
                                                <div className="signup_signin_input-container marginleft">
                                                    <label className="signup_signin_input-label">Last Name</label>
                                                    <input
                                                        className={`signin_input-field color-primary-dark-2`}
                                                        type="text"
                                                        placeholder="Enter your last name"
                                                        name="LastName"
                                                        autoComplete="off"
                                                        onChange={handleChange}
                                                        maxLength='25'
                                                        onBlur={handleBlur}
                                                        value={values.LastName}
                                                    />
                                                    {errors.LastName && touched.LastName && (
                                                        <div className="name-error-msg">{errors.LastName}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="marginBottom2rem">
                                            <div className="sign-up-name-container">
                                                <div className="signup_signin_input-container marginright">
                                                    <label className="signup_signin_input-label">Height</label>
                                                    <input
                                                        className={`signin_input-field color-primary-dark-2`}
                                                        type="number"
                                                        placeholder="Feet"
                                                        name="height_feet"
                                                        autoComplete="off"
                                                        onChange={handleChange}
                                                        onBlur={(e) => {
                                                            changeFloatToInt(e, handleBlur, setFieldValue, 'height_feet');
                                                        }}
                                                        onKeyDown={handleOnKeyDown}
                                                        value={values.height_feet}
                                                        min="1"
                                                        max="8"
                                                    />
                                                    <div></div>
                                                    {errors.height_feet && touched.height_feet && (
                                                        <div className="name-error-msg">{errors.height_feet}</div>
                                                    )}
                                                </div>
                                                <div className="signup_signin_input-container marginright">
                                                    <label className="signup_signin_input-label">&nbsp;</label>
                                                    <input
                                                        className={`signin_input-field color-primary-dark-2`}
                                                        type="number"
                                                        placeholder="Inch"
                                                        name="height_inch"
                                                        autoComplete="off"
                                                        onChange={handleChange}
                                                        // onBlur={handleBlur}
                                                        onBlur={(e) => {
                                                            changeFloatToInt(e, handleBlur, setFieldValue, 'height_inch');
                                                        }}
                                                        onKeyDown={handleOnKeyDown}
                                                        value={values.height_inch}
                                                        min="0"
                                                        max="11"
                                                    />
                                                    <div></div>
                                                    {errors.height_inch && touched.height_inch && (
                                                        <div className="name-error-msg">{errors.height_inch}</div>
                                                    )}
                                                </div>
                                                <div className="signup_signin_input-container marginright">
                                                    <label className="signup_signin_input-label">&nbsp;</label>
                                                    <input
                                                        readOnly={true}
                                                        className={`signin_input-field color-primary-dark-2`}
                                                        type="number"
                                                        placeholder="Enter your Height(Inch.)"
                                                        autoComplete="off"
                                                        //onChange={handleChange}
                                                        //onBlur={handleBlur}
                                                        value={+values.height_feet * 12 + +values.height_inch}
                                                    />
                                                    <div></div>
                                                    {/* {errors.height_inch && touched.height_inch && (
                                                <div className="name-error-msg">{errors.height_inch}</div>
                                            )} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="marginBottom2rem">
                                            <div className="sign-up-name-container">
                                                <div className="signup_signin_input-container marginleft">
                                                    <label className="signup_signin_input-label">Weight</label>
                                                    <input
                                                        className={`signin_input-field color-primary-dark-2`}
                                                        type="number"
                                                        placeholder="Enter your Weight(LBS)"
                                                        name="Weight"
                                                        autoComplete="off"
                                                        onChange={handleChange}
                                                        onBlur={(e) => {
                                                            changeFloatToInt(e, handleBlur, setFieldValue, 'Weight');
                                                        }}
                                                        value={values.Weight}
                                                        onKeyDown={handleOnKeyDown}
                                                    />
                                                    {errors.Weight && touched.Weight && (
                                                        <div className="name-error-msg">{errors.Weight}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="marginBottom2rem">
                                            <div className="sign-up-name-container">
                                                <div className="signup_signin_input-container marginright">
                                                    <label className="signup_signin_input-label">Gender</label>
                                                    <select
                                                        className={`${!values.Gender ? "color-secondary": "color-primary-dark-2"} signin_input-field `}
                                                        name="Gender"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.Gender}
                                                    >
                                                        <option value="" disabled selected>Select your gender</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                    </select>
                                                    {errors.Gender && touched.Gender && (
                                                        <div className="name-error-msg">{errors.Gender}</div>
                                                    )}
                                                </div>

                                                <div className="signup_signin_input-container marginleft">
                                                    <label className="signup_signin_input-label">Dominant Hand</label>
                                                    <select
                                                        className={`${!values.DominantHand ? "color-secondary": "color-primary-dark-2"} signin_input-field`}
                                                        name="DominantHand"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.DominantHand}
                                                    >
                                                        <option value="" disabled selected>Select your dominant hand</option>
                                                        <option value="left">Left</option>
                                                        <option value="right">Right</option>
                                                    </select>
                                                    {errors.DominantHand && touched.DominantHand && (
                                                        <div className="name-error-msg">{errors.DominantHand}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="marginBottom2rem">*/}
                                        {/*    <label className="signup_signin_input-label">E-mail</label>*/}
                                        {/*    <div className="kt-input-icon sign-up-password-input-container kt-input-icon--right">*/}
                                        {/*        <span className="kt-input-icon__icon kt-input-icon__icon--left">*/}
                                        {/*            <span>*/}
                                        {/*                <i className="far fa-envelope sign-up-icon"></i>*/}
                                        {/*            </span>*/}
                                        {/*        </span>*/}
                                        {/*        <input*/}
                                        {/*            className={`kt-mt-0 sign-up-password-input`}*/}
                                        {/*            type="email"*/}
                                        {/*            disabled={!!GLOBAL.URL_PARAMS.EMAIL_ADDRESS}*/}
                                        {/*            placeholder="Enter your email address"*/}
                                        {/*            name="Email"*/}
                                        {/*            autoComplete="off"*/}
                                        {/*            onChange={(e) => {*/}
                                        {/*                handleChange(e);*/}
                                        {/*                handleVerifyEmail(e.target['value'], errors?.Email);*/}
                                        {/*            }}*/}
                                        {/*            onBlur={handleBlur}*/}
                                        {/*            onKeyUp={(e) => handleVerifyEmail(e.target['value'], errors?.Email)}*/}
                                        {/*            value={values.Email}*/}
                                        {/*        />*/}
                                        {/*    </div>*/}
                                        {/*    {errors.Email && touched.Email && (*/}
                                        {/*        <div className="sign-up-error-msg">{errors.Email}</div>*/}
                                        {/*    )}*/}
                                        {/*    {checkingInvitation && (*/}
                                        {/*        <div className="kt-padding-l-15 kt-font-sm text-success">*/}
                                        {/*            Verifying your invitation*/}
                                        {/*        </div>*/}
                                        {/*    )}*/}
                                        {/*</div>*/}
                                        <div className="marginBottom2rem">
                                            <label className="signup_signin_input-label">Password</label>
                                            <div className="kt-input-icon sign-up-password-input-container kt-input-icon--right">
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                            <span>
                                                <i className="fas fa-lock sign-up-icon"></i>
                                            </span>
                                        </span>
                                                <input
                                                    className={`kt-mt-0 sign-up-password-input`}
                                                    ref={passwordRef1}
                                                    type={inputType.password ? "password" : "text"}
                                                    placeholder="Enter your Password"
                                                    name="Password"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.Password}
                                                    onKeyUp={(e) => passwordStrengthChecker(e)}
                                                />
                                                <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                            <span>
                                                {(values.Password && inputType.password) && (
                                                    <i onClick={() => onClickChangeInputType("password")} className="fa fa-eye sign-up-icon" />
                                                )}
                                                {(values.Password && !inputType.password) && (
                                                    <i onClick={() => onClickChangeInputType("password")} className="fa fa-eye-slash sign-up-icon" />
                                                )}
                                            </span>
                                        </span>
                                            </div>
                                            {errors.Password && touched.Password && (
                                                <div className="sign-up-error-msg">
                                                    {errors.Password}
                                                </div>
                                            )}
                                            {passwordStrength && showPasswordStrength && (
                                                <PasswordStrengthChecker password={showPasswordStrength} />
                                            )}
                                        </div>

                                        <div className="marginBottom2rem">
                                            <label className="signup_signin_input-label">Confirm Password</label>
                                            <div className="kt-input-icon sign-up-password-input-container kt-input-icon--right">
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                            <span>
                                                <i className="fas fa-lock sign-up-icon"></i>
                                            </span>
                                        </span>
                                                <input
                                                    className={`kt-mt-0 sign-up-password-input`}
                                                    ref={passwordRef2}
                                                    type={inputType.confirmPassword ? "password" : "text"}
                                                    placeholder="Enter your confirmed password"
                                                    name="ConfirmPassword"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.ConfirmPassword}
                                                />

                                                <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                            <span>
                                                {values.ConfirmPassword && inputType.confirmPassword && (
                                                    <i onClick={() => onClickChangeInputType("confirmPassword")} className="fa fa-eye sign-up-icon" />
                                                )}
                                                {(values.ConfirmPassword && (!inputType.confirmPassword)) && (
                                                    <i onClick={() => onClickChangeInputType("confirmPassword")} className="fa fa-eye-slash sign-up-icon" />
                                                )}
                                            </span>
                                        </span>
                                            </div>
                                            {(errors.ConfirmPassword && touched.ConfirmPassword) && (
                                                <div className="sign-up-error-msg">
                                                    {errors.ConfirmPassword}
                                                </div>
                                            )}
                                        </div>
                                        <div className="row kt-login__extra">
                                            <div className="padding-left-0-rem col">
                                                <label className="kt-checkbox kt-checkbox--brand">
                                                    <input
                                                        className={invalidFormElement(
                                                            "AcceptTerms",
                                                            errors,
                                                            values,
                                                            touched
                                                        )}
                                                        type="checkbox"
                                                        name="AcceptTerms"
                                                        // onChange={handleChange}
                                                        onClick={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.AcceptTerms}
                                                        checked={values.AcceptTerms} />
                                                    I agree the&nbsp;
                                                    <a href={"!#"}
                                                       rel="noopener noreferrer">
                                                        <span className="kt-link kt-login__link kt-font-bold" onClick={onClickTermOfServiceToggle}>Terms and Conditions</span>/
                                                        <span className="kt-link kt-login__link kt-font-bold" onClick={onClickPrivacyPolicyToggle}> Privacy Policy</span>
                                                    </a>
                                                    <span />
                                                    {errors.AcceptTerms && touched.AcceptTerms && (
                                                        <div className="invalid-feedback kt-font-bold">{errors.AcceptTerms}</div>
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="marginBottom2rem signup_signin_submit_wrapper">
                                            <button
                                                type="submit"
                                                className="signup_signin_submit"
                                                disabled={isSubmitting || validUser !== USER_STATUS.INVITED}
                                            >
                                                {isSubmitting ? (
                                                    <>
                                                        <i className="fa fa-spinner fa-spin" />&nbsp;&nbsp;
                                                        <Trans i18nKey="common.message.info.please-wait" />
                                                    </>
                                                ) : (
                                                    i18n.t("common.form.sign-up")
                                                )}
                                            </button>
                                            {errorMessage && (
                                                <div className="kt-login__account">
                                                    <span className="kt-login__account-msg text-danger">
                                                        {errorMessage}
                                                    </span>
                                                </div>
                                            )}
                                            {signUpCreated && (
                                                <div className="kt-login__account">
                                            <span className="kt-login__account-msg text-success">
                                                <Trans
                                                    i18nKey="common.message.success.registration" />
                                            </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                    {/*}</Formik>*/}
                <div className="signup_signin_registered">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default SignUp1;