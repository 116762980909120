import React, { useState, useEffect, useRef } from "react"

import GLOBAL from "../../global";
import ConfirmationDialog from "./Popups/ConfirmationDialog";
import examService from "../../services/ExamService";

const yesAnswers = [2002, 2004, 8000, 2000];
const questions = [
    {
        questionId: 302,
        text: "Are you pregnant?",
        options: [
            {
                answerId: 2001,
                text: "No",
                value: "no",
                checked: false
            },
            {
                answerId: 2000,
                text: "Yes",
                value: "yes",
                checked: false
            }
        ],
        ans: "No",
        name: "question302",
        userAns: null
    },
    {
        questionId: 303,
        text: "Do you have pain or other symptoms when you cough or sneeze during a bowel movement? Or, do you have back pain associated with bowel or " +
            "bladder problems, pain or other symptoms when you cough or sneeze during a bowel movement or numbness in the groin area? If so, you should not take this " +
            "test without clearance from your health professional.",
        options: [
            {
                answerId: 2003,
                text: "No",
                value: "no",
                checked: false
            },
            {
                answerId: 2002,
                text: "Yes",
                value: "yes",
                checked: false
            }
        ],
        ans: "No",
        name: "question303",
        userAns: null
    },
    {
        questionId: 304,
        text: "Have you recently felt faint or experienced any shortness of breath?",
        options: [
            {
                answerId: 2005,
                text: "No",
                value: "no",
                checked: false
            },
            {
                answerId: 2004,
                text: "Yes",
                value: "yes",
                checked: false
            }
        ],
        ans: "No",
        name: "question304",
        userAns: null
    },
    {
        questionId: 404,
        text: "Have you been in the hospital recently or have you had a medical procedure, and not been medically released to perform normal activities?",
        options: [
            {
                answerId: 8001,
                text: "No",
                value: "no",
                checked: false
            },
            {
                answerId: 8000,
                text: "Yes",
                value: "yes",
                checked: false
            }
        ],
        ans: "No",
        name: "question404",
        userAns: null
    },
];
const GEN_FEMALE = 'Female';

const redDotStyle = {
    minWidth: "13.05px",
    minHeight: "12.17px",
    backgroundColor: "red",
    borderRadius: "50%",
    marginRight: "2rem"
}

const TakeBiometricMovements = (props) => {
    const { onClickMovementCell, data, setTakeBiometricMovementModal } = props
    const gender = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['Gender']) || '';
    const { TestId, BodyRegionList } = data;
    const [answers, setAnswers] = useState([])
    const [clickSubmit, setClickSubmit] = useState(false)
    const [countryIndicationConfirmation, setCountryIndicationConfirmation] = useState(false);
    const [isOpening, setIsOpening] = useState(false);
    const [doesPatientAnsweredYes, setDoesPatientAnsweredYes] = useState(false);

    const lastCollectedAnswer = useRef(null)

    useEffect(() => {
        const answer = {};
        questions.forEach(question => { answer[question.questionId] = -1 })

        //// IF GENDER IS MALE THEN DELETE Are you pregnant? QUESTION.
        if (gender.toLowerCase() !== GEN_FEMALE.toLowerCase()) { delete answer["302"]}

        setAnswers(answer);
    }, [])

    const onSubmitDocument = async (e) => {
        e.preventDefault()
        setClickSubmit(true);
        const finalAnswers = [];
        let flag = false;
        for(const key in answers) {
            if(yesAnswers.includes(answers[key]) || answers[key] === -1) {
                flag = true;
                return ;
            }
            finalAnswers.push({ QuestionId: key, AnswerId: answers[key] })
        }


        setIsOpening(true)

        const payload = {
            BodyRegionList: BodyRegionList,
            TestId: TestId,
            Answers: finalAnswers
            // Answers: answers
        };

        const responseAssessment = await examService.saveAssessments(payload);
        const { success } = responseAssessment;

        if (!success) {
            return responseAssessment;
        }

        if (!flag) {
            onClickMovementCell && onClickMovementCell()();
        }

        setIsOpening(false)
        setTakeBiometricMovementModal && setTakeBiometricMovementModal(false);
    };

    const onConfirmCountryIndication = () => {
        const answersCopy = {...answers, [lastCollectedAnswer.current.questionId]: lastCollectedAnswer.current.answerId}
        setAnswers(answersCopy)
        checkDoesPatientAnsweredYes(answersCopy);
        GLOBAL.COUNTRY_INDICATION_CHAT = undefined;////TODO: While add Redux here will remove this
        GLOBAL.COUNTRY_INDICATION_CHAT_RESPONSE = undefined;
        setCountryIndicationConfirmation(false);
    };

    const countryIndicationClose = () => {
        setCountryIndicationConfirmation(false);
        GLOBAL.COUNTRY_INDICATION_CHAT = undefined;////TODO: While add Redux here will remove this
        GLOBAL.COUNTRY_INDICATION_CHAT_RESPONSE = undefined;
    };

    const collectAnswer = (e, questionId, answerId) => {
        e.preventDefault()
        lastCollectedAnswer.current = {questionId: questionId, answerId: answerId};
        const answersCopy = {...answers, [questionId]: answerId}
        // checkDoesPatientAnsweredYes(answersCopy);
        if (yesAnswers.includes(answerId)) {
            setCountryIndicationConfirmation(true);
            return;
        }
        checkDoesPatientAnsweredYes(answersCopy);
        setAnswers(answersCopy);
    }

    const checkDoesPatientAnsweredYes = (answers = []) => {
        for(const key in answers) {
            if(yesAnswers.includes(answers[key])) {
                setDoesPatientAnsweredYes(true);
                return;
            }
        }
        setDoesPatientAnsweredYes(false);
    }

    return (
        <>
            <form className="kt-form">
                <div className="kt-portlet__body take-biometric-movement-container">
                    {questions.map(question =>
                        <div key={question.questionId}
                             className={`form-group validated ${(question.questionId === 302 && gender.toLowerCase() === GEN_FEMALE.toLowerCase()) || (question.questionId !== 302) ? '' : 'd-none'}`}>
                            <div className="d-flex align-items-start">
                                <div className='take-biometric-movement-dot'></div>
                                <div>
                                    <label className="take-biometric-movement-question-title" htmlFor="inputSuccess1"> {question.text}</label>
                                    <div className="kt-radio-inline">
                                        {question.options.map(option =>
                                            <button className={`button take-biometric-movement-question-options ${answers[question.questionId] === option.answerId ? 'selected-option': ''}`}
                                                    onClick={(e) => collectAnswer(e, question.questionId, option.answerId)}
                                                    key={option.answerId}>{option.text}</button>
                                        )}
                                    </div>
                                    {(clickSubmit && answers[question.questionId] === -1) && <div className="invalid-feedback">This field is required.</div>}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="d-flex kt-padding-b-40 kt-padding-t-15 justify-content-center">
                    <button onClick={onSubmitDocument}
                        type="submit" className="button button-dashboard"
                        disabled={isOpening || doesPatientAnsweredYes}>
                        {isOpening ? "Saving..." : "Submit"}
                    </button>
                </div>
            </form>

            {countryIndicationConfirmation && (
                <ConfirmationDialog
                    confirmText="Yes"
                    confirmBtnCssClass="btn btn-danger"
                    cancelText="No"
                    cancelBtnCssClass="btn btn-success"
                    show={countryIndicationConfirmation}
                    onClickHeaderClose={countryIndicationClose}
                    toggleDialog={countryIndicationClose}
                    onConfirm={onConfirmCountryIndication}>
                    If you select YES you will not be able to perform your biometric movements. Are you sure?
                </ConfirmationDialog>
            )}
        </>
    )
}

export default TakeBiometricMovements;
