import axios from './Axios';
import { error, success } from "./ResponseHandler";
import GLOBAL from "../global";

const RESPONSE_TYPE = { responseType: 'text' };
const { REACT_APP_ROM_API_URL, REACT_APP_BASE_API_URL, REACT_APP_VA_API_URL } = process.env;
const VIDEO_STORE_URL = REACT_APP_ROM_API_URL + '/client_storage/';
const GENERAL_AUDIO_URL = REACT_APP_VA_API_URL + '/api/voice/GetGeneralAudios';
const GET_MY_EXERCISES = REACT_APP_BASE_API_URL + '/api/exercise/getMyExercises';
const SAVE_EXERCISES_TRACKING_URL = REACT_APP_BASE_API_URL + "/api/exercise/SaveExerciseTracking";
const SAVE_EXERCISES_PLAN_URL = REACT_APP_BASE_API_URL + "/api/exercise/ExercisePlan";
const No_START_OVER_URL = REACT_APP_ROM_API_URL + '/api/start-over';

class ExerciseService {
    async storeRecordedVideo(payload = {}, url) {
        try {
            url = url ? url : VIDEO_STORE_URL;
            payload.tenant = GLOBAL.TENANT;//ROM API
            const response = await axios.post(url, payload, RESPONSE_TYPE);

            return success({ data: response });
        } catch (e) {
            return error(e);
        }
    }

    async getGeneralAudios(payload = {}) {
        try {
            payload.Tenant = GLOBAL.TENANT;//VA PORTAL API
            const response = await axios.post(GENERAL_AUDIO_URL, payload, RESPONSE_TYPE);

            const data = JSON.parse(response.Message || '{}');
            if (data && data['ExerciseAudios']) {
                return success({ data: data['ExerciseAudios'] });
            }

            return success(response);
        } catch (e) {
            return error(e)
        }
    }

    async getMyExercises(payload = {}) {
        try {
            const response = await axios.post(GET_MY_EXERCISES, payload, RESPONSE_TYPE);
            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async saveExercisesTracking(payload = {}) {
        try {
            const response = await axios.post(SAVE_EXERCISES_TRACKING_URL, payload, RESPONSE_TYPE);
            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async saveExercisesPlan(payload = {}) {
        try {
            const response = await axios.post(SAVE_EXERCISES_PLAN_URL, payload, RESPONSE_TYPE);
            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async callStartOverApi(testId, tenant){
        try {
            let payload = {
                tenant: tenant,//ROM API
                testId: testId
            }
            const response = await axios.post(No_START_OVER_URL, payload, RESPONSE_TYPE);
            return success(response);
        } catch (e) {
            return error(e);
        }
    }
}

export default new ExerciseService();
