import React from "react";
import forwardHead from "../../../assets/img/exercises/exerciseIcon-base64/forwardHead";
import selectedMovementCancelBtn from "../../../assets/img/movement/selectedMovementCancelBtn.svg";

const SelectedMovementBox = ({selectedExercise, hideMovementBox}) => {
    return (
        <>
            <div className="row movement-shadow-box-top">
                <div className="col-md-10 font-size-20">
                    <div>
                        <span className="kt-widget2__icon kt-pr15">
                            <img src={forwardHead} alt="movement icon"/>
                        </span>
                        {selectedExercise.ExerciseDisplayName}
                    </div>
                    <div className="performing-text-container">
                        <span>
                            <small className="color-red-2">Performing</small>
                        </span>
                    </div>
                </div>
                <div className="col-md-2">
                    {selectedExercise?.IsUserCanSkip && (
                        <button className="movement-cancel-btn" onClick={hideMovementBox}>
                            <img src={selectedMovementCancelBtn} alt="movement cancel icon"/>
                        </button>
                    )}
                </div>
            </div>
        </>
    );
}

export default SelectedMovementBox;