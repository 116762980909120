import CONSTANT from "../../../../constants/Constant";

////TODO: will move to redux
export const _getBodyRegion = (chats) => {
    const selectedBodyRegionChat = chats.find(item => item.intent === CONSTANT.CHATBOT_INTENT.BODY_REGION);
    let bodyRegionList = [];
    if(selectedBodyRegionChat) {
        bodyRegionList = selectedBodyRegionChat.answers.map(answer => +answer?.id || 0);
    }

    return bodyRegionList;
}