import axios from './Axios';
import { error, success } from "./ResponseHandler";

const RESPONSE_TYPE = { responseType: 'text' };
const { REACT_APP_BASE_API_URL } = process.env;
const GET_CREDIT_CARDS_URL = REACT_APP_BASE_API_URL + "/api/payment/GetCreditCards";
const SAVING_CREDIT_CARD_URL = REACT_APP_BASE_API_URL + "/api/Payment/UpsertCreditCard";
const DELETE_CREDIT_CARD_URL = REACT_APP_BASE_API_URL + "/api/Payment/DeleteCreditCard";

class CardService {

    async saveCreditCard(payload) {
        try {
            const response = await axios.post(SAVING_CREDIT_CARD_URL, payload, RESPONSE_TYPE);

            // if (!response.Successful) {
            //     // return error(response);
            //     return {
            //         success: false,
            //         error: response?.DisplayMessage,
            //         data: [],
            //     };
            // }

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getCreditCards(payload) {
        try {
            const response = await axios.post(GET_CREDIT_CARDS_URL, payload, RESPONSE_TYPE);

            // if (!response.Successful) {
            //     // return error(response);
            //     return {
            //         success: false,
            //         error: response?.DisplayMessage,
            //         data: [],
            //     };
            // }

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async deleteCreditCard(payload) {
        try {
            const response = await axios.post(DELETE_CREDIT_CARD_URL, payload, RESPONSE_TYPE);

            // if (!response.Successful) {
            //     // return error(response);
            //     return {
            //         success: false,
            //         error: response?.DisplayMessage,
            //         data: [],
            //     };
            // }

            return success(response);
        } catch (e) {
            return error(e);
        }
    }
}

export default new CardService();