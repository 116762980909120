export default class Answer {
    constructor(options) {
        options = options || {};

        this.QuestionId = options.QuestionId || 0;
        this.QuestionTitle = options.QuestionTitle || '';
        this.ResponseIds = options.ResponseIds || [];
        this.ResponseId = options.ResponseId || 0;
        this.IsMultiSelect = options.IsMultiSelect || false;
        this.BodyLocation = options.BodyLocation || '';
        this.AnswerId = options.AnswerId || 0;
        this.AnswerTitle = options.AnswerTitle || '';
        this.GroupId = options.AnswerTitle || 0;
    }
}
