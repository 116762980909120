import { useDispatch } from "react-redux";

import {
    clearEditAssessmentData,
    setCurrentGroupId,
    setTestId
} from "../../../features/editAssessment/editAssessmentSlice";
import { fetchEditAssessmentDataAndInitData } from "../../../features/editAssessment/editAssessmentThunks";

const useEditAssessment = () => {
    const dispatch = useDispatch();

    const initEditAssessment = (testId, groupId= "") => {
        dispatch(setTestId(testId));
        if(groupId && parseInt(groupId)) {
            dispatch(setCurrentGroupId(parseInt(groupId)));
        }
        dispatch(fetchEditAssessmentDataAndInitData({ TESTID: testId }));
    };

    const clearEditAssessment = () => {
        dispatch(clearEditAssessmentData())
    }

    return {
        initEditAssessment,
        clearEditAssessment,
    }
}

export default useEditAssessment;