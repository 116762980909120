import React, {useState, useEffect} from 'react';

const StopWatch = ({isStartStopWatch, totalSeconds}) => {

    const [totalSecond, setTotalSecond] = useState(0);

    useEffect(()=>{
        if(isStartStopWatch){
            let seconds = 0;
            let interval = setInterval(() => {
                if(seconds !== totalSeconds){
                    setTotalSecond(prev => prev + 1);
                    seconds++;
                }
                else{
                    setTotalSecond(0);
                    clearInterval(interval);
                }
            }, 1000);
        }
    },[isStartStopWatch]);

    return (
        <>
            {totalSecond > 0 && (<span className="z-index-1 color-red-2 stopwatch-text">
                <span className="w-70-percent text-align-center"><strong className='white-space-pre'><span className="kt-badge kt-badge--danger kt-badge--dot kt-badge--xl stopWatchDesign"/>REC:</strong></span>
                <span className="w-30-percent text-align-center"><strong>{totalSecond}</strong></span>
            </span>)}
        </>
    );
}

export default StopWatch;