export async function setPrimaryCamera(){
    let maxResolutionWidth = 0;
    let maxResolutionHeight = 0;
    let requiredDeviceId = "";
    let deviceDetails = {};

    try {
        const result = await navigator.permissions.query({ name:'camera' });
        if (result.state === 'denied') {
            return {
                success: false,
            }
        }

        let devices = await navigator.mediaDevices.enumerateDevices();
        devices = devices.filter(item =>
            item.kind.toLocaleLowerCase().includes("videoinput")
                && !item.label.toLocaleLowerCase().includes('capture')
                && !item.label.toLocaleLowerCase().includes('tune')
                && !item.label.toLocaleLowerCase().includes('nvidia broadcast')
        );

        for(var i = 0; i < devices.length; i++){
            let device = devices[i];
            const stream = await navigator.mediaDevices.getUserMedia({deviceId: device.deviceId, video: true});
            let capabilities = stream.getVideoTracks()[0].getCapabilities();
            if(capabilities){
                if(capabilities.width.max > maxResolutionWidth){
                    maxResolutionWidth = capabilities.width.max;
                    maxResolutionHeight = capabilities.height.max
                    requiredDeviceId = device.deviceId;
                    deviceDetails = device;
                }
            }
        }

        return {
            deviceId: requiredDeviceId,
            maxWidth: maxResolutionWidth,
            maxHeight: maxResolutionHeight,
            deviceDetails: deviceDetails,
            success: true,
        };
    }
    catch (e) {
        console.error(e);
        return null;
    }
}