import React from "react";
import PauseImg from "../../../assets/img/movement/pause.svg";
import Skip from "../../../assets/img/movement/skip.svg";

const VideoTopElement = ({onClickPauseBtn, onClickSkip, selectedExercise, testId, isMovementPaused, isFullScreenVideo, isSkipping, isVideoTopElementDesabled}) => {
    return (
        <>
            <div className={isFullScreenVideo ? "display-none" : "display-flex-margin-bottom-important"}>
                <div className=" pause-skip-retake-block justify-start">
                    <button type="button"
                        disabled={isSkipping || isVideoTopElementDesabled}
                        className="movement-control-block-btn movement-control-block-btn-background-white"
                        onClick={()=>onClickPauseBtn()}>
                        {isMovementPaused && (
                            <span className="pause-btn-design">
                                <span className='pause-btn-design-text'>Pause</span>
                                <img height="20" src={PauseImg} alt="Pause"/>
                            </span>
                        )}
                        {!isMovementPaused && (
                            <span className="pause-btn-design"><span className='pause-btn-design-text'>Pause</span>
                                <img height="20" src={PauseImg} alt="Pause"/></span>
                        )}
                    </button>
                    {/*<button type="button"*/}
                    {/*    disabled={!lastMovementPerformed || lastMovementPerformed === {} || isSkipping || isVideoTopElementDesabled}*/}
                    {/*        className="movement-control-block-btn movement-control-block-btn-background-white"*/}
                    {/*        onClick={() => onClickRetakeExercise(lastMovementPerformed)}*/}
                    {/*        >*/}
                    {/*    <span className="pause-btn-design">*/}
                    {/*        <span className='pause-btn-design-text'>Retake</span>*/}
                    {/*        <img height="20" src={Retake} alt="RetakeLast"/>*/}
                    {/*    </span>*/}
                    {/*</button>*/}
                    <button type="button"
                        disabled={isSkipping || !selectedExercise?.IsUserCanSkip || isVideoTopElementDesabled}
                        className="movement-control-block-btn movement-control-block-btn-background-white"
                        onClick={() => onClickSkip(selectedExercise)}
                    >
                        {isSkipping ? <span className="pause-btn-design" style={{color: "#FFFFFF"}}><i className="fa fa-spinner fa-spin"/>&nbsp;Skipping...</span>
                        : <span className="pause-btn-design"  style={{color: "#FFFFFF"}}>
                                <span className='pause-btn-design-text'>Unable to Perform</span>
                                <img height="20" src={Skip} alt='Skip' />
                        </span>}
                    </button>
                </div>
                <div className="movement-modal-test-id">
                    {testId}
                </div>
            </div>
        </>
    );
}

export default VideoTopElement;