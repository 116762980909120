export const initialValuesForSignUp = () => {
    return {
        FirstName: "",
        LastName: "",
        Email: "",
        DateofBirth: "",
        Height: "",
        Weight: "",
        Password: "",
        AcceptTerms: false
    };
};

export const initialValuesForLogin = () => {
    return {
        Email: "",
        Password: "",
        AcceptTerms: false
    };
};

export const initialValuesForForgot = () => {
    return {
        Email: ""
    };
};

export const invalidFormElement = (fieldName, errors, values, touched) => {
    let classes = "form-control ";
    const errorField = errors[fieldName];
    const valueField = values[fieldName];
    const touchedField = touched[fieldName];

    if (errorField && touchedField) {
        classes += " is-invalid";
    }
    else if (errorField && !touchedField) {
        classes += " is-invalid";
    }
    else if (!errorField && touchedField) {
        classes += " is-valid";
    }
    else if (!errorField && valueField) {
        classes += " is-valid";
    }
    return classes;
};

export const repeatAtExercisePlanValues = () => [
    {
        DayName: 'Saturday',
        Time: '',
        IsRepeat: false,
        Error: ''
    },
    {
        DayName: 'Sunday',
        Time: '',
        IsRepeat: false,
        Error: ''
    },
    {
        DayName: 'Monday',
        Time: '',
        IsRepeat: false,
        Error: ''
    },
    {
        DayName: 'Tuesday',
        Time: '',
        IsRepeat: false,
        Error: ''
    },
    {
        DayName: 'Wednesday',
        Time: '',
        IsRepeat: false,
        Error: ''
    },
    {
        DayName: 'Thursday',
        Time: '',
        IsRepeat: false,
        Error: ''
    },
    {
        DayName: 'Friday',
        Time: '',
        IsRepeat: false,
        Error: ''
    },
]

export const notificationReminders = [15, 30, 45, 60, 75, 90, 105, 120]