import React from "react";
import {Link} from "react-router-dom"
// import ArrowLeft from "../../assets/img/utilities/arrowLeft.svg"
import crossBtn from "../../assets/img/utils/cross.svg"
import routes from "../../constants/Routes";
import IconCloseButton from "../shared/IconCloseButton";

const ShowAllQuestionsHeader = (props) => {
    // const {region, invitationType, testId, handler} = props
    const {region, invitationType, testId} = props
    return (
        <>
            <div className="edit-assessment-header-container">
                <div className="edit-assessment-header-info-container">
                    {testId && testId !== "" && <p><span className='edit-assessment-header-test-id'>Test ID: </span>{testId}</p>}
                    <div className='edit-assessment-header-region-invitation-container'>
                        {region && region !== "" && <p>Region: {region}</p>}
                        {(invitationType && invitationType !== "") && <p>Invitation type: {invitationType}</p>}
                    </div>
                </div>
                <div>

                    {/*{(invitationType && invitationType === "Self") && <button className="resume-bio-btn-padding movement-save-close bg-color-green"  onClick={handler}>Resume Biometric</button>}*/}
                    <Link to={`${routes.myAssessments}/false`}><IconCloseButton /></Link>
                </div>
            </div>
            <div className='edit-assessment-header-less-1024-px'>
                {region && region !== "" && <p>Region: {region}</p>}
                {(invitationType && invitationType !== "") && <p>Type: {invitationType}</p>}
            </div>
        </>
    )
}

export default ShowAllQuestionsHeader;