import CONSTANT from "../../../../constants/Constant";
import GLOBAL from "../../../../global";
import { convertToBase64, getAudioUrl, playAudio } from "../../../../utils/Utils";
import { checkFullBodyInBox } from '../utilities/ExerciseUtils';
import { getPercentage } from "../utilities/Utility";
import { savePainQuestions } from "./AssessementService";
import { getConditionalQuestionIds, willShowQuestion } from "../utilities/PainQuestionFormUtils";
import { processExerciseVideo } from "../../services/VideoSavingService";

export const getFullBodyInBox = (posePoints) => {
    let isFullBodyInBox = false;
    if(posePoints && posePoints.length > 0) {
        isFullBodyInBox = checkFullBodyInBox(posePoints);
    }

    return isFullBodyInBox;
}

const changeChildExercisesStatus = async (exerciseList, childExerciseListStr, exStatus) => {
    // let exList = childExerciseListStr.split(',');
    let exList = childExerciseListStr;
    for(let i = 0; i < exList.length; i++){
        let exercise = exerciseList.find(ex => ex.ExerciseCodeName === exList[i].trim());
        if(exercise && exercise !== {}){
            // if(exStatus === CONSTANT.EXERCISE_STATUS.SKIPPED.key){
            //     await saveChildSkippedExercise(exercise[0]);
            // }
            exercise.Status = exStatus;
        }
    }
}

export const getPatientId = () => {
    return (GLOBAL.USER_INFO && GLOBAL.USER_INFO['ContactId']) || '';
}

export const setMovementStatus = (exerciseList, exercise, status) => {
    if(exercise && exerciseList && exerciseList.length > 0){
        let ex = exerciseList.find(x => x.ExerciseCodeName === exercise.ExerciseCodeName);
        if(ex && ex !== {}){
            ex.Status = status;
            if(ex.MergeExercises.length > 0){
                changeChildExercisesStatus(exerciseList, ex.MergeExercises, status);
            }
        }
    }
    return exerciseList;
}

export const changeSelectedMovementStatus = (exerciseList, selectedEx, previousStatus) => {
    if(selectedEx && selectedEx !== {} && selectedEx.Status === CONSTANT.EXERCISE_STATUS.PERFORMING.key){
        if(previousStatus && previousStatus !== ''){
            return setMovementStatus(exerciseList, selectedEx, previousStatus);
        }
        return setMovementStatus(exerciseList, selectedEx, CONSTANT.EXERCISE_STATUS.NOT_PERFORMED.name);
    }
    else{
        return exerciseList;
    }
}

export const updateExerciseList = (queuedExercises, exerciseList, shouldChangePerformingStatus) => {
    return new Promise((resolve, reject) => {
        let i = 0, j = 0;

        if(queuedExercises && queuedExercises.length > 0 && exerciseList && exerciseList.length > 0){
            for(i = 0; i < queuedExercises.length; i++){
                for(j = 0; j < exerciseList.length; j++){
                    if(exerciseList[j].Status !== CONSTANT.EXERCISE_STATUS.SKIPPED.key) {
                        if(queuedExercises[i].exerciseName === exerciseList[j].ExerciseCodeName && (exerciseList[j].Status !== CONSTANT.EXERCISE_STATUS.PERFORMING.key)){
                            if(queuedExercises[i]?.IsSkipExercise) {//for after reopen a existing assessment
                                exerciseList[j].Status = CONSTANT.EXERCISE_STATUS.SKIPPED.name;
                            } else {
                                exerciseList[j].Status = queuedExercises[i].status;
                            }
                            exerciseList[j]['RawVideoURL'] = queuedExercises[i].request_output?.raw_video;
                            exerciseList[j]['RenderImageUrl'] = queuedExercises[i].request_output?.rendered_picture;
                            exerciseList[j]['StatusReason'] = queuedExercises[i]?.statusReason;
                        }
                    }
                }
            }
        }

        resolve({
            exerciseList
        });
    });
}

export const getMovementAudioUrl = (movementAudioList, exercise) => {
    return new Promise((resolve, reject) => {
        let movement = movementAudioList && movementAudioList.find(x => x["ExerciseValue"] === exercise.Value);
        if(movement && movement !== {} && movement['Instruction'] && movement['Instruction'] !== {}){
            resolve(movement['Instruction']['AudioUrl']);
        }
        resolve("");
    });
}

export const getNextMovement = async (exerciseList) => {
    return new Promise((resolve, reject)=>{
        if(exerciseList && exerciseList.length){
            let exList = exerciseList.filter(x => !x["IsInMergeExercise"] && x.Status === CONSTANT.EXERCISE_STATUS.NOT_PERFORMED.name);
            if(exList && exList.length > 0){
                resolve(exList[0]);
            }
        }
        resolve(null);
    });
}

export const getPainQuestionList = (exercise) => {
    return new Promise((resolve, reject) => {
        let painQuestions = [];
        try{
            if(exercise && exercise !== {}){
                let relatedExercises = exercise['RelatedExercises'];
                if (relatedExercises && relatedExercises.length > 0) {
                    for (let relatedExercise of relatedExercises) {
                        if (relatedExercise['Question'] && relatedExercise['Question'] !== {} && (relatedExercise['IsPainQuestion'] || relatedExercise['IsSoundQuestion']) && !relatedExercise['IsAPIServerCalcualtion']) {
                            painQuestions.push(relatedExercise['Question']);
                        }
                    }
                }
            }
            resolve(painQuestions);
        }
        catch(ex){
            resolve(painQuestions);
        }
    });
}

export const onAnalyzeMovement = async (testId, bodyRegionList, selectedExercise, recordedBlob) => {
    const exValue = selectedExercise?.ExerciseCodeName || "";
    const isMeasurementNeeded = selectedExercise && selectedExercise['IsMeasurementNeeded'] ? selectedExercise['IsMeasurementNeeded'] : false;
    const base64Image = await convertToBase64(recordedBlob);

    // const storeResponse = await storeRecordedVideo(filename, exValue, base64Image);

    // if(!storeResponse || storeResponse === '') return {success: false, message: "Failed to store recorded video"};

    const processResponse = await processExerciseVideo(testId,exValue, isMeasurementNeeded, base64Image);
    return processResponse;
}

// export const checkAllPainQuestionAnswered = (painQuestionList) => {
//     try{
//         if(painQuestionList && painQuestionList.length > 0){
//             for(let painQuestion of painQuestionList){
//                 if(painQuestion?.QuestionType !== CONSTANT.ELEMENT_TYPES.CHECKBOX) {
//                     if(!painQuestion.AnswerId || painQuestion.AnswerId.toString() === '0'){
//                         return false;
//                     }
//                 } else {
//                     if(painQuestion?.AnswerList && painQuestion?.AnswerList.length === 0) {
//                         return false;
//                     }
//                 }
//             }
//         }
//         return true;
//     }
//     catch(ex){
//         return true;
//     }
// }

export const checkAllPainQuestionAnswered = (painQuestionList) => {
    try {
        if (!painQuestionList || painQuestionList.length === 0) {
            return true;
        }

        for (const question of painQuestionList) {
            const isCheckboxType = question?.QuestionType === CONSTANT.ELEMENT_TYPES.CHECKBOX;
            const isAnswerMissing = !question.AnswerId || question.AnswerId.toString() === '0';
            const isCheckboxAnswerMissing = question?.AnswerList && question.AnswerList.length === 0;
            const shouldShowQuestion = willShowQuestion(question, painQuestionList, getConditionalQuestionIds(painQuestionList));
            if(!shouldShowQuestion) continue;

            if (
                ((isCheckboxType && isCheckboxAnswerMissing) || (!isCheckboxType && isAnswerMissing))) {
                return false;
            }
        }

        return true;
    } catch (ex) {
        console.error("Error checking pain questions:", ex);
        return true;
    }
}


// export const getFirstNotAnsweredQuestionInd = (painQuestionList) => {
//     try{
//         if(painQuestionList && painQuestionList.length > 0) {
//             for(let ind = 0; ind < painQuestionList.length; ind++){
//
//                 if(painQuestionList[ind]?.QuestionType !== CONSTANT.ELEMENT_TYPES.CHECKBOX) {
//                     if(!painQuestionList[ind].AnswerId || painQuestionList[ind].AnswerId.toString() === '0'){
//                         return ind;
//                     }
//                 } else {
//                     if(painQuestionList[ind]?.AnswerList && painQuestionList[ind]?.AnswerList.length === 0) {
//                         return ind;
//                     }
//                 }
//                 // if(!painQuestionList[ind].AnswerId || painQuestionList[ind].AnswerId.toString() === '0'){
//                 //     return ind;
//                 // }
//             }
//         }
//         return -1;
//     }
//     catch(ex){
//         return -1;
//     }
// }

export const getFirstNotAnsweredQuestionInd = (painQuestionList, conditionalQuestionList) => {
    try {
        let index = -1;
        if (!painQuestionList || painQuestionList.length === 0) {
            return index;
        }

        for (let ind = 0; ind < painQuestionList.length; ind++) {
            const question = painQuestionList[ind];
            const isCheckboxType = question?.QuestionType === CONSTANT.ELEMENT_TYPES.CHECKBOX;
            const isAnswerMissing = !question.AnswerId || question.AnswerId.toString() === '0';
            const isCheckboxAnswerMissing = question?.AnswerList && question.AnswerList.length === 0;
            const shouldShowQuestion = willShowQuestion(question, painQuestionList, conditionalQuestionList);

            if (((isCheckboxType && isCheckboxAnswerMissing) || (!isCheckboxType && isAnswerMissing)) && shouldShowQuestion) {
                index = ind;
                break;
            }
        }

        return index;
    } catch (ex) {
        console.error("Error finding first unanswered question:", ex);
        return -1;
    }
}


export const setStatusOfSelectedExercise = (exerciseList, previousStatus, exercise, prevSelectedExercise, isRetake) => {
    return new Promise((resolve, reject) => {
        if(isRetake){
            exerciseList = changeSelectedMovementStatus(exerciseList, prevSelectedExercise, previousStatus);
        }
        else if(prevSelectedExercise?.Status === CONSTANT.EXERCISE_STATUS.PERFORMING.key){
            exerciseList = setMovementStatus(exerciseList, prevSelectedExercise, CONSTANT.EXERCISE_STATUS.WAITING.key);
        }
        exerciseList = setMovementStatus(exerciseList, exercise, CONSTANT.EXERCISE_STATUS.PERFORMING.key);
        resolve(exerciseList);
    });
}

export const getTotalMovementsOfSelectedStatus = (exerciseList, status) => {
    return new Promise((resolve, reject) => {
        if(exerciseList && exerciseList.length > 0){
            let movements = exerciseList.filter(x => x.Status === status);
            resolve(movements.length);
        }
        else{
            resolve(0);
        }
    });
}

export const getMovementsOfSelectedStatus = (exerciseList, status) => {
    return new Promise((resolve, reject) => {
        if(exerciseList && exerciseList.length > 0){
            let movements = exerciseList.filter(x => x.Status === status);
            resolve(movements);
        }
        else{
            resolve(null);
        }
    });
}

export const getTotalCompleted = async (exerciseList, status1, status2, status3) => {
    let totalCompleted = await getTotalMovementsOfSelectedStatus(exerciseList, status1);
    let totalHumanExpert = await getTotalMovementsOfSelectedStatus(exerciseList, status2);
    let totalSkipped = await getTotalMovementsOfSelectedStatus(exerciseList, status3);
    return totalCompleted + totalHumanExpert + totalSkipped;
}

export const getTotalCompletedPercentage = async (exerciseList, status1, status2, status3) => {
    let totalCompleted = await getTotalCompleted(exerciseList, status1, status2, status3);
    let totalCompletedPercentage = getPercentage(totalCompleted, exerciseList.length);
    return totalCompletedPercentage;
}

export const analyzeMovementVideo = async (painQuestionList, providerEmail, testId, bodyRegionList, selectedExercise, recordedBlob) => {
    let isSavedPainQuestion = await savePainQuestions(painQuestionList, providerEmail, testId, bodyRegionList);
    if(isSavedPainQuestion){
        let response = await onAnalyzeMovement(testId, bodyRegionList, selectedExercise, recordedBlob);
        return response;
    }
    return {
        success: false,
        message: "Error on saving pain question"
    };
}

export const getLastPerformedMovement = (exerciseList, exercise) => {
    if(exercise){
        return exercise;
    }
    if(exerciseList && exerciseList.length > 0){
        let completedMovements = exerciseList.filter(x => x.Status === CONSTANT.EXERCISE_STATUS.COMPLETED.key || x.Status === CONSTANT.EXERCISE_STATUS.RETAKE_HUMAN_EXPERT.key);
        if(completedMovements && completedMovements.length > 0){
            return completedMovements[completedMovements.length-1];
        }
    }
    return null;
}

export const setCalibrationCompleted = (exercise) =>{
    return (exercise && exercise.ExerciseCodeName !== CONSTANT.EXERCISES_NAME.calibration) ? true : false;
}

export const getVideoLength = (exercise) => {
    if(exercise && exercise['VideoDuration'] && exercise['VideoDuration'] > 0)
    {
        return exercise['VideoDuration'];
    } 
    return (exercise && exercise['IsMergeExercise'] && exercise['Value'] !== CONSTANT.EXERCISES_NAME.calibration) ? 8 : 5;
}

export const hasChildInSkip = (childrenValueStr, exerciseList) => {
    if(childrenValueStr && exerciseList && exerciseList.length > 0){
        let exList = childrenValueStr.split(',');
        if(exList && exList.length > 0){
            let ex = exerciseList.find(x => x.Value === exList[0]);
            if(ex){
                return ex["IsUnableToPerformedExercise"];
            }
        }
    }
    return false;
}

export const getChildrenValue = (exercises) => {
    let childExercisesExerciseCodeName = [];
    if(exercises && exercises.length > 0) {
        for(let exercise of exercises){
            if(exercise && exercise['MergeExercises'].length > 0){
                childExercisesExerciseCodeName.push(...exercise['MergeExercises']);
            }
        }
    }

    return childExercisesExerciseCodeName;
}

export const getExercises = (exercises, exercisesName = [""]) => {
    let selectedExercises = [];
    if(exercises && exercises.length > 0) {
        for(let exercise of exercises){
            if(exercisesName.includes(exercise?.ExerciseCodeName)){
                selectedExercises.push(exercise);
            }
        }
    }

    return selectedExercises;
}

export const playPatientNotStandingFacingLeft = async () => {
    const audioUrlPatientNotStandingLeftFacing = getAudioUrl(CONSTANT.GENERAL_AUDIOS_KEY_TEXT.FACE_LEFT_WHILE_PERFORMING_EXERCISE);
    if(!audioUrlPatientNotStandingLeftFacing) return;

    await playAudio(audioUrlPatientNotStandingLeftFacing);
}

export const playPatientNotStandingFacingRight = async () => {
    const audioUrlPatientNotStandingRightFacing = getAudioUrl(CONSTANT.GENERAL_AUDIOS_KEY_TEXT.FACE_RIGHT_WHILE_PERFORMING_EXERCISE);
    if(!audioUrlPatientNotStandingRightFacing) return;

    await playAudio(audioUrlPatientNotStandingRightFacing);
}