import React from "react";

const ButtonComponent = ({btnClass, btnName, onClickHandler}) => {
    return (
        <button
            className={btnClass}
            onClick={onClickHandler}> {btnName}
        </button>
    );
}

export default ButtonComponent;