import React from "react";
import SweetAlert from 'react-bootstrap-sweetalert';

const NotificationAlert = (props) => {

    const {
        isShow,
        notificationObj,
        processingSaveAssessment
    } = props;

    const Saving = () => (<><i className="fa fa-spinner fa-spin"/>&nbsp;&nbsp;{notificationObj?.textWhileProcessingConfirmBtn ? notificationObj?.textWhileProcessingConfirmBtn : 'Saving'}</>)

    return (
        <SweetAlert
            cancelBtnCssClass={notificationObj.notificationCancelBtnCss}
            confirmBtnCssClass={notificationObj.notificationConfirmBtnCss}
            showConfirm= {notificationObj.showNotificationConfirmBtn}
            showCancel={notificationObj.showNotificationCancelBtn}
            show={isShow}
            title={notificationObj.notificationTitle}
            type={notificationObj.notificationAlert}
            onConfirm={notificationObj.onConfirmNotificationAlert}
            onCancel={notificationObj.onCancelNotificationAlert}
            cancelBtnText={notificationObj.notificationCancelBtnText}
            confirmBtnText={processingSaveAssessment ? <Saving/> : notificationObj.notificationConfirmBtnText}
            disabled={processingSaveAssessment ? true : false}
            closeOnClickOutside={notificationObj.closeNotificationOutsideClick}>
            {notificationObj.notificationAlertMessage}
        </SweetAlert>
    );
}

export default NotificationAlert;