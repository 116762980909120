import { useEffect, useState } from "react";

export default function useScroll(modalRef) {
    const [selectedElement, setSelectedElement] = useState(null);

    useEffect(() => {
        if (selectedElement !== null) {
            const elementTop = selectedElement === 0 ? 0 : selectedElement.offsetTop;
            const currentTop = modalRef.current.scrollTop;
            const scrollDiff = elementTop - currentTop;

            let startTime = null;

            const smoothScroll = (timestamp) => {
                if (!startTime) startTime = timestamp;
                const elapsedTime = timestamp - startTime;
                const progress = elapsedTime / 250;

                modalRef.current.scrollTop = currentTop + scrollDiff * Math.min(progress, 1);

                if (progress < 1) {
                    window.requestAnimationFrame(smoothScroll);
                }
            };

            window.requestAnimationFrame(smoothScroll);
        }
    }, [selectedElement]);

    return setSelectedElement;
}