import Response from './Response'

class Question {
    constructor(question = {}) {
        this.questionId = question?.QuestionId || ''
        this.dialogue = question?.Dialogue || ''
        this.isVasQuestion = question?.VasQuestion || false
        this.prepareQuestionResponse(question?.Responses)
    }

    prepareQuestionResponse = (responses = []) => {
        let questionResponses = [];
        responses.forEach(response => {
            questionResponses.push(new Response(response)) // HAS-A relation not IS-A relation.
        })
        this.responses = questionResponses;
    }
}

export default Question;