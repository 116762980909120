import React from 'react';

const PainRate = () => {
    return (
        <span className="d-flex mb-2">
            <span
                className="kt-badge kt-badge--primary kt-badge--pill kt-mr-5">
                0
            </span>
            <span className="kt-section__info kt-mb-0 font-bold">= No Pain,</span>
            <span
                className="kt-badge kt-badge--danger kt-badge--pill kt-ml-10 kt-mr-5">
                10
            </span>
            <span className="kt-section__info kt-mb-0 font-bold">= Emergency Room Visit</span>
        </span>
    )
};

export default PainRate
