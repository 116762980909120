import CONSTANT from "../../constants/Constant";
import Info from "../../assets/img/utils/info.svg";
import React from "react";
import { useSelector } from "react-redux";
import useEditAssessmentHeaderAndFooter from "./hooks/useEditAssessmentHeaderAndFooter";

const EditAssessmentQuestionHeader = () => {
    const { handleChangeCurrentGroupId, handleSetShowInstruction } = useEditAssessmentHeaderAndFooter();
    const editAssessmentGroup = useSelector(state => state.editAssessment);
    const groups = editAssessmentGroup?.groups || [];

    const baseData = editAssessmentGroup?.baseData || {};
    const currentGroupId = baseData?.currentGroupId || CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;
    const currentGroup = groups.filter(group => group.groupId === currentGroupId)[0] || {};

    return (
        <div className="kt-wizard-v4__nav">
            <div className="kt-wizard-v4__nav-items nav">
                {groups.length > 0 && (
                    groups.map((group, index) => {
                        return (
                            <div className="kt-wizard-v4__nav-item nav-item cursor-pointer flex-basis-20 clip-path"
                                data-ktwizard-state={group.groupId === currentGroupId ? 'current' : ''}
                                key={group?.groupId}
                            >
                                <div className="kt-wizard-v4__nav-body"
                                     onClick={() => handleChangeCurrentGroupId(group?.groupId)}
                                >
                                    <div className="kt-wizard-v4__nav-number">
                                        {group?.shortName}
                                    </div>
                                    <div className="kt-wizard-v4__nav-label">
                                        <div className="kt-wizard-v4__nav-label-title heading-primary">
                                            {group?.groupName}
                                        </div>
                                    </div>
                                    <div>
                                        {(currentGroup?.groupTitle && (group?.groupId === currentGroupId)) &&
                                            <div
                                                onClick={() => handleSetShowInstruction(true)}
                                                className="kt-ml-10">
                                                <img src={Info} alt="Information"/>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                )}
            </div>
        </div>
    )
}

export default EditAssessmentQuestionHeader;