export const bodyPart = [
    {
        "subBodyRegion": "Head Front",//previously=>Head
        "bodyLocation": "head",
        "cx": 190.32, "cy": 25,
        "side": 1,
    },
    {
        "subBodyRegion": "Head Right",
        "bodyLocation": "head_right",
        "cx":165.32, "cy":25,
        "side": 1,
    },
    {
        "subBodyRegion": "Head Left",
        "bodyLocation": "head_left",
        "cx":215.32, "cy":25,
        "side": 1,
    },
    {
        "subBodyRegion": "Head Back",
        "bodyLocation": "head_back",
        "cx":195.12, "cy":65.21,
        "side": 2,
    },
    {
        "subBodyRegion": "Upper Trapezius Front Right",
        "bodyLocation": "upper_trapezius_front_right",
        "cx":145.26, "cy":135.09,
        "side": 1,
    },
    {
        "subBodyRegion": "Upper Trapezius Front Left",
        "bodyLocation": "upper_trapezius_front_left",
        "cx":230.27, "cy":135.09,
        "side": 1,
    },
    {
        "subBodyRegion": "Shoulder Top Right",//previously=>Shoulder Right
        "bodyLocation": "shoulder_right",
        "cx":93.5, "cy":165.42,//previously=>"cx":87.5, "cy":174.42,
        "side": 1,
    },
    {
        "subBodyRegion": "Shoulder Top Left",//previously=>Shoulder Left
        "bodyLocation": "shoulder_left",
        "cx":285.8, "cy":165.42,//previously=>"cx":289.8, "cy":190.42,
        "side": 1,
    },
    {
        "subBodyRegion": "Shoulder Outside Left",//previously=>Shoulder Left Middle
        "bodyLocation": "shoulder_left_middle",
        "cx":310.8, "cy":180.42,//previously=>"cx":310.8, "cy":175.42,
        "side": 1,
    },
    {
        "subBodyRegion": "Shoulder Outside Right",//previously=>Shoulder Right Middle
        "bodyLocation": "shoulder_right_middle",
        "cx":66.8, "cy":175.42,//previously=>"cx":66.8, "cy":190.42,
        "side": 1,
    },
    {
        "subBodyRegion": "Shoulder Front Right",//previously=>Deltoid Right
        "bodyLocation": "deltoid_right",
        "cx":80.52, "cy":205.76,//previously=>"cx":64.52, "cy":217.76,
        "side": 1,
    },
    {
        "subBodyRegion": "Shoulder Front Left",//previously=>Deltoid Left
        "bodyLocation": "deltoid_left",
        "cx":300.2, "cy":205.76,
        "side": 1,
    },
    // {
    //     "subBodyRegion": "Sternum",
    //     "bodyLocation": "sternum",
    //     "cx":189.09, "cy":224.21,
    //     "side": 1,
    // },
    {
        "subBodyRegion": "Elbow Right",
        "bodyLocation": "elbow_right",
        "cx":64.52, "cy":352.33,
        "side": 1,
    },
    {
        "subBodyRegion": "Elbow Left",
        "bodyLocation": "elbow_left",
        "cx":307.82, "cy":352.33,
        "side": 1,
    },
    {
        "subBodyRegion": "Hip Front Right",
        "bodyLocation": "hip_front_right",
        "cx":120.16, "cy":370.47,
        "side": 1,
    },
    {
        "subBodyRegion": "Hip Front Left",
        "bodyLocation": "hip_front_left",
        "cx":260.64, "cy":370.47,
        "side": 1,
    },
    {
        "subBodyRegion": "Hip Side Right",
        "bodyLocation": "hip_side_right",
        "cx":113.16, "cy":405.47,//previously=>"cx":120.16, "cy":405.47,
        "side": 1,
    },
    {
        "subBodyRegion": "Hip Side Left",
        "bodyLocation": "hip_side_left",
        "cx":268.64, "cy":405.47,//previously=>"cx":246.64, "cy":405.47,
        "side": 1,
    },
    {
        "subBodyRegion": "Hip Right",
        "bodyLocation": "hip_right",
        "cx":134.16, "cy":458.47,
        "side": 1,
    },
    {
        "subBodyRegion": "Hip Left",
        "bodyLocation": "hip_left",
        "cx":246.64, "cy":458.47,
        "side": 1,
    },
    {
        "subBodyRegion": "Wrist Right",
        "bodyLocation": "wrist_right",
        "cx":54.83, "cy":485.68,
        "side": 1,
    },
    {
        "subBodyRegion": "Wrist Left",
        "bodyLocation": "wrist_left",
        "cx":323.14, "cy":485.68,
        "side": 1,
    },
    {
        "subBodyRegion": "Finger (Palmside) 1 Right",//previously=>Finger 1 Right
        "bodyLocation": "finger_1_right",
        "cx":25.94, "cy":522.71,
        "side": 1,
    },
    {
        "subBodyRegion": "Finger (Palmside) 2 Right",//previously=>Finger 2 Right
        "bodyLocation": "finger_2_right",
        "cx":32.32, "cy":558.59,
        "side": 1,
    },
    {
        "subBodyRegion": "Finger (Palmside) 3 Right",//previously=>Finger 3 Right
        "bodyLocation": "finger_3_right",
        "cx":46.76, "cy":563.38,
        "side": 1,
    },
    {
        "subBodyRegion": "Finger (Palmside) 4 Right",//previously=>Finger 4 Right
        "bodyLocation": "finger_4_right",
        "cx":61.21, "cy":561.97,
        "side": 1,
    },
    {
        "subBodyRegion": "Finger (Palmside) 5 Right",//previously=>Finger 5 Right
        "bodyLocation": "finger_5_right",
        "cx":73.96, "cy":545.83,
        "side": 1,
    },
    {
        "subBodyRegion": "finger (Palmside) 1 Left",//previously=>Finger 1 Left
        "bodyLocation": "finger_1_left",
        "cx":354.64, "cy":522.71,
        "side": 1,
    },
    {
        "subBodyRegion": "Finger (Palmside) 2 Left",//previously=>Finger 2 Left
        "bodyLocation": "finger_2_left",
        "cx":346.76, "cy":558.59,
        "side": 1,
    },
    {
        "subBodyRegion": "Finger (Palmside) 3 Left",//previously=>Finger 3 Left
        "bodyLocation": "finger_3_left",
        "cx":331.76, "cy":561.97,
        "side": 1,
    },
    {
        "subBodyRegion": "Finger (Palmside) 4 Left",//previously=>Finger 4 Left
        "bodyLocation": "finger_4_left",
        "cx":316.76, "cy":558.59,
        "side": 1,
    },
    {
        "subBodyRegion": "Finger (Palmside) 5 Left",//previously=>Finger 5 Left
        "bodyLocation": "finger_5_left",
        "cx":307.82, "cy":545.83,
        "side": 1,
    },
    {
        "subBodyRegion": "Thigh Front Left",//previously=>Mid Anterior Thigh Left
        "bodyLocation": "mid_anterior_thigh_left",
        "cx":242.37, "cy":555.59,
        "side": 1,
    },
    {
        "subBodyRegion": "Thigh Front Right",//previously=>Mid Anterior Thigh Right
        "bodyLocation": "mid_anterior_thigh_right",
        "cx":137.98, "cy":555.59,
        "side": 1,
    },
    {
        "subBodyRegion": "Iliotibial Band Right",
        "bodyLocation": "iliotibial_band_right",
        "cx":114.5, "cy":613.97,
        "side": 1,
    },
    {
        "subBodyRegion": "Iliotibial Band Left",
        "bodyLocation": "iliotibial_band_left",
        "cx":264.28, "cy":613.97,
        "side": 1,
    },
    {
        "subBodyRegion": "Medial (Inside) Knee Right",//previously=>Medial Knee Right
        "bodyLocation": "medial_knee_right",
        "cx":161.8, "cy":648.56,
        "side": 1,
    },
    {
        "subBodyRegion": "Knee Right",
        "bodyLocation": "knee_right",
        "cx":141.04, "cy":643.19,
        "side": 1,
    },
    {
        "subBodyRegion": "Lateral (Outside) Knee Right",//previously=>Lateral Knee Right
        "bodyLocation": "lateral_knee_right",
        "cx":120.88, "cy":648.56,
        "side": 1,
    },
    {
        "subBodyRegion": "Medial (Inside) Knee Left",//previously=>Medial Knee Left
        "bodyLocation": "medial_knee_left",
        "cx":216.64, "cy":648.56,
        "side": 1,
    },
    {
        "subBodyRegion": "Knee Left",
        "bodyLocation": "knee_left",
        "cx":235.99, "cy":642.19,
        "side": 1,
    },
    {
        "subBodyRegion": "Lateral (Outside) Knee Left",//previously=>Lateral Knee Left
        "bodyLocation": "lateral_knee_left",
        "cx":259.4, "cy":648.56,
        "side": 1,
    },
    {
        "subBodyRegion": "Front Of Leg (Below Knee) Right",//previously=>Front Of Leg Right
        "bodyLocation": "front_of_leg_right",
        "cx":144.36, "cy":754.15,
        "side": 1,
    },
    {
        "subBodyRegion": "Front Of Leg (Below Knee) Left",//previously=>Front Of Leg Left
        "bodyLocation": "front_of_leg_left",
        "cx":235.99, "cy":754.15,
        "side": 1,
    },
    {
        "subBodyRegion": "Medial (Inside) Ankle Right",//previous=>Medial Ankle Right
        "bodyLocation": "medial_ankle_right",
        "cx":168.17, "cy":865.11,
        "side": 1,
    },
    {
        "subBodyRegion": "Ankle Right",
        "bodyLocation": "ankle_right",
        "cx":147.55, "cy":862.12,
        "side": 1,
    },
    {
        "subBodyRegion": "lateral (Outside) Ankle Right",//previously=>Lateral Ankle Right
        "bodyLocation": "lateral_ankle_right",
        "cx":131.61, "cy":865.11,
        "side": 1,
    },
    {
        "subBodyRegion": "Medial (Inside) Ankle Left",
        "bodyLocation": "medial_ankle_left",
        "cx":210.27, "cy":865.11,
        "side": 1,
    },
    {
        "subBodyRegion": "Ankle Left",
        "bodyLocation": "ankle_left",
        "cx":229.51, "cy":862.12,
        "side": 1,
    },
    {
        "subBodyRegion": "Lateral (Outside) Ankle Left",//previous Lateral Ankle Left
        "bodyLocation": "lateral_ankle_left",
        "cx":246.64, "cy":865.11,
        "side": 1,
    },
    {
        "subBodyRegion": "Neck Left",
        "bodyLocation": "neck_left",
        "cx":180.12, "cy":99.21,
        "side": 2,
    },
    {
        "subBodyRegion": "Neck middle",
        "bodyLocation": "nape_of_neck",//previously=>neck_middle
        "cx":195.12, "cy":99.21,
        "side": 2,
    },
    {
        "subBodyRegion": "Neck Right",
        "bodyLocation": "neck_right",
        "cx":210.73, "cy":99.21,
        "side": 2,
    },
    {
        "subBodyRegion": "Lower Neck (C7) Left",
        "bodyLocation": "lower_neck_C7_left",
        "cx":178.12, "cy":150.21,
        "side": 2,
    },
    {
        "subBodyRegion": "Lower Neck (C7) Middle",
        "bodyLocation": "lower_neck_C7_middle",
        "cx":193.12, "cy":150.21,
        "side": 2,
    },
    {
        "subBodyRegion": "Lower Neck (C7) Right",
        "bodyLocation": "lower_neck_C7_right",
        "cx":208.73, "cy":150.21,
        "side": 2,
    },
    {
        "subBodyRegion": "Upper Trapezius Back Left",
        "bodyLocation": "upper_trapezius_back_left",
        "cx":133.14, "cy":129.65,
        "side": 2,
    },
    {
        "subBodyRegion": "Upper Trapezius Back Right",
        "bodyLocation": "upper_trapezius_back_right",
        "cx":255.76, "cy":129.65,
        "side": 2,
    },
    {
        "subBodyRegion": "Mid Back 1 Left",
        "bodyLocation": "mid_back_1_left",
        "cx":139.52, "cy":184.03,
        "side": 2,
    },
    {
        "subBodyRegion": "Mid Back 1 Right",
        "bodyLocation": "mid_back_1_right",
        "side": 2,
        "cx":246.53, "cy":184.03
    },
    {
        "subBodyRegion": "Mid Back 2 Left",
        "bodyLocation": "mid_back_2_left",
        "side": 2,
        "cx":178.59, "cy":211.53
    },
    {
        "subBodyRegion": "Mid Back 2 Middle",
        "bodyLocation": "midback_2_middle",
        "side": 2,
        "cx":193.59, "cy":211.53
    },
    {
        "subBodyRegion": "Mid Back 2 Right",
        "bodyLocation": "mid_back_2_right",
        "side": 2,
        "cx":208.73, "cy":211.53
    },
    {
        "subBodyRegion": "Mid Back 3 Left",
        "bodyLocation": "mid_back_3_left",
        "side": 2,
        "cx":177.59, "cy":272.82
    },
    {
        "subBodyRegion": "Mid Back 3 middle",
        "bodyLocation": "midback_3_middle",
        "side": 2,
        "cx":193.59, "cy":272.82
    },
    {
        "subBodyRegion": "Mid Back 3 Right",
        "bodyLocation": "mid_back_3_right",
        "side": 2,
        "cx":210.73, "cy":272.82
    },
    {
        "subBodyRegion": "Lower Back Left",
        "bodyLocation": "lower_back_left",
        "side": 2,
        "cx":177.74, "cy":359.74
    },
    {
        "subBodyRegion": "Lower Back Middle",
        "bodyLocation": "lower_back_middle",
        "side": 2,
        "cx":193.74, "cy":359.74
    },
    {
        "subBodyRegion": "Lower Back Right",
        "bodyLocation": "lower_back_right",
        "side": 2,
        "cx":209.63, "cy":359.74
    },
    {
        "subBodyRegion": "Triceps Left",
        "bodyLocation": "triceps_left",
        "side": 2,
        "cx":80.2, "cy":258.06
    },
    {
        "subBodyRegion": "Triceps Right",
        "bodyLocation": "triceps_right",
        "side": 2,
        "cx":311.82, "cy":258.06
    },
    {
        "subBodyRegion": "Hip Back Left",
        "bodyLocation": "hip_back_left",
        "side": 2,
        "cx":123.14, "cy":360.83
    },
    {
        "subBodyRegion": "Hip Back Right",
        "bodyLocation": "hip_back_right",
        "side": 2,
        "cx":263.76, "cy":360.83
    },
    {
        "subBodyRegion": "Sacroiliac Joint Left",
        "bodyLocation": "sacroiliac_joint_left",
        "side": 2,
        "cx":170.14, "cy":412.83
    },
    {
        "subBodyRegion": "Sacroiliac Joint Right",
        "bodyLocation": "sacroiliac_joint_right",
        "side": 2,
        "cx":218.76, "cy":412.83
    },
    // {
    //     "subBodyRegion": "Tailbone Left",
    //     "bodyLocation": "tailbone_left",
    //     "side": 2,
    //     "cx":177.14, "cy":450.83
    // },
    // {
    //     "subBodyRegion": "Tailbone Right",
    //     "bodyLocation": "tailbone_right",
    //     "side": 2,
    //     "cx":211.76, "cy":450.83
    // },
    {
        "subBodyRegion": "Gluteal (Buttocks) Left",//previously=>Gluteal Left
        "bodyLocation": "gluteal_left",
        "side": 2,
        "cx":133.14, "cy":397.83
    },
    {
        "subBodyRegion": "Gluteal (Buttocks) Right",//previously=>Gluteal Right
        "bodyLocation": "gluteal_right",
        "side": 2,
        "cx":255.76, "cy":397.83
    },
    {
        "subBodyRegion": "Lateral (Outside) Elbow Left",//previously=>Lateral Elbow Left
        "bodyLocation": "lateral_elbow_left",
        "side": 2,
        "cx":50.53, "cy":339
    },
    {
        "subBodyRegion": "Olecranon (Bony Back) Left",//previously=>Olecranon Left
        "bodyLocation": "olecranon_left",
        "side": 2,
        "cx":73.82, "cy":335.83
    },
    {
        "subBodyRegion": "Medial (Inside) Elbow Left",//previously=>Medial Elbow Left
        "bodyLocation": "medial_elbow_left",
        "side": 2,
        "cx":92.95, "cy":345.38
    },
    {
        "subBodyRegion": "Lateral (Outside) Elbow Right",//previously=>Lateral Elbow Right
        "bodyLocation": "lateral_elbow_right",
        "side": 2,
        "cx":335.42, "cy":335.51
    },
    {
        "subBodyRegion": "olecranon (Bony Back) Right",//previously=>Olecranon Right
        "bodyLocation": "olecranon_right",
        "side": 2,
        "cx":317.93, "cy":332.63
    },
    {
        "subBodyRegion": "Medial (Inside) Elbow Right",//previously=>Medial Elbow Right
        "bodyLocation": "medial_elbow_right",
        "side": 2,
        "cx":297.23, "cy":339
    },
    // {
    //     "subBodyRegion": "Sacroiliac Joint",
    //     "bodyLocation": "sacroiliac_joint",
    //     "side": 2,
    //     "cx":194.45, "cy":405.4
    // },
    {
        "subBodyRegion": "Coccyx",
        "bodyLocation": "coccyx",
        "side": 2,
        "cx":194.45, "cy":446.43
    },
    {
        "subBodyRegion": "Dorsum (Backside) 1 Left",//previously=>Dorsum 1 Left
        "bodyLocation": "dorsum_1_left",
        "side": 2,
        "cx":42.41, "cy":497.86
    },
    {
        "subBodyRegion": "Dorsum (Backside) 2 Left",//previously=>Dorsum 2 Left
        "bodyLocation": "dorsum_2_left",
        "side": 2,
        "cx":50.53, "cy":531.61
    },
    {
        "subBodyRegion": "Dorsum (Backside) 3 Left",//previously=>Dorsum 3 Left
        "bodyLocation": "dorsum_3_left",
        "side": 2,
        "cx":64.07, "cy":536.22
    },
    {
        "subBodyRegion": "Dorsum (Backside) 4 Left",//previously=>Dorsum 4 Left
        "bodyLocation": "dorsum_4_left",
        "side": 2,
        "cx":76.82, "cy":531.61
    },
    {
        "subBodyRegion": "Dorsum (Backside) 5 Left",//previously=>Dorsum 5 Left
        "bodyLocation": "dorsum_5_left",
        "side": 2,
        "cx":87.81, "cy":523.47
    },
    {
        "subBodyRegion": "Dorsum (Backside) 1 Right",//previously=>Dorsum 1 Right
        "bodyLocation": "dorsum_1_right",
        "side": 2,
        "cx":345, "cy":501.06
    },
    {
        "subBodyRegion": "Dorsum (Backside) 2 Right",//previous=>Dorsum 2 Right
        "bodyLocation": "dorsum_2_right",
        "side": 2,
        "cx":338.62, "cy":533.41
    },
    {
        "subBodyRegion": "Dorsum (Backside) 3 Right",//previously=>Dorsum 3 Right
        "bodyLocation": "dorsum_3_right",
        "side": 2,
        "cx":325.87, "cy":536.29
    },
    {
        "subBodyRegion": "Dorsum (Backside) 4 Right",//previously=>Dorsum 4 Right
        "bodyLocation": "dorsum_4_right",
        "side": 2,
        "cx":312.99, "cy":533.41
    },
    {
        "subBodyRegion": "Dorsum (Backside) 5 Right",//previously=>Dorsum 5 Right
        "bodyLocation": "dorsum_5_right",
        "side": 2,
        "cx":303.61, "cy":523.47
    },
    {
        "subBodyRegion": "Thigh Back Left",
        "bodyLocation": "thigh_back_left",
        "side": 2,
        "cx":136.61, "cy":523.53
    },
    {
        "subBodyRegion": "Thigh Back Right",
        "bodyLocation": "thigh_back_right",
        "side": 2,
        "cx":252.91, "cy":525.23
    },
    {
        "subBodyRegion": "Popliteal (Back Of Knee) Area Left",//previously=>Popliteal Area Left
        "bodyLocation": "popliteal_area_left",
        "side": 2,
        "cx":142.99, "cy":636.47
    },
    {
        "subBodyRegion": "Popliteal (Back Of Knee) Area Right",//previously=>Popliteal Area Right
        "bodyLocation": "popliteal_area_right",
        "side": 2,
        "cx":246.53, "cy":636.47
    },
    {
        "subBodyRegion": "Calf Left",
        "bodyLocation": "calf_left",
        "side": 2,
        "cx":142.99, "cy":752.3
    },
    {
        "subBodyRegion": "Calf Right",
        "bodyLocation": "calf_right",
        "side": 2,
        "cx":249.38, "cy":752.3
    },
    {
        "subBodyRegion": "Achilles Tendon Left",
        "bodyLocation": "achilles_tendon_left",
        "side": 2,
        "cx":150.99, "cy":840.77
    },
    {
        "subBodyRegion": "Achilles Tendon Right",
        "bodyLocation": "achilles_tendon_right",
        "side": 2,
        "cx":232.97, "cy":840.63
    },
    {
        "subBodyRegion": "Heel Left",
        "bodyLocation": "heel_left",
        "side": 2,
        "cx":142.99, "cy":870.77
    },
    {
        "subBodyRegion": "Heel Right",
        "bodyLocation": "heel_right",
        "side": 2,
        "cx":242.97, "cy":869.63
    },
    {
        "subBodyRegion": "Sole Left",
        "bodyLocation": "sole_left",
        "side": 2,
        "cx":133.14, "cy":909.71
    },
    {
        "subBodyRegion": "Sole Right",
        "bodyLocation": "sole_right",
        "side": 2,
        "cx":255.76, "cy":909.71,
    }
];
