import React, { useEffect, useState, useRef, useMemo, useCallback, useContext } from 'react';
import {getMeasuredExercises} from './Services/GetMeasurementRequestHandler';
import CONSTANT from "../../../constants/Constant";
import { getQueuedMovements, callStartOverApi} from "./Services/ExerciseService";
import { toast } from 'react-toastify';
import {playAudio,getAudioUrl} from "../../../utils/Utils";
import LeftSidePanel from './LeftSidePanel';
import RightSidePanel from './RightSidePanel';
import VideoPanel from './VideoPanel';
import HeaderPanel from './HeaderPanel';
import CountDown from './CountDown';
import NotificationAlert from './NotificationModal';
import StaticVideoModal from './StaticVideoModal';
import RetakeNotificationModal from './RetakeNotificationModal';
import {
    getChunksToBlob,
    clearIntervalAndStopAudio,
    doesUserNeedFacingLeft,
    doesUserNeedFacingRight
} from './utilities/Utility';
import { getSweetAlertModalConfig } from '../../../services/dom/AlertService';
import {
    getLastPerformedMovement, getTotalCompletedPercentage,
    getMovementsOfSelectedStatus, setMovementStatus, updateExerciseList, getNextMovement,
    analyzeMovementVideo, getPatientId, checkAllPainQuestionAnswered, setStatusOfSelectedExercise,
    getVideoLength, getChildrenValue, playPatientNotStandingFacingRight, playPatientNotStandingFacingLeft
} from './Services/MovementService';
import { skipMovement, updateUserData} from './Services/AssessementService';
import GLOBAL from '../../../global';
import Progressbar from '../ProgressBar';
import VideoTopElement from './VideoTopElement';
import NetworkCheck from './NetworkCheck';
import VideoToolbar from './VideoToolbar';
import { ModalHeader } from "reactstrap";
import BiometricLoader from './BiometricLoader';
import StopWatch from './StopWatch';
import HeightForm from './HeightForm';
import Guideline from "../../../assets/img/movement/Guideline.svg";
import DownArrow from "../../../assets/img/utils/DownArrow.svg";
import AppContext from '../../../contexts/AppContext';
import { setPrimaryCamera } from './Services/cameraService';
import allowCameraImg from '../../../assets/img/movement/allowCamera.jpg';
import { redirectTo } from '../chatbotNew/ChatServices';

const MovementCore = ({testId, bodyRegionList, onClose, clearAndCloseChatbot}) => {
    const {
        deAuthenticate
    } = useContext(AppContext);

    const FACING_MODE_USER = "user";
    const FACING_MODE_ENVIRONMENT = "environment";

    const { storeUserInfoToLocalStorage } = useContext(AppContext);

    const [isVideoTopElementDisabled, setIsVideoTopElementDisabled] = useState(false);
    const [filteredExerciseList, setFilteredExerciseList] = useState([]);
    const [isShowCountDown, setIsShowCountDown] = useState(false);
    const [startRecording, setStartRecording] = useState(false);
    const [openVideoModal, setOpenVideoModal] = useState('');
    const [isShowNotificationAlert, setIsShowNotificationAlert] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [openRetakeNotificationModal, setOpenRetakeNotificationModal] = useState(false);
    const [isMovementPaused, setIsMovementPaused] = useState(false);
    const [isShowSelectMovementBox, setIsShowSelectMovementBox] = useState(true);
    const [isAnalyzingVideo, setIsAnalyzingVideo] = useState(false);
    const [isFullScreenVideo, setIsFullScreenVideo] = useState(false);
    const [EnableDetailedVoiceInstruction, setEnableDetailedVoiceInstruction] = useState(true);
    const [processingSaveAssessment, setProcessingSaveAssessment] = useState(false);
    const [isSkipping, setIsSkipping] = useState(false);
    const [isShowHeightForm, setIsShowHeightForm] = useState(false);
    const [isUpdatingUserData, setIsUpdatingUserData] = useState(false);
    const [isShowMovementList, setIsShowMovementList] = useState(false)
    const [showMovementGuideline, setShowMovementGuideline] = useState(false)
    const [messageOnVideo, setMessageOnVideo] = useState('');
    const [painQuestionList, setPainQuestionList] = useState([]);
    const [webcamConstraints, setWebcamConstraints] = useState({
        facingMode: FACING_MODE_USER,
        deviceId: "",
        width: 1280,
        height: 720,
        frameRate: { min: 30 }
    });

    const exerciseList = useRef([]);
    const childExerciseExerciseCodeNameList = useRef([]);
    const measuredExerciseList = useRef([]);
    const selectedExercise = useRef({});
    const sweetAlertModalObj = useRef({});
    const retakeExercise = useRef({});
    const recordedBlob = useRef([]);
    const videoUrl = useRef('');
    const queuedMovements = useRef([]);
    const checkFullBodyInterval = useRef(null);
    const lastMovementPerformed = useRef(null);
    const retakeMovementList = useRef([]);
    const skippedMovementList = useRef([]);
    const shouldCheckMovementFinished = useRef(false);
    const completedPercentage = useRef(0);
    const videoRecordTime = useRef(5);
    const startUpdateExerciseList = useRef(false);
    const voiceEnable = useRef(true);
    const queueInterval = useRef(null);
    const notFoundAudioCounter = useRef(0);
    const patientNotFacingLeft = useRef(0);
    const patientNotFacingRight = useRef(0);
    const shoulderToShoulderDistance = useRef(0);
    const previousStatus = useRef('');
    const shouldStartCountdown = useRef(false);
    const retakeModal = useRef(false);
    const percentageMessage = useRef('');
    const isPageLoaded = useRef(false);
    const isInstructionAudioOfExercisePlayed = useRef(false);
    const doesWelcomeAudioPlayed = useRef(false);
    const movementInstructionPlayInterval = useRef(null);
    const isPlayingMovementInstructionAudio = useRef(false);
    const firstTimeFullBodyNegativeSetTimeout = useRef(null);
    //Cervical Flexion, Cervical Extension
    const notNeedGridExercisesRef = useRef(["floorLegsRaises"]);
    const notNeedSkipAndInMovementStatPopupExercisesRef = useRef(["floorLegsRaises"]);

    useEffect(() => {
        addEventListenerOnChangeCameraAccess();
        sweetAlertModalObj.current = getSweetAlertModalConfig({});

        fetchData();
    }, []);

    useEffect(() => {
        queueInterval.current = setInterval(() => {
            async function fetchQueuedMovements(){
                queuedMovements.current = await getQueuedMovements(testId);
                await updateExerciseListAndIsUserCanSkipExercisesStatus();
                if(shouldCheckMovementFinished.current){
                    await controlWithNotificationModal();
                }
            }
            if(startUpdateExerciseList.current){
                fetchQueuedMovements();
            }
        }, 10000);

        return () => {
            clearAllIntervalsAndStopAudio();
        }
    },[]);

    useEffect(()=>{
        async function fetchTotalCompletedPercentage(){
            completedPercentage.current = await calculateTotalCompletedPercentage();
        }
        fetchTotalCompletedPercentage();
    });

    const clearAllIntervalsAndStopAudio = async () => {
        clearInterval(queueInterval.current);
        clearInterval(checkFullBodyInterval.current);
        clearInterval(movementInstructionPlayInterval.current);
        clearInterval(firstTimeFullBodyNegativeSetTimeout.current);
        await stopPlayingAudio();
        queueInterval.current = null;
        checkFullBodyInterval.current = null;
        movementInstructionPlayInterval.current = null;
    }

    async function fetchData() {
        if(GLOBAL.DEVICE_WIDTH < 1000){
            await renderPage();
        }
        else{
            let primaryDevice = await setPrimaryCamera();
            if(primaryDevice && !primaryDevice?.success){
                showCameraAccessDeniedPopup();
                return;
            }

            setWebcamConstraints(primaryDevice?.deviceDetails);

            if(primaryDevice){
                if(primaryDevice?.maxHeight >= 720 && primaryDevice?.maxWidth >= 1280){
                    await renderPage();
                }
                else{
                    sweetAlertModalObj.current = getSweetAlertModalConfig({
                        showCancelBtn: true,
                        showConfirmBtn: true,
                        alertType: 'info',
                        title: 'Resolution',
                        confirmBtnText: 'Proceed',
                        cancelBtnText: 'Back',
                        confirmBtnCss: 'btn btn-sm btn-success',
                        cancelBtnCss: 'btn btn-sm btn-danger',
                        message: 'Your camera resolution is too low. Your camera resolution must be at least 1280 X 720 pixel. You can proceed with this resolution but it might cause the worst measurement results.',
                        closeOnOutsideClick: false,
                        onConfirm: renderPage,
                        onCancel: closeCameraIssueSweetAlert
                    });
                    setIsShowNotificationAlert(true);
                }
            }
            else{
                sweetAlertModalObj.current = getSweetAlertModalConfig({
                    showCancelBtn: false,
                    showConfirmBtn: true,
                    alertType: 'info',
                    title: 'Not Found',
                    confirmBtnText: 'Ok, Got It',
                    cancelBtnText: '',
                    confirmBtnCss: 'btn btn-sm btn-success',
                    cancelBtnCss: 'btn btn-sm btn-danger',
                    message: 'Camera not found. Please check your camera permission and try again.',
                    closeOnOutsideClick: false,
                    onConfirm: closeCameraIssueSweetAlert
                });

                setIsShowNotificationAlert(true);
            }
        }
    }

    const showCameraAccessDeniedPopup = () => {
        let alertMessage = (
            <div>
                <p>Please check your camera permission and try again.</p>
                <img src={allowCameraImg} alt="MyImage" style={{width: "100%", height: "auto"}}/>
            </div>
        );
        sweetAlertModalObj.current = getSweetAlertModalConfig({
            showCancelBtn: false,
            showConfirmBtn: true,
            alertType: 'info',
            title: 'Camera not allowed',
            confirmBtnText: 'Ok, Got It',
            cancelBtnText: '',
            confirmBtnCss: 'btn btn-sm btn-success',
            cancelBtnCss: 'btn btn-sm btn-danger',
            message: alertMessage,
            closeOnOutsideClick: false,
            onConfirm: closeCameraIssueSweetAlert
        });
        setIsShowNotificationAlert(true);
    }

    const addEventListenerOnChangeCameraAccess = () => {
        navigator.permissions.query({ name: 'camera' }).then(function(result) {
            result.addEventListener('change', function() {
                if (this.state === 'denied') {
                    showCameraAccessDeniedPopup();
                }
            });
        })
    }

    const closeCameraIssueSweetAlert = () => {
        setIsShowNotificationAlert(false);
        onClose && onClose();
    }

    const updateUserHeight = async (height) => {
        setIsUpdatingUserData(true);
        let payload = {
            Height: height,
        };
        await updateUserData(payload);

        GLOBAL.USER_INFO = {
            ...GLOBAL.USER_INFO,
            Height: height
        };

        storeUserInfoToLocalStorage();

        setIsUpdatingUserData(false);
        setIsShowHeightForm(false);
        await startMovementOnHeightPresent();
    }

    const renderPage = async () => {
        setIsShowNotificationAlert(false);
        let measuredExercise = await getMeasuredExercises(testId, bodyRegionList);
        if(!measuredExercise?.success || (measuredExercise?.data && measuredExercise?.data.length <= 0)) {
            toast.error(<div>Something went wrong please try again</div>);
            return;
        }
        measuredExercise = measuredExercise?.data;
        queuedMovements.current = await getQueuedMovements(testId);

        if(measuredExercise){
            exerciseList.current = measuredExercise || [];
            childExerciseExerciseCodeNameList.current = getChildrenValue(exerciseList.current);
            measuredExerciseList.current = measuredExercise['MeasuredExercises'] || [];

            if(GLOBAL.USER_INFO['Height'] && GLOBAL.USER_INFO['Height'] > 0){
                await startMovementOnHeightPresent();
            }
            else{
                setIsShowHeightForm(true);
            }
        }
    }

    const startMovementOnHeightPresent = async () => {
        if(exerciseList.current && exerciseList.current.length > 0){
            if(queuedMovements.current && queuedMovements.current.length > 0){
                sweetAlertModalObj.current = getSweetAlertModalConfig({
                    showCancelBtn: true,
                    showConfirmBtn: true,
                    alertType: 'info',
                    title: 'Confirmation',
                    confirmBtnText: 'Yes, Resume',
                    cancelBtnText: 'No, Start Over',
                    confirmBtnCss: 'btn btn-sm btn-success',
                    cancelBtnCss: 'btn btn-sm btn-danger',
                    message: 'Do you want to resume your last assessment?',
                    closeOnOutsideClick: false,
                    onConfirm: onConfirmResume,
                    onCancel: onStartOver
                });
                setIsShowNotificationAlert(true);
            }
            else{
                await onConfirmResume();
            }
        }
    }

    const startMovementRendering = async () => {
        lastMovementPerformed.current = getLastPerformedMovement(exerciseList.current, null);
        exerciseList.current = setMovementStatus(exerciseList.current, exerciseList.current[0], CONSTANT.EXERCISE_STATUS.PERFORMING.key);

        if(queuedMovements.current){
            startUpdateExerciseList.current = true;
            await updateExerciseListAndIsUserCanSkipExercisesStatus();
        }


        await onSelectNextMovement(exerciseList.current[0]);
        completedPercentage.current = await calculateTotalCompletedPercentage();
        setIsShowNotificationAlert(false);
        if(voiceEnable.current){
            await playGeneralAudio(CONSTANT.GENERAL_AUDIOS_KEY_TEXT.WELCOME_TEXT);
            if(!doesWelcomeAudioPlayed.current) {
                doesWelcomeAudioPlayed.current = true;
            }
        } else if(!doesWelcomeAudioPlayed.current) {
            doesWelcomeAudioPlayed.current = true;
        }
    }

    const onConfirmResume = async () => {
        for(let i = 0; i < exerciseList.current.length; i++){
            let status = CONSTANT.EXERCISE_STATUS.NOT_PERFORMED.name;
            let exercise = exerciseList.current[i];
            if(exercise.IsSkipExercise) {
                status = CONSTANT.EXERCISE_STATUS.SKIPPED.key;
            }
            exerciseList.current = setMovementStatus(exerciseList.current, exerciseList.current[i], status);
        }
        await startMovementRendering();
    }

    const onStartOver = async () => {
        let response = await callStartOverApi(testId, getPatientId());//VA PORTAL API
        if(response){
            queuedMovements.current = [];
            exerciseList.current.forEach(exercise => {
                exercise.Status = CONSTANT.EXERCISE_STATUS.NOT_PERFORMED.name;
            });

            await startMovementRendering();
        }
    }

    const toggleIsUserCanSkipExerciseProcessingAlert = async (isNeedOpen) => {
        if(!isNeedOpen){
            setIsShowNotificationAlert(false);
            await onClickSelect();
        }
        else {
            let alertMsg = 'Please wait. The system is calibrating to your height. We must finish this process before taking the remainder of your measurements';
            if(selectedExercise.current?.ExerciseCodeName !== CONSTANT.EXERCISES_NAME.calibration) {
                alertMsg = 'Please wait for the movement to be processed.';
            }

            sweetAlertModalObj.current = getSweetAlertModalConfig({
                showCancelBtn: false,
                showConfirmBtn: false,
                alertType: 'warning',
                title: selectedExercise.current?.ExerciseDisplayName || '',
                confirmBtnText: '',
                cancelBtnText: '',
                confirmBtnCss: '',
                cancelBtnCss: '',
                message: `${alertMsg}`,
                closeOnOutsideClick: false
            });
            setIsShowNotificationAlert(true);
            if(EnableDetailedVoiceInstruction) {
                await playGeneralAudio(CONSTANT.GENERAL_AUDIOS_KEY_TEXT.WAIT_UNTIL_CALIBRATION_FINISHED);
            }
        }
    }

    const updateExerciseListAndIsUserCanSkipExercisesStatus = async () => {
        let exListObj = await updateExerciseList(queuedMovements.current, exerciseList.current);
        let selectedExerciseUpdated = exListObj.exerciseList
            .filter(exercise => exercise?.ExerciseCodeName === selectedExercise.current?.ExerciseCodeName);
        let statusOfSelectedExercise;
        if(selectedExerciseUpdated.length > 0) {
            statusOfSelectedExercise = selectedExerciseUpdated[0]?.Status;
        }

        if(
            !selectedExercise.current?.IsUserCanSkip && (
                (statusOfSelectedExercise === CONSTANT.EXERCISE_STATUS.COMPLETED.key) ||
                (statusOfSelectedExercise === CONSTANT.EXERCISE_STATUS.RETAKE_HUMAN_EXPERT.key)
            )
        ) {
            await toggleIsUserCanSkipExerciseProcessingAlert(false);
        } else if(!selectedExercise.current?.IsUserCanSkip && statusOfSelectedExercise === CONSTANT.EXERCISE_STATUS.RETAKE.key) {
            exerciseList.current = setMovementStatus(exerciseList.current, exerciseList.current[0], CONSTANT.EXERCISE_STATUS.RETAKE.name)
            setFilteredExerciseList(prev => exerciseList.current.slice());
            let currentExercise = exerciseList.current.find((exercise) => exercise.ExerciseCodeName === selectedExercise.current?.ExerciseCodeName);
            await onSelectNextMovement(currentExercise);
            shouldStartCountdown.current = false;
            // sweetAlertModalObj.current = getSweetAlertModalObj(false, true, 'info', `Retake ${selectedExercise.current?.ExerciseDisplayName}`, 'Ok', '','btn btn-sm btn-success','',`${selectedExercise.current?.ExerciseDisplayName} was not successful, please re-retry.`, false, handleCloseIsUserCanSkipExercisesRetakeSweetAlert);
            sweetAlertModalObj.current = getSweetAlertModalConfig({
                showCancelBtn: false,
                showConfirmBtn: true,
                alertType: 'info',
                title: `Retake ${selectedExercise.current?.ExerciseDisplayName || ''}`,
                confirmBtnText: 'Ok',
                cancelBtnText: '',
                confirmBtnCss: 'btn btn-sm btn-success',
                cancelBtnCss: '',
                message: `${selectedExercise.current?.ExerciseDisplayName || ''} was not successful, please retry.`,
                closeOnOutsideClick: false,
                onConfirm: handleCloseIsUserCanSkipExercisesRetakeSweetAlert
            });
            setIsShowNotificationAlert(true);
            isInstructionAudioOfExercisePlayed.current = true; // to avoid playing instruction audio of calibration
        }
        exerciseList.current = exListObj.exerciseList;
        setFilteredExerciseList(prev => exerciseList.current.slice());
    }

    const handleCloseIsUserCanSkipExercisesRetakeSweetAlert = async () => {
        setIsShowNotificationAlert(false)
        shouldStartCountdown.current = true;
        let currentExercise = exerciseList.current.find((exercise) => exercise.ExerciseCodeName === selectedExercise.current?.ExerciseCodeName);
        await onSelectNextMovement(currentExercise);
    }

    const playGeneralAudio = async (audioKey) => {
        let audioUrl = getAudioUrl(audioKey);
        if(audioUrl){
            await playAudio(audioUrl);
        }
    }

    const stopPlayingAudio = async () => {
        await playAudio('remove');
    }

    const savePoses = async () => {
        if((!GLOBAL.IsColorRedForFullBox || notNeedGridExercisesRef.current.includes(selectedExercise.current?.ExerciseCodeName)) && shouldStartCountdown.current) {
            if(voiceEnable.current && (!isInstructionAudioOfExercisePlayed.current || !doesWelcomeAudioPlayed.current)) {
                return;
            }

            shouldStartCountdown.current = false;
            setIsVideoTopElementDisabled(true);
            clearInterval(checkFullBodyInterval.current);
            await stopPlayingAudio();
            await playGeneralAudio(CONSTANT.GENERAL_AUDIOS_KEY_TEXT.START_INSTRUCTION);

            setIsFullScreenVideo(true);
            setIsShowCountDown(true);
        }
    }

    const checkFullBodyInBoxThenStartCountDown = async () => {
        if(firstTimeFullBodyNegativeSetTimeout.current) clearTimeout(firstTimeFullBodyNegativeSetTimeout.current);
        // To stop the audio if the patient is not in the box and stop full site audio if the patient close the movement
        // modal before biometric modal is ready
        if(!queueInterval.current){
            await clearAllIntervalsAndStopAudio();
            clearAllActiveProperty();
            return;
        }

        firstTimeFullBodyNegativeSetTimeout.current = setTimeout(async () => {
            checkAndPlayAudio();
            if(checkFullBodyInterval.current) clearInterval(checkFullBodyInterval.current);
            checkFullBodyInterval.current = setInterval(checkAndPlayAudio, 10000);
        }, 3000)


    }

    const playPatientNotInBoxAudio = async () => {
        const audioUrlFullBodyNegative = getAudioUrl(CONSTANT.GENERAL_AUDIOS_KEY_TEXT.FULL_BODY_NEGATIVE);
        const audioUrlTakeBrake = getAudioUrl(CONSTANT.GENERAL_AUDIOS_KEY_TEXT.TAKE_BRAKE);

        if(!audioUrlFullBodyNegative) return;

        notFoundAudioCounter.current++;
        await playAudio(audioUrlFullBodyNegative);

        if (notFoundAudioCounter.current === 4 && audioUrlTakeBrake) {
            await playAudio(audioUrlTakeBrake);
        }
    }

    const checkAndPlayAudio = async () => {
        const shouldUserFaceLeft = doesUserNeedFacingLeft(selectedExercise.current);
        const shouldUserFaceRight = doesUserNeedFacingRight(selectedExercise.current);

        const shouldPlayAudio =
            !isPlayingMovementInstructionAudio.current &&
            shouldStartCountdown.current &&
            doesWelcomeAudioPlayed.current &&
            !notNeedGridExercisesRef.current.includes(selectedExercise.current?.ExerciseCodeName) &&
            (
                (!GLOBAL.IS_PATIENT_IN_BOX && notFoundAudioCounter.current < 4) ||
                (shouldUserFaceLeft && GLOBAL.IS_PATIENT_IN_BOX && !GLOBAL.IS_PATIENT_FACING_LEFT && patientNotFacingLeft.current < 4) ||
                (shouldUserFaceRight && GLOBAL.IS_PATIENT_IN_BOX && !GLOBAL.IS_PATIENT_FACING_RIGHT && patientNotFacingRight.current < 4)
            )
        ;

        if (shouldPlayAudio && GLOBAL.IsColorRedForFullBox) {
            await stopPlayingAudio();
            if(!GLOBAL.IS_PATIENT_IN_BOX) {
                await playAudio("remove");
                await playPatientNotInBoxAudio();
            } else if(shouldUserFaceRight && !GLOBAL.IS_PATIENT_FACING_RIGHT) {
                await playAudio("remove");
                patientNotFacingRight.current++;
                await playPatientNotStandingFacingRight();
            } else if(shouldUserFaceLeft && !GLOBAL.IS_PATIENT_FACING_LEFT) {
                await playAudio("remove");
                patientNotFacingLeft.current++;
                await playPatientNotStandingFacingLeft();
            }
        }
    }

    const storePreviousStatus = (exStatus) => {
        if(exStatus !== CONSTANT.EXERCISE_STATUS.PERFORMING.key){
            previousStatus.current = exStatus;
        }
    }

    const resetVideoAndRecordingStates = () => {
        setIsFullScreenVideo(false);
        setIsShowCountDown(false);
        setStartRecording(false);
    };

    const onSelectNextMovement = async (exercise, isRetake = false) => {
        if(notNeedGridExercisesRef.current.includes(exercise.ExerciseCodeName)) {
            GLOBAL.DRAW_BOX = false;
        } else {
            GLOBAL.DRAW_BOX = true;
        }

        if(exercise && exercise !== {}){
            resetVideoAndRecordingStates();

            isInstructionAudioOfExercisePlayed.current = false;
            setIsVideoTopElementDisabled(false);
            shouldStartCountdown.current = true;
            await stopPlayingAudio();
            let exStatus = exercise.Status;
            exerciseList.current = await setStatusOfSelectedExercise(exerciseList.current, previousStatus.current, exercise, selectedExercise.current, isRetake);
            storePreviousStatus(exStatus);
            toggleShowMovementBox(true);
            selectedExercise.current = exercise;
            setFilteredExerciseList(prev => exerciseList.current.slice());
            isPlayingMovementInstructionAudio.current = false;

            if(!isPageLoaded.current){
                isPageLoaded.current = true;
                setIsDataLoaded(true);
                GLOBAL.AUDIO_PLAY_ENABLED = true;
            }

            if(voiceEnable.current) {
                movementInstructionPlayInterval.current = setInterval(async () => {
                    await playMovementInstructionAudio(selectedExercise.current);
                }, 1000);
            }
            await playMovementInstructionAudio(selectedExercise.current);
            notFoundAudioCounter.current = 0;
            checkFullBodyInBoxThenStartCountDown();
        }
    }

    const playMovementInstructionAudio = async (exercise) => {
        if(voiceEnable.current && (!GLOBAL.IsColorRedForFullBox || notNeedGridExercisesRef.current.includes(selectedExercise.current?.ExerciseCodeName)) && !isInstructionAudioOfExercisePlayed.current && doesWelcomeAudioPlayed.current) {
            if(!isPlayingMovementInstructionAudio.current) {
                isPlayingMovementInstructionAudio.current = true
                await playMovementAudio(selectedExercise.current);
                isInstructionAudioOfExercisePlayed.current = true;
                isPlayingMovementInstructionAudio.current = false;
                clearInterval(movementInstructionPlayInterval.current)
            }
        }
    }

    const playMovementAudio = async (exercise) => {
        if(!shouldStartCountdown.current) return;
        isInstructionAudioOfExercisePlayed.current = false;

        if(voiceEnable.current && doesWelcomeAudioPlayed.current && exercise?.ExerciseGuideline?.AudioUrlWeb) {
            await stopPlayingAudio();
            await playAudio(exercise?.ExerciseGuideline?.AudioUrlWeb);
            isInstructionAudioOfExercisePlayed.current = true;
        } else if(!voiceEnable.current) {
            isInstructionAudioOfExercisePlayed.current = true;
        }
    }

    const onClickSelect = async () => {
        let exercise = await getNextMovement(exerciseList.current);
        if(exercise){
            await onSelectNextMovement(exercise);
        }
        else{
            await clearIntervalAndStopAudio(checkFullBodyInterval.current, null, stopPlayingAudio);
            if(selectedExercise.current && selectedExercise.current.Status === CONSTANT.EXERCISE_STATUS.PERFORMING.key){
                exerciseList.current = setMovementStatus(exerciseList.current, selectedExercise.current, CONSTANT.EXERCISE_STATUS.WAITING.key);
            }
            setFilteredExerciseList(prev => exerciseList.current.slice());
            shouldCheckMovementFinished.current = true;
            await controlWithNotificationModal();
        }
    }

    const onClosePauseNotification = async () =>{
        setIsMovementPaused(false);
        setIsShowNotificationAlert(false);
        isInstructionAudioOfExercisePlayed.current = false; // for playing instruction again after pause
        await onSelectNextMovement(selectedExercise.current);
    }

    const onClickPauseBtn = async() => {
        shouldStartCountdown.current = false;
        setIsMovementPaused(true);
        await clearIntervalAndStopAudio(checkFullBodyInterval.current, null, stopPlayingAudio);
        sweetAlertModalObj.current = getSweetAlertModalConfig({
            showCancelBtn: false,
            showConfirmBtn: true,
            alertType: 'warning',
            title: 'Movement Paused',
            confirmBtnText: 'Start Again',
            cancelBtnText: '',
            confirmBtnCss: 'btn btn-sm btn-success',
            cancelBtnCss: '',
            message: 'The movement has been paused',
            closeOnOutsideClick: false,
            onConfirm: onClosePauseNotification
        });
        setIsShowNotificationAlert(true);
        if(!doesWelcomeAudioPlayed.current) doesWelcomeAudioPlayed.current = true;
    }

    const onClickSkip = async (exercise) =>{
        if(exercise && exercise !== {}){
            await stopPlayingAudio();
            shouldStartCountdown.current = false;
            setIsSkipping(true);
            setIsVideoTopElementDisabled(true);
            setIsFullScreenVideo(false);
            setIsShowCountDown(false);
            let result = await skipMovement(exerciseList.current, exercise, CONSTANT.PROVIDER_EMAIL, testId);
            setIsSkipping(false);
            setIsVideoTopElementDisabled(false);
            setIsShowNotificationAlert(false);

            if(result?.success){
                exerciseList.current = setMovementStatus(exerciseList.current, exercise, CONSTANT.EXERCISE_STATUS.SKIPPED.key);
                setFilteredExerciseList(prev => exerciseList.current.slice());
                await onClickSelect();
            }
            else{
                toast.error(<div>Unable to skip the movements</div>);
            }
        }
    }

    const handleClickUnableToPerform = async (exercise) => {
        if(notNeedSkipAndInMovementStatPopupExercisesRef.current.includes(exercise.ExerciseCodeName)) {
            await onClickSkip(exercise);
            return;
        }

        sweetAlertModalObj.current = getSweetAlertModalConfig({
            showCancelBtn: true,
            showConfirmBtn: true,
            alertType: 'warning',
            title: 'Unable to Perform',
            confirmBtnText: 'Yes',
            cancelBtnText: 'No',
            confirmBtnCss: 'btn btn-sm btn-success',
            cancelBtnCss: 'btn btn-sm btn-danger',
            message: 'Are you sure you would like to skip this movement?',
            closeOnOutsideClick: false,
            onConfirm: () => onClickSkip(exercise), // Assuming `exercise` is in scope where this is called
            onCancel: () => setIsShowNotificationAlert(false),
            processingConfirmText: 'Skipping...'
        });
        setIsShowNotificationAlert(true);
    }

    const onCancelSaveAndClose = () => {
        setIsShowNotificationAlert(false);
    }

    const clearAllActiveProperty = () => {
        voiceEnable.current = false;
        setEnableDetailedVoiceInstruction(false);
        GLOBAL.AUDIO_PLAY_ENABLED = false;
        shouldStartCountdown.current = false;
        shouldCheckMovementFinished.current = false;
    }

    const onConfirmSaveAndClose = async () => {
        clearAllActiveProperty();
        await clearIntervalAndStopAudio(checkFullBodyInterval.current, queueInterval.current, stopPlayingAudio);
        setProcessingSaveAssessment(true);
        setProcessingSaveAssessment(false);
        setIsShowNotificationAlert(false);

        onClose && onClose();
    }

    const onConfirmSaveByClick = async () => {
        await clearIntervalAndStopAudio(checkFullBodyInterval.current, queueInterval.current, stopPlayingAudio);
        onConfirmSaveAndClose();
    }

    const onClickSaveAndCloseBtn = async () => {
        let totalCompletedPercentage = await calculateTotalCompletedPercentage();
        if(totalCompletedPercentage < 100){
            sweetAlertModalObj.current = getSweetAlertModalConfig({
                showCancelBtn: true,
                showConfirmBtn: true,
                alertType: 'warning',
                title: 'Warning',
                confirmBtnText: 'Yes',
                cancelBtnText: 'No',
                confirmBtnCss: 'btn btn-sm btn-success',
                cancelBtnCss: 'btn btn-sm btn-danger',
                message: 'You did not complete performing all movements. Do you want to save without those movements?',
                closeOnOutsideClick: false,
                onConfirm: onConfirmSaveByClick,
                onCancel: onCancelSaveAndClose
            });

            setIsShowNotificationAlert(true);
        }
        else{
            await onConfirmSaveAndClose();
        }
    }

    const calculateTotalCompletedPercentage = async () => {
        let totalCompletedPercentage = await getTotalCompletedPercentage(exerciseList.current,
            CONSTANT.EXERCISE_STATUS.COMPLETED.key,
            CONSTANT.EXERCISE_STATUS.RETAKE_HUMAN_EXPERT.key,
            CONSTANT.EXERCISE_STATUS.SKIPPED.key);

        return totalCompletedPercentage;
    }

    const openMovementPercentageModal = async () => {
        let totalCompletedPercentage = await calculateTotalCompletedPercentage();

        if(totalCompletedPercentage < 100){
            let waitingMovements = await getMovementsOfSelectedStatus(exerciseList.current, CONSTANT.EXERCISE_STATUS.WAITING.key) || [];
            let processingMovements = await getMovementsOfSelectedStatus(exerciseList.current, CONSTANT.EXERCISE_STATUS.PROCESSING.key) || [];
            retakeMovementList.current = waitingMovements.concat(processingMovements);
            retakeModal.current = false;
            percentageMessage.current = totalCompletedPercentage + '% completed, please wait until 100% complete.';
            toggleRetakeNotificationModal(true);
        }
        else if(totalCompletedPercentage >= 100){
            toggleRetakeNotificationModal(false);
            shouldCheckMovementFinished.current = false;

            skippedMovementList.current = await getMovementsOfSelectedStatus(exerciseList.current, CONSTANT.EXERCISE_STATUS.SKIPPED.key) || [];
            skippedMovementList.current = skippedMovementList.current.filter(exercise => !notNeedSkipAndInMovementStatPopupExercisesRef.current.includes(exercise.ExerciseCodeName));

            if(skippedMovementList.current && skippedMovementList.current.length > 0){
                sweetAlertModalObj.current = getSweetAlertModalConfig({
                    showCancelBtn: true,
                    showConfirmBtn: true,
                    alertType: 'warning',
                    title: '',
                    confirmBtnText: 'Yes',
                    cancelBtnText: 'No',
                    confirmBtnCss: 'btn btn-sm btn-success',
                    cancelBtnCss: 'btn btn-sm btn-danger',
                    message: 'You did not complete performing all movements. Do you want to save without those movements?',
                    closeOnOutsideClick: false,
                    onConfirm: onConfirmSaveAndClose,
                    onCancel: () => setIsShowNotificationAlert(false)
                });

                setIsShowNotificationAlert(true);
            } else {
                sweetAlertModalObj.current = getSweetAlertModalConfig({
                    showCancelBtn: false,
                    showConfirmBtn: true,
                    alertType: 'success',
                    title: 'Congratulations',
                    confirmBtnText: 'Save & Close',
                    cancelBtnText: '',
                    confirmBtnCss: 'btn btn-sm btn-success',
                    cancelBtnCss: '',
                    message: 'You have completed the biometric phase of your assessment. Please click save and close.',
                    closeOnOutsideClick: false,
                    onConfirm: saveAndCloseAndLogout
                });

                setIsShowNotificationAlert(true);
                voiceEnable.current && await playGeneralAudio(CONSTANT.GENERAL_AUDIOS_KEY_TEXT.ALL_MOVEMENT_COMPLETED);
            }
        }
    }

    const saveAndCloseAndLogout = async () => {
        await onConfirmSaveAndClose();
        clearAndCloseChatbot && clearAndCloseChatbot();
        deAuthenticate();
        redirectTo('');
    }

    const setShouldOPenRetakeListModal = (value) => {
        shouldCheckMovementFinished.current = value;
    }

    const controlWithNotificationModal = async () => {
        retakeMovementList.current = await getMovementsOfSelectedStatus(exerciseList.current, CONSTANT.EXERCISE_STATUS.RETAKE.key) || [];
        if(retakeMovementList.current && retakeMovementList.current.length > 0){
            setIsShowNotificationAlert(false);
            retakeModal.current = true;
            percentageMessage.current = '';
            toggleRetakeNotificationModal(true);
        }
        else{
            await openMovementPercentageModal();
        }
    }

    const onConfirmRetakeAlert = async () => {
        setIsShowNotificationAlert(false);
        await onSelectNextMovement(retakeExercise.current, true);
    }

    const onCancelRetakeAlert = async () => {
        setIsShowNotificationAlert(false);
        if(selectedExercise.current && selectedExercise.current.Status === CONSTANT.EXERCISE_STATUS.PERFORMING.key){
            await onSelectNextMovement(selectedExercise.current, true);
        }
    }

    const onClickRetakeExercise = async(exercise, isFromRetakeModal = false, isFromLeftSidePanel = false) => {
        shouldCheckMovementFinished.current = false;
        shouldStartCountdown.current = false;
        await clearIntervalAndStopAudio(checkFullBodyInterval.current, null, stopPlayingAudio);
        retakeExercise.current = exercise;

        setOpenVideoModal(false);

        if(!isFromRetakeModal && selectedExercise.current && selectedExercise.current.ExerciseCodeName !== exercise.ExerciseCodeName){
            let modalHeaderText = retakeExercise.current.Status === CONSTANT.EXERCISE_STATUS.RETAKE.key ? 'Retake' : '';
            let alertMsg =
                (isFromLeftSidePanel && !(retakeExercise.current.Status === CONSTANT.EXERCISE_STATUS.RETAKE.key))
                    ? `Are you sure you want to perform ${retakeExercise.current.ExerciseDisplayName}?`
                    : `Do you want to start ${retakeExercise.current.ExerciseDisplayName} now?`;

            sweetAlertModalObj.current = getSweetAlertModalConfig({
                showCancelBtn: true,
                showConfirmBtn: true,
                alertType: 'warning',
                title: modalHeaderText,
                confirmBtnText: 'Yes',
                cancelBtnText: 'No',
                confirmBtnCss: 'btn btn-sm btn-success',
                cancelBtnCss: 'btn btn-sm btn-danger',
                message: alertMsg,
                closeOnOutsideClick: false,
                onConfirm: onConfirmRetakeAlert,
                onCancel: onCancelRetakeAlert
            });

            setIsShowNotificationAlert(true);
        }
        else{
            await onSelectNextMovement(exercise, true);
        }
    }

    const toggleRetakeNotificationModal = (value) => {
        setOpenRetakeNotificationModal(value);
    }

    const onStopVideoRecording = async (chunks) => {
        GLOBAL.IsColorRedForFullBox = true;
        setStartRecording(false);
        await playGeneralAudio(CONSTANT.GENERAL_AUDIOS_KEY_TEXT.ENDING_MOVEMENT);

        let blobUrlObj = await getChunksToBlob(chunks);
        recordedBlob.current = blobUrlObj.blob;
        let url = blobUrlObj.url;

        setIsFullScreenVideo(false);

        if(url){
            setPainQuestionList(selectedExercise.current?.PainQuestions || []);
            videoUrl.current = url;
            setOpenVideoModal(true);
        }
        else{
            toast.error(<div>No Video found</div>);
        }
    }

    const startVideoRecording = async() => {
        videoRecordTime.current = getVideoLength(selectedExercise.current);
        await playGeneralAudio(CONSTANT.GENERAL_AUDIOS_KEY_TEXT.STARTING_MOVEMENT);
        setStartRecording(true);
    }

    const onHideCountDown = async () => {
        setIsShowCountDown(false);
        await startVideoRecording();
    };

    const onClickMovementAnalyze = async (painQuestionList) => {
        if(!checkAllPainQuestionAnswered(painQuestionList)){
            return;
        }
        setIsAnalyzingVideo(true);
        let response = await analyzeMovementVideo(painQuestionList, CONSTANT.PROVIDER_EMAIL, testId, bodyRegionList, selectedExercise.current, recordedBlob.current);
        if(response && response.success){
            lastMovementPerformed.current = getLastPerformedMovement(exerciseList.current, selectedExercise.current);
            let queuedExercises = queuedMovements.current.filter(x => x.exerciseName !== selectedExercise.current.ExerciseCodeName);
            queuedMovements.current = queuedExercises ? queuedExercises : [];
            setOpenVideoModal(false);

            if(!selectedExercise.current?.IsUserCanSkip){
                exerciseList.current = setMovementStatus(exerciseList.current, selectedExercise.current, CONSTANT.EXERCISE_STATUS.WAITING.key);
                setFilteredExerciseList(prev => exerciseList.current.slice());
                await toggleIsUserCanSkipExerciseProcessingAlert(true);
            }
            else{
                await onClickSelect();
            }
        }
        else{
            if(response && response.message){
                toast.error(<div>{response.message ? response.message : "Unable to analyze the video. Please try again later."}</div>);
            }
        }
        setIsShowSelectMovementBox(false);
        setIsAnalyzingVideo(false);
    }

    const toggleShowMovementBox = (value) => {
        setIsShowSelectMovementBox(value);
    }

    const onClickFullScreen = () => {
        if(GLOBAL.DEVICE_WIDTH >= 1000) {
            setIsFullScreenVideo(prev => !prev);
        }
    }

    const updateWebCamConstraints = useCallback((deviceId) => {
        setWebcamConstraints({...webcamConstraints, deviceId});
    }, []);

    const onClickSwitchCamera = useCallback(() => {
        let cameraFace = webcamConstraints.facingMode === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER;
        setWebcamConstraints({...webcamConstraints, facingMode: cameraFace});
    }, []);

    const onChangeDetailedVoiceInstruction = () => {
        voiceEnable.current = !voiceEnable.current;
        setEnableDetailedVoiceInstruction(!EnableDetailedVoiceInstruction);
        if(!doesWelcomeAudioPlayed.current) {
            doesWelcomeAudioPlayed.current = true;
        }
        if(!isInstructionAudioOfExercisePlayed.current) {
            isInstructionAudioOfExercisePlayed.current = true;
        }
    }

    const leftSidePanel = useMemo(() => {
        return <LeftSidePanel
            exerciseList={exerciseList.current}
            filteredExerciseList={filteredExerciseList}
            setFilteredExerciseList={setFilteredExerciseList}
            onClickRetakeExercise={onClickRetakeExercise}
            selectedExercise={selectedExercise.current}
            toggleShowMovementBox={toggleShowMovementBox}
            isShowSelectMovementBox={isShowSelectMovementBox}
            selectedRegions={bodyRegionList}
            childExerciseExerciseCodeNameList={childExerciseExerciseCodeNameList.current}
        />
    },[filteredExerciseList, isShowSelectMovementBox]);

    return (
        <div className="movement-core">
            <div className={isFullScreenVideo ? "display-none" : ""}>
                <ModalHeader className="movement-modal">
                    <HeaderPanel
                        EnableDetailedVoiceInstruction={EnableDetailedVoiceInstruction}
                        onChangeDetailedVoiceInstruction={onChangeDetailedVoiceInstruction}
                        onClickSaveAssessment={onClickSaveAndCloseBtn}
                        processingSaveAssessment={processingSaveAssessment}
                    />
                </ModalHeader>
            </div>
            {isDataLoaded && (
                <>
                    <div className={`${isFullScreenVideo ? "d-none" : "position-relative"} progress-bar-container`}>
                        <div className="">
                            <Progressbar progress={completedPercentage.current}/>
                        </div>
                        <div>
                            <NetworkCheck/>
                        </div>
                    </div>
                    <div className={isFullScreenVideo ? "d-none row height-91-4-vh justify-content-between" : "row height-75-vh justify-content-between"}>
                        <div className='position-relative w-98-percent z-index-101 margin-bottom-2-px'>
                            <div className='select-movement w-100-percent' onClick={() => setIsShowMovementList(prevState => !prevState)}>
                                <p>{selectedExercise.current?.ExerciseDisplayName}</p>
                                <img src={DownArrow} alt='Down Arrow' />
                            </div>
                            {isShowMovementList &&
                                <div className='movent w-100-percent'>
                                    {leftSidePanel}
                                </div>
                            }
                        </div>

                        <div className={isFullScreenVideo ? 'display-none' : 'more-than-576px'}>
                            <div className="width-25-percent max-height-inherit h-100-percent d-flex flex-column position-relative">
                                {leftSidePanel}
                            </div>
                        </div>

                        <div className={isFullScreenVideo ? "width-75-percent max-height-inherit h-100-percent padding-left-0-important" : "width-50-percent position-relative max-height-inherit h-100-percent d-flex flex-column"}>
                            <VideoTopElement
                                onClickPauseBtn={onClickPauseBtn}
                                onClickSkip={handleClickUnableToPerform}
                                selectedExercise={selectedExercise.current}
                                testId={testId}
                                isMovementPaused={isMovementPaused}
                                isFullScreenVideo={isFullScreenVideo}
                                isSkipping={isSkipping}
                                isVideoTopElementDesabled={isVideoTopElementDisabled}
                            />
                            <div className="movement-video-design">
                                {/*{videoPanel}*/}
                                <VideoPanel
                                    startRecording={startRecording}
                                    onStopVideoRecording={onStopVideoRecording}
                                    savePoses={savePoses}
                                    totalSeconds={videoRecordTime.current}
                                    webcamConstraints={webcamConstraints}
                                    isShowCountDown={isShowCountDown}
                                    messageOnVideo={messageOnVideo}
                                    setMessageOnVideo={setMessageOnVideo}
                                    selectedExercise={selectedExercise}
                                />
                                {(isShowCountDown || messageOnVideo !== "") && (
                                    <div className="d-flex-center-center h-100-percent">
                                        <CountDown
                                            onHideCountDownAndStartRecording={onHideCountDown}
                                            selectedExercise={selectedExercise.current}
                                            notNeedGridExercises={notNeedGridExercisesRef.current}
                                            messageOnVideo={messageOnVideo}
                                            isShowCountDown={isShowCountDown}
                                        />
                                    </div>
                                )}
                                { startRecording &&
                                    <div className="d-flex-center-center stopwatch-parent">
                                        <StopWatch isStartStopWatch={startRecording} totalSeconds={videoRecordTime.current}/>
                                    </div>
                                }
                                <VideoToolbar
                                    isFullScreenVideo={isFullScreenVideo}
                                    onClickFullScreen={onClickFullScreen}
                                    updateWebCamConstraints={updateWebCamConstraints}
                                    onClickSwitchCamera={onClickSwitchCamera}
                                    webcamConstraints={webcamConstraints}
                                />
                            </div>
                            {!isFullScreenVideo && (
                                <div className='movement-guideline-text' onClick={() => setShowMovementGuideline(prevState => !prevState)}>
                                    <img className='movement-guideline-icon' src={Guideline} alt='Guideline' />
                                    Guideline
                                </div>
                            )}
                            {showMovementGuideline &&
                                <div className='movement-gui'>
                                    <div className="width-25-percent-for-guideline below-guideline max-height-inherit h-100-percent d-flex flex-column">
                                        <RightSidePanel
                                            Name={selectedExercise.current?.ExerciseDisplayName}
                                            ExerciseMediaUrl={selectedExercise.current?.ExerciseGuideline?.MediaUrl}
                                            PatientGuidelineContent={selectedExercise.current?.ExerciseGuideline?.InstructionPatient}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="width-25-percent-for-guideline max-height-inherit h-100-percent">
                            <RightSidePanel
                                Name={selectedExercise.current?.ExerciseDisplayName}
                                ExerciseMediaUrl={selectedExercise.current?.ExerciseGuideline?.MediaUrl}
                                PatientGuidelineContent={selectedExercise.current?.ExerciseGuideline?.InstructionPatient}
                            />
                        </div>
                    </div>
                    <RetakeNotificationModal
                        isShow={openRetakeNotificationModal}
                        retakeMovements={retakeMovementList.current}
                        toggleRetakeNotificationModal={toggleRetakeNotificationModal}
                        onClickRetakeExercise={onClickRetakeExercise}
                        setShouldOPenRetakeListModal={setShouldOPenRetakeListModal}
                        isRetakeModal={retakeModal.current}
                        message={percentageMessage.current}
                        isAudioPlayEnabled={EnableDetailedVoiceInstruction}
                        playGeneralAudio={playGeneralAudio}
                    />
                    <StaticVideoModal
                        isShow={openVideoModal}
                        videoUrl={videoUrl.current}
                        painQuestionList={painQuestionList}
                        setPainQuestionList={setPainQuestionList}
                        onClickAnalyze={onClickMovementAnalyze}
                        exercise={selectedExercise.current}
                        onClickRetakeExercise={onClickRetakeExercise}
                        isAnalyzingVideo={isAnalyzingVideo}
                        isAudioPlayEnabled={EnableDetailedVoiceInstruction}
                        playGeneralAudio={playGeneralAudio}
                    />
                </>
            )}
            {!isDataLoaded && !isShowNotificationAlert && (
                <BiometricLoader/>
            )}
            <NotificationAlert
                isShow={isShowNotificationAlert}
                notificationObj={sweetAlertModalObj.current}
                processingSaveAssessment={processingSaveAssessment || isSkipping}
            />
            <HeightForm
                isShow={isShowHeightForm}
                updateUserHeight={updateUserHeight}
                isUpdatingUserData={isUpdatingUserData}/>
        </div>
    );
}

export default MovementCore;