import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";

import GLOBAL from "../../../global";
import { invalidFormElement } from "../../../utils/DefaultInitialization";

const AddAddress = () => {

    const { i18n } = useTranslation();

    const initialValuesForAddCard = () => {
        return {
            FirstName: GLOBAL.URL_PARAMS.EMAIL_ADDRESS ? GLOBAL.URL_PARAMS.EMAIL_ADDRESS : '',
            LastName: "",
            Country: "",
            Address: "",
            State: "",
            PostCode: ""
        };
    };

    const validationSchema = () => {
        return Yup.object().shape({
            FirstName: Yup.string()
                .required(i18n.t("common.message.error.required-field")),
            LastName: Yup.string()
                .required(i18n.t("common.message.error.required-field")),
            Country: Yup.string()
                .required(i18n.t("common.message.error.required-field")),
            Address: Yup.string()
                .required(i18n.t("common.message.error.required-field")),
            State: Yup.string()
                .required(i18n.t("common.message.error.required-field")),
            PostCode: Yup.string()
                .required(i18n.t("common.message.error.required-field"))
        });
    };

    const onSubmit = async (values, actions) => {
        console.log({ values })
    }

    return (
        <Formik
            initialValues={initialValuesForAddCard()}
            validationSchema={validationSchema()}
            onSubmit={onSubmit}>
            {({
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  values,
                  errors,
                  touched,
                  isSubmitting,
              }) => (
                <div className="kt-login__container">
                    <div className="kt-login__signin">
                        <form className="kt-form" onSubmit={handleSubmit}>
                            <div className="d-flex justify-content-between">
                                <div className="form-group w-49">
                                    <label>First Name <span className="kt-font-danger"> *</span></label>
                                    <div className="kt-input-icon kt-input-icon--right">
                                        <input
                                            className={`kt-mt-0 ${invalidFormElement(
                                                "FirstName",
                                                errors,
                                                values,
                                                touched
                                            )}`}
                                            type="text"
                                            placeholder="First Name"
                                            name="FirstName"
                                            autoComplete="off"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.FirstName}
                                        />

                                        {errors.FirstName && touched.FirstName && (
                                            <div className="invalid-feedback">{errors.FirstName}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group w-49">
                                    <label>Last Name <span className="kt-font-danger"> *</span></label>
                                    <div className="kt-input-icon kt-input-icon--right">
                                        <input
                                            className={`kt-mt-0 ${invalidFormElement(
                                                "LastName",
                                                errors,
                                                values,
                                                touched
                                            )}`}
                                            type="text"
                                            placeholder="LastName"
                                            name="LastName"
                                            autoComplete="off"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.LastName}
                                        />

                                        {errors.LastName && touched.LastName && (
                                            <div className="invalid-feedback">{errors.LastName}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Country <span className="kt-font-danger"> *</span></label>
                                <div className="kt-input-icon kt-input-icon--right">
                                    <select className={`form-control kt-mt-0 ${invalidFormElement(
                                        "Country",
                                        errors,
                                        values,
                                        touched
                                    )}`}
                                            id="Country"
                                            name="Country"
                                            autoComplete="off"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.Country}>
                                        <option value="" disabled selected>Your Country</option>
                                        <option>BD</option>
                                        <option>IND</option>
                                        <option>PAK</option>
                                    </select>

                                    {errors.Country && touched.Country && (
                                        <div className="invalid-feedback">{errors.Country}</div>
                                    )}
                                </div>
                            </div>
                            <div className="form-group ">
                                <label>Address <span className="kt-font-danger"> *</span></label>
                                <div className="kt-input-icon kt-input-icon--right">
                                    <input
                                        className={`kt-mt-0 ${invalidFormElement(
                                            "Address",
                                            errors,
                                            values,
                                            touched
                                        )}`}
                                        type="text"
                                        placeholder="Please enter your name"
                                        name="Address"
                                        autoComplete="off"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.Address}
                                    />
                                    {errors.Address && touched.Address && (
                                        <div className="invalid-feedback">{errors.Address}</div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="form-group w-49">
                                    <label>State / Province <span className="kt-font-danger"> *</span></label>
                                    <div className="kt-input-icon kt-input-icon--right">
                                        <input
                                            className={`kt-mt-0 ${invalidFormElement(
                                                "State",
                                                errors,
                                                values,
                                                touched
                                            )}`}
                                            type="text"
                                            placeholder="State"
                                            name="State"
                                            autoComplete="off"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.State}
                                        />

                                        {errors.State && touched.State && (
                                            <div className="invalid-feedback">{errors.State}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group w-49">
                                    <label>Post Code <span className="kt-font-danger"> *</span></label>
                                    <div className="kt-input-icon kt-input-icon--right">
                                        <input
                                            className={`kt-mt-0 ${invalidFormElement(
                                                "PostCode",
                                                errors,
                                                values,
                                                touched
                                            )}`}
                                            type="text"
                                            placeholder="PostCode"
                                            name="PostCode"
                                            autoComplete="off"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.PostCode}
                                        />

                                        {errors.PostCode && touched.PostCode && (
                                            <div className="invalid-feedback">{errors.PostCode}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="kt-login__actions kt-pt15 kt-mb-15">
                                <button
                                    type="submit"
                                    className="btn btn-brand btn-elevate kt-login__btn-primary w-25 float-right"
                                    disabled={isSubmitting}>
                                    {isSubmitting ? (
                                        <>
                                            <i className="fa fa-spinner fa-spin"/>&nbsp;&nbsp;
                                            <Trans i18nKey="common.message.info.please-wait"/>
                                        </>
                                    ) : (
                                        <>Submit</>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Formik>
    )
}

export default AddAddress