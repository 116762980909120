import { PROCESS_QUEUE_EXERCISE_URL, No_START_OVER_URL} from "./UrlManager";
import GLOBAL from "../../../../global";
import axios from '../../../../../src/services/Axios';
import { error, success } from "../../../../services/ResponseHandler";

const RESPONSE_TYPE = { responseType: 'text' };

export const getQueuedMovements = async (testId) => {
    try {
        const requestUrl = `${PROCESS_QUEUE_EXERCISE_URL}`;
        let payload = {
            tenant: GLOBAL.TENANT,//VA PORTAL API
            testId: testId,
        }
        const response = await axios.post(requestUrl, payload ,RESPONSE_TYPE);
        return response?.Message && response?.Message?.queue ? response?.Message.queue : [];
    } catch (err) {
        console.log(err);
        return [];
    }
}

export const callStartOverApi = async (testId, patientId) => {//VA PORTAL API
    try {
        let payload = {
            tenant: GLOBAL.TENANT,//VA PORTAL API
            testId: testId,
            PatientId: patientId//VA PORTAL API
        }
        const response = await axios.post(No_START_OVER_URL, payload, RESPONSE_TYPE);
        return response;
    } catch (e) {
        console.log(e);
        return false;
    }
}