// import axios from './Axios';
import { error, success } from "./ResponseHandler";
// import GLOBAL from "../global";
import defaultProfilePic from "../assets/img/users/default.jpg";


const billingAddresses = {
    Message: [
        {
            Status: 'primary',
            Address: "Ap #285-7193 Ullamcorper Avenue Amesbury HI 93373 US"
        },
        {
            Status: 'primary',
            Address: "Ap #285-7193 Ullamcorper Avenue Amesbury HI 93373 US"
        },
    ]
}

const paymentMethods = {
    Message: [
        {
            CardHolderName: "Arun kundu",
            Status: "primary",
            CardImageUrl: defaultProfilePic,
            CardNumber: "4444444444",
            ExpiresDate: "23/23/4555"
        },
        {
            CardHolderName: "Arun kundu",
            Status: "secondary",
            CardImageUrl: defaultProfilePic,
            CardNumber: "111111111111",
            ExpiresDate: "12/12/1000"
        },
    ]
}

class SubscriptionService {
    async getBillingAddress(payload = {}) {
        try {
            // payload.Tenant = GLOBAL.TENANT;
            // const response = await axios.post(GET_BILLING_ADDRESSES_URL, payload, RESPONSE_TYPE);
            // return success({ data: response });

            return new Promise((resolve)=> {
                resolve(success(billingAddresses))
            })

        } catch (e) {
            return error(e);
        }
    }

    async getPaymentMethods(payload = {}) {
        try {
            // payload.Tenant = GLOBAL.TENANT;
            // const response = await axios.post(GET_PAYMENT_METHODS_URL, payload, RESPONSE_TYPE);
            // return success({ data: response });

            return new Promise((resolve)=> {
                resolve(success(paymentMethods))
            })

        } catch (e) {
            return error(e);
        }
    }
}

export default new SubscriptionService();
