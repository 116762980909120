import React, {useContext, useEffect} from 'react';
import { Modal } from 'reactstrap';

import CONSTANT from "../../../constants/Constant";
import { playAudio } from "../../../utils/Utils";
import ConfirmationDialog from "../Popups/ConfirmationDialog";
import routes from "../../../constants/Routes";

import { redirectTo } from "./ChatServices";
import ErrorDialog from "../Popups/ErrorDialog";
import useResumeChatbot from "./hooks/useResumeChatbot";
import {useDispatch, useSelector} from "react-redux";
import useChatbot from "./hooks/useChatbot";
import {
    setShowConfirmationPopupToCloseBot, setIsFetchingChatQuestion, setShowWelcomePage, setShowChatBot, setChatBotErrorMsg
} from "../../../features/chat/chatbotSlice";
import ChatbotHeader from "./ChatbotHeader";
import ChatbotContainer from "./ChatbotContainer";
import AppContext from "../../../contexts/AppContext";

const Chatbot = () => {
    const dispatch = useDispatch();
    const { userInfo } = useContext(AppContext);
    const { resumeChatBot } = useResumeChatbot();

    const { CanPatientCreateAssessment } = userInfo.Permissions;
    const chatBotState = useSelector(state => state.chatBot);
    const botData = chatBotState?.botData || {};
    const chatBotUIState = chatBotState?.chatBotUIState || {};

    const chats = botData?.chats || [];

    const resumeChats = chatBotState?.resumeChats || {};
    const selectedBotInfoObj = botData?.botInfo || {};
    const currentChatIndex = botData?.currentChatIndex ?? -1;

    let showConfirmationPopupToCloseBot = chatBotUIState?.showConfirmationPopupToCloseBot || false;
    let chatBotErrorMsg = chatBotUIState?.chatBotErrorMsg || false;
    let showChatBot = chatBotUIState?.showChatBot || false;

    let testIdToResumeChat = botData?.testIdToResumeChat || '';
    let bodyRegionList = botData?.bodyRegionList || [];

    const {
        setCurrentAndPrevChatIndex, fetchAndProcessChatQuestion, automaticallySelectBodyRegion, clearAndCloseChatbot,
        calculateChatbotProgress
    } = useChatbot();

    useEffect(() => {
        if(!resumeChats.isLoading) {
            setCurrentAndPrevChatIndex(chats);
            calculateChatbotProgress(selectedBotInfoObj?.options?.name);
        }
    }, [resumeChats.isLoading]);

    useEffect(() => {
        if(!showChatBot) startChatBot();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let callback = async () => {
            dispatch(setShowWelcomePage(false));
        }

        automaticallySelectBodyRegion(bodyRegionList, callback);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentChatIndex]);

    /*=================Public Methods===============*/
    const toggleChatModal = () => {
        dispatch(setShowConfirmationPopupToCloseBot(true));
    };

    const onToggleConfirmChatbotModalClose = async () => {
        dispatch(setShowConfirmationPopupToCloseBot(!showConfirmationPopupToCloseBot));
    };

    const onCloseChatbotConfirmModalClose = () => {
        const options = (selectedBotInfoObj?.options || {});
        if(!CanPatientCreateAssessment) {
            redirectTo(routes.dashboard);
        } else if(((options && options.name) === CONSTANT.CHAT_BOTS.INTAKE_BOT) || ((options && options.name) === CONSTANT.CHAT_BOTS.MSK_BOT)) {
            redirectTo(`${routes.myAssessments}/true`)
        }
        clearAndCloseChatbot();
    };

    const startChatBot = async () => {
        dispatch(setShowChatBot(true));
        if(!testIdToResumeChat) {
            await fetchAndProcessChatQuestion(CONSTANT.CHATBOT_INTENT.INITIAL);
        } else if(testIdToResumeChat) {
            dispatch(setIsFetchingChatQuestion(true));
            await resumeChatBot(testIdToResumeChat);
        }
        playAudio('remove').then();
    };

    const onClickCloseValidationAlert = () => {
        dispatch(setChatBotErrorMsg(''));
    };

    return (
        <Modal
            isOpen={showChatBot} toggle={toggleChatModal}
            backdrop={false} className="modal-xxl kt-m0"
            modalClassName="mmh-chat-modal modal-fix chat-bot"
        >

            <ErrorDialog errorMsg={chatBotErrorMsg} clearErrorMsg={onClickCloseValidationAlert}/>

            {/*Conformation while closing the chatbot*/}
            {showConfirmationPopupToCloseBot && (
                <ConfirmationDialog
                    confirmText="Yes"
                    cancelText="No"
                    show={showConfirmationPopupToCloseBot}
                    onClickHeaderClose={onToggleConfirmChatbotModalClose}
                    toggleDialog={onToggleConfirmChatbotModalClose}
                    onConfirm={onCloseChatbotConfirmModalClose}>
                    Are you sure you want to close?
                </ConfirmationDialog>
            )}

            {/*ChatBot*/}
            <div className="kt-chat mmh-chat">
                <div className="kt-portlet kt-portlet--last bg-color-white-dark">
                    <ChatbotHeader toggleChatModal={toggleChatModal} />
                    <ChatbotContainer />
                </div>
            </div>
        </Modal>
    );
};

export default Chatbot;