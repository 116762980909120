import CONSTANT from "../../../../constants/Constant";
import { getElementHeight, getElementWidth } from "../../../../utils/DomUtil";
import { LEFT_FACING_EXERCISE_CODE_NAMES, RIGHT_FACING_EXERCISE_CODE_NAMES } from "./ExerciseUtils";

const blobToUrl = (blob) => {
    try{
        return URL.createObjectURL(blob);
    }
    catch(ex){
        return '';
    }
}

export const getChunksToBlob = (chunks) => {
    return new Promise((resolve, reject) => {
        try{
            const blob = new Blob(chunks, {
                type: CONSTANT.VIDEO_TYPE.VIDEO_MP4
            });
            const url = blobToUrl(blob);
            resolve({blob,url});
        }
        catch(ex){
            resolve({blob: null,url: ''});
        }
    });
}

export const getVideoStyle = () => {
    const bodyElementHeight = getElementHeight('root');
    let actualHeight = +bodyElementHeight - 350;

    // if (isMobile) {
    //     actualHeight = actualHeight + 130;
    // } else {
    //     actualHeight = actualHeight < 412 ? 412 : actualHeight;
    // }

    actualHeight = actualHeight < 412 ? 412 : actualHeight;

    return { 
        border: "5px solid #0075B6",
        position: "relative",
        height: "100%",//"500px",
        backgroundColor: "#EEF1FF",
        zIndex: 0,
        maxHeight: "672px",//actualHeight + "px", 
        minHeight: "500px"//actualHeight + "px"
    };
};
////TODO: Need to refactor this method later
export const clearIntervalAndStopAudio = async (interval1, interval2, stopPlayingAudio) => {
    if(interval1){
        clearInterval(interval1);
    }
    if(interval2){
        clearInterval(interval2);
    }
    await stopPlayingAudio();
}

export const getPercentage = (calculatedValue, totalValue) => {
    if(totalValue === 0 || calculatedValue === 0) return 0;
    return parseInt((calculatedValue / totalValue) * 100);
}

export const canUserSkipExercise = (exercise) => {
    if(!exercise) return false;

    return exercise && exercise['IsUserCanSkip'];
}

export const doesUserNeedFacingLeft = (exercise) => {
    let shouldUserFaceLeft = false;

    if(exercise && exercise?.ExerciseCodeName && LEFT_FACING_EXERCISE_CODE_NAMES.includes(exercise?.ExerciseCodeName)){
        shouldUserFaceLeft = true;
    }

    return shouldUserFaceLeft;
}

export const doesUserNeedFacingRight = (exercise) => {
    let shouldUserFaceRight = false;

    if(exercise && exercise?.ExerciseCodeName && RIGHT_FACING_EXERCISE_CODE_NAMES.includes(exercise?.ExerciseCodeName)){
        shouldUserFaceRight = true;
    }

    return shouldUserFaceRight;
}