import React from "react";
import Mike from "../../assets/img/emma-voice/mike.png";
import OnMike from "../../assets/img/emma-voice/onMike.png";

const MovementVoice = ({isAudioPlayEnabled, onChangeAudioPlay}) => {
    const toggleBtn = (e) => {
        e.preventDefault();
        onChangeAudioPlay();
    }
    return(
        <div className="movement-voice-box">
            <button className="movement-toggle-btn" onClick={(e) => toggleBtn(e)}>
                {isAudioPlayEnabled && (
                    <>
                        <span className="movement-on-off-text">ON</span>
                        <span className="mike-design">
                            <img src={OnMike}/>
                        </span>
                    </>
                )}
                {!isAudioPlayEnabled && (
                    <>
                        <span className="mike-design">
                            <img src={Mike}/>
                        </span>
                        <span className="movement-on-off-text">OFF</span>
                    </>
                )}
            </button>
        </div>
    )
}

export  default MovementVoice;