import React, { useEffect } from "react";

import CONSTANT from "../../constants/Constant";

import Header from "../shared/layout/Header";
import Footer from "../shared/layout/Footer";
import { playAudio } from "../../utils/Utils";
import BodyWithLeftMenu from "../shared/layout/BodyWithLeftMenu";
import MyCardsDetails from "./MyCardsDetails";
import BodyWithTopMenu from "../shared/layout/BodyWithTopMenu";

const MyCards = () => {
    useEffect(() => {
        playAudio('remove').then();
    }, []);

    return (
        <>
            {/*<MobileHeader/>*/}
            <div className="kt-grid kt-grid--hor kt-grid--root">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                        <Header/>
                        <BodyWithTopMenu pageName={CONSTANT.PAGE_NAME.MY_CARDS}>
                            <MyCardsDetails/>
                        </BodyWithTopMenu>
                        <Footer/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyCards;
