import { useDispatch, useSelector } from "react-redux";
import {
    setAllQuestionsError,
    setCurrentGroupId,
    setCurrentQuestionId,
    setCurrentSubGroupIndex,
    setOpenBiometricMovementAndCautionQuestionContainer,
    setShowInlineError,
    setShowInstruction,
} from "../../../features/editAssessment/editAssessmentSlice";
import { toast } from "react-toastify";
import CONSTANT from "../../../constants/Constant";
import { getIndexOfCurrentGroup, indexOfGroupId, isCCQuestionAnswered } from "../EditAssessmentUtils";
import {
    getAllVisibleQuestions,
    handleQuestionAnswerValidation,
} from "../EditAssessmentService";
import { _getWaitingRoomByInvitationInfo, deepClone, isEmptyObject } from "../../../utils/Utils";
import useSweetAlert from "../../shared/sweetAlertComponent/useSweetAlert";
import routes from "../../../constants/Routes";
import { saveNoCC_Complaint } from "../../shared/chatbotNew/ChatServices";
import GLOBAL from "../../../global";
import { useHistory } from "react-router-dom";
import Assessment from "../../../models/Assessment";
import examService from "../../../services/ExamService";
import { useContext } from "react";
import AppContext from "../../../contexts/AppContext";
import { saveIntake } from "../../shared/intake-form/intake-form-service";
import useIntakeFrom from "../../shared/intake-form/useIntakeFrom";
import useHandleExpiredAndUpcomingMeetingPopupMsg from "../../../hooks/useHandleExpiredAndUpcomingMeetingPopupMsg";

const useEditAssessmentHeaderAndFooter = () => {
    const { showSweetAlert, hideSweetAlert, setOkLoading, setNoLoading } = useSweetAlert();
    const { setTelemedicineProperties, resetTelemedicineKeys } = useContext(AppContext);
    const dispatch = useDispatch();
    const history = useHistory();
    const { handleOpenIntakeForm, handleCloseIntakeForm, handleSetJoiningTelemedicine, handleSetJoiningLaterTelemedicine } = useIntakeFrom();
    const { getAssessmentAvailabilityStatus } = useHandleExpiredAndUpcomingMeetingPopupMsg();

    const editAssessmentGroup = useSelector(state => state.editAssessment);
    const ccQuestion = editAssessmentGroup?.ccQuestion || {};
    const groups = editAssessmentGroup?.groups || [];

    const baseData = editAssessmentGroup?.baseData || {};
    const currentGroupId = baseData?.currentGroupId || CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;
    const invitationTypeName = baseData?.invitationTypeName || "";
    const invitationId = baseData?.invitationId || "";
    const currentSubGroupIndex = baseData?.currentSubGroupIndex || 0;
    const testId = baseData?.testId || "";
    const bodyRegionList = baseData?.bodyRegionList || [];
    const meetingEndDate = baseData?.meetingEndDate || '';
    const meetingStartDate = baseData?.meetingStartDate || '';

    const currentGroupIndex = groups.findIndex(group => group.groupId === currentGroupId);
    const currentGroup = groups.find(group => group.groupId === currentGroupId);
    const questions = currentGroup?.subGroups[currentSubGroupIndex]?.questions || [];

    const isIntakeSigned = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['IsIntakeSigned']) || false;

    const handleChangeCurrentGroupId = (groupId) => {
        if(!groupId) {
            toast.error('Group Id is required');
            return;
        }

        if(indexOfGroupId(groups, groupId) > currentGroupIndex) {
            let doesAllQuestionAnswered = checkIsAllQuestionsAnsweredOfCurrentSubGroup();
            if (!doesAllQuestionAnswered) return;
        }

        dispatch(setCurrentGroupId(groupId));
        dispatch(setCurrentSubGroupIndex(0));
        dispatch(setCurrentQuestionId(0));
    }

    const handleOnClickNextBtn = () => {
        const currentGroupIndex = getIndexOfCurrentGroup(groups, currentGroupId);
        const totalGroupsCount = groups.length;

        if (currentGroupIndex === -1 || currentGroupIndex === totalGroupsCount - 1) {
            toast.error('You are already in the last group or group not found');
            console.error('You are already in the last group or group not found');
            return;
        }

        const totalSubGroupsCount = groups[currentGroupIndex]?.totalSubGroups;

        let doesAllQuestionAnswered = checkIsAllQuestionsAnsweredOfCurrentSubGroup();
        if(!doesAllQuestionAnswered) return;

        if (totalSubGroupsCount > 0) {
            if (baseData?.currentSubGroupIndex < totalSubGroupsCount - 1) {
                dispatch(setCurrentSubGroupIndex(currentSubGroupIndex + 1));
                return;
            }
        }

        const nextGroupIndex = currentGroupIndex + 1;
        const nextGroupId = groups[nextGroupIndex]?.groupId;
        dispatch(setCurrentGroupId(nextGroupId));
        dispatch(setCurrentSubGroupIndex(0));
        dispatch(setShowInlineError(false));
        dispatch(setCurrentQuestionId(0));
    };


    const handleOnClickPrevBtn = () => {
        const currentGroupIndex = getIndexOfCurrentGroup(groups, currentGroupId);

        if(currentGroupIndex === -1 || currentGroupIndex === 0) {
            toast.error('You are already in the first group or group not found');
            console.error('You are already in the first group or group not found');
            return;
        }

        if(currentSubGroupIndex >= 1) {
            const prevCurrentSubGroupIndex = currentSubGroupIndex - 1;
            dispatch(setCurrentSubGroupIndex(prevCurrentSubGroupIndex));
            return;
        }

        const prevGroupIndex = currentGroupIndex - 1;
        const prevGroupId = groups[prevGroupIndex]?.groupId;
        const lastIndexOfPrevGroup =
            groups[prevGroupIndex]?.totalSubGroups - 1 > 0
                ? groups[prevGroupIndex]?.totalSubGroups - 1
                : 0;
        dispatch(setCurrentGroupId(prevGroupId));
        dispatch(setCurrentSubGroupIndex(lastIndexOfPrevGroup));
        dispatch(setShowInlineError(false));
        dispatch(setCurrentQuestionId(0));
    }

    const checkIsAllQuestionsAnsweredOfCurrentSubGroup = () => {
        let isAllQuestionsAnswered = true;
        const allVisibleQuestion = deepClone(getAllVisibleQuestions(currentGroup, currentSubGroupIndex));

        const doesHaveError = handleQuestionAnswerValidation(allVisibleQuestion, dispatch, setAllQuestionsError, setShowInlineError);

        let message = '';
        if(currentGroupId === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key) {
            message = "All mandatory sections must be filled to submit."
        } else {
            message = "It is required to fill in all mandatory fields in order to submit."
        }

        if(doesHaveError) {
            showSweetAlert({
                title: 'Attention',
                message: message,
                type: CONSTANT.ALERT_TYPE.ERROR,
                onConfirm: hideSweetAlert,
                showCancelBtn: false,
                showConfirmBtn: false,
                showOkBtn: true,
                confirmBtnText: 'OK',
            });

            isAllQuestionsAnswered = false;
        }

        return isAllQuestionsAnswered;
    }

    const handleSetShowInstruction = (value) => {
        dispatch(setShowInstruction(value));
    }

    const showCCOrPainQuestionPopUp = () => {
        if(isCCQuestionAnswered(ccQuestion)) {
            showSweetAlert({
                title: 'Question',
                message: 'Do you have any other areas of pain?',
                onCancel: () => {
                    setNoLoading(true);
                    showPopupForBiometric();
                },
                onConfirm: hideSweetAlert
            })
        } else {
            showSweetAlert({
                title: 'Question',
                message:
                    'If you are experiencing acute or chronic pain, you can point to the specific area of discomfort' +
                    ' on the skeleton illustration provided. If you don\'t have any complaints, please proceed by' +
                    ' clicking on the "No" button.',
                onCancel: handleNoCC,
                onConfirm: hideSweetAlert
            })
        }
    }

    const getWaitingRoomId = async () => {
        let tempWaitingRoomId = "";
        const {
            waitingRoomId
        } = await _getWaitingRoomByInvitationInfo(testId, invitationId);

        tempWaitingRoomId = waitingRoomId;
        return tempWaitingRoomId;
    }

    const showPopupForBiometric = async () => {
        if (invitationTypeName?.toLowerCase() === CONSTANT.REDIRECT_TYPE.SELF) {
            hideSweetAlert(); //as do not close the sweetalert at showCCOrPainQuestionPopUp function

            showSweetAlert({
                title: 'Question',
                message: 'Do you want to perform biometric movement?',
                onConfirm: () => {
                    hideSweetAlert();
                    handleOpenMovementModal();
                },
                onCancel: () => {
                    hideSweetAlert();
                    history.push(`${routes.myAssessments}/${false}`);
                }
            })
        } else {
            if (invitationId) {
                await getWaitingRoomId();
                hideSweetAlert(); //as do not close the sweetalert at showCCOrPainQuestionPopUp function

                if (invitationTypeName?.toLowerCase() === CONSTANT.REDIRECT_TYPE.INCLINIC) {
                    showThankYouForYorResponsePopup();
                } else if (invitationId) {
                    const assessmentAvailabilityStatus = getAssessmentAvailabilityStatus(meetingStartDate, meetingEndDate);
                    const shouldNotShowJoinTelemedicine =
                        (assessmentAvailabilityStatus === CONSTANT.ASSESSMENT_AVAILABILITY.UPCOMING.KEY) ||
                        (assessmentAvailabilityStatus === CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.KEY);

                    if (!isIntakeSigned && !shouldNotShowJoinTelemedicine) {
                        GLOBAL.ASSESSMENT = new Assessment({ TestId: testId });////TODO: will need to store those on redux

                        handleOpenIntakeForm({
                            bodyRegionList: bodyRegionList || [],
                            invitationTypeName: invitationTypeName || '',
                            onClickJoinTelemedicineNow: onClickJoinTelemedicineNow,
                            onClickJoinTelemedicineLater: onClickJoinTelemedicineLater
                        })
                    } else {
                        if (shouldNotShowJoinTelemedicine) {
                            history.push(`${routes.myAssessments}/${false}`);
                        } else {
                            showSweetAlert({
                                title: 'Question',
                                message: 'Do you want to join now?',
                                type: CONSTANT.ALERT_TYPE.SUCCESS,
                                showConfirmBtn: true,
                                showCancelBtn: true,
                                confirmBtnCss: "btn btn-success btn-wide",
                                cancelBtnCss: "btn btn-danger btn-wide kt-ml-20",
                                confirmBtnText: "JOIN NOW",
                                cancelBtnText: "JOIN LATER",
                                onConfirm: onClickJoinTelemedicineNow,
                                onCancel: () => {
                                    setNoLoading(true);
                                    onClickJoinTelemedicineLater();
                                }
                            })
                        }
                    }
                }
            }
        }
    }

    const showThankYouForYorResponsePopup = () => {
        showSweetAlert({
            title: 'Success',
            message: 'Thank you for your response.',
            type: CONSTANT.ALERT_TYPE.SUCCESS,
            showOkBtn: true,
            showConfirmBtn: false,
            onConfirm: () => {
                hideSweetAlert();
                history.push(`${routes.myAssessments}/${false}`);
            }
        })
    }

    const onClickJoinTelemedicineNow = async (values = {}) => {
        if(!isIntakeSigned) handleSetJoiningTelemedicine(true); //To show loading spinner on the button at intake form
        else setOkLoading(true); //To show loading spinner on the button of sweetalert

        if(!isEmptyObject(values)) {
            await saveIntake(values, testId);
        }

        setTelemedicineProperties(invitationId, bodyRegionList, afterInitializeTelemedicineModal, afterCloseTelemedicineModal);
    };

    const afterInitializeTelemedicineModal = () => {
        hideSweetAlert();
        history.push(`${routes.myAssessments}/${false}`);
    }

    const afterCloseTelemedicineModal = () => {
        resetTelemedicineKeys();
        handleCloseIntakeForm();
    }

    const onClickJoinTelemedicineLater = async (values = {}) => {
        if(!isIntakeSigned) handleSetJoiningLaterTelemedicine(true) //To show loading spinner on the button at intake form
        else setNoLoading(true); //To show loading spinner on the button of sweetalert

        let waitingRoomId = await getWaitingRoomId() || "";

        await sendEmailForJoinTelemedicineLater(waitingRoomId);
        if(!isIntakeSigned) handleSetJoiningLaterTelemedicine(false); //To show loading spinner on the button at intake form
        else setNoLoading(false); //To show loading spinner on the button of sweetalert

        handleCloseIntakeForm();
        showPopUpForJoinLater();
    };

    const sendEmailForJoinTelemedicineLater = async (waitingRoomId) => {
        if(!waitingRoomId) {
            toast.error('Waiting Room Id is not available');
            return;
        }

        const payload = { WaitingRoomId: waitingRoomId };
        const response = await examService.sendTelemedEmailForEmma(payload);
        const { success, error } = response;

        if (!success) {
            console.error(error);
        }
    }

    const showPopUpForJoinLater = () => {
        showSweetAlert({
            title: CONSTANT.ALERT_TITLE.CONGRATULATIONS,
            message: 'You have successfully completed the questionnaire portion of the assessment. Your provider has been notified and will follow up with you in order to complete the last part of the assessment.',
            type: CONSTANT.ALERT_TYPE.SUCCESS,
            onConfirm: () => {
                hideSweetAlert();
                history.push(`${routes.myAssessments}/${false}`);
            },
            showCancelBtn: false,
            showConfirmBtn: false,
            showOkBtn: true,
            confirmBtnText: 'OK',
        });
    }

    const handleNoCC = async () => {
        setNoLoading(true);
        const response = await saveNoCC_Complaint(testId);
        const { data, error } = response;

        if (error) {
            console.error(error);
            toast.error("Internal Server Error. Please try again")
            return;
        }
        history.push(`${routes.myAssessments}/${false}`);
        hideSweetAlert();
    }

    const handleOnClickSubmit = async (e) => {
        e.preventDefault();

        let doesAllQuestionAnswered = checkIsAllQuestionsAnsweredOfCurrentSubGroup();
        if (!doesAllQuestionAnswered) return;


        if (currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) {
            showCCOrPainQuestionPopUp(true);
        } else {
            showSweetAlert({
                title: 'Summary',
                message: "Before submitting, please be sure to complete all sections of the intake process.",
                type: CONSTANT.ALERT_TYPE.INFO,
                onConfirm: () => {
                    hideSweetAlert();
                    showThankYouForYorResponsePopup();
                },
                onCancel: hideSweetAlert,
                confirmBtnText: 'Submit',
                cancelBtnText: 'Complete Other Sections'
            });
        }
    }

    const handleOpenMovementModal = () => {
        GLOBAL.ASSESSMENT = new Assessment({ TestId: testId });////TODO: will need to store those on redux
        dispatch(setOpenBiometricMovementAndCautionQuestionContainer(true));
    }

    return {
        handleChangeCurrentGroupId,
        handleOnClickNextBtn,
        handleOnClickPrevBtn,
        handleSetShowInstruction,
        handleOnClickSubmit,
        onClickJoinTelemedicineNow,
        onClickJoinTelemedicineLater,
        handleOpenMovementModal,
        showCCOrPainQuestionPopUp
    }
}

export default useEditAssessmentHeaderAndFooter;