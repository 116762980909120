import MyAssessmentTooltip from "../../my-assessments/MyAssessmentTooltip";
import React from "react";
import IconCloseButton from "../IconCloseButton";
import { getStyle } from "./utils";
import PainRate from "../PainRate";

const CCQuestion = ({ question, currentSelectedQuestionId, error, getResponseStyle, onChangeCollectResponse, setFirstUnAnsweredQuestion, isReportAvailable = true, isResponseSelected }) => {

    let firstUnAnsweredQuestion = {};
    if(setFirstUnAnsweredQuestion) {
        firstUnAnsweredQuestion = question;
    }

    return (
        <div
            id={question.questionId}
            key={question.questionId}
            className={setFirstUnAnsweredQuestion ? 'p-4 m-3 mr-4' : 'kt-mb-10 pl-2'}
            style={getStyle(question, currentSelectedQuestionId, firstUnAnsweredQuestion)}>
            <div className="question d-flex justify-content-between">
                <div>
                    <label className='heading-primary'>{question.dialogue}</label>
                    {question.isVasQuestion && <PainRate />}
                    <div>
                        {question.responses.map(option =>
                                <button
                                    key={option.id}
                                    id={`option-${option.id}`}
                                    style={getResponseStyle(question.questionId, option.id, option?.color)}
                                    className={`edit-assessment-button`}
                                    disabled={isReportAvailable && !isResponseSelected(question.questionId, option.id, option?.color)}
                                    onClick={(e) => onChangeCollectResponse(e, question, option)}>
                                    {option.name}
                                    {option?.title && <MyAssessmentTooltip text={option.description} target={`option-${option.id}`} />}
                                </button>
                        )}
                    </div>
                </div>
                {setFirstUnAnsweredQuestion &&
                    <IconCloseButton closeHandler={() =>  setFirstUnAnsweredQuestion({})} styleClass={"ml-2"} />
                }
            </div>
            <div className='input-error'>{error[question.questionId]}</div>
        </div>
    )
}

export default CCQuestion;