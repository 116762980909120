import { createSlice } from '@reduxjs/toolkit';
import { initialState } from "./chatbotConstants";
import CONSTANT from "../../constants/Constant";
import chatbotExtraReducers from "./chatbotExtraReducers";
import { makeSerializable } from "../../utils/Utils";
import GLOBAL from "../../global";

const dataSlice = createSlice({
    name: 'dataSlice',
    initialState,
    reducers: {
        setAssessmentCompletion(state, action) {
            const { assessmentKey, isCompleted } = action.payload;
            const botData = state?.botData;

            switch (assessmentKey) {
                case CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key:
                    botData.isDemographicCompleted = isCompleted;
                    break;
                case CONSTANT.ASSESSMENT_GROUP.ADL.key:
                    botData.isADLCompleted = isCompleted;
                    break;
                case CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.key:
                    botData.isMedicalHistoryCompleted = isCompleted;
                    break;
                case CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key:
                    botData.isBiometricCompleted = isCompleted;
                    break;
                case CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key:
                    botData.isChiefComplaintCompleted = isCompleted;
                    break;
                default:
                    break;
            }
        },
        setCurrentChatIndex(state, action) {
            state.botData.currentChatIndex = action.payload;
        },
        setPrevChatIndex(state, action) {
            state.botData.prevChatIndex = action.payload;
        },
        setTestId(state, action) {
            state.botData.testId = action.payload;
        },
        setSelectedResponseOfCautionQuestion(state, action) {
            state.botData.selectedResponseOfCautionQuestion = action.payload;
        },
        setShowPopUpWarningForYesAtCautionQuestion(state, action) {
            state.chatBotUIState.showPopUpWarningForYesAtCautionQuestion = action.payload;
        },
        setChats(state, action) {
            state.botData.chats = makeSerializable(action.payload);
        },
        updateChatAtIndex(state, action) {
            const { index, chat } = action.payload;
            return {
                ...state,
                botData: {
                    ...state.botData,
                    chats: state.botData.chats.map((tempChat, ind) =>
                        ind === index ? chat : tempChat
                    )
                }
            };
        },
        sliceChatAtIndex(state, action) {
            const { index } = action.payload;
            return {
                ...state,
                botData: {
                    ...state.botData,
                    chats: state.botData.chats.slice(0, index + 1)
                }
            };
        },
        setBotInfo(state, action) {
            state.botData.botInfo = action.payload;
        },
        setIsFetchingChatQuestion(state, action) {
            return {
                ...state,
                chatBotUIState: {
                    ...state.chatBotUIState,
                    isFetchingChatQuestion: action.payload
                }
            }
        },
        setChatBotErrorMsg(state, action) {
            state.chatBotUIState.chatBotErrorMsg = action.payload;
        },
        setSessionId(state, action) {
            state.botData.sessionId = action.payload;
        },
        setBodyRegionList(state, action) {
            const bodyRegionList = action.payload;
            return {
                ...state,
                botData: {
                    ...state.botData,
                    bodyRegionList: bodyRegionList
                }
            }
        },
        setBotProgressbarValue(state, action) {
            state.chatBotUIState.botProgressbarValue = action.payload;
        },
        clearChatbotReduxStore() {
            return initialState;
        },
        setShowConfirmationPopupToCloseBot(state, action) {
            state.chatBotUIState.showConfirmationPopupToCloseBot = action.payload;
        },
        setShowWelcomePage(state, action) {
            state.chatBotUIState.showWelcomePage = action.payload;
        },
        setShowChatBot(state, action) {
            state.chatBotUIState.showChatBot = action.payload;
        },
        setOpenChiefComplaintModal(state, action) {
            state.chatBotUIState.openChiefComplaintModal = action.payload;
        },
        setIsLoadingCC(state, action) {
            state.chatBotUIState.isLoadingCC = action.payload;
        },
        setOpenSelfAssessmentModal(state, action) {
            state.chatBotUIState.openSelfAssessmentModal = action.payload;
        },
        setBotAnswerAtIndex(state, action) {
            const { index, answer } = action.payload;
            return {
                ...state,
                botData: {
                    ...state.botData,
                    chats: state.botData.chats.map((chat, i) =>
                        i === index ? { ...chat, answers: [answer] } : chat
                    )
                }
            };
        },
        setQuestionHintModal(state, action) {
            state.chatBotUIState.questionHintModal = action.payload;
        },
        setQuestionHint(state, action) {
            state.botData.questionHint = action.payload;
        },
        setQuestionHintTitle(state, action) {
            state.botData.questionHintTitle = action.payload;
        },
        setIsOkButtonLoading(state, action) {
            state.chatBotUIState.isOkButtonLoading = action.payload;
        },
        setOpenChatBot(state, action) {
            const initChatBot = true;
            const tempInvitationData = action.payload.invitationData || {};
            const tempPatientId = action.payload.patientId || null;
            const testIdToResumeChat = action.payload.testIdToResumeChat || "";
            const meetingStart = action.payload?.meetingStart || '';
            const meetingEnd = action.payload?.meetingEnd || '';

            if (testIdToResumeChat) GLOBAL.ASSESSMENT = tempInvitationData; ////TODO: Have to add those in context API

            return {
                ...state,
                botData: {
                    ...state.botData,
                    patientId: tempPatientId,
                    invitationTypeName: (tempInvitationData?.InvitationTypeName || tempInvitationData?.RedirectTypeText) || '',
                    testIdToResumeChat: testIdToResumeChat,
                    bodyRegionList: tempInvitationData?.BodyRegionList || [],
                    invitationId: tempInvitationData?.InvitationId || '',
                    isFollowup: tempInvitationData?.IsFollowup || false,
                    meetingStart: meetingStart,
                    meetingEnd: meetingEnd,
                },
                chatBotUIState: {
                    ...state.chatBotUIState,
                    initChatBot: initChatBot,
                }
            }
        },
        clearChatBotProperty(state) {
            return {
                ...state,
                botData: {
                    ...state.botData,
                    patientId: "",
                    invitationTypeName: '',
                    testIdToResumeChat: "",
                    invitationId: '',
                    isFollowup: false,
                },
                chatBotUIState: {
                    ...state.chatBotUIState,
                    initChatBot: false,
                }
            }
        },




        updateChatResponse(state, action) {
            const { questionId, response } = action.payload;
            const index = state.chatResponses.findIndex(chat => chat.questionId === questionId);
            if (index !== -1) {
                state.chatResponses[index] = {
                    ...state.chatResponses[index],
                    ...response
                };
            }
        },
        addChatResponse(state, action) {
            state.chatResponses.push(action.payload);
        },
        resetState(state) {
            Object.assign(state, initialState);
        }
    },
    extraReducers: chatbotExtraReducers
});


export const {
    setAssessmentCompletion,
    setCurrentChatIndex,
    setPrevChatIndex,
    setTestId,
    setShowPopUpWarningForYesAtCautionQuestion,
    setChats,
    updateChatAtIndex,
    sliceChatAtIndex,
    setBotInfo,
    setIsFetchingChatQuestion,
    setChatBotErrorMsg,
    setSessionId,
    setBotProgressbarValue,
    clearChatbotReduxStore,
    setShowConfirmationPopupToCloseBot,
    setShowWelcomePage,
    setShowChatBot,
    setBotAnswerAtIndex,
    setOpenChiefComplaintModal,
    setIsLoadingCC,
    setOpenSelfAssessmentModal,
    setBodyRegionList,
    setSelectedResponseOfCautionQuestion,
    setQuestionHintModal,
    setQuestionHint,
    setQuestionHintTitle,
    setIsOkButtonLoading,
    setOpenChatBot,
    clearChatBotProperty,




    updateChatResponse,
    addChatResponse,
    resetState
} = dataSlice.actions;

export default dataSlice.reducer;