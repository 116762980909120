import examService from "../../services/ExamService";
import GLOBAL from "../../global";
import { doesHaveAnswerIdRequired, getUniqueQuestionIdsInSecondArray } from "./EditAssessmentUtils";
import { toast } from "react-toastify";
import answerValidationService from "../../services/answerValidation/AnswerValidationService";
import CONSTANT from "../../constants/Constant";

export const saveSingleQuestionAnswer = async (testId, answers) => {
    const payload = {
        Answers: answers,
        TestId: testId,
        Tenant: GLOBAL.TENANT,
    }

    const response = await examService.saveAssessment(payload);
    const { success, error } = response;

    if (!success) {
        console.error(error);
    }

    return response;
}

export const checkAnswerIdValidation = (question, answerId) => {
    let isValid = true;

    if(doesHaveAnswerIdRequired(question)) {
        if(!answerId) {
            toast.error("Please provide answerId for this question");
            isValid = false;
        }
    }

    return isValid;
}

export const handleQuestionAnswerValidation = (questions, dispatch, setAllQuestionsError, setShowInlineError) => {
    let allVisibleQuestion = questions.filter(question => !question.isDefaultHide);

    const { errorUpdatedQuestions, doesHasValidationErrors } = updateQuestionError(allVisibleQuestion);
    dispatch(setAllQuestionsError(errorUpdatedQuestions));
    if(doesHasValidationErrors) {
        dispatch(setShowInlineError(true));
        console.error('Please fix the errors before proceeding.');
    } else {
        dispatch(setShowInlineError(false));
    }

    return doesHasValidationErrors;
}

export const updateQuestionError = (questions) => {
    let doesHasValidationErrors = false;

    questions.map(question => {
        const questionId = question?.questionId || 0;
        const responseType = question?.responseType || '';
        let answerValue;
        if((question?.responseType === CONSTANT.ELEMENT_TYPES.CHECKBOX) || (question?.responseType === CONSTANT.ELEMENT_TYPES.MULTISELECT)) {
            answerValue = question?.answerList ?? [];
        } else {
            answerValue = question?.answerValue ?? '';
        }

        const validationResult = answerValidationService.validate(questionId, responseType, answerValue);

        // Update the question's error property directly
        question.error = {
            questionId: question.questionId,
            isValid: validationResult.valid,
            message: validationResult.message,
        };

        if (!validationResult.valid) {
            doesHasValidationErrors = true;
        }

        return question;
    })

    return {
        errorUpdatedQuestions: questions,
        doesHasValidationErrors
    };
}

export const getConditionalQuestions = (question) => {
    let childQuestionIdsOfSelectedResponse = [];
    let childQuestionIdsOfNotSelectedResponse = [];
    let selectedAnswerId = question?.answerId;

    for (const response of question.responses) {
        if (response?.conditionalQuestionList.length === 0) continue;

        if(response?.id === selectedAnswerId) {
            const newQuestionIds = getUniqueQuestionIdsInSecondArray(childQuestionIdsOfSelectedResponse, response.conditionalQuestionList);
            childQuestionIdsOfSelectedResponse.push(...newQuestionIds);
        } else {
            const newQuestionIds = getUniqueQuestionIdsInSecondArray(childQuestionIdsOfNotSelectedResponse, response.conditionalQuestionList);
            childQuestionIdsOfNotSelectedResponse.push(...newQuestionIds);
        }
    }

    return {
        childQuestionIdsOfSelectedResponse,
        childQuestionIdsOfNotSelectedResponse
    };
}

export const getAllVisibleQuestions = (currentGroup, currentSubGroupIndex) => {
    let allQuestions = [];
    const groupId = currentGroup?.groupId ? currentGroup?.groupId : CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;

    if(groupId === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key) {
        currentGroup?.subGroups.forEach(subGroup => {
            allQuestions = [...allQuestions, ...subGroup.questions];
        });
    } else {
        allQuestions = currentGroup?.subGroups[currentSubGroupIndex]?.questions || [];
    }

    return allQuestions;
}