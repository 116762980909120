import React from "react";

import GLOBAL from "../../../global";
import crossBtn from "../../../assets/img/utils/cross.svg";

const ChatbotHeader = (props) => {
    const toggleChatModal = props?.toggleChatModal || (() => {});

    return (
        <div className={`kt-portlet__head bg-color-primary`}>
            <div className="kt-chat__head mmh-chat--head">
                <div className="kt-chat__left flex-grow-2">
                    <div className="kt-chat__label">
                        <a rel="noopener noreferrer"
                           href={"!#"} onClick={(e) => e.preventDefault()}
                           className="kt-chat__title kt-font-xl cursor-hand">
                            <>
                                {GLOBAL.LOGO_URL
                                    ? <img src={GLOBAL.LOGO_URL} height="50" className="logo" alt="Logo"/>
                                    : <img src={GLOBAL.WHITE_LOGO_URL} height="50" className="logo" alt="Logo"/>
                                }
                            </>
                        </a>

                    </div>
                </div>
                <div className="kt-chat__right flex-grow-2">
                    <button type="button"
                            onClick={toggleChatModal}
                            className="btn"
                            data-dismiss="modal">
                        <img src={crossBtn} alt="close"/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ChatbotHeader;