import React from "react";
import {
    _focusChatElement,
    getPreviousEditableChatInd,
    getWillShowPrevQuestion,
    isAlreadyAnsweredCCOrBiometricMovementQuestion
} from "../ChatServices";
import {deepClone, getMarkup} from "../../../../utils/Utils";
import CONSTANT from "../../../../constants/Constant";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {
    setChats,
    setCurrentChatIndex,
    setPrevChatIndex,
    updateChatAtIndex
} from "../../../../features/chat/chatbotSlice";
import useChiefComplaintAtBot from "../hooks/useChiefComplaintAtBot";
import useSelfAssessmentAtChatbot from "../hooks/useSelfAssessmentAtChatbot";

const QuestionAnswerOfPrevQuestion = () => {
    const dispatch = useDispatch();
    const chatBotState = useSelector(state => state.chatBot);
    const chatBotUIState = chatBotState?.chatBotUIState;
    const botData = chatBotState?.botData || {};

    const chats = botData?.chats || [];
    const currentChatIndex = botData?.currentChatIndex ?? -1;
    const prevChatIndex = botData?.prevChatIndex ?? -2;

    const isFetchingChatQuestion = chatBotUIState?.isFetchingChatQuestion;

    const { chiefComplaintModalToggle } = useChiefComplaintAtBot();
    const { movementModalToggle } = useSelfAssessmentAtChatbot();

    const onClickPrevQuestion = async () => {
        if(isFetchingChatQuestion) {
            toast.info("Save in progress...");
            return;
        }

        let tempPrevChatIndex = prevChatIndex;
        let tempCurrentChatIndex = currentChatIndex;
        if((chats[prevChatIndex]?.questionId !== CONSTANT.CC_QUESTION.ID) && isAlreadyAnsweredCCOrBiometricMovementQuestion(chats[tempPrevChatIndex])) {
            tempPrevChatIndex = tempPrevChatIndex - 1;
        }

        let prevEditableChatInd = getPreviousEditableChatInd(chats, tempPrevChatIndex);

        if(prevEditableChatInd >= 0) {
            onClickEditCancel(chats[prevEditableChatInd], true);

            let prevPrevEditableChatInd = getPreviousEditableChatInd(chats, prevEditableChatInd - 1);
            if(prevPrevEditableChatInd >= 0) {
                onClickEditCancel(chats[prevPrevEditableChatInd], true);
            }
            dispatch(setPrevChatIndex(prevPrevEditableChatInd));
        }
        if(chats[tempCurrentChatIndex]?.answered === true) onClickEditCancel(chats[tempCurrentChatIndex], true);
        dispatch(setCurrentChatIndex(prevEditableChatInd));
    }

    const onClickEditCancel = (chat, editable) => {
        const { id, responseType } = chat;
        const tempChats = deepClone(chats);

        const index = tempChats.findIndex(item => item.id === id);

        if (index > -1) {
            let tempChat = deepClone(tempChats[index]);
            if (editable) {
                tempChat.answered = false;
                tempChat.mode = true;
            }
            dispatch(updateChatAtIndex({ index, chat: tempChat }));
        }
        // dispatch(setChats([...[], ...tempChats]));
        _focusChatElement(chat);
    };

    const openModal = async () => {
        if(chats[prevChatIndex].intent === CONSTANT.CHATBOT_INTENT.CHIEF_COMPLAINTS) {
            await chiefComplaintModalToggle(chats[prevChatIndex], chats[prevChatIndex]?.answers[0], true);
        } else if(chats[prevChatIndex].intent === CONSTANT.CHATBOT_INTENT.MOVEMENT) {
            await movementModalToggle(chats[prevChatIndex], chats[prevChatIndex]?.answers[0], true);
        }
    }

    return(
        <>
            {chats[currentChatIndex]?.types && (
                <>
                    {prevChatIndex >= 0 && getWillShowPrevQuestion(chats[prevChatIndex]) && (
                        <div className="prev-question-section pb-3 mb-2">
                            <div className="prev-question position-relative">
                                <p className="edit-question mb-1">
                                    {
                                        (chats[prevChatIndex]?.questionId === CONSTANT.CC_QUESTION.ID)
                                        ? "If you would like to review or edit your chief complaints, press edit"
                                        : "If you would like to edit your response to the last question, press edit"
                                    }
                                </p>
                                <div className="d-flex w-100 justify-content-between align-items-center">
                                    <div className="prev-question__container d-flex justify-content-between flex-grow-1">
                                        <div className="d-flex align-items-center prev-question__text-container">
                                        {chats[prevChatIndex]?.questionId !== CONSTANT.CC_QUESTION.ID &&  (
                                            <span className="color-primary-dark-1 text-justify"
                                            dangerouslySetInnerHTML={getMarkup(chats[prevChatIndex]?.dialogue)}/>
                                        )}
                                        </div>
                                        {chats[prevChatIndex]?.answers?.length > 0 && (
                                            <div className="answer-container ml-4">
                                                {(chats[prevChatIndex]?.answers[0]?.name !== ""
                                                    && chats[prevChatIndex].intent !== CONSTANT.CHATBOT_INTENT.MOVEMENT)
                                                    ?
                                                    <div className="answer p-3 bg-color-primary-light-1 color-white">
                                                        <div className="d-flex overflow-hidden align-items-center">
                                                            <div className="d-flex justify-content-center align-items-center"><i className="fa fa-check mr-3"/> </div>
                                                            <div className="overflow-hidden answer-text">{chats[prevChatIndex]?.answers[0]?.name.toString()}</div>
                                                        </div>
                                                    </div>

                                                    :
                                                    <div>
                                                        {chats[prevChatIndex]?.answers[0]?.referenceId === CONSTANT.REFERENCE_RESPONSE.NO && (
                                                            <div className={'answer p-3 font-size-md bg-color-primary-light-1 color-white'}>
                                                        <span>
                                                            <i className="fa fa-check mr-3"/>
                                                            {chats[prevChatIndex].intent === CONSTANT.CHATBOT_INTENT.CHIEF_COMPLAINTS
                                                                ? "NO CHIEF COMPLAINTS" //for chief complain
                                                                : "NO, I WILL DO LATER" //for biometric movement
                                                            }
                                                        </span>
                                                            </div>
                                                        )}
                                                        {/*{(chats[prevChatIndex]?.questionId !== CONSTANT.CC_QUESTION.ID) && isAlreadyAnsweredCCOrBiometricMovementQuestion(chats[prevChatIndex]) && (*/}
                                                        {/*    <div*/}
                                                        {/*        className={`answer p-3 font-size-md bg-color-primary-light-1 color-white cursor-pointer`}*/}
                                                        {/*        onClick={openModal}*/}
                                                        {/*    >*/}
                                                        {/*        <span>*/}
                                                        {/*            <i className="fa fa-check mr-3"/>*/}
                                                        {/*            <span>REVIEW</span>*/}
                                                        {/*        </span>*/}
                                                        {/*    </div>*/}
                                                        {/*)}*/}
                                                    </div>
                                                }

                                                <div
                                                    className="answer p-3 color-white edit bg-color-green hover-bg-color-bright-light-green"
                                                    onClick={onClickPrevQuestion}
                                                >
                                                        <span
                                                            className={`ml-2 white-space-pre ${isAlreadyAnsweredCCOrBiometricMovementQuestion(chats[prevChatIndex]) ? ' font-size-md ' : ""}`}>
                                                             Edit
                                                        </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    )}
                </>
            )}

        </>
    )
}

export default QuestionAnswerOfPrevQuestion;
