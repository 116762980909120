import { getNotificationInformation } from '../data/api'
import { mapNotificationDataToLocalNotificationModal } from '../data/models/NotificationMapping'
import CONSTANT from "../../../../constants/Constant";


const dateRegex = /(\d{1,2} \w+ \d{2,4}) (\d{1,2}:\d{1,2} [APMapm]{2}) - (\d{1,2}:\d{1,2} [APMapm]{2})/;

export const getNotificationData = async (Email) => {
   const notificationData = await getNotificationInformation({ Email })
    const formatedData  = mapNotificationDataToLocalNotificationModal(notificationData)

    return formatedData
}

export const extractDateAndTime = (dateTimeString) => {
    const match = dateTimeString.match(dateRegex);

    // 20 February 2024 01:52 PM - 03:52 PM
    if (match) {
        const datePart = match[1]; // "20 February 2024"
        const startTimeUTC = match[2]; // "01:52 PM"
        const endTimeUTC = match[3]; // "03:52 PM"
        return {
            date: datePart,
            startTimeUTC,
            endTimeUTC
        }
    } else {
        return null;
    }
}

export const timeConverterComponent = (dateTimeString) => {
    const dateTime = extractDateAndTime(dateTimeString);

    if (!dateTime) {
        return "Invalid date and time";
    }

    const { date, startTimeUTC, endTimeUTC } = dateTime;

    // Extract just the time part for start and end
    const startTime = new Date(`${date} ${startTimeUTC} UTC`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    const endTime = new Date(`${date} ${endTimeUTC} UTC`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

    // Assuming the date doesn't change, use the start date
    const localDate = new Date(`${date} ${startTimeUTC} UTC`).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

    return {
        date: localDate,
        startTime,
        endTime
    }
};
