import { isEmptyObject } from "../../utils/Utils";
import { toast } from "react-toastify";
import Chat from "../../models/Chat";
import ChatRequestDetail from "../../models/ChatRequestDetail";
import GLOBAL from "../../global";

export const handleResumeChat = (state, payload) => {
    const chats = payload?.chatResponses || [];
    if(chats.length <= 0) {
        toast.error("No data found");
        return;
    }

    const formatedChats = mapResumeChatBotResponse(chats, state);
    state.botData.chats = formatedChats;

    ////TODO: Will be shift to redux store
    GLOBAL.ASSESSMENT.ADLCompleted = payload?.isADLCompleted || false;
    GLOBAL.ASSESSMENT.MedicalHistoryCompleted = payload?.isMedicalHistoryCompleted || false;
    GLOBAL.ASSESSMENT.ChiefComplaintsCompleted = payload?.isChiefComplaintCompleted || false;
    GLOBAL.ASSESSMENT.BiometricCompleted = payload?.isBiometricCompleted || false;
}


const mapResumeChatBotResponse = (response, state) => {
    if(!response || isEmptyObject(response)) return;

    let tempChats = [];
    let tempChat = {};
    let tempAnswers = [];
    let tempAnswer = {};
    let answered = false;
    let tempResponse = {};

    let sessionId = response[0]?.sessionId || "";
    // setSessionId && setSessionId(sessionId);

    if(!sessionId) {
        toast.error("Session Id is missing");
        console.error("Session Id is missing");
        return;
    }

    state.botData.sessionId = sessionId;

    for(const element of response) {
        tempChat = element;
        tempAnswers = [];
        tempAnswer = {};
        answered = false;
        tempResponse = {};

        tempChat = new Chat({
            ...element
        });

        for(const element of tempChat.responses) {
            tempResponse = element;
            if(tempResponse?.checked) {
                answered = true;
            } else {
                answered = false;
                continue;
            }

            tempAnswer = new ChatRequestDetail({
                ...tempResponse
            });

            tempAnswers.push(tempAnswer);
        }
        tempChat.answered = answered;
        tempChat.answers = tempAnswers;

        tempChats.push(tempChat);
    }
    return tempChats;
}