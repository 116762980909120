import GLOBAL from "../../../global";
import axios from "../../../services/Axios";
import { PROCESS_EXERCISE_VIDEO_URL } from "../MovementModal/Services/UrlManager";
import { error, success } from "../../../services/ResponseHandler";

const RESPONSE_TYPE = { responseType: 'text' };

export const processExerciseVideo = async (testId, exerciseValue, isMeasurementNeeded, base64Image) => {
    const height = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['Height']) || 0;

    const payload = {
        tenant: GLOBAL.TENANT,//ROM API
        testId: testId,
        height: height,
        exerciseName: exerciseValue,
        exerciseMode: "normal",//means not passive movement
        registeredExerciseName: exerciseValue,
        isMeasurementNeeded: isMeasurementNeeded,
        isVideoData: true,
        mediaData: base64Image,
    };

    let responseObj = {
        success: false,
        message: ""
    }

    try{
        const response = await axios.post(PROCESS_EXERCISE_VIDEO_URL, payload, RESPONSE_TYPE);
        if(response && response["success"] !== undefined){
            responseObj.success = response["success"];
            responseObj.message = response["success"] === false ? response["message"] : "";
        }
        return success(responseObj);
    }
    catch(err){
        return error(err);
    }
}