import React, { useEffect, useState } from "react";
import CONSTANT from "../../../constants/Constant";
import {toast} from "react-toastify";

const InputBox = ({ label, value, placeHolder, disabled= false, question, error, onChange }) => {
    const [inputValue, setInputValue] = useState('');
    const [heightInFeet, setHeightInFeet] = useState('');
    const [heightInInch, setHeightInInch] = useState('');

    useEffect(() => {
        setInputValue(value);
        if (value !== '') {
            const feet = Math.floor(value / 12);
            const inch = value % 12;
            setHeightInFeet(feet.toString());
            setHeightInInch(inch.toString());
        }
    }, [value])

    const onChangeInput = (value, willSave = false) => {
        if(disabled) {
            toast.error("Report already ready can not change the response");
            return;
        }

        setInputValue(value);
        onChange && onChange({ question: question, answerText: value, willSave });
    };

    const onBlurInput = (value) => {
        if(disabled) {
            toast.error("Report already ready can not change the response");
            return;
        }

        setInputValue(value);
        onChange({ question: question, answerText: value });
    }

    const getAndSaveHeight = (value, type) => {
        if(disabled) {
            toast.error("Report already ready can not change the response");
            return;
        }

        let feet = 0;
        let inch = 0;
        let totalHeight;
        if (type === CONSTANT.HEIGHT.FEET) {
            feet = (value && parseInt(value)) || 0;
            setHeightInFeet(value);
            inch = (heightInInch && parseInt(heightInInch)) || 0;
            totalHeight = (feet * 12 + inch).toString();
        } else if (type === CONSTANT.HEIGHT.INCH) {
            inch = (value && parseInt(value)) || 0;
            if(inch > 11) {
                return;
            }

            setHeightInInch(value);
            feet = (heightInFeet && parseInt(heightInFeet)) || 0;
            totalHeight = (inch + feet * 12).toString();
        }

        setInputValue(totalHeight);
        onChangeInput(totalHeight, true);
    }

    return (
        <>
            {question.questionId !== CONSTANT.CHATBOT_ACCOUNT.HEIGHT && (
                <div className="form-group row">
                    <label id={`cc-question-${question.questionId}`}
                           className="col-xl-4 col-lg-4 col-form-label heading-primary">{label}</label>
                    <div className="col-lg-8 col-xl-8">
                        <input className="text-input" onChange={(e) => onChangeInput(e.target.value)}
                               onBlur={(e) => onBlurInput(e.target.value)}
                               placeholder={placeHolder} type="text"
                               value={inputValue} disabled={disabled}/>
                        {error && error.questionId === question.questionId && (
                            <span className="form-text text-muted kt-font-danger">* {error.message}</span>
                        )}
                    </div>
                </div>
            )}
            {question.questionId === CONSTANT.CHATBOT_ACCOUNT.HEIGHT && (
                <div className="form-group row">
                    <label id={`cc-question-${question.questionId}`} className="col-xl-4 col-lg-4 col-form-label heading-primary">{label}</label>
                    <div className="col-lg-8 col-xl-8 col-form-label">
                        <div className="d-flex kt-space-between">
                            <input className="text-input w-32"
                                   onChange={(e) => getAndSaveHeight(e.target.value, CONSTANT.HEIGHT.FEET)}
                                   placeholder='Feet'
                                   maxLength="1"
                                   type="text"
                                   value={heightInFeet > 0 ? heightInFeet : ''}
                                   disabled={disabled}
                            />
                            <input className="text-input w-25"
                                   onChange={(e) => getAndSaveHeight(e.target.value, CONSTANT.HEIGHT.INCH)}
                                   placeholder='Inch'
                                   type="text"
                                   value={heightInInch > 0 ? heightInInch : ''}
                                   disabled={disabled}
                            />
                            <input className="text-input w-25" onChange={(e) => onChangeInput(e.target.value)}
                                   type="text"
                                   value={inputValue} disabled/>
                        </div>
                        {error && error.questionId === question.questionId && (
                            <span className="form-text text-muted kt-font-danger">* {error.message}</span>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default InputBox;
