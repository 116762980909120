import React, { useEffect, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import GLOBAL from "../../../global";

import AddPaymentCardForm from "./AddPaymentCardForm";
import subscriptionService from "../../../services/SubscriptionService";
import PaymentCard from "../../../models/PaymentCard";

const PaymentMethods = () => {

    const [openAddPaymentMethodModal, setOpenAddPaymentMethodModal] = useState(false);
    const patientId = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['ContactId']) || '';
    const [paymentCards, setPaymentCards] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            await getBillingAddresses();
        };

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps,
    }, [])

    const getBillingAddresses = async () => {
        const payload = {
            PatientId: patientId,
        };
        const response = await subscriptionService.getPaymentMethods(payload);
        const { data, success, error } = response;

        if (!success) {
            console.log(error);
            return;
        }

        const cards = [];
        data.forEach((item) => {
            const card = new PaymentCard(item);
            cards.push(card);
        })

        console.log({ cards })
        setPaymentCards(cards);
    }

    const toggleAddPaymentMethodModal = (e) => {
        e.preventDefault();
        setOpenAddPaymentMethodModal(!openAddPaymentMethodModal);
    }

    const getBotWidthStyle = () => {
        if (isMobile || isTablet) {
            return 'w-100';
        } else {
            return 'w-49 kt-mr-10';
        }
    };
    return (
        <>
            <div className="kt-portlet kt-portlet--height-fluid">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            Payment Methods
                        </h3>
                    </div>
                </div>
                <div className="kt-portlet__body kt-portlet__body--fluid flex-column">
                    <div className="kt-mb-10">
                        <h4>My Cards</h4>
                    </div>
                    <div className="kt-widget5 d-flex flex-wrap">
                        {paymentCards && paymentCards.length > 0 && (
                            paymentCards.map((card, index)=> {
                                return (
                                    <div key={index}
                                        className={`kt-widget5__item flex-column kt-padding-20 align-items-start ${getBotWidthStyle()}`}
                                        style={{ border: '1px dashed #e4e6ef', borderRadius: 5 }}>
                                        <div className="w-100 kt-mb-10">
                                            <h4 className="kt-font-transform-c">{card.CardHolderName}
                                                <span
                                                    className="kt-badge kt-badge--success kt-badge--md kt-badge--inline kt-ml-5"> {card.Status}</span>
                                            </h4>
                                        </div>
                                        <div className="d-flex w-100 flex-row flex-wrap">
                                            <div className="kt-widget5__content kt-mr-25">
                                                <img className="kt-mr-10"
                                                     src={card.CardImage}
                                                     style={{ width: '80px', height: '50px', borderRadius: '5px' }}
                                                     alt="Pic"/>
                                                <div className="kt-widget5__section">
                                                    <span className="kt-widget5__title">
                                                        {card.CardNumber}
                                                    </span>
                                                    <p className="kt-widget5__desc text-muted">
                                                        Card expires at {card.ExpiresDate}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="kt-widget5__content">
                                                <button className="btn btn-secondary btn-sm kt-mr-5"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#kt_modal_upgrade_plan">Edit
                                                </button>
                                                <button className="btn btn-secondary btn-sm" data-bs-toggle="modal"
                                                        data-bs-target="#kt_modal_upgrade_plan">Delete
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        )}
                        <div
                            className={`kt-widget5__item bg-secondary flex-column kt-padding-20 align-items-start ${getBotWidthStyle()}`}
                            style={{ border: '1px dashed #e4e6ef', borderRadius: 5 }}>
                            <div className="d-flex flex-row">
                                <div className="kt-widget5__content kt-mr-25">
                                    <div className="kt-widget5__section">
                                        <h5>Important Note!</h5>
                                        <p className="kt-widget5__desc">
                                            Please carefully read
                                            <a href={"!#"} className="kt-link kt-font-boldest kt-ml-5">Payment terms
                                            </a> before adding your your new payment card
                                        </p>
                                    </div>
                                </div>
                                <div className="kt-widget5__content">
                                    <button className="btn btn-success btn-sm kt-mr-5"
                                            data-bs-toggle="modal" onClick={toggleAddPaymentMethodModal}
                                            data-bs-target="#kt_modal_upgrade_plan">Add Card
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openAddPaymentMethodModal && (
                <Modal isOpen={openAddPaymentMethodModal} className="modal-md">
                    <ModalHeader toggle={toggleAddPaymentMethodModal}>
                        Add New Card
                    </ModalHeader>
                    <ModalBody>

                        <AddPaymentCardForm/>
                    </ModalBody>
                </Modal>
            )}
        </>
    )
}

export default PaymentMethods;