import CONSTANT from "../../../../constants/Constant";

export const getParentQuestionOfChildQuestion = (painQuestions, childQuestionId) => {
    let parentQuestion = null;
    painQuestions?.forEach(question => {
        if(question?.Responses?.length > 0) {
            question.Responses.forEach(response => {
                for(let i = 0; i < response.ConditionalQuestionList.length; i++) {
                    if(response.ConditionalQuestionList[i] === childQuestionId) {
                        parentQuestion = question;
                        break;
                    }
                }
            });
        }
    });
    return parentQuestion;
}

export const getPainQuestionAnswers = (painQuestions, questionId) => {
    const question = painQuestions.find(question => question.QuestionId === questionId);
    if(!question) return [];

    if(question.QuestionType === CONSTANT.ELEMENT_TYPES.CHECKBOX) {
        return question?.AnswerList || [];
    } else if(question.QuestionType === CONSTANT.ELEMENT_TYPES.BUTTON) {
        return question?.AnswerId || 0;
    }
    // return (painQuestions.find(question => question.QuestionId === questionId)?.AnswerList ||
    //     painQuestions.find(question => question.QuestionId === questionId)?.AnswerId) || [];
};

export const willShowQuestion = (question, painQuestions, conditionalQuestionList) => {
    const isCurrentQuestionIsAConditionalChildQuestion = conditionalQuestionList.includes(question.QuestionId);
    if (!isCurrentQuestionIsAConditionalChildQuestion) return true;

    const parentQuestion = getParentQuestionOfChildQuestion(painQuestions, question.QuestionId);
    if (!parentQuestion) return true;

    return isQuestionVisibleBasedOnParent(parentQuestion, question, painQuestions);
};

const isQuestionVisibleBasedOnParent = (parentQuestion, childQuestion, painQuestions) => {
    const parentQuestionAnswers = getPainQuestionAnswers(painQuestions, parentQuestion.QuestionId);

    if (parentQuestion.QuestionType === CONSTANT.ELEMENT_TYPES.CHECKBOX) {
        return shouldShowCurrentChildCheckboxQuestion(parentQuestion, parentQuestionAnswers, childQuestion.QuestionId);
    } else if (parentQuestion.QuestionType === CONSTANT.ELEMENT_TYPES.BUTTON) {
        return shouldShowCurrentChildButtonQuestion(parentQuestion, parentQuestionAnswers, childQuestion.QuestionId);
    }

    return true;
};

export const shouldShowCurrentChildButtonQuestion = (parentQuestion, answerIdOfParentQuestion, childQuestionId) => {
    const selectedResponse = parentQuestion.Responses.find(response => response.AnswerId === answerIdOfParentQuestion);
    if (!selectedResponse || selectedResponse?.ConditionalQuestionList.length === 0) return false;

    return selectedResponse?.ConditionalQuestionList.includes(childQuestionId);
};

export const shouldShowCurrentChildCheckboxQuestion = (parentQuestion, answerListOfParentQuestion, childQuestionId) => {
    if (answerListOfParentQuestion.length === 0) return false;

    const doesSelectedResponseContainCurrentChildQuestion = parentQuestion.Responses
        .filter(response => answerListOfParentQuestion.includes(response.AnswerId))
        .some(response => response?.ConditionalQuestionList.includes(childQuestionId));

    return doesSelectedResponseContainCurrentChildQuestion;
};

export const getConditionalQuestionIds = (questions) => {
    const conditionalQuestionListSet = new Set();

    questions?.forEach(question => {
        if(question?.Responses?.length > 0) {
            question.Responses.forEach(response => {
                if(response?.ConditionalQuestionList?.length > 0) {
                    response.ConditionalQuestionList.forEach(conditionalQuestionId => {
                        conditionalQuestionListSet.add(conditionalQuestionId);
                    });
                }
            });
        }
    });


    return [...conditionalQuestionListSet];
}