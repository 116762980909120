import {
    createChatRequest,
    getIndexOfFirstUnansweredChat,
    shouldSaveToServer,
    willSelectBodyRegionAutomatically
} from "../ChatUtils";
import {
    getPreviousEditableChatInd,
    onSaveSingleClick,
    removeChildQuestions,
    toggleCheckboxChatResponse
} from "../ChatServices";
import {
    clearChatBotProperty, clearChatbotReduxStore, setBodyRegionList,
    setBotProgressbarValue, setChatBotErrorMsg, setChats,
    setCurrentChatIndex, setIsFetchingChatQuestion, setPrevChatIndex,
    setSessionId, updateChatAtIndex
} from "../../../../features/chat/chatbotSlice";
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../../../global";
import CONSTANT from "../../../../constants/Constant";
import ChatRequestDetail from "../../../../models/ChatRequestDetail";
import { saveOrCreateNewAssessment } from "../business/chatbotService";
import chatbotService from "../../../../services/ChatbotService";
import Chat from "../../../../models/Chat";
import { useContext } from "react";
import AppContext from "../../../../contexts/AppContext";
import { deepClone, playAudio } from "../../../../utils/Utils";
import { _getBodyRegion } from "../business/NewScreeningUtils";
import { fetchChatThunks } from "../../../../features/chat/chatbotThunks";

const useChatbot = () => {
    const { isAudioPlayEnabled, audioPlayEnabled } = useContext(AppContext); //TODO: will replace with redux

    const dispatch = useDispatch();
    const botState = useSelector(state => state.chatBot);

    const botData = botState?.botData || {};

    const chats = botData?.chats || [];
    const currentChatIndex = botData?.currentChatIndex ?? -1;
    const selectedBotInfoObj = botData?.botInfo || {};
    const sessionId = botData?.sessionId || '';
    const invitationId = botData?.invitationId || '';
    let invitationTypeName = (botData?.invitationTypeName || '').toLowerCase();
    const isFollowup = botData?.isFollowup || false;

    const patientId = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['ContactId']) || '';

    const prepareChatbotAnswer = async (chat, removeChildQuestionIds = []) => {
        let { mode } = chat;
        if (chat?.answers && (chat?.answers.length === 0)) {
            return;
        }

        ////TODO: Have to remove those
        GLOBAL.SAVED_ANSWERS = GLOBAL.SAVED_ANSWERS.filter((answer) => answer.QuestionId !== chat.questionId);

        const { questionId, intent } = chat;
        let tempChats = deepClone(chats);
        const index = tempChats.findIndex(item => item.questionId === questionId);

        if (index > -1) {
            let tempChat = deepClone(tempChats[index]);
            tempChat = { ...tempChat, ...chat };
            tempChat.answered = true;

            tempChats[index] = tempChat;
            dispatch(updateChatAtIndex({ index, chat: tempChat }));
        }

        // noinspection JSIgnoredPromiseFromCall
        await fetchAndProcessChatQuestion(intent, mode, tempChats, index, removeChildQuestionIds);
    }

    const setCurrentAndPrevChatIndex = (chats) => {
        let firstUnansweredChatInd = getIndexOfFirstUnansweredChat(chats);
        dispatch(setCurrentChatIndex(firstUnansweredChatInd));
        const tempPrevChatIndex = getPreviousEditableChatInd(chats, firstUnansweredChatInd - 1);

        dispatch(setPrevChatIndex(tempPrevChatIndex));
        return firstUnansweredChatInd;
    }

    const calculateChatbotProgress = (botName = '') => {
        let totalCount = 4;
        if (botName === CONSTANT.CHAT_BOTS.INTAKE_BOT) {
            totalCount = 3;
        }

        let countStep = GLOBAL.ASSESSMENT?.ADLCompleted ? 1 : 0;
        countStep += GLOBAL.ASSESSMENT?.MedicalHistoryCompleted ? 1 : 0;
        countStep += GLOBAL.ASSESSMENT?.ChiefComplaintsCompleted ? 1 : 0;
        countStep += GLOBAL.ASSESSMENT?.BiometricCompleted ? 1 : 0;

        let percentage = 100 / totalCount;
        dispatch(setBotProgressbarValue(Math.round(percentage * countStep)));
    }

    const insertNewChatQuestionIntoState = (chat, updatedChats) => {
        const childQuestionIdsList = GLOBAL?.parentAndChildQuestionIds?.childQuestionIds || [];
        const parentQuestionId = GLOBAL?.parentAndChildQuestionIds?.parentQuestionId || -1;

        if (Object.keys(GLOBAL?.parentAndChildQuestionIds).length === 0) {
            updatedChats = [...updatedChats, chat];
            dispatch(setChats(updatedChats));
            setCurrentAndPrevChatIndex(updatedChats);
            return;
        }

        insertChildChatQuestionAtCorrectPosition(chat, parentQuestionId, childQuestionIdsList, updatedChats);
    };

    const insertChildChatQuestionAtCorrectPosition = (chat, parentQuestionId, childQuestionIdsList, updatedChats)=> {
        let lastPresentChildQuestionIndex = -1;
        let totalChildQuestionFetched = 0;
        for(let i = 0; i < childQuestionIdsList.length; i++) {
            for(let j = 0; j < updatedChats.length; j++) {
                if(updatedChats[j].questionId === childQuestionIdsList[i]) {
                    lastPresentChildQuestionIndex = j;
                    totalChildQuestionFetched = i + 1;
                    break;
                }
            }
        }

        const isCurrentQuestionAlreadyPresent = updatedChats.findIndex(tempChat=> tempChat.questionId === chat.questionId);
        const tempChats = [...updatedChats];
        if(isCurrentQuestionAlreadyPresent === -1) {
            if(lastPresentChildQuestionIndex === -1) {
                const parentQuestionIndex = tempChats.findIndex(tempChat => tempChat.questionId === parentQuestionId);
                tempChats.splice(parentQuestionIndex + 1, 0, chat);
            }
            else {
                tempChats.splice(lastPresentChildQuestionIndex + 1, 0, chat);
            }
            dispatch(setChats(tempChats));
        }
        setCurrentAndPrevChatIndex(tempChats);
        if(totalChildQuestionFetched === childQuestionIdsList.length) {
            GLOBAL.parentAndChildQuestionIds = {};
        }
    }

    //updatedCurrentIndex for while automatically selecting body region
    const fetchAndProcessChatQuestion = async (intent, isEditing = false, updatedChats = [], updatedCurrentIndex = -3, removeChildQuestionIds = []) => {//saving and fetching chat
        const { options } = selectedBotInfoObj;
        let chatRequest = createChatRequest(updatedCurrentIndex, updatedChats, intent, isEditing, options, patientId, invitationId, invitationTypeName, isFollowup, sessionId);
        if(intent === CONSTANT.CHATBOT_INTENT.INITIAL) {
            chatRequest.answers = [{
                ...new ChatRequestDetail({ name : "hello" })
            }]
        }

        dispatch(setIsFetchingChatQuestion(true));
        let currentChat = updatedChats[updatedCurrentIndex] || {};

        if (shouldSaveToServer(intent, currentChat)) {
            dispatch(setChatBotErrorMsg(''));

            const response = await saveOrCreateNewAssessment(updatedChats, invitationId); ////TODO: have to compare with onSaveSingleClick
            const { data, success } = response;

            if (!success) {
                if(currentChat) currentChat.answered = false;
                dispatch(setIsFetchingChatQuestion(false));
                setChatBotErrorMsg('Internal server error. Please try again.');
                return;
            }
        }

        chatRequest.testId = (GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['TestId']) || '';
        chatRequest.demographicsCompleted = (GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['DemographicsCompleted']) || false;
        chatRequest.chatEnded = updatedChats[updatedCurrentIndex]?.chatEnded;
        await onSaveSingleClick({ ...chatRequest , responseType: currentChat?.responseType || ''});

        if (currentChat?.lastQuestionInGroup && !isEditing) {
            calculateChatbotProgress(currentChat?.botName);
        }

        if(intent === CONSTANT.CHATBOT_INTENT.BODY_REGION) {
            dispatch(setBodyRegionList(_getBodyRegion(updatedChats)));
        }

        const response = await chatbotService.chat(chatRequest, options.apiUrl);
        const { data, success, error } = response;

        if(removeChildQuestionIds.length > 0) {
            const result = await removeChildQuestions(updatedChats, removeChildQuestionIds, isEditing, dispatch);
            updatedChats = result.chats;
            isEditing = result.mode;
        }

        let doesNeedFetchNewQuestion = await handleEditingAnswer(isEditing, updatedChats);
        if(!doesNeedFetchNewQuestion) return;
        dispatch(setIsFetchingChatQuestion(false));

        if (!success) {
            if(currentChat) currentChat.answered = false;
            if(error) {
                setChatBotErrorMsg(error);
            } else {
                setChatBotErrorMsg('Internal server error. Please try again.');
            }
            return;
        }


        const chat = new Chat(data);
        dispatch(setSessionId(chat?.sessionId));
        insertNewChatQuestionIntoState(chat, updatedChats);
    };

    const handleEditingAnswer = async (isEditing, updatedChats) => {
        if (!isEditing || Object.keys(GLOBAL?.parentAndChildQuestionIds).length > 0) return true;

        dispatch(setIsFetchingChatQuestion(false));
        dispatch(setChats(updatedChats));

        let firstUnansweredChatInd = getIndexOfFirstUnansweredChat(updatedChats);
        if (firstUnansweredChatInd === -1) {
            return updatedChats.length > 0;
        }

        setCurrentAndPrevChatIndex(updatedChats);
        return false;
    }

    const onChangeUpdateChatsState = async (chat) => {
        const tempChats = deepClone(chats);
        const indexC = tempChats.findIndex(item => item.id === chat.id);
        if (indexC > -1) {
            tempChats[indexC] = chat;
            dispatch(updateChatAtIndex({ index: indexC, chat }));
        }
    };

    const clearAndCloseChatbot = () => {
        dispatch(clearChatBotProperty());
        dispatch(clearChatbotReduxStore());
        GLOBAL.ASSESSMENT = null;
        GLOBAL.OUTCOME_ASSESSMENT = [];
        GLOBAL.SAVED_ANSWERS = [];
        GLOBAL.CHIEF_COMPLAINTS_ANSWERS = [];
        GLOBAL.ISANYCHIEFCOMPLAINTANSWEDED = false;
        GLOBAL.BIOMETRIC_PROGRESS = 0;
        playAudio("remove");
    };

    const automaticallySelectBodyRegion = async (bodyRegionList, callback) => {
        if (currentChatIndex !== 0) {
            return;
        }

        const chat = chats[currentChatIndex];
        let deepCopyChat = deepClone(chat);
        if (willSelectBodyRegionAutomatically(deepCopyChat, bodyRegionList)) {
            const updatedChat = await selectBodyRegions(deepCopyChat, bodyRegionList);
            await prepareChatbotAnswer(updatedChat);
        }
        if(callback) callback();
    };

    const selectBodyRegions = async (chat, bodyRegionList) => {
        let updatedChat = deepClone(chat);
        for (const chatResponse of chat.responses) {
            if (bodyRegionList.includes(chatResponse.id)) {
                updatedChat = toggleCheckboxChatResponse(updatedChat, chatResponse);
                await onChangeUpdateChatsState(updatedChat);
            }
        }
        return updatedChat;
    };

    const onChangeAudioPlay = async () => {
        await playAudio('remove');
        let wasAudioPlayEnabled = isAudioPlayEnabled;
        GLOBAL.AUDIO_PLAY_ENABLED = !isAudioPlayEnabled;
        if(audioPlayEnabled) audioPlayEnabled(!isAudioPlayEnabled);
        if(!wasAudioPlayEnabled && chats.length > 0 && chats[currentChatIndex]?.audioUrl) {
            playAudio(chats[currentChatIndex].audioUrl);
        }
    };



    return {
        setCurrentAndPrevChatIndex,
        prepareChatbotAnswer,
        onChangeAudioPlay,
        onChangeUpdateChatsState,
        fetchAndProcessChatQuestion,
        removeChildQuestions,
        calculateChatbotProgress,
        clearAndCloseChatbot,
        automaticallySelectBodyRegion,
    }
}

export default useChatbot;