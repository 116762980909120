import React from "react";
import GLOBAL from "../../../../global";
import MovementModal from "../../MovementModal/MovementModal";
import QuestionAnswerOfPrevQuestion from "./QuestionAnswerOfPrevQuestion";
import CurrentQuestion from "./currentQuestion/CurrentQuestion";
import ConfirmationDialog from "../../Popups/ConfirmationDialog";
import ChiefComplaintWithBodyPart from "../../chiefComplaintNew/ChiefComplaintWithBodyPart";
import {useSelector} from "react-redux";
import useChatbot from "../hooks/useChatbot";
import useSelfAssessmentAtChatbot from "../hooks/useSelfAssessmentAtChatbot";
import useChiefComplaintAtBot from "../hooks/useChiefComplaintAtBot";
import {deepClone} from "../../../../utils/Utils";


const  QuestionContainer = () => {
    const chatBotState = useSelector(state => state.chatBot);
    const botData = chatBotState?.botData || {};
    const chatBotUIState = chatBotState?.chatBotUIState || {};

    const chats = botData?.chats || [];
    const currentChatIndex = botData?.currentChatIndex ?? -1;
    const bodyRegionList = botData?.bodyRegionList || [];
    const selectedBotInfoObj = botData?.botInfo || {};
    const prevChatIndex = botData?.prevChatIndex ?? -2;

    const openChiefComplaintModal = chatBotUIState?.openChiefComplaintModal || false;
    const openSelfAssessmentModal = chatBotUIState?.openSelfAssessmentModal || false;
    const showPopUpWarningForYesAtCautionQuestion = chatBotUIState?.showPopUpWarningForYesAtCautionQuestion || false;

    const { onCloseChiefComplaint, onSubmitChiefComplaint } = useChiefComplaintAtBot();
    const { handleCloseBiometricModal, handleClickNoAtPopUpWarningForYesAtCautionQuestionClose, handleClickYesAtPopUpWarningForYesAtCautionQuestionClose } = useSelfAssessmentAtChatbot();
    const { clearAndCloseChatbot } = useChatbot();

    const { options } = selectedBotInfoObj;

    return (
        <div className="question">
            <div className="kt-chat__message kt-chat__message--success questions__container w-100">
                <QuestionAnswerOfPrevQuestion />

                <CurrentQuestion />

                <MovementModal
                    open={openSelfAssessmentModal}
                    selectedRegion={bodyRegionList}
                    testId={(GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['TestId']) || ''}
                    onClose={handleCloseBiometricModal}
                    clearAndCloseChatbot={clearAndCloseChatbot}
                />

                <ChiefComplaintWithBodyPart
                    //if clicked from current chat question then currentChatIndex or if clicked from previous chat question then prevChatIndex
                    chat={deepClone(chats[currentChatIndex])}
                    options={options}
                    isOpen={openChiefComplaintModal}
                    onClose={() => onCloseChiefComplaint()}
                    onSubmit={onSubmitChiefComplaint}
                    testId={(GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['TestId']) || ''}
                />

                {/* While clicking yes at caution question of biometric movement*/}
                {showPopUpWarningForYesAtCautionQuestion && (
                    <ConfirmationDialog
                        confirmText="Yes, I want to cancel assessment."
                        confirmBtnCssClass="btn btn-danger"
                        cancelText="No, I wanted to continue."
                        cancelBtnCssClass="btn btn-success"
                        show={showPopUpWarningForYesAtCautionQuestion}
                        onClickHeaderClose={handleClickNoAtPopUpWarningForYesAtCautionQuestionClose}
                        toggleDialog={handleClickNoAtPopUpWarningForYesAtCautionQuestionClose}
                        onConfirm={handleClickYesAtPopUpWarningForYesAtCautionQuestionClose}>
                        If you select YES you will not be able to perform your biometric movements. Are you sure?
                    </ConfirmationDialog>
                )}
            </div>
        </div>
    )
};

export default QuestionContainer;