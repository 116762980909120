import CONSTANT from "../../../../constants/Constant";
import CheckBoxQuestionsAnswers from "../Responses/CheckBoxQuestionsAnswers";
import LabelQuestionsAnswers from "../Responses/LabelQuestionsAnswers";
import ButtonQuestionsAnswers from "../Responses/ButtonQuestionsAnswers";
import MovementModalAnswers from "../Responses/MovementModalAnswers";
import ChiefComplaintQuestionsAnswers from "../Responses/ChiefComplaintQuestionsAnswers";
import AutoCompleteQuestionsAnswers from "../Responses/AutoCompleteQuestionsAnswers";
import TextQuestionsAnswers from "../Responses/TextQuestionsAnswers";
import DatetimePickerQuestionsAnswers from "../Responses/DatetimePickerQuestionsAnswers";
import DatePickerQuestionsAnswers from "../Responses/DatePickerQuestionsAnswers";
import React from "react";
import DropdownQuestionsAnswers from "../Responses/DropdownQuestionsAnswers";
import {useSelector} from "react-redux";

const CurrentQuestionResponse = () => {
    const chatBotState = useSelector(state => state.chatBot);
    const botData = chatBotState?.botData || {};
    const chats = botData?.chats || [];
    const currentChatIndex = botData?.currentChatIndex ?? -1;

    return (
        <div className="mb-5">
            {chats[currentChatIndex]?.responseType === CONSTANT.ELEMENT_TYPES.CHECKBOX && (
                <CheckBoxQuestionsAnswers />
            )}

            {chats[currentChatIndex]?.responseType === CONSTANT.ELEMENT_TYPES.LABEL && (
                <LabelQuestionsAnswers />
            )}

            {chats[currentChatIndex]?.responseType === CONSTANT.ELEMENT_TYPES.BUTTON && (
                <ButtonQuestionsAnswers />
            )}

            {chats[currentChatIndex]?.responseType === CONSTANT.ELEMENT_TYPES.MODAL && (
                <>
                    {chats[currentChatIndex]?.intent === CONSTANT.CHATBOT_INTENT.MOVEMENT && (
                        <MovementModalAnswers />
                    )}

                    {chats[currentChatIndex]?.intent === CONSTANT.CHATBOT_INTENT.CHIEF_COMPLAINTS && (
                        <ChiefComplaintQuestionsAnswers />
                    )}
                </>
            )}

            {chats[currentChatIndex]?.responseType === CONSTANT.ELEMENT_TYPES.AUTOCOMPLETE && (
                <AutoCompleteQuestionsAnswers />
            )}

            {chats[currentChatIndex]?.responseType === CONSTANT.ELEMENT_TYPES.DROPDOWN && (
                <DropdownQuestionsAnswers />
            )}

            {chats[currentChatIndex]?.responseType === CONSTANT.ELEMENT_TYPES.TEXT && (
                <TextQuestionsAnswers />
            )}

            {chats[currentChatIndex]?.responseType === CONSTANT.ELEMENT_TYPES.DATETIME_PICKER && (
                <DatetimePickerQuestionsAnswers />
            )}

            {chats[currentChatIndex]?.responseType === CONSTANT.ELEMENT_TYPES.DATE_PICKER && (
                <DatePickerQuestionsAnswers />
            )}
        </div>
    )
}

export default CurrentQuestionResponse;