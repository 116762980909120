import DatePicker from "react-datepicker";
import React from "react";
import ChatRequestDetail from "../../../../models/ChatRequestDetail";

import {format} from "date-fns";
import {useSelector} from "react-redux";
import useChatbot from "../hooks/useChatbot";

const DatetimePickerQuestionsAnswers = () => {
    const { prepareChatbotAnswer, onChangeUpdateChatsState } = useChatbot();
    const chatBotState = useSelector(state => state.chatBot);
    const botData = chatBotState?.botData || {};
    const chats = botData?.chats || [];
    const currentChatIndex = botData?.currentChatIndex ?? -1;

    const chat = chats[currentChatIndex];

    const onChangeDatetimePicker = (date) => {
        let chatResponse = new ChatRequestDetail({ name: new Date(date) })
        chat.answers = [{
            ...chatResponse
        }];
        onChangeUpdateChatsState(chat);
    };

    const onClickDatetimePickerAnswer = () => {
        prepareChatbotAnswer(chat);
    };

    return (
        <>
            {chat && !chat.answered && (
                <>
                    <div className="responses">
                        <div className="form-group mmh-date-picker">
                            <DatePicker
                                id="date_picker_id"
                                name={`datetime_picker_${chat.id}`}
                                closeOnScroll={true}
                                fixedHeight={true}
                                showTimeSelect={true}
                                inline
                                timeFormat="h:mm aa"
                                dateFormat="MM/dd/YYYY h:mm aa"
                                className="form-control"
                                selected={chat.answers[0]?.name ? new Date(chat.answers[0]?.name) : new Date()}
                                value={chat.answers[0]?.name ? new Date(chat.answers[0]?.name) : new Date()}
                                onChange={(date) => onChangeDatetimePicker(date)}
                            />
                        </div>
                        <button type="button" className="btn btn-primary btn-lg"
                                onClick={onClickDatetimePickerAnswer}>
                            OK
                        </button>
                    </div>
                </>
            )}
            {chat && chat.answered && (
                <div className="answers">
                    {chat.answers && chat.answers.length > 0 && (
                        chat.answers.map((chatAnswer, indexR) => {
                            return (
                                <span className="kt-font-brand" key={indexR}>
                                    {format(new Date(), 'dd/MM/yyyy hh:mm a')}
                                </span>
                            )
                        })
                    )}
                </div>
            )}
        </>
    )
};

export default DatetimePickerQuestionsAnswers;