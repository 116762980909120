import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import painLogService from "../../../services/PainLogService";
import GLOBAL from "../../../global";
import Appointment from "../../../models/Appointment";
import DatePicker from "react-datepicker";
import { dateInPast} from "../../../utils/Utils";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from 'react-toastify';


const AppointmentPopup = (props) => {
    const [showPopup, setShowPopup] = useState(props.show);
    const [allDoctors, setAllDoctors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentSelectedDoctor, setCurrentSelectedDoctor] = useState(0);
    const [connectPhoneStatus, setConnectPhoneStatus] = useState(false);
    const [connectEmailStatus, setConnectEmailStatus] = useState(false);
    const [isClickedSubmit, setIsClickedSubmit] = useState(false);
    const [eventDate, setEventDate] = useState(new Date());
    const [eventEndTime, setEventEndTime] = useState(new Date().getTime());
    const [eventDateErrorMessage, setEventDateErrorMessage] = useState('');
    const [eventTimeErrorMessage, setEventTimeErrorMessage] = useState('');
    const [eventEndTimeErrorMessage, setEventEndTimeErrorMessage] = useState('');
    const [allChecked, setAllChecked] = useState(false);
    const [sweetAlertMessage, setSweetAlertMessage] = useState("");
    const [isPhoneExist, setIsPhoneExist] = useState(true);

    useEffect(() => {
        async function load(){
            await fetchDoctorData();
            setEventEndTime(new Date().getTime() + (2*1000*3600));
        }
        load();
    }, [])


    const fetchDoctorData = async () => {
        const response = await painLogService.findDoctors({ });
        const { data, success, error } = response;

        if (!success) {
            console.error(error);
            return;
        }
        setLoading(false);
        const parsedData = data["Data"] || [];
        setAllDoctors(parsedData)

        let primaryDoctorIndex = 0;
        for(let i=0;i<parsedData.length;i++) {
            if(parsedData[i].IsPrimary) {
                primaryDoctorIndex = i;
                break;
            }
        }
        if(parsedData && parsedData.length > 0 && parsedData[primaryDoctorIndex] && !parsedData[primaryDoctorIndex].Phone){
            setIsPhoneExist(false);
            letsConnectWithEmail();
        }
        setCurrentSelectedDoctor(primaryDoctorIndex);
    };

    const handleClose = (e) => {
        e.preventDefault();
        setShowPopup(false);
        props.func(false);
    };

    const letsConnectWithPhone = () => {
        setConnectPhoneStatus(true);
        setConnectEmailStatus(false);
        setSweetAlertMessage("Your Provider has no Phone number, please select Email")
    };

    const letsConnectWithEmail = () => {
        setConnectEmailStatus(true);
        setConnectPhoneStatus(false);
        setSweetAlertMessage("")
    };

    const _preparePayload = () => {
        const endTime = new Date(eventDate.valueOf()) ;
        if(new Date(eventEndTime).getHours() < new Date(eventDate).getHours()) {
            endTime.setDate(endTime.getDate() + 1);
        }
        endTime.setHours(endTime.getHours() + (new Date(eventEndTime).getHours() - new Date(endTime).getHours()))
        endTime.setMinutes(Math.abs(endTime.getMinutes() + (new Date(eventEndTime).getMinutes() - new Date(endTime).getMinutes())))
        const appointment = new Appointment();
        // appointment.patient_id = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['ContactId']) || '';
        appointment.text = "Hi";
        appointment.owner = allDoctors[currentSelectedDoctor].ContactId || '';
        appointment.provider_id = allDoctors[currentSelectedDoctor].ContactId || '';
        appointment.start_date = eventDate.toLocaleString();
        appointment.end_date = new Date(endTime).toLocaleString();
        appointment.current_user = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['ContactId']) || '';
        return appointment;
    }

    const handleSubmit = async (e) => {
        setIsClickedSubmit(true);
        if ((connectEmailStatus === true || (connectPhoneStatus === true && allDoctors[currentSelectedDoctor].Phone))  && timeValidation && dateHandler) {
            setAllChecked(true);
            const payload = _preparePayload();
            const response = await painLogService.sendEmail(payload);
            let { success, error } = response;
            if (!success) {
                console.error(error);
                toast.error(<div>{error.Message}</div>)
                setAllChecked(false);
                return;
            }
            props.submitFunc(true);
            handleClose(e);
        }

    };

    const selectDoctorHandler = (e) => {
        setCurrentSelectedDoctor(e.target.value)
        setIsClickedSubmit(false)
    }

    const timeValidation = (e) => {
        if (new Date().getTime() >= e.getTime()) {
            setEventDate(e)
            setEventEndTime(new Date(e).getTime() + (2*1000*3600));
            setEventTimeErrorMessage('Please, select a valid start time')
            return false
        }
        else {
            setEventDate(e)
            setEventEndTime(new Date(e).getTime() + (2*1000*3600));
            setEventTimeErrorMessage('')
            setEventEndTimeErrorMessage('')
            return true
        }
    }

    const dateHandler = (e) => {
        setEventDate(e)
        if (dateInPast(e.toLocaleDateString())) {
            setEventDateErrorMessage('Please, select a valid date')
            return false
        }
        else {
            timeValidation(e)
            setEventDateErrorMessage('')
            return true
        }
    }

    const endTimeHandler = (e) => {
        setEventEndTime(e);
        if (eventDate.getTime() >= e.getTime()) {
            setEventEndTimeErrorMessage('Please, select a valid end time')
            return false;
        }
        else {
            setEventEndTimeErrorMessage('')
            return true;
        }
    }

    const closeSweetAlert = () => {
        setConnectPhoneStatus(false)
        setConnectPhoneStatus(false)
        setSweetAlertMessage("")
    }
    return (
        <>
            <Modal
                isOpen={showPopup}
                className="modal-main d-flex justify-content-center align-items-center w-100"
                toggle={handleClose}>
                <ModalHeader toggle={handleClose}>Request an Appointment</ModalHeader>
                <ModalBody>
                    {loading &&
                        <div className="text-center"><span>Please wait...<i className="fa fa-spinner fa-spin kt-font-primary"/></span></div>
                    }
                    {!loading && (
                        <div>
                            <div className="row" style={{marginBottom: "0px !important"}}>
                                <label className="col-md-12" style={{color: "black"}}>Select Your Provider</label>
                                <div className="col-md-12">
                                    <select className="form-control" onChange={selectDoctorHandler} value={currentSelectedDoctor}>
                                        {allDoctors.map((doctor, index) => <option key={doctor.Email} value={index} >
                                            {doctor.PhysicianType? doctor.FullName + " (" + doctor.PhysicianType + ")" : doctor.FullName} </option>)}
                                    </select>
                                </div>
                            </div>

                            <div className="row" style={{marginBottom: "0px !important"}}>
                                
                                {allDoctors && allDoctors.length > 0 && allDoctors[currentSelectedDoctor]?.Phone && (
                                    <>
                                        <label className="col-md-12" style={{color: "black"}}>How would you like to contact?</label>
                                        <div className="col-md-12">
                                            <label className="kt-radio">
                                                <input type="radio" name="radio2" onChange={letsConnectWithPhone} checked={connectPhoneStatus} /> Phone
                                                    <span></span>
                                            </label>
                                            <label className="kt-radio">
                                                <input type="radio" name="radio2" onChange={letsConnectWithEmail} checked={connectEmailStatus} /> Email
                                                    <span></span>
                                            </label>
                                        </div>
                                    </>
                                )}
                                {
                                    allDoctors && allDoctors.length > 0 && !allDoctors[currentSelectedDoctor]?.Phone && (
                                        <>
                                            <div className="col-md-12" style={{color: "black"}}>Provider E-mail</div>
                                            <div className="col-md-12" style={{color: "black"}}><i className="kt-menu__link-icon kt-font-xl kt-mr-10 fas fa-envelope-open"></i><strong>{allDoctors[currentSelectedDoctor].Email}</strong></div>
                                        </>
                                    )
                                }
                                <span className={isClickedSubmit && !connectPhoneStatus && !connectEmailStatus ? "invalid-feedback d-block" : "invalid-feedback"}>
                                    Please, select one to continue.</span>
                                {isPhoneExist && connectEmailStatus && <label className="kt-mt-10"><i className="kt-menu__link-icon kt-font-xl kt-mr-10 fas fa-envelope-open"></i><strong>{allDoctors[currentSelectedDoctor].Email}</strong></label> }
                                {connectPhoneStatus && <label className="kt-mt-10"><i className="kt-menu__link-icon kt-font-xl kt-mr-10 fas fa-phone-volume"></i><strong>{allDoctors[currentSelectedDoctor].Phone}</strong></label> }
                            </div>

                            <div className="row" style={{marginBottom: "0px !important"}}>
                                <label className="col-md-12" style={{color: "black"}}>Appointment Date</label>
                                <div className="col-md-12 mmh-date-picker">
                                    <DatePicker
                                        fixedHeight
                                        dateFormat="MM/dd/yyyy"
                                        className="form-control"
                                        selected={eventDate}
                                        onChange={(e) => dateHandler(e)}
                                    />
                                    <div className="invalid-feedback d-block">{eventDateErrorMessage}</div>
                                </div>
                            </div>

                            <div className="row" style={{marginBottom: "0px !important"}}>
                                <div className="col-md-6">
                                    <label className="" style={{color: "black"}}>Start Time</label>
                                    <div className="mmh-date-picker">
                                        <DatePicker
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="h:mm a"
                                            className="form-control"
                                            selected={eventDate}
                                            onChange={(e) => timeValidation(e)}
                                        />
                                        {(eventTimeErrorMessage !== '') && <div className="invalid-feedback d-block">{eventTimeErrorMessage}</div>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="" style={{color: "black"}}>End Time</label>
                                    <div className="mmh-date-picker">
                                        <DatePicker
                                            style={{backgroundColor: "#f5f8fa"}}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="h:mm a"
                                            className="form-control"
                                            selected={eventEndTime}
                                            onChange={(e) => endTimeHandler(e)}
                                        />
                                        {(eventEndTimeErrorMessage !== '') && <div className="invalid-feedback d-block">{eventEndTimeErrorMessage}</div>}
                                    </div>
                                </div>
                            </div>
                            { allChecked ?
                                <div className="mt-4"><span className="spinner-grow spinner-grow-sm text-success" role="status" aria-hidden="true"></span>
                                    <span className="text-success"><strong>Please wait...</strong></span></div> : null}
                        </div>
                    )}
                </ModalBody>
                {!loading && (<div className="modal-footer justify-content-center">
                    <button type="button" onClick={handleSubmit} className="btn btn-success text-center" disabled={allChecked}>Submit Request</button>
                </div>)}
            </Modal>

            <SweetAlert
                show={false}
                confirmBtnCssClass="btn btn-sm btn-danger"
                confirmBtnText="OK"
                title={`${sweetAlertMessage}`} warning
                onConfirm={closeSweetAlert}
                closeOnClickOutside={false}>
            </SweetAlert>
        </>
    );
};

export default AppointmentPopup;



