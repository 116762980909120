import React from "react";
import {getRetakeButtonDisableClass} from "../../../../src/utils/Movement";
import CONSTANT from "../../../constants/Constant";
import { UncontrolledTooltip } from "reactstrap";

const StartOrRetakeBtn = ({onClickStartOrRetakeExercise, exercise}) => {

    return (
        <>
        {exercise && exercise !== {} && (
            <>
                <a rel="noopener noreferrer"
                    href={"!#"}
                    id={`retakebutton-${exercise?.ExerciseCodeName}`}
                    onClick={(e) => {
                        e.preventDefault();
                        onClickStartOrRetakeExercise && onClickStartOrRetakeExercise(exercise);
                    }}
                    className={`btn btn-sm btn-icon btn-icon-md kt-ml-5 ${getRetakeButtonDisableClass(exercise)}`}>
                    {exercise.Status === CONSTANT.EXERCISE_STATUS.NOT_PERFORMED.name ? 
                    (<>
                        <i className="fa fa-play"></i>
                    </>) : 
                    (<>
                        <i className="fas fa fa-redo"/>
                    </>)}
                </a>
                {exercise['StatusReason'] && exercise['StatusReason'] !== '' && (
                    <UncontrolledTooltip
                        placement="top"
                        target={`retakebutton-${exercise?.ExerciseCodeName}`}>
                        <span className="color-primary-dark-1">{exercise['StatusReason']}</span>
                    </UncontrolledTooltip>
                )}
            </>
        )}
        </>
    );
}

export default StartOrRetakeBtn;