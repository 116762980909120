import React from "react";
import Mike from "../../../assets/img/emma-voice/mike.png";
import OnMike from "../../../assets/img/emma-voice/onMike.png";

const VoiceOnOffBtn = ({isAudioPlayEnabled, onChangeAudioPlay}) => {
    const toggleBtn = (e) => {
        e.preventDefault();
        onChangeAudioPlay();
    }
    return(
        <div className="voice-box">
            <button className="voice-box__toggle-btn" onClick={(e) => toggleBtn(e)}>
                {isAudioPlayEnabled && (
                    <>
                        <div className="voice-box__on-off-text">ON</div>
                        <div className="mike-design">
                            <img src={OnMike} alt='on' className="on-btn"/>
                        </div>
                    </>
                )}
                {!isAudioPlayEnabled && (
                    <>
                        <div className="mike-design">
                            <img src={Mike} alt='off' className="off-btn"/>
                        </div>
                        <div className="voice-box__on-off-text">OFF</div>
                    </>
                )}
            </button>
        </div>
    )
}

export  default VoiceOnOffBtn;