import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";


const VideoModal = (props) => {
    const { visible, url, closeModal, headerTitle } = props
    return (
        <Modal
            isOpen={visible}
            backdrop={'static'}
            className="modal-lg">
            <ModalHeader toggle={closeModal}>
                {headerTitle || 'Video'} &nbsp;&nbsp;
            </ModalHeader>
            <ModalBody className='d-flex justify-content-center'>
                {url && (
                    <iframe title='Video tutorial' width="100%" height="500"
                            src={url}>
                    </iframe>
                )}
                {!url && (
                    <div className='align-self-center font-size-20 kt-mt-50 kt-mb-50'>No tutorial available</div>
                )}
            </ModalBody>
        </Modal>
    )
}

export default VideoModal;
