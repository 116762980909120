import React from "react";

const StepBar = (props) => {
    let { groups, isShowFullName = true, showName= true } = props

    return(
        <ol className={`steps`}>
            {groups.length > 0 && groups.map(group =>
                <li
                    key={group.key}
                    className={`step ${group?.groupCompleted ? "is-complete" : ""}`}
                    data-step={group?.shortName}
                >
                    {showName ? `${isShowFullName ? group?.name: group?.midName}` : ""}
                </li>)}
        </ol>
    )
}

export default StepBar;