import GLOBAL from "../global";
import CONSTANT from "../constants/Constant";

import { isMobile } from "react-device-detect";
import { getElementHeight } from "./DomUtil";

import forwardHead from "../assets/img/exercises/exerciseIcon-base64/forwardHead";
import kneeSquat from "../assets/img/exercises/exerciseIcon-base64/kneeSquat";
import makeMuscle from "../assets/img/exercises/exerciseIcon-base64/makeMuscle";
import reachArmsOverHead from "../assets/img/exercises/exerciseIcon-base64/reachArmsOverHead";
import commonExerciseIcon from "../assets/img/exercises/exerciseIcon-base64/common-exercise";

let shoulderToShoulderDistance = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['ShoulderToShoulderDistance']);

export const getExerciseStatusIcon = (exerciseStatus) => {
    if (exerciseStatus === CONSTANT.EXERCISE_STATUS.NOT_PERFORMED.name || exerciseStatus === CONSTANT.EXERCISE_STATUS.SKIPPED.key) {
        return 'fas fa-play';
    } else if (exerciseStatus === CONSTANT.EXERCISE_STATUS.PERFORMING.key) {
        return 'flaticon-more-1 kt-font-primary';
    } else if (exerciseStatus === CONSTANT.EXERCISE_STATUS.COMPLETED.key) {
        return 'flaticon2-check-mark kt-font-success';
    } else if (exerciseStatus === CONSTANT.EXERCISE_STATUS.PROCESSING.key) {
        return 'fa fa-spinner fa-spin kt-font-warning';
    } else if (exerciseStatus === CONSTANT.EXERCISE_STATUS.AVAILABLE.key) {
        return 'fa fa-circle-notch kt-font-info';
    } else if (exerciseStatus === CONSTANT.EXERCISE_STATUS.RETAKE.key) {
        return 'fa fa-times kt-font-danger';
    } else if (exerciseStatus === CONSTANT.EXERCISE_STATUS.RETAKE_HUMAN_EXPERT.key) {
        return 'flaticon2-check-mark kt-font-success';
    } else if (exerciseStatus === CONSTANT.EXERCISE_STATUS.WAITING.key) {
        return 'fa fa-hourglass-half kt-font-primary';
    } else if (shoulderToShoulderDistance) {
        return 'flaticon2-check-mark kt-font-success';
    }
};

export const getExerciseStatusStyle = (exerciseStatus) => {
    if (exerciseStatus === CONSTANT.EXERCISE_STATUS.NOT_PERFORMED.name) {
        return 'kt-font-danger';
    } else if (exerciseStatus === CONSTANT.EXERCISE_STATUS.PERFORMING.key) {
        return 'kt-font-primary';
    } else if (exerciseStatus === CONSTANT.EXERCISE_STATUS.COMPLETED.key) {
        return 'kt-font-success';
    } else if (exerciseStatus === CONSTANT.EXERCISE_STATUS.PROCESSING.key) {
        return 'kt-font-warning';
    } else if (exerciseStatus === CONSTANT.EXERCISE_STATUS.RETAKE_HUMAN_EXPERT.key) {
        return 'kt-font-success';
    } else {
        return 'kt-font-info';
    }
};

export const _getStatusName = (status, IsSkipExercise = false) => {
    if (status === CONSTANT.EXERCISE_STATUS.RETAKE_HUMAN_EXPERT.key) {
        return CONSTANT.EXERCISE_STATUS.RETAKE_HUMAN_EXPERT.name;
    } else if(status === "" && IsSkipExercise) {
        return CONSTANT.EXERCISE_STATUS.SKIPPED.name;
    } else {
        return status
    }
};

export const getExerciseIcon = (exercise) => {
    if (exercise === CONSTANT.EXERCISES_NAME.postureAnalysis) {
        return forwardHead;

    } else if (exercise === CONSTANT.EXERCISES_NAME.kneeSquat) {
        return kneeSquat;

    } else if (exercise === CONSTANT.EXERCISES_NAME.makeMuscle) {
        return makeMuscle;

    } else if (exercise === CONSTANT.EXERCISES_NAME.reachArmsOverHead) {
        return reachArmsOverHead;

    } else {
        return commonExerciseIcon;
    }
};

export const getExerciseName = (exercises, exerciseName) => {
    let tempExerciseName = '';

    if (exercises && exercises.length > 0) {
        const exercise = exercises.find(exercise => exercise['Value'] === exerciseName);
        if (exercise) {
            tempExerciseName = exercise.Name;
        }
    }

    return tempExerciseName;
};

export const _actualHeightForExerciseList = () => {
    const bodyElementHeight = getElementHeight('root');
    let actualHeight = +bodyElementHeight - 300;

    if (isMobile) {
        actualHeight = actualHeight + 130;
    } else {
        actualHeight = actualHeight < 250 ? 250 : actualHeight;
    }
    return actualHeight;
};

export const getScrollStyleForExercise = (value) => {
    let actualHeight = _actualHeightForExerciseList();

    if (value === CONSTANT.TAB.MOVEMENT_GUIDELINE) {
        actualHeight = actualHeight + 83;
    }

    return { maxHeight: actualHeight + "px", Height: "auto" };
};

export const onExecuteScroll = (exercise, exercises, psExerciseRef) => {
    try {
        const index = exercises.findIndex(item => item.Value === exercise.Value);

        if (index > 4) {
            setTimeout(() => {
                if (psExerciseRef) {
                    psExerciseRef.scrollTop = psExerciseRef.scrollTop + 50;
                }
            }, 500);
        }
    } catch (error) {
        console.error(error);
    }
};

export const getRetakeButtonDisableClass = (exercise) => {
    // if (exercise.Status === CONSTANT.EXERCISE_STATUS.NOT_PERFORMED.name) {
    //     return 'btn-link disabled kt-hidden';

    // }
    if (exercise.Status === CONSTANT.EXERCISE_STATUS.PERFORMING.name || exercise.Status === CONSTANT.EXERCISE_STATUS.WAITING.key || exercise.Status === CONSTANT.EXERCISE_STATUS.PROCESSING.key) {
        return 'btn-link disabled';

    } else if (exercise.Status === CONSTANT.EXERCISE_STATUS.RETAKE.name) {
        return 'btn-link disabled';

    } else {
        return 'btn-link';
    }
};

export const getPerformingExerciseTextDesign = (exercise) => {
    if (exercise.Status === CONSTANT.EXERCISE_STATUS.PERFORMING.name) {
        return 'btn-link disabled';
    }
}
