export const getSweetAlertModalObj = (
    showCancelBtn = false, 
    showConfirmBtn = false, 
    alert = 'default', 
    title = '', 
    confirmBtnText = 'Yes', 
    cancelBtnText = 'No',
    confirmBtnCss = '',
    cancelBtnCss = '',
    alertMessage = '',
    closeOutsideClick = false,
    onConfirmFun=()=>{},
    onCancelFun=()=>{},
    textWhileProcessingConfirmBtn= ''
    ) => {
        return {
            showNotificationCancelBtn: showCancelBtn,
            showNotificationConfirmBtn: showConfirmBtn,
            notificationAlert: alert,
            notificationTitle: title,
            notificationConfirmBtnText: confirmBtnText,
            notificationCancelBtnText: cancelBtnText,
            notificationConfirmBtnCss: confirmBtnCss,
            notificationCancelBtnCss: cancelBtnCss,
            closeNotificationOutsideClick: closeOutsideClick,
            notificationAlertMessage: alertMessage,
            onCancelNotificationAlert: onCancelFun,
            onConfirmNotificationAlert: onConfirmFun,
            textWhileProcessingConfirmBtn: textWhileProcessingConfirmBtn
        }
}

export const getSweetAlertModalConfig = ({
     showCancelBtn = false,
     showConfirmBtn = false,
     alertType = 'default',
     title = '',
     confirmBtnText = 'Yes',
     cancelBtnText = 'No',
     confirmBtnCss = '',
     cancelBtnCss = '',
     message = '',
     closeOnOutsideClick = false,
     onConfirm = () => {},
     onCancel = () => {},
     processingConfirmText = ''
}) => ({
    showNotificationCancelBtn: showCancelBtn,
    showNotificationConfirmBtn: showConfirmBtn,
    notificationAlert: alertType,
    notificationTitle: title,
    notificationConfirmBtnText: confirmBtnText,
    notificationCancelBtnText: cancelBtnText,
    notificationConfirmBtnCss: confirmBtnCss,
    notificationCancelBtnCss: cancelBtnCss,
    closeNotificationOutsideClick: closeOnOutsideClick,
    notificationAlertMessage: message,
    onCancelNotificationAlert: onCancel,
    onConfirmNotificationAlert: onConfirm,
    textWhileProcessingConfirmBtn: processingConfirmText
});

export const getConfirmationDialogModalObj = ({
  headerText,
  show,
  toggleDialog,
  children,
  onClickHeaderClose,
  confirmText,
  cancelText,
  confirmBtnCssClass,
  cancelBtnCssClass,
  isYesInLeft = false,
  isSaving = false,
  showLoadingInConform = false }) => ({
    title: 'Confirm action',
    message: 'Are you sure you want to proceed?',
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel',
})