import React from "react";
import { Modal } from "reactstrap";

import MovementCore from "./MovementCore";

const MovementModal = (props) => {
    const { open, selectedRegion, testId, onClose, clearAndCloseChatbot } = props;

    return (
        <>
            <Modal isOpen={open} className="modal-xl h-100 kt-m0"
                   modalClassName="mmh-movement-modal">
                <MovementCore
                    bodyRegionList={selectedRegion}
                    testId={testId}
                    onClose={onClose}
                    clearAndCloseChatbot={clearAndCloseChatbot}
                />
            </Modal>
        </>

    )
};

export default MovementModal;