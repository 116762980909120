import CONSTANT from "../../../../constants/Constant";
import ChatRequestDetail from "../../../../models/ChatRequestDetail";
import {
    setChatBotErrorMsg,
    setSelectedResponseOfCautionQuestion,
    setShowPopUpWarningForYesAtCautionQuestion
} from "../../../../features/chat/chatbotSlice";
import { useDispatch, useSelector } from "react-redux";

import { deepClone, isEmptyObject } from "../../../../utils/Utils";
import useChatbot from "./useChatbot";
import { setChildQuestionIdsToRemoveAndParentAndChildQuestionIds, toggleCheckboxChatResponse } from "../ChatServices";
import answerValidationService from "../../../../services/answerValidation/AnswerValidationService";
import { toast } from "react-toastify";

const useCurrentQuestionResponse = () => {
    const { prepareChatbotAnswer, onChangeUpdateChatsState } = useChatbot();
    const dispatch = useDispatch();

    const chatState = useSelector(state => state.chatBot);
    const chats = chatState?.botData?.chats || [];

    const onClickButtonAnswer = async (chat, chatResponse) => {
        if(isEmptyObject(chat) || isEmptyObject(chatResponse)) return;
        let deepClonedChat = deepClone(chat);
        let deepClonedChatResponse = deepClone(chatResponse);
        const questionId = deepClonedChat.questionId || 0;
        const answer = chatResponse?.id; // for label response we are sending {id: 0} as answer that's why we are not using chatResponse?.id || '';
        const responseType = deepClonedChat.responseType || '';

        let validation = answerValidationService.validate(questionId, responseType, answer);

        if(!validation?.valid) {
            dispatch(setChatBotErrorMsg(validation?.message || ''));
            return;
        }

        if (deepClonedChat.intent === CONSTANT.CHATBOT_INTENT.COUNTRY_INDICATION //Means caution question
            && deepClonedChatResponse['referenceId'] && deepClonedChatResponse['referenceId'] === CONSTANT.REFERENCE_RESPONSE.YES) {
            dispatch(setSelectedResponseOfCautionQuestion(deepClonedChatResponse));
            dispatch(setShowPopUpWarningForYesAtCautionQuestion(true));
            return;
        }

        deepClonedChat.answers = [{
            ...new ChatRequestDetail(deepClonedChatResponse)
        }];
        const removeChildQuestionIds = setChildQuestionIdsToRemoveAndParentAndChildQuestionIds(deepClone(chats), deepClonedChat, deepClonedChatResponse);
        await prepareChatbotAnswer(deepClonedChat, removeChildQuestionIds);
    };

    const onClickCheckbox = async (chat, chatResponse) => {
        if(chat.answered) return;

        let deepClonedChat = deepClone(chat);
        let deepClonedChatResponse = deepClone(chatResponse);
        const updatedChat = toggleCheckboxChatResponse(deepClonedChat, deepClonedChatResponse);
        await onChangeUpdateChatsState(updatedChat);
    };

    const onClickCheckboxAnswer = (chat) => {
        let deepClonedChat = deepClone(chat);
        let answersForValidation = deepClonedChat.answers.map(answer => {
            return {
                id: answer?.id || 0,
                value: answer?.name || ''
            }
        });

        const questionId = deepClonedChat.questionId || 0;
        const answer = answersForValidation || '';
        const responseType = deepClonedChat.responseType || '';
        let validation = answerValidationService.validate(questionId, responseType, answer);

        if(!validation?.valid) {
            dispatch(setChatBotErrorMsg(validation?.message || ''));
        }
        prepareChatbotAnswer(deepClonedChat);
    };

    const getAnswerListForCheckboxQuestion = (chat) => {
        let answerList = [];
        if(chat?.responseType !== CONSTANT.ELEMENT_TYPES.CHECKBOX) {
            toast.error('Question type have to be checkbox');
            return answerList;
        }

        let answers = chat?.answers || [];
        answerList = answers.map(answer => {
            return answer?.id || 0;
        });

        return answerList;
    }

    return {
        onClickButtonAnswer,
        onClickCheckbox,
        onClickCheckboxAnswer,
        getAnswerListForCheckboxQuestion,
    }
}

export default useCurrentQuestionResponse;