import GLOBAL from "../../../../global";
import Assessment from "../../../../models/Assessment";
import {
    ASSESSMENT_SAVE_URL,
    UPDATE_USER_URL,
    TELEMED_SESSION_KEY_URL,
    EXERCISE_GUIDELINE_ALL,
} from './UrlManager';
import axios from '../../../../../src/services/Axios';
import ExamService from "../../../../services/ExamService";
import { error, success } from "../../../../services/ResponseHandler";

const RESPONSE_TYPE = { responseType: 'text' };

const getNewAssessment = (providerEmail,testId,bodyRegionList) => {
    let assessment = new Assessment();
    assessment.ProviderEmail = providerEmail;
    assessment.TestId = (GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['TestId']) || testId;
    assessment.BodyRegionList = bodyRegionList || [];
    assessment.InvitationId = GLOBAL.URL_PARAMS.INVITATION_ID;
    assessment.DemographicsCompleted = (GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['DemographicsCompleted']) || false;
    assessment.ADLCompleted = (GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['ADLCompleted']) || false;
    assessment.MedicalHistoryCompleted = (GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['MedicalHistoryCompleted']) || false;
    assessment.ChiefComplaintsCompleted = (GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['ChiefComplaintsCompleted']) || false;
    assessment.Results = [];
    assessment.Answers = [];

    return assessment;
};

const saveAssessments = async (payload) => {
    try {
        if(payload.InvitationId === "") { payload.InvitationId = null };
        const response = await axios.post(ASSESSMENT_SAVE_URL, payload, RESPONSE_TYPE);

        return success(response)?.success;
    } catch (e) {
        error(e);
        return false;
    }
}

export const savePainQuestions = async (painQuestionList, providerEmail, testId, bodyRegionList) => {
    if(painQuestionList && painQuestionList.length > 0){
        let assessment = getNewAssessment(providerEmail,testId,bodyRegionList);

        for(let painQuestion of painQuestionList){
            let answer = {};
            answer.AnswerId = painQuestion.AnswerId;
            answer.QuestionId = painQuestion.QuestionId;
            answer.AnswerList = painQuestion.AnswerList;

            assessment.Answers.push(answer);
        }
        let response = await saveAssessments(assessment);
        return response;
    }
    return true;
}


export const updateUserData = async (userPayload) => {
    if(userPayload && userPayload !== {}){
        try {
            const response = await axios.post(UPDATE_USER_URL, userPayload, RESPONSE_TYPE);
        } catch (e) {
            console.error("Exception issue in patient update");
            error(e);
        }
    }
    return;
}

export const skipMovement = async (exercsieList, exercise,providerEmail,testId) => {
    if(exercise && exercsieList && exercsieList.length > 0){

        let payload = {
            TestId: testId,
            ExerciseValue: exercise?.ExerciseCodeName,
            IsUnableToPerformedExercise: true
        };
        let response = await ExamService.skipAMovement(payload);
        return response;
    }
    return true;
}

export const getTelemedSessionId = async (invitationId) => {

    const payload = {
        InvitationId: invitationId
    };

    try {
        const response = await axios.post(TELEMED_SESSION_KEY_URL, payload, RESPONSE_TYPE);

        if(response){
            if(response && response?.StatusMessage === 'Success'){
                return success(response);
            }
        }
        else {
            return {
                success: false,
                error: "No response found"
            }
        }
    } catch (e) {
        error(e);
        return {
            success: false,
            error: "Request failed"
        };
    }
}

export const getAllExerciseGuideline = async () => {
    const payload = {

    };

    try {
        const response = await axios.post(EXERCISE_GUIDELINE_ALL, payload, RESPONSE_TYPE);
        return success(response);
    } catch (e) {
        return error(e);
    }
}
