import axios from './Axios';
import { error, success } from "./ResponseHandler";
import GLOBAL from "../global";
import CONSTANT from "../constants/Constant";

const { REACT_APP_VA_API_URL } = process.env;
const GET_NOTIFICATION_INFORMATION = REACT_APP_VA_API_URL +  `/api/${CONSTANT.CONTROLLER.ROM_EXERCISE_API}/GetNotificationInformation`;

class NotificationService {

    async getNotification(payload = {}) {
        try {
            payload.Tenant = GLOBAL.TENANT;
            const response = await axios.post(GET_NOTIFICATION_INFORMATION, payload);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }
}

export default new NotificationService()