import './layout.scss';

import React, { useContext, useState } from 'react';
import { Link } from "react-router-dom";
import routes from "../../../constants/Routes";
import AppointmentPopup from './AppointmentPopup';
import AppointmentConfirmPopup from './AppointmentConfirmPopup';
import { useLocation } from 'react-router-dom'

import myCalender from "../../../assets/img/sidebar/my-calender.svg";
import myReport from "../../../assets/img/sidebar/my-report.svg";
// import myStatistics from "../../../assets/img/sidebar/my-statistics.svg";
import myCards from "../../../assets/img/sidebar/my-cards.svg";
// import myDocuments from "../../../assets/img/sidebar/my-documents.svg";
// import myTelemedInvitation from "../../../assets/img/sidebar/my-telemed-invitation.svg";
// import myIntakeForm from "../../../assets/img/sidebar/my-intake-form.svg";
import myAssessment from "../../../assets/img/sidebar/my-assessment.svg";
import myExercises from "../../../assets/img/sidebar/my-exercises.svg";
// import myPainlog from "../../../assets/img/sidebar/my-painlog.svg";
// import myInsights from "../../../assets/img/sidebar/my-insights.svg";
// import crossBtn from "../../../assets/img/utilities/cross-btn.svg";

import AppContext from "../../../contexts/AppContext";
import GLOBAL from "../../../global";

const TopSideMenu = (props) => {
    // noinspection CheckTagEmptyBody
    const location = useLocation();
    const [show, setShow] = useState(false);
    let [submitStatus , setSubmitStatus] = useState(false);
    const { toggleShowTakeAssessmentModal, toggleShowLeftSideMenuInMobile, userInfo, showTopNavigationInMobile } = useContext(AppContext);
    const { CanPatientCreateAssessment, IsPatientReportAvailable } = userInfo.Permissions;

    const handleShow = (e) => {
        e.preventDefault();
        setShow(true);
    };

    const pull_data = (data) => {
        setShow(data);
    };

    const pull_data_submitStatus = (submit) =>{
        setSubmitStatus(submit);
    };

    const submitCloseFunc = (submit) =>{
        setSubmitStatus(submit);
    };

    const closeSideMenu = (e) => {
        e.preventDefault();

        if(window.innerWidth <= 1024) {
            toggleShowLeftSideMenuInMobile();
        }
    }

    return (
        <>
            {
                submitStatus && <AppointmentConfirmPopup submitCloseFunc={submitCloseFunc} submitStatus={submitStatus}/>
            }
            {show === true && <AppointmentPopup func={pull_data} show={show} submitFunc={pull_data_submitStatus}/>}


            {/*<div className="kt-aside-menu kt-mt-0 kt-padding-l-15">*/}
            <div className={`header-top-menu ${showTopNavigationInMobile && 'd-block'}`}>
                {/*Will be hidden for desktop and will show at mobile to tab*/}
                {/*<div className="side-menu-mobile-header">*/}
                {/*    <div className="d-flex justify-content-between">*/}
                {/*        <div className="kt-header__brand-logo">*/}
                {/*            <img className="kt-header__brand-logo" src={GLOBAL.WHITE_LOGO_URL} height="50" alt="MMH Logo"/>*/}
                {/*        </div>*/}
                {/*        <img src={crossBtn} alt="cross_btn" onClick={closeSideMenu}/>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="side-menu-wrapper flex-wrap" onClick={closeSideMenu}>

                    <Link to={`${routes.dashboard}`} className="kt-menu__link kt-menu__toggle">
                        <div className={`kt-menu__item  kt-menu__item--submenu header-menu-container ${location.pathname.includes(`${routes.dashboard}`) && "bg-color-secondary-light"}`} aria-haspopup="true">
                            <img className="kt-mr-15" src={myAssessment} alt="Dashboard" />
                            <span>Dashboard</span>
                        </div>
                    </Link>

                    <Link to={`${routes.myAssessments}/${false}`} className="kt-menu__link kt-menu__toggle">
                        <div className={`kt-menu__item  kt-menu__item--submenu header-menu-container ${location.pathname.includes(`${routes.myAssessments}`) && "bg-color-secondary-light"}`} aria-haspopup="true">
                            <img className="kt-mr-15" src={myAssessment} alt="My Assessments" />
                            <span>My Assessments</span>
                        </div>
                    </Link>

                    {CanPatientCreateAssessment &&
                        <div className={`kt-menu__item  kt-menu__item--submenu header-menu-container`} aria-haspopup="true" onClick={toggleShowTakeAssessmentModal}>
                            <div  className="kt-menu__link kt-menu__toggle">
                                <img className="kt-mr-15" src={myExercises} alt="Take Assessments" />
                                <span>Take Assessments</span>
                            </div>
                        </div>
                    }

                    <Link to={`${routes.myExercises}/${false}`} className="kt-menu__link kt-menu__toggle">
                        <div className={`kt-menu__item  kt-menu__item--submenu header-menu-container ${location.pathname.includes(`${routes.myExercises}`) && "bg-color-secondary-light"}`} aria-haspopup="true">
                            <img className="kt-mr-15" src={myExercises} alt="My Exercises" />
                            <span>My Exercises</span>
                        </div>
                    </Link>

                        {/*<div className={`kt-menu__item  kt-menu__item--submenu header-menu-container`} aria-haspopup="true">*/}
                        {/*    <Link to={routes.myIntake} className="kt-menu__link kt-menu__toggle">*/}
                        {/*        <img className="kt-mr-15" src={myIntakeForm} alt="My Intake" />*/}
                        {/*        <span className={` ${location.pathname.includes(`${routes.myIntake}`) && "color-primary font-weight-600"}`}>My Intake Form</span>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}
                        {/*<div className={`kt-menu__item  kt-menu__item--submenu header-menu-container`} aria-haspopup="true">*/}
                        {/*    <Link to={routes.myPainlogs} className="kt-menu__link kt-menu__toggle">*/}
                        {/*        <img className="kt-mr-15" src={myPainlog} alt="My Painlogs" />*/}
                        {/*        <span className={` ${location.pathname.includes(`${routes.myPainlogs}`) && "color-primary font-weight-600"}`}>My Painlogs</span>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}
                    {/*</div>*/}

                    {/* Working with my Physician */}
                    {/*<div className=" ">*/}
                        {/*<div className={`kt-menu__item  kt-menu__item--active header-menu-container`} aria-haspopup="true">*/}
                        {/*    <a href={"!#"} rel="noopener noreferrer" onClick={event => event.preventDefault()}*/}
                        {/*       className="kt-menu__link cursor-hand">*/}
                        {/*        <span className="color-primary font-size-lg font-primary font-medium">Working with my Physician</span>*/}
                        {/*    </a>*/}
                        {/*</div>*/}

                    <Link to={routes.myCalendar} className="kt-menu__link kt-menu__toggle">
                        <div className={`kt-menu__item  kt-menu__item--submenu header-menu-container ${location.pathname.includes(`${routes.myCalendar}`) && "bg-color-secondary-light"}`} aria-haspopup="true">
                            <img className="kt-mr-15" src={myCalender} alt="My Calender" />
                            <span>My Calendar</span>
                        </div>
                    </Link>
                        {/*<div className={`kt-menu__item  kt-menu__item--submenu header-menu-container`} aria-haspopup="true">*/}
                        {/*    <Link to={routes.myDocuments} className="kt-menu__link kt-menu__toggle">*/}
                        {/*        <img className="kt-mr-15" src={myDocuments} alt="My Documents" />*/}
                        {/*        <span className={` ${location.pathname.includes(`${routes.myDocuments}`) && "color-primary font-weight-600"}`}>My Documents</span>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}
                        {/*<div className={`kt-menu__item  kt-menu__item--submenu header-menu-container`} aria-haspopup="true">*/}
                        {/*    <Link to={routes.myTelemedicineInvitation} className="kt-menu__link kt-menu__toggle">*/}
                        {/*        <img className="kt-mr-15" src={myTelemedInvitation} alt="My Telemedicine Invitation" />*/}
                        {/*        <span className={` ${location.pathname.includes(`${routes.myTelemedicineInvitation}`) && "color-primary font-weight-600"}`}>My Telemed Invitations</span>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}
                        {/*<div className={`kt-menu__item  kt-menu__item--submenu header-menu-container`} aria-haspopup="true">*/}
                        {/*    <div onClick={handleShow}  className="kt-menu__link kt-menu__toggle">*/}
                        {/*        <i className="-icon-color kt-menu__link-icon fa fa-handshake color-primary"></i>*/}
                        {/*        <span className=" kt-menu__link-text">Request an Appointment</span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    {/*</div>*/}

                    {/* My Statistics */}
                    {/*<div className=" ">*/}
                        {/*<div className={`kt-menu__item  kt-menu__item--active header-menu-container`} aria-haspopup="true">*/}
                        {/*    <a href={"!#"} rel="noopener noreferrer" onClick={event => event.preventDefault()}*/}
                        {/*       className="kt-menu__link cursor-hand">*/}
                        {/*        <span className="color-primary font-size-lg font-primary font-medium">My Statistics</span>*/}
                        {/*    </a>*/}
                        {/*</div>*/}

                    {IsPatientReportAvailable
                        ? (
                            <Link to={routes.myReports} className="kt-menu__link kt-menu__toggle">
                                <div className={`kt-menu__item  kt-menu__item--submenu header-menu-container ${location.pathname.includes(`${routes.myReports}`) && "bg-color-secondary-light"}`} aria-haspopup="true">
                                    <img className="kt-mr-15" src={myReport} alt="My Reports" />
                                    <span>My Reports</span>
                                </div>
                            </Link>
                        )
                        : ""
                    }
                    {/*<Link to={routes.myStatistics} className="kt-menu__link kt-menu__toggle">*/}
                    {/*    <div className={`kt-menu__item  kt-menu__item--submenu header-menu-container ${location.pathname.includes(`${routes.myStatistics}`) && "bg-color-secondary-light"}`} aria-haspopup="true">*/}
                    {/*        <img className="kt-mr-15" src={myStatistics} alt="My Statistics" />*/}
                    {/*        <span>My Statistics</span>*/}
                    {/*    </div>*/}
                    {/*</Link>*/}
                        {/*<div className={`kt-menu__item  kt-menu__item--submenu header-menu-container ${location.pathname.includes(`${routes.myInsight}`) && "bg-color-secondary-light"}`} aria-haspopup="true">*/}
                        {/*    <Link to={routes.myInsight} className="kt-menu__link kt-menu__toggle">*/}
                        {/*        <img className="kt-mr-15" src={myInsights} alt="My Insight" />*/}
                        {/*        <span>My Insights</span>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}

                        {GLOBAL?.IS_SUBSCRIPTION_ENABLED &&
                            <Link to={routes.cards} className="kt-menu__link kt-menu__toggle">
                                <div className={`kt-menu__item  kt-menu__item--submenu header-menu-container ${location.pathname.includes(`${routes.cards}`) && "bg-color-secondary-light"}`} aria-haspopup="true">
                                    <img className="kt-mr-15" src={myCards} alt="Credit Cards" />
                                    <span>Credit Cards</span>
                                </div>
                            </Link>
                        }
                    {/*</div>*/}
                </div>
            </div>
        </>
    );
};

export default TopSideMenu;
