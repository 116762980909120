import "./NotFound.scss";
import React from "react";
import { Trans } from "react-i18next";

const NotFound = () => {
  return (
    <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
      <div className="kt-grid__item kt-grid__item--fluid kt-grid  kt-error-v1">
        <div className="kt-error-v1__container">
          <h1 className="kt-error-v1__number">404</h1>
          <p className="kt-error-v1__desc">
            <Trans i18nKey="common.message.error.something-went-wrong-here"></Trans>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
