import React, { Fragment, useState } from 'react';
import { Modal, ModalHeader, ModalBody, UncontrolledTooltip } from 'reactstrap';

const FysicalScoreReportView = (props) => {
    const [modalFullScreen, setModalFullScreen] = useState(false);

    const { open, url, toggleFysicalReportModal } = props;

    const onClickToggleModalScreenSize = (e) => {
        e.preventDefault();
        setModalFullScreen(!modalFullScreen);
    };

    const getFullScreenModalStyle = () => {
        if (modalFullScreen) {
            return 'modal-full-screen'
        }
    };

    return (
        <Modal isOpen={open} className={`modal-xl report ${getFullScreenModalStyle()}`}>
            <ModalHeader toggle={toggleFysicalReportModal}>
                Report &nbsp;&nbsp;
                {modalFullScreen && (
                    <Fragment>
                        <a rel="noopener noreferrer" href={"!#"}
                           className="btn btn-outline-hover-success btn-icon btn-sm float-right"
                           onClick={onClickToggleModalScreenSize}
                           id="btnMinimize">
                            <span>
                                <i className={modalFullScreen ? "fa fa-compress" : "fa fa-expand"}/>
                            </span>
                        </a>
                        <UncontrolledTooltip
                            placement="bottom"
                            target="btnMinimize">
                            MINIMIZE SCREEN
                        </UncontrolledTooltip>
                    </Fragment>
                )}
                {!modalFullScreen && (
                    <Fragment>
                        <a rel="noopener noreferrer" href={"!#"}
                           className="btn btn-outline-hover-success btn-icon btn-sm float-right"
                           onClick={onClickToggleModalScreenSize}
                           id="btnMaximize">
                            <span>
                                <i className={modalFullScreen ? "fa fa-compress" : "fa fa-expand"}/>
                            </span>
                        </a>
                        <UncontrolledTooltip
                            placement="bottom"
                            target="btnMaximize">
                            FULL SCREEN
                        </UncontrolledTooltip>
                    </Fragment>
                )}
            </ModalHeader>
            <ModalBody>
                <div className="kt-spinner kt-spinner--lg kt-spinner--info kt-mb-20"/>
                <div className="embed-responsive embed-responsive-21by9">
                    <iframe className="embed-responsive-item" title="Report"
                            src={url}/>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default FysicalScoreReportView;
