import GLOBAL from "../../global";
import {
    drawMiddleBottomLine
} from "../CanvasDrawing";

let startInferenceTime,
    numInferences = 0;
let inferenceTimeSum = 0,
    lastPanelUpdate = 0;
let totalFps = 0;
const CONFIDENCE_SCORE = 0.4;

const MOVENET_CONFIG = {
    maxPoses: 1,
    type: 'lightning',
    scoreThreshold: 0.3
};

export const NET_MODEL = MOVENET_CONFIG;
export const DEFAULT_LINE_WIDTH = 3;
export const DEFAULT_RADIUS = 3;
export const FULL_SCREEN_SKELETON_COLOR = "#5578EB";
export const MIN_SCREEN_SKELETON_COLOR = "#FFFFFF";
export const SPEECH_PROBABILITY_THRESHOLD = 0.90;

export const GUI_STATE = {
    detector: null,
    model: null,
    modelType: null,
    rafId: null,
    ctxFullScreen: null,
    ctxMinScreen: null
};

const KEYPOINTS = {
    'nose': 0,
    'left_eye': 1,
    'right_eye': 2,
    'left_ear': 3,
    'right_ear': 4,
    'left_shoulder': 5,
    'right_shoulder': 6,
    'left_elbow': 7,
    'right_elbow': 8,
    'left_wrist': 9,
    'right_wrist': 10,
    'left_hip': 11,
    'right_hip': 12,
    'left_knee': 13,
    'right_knee': 14,
    'left_ankle': 15,
    'right_ankle': 16
};

const LOWER_POINTS = {
    'calibration': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'forwardHead': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'rolledShoulder': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'spineAlign': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'postureAnalysis': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'lookCeiling': [KEYPOINTS.left_shoulder, KEYPOINTS.right_shoulder],
    'chinToChest': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'turnHeadRight': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'turnHeadLeft': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'tiltHeadLeft': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'tiltHeadRight': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'reachArmsOverHead': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'makeMuscle': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'rightHandToShoulder': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'leftHandToShoulder': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'leftShoulderBlade': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'rightShoulderBlade': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'slideUpRightHand': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'slideUpLeftHand': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'handsTouchBackRightUp': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'handsTouchBackLeftUp': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'slideDownHandsOnBack': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'prayHands': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'invertedPrayHands': [KEYPOINTS.left_knee, KEYPOINTS.right_knee],
    'tipToFloorStandStill': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'tipToFloor': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'handLegBendLeftStandStill': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'handLegBendLeft': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'handLegBendRightStandStill': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'handLegBendRight': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'lumbarLeftLateralFlexion': [KEYPOINTS.left_knee, KEYPOINTS.right_knee],
    'lumbarRightLateralFlexion': [KEYPOINTS.left_knee, KEYPOINTS.right_knee],
    'headToTopBend': [KEYPOINTS.left_knee, KEYPOINTS.right_knee],
    'backFlexion': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'kneeSquat': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'hipExtensionLeft': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'hipExtensionRight': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'hipKneeFlexedFlexionLeft': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'hipKneeFlexedFlexionRight': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'hipKneeExtendedFlexionLeft': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'hipKneeExtendedFlexionRight': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'hipAdductionLeft': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'hipAdductionRight': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'hipAbductionLeft': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'hipAbductionRight': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'hipInternalRotationLeft': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'hipInternalRotationRight': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'hipExternalRotationLeft': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'hipExternalRotationRight': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'kneeFlexionLeft': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'kneeFlexionRight': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'kneeExtensionLeft': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'kneeExtensionRight': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'shoulderAbductionRight': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'shoulderAdductionLeft': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'shoulderAdductionRight': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'shoulderFlexionLeft': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'shoulderFlexionRight': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'shoulderExtensionLeft': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'shoulderExtensionRight': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'shoulderInternalRotationLeft': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'shoulderInternalRotationRight': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'shoulderExternalRotationLeft': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'shoulderExternalRotationRight': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'elbowFlexionLeft': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'elbowFlexionRight': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'elbowExtensionLeft': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'elbowExtensionRight': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'elbowExtension': [KEYPOINTS.left_hip, KEYPOINTS.right_hip],
    'ankleDorsiFlexionRight': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'ankleDorsiFlexionLeft': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'anklePlantarFlexionRight': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
    'anklePlantarFlexionLeft': [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle],
};

// const boxCoordinates = {
//     left: 150,
//     top: 40,
//     right:300,
//     bottom: 400
// }

export const checkFullBodyInBox = (keyPoints) => {
    for(let i = 0; i < keyPoints.length; i++){
        if(keyPoints[i].x <= GLOBAL.left || keyPoints[i].x >= GLOBAL.right
            || keyPoints[i].y <= GLOBAL.top || keyPoints[i].y >= GLOBAL.bottom){
                return false;
            }
    }
    return true;
}

export const checkHead = (keyPoints) => {
    let isValid;

    if (keyPoints[KEYPOINTS.nose]?.score < CONFIDENCE_SCORE && keyPoints[KEYPOINTS.left_eye]?.score < CONFIDENCE_SCORE && keyPoints[KEYPOINTS.right_eye]?.score < CONFIDENCE_SCORE && keyPoints[KEYPOINTS.left_ear]?.score < CONFIDENCE_SCORE && keyPoints[KEYPOINTS.right_ear]?.score < CONFIDENCE_SCORE) {
        isValid = false;
    } else {
        isValid = true;
    }

    return isValid;
}

export const checkLeg = (keyPoints, exerciseValue) => {
    let isValid;
    const exercisePoints = (exerciseValue && LOWER_POINTS[exerciseValue]) || [KEYPOINTS.left_ankle, KEYPOINTS.right_ankle];
    const lowerPoint = exercisePoints[0];
    const upperPoint = exercisePoints[1];

    if (keyPoints[lowerPoint]?.score < CONFIDENCE_SCORE && keyPoints[upperPoint]?.score < CONFIDENCE_SCORE) {
        isValid = false;
    } else {
        isValid = true;
    }

    return isValid;
}

export const checkFeet = (keyPoints) => {
    let leftFootY, rightFootY, isValid;
    const bottomLinePosition = drawMiddleBottomLine();

    let leftLineFootY = bottomLinePosition?.yDown;
    let rightLineFootY = bottomLinePosition?.yDown;
    const leftFootAnkle = keyPoints[KEYPOINTS.left_ankle];
    const rightFootAnkle = keyPoints[KEYPOINTS.right_ankle];

    leftFootY = leftFootAnkle.y - 50;
    rightFootY = rightFootAnkle.y - 50;

    if ((leftFootY > (leftLineFootY - 50) && leftFootY < (leftLineFootY + 50)) && (rightFootY > (rightLineFootY - 50) && rightFootY < (rightLineFootY + 50))) {
        isValid = true;
    } else {
        isValid = true;
    }

    return isValid;
}

export const getShoulderMidPoint = (keyPoints) => {
    let shoulderLeft = keyPoints[KEYPOINTS.left_shoulder]
    let shoulderRight = keyPoints[KEYPOINTS.right_shoulder]

    if (shoulderLeft == null || shoulderRight == null) return null

    return {
        score: shoulderLeft.score > shoulderRight.score ? shoulderRight.score : shoulderLeft.score,
        x: shoulderLeft.x + (shoulderRight.x - shoulderLeft.x) / 2,
        y: shoulderLeft.y + (shoulderRight.y - shoulderLeft.y) / 2
    }
}

export const checkNoseInRightSide = (keyPoints) => {
    let nosePoint = keyPoints[KEYPOINTS.nose]
    let shoulderMidPoint = getShoulderMidPoint(keyPoints)

    if (nosePoint == null || shoulderMidPoint == null) return false

    return nosePoint.x >= shoulderMidPoint.x
}

export const checkNoseInLeftSide = (keyPoints) => {
    let nosePoint = keyPoints[KEYPOINTS.nose]
    let shoulderMidPoint = getShoulderMidPoint(keyPoints)

    if (nosePoint == null || shoulderMidPoint == null) return false

    return nosePoint.x <= shoulderMidPoint.x
}

export const beginEstimatePosesStats = () => {
    startInferenceTime = (performance || Date).now();
}

export const endEstimatePosesStats = () => {
    const endInferenceTime = (performance || Date).now();
    inferenceTimeSum += endInferenceTime - startInferenceTime;
    ++numInferences;
    const panelUpdateMilliseconds = 1000;

    if (endInferenceTime - lastPanelUpdate >= panelUpdateMilliseconds) {
        const averageInferenceTime = inferenceTimeSum / numInferences;
        inferenceTimeSum = 0;
        numInferences = 0;
        totalFps = (1000.0 / averageInferenceTime, 120);
        lastPanelUpdate = endInferenceTime;
    }
}
