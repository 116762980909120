import React from "react";
import {
    getButtonDisplayStyle,
} from "../ChatServices";
import CONSTANT from "../../../../constants/Constant";
import {isSelectedCurrentOption} from "../ChatUtils";
import {useSelector} from "react-redux";
import useCurrentQuestionResponse from "../hooks/useCurrentQuestionResponse";
import Checkbox from "../../form-element/CheckboxOption";
import {shouldCheckboxOptionDisabled} from "../../../../utils/Utils";

const CheckBoxQuestionsAnswers = () => {
    const { onClickCheckbox, onClickCheckboxAnswer, getAnswerListForCheckboxQuestion } = useCurrentQuestionResponse();
    const chatBotState = useSelector(state => state.chatBot);
    const botData = chatBotState?.botData || {};
    const chats = botData?.chats || [];
    const currentChatIndex = botData?.currentChatIndex ?? -1;

    const chat = chats[currentChatIndex];

    const isCurrentOptionSelected = (chat, chatResponse) => {
        return isSelectedCurrentOption(chat, chatResponse);
    }

    return  (
        <>
            {chat && (
                <>
                    <div className={`responses ${getButtonDisplayStyle(chat)}`}>
                        {chat.responses && chat.responses.length > 0 && (
                            chat.responses.map((chatResponse, indexR) => {
                                return (
                                    <Checkbox.OptionItem
                                        key={chatResponse?.id}
                                        isChecked={isCurrentOptionSelected(chat, chatResponse)}
                                        onClick={() => onClickCheckbox(chat, chatResponse)}
                                        label={chatResponse.name}
                                        className={`btn btn-outline-brand btn-elevate btn-pill-5 btn-lg btn-ans px-2 mt-2 
                                            ${chat.responses.length > 4 ? " min-w-15vw" : ""} 
                                            ${isCurrentOptionSelected(chat, chatResponse) ? "selected-option" : ""}`
                                        }
                                        iconClassName="mr-2"
                                        disabled={shouldCheckboxOptionDisabled(chat.singleSelectAnswerId, getAnswerListForCheckboxQuestion(chat), chatResponse.id)}
                                    />
                                );
                            }))}
                    </div>

                    <button
                        type="button"
                        id={`btn-checkbox-${chat.questionId}`}
                        className="btn btn-check kt-mt-30 bg-color-bright-light-green kt-rounded d-block mx-auto text-white"
                        disabled={chat.answered && chat.intent === CONSTANT.CHATBOT_INTENT.BODY_REGION}
                        onClick={() => onClickCheckboxAnswer(chat)}>
                        OK
                    </button>
                </>
            )}
        </>
    )
};

export default CheckBoxQuestionsAnswers;