import React, {useState} from "react";
import DeviceSettingsModal from "./DeviceSettingsModal";
import Settings from "../../../assets/img/movement/settings.svg";
import ExpandVideo from "../../../assets/img/movement/ExpandVideo.svg";
import AudioMute from "../../../assets/img/movement/AudioMute.svg";
import VideoUnmute from "../../../assets/img/movement/VideoUnmute.svg";
import GLOBAL from "../../../global";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const VideoToolbar = ({ isFullScreenVideo, onClickFullScreen, updateWebCamConstraints, onClickSwitchCamera, webcamConstraints}) => {

    const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
    const [deviceSettingsModal, setDeviceSettingsModal] = useState(false);

    const onClickFullScreenVideo = (e) => {
        e.preventDefault();
        onClickFullScreen();
    }

    const onClickInputDeviceSettingsModal = (e) => {
        e.preventDefault();
        setDeviceSettingsModal(!deviceSettingsModal);
    };
    const onClickSwitchCameraFacing = (e) => {
        e.preventDefault();
        onClickSwitchCamera();
    };

    return (
        <>
            <div className="video-toolbar-box">
                <div className="padding-bottom-12 video-expand-button">
                    <div className="background-video-toolbar-background-white background-video-toolbar-round-50">
                        <a rel="noopener noreferrer" href={"!#"}
                        onClick={(e)=>onClickFullScreenVideo(e)}
                        id="expand">
                            {isFullScreenVideo && (
                                <img src={ExpandVideo} alt="ExpandVideo"/>
                            )}
                            {!isFullScreenVideo && (
                                <img src={ExpandVideo} alt="ExpandVideo"/>
                            )}
                        </a>
                    </div>
                </div>
                {GLOBAL.DEVICE_WIDTH >= 1000 && (
                    <div className="background-video-toolbar-background-white background-video-toolbar-round-50">
                        <a rel="noopener noreferrer" href={"!#"}
                            className="toolbar-item"
                            onClick={onClickInputDeviceSettingsModal}
                            id="camera-settings">
                                <img src={Settings} alt="settings"/>
                        </a>
                    </div>
                 ) //: 
                //     <div className="background-video-toolbar-background-white background-video-toolbar-round-50">
                //         <button rel="noopener noreferrer" href={"!#"}
                //             className="toolbar-item"
                //             onClick={onClickSwitchCameraFacing}
                //             id="camera-settings">
                //                 <i class="fa-solid fa-camera-rotate"></i>
                //         </button>
                //     </div>
                }
            </div>
            {!isFullScreenVideo && (
                <DeviceSettingsModal
                    openModal={deviceSettingsModal}
                    toggleModal={onClickInputDeviceSettingsModal}
                    onSelectChangeDevice={updateWebCamConstraints}
                    webcamConstraints={webcamConstraints}
                />
            )}
        </>
    );

}

export default VideoToolbar;