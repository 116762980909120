import { createSlice } from '@reduxjs/toolkit';
import { initialSweetAlertState } from './sweetAlertConstants';

const sweetAlertSlice = createSlice({
    name: 'sweetAlert',
    initialState: initialSweetAlertState,
    reducers: {
        setSweetConfig(state, action) {
            state.config = { ...state.config, ...action.payload };
            state.isVisible = true;
        },
        clearSweetConfig(state) {
            state.config = initialSweetAlertState.config;
            state.isVisible = false;
        },
        toggleOkLoading(state, action) {
            state.config.okLoading = action.payload;
        },
        toggleNoLoading(state, action) {
            state.config.noLoading = action.payload;
        }
    }
});

export const {
    setSweetConfig,
    clearSweetConfig,
    toggleOkLoading,
    toggleNoLoading
} = sweetAlertSlice.actions;
export default sweetAlertSlice.reducer;
