import defaultProfilePic from "../assets/img/users/default.jpg";
export default class PaymentCard {
    constructor(options) {
        options = options || {};

        this.CardHolderName = options.CardHolderName || '';
        this.Status = options.Status || '';
        this.CardImage = options.CardImage || defaultProfilePic;
        this.CardNumber = options.CardNumber || '';
        this.ExpiresDate = options.ExpiresDate || '';
    }
}