import React, {Fragment} from "react";
import { ChatbotButton } from "../ButtonComponent";
import CONSTANT from "../../../../constants/Constant";
import personLogo from "../../../../assets/img/users/user-base64/default";

import {
    getButtonMarginRightStyle,
    getButtonDisplayStyle,
    defaultColor,
    painActiveColor,
    getImageWithButton,
    getMarginStyle,
    getButtonMinWidth
} from '../ChatServices';
import MyAssessmentTooltip from "../../../my-assessments/MyAssessmentTooltip";
import {useSelector} from "react-redux";
import useCurrentQuestionResponse from "../hooks/useCurrentQuestionResponse";

const ButtonQuestionsAnswers = () => {
    const { onClickButtonAnswer } = useCurrentQuestionResponse();
    const chatBotState = useSelector(state => state.chatBot);
    const botData = chatBotState?.botData || {};
    const chats = botData?.chats || [];
    const currentChatIndex = botData?.currentChatIndex ?? -1;

    const chat = chats[currentChatIndex];

    return (
        <>
            {chat && !chat.answered && (
                <div className={`responses ${getButtonDisplayStyle(chat)}`}>
                    {chat.responses && chat.responses.length > 0 && (
                        chat.responses.map((chatResponse, indexR) => {
                            return (
                                <Fragment key={chatResponse?.id || indexR}>
                                        <ChatbotButton
                                            tabIndex="-1"
                                            type="button"
                                            id={`btn-${chat.questionId}-${chatResponse.id}`}
                                            color={defaultColor(chatResponse.color)}
                                            fill={painActiveColor(chat, chatResponse.name)}
                                            onClick={() => onClickButtonAnswer(chat, chatResponse)}
                                            className={`btn btn-outline-brand btn-elevate btn-pill-5 btn-lg btn-ans px-3 mt-2 ${getImageWithButton(chat, chatResponse)} ${getButtonMarginRightStyle(chat)} ${getButtonMinWidth(chat)}`}>
                                            <div className="d-flex">
                                                {chat.intent === CONSTANT.CHATBOT_INTENT.PROVIDER && chatResponse.icon && (
                                                    <div
                                                        className="kt-media kt-media--sm kt-media--circle float-left kt-padding-5 kt-m5 kt-mr-10 border">
                                                        <img src={chatResponse.icon} alt="doctor profile"/>
                                                    </div>
                                                )}
                                                {chat.intent === CONSTANT.CHATBOT_INTENT.PROVIDER && !chatResponse.icon && (
                                                    <div
                                                        className="kt-media kt-media--sm kt-media--circle float-left kt-padding-5 kt-m5 kt-mr-10 border">
                                                        <img src={personLogo} alt="doctor profile"/>
                                                    </div>
                                                )}
                                                <span className={`m-auto ${getMarginStyle(chat)}`}>
                                                    {painActiveColor(chat, chatResponse.name) === "true"
                                                        ? chatResponse.color === "" && (<i className="fa fa-check"/>)
                                                        : ""
                                                    }
                                                    {chatResponse.name}
                                                </span>
                                            </div>
                                        </ChatbotButton>
                                    {chatResponse.title && (
                                        <MyAssessmentTooltip
                                            title={chatResponse.title}
                                            description={chatResponse.description}
                                            target={`btn-${chat.questionId}-${chatResponse.id}`}
                                        />
                                    )}
                                </Fragment>
                            )
                        })
                    )}
                </div>
            )}
            {chat?.answered && (
                <div className="answers">
                    {chat.answers && chat.answers.length > 0 && (
                        chat.answers.map((chatAnswer, indexR) => {
                            return (
                                <div className="kt-widget3" key={chatAnswer?.id}>
                                    <div className="kt-widget3__item">
                                        <div className="kt-widget3__header d-flex justify-content-end">
                                            <div className="kt-widget3__user-img">
                                                <ChatbotButton
                                                    type="button"
                                                    color={chatAnswer.color}
                                                    fill={'true'}
                                                    key={chatAnswer?.id}
                                                    className={`btn btn-pill-5 btn-lg cursor-hand ${getImageWithButton(chat, chatAnswer)}`}>
                                                    <div className="d-flex">
                                                        <span className={`m-auto ${getMarginStyle(chat)}`}>
                                                            {chatAnswer.name}
                                                        </span>
                                                    </div>
                                                </ChatbotButton>
                                            </div>
                                            {chatAnswer.title && (
                                                <div className="kt-widget3__info">
                                                    <span className="kt-widget3__username ">
                                                        {chatAnswer.title}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        {chatAnswer.description && (
                                            <div className="kt-widget3__body">
                                                <p className="kt-widget3__text">
                                                    {chatAnswer.description}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                        })
                    )}
                </div>
            )}
        </>
    )
};

export default ButtonQuestionsAnswers;