import React, {useEffect, useState} from "react";

import {getElementStyle, getGuidelineContainerStyleClass} from "./telemedicineUtils";
import CrossBtn from '../../../assets/img/utils/cross.svg'

const MovementGuideline = ({ selectedExercise, isFromMovementGuidelineModal = true }) => {
    const [isTabOrMobile, setIsTabOrMobile] = useState(+(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 768);
    const [showFullGuidelineInTabOrMobile, setShowFullGuidelineInTabOrMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if(+(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 768) {
                setIsTabOrMobile(true);
            } else {
                setIsTabOrMobile(false);
            }
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleShowFullGuideline = (e, isFromCrossBtn = false) => {
        if(!isFromCrossBtn && isTabOrMobile &&showFullGuidelineInTabOrMobile ) {
            return;
        }

        setShowFullGuidelineInTabOrMobile((prevState) => !prevState);
    }

    return (
        <div className="movement-guideline-container">
            <div className={`bg-color-light-gray height-inherite d-flex flex-column ${getGuidelineContainerStyleClass(isTabOrMobile, showFullGuidelineInTabOrMobile, isFromMovementGuidelineModal)}`}>
                <div className={`${getElementStyle(isTabOrMobile, showFullGuidelineInTabOrMobile, isFromMovementGuidelineModal)}`}>
                    <div className="movement-guideline-container__header bg-secondary">
                        <p>Movement Guideline</p>
                        {!isFromMovementGuidelineModal && (
                            <img className="" src={CrossBtn} alt="cross_btn" onClick={(e) => toggleShowFullGuideline(e,true)}/>
                        )}
                    </div>
                </div>
                {(Object.keys(selectedExercise).length !== 0)
                    ? (
                        <>
                            <div className={`biometric-guideline-name-container font-bold font-size-md p-4 ${getElementStyle(isTabOrMobile, showFullGuidelineInTabOrMobile, isFromMovementGuidelineModal)}`}>
                                {/*{selectedExercise !== {} && selectedExercise.ExerciseName !== '' ? (<div>{selectedExercise.ExerciseName}</div>)*/}
                                {selectedExercise.ExerciseName !== '' && (<div>{selectedExercise.ExerciseName}</div>)}
                            </div>
                            <div className="biometric-guideline-img-container position-relative">
                                {/* <div className="h-100-percent fake-spinner d-flex justify-content-center align-items-center">
                              <i className="fa fa-spinner fa-spin"/>
                          </div> */}
                                <div className={`h-100-percent img-tra`} onClick={toggleShowFullGuideline}>
                                    {selectedExercise?.ExerciseMediaUrlPatient !== ''
                                        ? (<img src={selectedExercise?.ExerciseMediaUrlPatient} className="max-height-50-vh max-width-100-percent"/>)
                                        : null
                                    }
                                </div>
                            </div>
                            <div className={`biometric-guideline-guideline-text mt-3 ${getElementStyle(isTabOrMobile, showFullGuidelineInTabOrMobile, isFromMovementGuidelineModal)}`}>
                                {selectedExercise?.PatientGuidelineContent !== ''
                                    ? (<div>{selectedExercise?.PatientGuidelineContent}</div>)
                                    : null
                                }
                            </div>
                        </>
                    )
                    : (
                        // <div className={`font-bold font-size-sm  ${isTabOrMobile ? ' d-none ' : ' d-flex justify-content-center align-items-center flex-grow-1 '} `}>
                        <div className={`font-bold font-size-sm d-flex justify-content-center align-items-center flex-grow-1`}>
                            <div className={`${getElementStyle(isTabOrMobile, showFullGuidelineInTabOrMobile, isFromMovementGuidelineModal)}`}>
                                {isFromMovementGuidelineModal
                                    ? (<p>Select an exercise</p>)
                                    : (<p className="text-align-center">Ask provider to select an exercise</p>)
                                }
                            </div>

                        </div>
                    )
                }

            </div>
        </div>
    )
}

export default MovementGuideline;
