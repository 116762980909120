import React, {useContext, useEffect} from 'react';

// noinspection ES6CheckImport
import { Redirect } from "react-router-dom";

import routes from "../../../constants/Routes";
import GLOBAL from "../../../global";
import CONSTANT from "../../../constants/Constant";
import AppContext from "../../../contexts/AppContext";
import Chatbot from "./Chatbot";


import { isEmptyObject } from "../../../utils/Utils";
import {createBotOption, intakeOptions, mskOptions, postureOptions} from "./business/createBotInfoObj";
import {setBotInfo} from "../../../features/chat/chatbotSlice";
import {useDispatch, useSelector} from "react-redux";


const ChatBotWrapper = () => {
    const dispatch = useDispatch();
    const { authenticated } = useContext(AppContext);
    const botState = useSelector(state => state.chatBot);
    const chatBotUIState = botState?.chatBotUIState || false;
    const botData = botState?.botData || {};

    const selectedBotInfoObj = botData?.botInfo || {};
    const initChatBot = chatBotUIState?.initChatBot || false;
    const bodyRegionList = botData?.bodyRegionList || [];
    let invitationTypeName = (botData?.invitationTypeName || '').toLowerCase();

    useEffect(() => {
        if(initChatBot) {
            dispatch(setBotInfo(getBotInfo()));
        }
    }, [initChatBot]);

    const getBotInfo = () => {
        GLOBAL.SHOULDER_TO_SHOULDER_DISTANCE = '';
        let botOption = {};

        if (invitationTypeName) {
            if(bodyRegionList.includes(CONSTANT.BODY_REGIONS.POSTURE.key)) {
                botOption = createBotOption(postureOptions);
            } else if (invitationTypeName === CONSTANT.REDIRECT_TYPE.SELF) {
                    botOption = createBotOption(mskOptions);
            } else if (invitationTypeName === CONSTANT.REDIRECT_TYPE.TELEMEDICINE || invitationTypeName === CONSTANT.REDIRECT_TYPE.INCLINIC) {
                botOption = createBotOption(intakeOptions);
            }
        }

        return botOption;
    }


    if (!authenticated) {
        return <Redirect to={`${routes.landing}`}/>;
    }

    return (
        <>
            {initChatBot && !isEmptyObject(selectedBotInfoObj) && (
                <Chatbot/>
            )}
        </>
    )
};

export default ChatBotWrapper;