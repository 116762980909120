import React, { Fragment, useState } from 'react';
import { Modal, ModalHeader, ModalBody, UncontrolledTooltip } from 'reactstrap';

const DisplayUrlContent = (props) => {
    const { open, url, onToggleModal, headerTitle } = props;
    const [modalFullScreen, setModalFullScreen] = useState(false);
    const [loading, setLoading] = useState(true);

    const onClickTermsOfServiceModalScreenToggle = (e) => {
        e.preventDefault();
        setModalFullScreen(!modalFullScreen);
    };

    const getFullScreenModalStyle = () => {
        if (modalFullScreen) {
            return 'modal-full-screen'
        }
    };

    return (
        <Modal isOpen={open} className={`modal-xl ${getFullScreenModalStyle()}`}>
            <ModalHeader toggle={onToggleModal}>
                <div className="modal-title">
                    {`${headerTitle ? headerTitle : 'Terms of services'}`}
                    {modalFullScreen && (
                        <Fragment>
                            <a rel="noopener noreferrer" href={"!#"}
                               className="btn btn-outline-hover-success btn-sm float-right"
                               onClick={(e) => onClickTermsOfServiceModalScreenToggle(e)}
                               id="btnMinimize">
                                <span>
                                    <i className={modalFullScreen ? "fa fa-compress" : "fa fa-expand"}> </i>
                                </span>
                            </a>
                            <UncontrolledTooltip
                                placement="bottom"
                                target="btnMinimize">
                                MINIMIZE SCREEN
                            </UncontrolledTooltip>
                        </Fragment>
                    )}
                    {!modalFullScreen && (
                        <Fragment>
                            <a rel="noopener noreferrer" href={"!#"}
                               className="btn btn-outline-hover-success btn-sm float-right"
                               onClick={(e) => onClickTermsOfServiceModalScreenToggle(e)}
                               id="btnMaximize">
                                <span>
                                    <i className={modalFullScreen ? "fa fa-compress" : "fa fa-expand"}> </i>
                                </span>
                            </a>
                            <UncontrolledTooltip
                                placement="bottom"
                                target="btnMaximize">
                                FULL SCREEN
                            </UncontrolledTooltip>
                        </Fragment>
                    )}
                </div>
            </ModalHeader>
            <ModalBody>
                {loading && <div className="kt-spinner z-index-0 kt-spinner--lg kt-spinner--info kt-mb-20"/>}
                <div className="embed-responsive embed-responsive-21by9">
                <iframe
                        className="embed-responsive-item" title="Terms of service"
                        onLoad={() => setLoading(false)}
                        src={url}/>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default DisplayUrlContent;
