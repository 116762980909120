import { createAsyncThunk } from '@reduxjs/toolkit';
import ChatbotService from "../../services/ChatbotService";
import { setSessionId } from "./chatbotSlice";

export const fetchResumeChatThunks = createAsyncThunk(
    'chatbot/fetchResumeChatThunks',
    async ({ payload }, { rejectWithValue }) => {
        try {
            const response = await ChatbotService.resumeChat(payload);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
