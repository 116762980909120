import axios from './Axios';
import { error, success } from "./ResponseHandler";
import GLOBAL from "../global";
import CONSTANT from "../constants/Constant";

const RESPONSE_TYPE = { responseType: 'text' };
const { REACT_APP_BASE_API_URL } = process.env;
const LOGIN_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.BASE_CONTROLLER}/Login`;
const DELETE_PROFILE = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.BASE_CONTROLLER}/DeleteUser`;
const REFRESH_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.BASE_CONTROLLER}/refreshtoken`;
const REGISTER_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.BASE_CONTROLLER}/Register`;
// const CREDIT_CARD_SAVING_URL = REACT_APP_BASE_API_URL + "/api/Payment/UpsertCreditCard";
const FORGOT_URL = REACT_APP_BASE_API_URL + "/api/base/ForgotPassword";
// const UPDATE_USER_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.BASE_CONTROLLER}/UpdatePatientInformation`;
// const USER_STATISTIC_URL = REACT_APP_BASE_API_URL + "/api/base/GetUserStatistic";
const TENANT_FEATURES_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.BASE_CONTROLLER}/GetTenantFeatures`;
const TENANT_INFO_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.BASE_CONTROLLER}/TenantInfo`;
// const UPDATE_FOR_PATIENT_URL = REACT_APP_BASE_API_URL + "/api/base/update";
const GET_PASSWORD_URL = REACT_APP_BASE_API_URL + "/api/base/GetPassword";
const RESET_PASSWORD_URL = REACT_APP_BASE_API_URL + "/api/base/ResetPassword";
// const GET_PATIENT_URL = REACT_APP_BASE_API_URL + "/api/base/GetPatient";
// const ACTIVATE_SUBSCRIPTION = REACT_APP_BASE_API_URL + "/api/SubscriptionFeature/ActivateSubscription";
// const GET_NOTIFICATION_SETTINGS = REACT_APP_BASE_API_URL + "/api/SubscriptionFeature/GetNotificationSettings";
// const GET_NOTIFICATION_PRIVILEGE = REACT_APP_BASE_API_URL + "/api/SubscriptionFeature/SetNotificationPrevilege";
const GET_SUBSCRIPTION_FEATURE_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.SUBSCRIPTION_FEATURE_CONTROLLER}/GetSubscriptionFeature`;
const VERIFY_EMAIL_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.BASE_CONTROLLER}/VerifyEmail`;
const VERIFY_INVITATION = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.BASE_CONTROLLER}/VerifyInvitation`;
// const REDIRECT_TO_INFORMATION_URL = REACT_APP_BASE_API_URL + "/api/base/GetRedirectToInformation";

// const INTAKE_QUESTIONS_URL = REACT_APP_BASE_API_URL + "/api/base/intakeQuestions";

class AuthService {
    async login(user) {
        try {
            user.Tenant = GLOBAL.TENANT;//NEED THIS AS DO NOT GET TOKEN YET
            const response = await axios.post(LOGIN_URL, user, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async deleteProfile(user) {
        try {
            const response = await axios.post(DELETE_PROFILE, user, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async refreshtoken(payload) {
        try {
            const response = await axios.post(REFRESH_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async register(user) {
        try {
            user.Tenant = GLOBAL.TENANT;//NEED THIS AS DO NOT GET TOKEN YET
            const response = await axios.post(REGISTER_URL, user, RESPONSE_TYPE);

            // if (!response.Successful) {
            //     return error(response);
            // }

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async forgot(user) {
        try {
            user.Tenant = GLOBAL.TENANT;//NEED THIS AS DO NOT GET TOKEN YET
            const response = await axios.post(FORGOT_URL, user, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    // async updateUser(payload = {}) {
    //     try {
    //         const response = await axios.post(UPDATE_USER_URL, payload, RESPONSE_TYPE);
    //
    //         return success(response);
    //     } catch (e) {
    //         return error(e);
    //     }
    // }

    // async getUserStatistic(payload = {}) {
    //     try {
    //         const response = await axios.post(USER_STATISTIC_URL, payload, RESPONSE_TYPE);
    //
    //         return success(response);
    //     } catch (e) {
    //         return error(e);
    //     }
    // }

    async getTenantFeature(payload = {}) {
        try {
            payload.Tenant = GLOBAL.TENANT;//NEED THIS AS DO NOT GET TOKEN YET
            const response = await axios.post(TENANT_FEATURES_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getTenantInfo(payload = {}) {
        try {
            payload.Tenant = GLOBAL.TENANT;//NEED THIS AS DO NOT GET TOKEN YET
            const response = await axios.post(TENANT_INFO_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    // async updateForPatient(payload = {}) {
    //     try {
    //         const response = await axios.post(UPDATE_FOR_PATIENT_URL, payload, RESPONSE_TYPE);
    //
    //         return success(response);
    //     } catch (e) {
    //         return error(e);
    //     }
    // }

    async getPassword(payload = {}) {
        try {
            payload.Tenant = GLOBAL.TENANT;//NOT USING THIS API
            const response = await axios.post(GET_PASSWORD_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async resetPassword(payload = {}) {
        try {
            payload.Tenant = GLOBAL.TENANT;//NOT USING THIS API
            const response = await axios.post(RESET_PASSWORD_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getSubscriptionFeature(user) {
        try {
            const response = await axios.post(GET_SUBSCRIPTION_FEATURE_URL, user, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async verifyEmail(payload) {
        try {
            payload.Tenant = GLOBAL.TENANT;//NEED THIS AS DO NOT GET TOKEN YET
            const response = await axios.post(VERIFY_EMAIL_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async verifyInvitation(payload) {
        try {
            payload.Tenant = GLOBAL.TENANT;//NEED THIS AS DO NOT GET TOKEN YET
            const response = await axios.post(VERIFY_INVITATION, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    // async redirectTo(payload) {
    //     try {
    //         const response = await axios.post(REDIRECT_TO_INFORMATION_URL, payload, RESPONSE_TYPE);
    //
    //         return success(response);
    //     } catch (e) {
    //         return error(e);
    //     }
    // }

    // async saveCreditCard(payload) {
    //     try {
    //         payload.Tenant = GLOBAL.TENANT;
    //         const response = await axios.post(CREDIT_CARD_SAVING_URL, payload, RESPONSE_TYPE);
    //
    //         if (!response.Successful) {
    //             // return error(response);
    //             return {
    //                 success: false,
    //                 error: response?.DisplayMessage,
    //                 data: [],
    //             };
    //         }
    //
    //         return success(response);
    //     } catch (e) {
    //         return error(e);
    //     }
    // }

}

export default new AuthService();
