import React, { useContext, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";

import AppContext from "../../contexts/AppContext";
import routes from "../../constants/Routes";
import { playAudio } from "../../utils/Utils";
import CONSTANT from "../../constants/Constant";

import Header from "../shared/layout/Header";
import Footer from "../shared/layout/Footer";
import MyAssessmentsDetail from "./MyAssessmentsDetail";
import BodyWithTopMenu from "../shared/layout/BodyWithTopMenu";

const MyAssessments = () => {
    const { userInfo } = useContext(AppContext);
    const { EnableFysicalScore, IsPostureAnalyzeEnabled } = userInfo.Permissions;
    const { isShowCompletedAssessments } = useParams();

    useEffect(() => {
        playAudio('remove').then();
    }, []);

    return (
        <>
            {!EnableFysicalScore && !IsPostureAnalyzeEnabled && (
                <Redirect to={`${routes.landing}`}/>
            )}
            {(EnableFysicalScore || IsPostureAnalyzeEnabled) && (
                <>
                    {/*<MobileHeader/>*/}
                    <div className="kt-grid kt-grid--hor kt-grid--root">
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                                <Header/>
                                {!EnableFysicalScore && IsPostureAnalyzeEnabled && (
                                    <MyAssessmentsDetail
                                        showCompletedAssessments={JSON.parse(isShowCompletedAssessments)}/>
                                )}
                                {EnableFysicalScore && (
                                    <>
                                        <BodyWithTopMenu pageName={CONSTANT.PAGE_NAME.MY_ASSESSMENTS}>
                                            <MyAssessmentsDetail
                                                showCompletedAssessments={JSON.parse(isShowCompletedAssessments)}/>
                                        </BodyWithTopMenu>
                                        <Footer/>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default MyAssessments;
