import { deepClone, playAudio } from "../../../../utils/Utils";
import ChatRequestDetail from "../../../../models/ChatRequestDetail";
import CONSTANT from "../../../../constants/Constant";
import { onSaveSingleClick } from "../ChatServices";
import GLOBAL from "../../../../global";
import routes from "../../../../constants/Routes";
import {
    setOpenSelfAssessmentModal,
    setShowPopUpWarningForYesAtCautionQuestion
} from "../../../../features/chat/chatbotSlice";
import { useDispatch, useSelector } from "react-redux";
import useChatbot from "./useChatbot";

const useSelfAssessmentAtChatbot = () => {
    const dispatch = useDispatch();
    const { prepareChatbotAnswer, clearAndCloseChatbot } = useChatbot();
    const chatBotState = useSelector(state => state.chatBot);

    const botData = chatBotState?.botData || {};
    const chats = botData?.chats || [];
    const currentChatIndex = botData?.currentChatIndex ?? -1;
    const selectedResponseOfCautionQuestion = botData?.selectedResponseOfCautionQuestion || {};
    const chat = chats[currentChatIndex] || {};

    const movementModalToggle = async (chat, response, forEdit) => {
        playAudio('remove').then();
        let deepClonedChat = deepClone(chat);

        deepClonedChat.answers = [{
            ...new ChatRequestDetail(response)
        }];

        if (response['referenceId'] && response['referenceId'] === CONSTANT.REFERENCE_RESPONSE.NO) { // 2 for I will do later
            await prepareChatbotAnswer(deepClonedChat);
            return;
        }

        // _setBodyRegionList(chats, chat);

        if (forEdit) {
            chat.movementEdit = true;
        }

        // let currentMovementEntryModalModal = movementEntryModalModal;
        // setMovementEntryModalModal(!currentMovementEntryModalModal);
        dispatch(setOpenSelfAssessmentModal(prevState => !prevState));

        if (response?.referenceId === CONSTANT.REFERENCE_RESPONSE.YES) {
            await onSaveSingleClick(chat)
        }
    };

    // const onCloseMovementModal = (chat) => {
    //     setMovementEntryModalModal(false);
    //
    //     GLOBAL.ALREADY_CALLED_API = false;
    //     const hasQuestion = chats.includes(item => item.questionId === 10000675); // 10000675 Prevent duplicate finish question, temp solution
    //
    //     if (!chat?.movementEdit && !hasQuestion) {
    //         prepareChatbotAnswer(chat);
    //     }
    // };

    const onSubmitMovementEntry = async (chat, response) => {
        // debugger
        // setMovementEntryModalModal(false);
        // dispatch(setOpenSelfAssessmentModal(false));
        GLOBAL.ALREADY_CALLED_API = false; //// TODO: have to remove using of global variable
        const hasQuestion = chats.includes(item => item.questionId === 10000675); // 10000675 Prevent duplicate finish question, temp solution

        if (!chat.movementEdit) {
            await prepareChatbotAnswer(chat);
        }
    };

    const handleCloseBiometricModal = () => {
        // closeChatModal && closeChatModal();
        clearAndCloseChatbot();
        // setMovementEntryModalModal && setMovementEntryModalModal(false);
        dispatch(setOpenSelfAssessmentModal(false));
        window.location.replace(`/#${routes.myAssessments}/false`);
    }

    const handleClickNoAtPopUpWarningForYesAtCautionQuestionClose = () => {
        dispatch(setShowPopUpWarningForYesAtCautionQuestion(false));
    };

    const handleClickYesAtPopUpWarningForYesAtCautionQuestionClose = async () => {
        dispatch(setShowPopUpWarningForYesAtCautionQuestion(false));
        let deepClonedChat = deepClone(chat);
        deepClonedChat.answers = [{
            ...new ChatRequestDetail(selectedResponseOfCautionQuestion)
        }];
        await prepareChatbotAnswer(deepClonedChat);
    };

    return {
        movementModalToggle,
        // onCloseMovementModal,
        // onSubmitMovementEntry,
        handleCloseBiometricModal,
        handleClickNoAtPopUpWarningForYesAtCautionQuestionClose,
        handleClickYesAtPopUpWarningForYesAtCautionQuestionClose
    };
}

export default useSelfAssessmentAtChatbot;