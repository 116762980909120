import { ChatbotButton } from "../ButtonComponent";
import React from "react";
import { defaultColor, getMarginStyle } from "../ChatServices";
import {isSelectedCurrentOption} from "../ChatUtils";
import {useSelector} from "react-redux";
import useSelfAssessmentAtChatbot from "../hooks/useSelfAssessmentAtChatbot";

const MovementModalAnswers = () => {
    const chatBotState = useSelector(state => state.chatBot);
    const botData = chatBotState?.botData || {};
    const chats = botData?.chats || [];
    const currentChatIndex = botData?.currentChatIndex ?? -1;

    const { movementModalToggle } = useSelfAssessmentAtChatbot();

    const chat = chats[currentChatIndex];

    return (
        <>
            {chat && !chat.answered && (
                <>
                    <div className="responses">
                        {chat.responses && chat.responses.length > 0 && (
                            chat.responses.map((chatResponse, indexR) => {
                                return (
                                    <ChatbotButton
                                        type="button"
                                        key={indexR}
                                        color={defaultColor(chatResponse.color)}
                                        fill={isSelectedCurrentOption(chat, chatResponse).toString()}
                                        onClick={() => movementModalToggle(chat, chatResponse)}
                                        className="btn btn-outline-brand btn-elevate btn-pill-5 btn-lg btn-ans mr-5 mt-2 px-2">
                                        <div className="d-flex">
                                                <span className={`m-auto ${getMarginStyle(chat)}`}>
                                                    {isSelectedCurrentOption(chat, chatResponse) ? <i className="fa fa-check"/> : "" } {chatResponse.name}
                                                </span>
                                        </div>
                                    </ChatbotButton>
                                )
                            })
                        )}
                    </div>
                </>
            )}
            {chat && chat.answered && (
                <div className="answers">
                    {chat.answers && chat.answers.length > 0 && (
                        chat.answers.map((chatAnswer, indexR) => {
                            return (
                                <div className="kt-widget3" key={indexR}>
                                    <div className="kt-widget3__item">
                                        <div className="kt-widget3__header d-flex justify-content-end">
                                            <div className="kt-widget3__user-img">
                                                {chatAnswer.referenceId === 2 && (
                                                    <button type="button"
                                                            disabled="disabled"
                                                            className="btn btn-pill btn-lg cursor-hand">
                                                        {/*NO CHIEF COMPLAINTS*/}
                                                        {chatAnswer.name}
                                                    </button>
                                                )}
                                                {chatAnswer.referenceId !== 2 && (
                                                    <button type="button"
                                                            onClick={() => movementModalToggle(chat, chatAnswer, true)}
                                                            className="btn btn-primary btn-pill btn-lg">
                                                        REVIEW BIOMETRIC MOVEMENT
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    )}
                </div>
            )}
        </>
    )
};

export default MovementModalAnswers;
