export default class ChatRequestDetail {
    constructor(options) {
        options = options || {};

        this.id = options.id || 0;
        this.name = options.name || '';
        this.referenceId = options.referenceId || 0;
        this.bodyLocation = options.bodyLocation || '';
        this.labels = options.labels || [];
        this.types = options.types || [];
        this.triggers = options.triggers || [];
        this.symptoms = options.symptoms || [];
        this.title = options.title || '';
        this.description = options.description || '';
    }
}
