import { AsyncPaginate } from "react-select-async-paginate";
import { loadOptions } from "../../../../utils/Utils";
import React from "react";
import {useSelector} from "react-redux";
import useChatbot from "../hooks/useChatbot";

const AutoCompleteQuestionsAnswers = () => {
    const { prepareChatbotAnswer, onChangeUpdateChatsState } = useChatbot();
    const chatBotState = useSelector(state => state.chatBot);
    const botData = chatBotState?.botData || {};
    const chats = botData?.chats || [];
    const currentChatIndex = botData?.currentChatIndex ?? -1;


    const chat = chats[currentChatIndex];

    const onChangeAutoComplete = (chat) => chatResponses => {
        chat.answers.length = [{
            ...chatResponses
        }];
        onChangeUpdateChatsState(chat);
    };

    const onClickAutoCompleteAnswer = (chat) => () => {
        prepareChatbotAnswer(chat);
    };

    return (
        <>
            {chat && !chat.answered && (
                <>
                    <div className="responses d-block">
                        <div className="form-group">
                            <AsyncPaginate
                                menuPlacement="top"
                                isClearable={false}
                                isSearchable={true}
                                isRtl={false}
                                backspaceRemovesValue={false}
                                closeMenuOnSelect={true}
                                name="chatbotAutoComplete"
                                className="mmh-multi-select kt-p0 kt-font-xl"
                                optionClassName="needsclick"
                                isMulti
                                value={chat.answers[0]}
                                loadOptions={loadOptions(chat.responses)}
                                onChange={onChangeAutoComplete(chat)}
                                classNamePrefix="mmh"
                            />
                        </div>
                        <button type="button" className="btn btn-primary btn-lg"
                                onClick={onClickAutoCompleteAnswer(chat)}>
                            OK
                        </button>
                    </div>
                </>
            )}

            {chat && chat.answered && (
                <div className="answers">
                    <div className="kt-list-timeline">
                        <div className="kt-list-timeline__items">
                            {chat.answers && chat.answers.length > 0 && (
                                chat.answers.map((chatAnswer, indexR) => {
                                    return (
                                        <div className="kt-list-timeline__item kt-m0" key={indexR}>
                                            <span
                                                className="kt-list-timeline__badge kt-list-timeline__badge--brand">
                                            </span>
                                            <span className="kt-list-timeline__text kt-font-xl">{chatAnswer.name}</span>
                                        </div>
                                    )
                                })
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
};

export default AutoCompleteQuestionsAnswers;