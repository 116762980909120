import React, { memo } from "react";
import './PasswordStrengthChecker.scss';

const PasswordStrengthChecker = memo((props) => {
    const { password } = props;

    const smallLetter = /[a-z]/;
    const digit = /\d+/;
    const capitalLetter = /[A-Z]/;
    // const othersCharacters = /.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/;
    const specialCharacters = /[!,@,#,$,%,^,&,*,\(\),_,\+=\{\}\[\]|;:'",<>\?\/\\-]/;
    let passwordStrongLevel = 1;
    let passwordStrengthText = '';
    let passwordStrengthWidth = 0;
    let passwordStrengthColor = '';

    const lengthOfPassword = password.length;
    const valueOfPassword = password;

    if (valueOfPassword === "") {
        passwordStrengthText = '';
        passwordStrongLevel = 0;
    }

    // if (lengthOfPassword > 0) {
    //
    //     if (lengthOfPassword <= 3 && (valueOfPassword.match(smallLetter) || valueOfPassword.match(digit) || valueOfPassword.match(othersCharacters) || valueOfPassword.match(capitalLetter))) {
    //         passwordStrongLevel = 1;
    //     }
    //     if (lengthOfPassword >= 5 && ((valueOfPassword.match(smallLetter) && valueOfPassword.match(digit)) || (valueOfPassword.match(smallLetter) && valueOfPassword.match(capitalLetter)) || (valueOfPassword.match(digit) && valueOfPassword.match(othersCharacters)) || (valueOfPassword.match(digit) && valueOfPassword.match(capitalLetter)) || (valueOfPassword.match(capitalLetter) && valueOfPassword.match(othersCharacters)) || (valueOfPassword.match(smallLetter) && valueOfPassword.match(othersCharacters)))) {
    //         passwordStrongLevel = 2;
    //     }
    //     if (lengthOfPassword >= 6 && ((valueOfPassword.match(smallLetter) && valueOfPassword.match(digit) && valueOfPassword.match(othersCharacters)) || (valueOfPassword.match(smallLetter) && valueOfPassword.match(othersCharacters) && valueOfPassword.match(capitalLetter)) || (valueOfPassword.match(digit) && valueOfPassword.match(capitalLetter) && valueOfPassword.match(othersCharacters)) || (valueOfPassword.match(digit) && valueOfPassword.match(capitalLetter) && valueOfPassword.match(smallLetter)))) {
    //         passwordStrongLevel = 3;
    //     }
    //     if (lengthOfPassword >= 8 && valueOfPassword.match(smallLetter) && valueOfPassword.match(digit) && valueOfPassword.match(othersCharacters) && valueOfPassword.match(capitalLetter)) {
    //         passwordStrongLevel = 4;
    //     }
    // }

    const hasSmallLetter = valueOfPassword.match(smallLetter) !== null;
    const hasCapitalLetter = valueOfPassword.match(capitalLetter) !== null;
    const hasDigit = valueOfPassword.match(digit) !== null;
    const hasSpecialCharacters = valueOfPassword.match(specialCharacters) !== null;

    if (lengthOfPassword > 0) {
        const characterTypesCount =
            [hasSmallLetter, hasCapitalLetter, hasDigit, hasSpecialCharacters].filter(item => item).length;

        if (lengthOfPassword >= 10 && characterTypesCount === 4) {
            passwordStrongLevel = 4;
        } else if (lengthOfPassword >= 8 && characterTypesCount >= 4) {
            passwordStrongLevel = 3;
        } else if (lengthOfPassword >= 4 && characterTypesCount >= 2) {
            passwordStrongLevel = 2;
        } else {
            passwordStrongLevel = 1;
        }
    }

    // if (passwordStrongLevel === 1) {
    //     passwordStrengthText = "Weak";
    // } else if (passwordStrongLevel === 2) {
    //     passwordStrengthText = "Medium";
    // } else if (passwordStrongLevel === 3) {
    //     passwordStrengthText = "Strong";
    // } else if (passwordStrongLevel === 4) {
    //     passwordStrengthText = "Very Strong";
    // }

    const passwordStrengthMap = {
        1: { text: "Weak", colorClass: 'weak-level-password' },
        2: { text: "Medium", colorClass: 'medium-level-password' },
        3: { text: "Strong", colorClass: 'strong-level-password' },
        4: { text: "Very Strong", colorClass: 'very-strong-level-password' }
    };

    passwordStrengthText = passwordStrengthMap[passwordStrongLevel]?.text || "Unknown";
    passwordStrengthColor = passwordStrengthMap[passwordStrongLevel]?.colorClass || 'default-password-color';

    // const setStylePasswordColor = (passwordStrongLevel) => {
        // if (passwordStrongLevel === 1) {
        //     passwordStrengthColor = 'weak-level-password';
        // } else if (passwordStrongLevel === 2) {
        //     passwordStrengthColor = 'medium-level-password';
        // } else if (passwordStrongLevel === 3) {
        //     passwordStrengthColor = 'strong-level-password';
        // } else if (passwordStrongLevel === 4) {
        //     passwordStrengthColor = 'very-strong-level-password';
        // }
    // };

    // setStylePasswordColor(passwordStrongLevel);
    passwordStrengthWidth = passwordStrongLevel * 24;

    const getStylePasswordStrength = () => {
        return "password-strength-level";
    };

    return (
        <>
            <div className={valueOfPassword ? getStylePasswordStrength() : 'd-none'}>
                <h6 className="kt-ml-5 password_strength_title">{passwordStrengthText}</h6>
                <span style={{ width: `${passwordStrengthWidth}%` }}
                      className={`${passwordStrengthColor} password-level`}/>
            </div>
        </>
    )
});

export default PasswordStrengthChecker;