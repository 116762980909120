import React, { useEffect, useState } from "react";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import SweetAlert from "react-bootstrap-sweetalert";
import { Trans } from "react-i18next";
import cloneDeep from "lodash/cloneDeep";

import GLOBAL from "../../global";
import CONSTANT from "../../constants/Constant";
import { _getHourMinValuesOptionsMinValues } from "../../utils/Utils";

import {
    notificationReminders,
    repeatAtExercisePlanValues
} from "../../utils/DefaultInitialization";
import exerciseService from "../../services/ExerciseService";

const ExercisesPlanningForm = (props) => {
    const { exercise, onSave } = props;
    const { ExercisePlan } = exercise
    const [alertMessage, setAlertMessage] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [alertType, setAlertType] = useState(CONSTANT.ALERT_TYPE.INFO);
    let onConfirmAlert = () => {
    };
    const [onConfirmAlertFunction, setOnConfirmAlertFunction] = useState(() => () => {
    });
    const currentDate = new Date();
    const [startDate, setStartDate] = useState(ExercisePlan?.StartDate ? new Date(ExercisePlan?.StartDate) : new Date());
    const [startDateError, setStartDateError] = useState('');
    const [endDate, setEndDate] = useState(ExercisePlan?.EndDate ? new Date(ExercisePlan?.EndDate) : new Date(currentDate.setDate(currentDate.getDate() + 2)));
    const [endDateError, setEndDateError] = useState('');
    const [performingTime, setPerformingTime] = useState(ExercisePlan?.PerformingTime ? new Date(ExercisePlan?.PerformingTime) : null);
    const [performingTimeError, setPerformingTimeError] = useState('');
    const [reminders, setReminders] = useState(_getHourMinValuesOptionsMinValues(ExercisePlan?.RemindMeBefore) || []);
    const [reminderError, setReminderError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [repeatAtExercisePlan, setRepeatAtExercisePlan] = useState(cloneDeep(repeatAtExercisePlanValues()));
    const notificationReminderOptions = _getHourMinValuesOptionsMinValues(notificationReminders);

    useEffect(() => {
        if (ExercisePlan?.RepeatAt?.length > 0) {
            ExercisePlan.RepeatAt.forEach((item) => {
                item.Time = new Date(item.Time);
            })
            setRepeatAtExercisePlan(ExercisePlan?.RepeatAt);
        }

    }, [ExercisePlan])

    const onClickSubmit = async () => {
        repeatAtExercisePlan.forEach((item) => item.Error = '');
        _checkStartValidation();
        _checkEndValidation();
        // _checkPerformingTimeValidation();
        // _checkRemindersValidation();

        if (!startDate || !endDate) {
            return;
        }

        const repeatAt = repeatAtExercisePlan.map((item) => {
            return {
                DayName: item.DayName,
                Time: new Date(item.Time).toUTCString(),
                IsRepeat: item.IsRepeat
            }
        });

        const payload = {
            ExerciseId: exercise.ExerciseId,
            StartDate: startDate,
            EndDate: endDate,
            PerformingTime: new Date(performingTime).toUTCString(),
            RepeatAt: repeatAt,
            RemindMeBefore: reminders?.map((item) => item?.value)
        }

        setIsSubmitting(true);
        const response = await exerciseService.saveExercisesPlan(payload);
        const { success, error } = response;
        setIsSubmitting(false);

        if (!success) {
            console.error(error);
            setAlertTitle(CONSTANT.ALERT_TITLE.DANGER);
            setAlertType(CONSTANT.ALERT_TYPE.DANGER);
            setAlertMessage('Data is not saved. Please try again...');
            return;
        }

        setAlertTitle(CONSTANT.ALERT_TITLE.SUCCESS);
        setAlertType(CONSTANT.ALERT_TYPE.SUCCESS);
        setAlertMessage('Successfully Saved');
        setOnConfirmAlertFunction(() => onSave);
    };

    onConfirmAlert = () => {
        setAlertMessage('');
        setAlertTitle('');
        setAlertType(CONSTANT.ALERT_TYPE.INFO);
    }

    const onChangeStartDate = (value) => {
        setStartDate(value);
        _checkStartValidation(value);
    }

    const onChangeEndDate = (value) => {
        setEndDate(value);
        _checkEndValidation(value);
    }

    const onChangePerformingTime = (value) => {
        let date;
        if (value?.length > 4) {
            date = new Date();
            date.setHours(value?.substr(0, 2), value?.substr(3, 4));
        }
        setPerformingTime(date);
        _checkPerformingTimeValidation(value);
    }

    const onChangeRemindMeBefore = (value) => {
        setReminders(value);
        _checkRemindersValidation(value);
    }

    const _checkStartValidation = (startDateValue = startDate) => {
        if (!startDateValue) {
            setStartDateError('This field is required');
        } else {
            setStartDateError('');
        }
    }

    const _checkEndValidation = (endDateValue = endDate) => {
        if (!endDateValue) {
            setEndDateError('This field is required');
        } else {
            setEndDateError('');
        }
    }

    const _checkPerformingTimeValidation = (performingTimeValue = performingTime) => {
        if (!performingTimeValue) {
            setPerformingTimeError('This field is required');
        } else {
            setPerformingTimeError('');
        }
    }

    const _checkRemindersValidation = (reminderValues = reminders) => {
        if (reminderValues.length === 0) {
            setReminderError('This field is required');
        } else {
            setReminderError('');
        }
    }

    const onChangeRepeatAt = (item, value) => {
        const plan = repeatAtExercisePlan.find((plan) => plan.DayName === item.DayName);
        if (plan) {

            if (value) {
                plan.Error = '';
                const date = new Date();
                date.setHours(value?.substr(0, 2), value?.substr(3, 4));
                plan.Time = date;
            } else {
                plan.Time = '';
            }
        }
        setRepeatAtExercisePlan([...repeatAtExercisePlan]);
    }

    const onChangeRepeatAtCheckbox = (item) => {
        const plan = repeatAtExercisePlan.find((plan) => plan.DayName === item.DayName);
        if (plan) {
            if (item.Time) {
                plan.IsRepeat = !plan.IsRepeat;
            } else {
                plan.Error = 'Please set time';
            }
        }
        setRepeatAtExercisePlan([...repeatAtExercisePlan]);
    }

    return (
        <>
            <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
                <div className="kt-widget kt-widget--user-profile-3 text-center kt-mb-10">
                    <h5>{exercise.ExerciseName}</h5>
                    <div className="kt-widget__top">
                        <div className="kt-widget__media d-flex justify-content-end w-60">
                            <img src={exercise.ExerciseImageUrl} alt="ExerciseAlt"/>
                        </div>
                    </div>
                </div>
                <div className="kt-login__container">
                    <div className="kt-login__signin">
                        <div className="kt-form">
                            <div className='d-flex justify-content-between'>
                                <div className="form-group w-49">
                                    <label className='kt-mr-5'>Start Date</label>
                                    <DatePicker
                                        closeOnScroll={true}
                                        fixedHeight={true}
                                        showTimeSelect={true}
                                        timeFormat="h:mm aa"
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        className="form-control"
                                        wrapperClassName="d-flex"
                                        selected={startDate}
                                        onChange={onChangeStartDate}
                                        name='StartDate'
                                    />
                                    {startDateError && (
                                        <div className="invalid-feedback d-block">{startDateError}</div>
                                    )}
                                </div>
                                <div className="form-group w-49">
                                    <label className='kt-mr-5'>End Date</label>
                                    <DatePicker
                                        closeOnScroll={true}
                                        fixedHeight={true}
                                        showTimeSelect={true}
                                        timeFormat="h:mm aa"
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        className="form-control"
                                        wrapperClassName="d-flex"
                                        selected={endDate}
                                        onChange={onChangeEndDate}
                                        name='EndDate'
                                    />
                                    {endDateError && (
                                        <div className="invalid-feedback d-block">{endDateError}</div>
                                    )}
                                </div>
                            </div>
                            {/*<div className="form-group">*/}
                            {/*    <label>Performing Times</label>*/}
                            {/*    <div className="kt-input-icon kt-input-icon--right">*/}
                            {/*        <TimePicker*/}
                            {/*            className='w-100 min-h-40 border-0'*/}
                            {/*            onChange={onChangePerformingTime}*/}
                            {/*            value={performingTime}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*    {performingTimeError && (*/}
                            {/*        <div className="invalid-feedback d-block">{performingTimeError}</div>*/}
                            {/*    )}*/}
                            {/*</div>*/}
                            {/*<div className="form-group">*/}
                            {/*    <label>Repeat at</label>*/}
                            {/*    {repeatAtExercisePlan.map((item, index) => {*/}
                            {/*        return (*/}
                            {/*            <div key={index} className="form-group d-flex kt-mb-5">*/}
                            {/*                <label className='w-25 align-self-center kt-mr-10'>{item.DayName}</label>*/}
                            {/*                <div className='d-flex flex-column w-100'>*/}
                            {/*                    <TimePicker*/}
                            {/*                        className='w-100 min-h-40 border-0'*/}
                            {/*                        onChange={(value) => onChangeRepeatAt(item, value)}*/}
                            {/*                        value={item.Time}*/}
                            {/*                    />*/}
                            {/*                    {item.Error && (*/}
                            {/*                        <div className="invalid-feedback d-block">{item.Error}</div>*/}
                            {/*                    )}*/}
                            {/*                </div>*/}
                            {/*                <label*/}
                            {/*                    className="kt-checkbox kt-checkbox--primary kt-mt-10 kt-ml-10 kt-padding-l-20">*/}
                            {/*                    <input*/}
                            {/*                        type="checkbox"*/}
                            {/*                        checked={item.IsRepeat}*/}
                            {/*                        onChange={() => onChangeRepeatAtCheckbox(item)}/>*/}
                            {/*                    <span/>*/}
                            {/*                </label>*/}
                            {/*            </div>*/}
                            {/*        )*/}
                            {/*    })}*/}
                            {/*</div>*/}
                            {/*<div className="form-group">*/}
                            {/*    <label>Remind me before</label>*/}
                            {/*    <Select*/}
                            {/*        onChange={onChangeRemindMeBefore}*/}
                            {/*        closeMenuOnSelect={false}*/}
                            {/*        defaultValue={_getHourMinValuesOptionsMinValues(ExercisePlan?.RemindMeBefore)}*/}
                            {/*        isMulti*/}
                            {/*        options={notificationReminderOptions}*/}
                            {/*        styles={{ height: '40px' }}*/}
                            {/*    />*/}
                            {/*    {reminderError && (*/}
                            {/*        <div className="invalid-feedback d-block">{reminderError}</div>*/}
                            {/*    )}*/}
                            {/*</div>*/}
                            <div className="kt-login__actions kt-pt15 kt-mb-15">
                                <button
                                    onClick={onClickSubmit}
                                    disabled={isSubmitting}
                                    type="submit"
                                    className="btn btn-brand btn-elevate kt-login__btn-primary float-right">
                                    {isSubmitting ? (
                                        <>
                                            <i className="fa fa-spinner fa-spin"/>&nbsp;&nbsp;
                                            <Trans i18nKey="common.message.info.please-wait"/>
                                        </>
                                    ) : (
                                        <>
                                            <i className="fa fa-times"/>
                                            <Trans i18nKey="common.form.save-&-close"/>
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SweetAlert
                show={alertMessage !== ""}
                title={alertTitle}
                type={alertType}
                onConfirm={() => {
                    onConfirmAlertFunction();
                    onConfirmAlert();
                }}
                closeOnClickOutside={false}>
                {alertMessage}
            </SweetAlert>
        </>
    )
}

export default ExercisesPlanningForm
