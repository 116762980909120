export const initialState = {
    resumeChats: {
        isLoading: false
    },
    botData: {
        chats: [],
        testId: "",
        patientId: "",
        invitationTypeName: "",
        testIdToResumeChat: "",
        invitationId: "",
        isFollowup: false,
        sessionId: "",
        isADLCompleted: false,
        isBiometricCompleted: false,
        isChiefComplaintCompleted: false,
        isDemographicCompleted: false,
        isMedicalHistoryCompleted: false,
        currentChatIndex: -1,
        prevChatIndex: -2,
        botInfo: {},
        bodyRegionList: [],
        selectedResponseOfCautionQuestion: {},
        questionHint: "",
        questionHintTitle: "",
        meetingStart: "",
        meetingEnd: "",
    },
    chatBotUIState: {
        initChatBot: false,
        isFetchingChatQuestion: false,
        showWelcomePage: true,
        showPopUpWarningForYesAtCautionQuestion: false,
        chatBotErrorMsg: "",
        botProgressbarValue: 0,
        showConfirmationPopupToCloseBot: false,
        showChatBot: false,
        openChiefComplaintModal: false,
        isLoadingCC: false,
        openSelfAssessmentModal: false,
        questionHintModal: false,
        isOkButtonLoading: false,
    }
}