import { plainToClass } from "class-transformer";

const success = (response, Model) => {
    return {
        data: Model ? toModel(Model, response["Message"]) : response["Message"] || response["data"] || response["Data"],
        success: true
    };
};

const error = (response) => {
    // const requestUrl = response?.response?.config?.url;
    // const isLoginEndpoint = requestUrl?.includes("Login");

    // if (response?.response?.status === 401 && !isLoginEndpoint) {
    //     toast.error("Authentication failed. Please login.");
    //     redirectTo("/");
    // }

    return { data: [], success: false, error: response?.response?.data?.Message || response?.response?.data?.StatusMessage || response["Message"] || response["message"] || response["ErrorMessages"] };
};

const toModel = (Model, data) => {
    return plainToClass(Model, data);
};

export { success, error };
