import React from 'react'

export default function Progressbar({ progress, top = "0" }) {
    return (
        <div
            className={`d-flex position-absolute w-100 flex-column justify-content-end kt-mr-5`}
            style={
                top === "0" ? { top: "-2%"} : { top: top }
            }
        >
            <div className="progress--bar align-self-center w-100 bg-color-light-gray">
                <div
                    className="progress-bar bg-color-bright-light-green"
                    role="progressbar"
                    style={{ width: progress + '%' }}
                    aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100">

                </div>

                <div className="progress-count" style={{ left: (progress - 1)+ "%"}}>
                    <span className="progress-count_container">
                        {progress}%
                    </span>

                </div>
            </div>
        </div>
    )
}