import React, {useEffect, useState, useRef, useContext} from "react";
import OT from "@opentok/client";
import MovementGuideline from "./MovementGuideline";
import AppContext from "../../../contexts/AppContext";
import TelemedicineVideoToolbar from "./TelemedicineVideoToolbar";
import { toast } from 'react-toastify';
import MovementGuidelineAllModal from "./MovementGuidelineAllModal";
import SweetAlert from "react-bootstrap-sweetalert";
import useTelemedicineDetails from "./useTelemedicineDetails";

const TelemedicineDetails = ({  biometricExercisesGuideline, isSelectedTrainingMovementGide, setIsSelectedTrainingMovementGide, bodyRegionList }) => {
    const {
        publisherVideoRef,
        errorMsgRef,
        isVideoHidden,
        isAudioMuted,
        subscriberCount,
        selectedExercise,
        sweetAlertErrorMsg,
        initializeSession,
        drawCtx,
        cleanUpOnCloseModal,
        onClickMuteUnmuteVideo,
        onClickMuteUnmuteAudio,
        onClickCameraSwitch,
        onClickOkBtn
    } = useTelemedicineDetails(biometricExercisesGuideline);

    useEffect(()=> {
        initializeSession();
        drawCtx();

        return () => {
            cleanUpOnCloseModal();
        }
    },[]);


    return (
        <div className="row position-relative telemedicine-video-guideline-container">
            <>
                <div className="col-md-12 col-lg-9 telemedicine-video-guideline-container__video-continer">
                    <div id="videos">
                        <div id="subscriber"></div>
                        <div id="publisher"></div>
                    </div>

                    <canvas ref={publisherVideoRef} className="telemedicine-video-guideline-container__canvas"/>
                    <p className="telemedicine-video-guideline-container__message">{subscriberCount <= 0 ? "Please wait for the provider to join." : ""}</p>

                    <TelemedicineVideoToolbar
                        onClicks={{
                            onClickMuteUnmuteVideo,
                            onClickMuteUnmuteAudio,
                            onClickCameraSwitch
                        }}
                        controlStates={{
                            isVideoHidden,
                            isAudioMuted
                        }}
                    />
                </div>

                <div className="col-md-12 col-lg-3 telemedicine-video-guideline-container__guideline-container position-static">
                    <MovementGuideline selectedExercise={selectedExercise} isFromMovementGuidelineModal={false}/>
                </div>
            </>

            {isSelectedTrainingMovementGide && (
                <MovementGuidelineAllModal
                    isSelectedTrainingMovementGide={isSelectedTrainingMovementGide}
                    setIsSelectedTrainingMovementGide={setIsSelectedTrainingMovementGide}
                    // keys={keys}
                    bodyRegionList={bodyRegionList}
                    biometricExercisesGuideline={biometricExercisesGuideline}
                />
            )}

            <SweetAlert
                confirmBtnCssClass="btn btn-sm btn-success"
                showConfirm= {true}
                showCancel={false}
                show={sweetAlertErrorMsg}
                title=""
                type="info"
                onConfirm={onClickOkBtn}
                confirmBtnText="Ok"
                closeOnClickOutside={false}>
                <div className='tele_sweetalert_message'>
                    {errorMsgRef.current}
                </div>
            </SweetAlert>
        </div>
    );
}

export default TelemedicineDetails;
