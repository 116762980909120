import CONSTANT from "../../../constants/Constant";
import {convertRgbToRgba, getArrayFromObject} from "../../../utils/Utils";
import deleteBtn from "../../../assets/img/chiefComplaint/delete.svg";
import React, {useRef, useState} from "react";
import {toast} from "react-toastify";
import ConfirmationDialog from "../Popups/ConfirmationDialog";

const SelectCCPointsList = ({ vasQuestion, side, clearACCPoint, clearALLCCPoints, isReportAvailable = false, selectedBodyLocationRef, setShowQuestionModal }) => {
    const [pointNameToDelete, setPointNameToDelete] = useState('');
    const [deletingAllPointsOfSide, setDeletingAllPointsOfSide] = useState('');
    const [isShowConfirmation, setIsShowConfirmation] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const conformationAlertContentRef = useRef({});
    const selectedCCPointRef = useRef({});

    const pointsOfSide = getArrayFromObject(vasQuestion).filter(point => point.side === CONSTANT.SIDE[side].key);

    const handleClickClearACCPoint = async (e, point) => {
        e.preventDefault();

        if(!point?.bodyLocation) {
            toast.error('Body location missing!');
            return;
        }

        setIsShowConfirmation(true);
        conformationAlertContentRef.current.text = `Do you want to clear ${point?.name} ?`;
        conformationAlertContentRef.current.onClickYes = handleClearACCPoint;
        conformationAlertContentRef.current.headerText = 'Alert!';
        selectedCCPointRef.current = point;
    }

    const handleClearACCPoint = async () => {
        if(!selectedCCPointRef.current?.bodyLocation) {
            toast.error('Body location missing!');
            return;
        }

        setIsDeleting(true)
        setPointNameToDelete(selectedCCPointRef.current?.bodyLocation);
        await clearACCPoint(selectedCCPointRef.current);
        setPointNameToDelete('');
        setIsShowConfirmation(false);
        setIsDeleting(false)
    }

    // const handleClickClearAllCCPointOfASide = async (e) => {
    //     e.preventDefault();
    //
    //     if(pointsOfSide.length <= 0) {
    //         toast.error('Select at least one cc point');
    //         return;
    //     }
    //
    //     setIsShowConfirmation(true);
    //     conformationAlertContentRef.current.text = `Are you sure you want to clear all your Chief Complaints?`;
    //     conformationAlertContentRef.current.onClickYes = handleClearAllCCPointOfASide;
    //     conformationAlertContentRef.current.headerText = 'Alert!';
    //     setDeletingAllPointsOfSide(side);
    // }

    // const handleClearAllCCPointOfASide = async () => {
    //     if(pointsOfSide.length <= 0) {
    //         toast.error('Select at least one cc point');
    //         return;
    //     }
    //
    //     setIsDeleting(true)
    //     setDeletingAllPointsOfSide(side);
    //     await clearALLCCPoints(pointsOfSide);
    //     setDeletingAllPointsOfSide('');
    //     setIsShowConfirmation(false);
    //     setIsDeleting(false);
    // }

    const onClickCCPointName = (point) => {
        selectedBodyLocationRef.current = {bodyLocation: point?.bodyLocation, name: point?.name, side: point?.side}
        setShowQuestionModal(true);
    }


    return (
        <div className="selected_point_list">
            {pointsOfSide.length <= 0 ? (
                <div>
                    <p className="text-align-center body-point-text__title">{side}</p>
                    <p className="body-point-text__description">You did not mention any chief complaints. Select body to add chief complaints</p>
                </div>
            ): (
                <div>
                    <p className="text-align-center body-point-text__title">{side}</p>
                    {pointsOfSide.map(point => (
                        <div key={point.bodyLocation}>
                            <div
                                className="body-point-text__description rounded-sm mb-3 d-flex justify-content-between align-items-center"
                                style={{color: point.color, padding: "1rem", background: convertRgbToRgba(point.color)}}
                            >
                                <span
                                    onClick={()=> onClickCCPointName(point)}
                                    className="cursor-pointer"
                                >
                                    {point.name}
                                </span>
                                <button
                                    className="border-0 bg-transparent"
                                >
                                    {!isReportAvailable
                                        ? (
                                            <>
                                                {((pointNameToDelete === point.bodyLocation) && isDeleting)
                                                    ? <i className="fa fa-spinner fa-spin mr-3"/>
                                                    : <img
                                                        onClick={async (e) => { e.preventDefault();await handleClickClearACCPoint(e, point)}}
                                                        src={deleteBtn} alt="delete"
                                                        style={{cursor: 'pointer'}}
                                                    />
                                                }
                                            </>
                                        ): ""
                                    }

                                </button>
                            </div>
                        </div>
                    ))}

                    {/*<button*/}
                    {/*    className="bg-transparent body-point-text__clear d-flex justify-content-between w-full"*/}
                    {/*    onClick={async (e) => { e.preventDefault(); await handleClickClearAllCCPointOfASide(e)}}*/}
                    {/*    disabled={isReportAvailable}*/}
                    {/*>*/}
                    {/*    Clear All*/}
                    {/*    {((deletingAllPointsOfSide === side) && isDeleting ) ? <i className="fa fa-spinner fa-spin ml-3"/> : ""}*/}
                    {/*</button>*/}
                </div>
            )}

            {isShowConfirmation && (
              <ConfirmationDialog
                headerText={conformationAlertContentRef.current.headerText}
                showLoadingInConform={isDeleting}
                confirmText="Yes"
                cancelText="No"
                show={isShowConfirmation}
                onClickHeaderClose={() => setIsShowConfirmation(prevState => !prevState)}
                toggleDialog={() => setIsShowConfirmation(prevState => !prevState)}
                onConfirm={conformationAlertContentRef.current?.onClickYes}
              >
                  {conformationAlertContentRef.current?.text}
              </ConfirmationDialog>
            )}
        </div>
    )
}

export default SelectCCPointsList;
