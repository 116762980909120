import CONSTANT from "../../constants/Constant";
import { calculateAge, dateValidator } from "../../utils/Utils";
import answerValidationMessagesConstant from "./answerValidationMessagesConstant";

export const answerValidationRuleForSpecificQuestionId = {
    [CONSTANT.CHATBOT_ACCOUNT.HEIGHT]: (value) => {
        const height = +value;
        if (isNaN(value)) {
            return {
                valid: false,
                message: answerValidationMessagesConstant.HEIGHT.INVALID,
            };
        } else if (typeof height === "number" && height % 1 !== 0) {
            return {
                valid: false,
                message: answerValidationMessagesConstant.HEIGHT.NOT_INTEGER,
            };
        } else if ((typeof height === "number") && (height < 25 || height > 107)) {
            return {
                valid: false,
                message: answerValidationMessagesConstant.HEIGHT.OUT_OF_RANGE,
            };
        }
        return { valid: true, message: "" };
    },
    [CONSTANT.CHATBOT_ACCOUNT.WEIGHT]: (value) => {
        const weight = +value;
        if (isNaN(value)) {
            return {
                valid: false,
                message: answerValidationMessagesConstant.WEIGHT.INVALID,
            };
        } else if (typeof value !== "number" && value.trim() === '') {
            return {
                valid: false,
                message: answerValidationMessagesConstant.WEIGHT.REQUIRED,
            };
        } else if (weight % 1 !== 0) {
            return {
                valid: false,
                message: answerValidationMessagesConstant.WEIGHT.NOT_INTEGER,
            };
        } else if (weight < 30 || weight > 500) {
            return {
                valid: false,
                message: answerValidationMessagesConstant.WEIGHT.OUT_OF_RANGE,
            };
        }
        return { valid: true, message: "" };
    },
    [CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH]: (value) => {
        const isDateValid = dateValidator(value);
        if (!isDateValid.valid) {
            return isDateValid;
        }
        const age = calculateAge(value);
        if (age < 7 || age > 120) {
            return {
                valid: false,
                message: answerValidationMessagesConstant.DATE_OF_BIRTH.OUT_OF_RANGE,
            };
        }
        return { valid: true, message: "" };
    },
    [CONSTANT.CHATBOT_ACCOUNT.EMAIL]: (value) => {
        if (!value || value.trim() === '') {
            return {
                valid: false,
                message: answerValidationMessagesConstant.EMAIL.REQUIRED,
            };
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
            return {
                valid: false,
                message: answerValidationMessagesConstant.EMAIL.INVALID,
            };
        }
        return { valid: true, message: "" };
    },
    [CONSTANT.CHATBOT_ACCOUNT.FIRST_NAME]: (value) => {
        if (!value || value.trim() === '') {
            return {
                valid: false,
                message: answerValidationMessagesConstant.FIRST_AND_LAST_NAME.REQUIRED,
            };
        } else if (value.trim().length < 2) {
            return {
                valid: false,
                message: answerValidationMessagesConstant.FIRST_AND_LAST_NAME.MIN_LENGTH,
            };
        }
        return { valid: true, message: "" };
    },
    // Height in feet validation
    [CONSTANT.CHATBOT_ACCOUNT.HEIGHT_IN_FEET]: (value) => {
        const feet = +value;

        if (isNaN(value)) {
            return {
                valid: false,
                message: answerValidationMessagesConstant.HEIGHT_FEET.INVALID,
            };
        } else if (typeof value !== "number" && value.trim() === '') {
            return {
                valid: false,
                message: answerValidationMessagesConstant.HEIGHT_FEET.REQUIRED,
            };
        }
        else if (feet % 1 !== 0) {
            return {
                valid: false,
                message: answerValidationMessagesConstant.HEIGHT_FEET.NOT_INTEGER,
            };
        } else if (feet < 2 || feet > 8) {
            return {
                valid: false,
                message: answerValidationMessagesConstant.HEIGHT_FEET.OUT_OF_RANGE,
            };
        }
        return { valid: true, message: "" };
    },
    // Height in inches validation
    [CONSTANT.CHATBOT_ACCOUNT.HEIGHT_IN_INCH]: (value) => {
        let inchValue = value?.value;
        let feetValue = value?.feet;

        const inch = +inchValue;
        if (isNaN(inchValue)) {
            return {
                valid: false,
                message: answerValidationMessagesConstant.HEIGHT_INCH.INVALID,
            };
        }
        else if (typeof inchValue !== "number" && inchValue.trim() === '') {
            return {
                valid: false,
                message: answerValidationMessagesConstant.HEIGHT_INCH.REQUIRED,
            };
        }
        else if (inch % 1 !== 0) {
            return {
                valid: false,
                message: answerValidationMessagesConstant.HEIGHT_INCH.NOT_INTEGER,
            };
        } else if(!isNaN(feetValue) && +feetValue === 2 && (inch < 1 || inch > 11)) {
            return {
                valid: false,
                message: answerValidationMessagesConstant.HEIGHT_INCH.OUT_OF_RANGE_FEET,
            }
        } else if (inch < 0 || inch > 11) {
            return {
                valid: false,
                message: answerValidationMessagesConstant.HEIGHT_INCH.OUT_OF_RANGE,
            };
        }
        return { valid: true, message: "" };
    },
    // Password validation
    [CONSTANT.CHATBOT_ACCOUNT.PASSWORD]: (value) => {
        if (!value || value.trim() === '') {
            return {
                valid: false,
                message: answerValidationMessagesConstant.PASSWORD.REQUIRED,
            };
        }

        let message = '';
         if (!/[A-Z]/.test(value)) {
            message = answerValidationMessagesConstant.PASSWORD.MUST_HAVE_UPPERCASE;
        } else if (!/[a-z]/.test(value)) {
            message = answerValidationMessagesConstant.PASSWORD.MUST_HAVE_LOWERCASE;
        } else if (!/\d/.test(value)) {
            message = answerValidationMessagesConstant.PASSWORD.MUST_HAVE_NUMBER;
        } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
            message = answerValidationMessagesConstant.PASSWORD.MUST_HAVE_SPECIAL_CHAR;
        } else if (value.length > 0 && value.length < 8) {
             message = answerValidationMessagesConstant.PASSWORD.TOO_SHORT;
         }

        if (message) {
            return { valid: false, message };
        }
        return { valid: true, message: "" };
    },
    // Confirm Password validation
    [CONSTANT.CHATBOT_ACCOUNT.CONFIRM_PASSWORD]: ({ value, password }) => {
        if (!value || value.trim() === '') {
            return {
                valid: false,
                message: answerValidationMessagesConstant.CONFIRM_PASSWORD.REQUIRED,
            };
        }
        if (value !== password) {
            return {
                valid: false,
                message: answerValidationMessagesConstant.CONFIRM_PASSWORD.MISMATCH,
            };
        }

        return { valid: true, message: "" };
    },
    [CONSTANT.CHATBOT_ACCOUNT.GENDER_AS_SELECT]: (value) => {
        const genders = ['female', 'male'];
        if (!value || value.trim() === '') {
            return {
                valid: false,
                message: answerValidationMessagesConstant.GENDER_AS_SELECT.REQUIRED,
            }
        }

        if(!genders.includes(value.toLowerCase())) {
            return {
                valid: false,
                message: answerValidationMessagesConstant.GENDER_AS_SELECT.INVALID_OPTION,
            }
        }

        return { valid: true, message: "" };
    },
    [CONSTANT.CHATBOT_ACCOUNT.DOMINANT_HAND_AS_SELECT]: (value) => {
        const hands = ['left', 'right'];
        if (!value || value.trim() === '') {
            return {
                valid: false,
                message: answerValidationMessagesConstant.DOMINANT_HAND_AS_SELECT.REQUIRED,
            }
        }

        if(!hands.includes(value.toLowerCase())) {
            return {
                valid: false,
                message: answerValidationMessagesConstant.DOMINANT_HAND_AS_SELECT.INVALID_OPTION,
            }
        }

        return { valid: true, message: "" };
    },
    [CONSTANT.CHATBOT_ACCOUNT.TERMS_AND_CONDITIONS]: (value) => {
        if (!value) {
            return {
                valid: false,
                message: answerValidationMessagesConstant.TERMS_AND_CONDITIONS.REQUIRED,
            };
        }
        return { valid: true, message: "" };
    }
};
