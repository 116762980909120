import { useContext, useRef, useState } from "react";
import OT from "@opentok/client";
import { toast } from "react-toastify";
import AppContext from "../../../contexts/AppContext";

const useTelemedicineDetails = (biometricExercisesGuideline) => {
    const { telemedicine, toggleShowTelemedModal } = useContext(AppContext);
    const { telemedicineKeys, onCloseCallback } = telemedicine;

    const [selectedExercise, setSelectedExercise] = useState({});
    const [isAudioMuted, setIsAudioMuted] = useState(false);
    const [isVideoHidden, setIsVideoHidden] = useState(false);
    const [sweetAlertErrorMsg, setSweetAlertErrorMsg] = useState(false);
    const [subscriberCount, setSubscriberCount] = useState(0);

    let sessionRef = useRef(null);
    const publisherVideoRef = useRef(null);
    let publisherRef = useRef(null);
    let subscriberRef = useRef(null);
    let errorMsgRef = useRef("");
    let isCameraAccessDeniedRef = useRef(false);

    const initializeSession = () => {
        try {
            if(sessionRef.current) {
                sessionRef.current.off('streamCreated');
                sessionRef.current.off('sessionDisconnected');
                sessionRef.current.off('signal');
                sessionRef.current.disconnect();
            }
            if (publisherRef.current) {
                publisherRef.current.destroy();
                publisherRef.current = null;
            }

            if (subscriberRef.current) {
                subscriberRef.current.destroy();
                subscriberRef.current = null;
            }

            sessionRef.current = null;

            sessionRef.current = OT.initSession(telemedicineKeys?.opentokApiKey, telemedicineKeys?.opentokSessionId);

            sessionRef.current.on('streamCreated', (event) => {
                const subscriberOptions = {
                    insertMode: 'append',
                    width: '200px',
                    height: '200px'
                };
                subscriberRef.current = sessionRef.current.subscribe(event.stream, 'subscriber', subscriberOptions, handleError);
                if(subscriberRef.current) {
                    subscriberRef.current.on('error', function(err) {
                        handleError(err);
                    });
                    setSubscriberCount(prevCount => prevCount + 1);
                }
            });

            sessionRef.current.on('streamDestroyed', (event) => {
                setSubscriberCount(prevCount => prevCount - 1);
            });

            sessionRef.current.on('sessionDisconnected', (event) => {
                reconnect();
            })

            sessionRef.current.on("signal", function(event) {
                if(event && event.data){
                    let signalData = JSON.parse(event.data);
                    if(signalData.data){
                        let data = JSON.parse(signalData.data);
                        // if(data?.exerciseName && selectedExercise?.exerciseName === data.exerciseName) {
                        //     return;
                        // }

                        if(data?.exerciseName && biometricExercisesGuideline && biometricExercisesGuideline.length > 0){
                            let exercise = biometricExercisesGuideline.find(x => x.ExerciseValue === data.exerciseName);
                            if(exercise) {
                                let selectedExObj = {
                                    ExerciseName: exercise.ExerciseName,
                                    PatientGuidelineContent: exercise.PatientGuidelineContent,
                                    ExerciseMediaUrlPatient: exercise.ExerciseMediaUrlPatient
                                }
                                setSelectedExercise(selectedExObj);
                            }

                            if(data?.movementName && data?.movementStartStopStatus) {
                                let movementStr = data?.movementStartStopStatus;
                                if(movementStr) {
                                    toast.success(`${data?.movementName} ${movementStr}`);
                                }
                            }
                        } else if(data?.endMeeting) {
                            onCloseCallback && onCloseCallback();
                            toggleShowTelemedModal();
                        }
                    }
                }
            });

            // initialize the publisher
            const publisherOptions = {
                insertMode: 'append',
                width: '100%',
                height: '80vh'
            };
            publisherRef.current = OT.initPublisher('publisher', publisherOptions, handleError);
            if(publisherRef.current) {
                publisherRef.current.on('error', function(err) {
                    handleError(err);
                });
                publisherRef.current.on('videoDisabled', event => {
                    console.error('video disabled ', event);
                });
            }

            // Connect to the session
            sessionRef.current.connect(telemedicineKeys?.opentokToken, (error) => {
                if (error) {
                    handleError(error);
                } else {
                    // If the connection is successful, publish the publisher to the session
                    sessionRef.current.publish(publisherRef.current, handleError);
                }
            });
        } catch (e) {
            handleError(e);
        }

    }

    function handleError(error) {
        if (error) {
            if (error.name === 'OT_HARDWARE_UNAVAILABLE' || error.name === "NotReadableError") {
                errorMsgRef.current = "Your camera or microphone is being used by another application. Please close other applications and try again.";
                setSweetAlertErrorMsg(true);


            } else if(error?.name === "OT_USER_MEDIA_ACCESS_DENIED") {
                errorMsgRef.current = "You have denied access to your camera and microphone. Please allow access and refresh the window.";
                setSweetAlertErrorMsg(true);
                isCameraAccessDeniedRef.current = true;
            }
        }
    }

    const onClickMuteUnmuteVideo = async () => {
        try {
            if(publisherRef.current) {
                if(publisherRef.current.getVideoSource() && !publisherRef.current.getVideoSource().track) {
                    // toast.error("Camera is not available");
                    reconnect(true);
                    return;
                }
                await publisherRef.current.publishVideo(isVideoHidden);
                setIsVideoHidden((prevState) => !prevState);
            } else {
                toast.error("Camera is not available");
            }
        } catch (e) {
            handleError(e)
        }
    }

    const reconnect = (isCameraBeingUsedByAnotherProgram = false) => {
        if(!isCameraBeingUsedByAnotherProgram) {
            toast.warn('Disconnected from the room. Attempting to reconnect');
        }

        clearState();
        initializeSession();

        if(!navigator.onLine) {
            setTimeout(reconnect, 5000);
        }
    }

    const clearState = () => {
        setSelectedExercise({});
        setIsAudioMuted(false);
        setIsVideoHidden(false);
        setSweetAlertErrorMsg(false);
        errorMsgRef.current = "";
        isCameraAccessDeniedRef.current = false;
        setSubscriberCount(0);
    }

    const cleanUpOnCloseModal = () => {
        if(sessionRef.current) {
            sessionRef.current.off('streamCreated');
            sessionRef.current.off('sessionDisconnected');
            sessionRef.current.off('signal');
            sessionRef.current.disconnect();
        }

        if (publisherRef.current) {
            publisherRef.current.destroy();
            publisherRef.current = null;
        }

        if (subscriberRef.current) {
            subscriberRef.current.destroy();
            subscriberRef.current = null;
        }
        onCloseCallback && onCloseCallback();
    }

    const onClickOkBtn = () => {
        setSweetAlertErrorMsg(false);
        errorMsgRef.current = "";
        if(!isCameraAccessDeniedRef.current) {
            setTimeout(() => reconnect(true), 5000);
        }
    }

    const drawCtx = () => {
        if (publisherVideoRef.current) {
            let ctx = publisherVideoRef.current.getContext('2d');
            ctx.clearRect(0, 0, publisherVideoRef.current.width, publisherVideoRef.current.height);

            let canvasMidWidth = publisherVideoRef.current.width / 2;
            // let canvasMidHeight = publisherVideoRef.current.height / 2;

            ctx.lineWidth = 1;
            ctx.strokeStyle = "blue";

            ctx.beginPath();
            ctx.moveTo(canvasMidWidth, 0);
            ctx.lineTo(canvasMidWidth, publisherVideoRef.current.height);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(canvasMidWidth - 50, publisherVideoRef.current.height - 5);
            ctx.lineTo(canvasMidWidth + 50, publisherVideoRef.current.height - 5);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(canvasMidWidth - 49, publisherVideoRef.current.height - 35);
            ctx.lineTo(canvasMidWidth - 49, publisherVideoRef.current.height - 5);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(canvasMidWidth + 49, publisherVideoRef.current.height - 35);
            ctx.lineTo(canvasMidWidth + 49, publisherVideoRef.current.height - 5);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(canvasMidWidth - 50, publisherVideoRef.current.height - 35);
            ctx.lineTo(canvasMidWidth - 40, publisherVideoRef.current.height - 35);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(canvasMidWidth + 50, publisherVideoRef.current.height - 35);
            ctx.lineTo(canvasMidWidth + 40, publisherVideoRef.current.height - 35);
            ctx.stroke();
        }
    };

    const onClickMuteUnmuteAudio = () => {
        try {
            if(publisherRef.current) {
                publisherRef.current.publishAudio(isAudioMuted);
                setIsAudioMuted((prevState) => !prevState);
            }
        } catch (e) {
            handleError(e);
        }
    }

    const onClickCameraSwitch = async () => {
        if (publisherRef.current) {
            try {
                await publisherRef.current.cycleVideo();
            } catch (error) {
                handleError(error);
            }
        }
    }

    return {
        isVideoHidden,
        isAudioMuted,
        subscriberCount,
        selectedExercise,
        sweetAlertErrorMsg,
        publisherVideoRef,
        errorMsgRef,
        initializeSession,
        drawCtx,
        cleanUpOnCloseModal,
        onClickMuteUnmuteVideo,
        onClickMuteUnmuteAudio,
        onClickCameraSwitch,
        onClickOkBtn,
    }
}

export default useTelemedicineDetails;