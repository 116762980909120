import React, {useState} from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const HeightForm = ({isShow, updateUserHeight, isUpdatingUserData}) => {

    const [heightVal, setHeightVal] = useState(0);

    const handleChange = (event) => {
        event.preventDefault();
        setHeightVal(event.target.value);
    }

    const submitHandler = (e) => {
        e.preventDefault();
        updateUserHeight(+heightVal);
    }

    return (
        <>
            <Modal isOpen={isShow} className="modal-md retake-notification-modal" centered>
                <ModalHeader>
                    <strong>Input Height</strong>
                </ModalHeader>
                <ModalBody>
                <div>
                    <label>
                        Height (Inch): <input type="number" value={heightVal} onChange={handleChange} />
                    </label>
                </div>
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex-center-center">
                        {isUpdatingUserData ? <button className="btn btn-success" disabled={heightVal === 0} type="button"><i className="fa fa-spinner fa-spin"/>&nbsp;Saving...</button>
                        : <button className="btn btn-success" disabled={heightVal === 0} type="button" onClick={submitHandler}>Submit</button>}
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default HeightForm;