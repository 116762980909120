import CONSTANT from "../../../../constants/Constant";

const { REACT_APP_BASE_API_URL, REACT_APP_ROM_API_URL, REACT_APP_VA_API_URL } = process.env;
export const ASSESSMENT_SAVE_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.EXAM_CONTROLLER}/SaveAssessments`;
export const MEASURED_EXERCISES_URL = REACT_APP_VA_API_URL + `/api/${CONSTANT.CONTROLLER.ROM_EXERCISE_API}/GetMeasuredExercises`;
// export const PROCESS_EXERCISE_VIDEO_URL = REACT_APP_ROM_API_URL + '/enqueue/';
export const PROCESS_EXERCISE_VIDEO_URL = REACT_APP_ROM_API_URL + '/api/v1/enqueue';
export const PROCESS_QUEUE_EXERCISE_URL = REACT_APP_VA_API_URL + '/api/RomExerciseApi/queue_status';
export const UPDATE_USER_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.BASE_CONTROLLER}/UpdatePatientInformation`;
export const No_START_OVER_URL = REACT_APP_VA_API_URL + '/api/RomExerciseApi/start-over';
export const TELEMED_SESSION_KEY_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.OPENTOK_CONTROLLER}/ManageOpentokSession`;
export const EXERCISE_GUIDELINE_ALL = REACT_APP_BASE_API_URL + '/api/exam/GetExerciseGuidelineAll';