import crossBtn from "../../../assets/img/utils/cross.svg";
import Popup from "../Popup";
import React from "react";

const ErrorDialog = ({ headerText = '', errorMsg, clearErrorMsg }) => {
    return (
        <Popup show={!!errorMsg}>
            <div style={{width: "40vw"}} className="error-dialog">
                <div className="d-flex justify-content-end p-3">
                    <img className="cursor-pointer" onClick={clearErrorMsg} src={crossBtn} alt="Close button" />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <div className="bordered">
                        {headerText === '' ? "Error" : headerText}
                    </div>
                </div>
                <div className="error">
                    <div className="error-container">
                        {errorMsg}
                    </div>
                </div>
            </div>
        </Popup>
    )
};

export default ErrorDialog;