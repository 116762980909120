import axios from './Axios';
import { error, success } from "./ResponseHandler";
import CONSTANT from "../constants/Constant";
import GLOBAL from "../global";

const { REACT_APP_CHAT_API_URL } = process.env;

const RESUME_CHAT_URL = REACT_APP_CHAT_API_URL + `/api/${CONSTANT.BOT_API_VERSION}/resume-chat`;

class ChatbotService {
    async chat(payload, url) {
        try {
            const response = await axios.post(url, payload);
            if(!response?.success) {
                return error(response);
            }
            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async resumeChat(payload = {}) {
        try {
            payload.tenant = GLOBAL.TENANT;
            payload.lang = 'en';

            let response = await axios.post(RESUME_CHAT_URL, payload);
            response = success(response);
            if(!response?.success) {
                return error(response);
            }

            return response;
        } catch (e) {
            console.error(e);
            return error(e);
        }
    }
}

export default new ChatbotService();
