import NoVideoIcon from "../../../assets/img/movement/noVideoIcon.svg";
import VideoIcon from "../../../assets/img/movement/videoIcon.svg";
import {UncontrolledTooltip} from "reactstrap";
import NoAudioIcon from "../../../assets/img/movement/noAudioIcon.svg";
import AudioIcon from "../../../assets/img/movement/audioIcon.svg";
import SwitchCamera from "../../../assets/img/movement/switchCamera.svg";
import React from "react";

const TelemedicineVideoToolbar = ({ onClicks, controlStates }) => {
    const { isVideoHidden, isAudioMuted } = controlStates;
    const { onClickMuteUnmuteVideo, onClickMuteUnmuteAudio, onClickCameraSwitch } = onClicks;

    return (
        <>
            <div className="telemedicine-video-guideline-container__video-toolbar">
                <div className="padding-bottom-12">
                    <div className="">
                        <div className="cursor-pointer"
                             id="video-camera-unmute"
                             onClick={onClickMuteUnmuteVideo}
                        >
                            {isVideoHidden && (
                                <img src={NoVideoIcon} alt="VideoUnmute"/>
                            )}
                            {!isVideoHidden && (
                                <img src={VideoIcon} alt="VideoUnmute"/>
                            )}
                        </div>
                    </div>
                    {/*{isVideoHidden && (*/}
                    {/*    <UncontrolledTooltip*/}
                    {/*        placement="bottom"*/}
                    {/*        target="video-camera-unmute">*/}
                    {/*        HIDE VIDEO*/}
                    {/*    </UncontrolledTooltip>*/}
                    {/*)}*/}
                    {/*{!isVideoHidden && (*/}
                    {/*    <UncontrolledTooltip*/}
                    {/*        placement="bottom"*/}
                    {/*        target="video-camera-unmute">*/}
                    {/*        SHOW VIDEO*/}
                    {/*    </UncontrolledTooltip>*/}
                    {/*)}*/}
                </div>
                <div className="padding-bottom-12">
                    <div className="">
                        <div className="cursor-pointer"
                             id="audio-unmute"
                             onClick={onClickMuteUnmuteAudio}
                        >
                            {isAudioMuted && (
                                <img src={NoAudioIcon} alt="AudioMute"/>
                            )}
                            {!isAudioMuted && (
                                <img src={AudioIcon} alt="AudioMute"/>
                            )}
                        </div>
                    </div>
                    {/*{isAudioMuted && (*/}
                    {/*    <UncontrolledTooltip*/}
                    {/*        placement="bottom"*/}
                    {/*        target="audio-unmute">*/}
                    {/*        MUTE AUDIO*/}
                    {/*    </UncontrolledTooltip>*/}
                    {/*)}*/}
                    {/*{!isAudioMuted && (*/}
                    {/*    <UncontrolledTooltip*/}
                    {/*        placement="bottom"*/}
                    {/*        target="audio-unmute">*/}
                    {/*        UNMUTE AUDIO*/}
                    {/*    </UncontrolledTooltip>*/}
                    {/*)}*/}
                </div>

                <div className="padding-bottom-12">
                    <div className="">
                        <div className="cursor-pointer"
                             id="camera-switch"
                             onClick={onClickCameraSwitch}
                        >
                            {(
                                <img src={SwitchCamera} alt="AudioMute"/>
                            )}
                        </div>
                    </div>
                    {isAudioMuted && (
                        <UncontrolledTooltip
                            placement="bottom"
                            target="camera-switch">
                            SWITCH CAMERA
                        </UncontrolledTooltip>
                    )}
                </div>
            </div>
        </>
    )
}

export default TelemedicineVideoToolbar;
