import React, { useEffect, useRef, useState } from "react";
import dropDown from "../../assets/img/mmh-table/dropDown.svg";
import dropUp from "../../assets/img/mmh-table/dropUp.svg";
import Selected from "./Selected";

const MultiSelectDropdown = ({ options, placeholder, onChange }) => {
    const [notSelectedOptions, setNotSelectedOptions] = useState(options);
    const [willOpenOption, setWillOpenOption] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const optionsRef = useRef();

    function optionHandleClickOutside(event) {
        if (optionsRef.current && !optionsRef.current.contains(event.target)) {
            setWillOpenOption(false);
        }
    }
    const toggleWillOpenOption = () => {
        setWillOpenOption(!willOpenOption);
    };

    const isAlreadySelected = (selectedOption) => {
        return selectedOptions.find(option => selectedOption.value === option.value);
    };

    const notWillOpenOption = () => {
        setWillOpenOption(false);
    };

    const onClickOption = (e, selectedOption) => {
        e.stopPropagation();

        let tempSelectedOptions = [];
        if (isAlreadySelected(selectedOption)) {
            tempSelectedOptions = selectedOptions.filter((item) => item.value !== selectedOption.value);
            setSelectedOptions(tempSelectedOptions);
            setNotSelectedOptions([...notSelectedOptions, selectedOption]);
        } else {
            tempSelectedOptions = [...selectedOptions, selectedOption];
            setSelectedOptions(tempSelectedOptions);
            setNotSelectedOptions(notSelectedOptions.filter((option) => option.value !== selectedOption.value));
        }
        onChange && onChange(tempSelectedOptions);
    };

    useEffect(() => {
        setNotSelectedOptions(options);
    }, [options]);

    useEffect(() => {
        document.addEventListener('mousedown', optionHandleClickOutside);

        return () => {
            document.removeEventListener('mousedown', optionHandleClickOutside);
        };
    }, [optionsRef]);

    return (
        <div className="position-relative" ref={optionsRef}>
            <div className="select-option filter_width_height kt-space-between" onClick={toggleWillOpenOption}>
                <div className="multiselectContainer">
                    {
                        (selectedOptions.length) > 0
                            ? selectedOptions.map(option => <Selected option={option} selectedOptionHandler={onClickOption} />)
                            : placeholder
                    }
                </div>
                <div className="separator">
                    <div className=""></div>
                    <img src={willOpenOption? dropUp : dropDown} alt="arrowDownUp" />
                </div>
            </div>

            {willOpenOption && notSelectedOptions.length > 0 &&
                <div className="d-flex justify-content-center" style={{color:"#0075B6"}}>
                    <div className="content" >
                        <ul className="options">
                            {notSelectedOptions.map((option) =>
                                <li
                                    onClick={(e) => onClickOption(e, option)}
                                    key={option.value}
                                >
                                    {option.label}
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            }
        </div>
    );
};

export default MultiSelectDropdown;
