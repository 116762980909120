class EnabledBodyLocationAndAnsweredVasQuestion {
    constructor(option = {}) {
        this.BodyLocation = option?.BodyLocation || ''
        this.Color = option?.Color || ''
        this.PainValue = option?.PainValue || ''
        this.SubBodyRegion = option?.SubBodyRegion || ''
        this.Name = option?.Name || ''
    }
}

export default EnabledBodyLocationAndAnsweredVasQuestion;
