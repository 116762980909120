export const bodyPart = [
    {
        "subBodyRegion": "Head",
        "bodyLocation": "head",
        "disabled": true
    },
    {
        "subBodyRegion": "Head Left",
        "bodyLocation": "head_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Head Right",
        "bodyLocation": "head_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Head Back",
        "bodyLocation": "head_back",
        "disabled": true
    },
    {
        "subBodyRegion": "Upper Trapezius Front Right",
        "bodyLocation": "upper_trapezius_front_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Upper Trapezius Front Left",
        "bodyLocation": "upper_trapezius_front_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Shoulder Right",
        "bodyLocation": "shoulder_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Shoulder Left",
        "bodyLocation": "shoulder_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Shoulder Left Middle",
        "bodyLocation": "shoulder_left_middle",
        "disabled": true
    },
    {
        "subBodyRegion": "Shoulder Right Middle",
        "bodyLocation": "shoulder_right_middle",
        "disabled": true
    },
    {
        "subBodyRegion": "Deltoid Right",
        "bodyLocation": "deltoid_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Deltoid Left",
        "bodyLocation": "deltoid_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Sternum",
        "bodyLocation": "sternum",
        "disabled": true
    },
    {
        "subBodyRegion": "Elbow Right",
        "bodyLocation": "elbow_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Elbow Left",
        "bodyLocation": "elbow_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Hip Front Right",
        "bodyLocation": "hip_front_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Hip Front Left",
        "bodyLocation": "hip_front_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Hip Side Right",
        "bodyLocation": "hip_side_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Hip Side Left",
        "bodyLocation": "hip_side_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Hip Right",
        "bodyLocation": "hip_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Hip Left",
        "bodyLocation": "hip_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Wrist Right",
        "bodyLocation": "wrist_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Wrist Left",
        "bodyLocation": "wrist_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Finger 1 Right",
        "bodyLocation": "finger_1_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Finger 2 Right",
        "bodyLocation": "finger_2_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Finger 3 Right",
        "bodyLocation": "finger_3_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Finger 4 Right",
        "bodyLocation": "finger_4_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Finger 5 Right",
        "bodyLocation": "finger_5_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Finger 1 Left",
        "bodyLocation": "finger_1_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Finger 2 Left",
        "bodyLocation": "finger_2_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Finger 3 Left",
        "bodyLocation": "finger_3_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Finger 4 Left",
        "bodyLocation": "finger_4_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Finger 5 Left",
        "bodyLocation": "finger_5_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Mid Anterior Thigh Left",
        "bodyLocation": "mid_anterior_thigh_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Mid Anterior Thigh Right",
        "bodyLocation": "mid_anterior_thigh_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Iliotibial Band Right",
        "bodyLocation": "iliotibial_band_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Iliotibial Band Left",
        "bodyLocation": "iliotibial_band_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Medial Knee Right",
        "bodyLocation": "medial_knee_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Knee Right",
        "bodyLocation": "knee_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Lateral Knee Right",
        "bodyLocation": "lateral_knee_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Medial Knee Left",
        "bodyLocation": "medial_knee_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Knee Left",
        "bodyLocation": "knee_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Lateral Knee Left",
        "bodyLocation": "lateral_knee_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Front Of Leg Right",
        "bodyLocation": "front_of_leg_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Front Of Leg Left",
        "bodyLocation": "front_of_leg_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Medial Ankle Right",
        "bodyLocation": "medial_ankle_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Ankle Right",
        "bodyLocation": "ankle_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Lateral Ankle Right",
        "bodyLocation": "lateral_ankle_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Medial Ankle Left",
        "bodyLocation": "medial_ankle_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Ankle Left",
        "bodyLocation": "ankle_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Lateral Ankle Left",
        "bodyLocation": "lateral_ankle_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Neck Left",
        "bodyLocation": "neck_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Nape of Neck",
        "bodyLocation": "nape_of_neck",
        "disabled": true
    },
    {
        "subBodyRegion": "Neck Right",
        "bodyLocation": "neck_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Lower Neck",
        "bodyLocation": "lower_neck",
        "disabled": true
    },
    {
        "subBodyRegion": "Upper Trapezius Back Left",
        "bodyLocation": "upper_trapezius_back_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Upper Trapezius Back Right",
        "bodyLocation": "upper_trapezius_back_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Mid Back 1 Left",
        "bodyLocation": "mid_back_1_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Mid Back 1 Right",
        "bodyLocation": "mid_back_1_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Mid Back 2 Left",
        "bodyLocation": "mid_back_2_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Mid Back 2 Middle",
        "bodyLocation": "midback_2_middle",
        "disabled": true
    },
    {
        "subBodyRegion": "Mid Back 2 Right",
        "bodyLocation": "mid_back_2_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Mid Back 3 Left",
        "bodyLocation": "mid_back_3_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Mid Back 3 Middle",
        "bodyLocation": "midback_3_middle",
        "disabled": true
    },
    {
        "subBodyRegion": "Mid Back 3 Right",
        "bodyLocation": "mid_back_3_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Lower Back Left",
        "bodyLocation": "lower_back_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Lower Back Middle",
        "bodyLocation": "low_back_middle",
        "disabled": true
    },
    {
        "subBodyRegion": "Lower Back Right",
        "bodyLocation": "lower_back_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Triceps Left",
        "bodyLocation": "triceps_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Triceps Right",
        "bodyLocation": "triceps_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Hip Back Left",
        "bodyLocation": "hip_back_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Hip Back Right",
        "bodyLocation": "hip_back_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Sacroiliac Joint Left",
        "bodyLocation": "sacroiliac_joint_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Sacroiliac Joint Right",
        "bodyLocation": "sacroiliac_joint_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Tailbone Left",
        "bodyLocation": "tailbone_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Tailbone Right",
        "bodyLocation": "tailbone_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Gluteal Left",
        "bodyLocation": "gluteal_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Gluteal Right",
        "bodyLocation": "gluteal_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Lateral Elbow Left",
        "bodyLocation": "lateral_elbow_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Olecranon Left",
        "bodyLocation": "olecranon_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Medial Elbow Left",
        "bodyLocation": "medial_elbow_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Lateral Elbow Right",
        "bodyLocation": "lateral_elbow_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Olecranon Right",
        "bodyLocation": "olecranon_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Medial Elbow Right",
        "bodyLocation": "medial_elbow_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Sacroiliac Joint",
        "bodyLocation": "sacroiliac_joint",
        "disabled": true
    },
    {
        "subBodyRegion": "Coccyx",
        "bodyLocation": "coccyx",
        "disabled": true
    },
    {
        "subBodyRegion": "Dorsum 1 Left",
        "bodyLocation": "dorsum_1_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Dorsum 2 Left",
        "bodyLocation": "dorsum_2_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Dorsum 3 Left",
        "bodyLocation": "dorsum_3_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Dorsum 4 Left",
        "bodyLocation": "dorsum_4_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Dorsum 5 Left",
        "bodyLocation": "dorsum_5_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Dorsum 1 Right",
        "bodyLocation": "dorsum_1_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Dorsum 2 Right",
        "bodyLocation": "dorsum_2_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Dorsum 3 Right",
        "bodyLocation": "dorsum_3_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Dorsum 4 Right",
        "bodyLocation": "dorsum_4_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Dorsum 5 Right",
        "bodyLocation": "dorsum_5_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Thigh Back Left",
        "bodyLocation": "thigh_back_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Thigh Back Right",
        "bodyLocation": "thigh_back_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Popliteal Area Left",
        "bodyLocation": "popliteal_area_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Popliteal Area Right",
        "bodyLocation": "popliteal_area_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Calf Left",
        "bodyLocation": "calf_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Calf Right",
        "bodyLocation": "calf_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Achilles Tendon Left",
        "bodyLocation": "achilles_tendon_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Achilles Tendon Right",
        "bodyLocation": "achilles_tendon_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Heel Left",
        "bodyLocation": "heel_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Heel Right",
        "bodyLocation": "heel_right",
        "disabled": true
    },
    {
        "subBodyRegion": "Sole Left",
        "bodyLocation": "sole_left",
        "disabled": true
    },
    {
        "subBodyRegion": "Sole Right",
        "bodyLocation": "sole_right",
        "disabled": true
    }
];
