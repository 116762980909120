import React, { useEffect, useRef } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";
import GLOBAL from "../../../global";

import PaymentMethods from "./PaymentMethods";
import BillingAddress from "./BillingAddress";
import BillingHistory from "./BillingHistory";
import OverallPlan from "./OverallPlan";
import defaultProfilePic from "../../../assets/img/users/default.jpg";

const SubscriptionDetails = () => {
    const subscription = useRef(true);

    useEffect(() => {
        return () => {
            subscription.current = false;
        };

    }, []);

    const onClickEmailHandle = (e) => {
        e.preventDefault();
    };

    const onClickToggleSidebar = () => {
        document.getElementById("kt_user_profile_aside").classList.toggle("kt-app__aside--on");
    };

    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
                    <div className="kt-grid__item kt-app__toggle kt-app__aside kt-mr-10" id="kt_user_profile_aside">
                        <button className="kt-app__aside-close" id="kt_user_profile_aside_close"
                                onClick={onClickToggleSidebar}>
                            <i className="la la-close"/>
                        </button>
                        <div className="kt-portlet ">
                            <div className="kt-portlet__head  kt-portlet__head--noborder">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body kt-portlet__body--fit-y">
                                <div className="kt-widget kt-widget--user-profile-1">
                                    <div className="kt-widget__head">
                                        <div className="kt-widget__media">
                                            {GLOBAL.USER_INFO['ProfilePic'] ?
                                                <img src={GLOBAL.USER_INFO['ProfilePic']} alt="Pic"/>
                                                : <img src={defaultProfilePic} alt="Pic"/>
                                            }
                                        </div>
                                        <div className="kt-widget__content">
                                            <div className="kt-widget__section">
                                                <a href={"!#"} className="kt-widget__username"
                                                   onClick={(e) => e.preventDefault()}>
                                                    {GLOBAL.USER_INFO['FullName']}
                                                    <i className="flaticon2-correct kt-font-success"/>
                                                </a>
                                                <span className="kt-widget__subtitle">
                                                    PATIENT
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="kt-widget__body">
                                        <div className="kt-widget__content">
                                            <div className="kt-widget__info">
                                                <span className="kt-widget__label">Email:</span>
                                                <a onClick={e => onClickEmailHandle(e)}
                                                   rel="noopener noreferrer" href={"!#"}
                                                   className="kt-widget__data">{GLOBAL.USER_INFO['EmailAddress']}</a>
                                            </div>
                                            <div className="kt-widget__info">
                                                <span className="kt-widget__label">Phone:</span>
                                                <a href={"!#"}
                                                   className="kt-widget__data">{GLOBAL.USER_INFO['MobilePhone']}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PerfectScrollbar className="kt-widget2 scrollable-y border-bottom-1">
                        <OverallPlan/>
                        <PaymentMethods/>
                        <BillingAddress/>
                        <BillingHistory/>
                    </PerfectScrollbar>
                </div>
            </div>
        </>
    )
};

export default SubscriptionDetails;
