import { UncontrolledTooltip } from "reactstrap";
import React from "react";

const MyAssessmentTooltip = ({target, text = '', title = '', description = ''}) => {
    // const {target, text} = props
    const element = document.getElementById(target)

    return (
        <>
            {element ? <UncontrolledTooltip
                placement="top"
                target={target}
            >
                {text !== '' ? <span className="color-primary-dark-1">{text}</span> : null}
                {title !== '' ? <strong>{title}</strong> : null}
                {description !== '' ? <><br/>description</> : null}

                {/*<strong>{chatResponse.title}</strong><br/>*/}
                {/*{chatResponse.description}*/}
                {/*// <span className="color-primary-dark-1">{text}</span>*/}
            </UncontrolledTooltip>: null}

        </>
    )
}

export default MyAssessmentTooltip