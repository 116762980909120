import CONSTANT from "../../constants/Constant";
import React, {Fragment} from "react";
import InputBox from "../shared/form-element/InputBox";
import RadioButton from "../shared/form-element/RadioButton";
import { isMobile } from "react-device-detect";
import MaskInput from "../shared/form-element/MaskInput";
import DropdownItem from "../shared/form-element/DropdownItem";
import { useSelector } from "react-redux";
import { deepClone } from "../../utils/Utils";
import { getIndexOfCurrentGroup } from "./EditAssessmentUtils";
import useEditAssessmentBody from "./hooks/useEditAssessmentBody";
import ChiefComplaint from "../shared/chiefComplaintNew/ChiefComplaint";
import CheckboxForEditAssessment from "../shared/form-element/CheckboxForEditAssessment";

const EditAssessmentQuestionContainer = ({ setSelectedElementForScroll }) => {
    const editAssessmentGroup = useSelector(state => state.editAssessment);

    const { handleAnswerSave, handleUpdateCCQuestionAnswer } = useEditAssessmentBody(setSelectedElementForScroll);

    const groups = editAssessmentGroup?.groups;

    const baseData = editAssessmentGroup?.baseData || {};
    const ccQuestion = editAssessmentGroup?.ccQuestion || {};
    const currentGroupId = baseData?.currentGroupId || CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;
    const currentGroupIndex = getIndexOfCurrentGroup(groups, currentGroupId);
    const currentSubGroupIndex = baseData?.currentSubGroupIndex || 0;
    const testId = baseData?.testId || "";
    const isReportAvailable = baseData?.isReportAvailable || false;
    const showInlineError = baseData?.showInlineError || false;
    const currentQuestionId = baseData?.currentQuestionId || false;

    const currentGroup = groups[currentGroupIndex];

    return (
        <Fragment>
            {currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key &&
                (
                    <div className={`edit-assessment-chief-complaint-container w-100`}>
                        <ChiefComplaint
                            testId={testId}
                            isReportAvailable={isReportAvailable}
                            ccQuestion={ccQuestion}
                            updateAnswer={handleUpdateCCQuestionAnswer}
                        />
                    </div>
                )
            }

            {currentGroup?.subGroups.map((subGroup, index) => {
                if(
                    (
                        (index === currentSubGroupIndex) &&
                        (currentGroupId !== CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key)
                    ) ||
                    (currentGroupId === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key)
                ) {
                    return (
                        <>
                            <div className={`edit-assessment-subgroup`}>{subGroup?.header1}</div>

                            {subGroup?.pageCaption && (
                                <div
                                    className="edit-assessment-page-caption">{subGroup?.pageCaption}
                                </div>
                            )}

                            <div
                                className=" kt-mb-0 kt-padding-b-0 kt-mt-10"
                                data-ktwizard-state="current"
                            >
                                {subGroup?.questions.map((question) => {
                                    return (
                                        <Fragment
                                            key={question?.questionId}>
                                            {(((currentGroupId !== CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key)) && !question['isDefaultHide']) &&
                                                (
                                                    <>
                                                        {(question.responseType === CONSTANT.ELEMENT_TYPES.TEXT
                                                                || question.responseType === CONSTANT.ELEMENT_TYPES.CALCULATION
                                                                || question.responseType === CONSTANT.ELEMENT_TYPES.NUMBER) &&
                                                            question.questionId !== CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH && (
                                                                <InputBox
                                                                    disabled={question.isScoreQuestion || isReportAvailable}
                                                                    label={question.text}
                                                                    placeHolder={question.dialogue}
                                                                    value={question.answerValue}
                                                                    question={question}
                                                                    error={showInlineError && question.error?.message && question.error}
                                                                    onChange={handleAnswerSave}
                                                                />
                                                        )}
                                                        {question.responseType === CONSTANT.ELEMENT_TYPES.BUTTON && question.responses && question.responses.length > 0 && (
                                                            <RadioButton
                                                                disabled={isReportAvailable}
                                                                isFocused={question.questionId === currentQuestionId}
                                                                label={question.text}
                                                                question={question}
                                                                options={question.responses}
                                                                checkedOption={question?.answerId}
                                                                onChange={handleAnswerSave}
                                                                error={showInlineError && question.error?.message && question.error}
                                                                cssStyle={currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key || isMobile ? 'column' : 'row'}/>
                                                        )}

                                                        {((question.responseType === CONSTANT.ELEMENT_TYPES.CHECKBOX || question.responseType === CONSTANT.ELEMENT_TYPES.MULTISELECT) &&
                                                            question.responses && question.responses.length > 0) && (
                                                            <CheckboxForEditAssessment
                                                                disabled={isReportAvailable}
                                                                label={question.text}
                                                                question={question}
                                                                options={question.responses}
                                                                onChange={handleAnswerSave}
                                                                checkedOptionsIds={question?.answerList ? question.answerList : []}
                                                                error={showInlineError && question.error?.message && question.error}
                                                                cssStyle={currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key || isMobile ? 'column' : 'row'}/>
                                                        )}
                                                        {((question.responseType === CONSTANT.ELEMENT_TYPES.TEXT && question.questionId === CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH)
                                                            || question.responseType === CONSTANT.ELEMENT_TYPES.DATE_PICKER) && (
                                                            <MaskInput
                                                                disabled={isReportAvailable}
                                                                label={question.text}
                                                                value={question.answerValue}
                                                                placeHolder={question.dialogue}
                                                                question={question}
                                                                mask={(question.questionId === CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH || question.responseType === CONSTANT.ELEMENT_TYPES.DATE_PICKER) ? '99/99/9999' : '99/99/9999'}
                                                                error={showInlineError && question.error?.message && question.error}
                                                                onChange={handleAnswerSave}
                                                            />
                                                        )}
                                                        {question.responseType === CONSTANT.ELEMENT_TYPES.DROPDOWN && (
                                                            <DropdownItem
                                                                label={question.text}
                                                                disabled={isReportAvailable}
                                                                checkedOption={question.answerId}
                                                                value={deepClone(question.responses)}
                                                                question={question}
                                                                mask={(question.questionId === CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH || question.responseType === CONSTANT.ELEMENT_TYPES.DATE_PICKER) ? '99/99/9999' : '99/99/9999'}
                                                                error={showInlineError && question.error?.message && question.error}
                                                                onChange={handleAnswerSave}
                                                            />
                                                        )}
                                                    </>
                                                )}
                                        </Fragment>
                                    )
                                })}
                            </div>
                        </>
                    )
                }
            })}
        </Fragment>
    )
}

export default EditAssessmentQuestionContainer;