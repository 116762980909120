import React, { Fragment, useEffect, useState } from 'react';
import CONSTANT from "../../constants/Constant";

const MultiBarIndicator = (props) => {
    let {scores} = props;
    const [, setForceUpdate] = useState(0);

    useEffect(() => {
        if (scores && scores.length > 0) {
            scores.forEach((item) =>
                item.arrowPosition = (item.score && Math.floor(((item.score - 100) * 0.583) + 10)) || 10
            );
        }

        setForceUpdate(Date.now());

    }, [scores])

    const getRegionDisplayName = (regionValue) => {
        if (regionValue.toLowerCase() === CONSTANT.BODY_REGIONS.NECK.name.toLowerCase()) {
            return CONSTANT.BODY_REGIONS.NECK.value;
        } else if (regionValue.toLowerCase() === CONSTANT.BODY_REGIONS.ARMS.name.toLowerCase()) {
            return CONSTANT.BODY_REGIONS.ARMS.value;
        } else if (regionValue.toLowerCase() === CONSTANT.BODY_REGIONS.BACK.name.toLowerCase()) {
            return CONSTANT.BODY_REGIONS.BACK.value;
        } else if (regionValue.toLowerCase() === CONSTANT.BODY_REGIONS.LEGS.name.toLowerCase()) {
            return CONSTANT.BODY_REGIONS.LEGS.value;
        }else if (regionValue.toLowerCase() === CONSTANT.BODY_REGIONS.POSTURE.name.toLowerCase()) {
            return CONSTANT.BODY_REGIONS.POSTURE.value;
        } else {
            return '';
        }
    }

    return (
        <>
            <br/>
            <br/>
            {scores && scores.length > 0 &&
            scores.map((item, index) => {
                return (
                    <Fragment key={index}>
                        <div className="row">
                            <div className="col-md-4">
                                <span
                                    className="kt-font-bold kt-font-transform-u">{getRegionDisplayName(item.region)}</span>
                            </div>
                            <div className="col-md-4">
                                <div className="kt-mb-5">{item.message}</div>
                                <div className="slide-bar-down-arrow-content kt-mt-5"
                                     style={{ width: item.arrowPosition + "px" }}>
                                    <span className="float-right">{item.score}</span>
                                    <br/>
                                    <i className="fas fa-arrow-down float-right kt-font-xl"/>
                                </div>
                                <div className="kt-mt-20">
                                    <span className="kt-badge kt-badge--inline kt-badge--success slide-bar-width"/>
                                    <span
                                        className="kt-badge kt-badge--inline kt-badge--warning slide-bar-width slide-bar-position"/>
                                    <span
                                        className="kt-badge kt-badge--inline kt-badge--danger slide-bar-width slide-bar-position"/>
                                </div>
                                <div className="row">
                                    <div className="kt-ml-5">100</div>
                                    <div className="kt-ml-40">200</div>
                                    <div className="kt-ml-30">300</div>
                                    <div className="kt-ml-35">400</div>
                                </div>
                            </div>
                        </div>
                        <br/>
                    </Fragment>
                )}
            )}
        </>
    );
};

export default MultiBarIndicator;