import React, {useEffect, useRef, useState, memo, useMemo} from "react"
import BodyPart from "./BodyPart";
import BodyPoints from "./BodyPoints";
import examService from "../../../services/ExamService";
import SwitchButton from "../form-element/SwitchButton";
import ConfirmationDialog from "../Popups/ConfirmationDialog";
import ChiefComplaintQuestion from "./ChiefComplaintQuestion";
import { mapDataToLocalModal, mapVasQuestionToLocalModal, extractEnabledBodyLocationsFromLocallyStoredData} from './services/chief-complaint'
import selectCCGif from "../../../assets/gif/CC/select_CC_point.gif";
import {toast} from "react-toastify";
import SelectCCPointsList from "./SelectCCPointsList";
import CONSTANT from "../../../constants/Constant";
import {saveNoCC_Complaint} from "../chatbotNew/ChatServices";
import {getArrayFromObject} from "../../../utils/Utils";

const ChiefComplaint = ({testId, onSubmit, onChange, isReportAvailable = false, ccQuestion = {}, updateAnswer, isSavingNoCC, isAlreadyCCQuestionAnswered = false}) => {
    const [vasQuestion, setVasQuestion] = useState({})
    const [loading, setLoading] = useState(true)
    const [isShowConfirmation, setIsShowConfirmation] = useState(false)
    const [showQuestionModal, setShowQuestionModal] = useState(false)
    const [showFront, setShowFront] = useState(false);
    const [isLoadingCCAgain, setIsLoadingCCAgain] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const selectedBodyLocationRef = useRef({});
    const enabledBodyPoints = useRef([]);
    const selectedCCQuestionAnswer = useRef({});//For Yes, Chief Complaint | No, Chief complaint question
    const conformationAlertContentRef = useRef({});

    useEffect( () => {
        if(isSavingNoCC) {
            return
        }

        (async () => {
            fetchAndMapDate();
        })()

        //isSavingNoCC is a flag if user select No, Chief Complaint from confirmation dialog
    }, [isSavingNoCC])

    const fetchAndMapDate = async () => {
        const data = await fetchData();
        if(data && data instanceof Array && data.length > 0) {
            const mappedData = mapDataToLocalModal(data)
            const formatedVasQuestions =  mapVasQuestionToLocalModal(mappedData)
            setVasQuestion(formatedVasQuestions)
            enabledBodyPoints.current = extractEnabledBodyLocationsFromLocallyStoredData(mappedData)
        }
    }

    const fetchData = async () => {
        const response = await examService.getEnabledBodyLocationAndAnsweredVasQuestion({ TestId: testId });
        const { data, success, error } = response;

        if (!success) {
            console.error(error);
            return;
        }
        setLoading(false)
        return [...data];
    }

    const vasQuestionHandler = (event) => {
        if(event.target.tagName === 'circle') {
            setIsShowConfirmation(true);
            conformationAlertContentRef.current.yesBtnText = "Confirm";
            conformationAlertContentRef.current.noBtnText = "Cancel";
            conformationAlertContentRef.current.text = `<div>Are the body regions (<strong>${event.target?.dataset?.locationname}</strong>) correctly articulated?</>`;
            conformationAlertContentRef.current.onClickYes = onConfirmHandler;
            conformationAlertContentRef.current.headerText = '';
            selectedBodyLocationRef.current = {bodyLocation: event.target.id, name: event.target?.dataset?.locationname, side: event.target?.dataset?.side}
        }
    }

    const onConfirmHandler = () => {
        setShowQuestionModal(true)
        setIsShowConfirmation(false)
    }

    const closeConfirmationModal = () => {
        setIsShowConfirmation(false)
        selectedBodyLocationRef.current = {}
    }

    const handleToggle = () => {
        setShowFront(prevState => !prevState);
    };

    const handleClearALLCCPoints = async (e) => {
        e.preventDefault();

        if(getArrayFromObject(vasQuestion).length <= 0) {
            toast.error('Select at least one cc point');
            return;
        }

        setIsShowConfirmation(true);
        conformationAlertContentRef.current.text = `Are you sure you want to clear all your Chief Complaints?`;
        conformationAlertContentRef.current.onClickYes = () => clearALLCCPoints(getArrayFromObject(vasQuestion));
        conformationAlertContentRef.current.headerText = 'Alert!';
    }

    const clearALLCCPoints = async (points) => {
        if(points.length <= 0) {
            toast.error('Select at least one cc point');
            return;
        }

        let allPoints = points.map(point => point?.bodyLocation);


        let payload = {
            TestId: testId,
            BodyPoints: allPoints
        }

        setIsDeleting(true)
        const response = await examService.clearCCQuestionOfAPoint(payload);
        const { data, success, error } = response;
        setIsDeleting(false)

        if (!success) {
            console.error(error);
            toast.error("Internal Server Error. Please try again")
            return;
        }

        const ChiefComplaintsQuestionStatus = data?.ChiefComplaintsQuestionStatus;
        updateAnswer && ChiefComplaintsQuestionStatus && updateAnswer(ChiefComplaintsQuestionStatus?.AnswerId, ChiefComplaintsQuestionStatus?.AnswerValue);

        setVasQuestion(prevState => {
            let newState = {...prevState}
            allPoints.forEach((point) => {
                delete newState[point]
            })
            return newState;
        });

        setIsShowConfirmation(false);
    }

    const clearACCPoint = async (point) => {
        if(!point?.bodyLocation) {
            toast.error("Please provide body location value");
            return;
        }

        let payload = {
            TestId: testId,
            BodyPoints: [point?.bodyLocation]
        }

        const response = await examService.clearCCQuestionOfAPoint(payload);
        const { data, success, error } = response;

        if (!success) {
            console.error(error);
            toast.error("Internal Server Error. Please try again")
            return;
        }

        const ChiefComplaintsQuestionStatus = data?.ChiefComplaintsQuestionStatus;
        updateAnswer && ChiefComplaintsQuestionStatus && updateAnswer(ChiefComplaintsQuestionStatus?.AnswerId, ChiefComplaintsQuestionStatus?.AnswerValue);

        setVasQuestion(prevState => {
            let newState = {...prevState}
            delete newState[point?.bodyLocation]
            return newState;
        });
    }

    const onChangeCCQuestion = (event) => {
        selectedCCQuestionAnswer.current = event;

        conformationAlertContentRef.current.yesBtnText = "Save";
        conformationAlertContentRef.current.noBtnText = "Cancel";
        conformationAlertContentRef.current.text = `Are you sure you want to save the change?`;
        conformationAlertContentRef.current.onClickYes = handleCCQuestionAnsChange;
        conformationAlertContentRef.current.headerText = '';
        setIsShowConfirmation(true);
    }

    const handleCCQuestionAnsChange = async () => {
        setIsLoadingCCAgain(true);
        await onChange({ ...selectedCCQuestionAnswer.current, waitUntilSave : true });
        if(selectedCCQuestionAnswer.current?.answerText.toString().toLowerCase().includes('no')) {
            await saveNoCC_Complaint(testId);
            await fetchAndMapDate();
        }
        setIsShowConfirmation(false);
        setIsLoadingCCAgain(false);
    }

    return (
        <>
            {loading ? <div className="kt-spinner kt-spinner--lg kt-spinner--info center-element"/> :
                <div className='row d-flex justify-content-between height-inherite no-padding-left'>
                    <div
                        className='col-xl-9 col-lg-9 d-flex justify-content-around align-items-center height-inherite position-relative chief-complaint-body-container'>
                        {/* Only visible at mobile screen */}
                        <div className="w-100-percent svg-container">
                            <div className="d-flex w-100-percent svg-container">
                                <div className='body-toggle-btn-container'>
                                    <SwitchButton onChangeHandler={handleToggle} isChecked={showFront}/>
                                </div>
                                <BodyPart
                                    imageSide={CONSTANT.SIDE.FRONT.name}
                                    styleName='male_front'
                                    showFirst={!showFront}
                                    imageLink='https://mmhcdn.s3.us-east-2.amazonaws.com/image/front-img.png'
                                    vasQuestionHandler={vasQuestionHandler}
                                    pointListComponent={
                                        <SelectCCPointsList
                                            vasQuestion={vasQuestion}
                                            side={CONSTANT.SIDE.FRONT.name}
                                            clearACCPoint={clearACCPoint}
                                            clearALLCCPoints={clearALLCCPoints}
                                            isReportAvailable={isReportAvailable}
                                            selectedBodyLocationRef={selectedBodyLocationRef}
                                            setShowQuestionModal={setShowQuestionModal}
                                        />
                                    }
                                >
                                    <BodyPoints
                                        points={enabledBodyPoints.current}
                                        answeredVasQuestion={vasQuestion}
                                        side={1}
                                    />
                                </BodyPart>
                                <BodyPart
                                    imageSide={CONSTANT.SIDE.BACK.name}
                                    styleName='male_back'
                                    showFirst={showFront}
                                    imageLink='https://mmhcdn.s3.us-east-2.amazonaws.com/image/back-img.png'
                                    vasQuestionHandler={vasQuestionHandler}
                                    pointListComponent={
                                        <SelectCCPointsList
                                            vasQuestion={vasQuestion}
                                            side={CONSTANT.SIDE.BACK.name}
                                            clearACCPoint={clearACCPoint}
                                            clearALLCCPoints={clearALLCCPoints}
                                            isReportAvailable={isReportAvailable}
                                            selectedBodyLocationRef={selectedBodyLocationRef}
                                            setShowQuestionModal={setShowQuestionModal}
                                        />
                                    }
                                >
                                    <BodyPoints
                                        points={enabledBodyPoints.current}
                                        answeredVasQuestion={vasQuestion}
                                        side={2}
                                    />
                                </BodyPart>
                            </div>

                            {getArrayFromObject(vasQuestion).length > 1 && (
                                <div className="w-100-percent d-flex justify-content-center align-items-center">
                                    <button
                                        className="bg-color-ice-blue-4 body-point-text__clear d-flex justify-content-between w-full"
                                        onClick={async (e) => {
                                            e.preventDefault();
                                            await handleClearALLCCPoints(e);
                                        }}
                                        disabled={isReportAvailable}
                                    >
                                        Clear All Key Points
                                        {(isDeleting) ?
                                            <i className="fa fa-spinner fa-spin ml-3"/> : ""}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className="col-xl-3 col-lg-3 body-part flex-column chief-complaint-info-container h-100-percent">
                        <p className="body-part-click-instruction overflow-auto">
                            {(
                                // From EditAssessment
                                ccQuestion?.questionTitle && !onSubmit)
                                ? (
                                    <div>
                                        <p>
                                            {!isReportAvailable
                                                ? "To resume the biometric, click 'Resume Biometric'. To close it, click (X)."
                                                : "Thank you for completing the assessment. To close this tab, click the (X) button."
                                            }
                                        </p>
                                        <img alt="cc_point" src={selectCCGif} className="w-80-percent object-fit-scale-down"/>
                                        <p className="mt-3 mb-0">To mention chief complaint, please select flashing key points.</p>
                                    </div>
                                )
                                // From Chatbot
                                :
                                "Let's continue with your chief complaint. Please select a " +
                                "Blue highlighted area of the body that bothers you on a daily or intermittent basis. When you are done please click save and close from button."
                            }
                        </p>
                        {/* If use came from chatbot in cc only then this btn will be shown */}
                        {onSubmit &&
                            <button className="button rounded-btn bg-color-red p-4 font-size-lg" onClick={onSubmit}>Save
                                & Close</button>
                        }
                    </div>
                </div>
            }

            {isShowConfirmation && (
                <ConfirmationDialog
                    confirmText={conformationAlertContentRef.current.yesBtnText}
                    cancelText={conformationAlertContentRef.current.noBtnText}
                    show={isShowConfirmation}
                    onClickHeaderClose={closeConfirmationModal}
                    toggleDialog={closeConfirmationModal}
                    onConfirm={conformationAlertContentRef.current.onClickYes}
                    isYesInLeft={true}
                    showLoadingInConform={isLoadingCCAgain || isDeleting}
              >
                  <div dangerouslySetInnerHTML={{__html: conformationAlertContentRef.current.text}}/>
              </ConfirmationDialog>
            )}

            {showQuestionModal &&
                <ChiefComplaintQuestion
                    locationName={selectedBodyLocationRef.current?.name}
                    bodyLocation={selectedBodyLocationRef.current?.bodyLocation}
                    side={selectedBodyLocationRef.current?.side}
                    vasQestionHandler={setVasQuestion}
                    testId={testId}
                    closeModal={setShowQuestionModal}
                    onSubmit={onSubmit}
                    isReportAvailable={isReportAvailable}
                    updateAnswer={updateAnswer}
                    ccQuestion={ccQuestion}
                />}
        </>
    )
}

export default ChiefComplaint;
