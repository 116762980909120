import '../../dashboard/Dashboard.scss';
import React, { useContext, useState } from "react";
import { Modal, ModalBody, ModalHeader, UncontrolledTooltip } from "reactstrap";
import { Trans } from "react-i18next";
import AppContext from "../../../contexts/AppContext";
import { getCurrentYear } from "../../../utils/Utils";

const Footer = () => {
    const { authenticated } = useContext(AppContext);
    const [videoInformationModal, setVideoInformationModal] = useState(false);

    const onClickVideoInformationModal = (e) => {
        e.preventDefault();
        setVideoInformationModal(!videoInformationModal);
    };

    return (
        <>
            <div className="kt-footer kt-grid__item">
                <div className="kt-container kt-container--fluid ">
                    <div className="kt-footer__wrapper">
                        <div className="kt-footer__copyright">
                            &copy;{getCurrentYear()}&#x2024;&nbsp;
                            <a
                                rel="noopener noreferrer"
                                href="http://mymedicalhub.com/"
                                target="_blank"
                                className="kt-link">
                                <Trans i18nKey="common.page.all-rights-reserved"/>
                                &#8218; &nbsp;<Trans i18nKey="common.page.copyright"/>
                                &nbsp;<Trans i18nKey="common.page.company"/>
                            </a>
                        </div>
                        <div className="kt-footer__menu">
                            {authenticated && (
                                <>
                                    <a
                                        rel="noopener noreferrer"
                                        href={"!#"}
                                        target="_blank"
                                        className="kt-link"
                                        onClick={(e) => onClickVideoInformationModal(e)}
                                        id="showVideoInformationUncontrolToolTip">
                                        <Trans i18nKey="common.page.tips-for-video"/>
                                    </a>
                                    <UncontrolledTooltip placement="top" target="showVideoInformationUncontrolToolTip">
                                        4 tips for a great video
                                    </UncontrolledTooltip>
                                </>
                            )}
                            <a
                                rel="noopener noreferrer"
                                href="http://mymedicalhub.com/"
                                target="_blank"
                                className="kt-link d-none">
                                <Trans i18nKey="common.page.about"/>
                            </a>
                            <a
                                rel="noopener noreferrer"
                                href="http://mymedicalhub.com/contact/"
                                target="_blank"
                                className="kt-link d-none">
                                <Trans i18nKey="common.page.contact"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={videoInformationModal} className="vide-information-modal">
                <ModalHeader toggle={onClickVideoInformationModal}> Four Tips for a Great Video Experience</ModalHeader>
                <ModalBody>
                    <p><b>1. Use Plenty of Light.</b> Lighting makes a huge difference in the quality of a finished
                        experimental
                        video, so make it one of your top priorities during the experiment.
                        If you don’t use enough properly-placed light, your video will probably look bad. </p>
                    <p><b>2. Use a Clean Background.</b> One easy way to get a measurement result from your video is to
                        use a solid-colored background.
                        A wall, a bedsheet, or a large sheet of backdrop paper are all good options. Make sure your
                        subject stands several feet away from the backdrop to avoid casting shadows on it.</p>
                    <p><b>3. Avoid Shaky Footage.</b> Shaky footage will make any video look like bad.
                        It’s hard to hold a camera completely steady, so try not to hold your camera at all if you can
                        help it. Instead, use a tripod, or set your camera on a sturdy surface.</p>
                    <p><b>4. Follow Instructions.</b> When you will create a video for measurement please follow the
                        instructions properly to get a better measurement angle result.</p>
                    <br/>
                    <h6>These tips apply to all participants on the video. </h6>
                </ModalBody>
            </Modal>
        </>
    );
};

export default Footer;
