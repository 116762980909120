import { Trans, useTranslation } from "react-i18next";
import React  from "react";

import { playAudio } from "../../../utils/Utils";

const TakeAssessmentItem = (props) => {
    const { t } = useTranslation();
    const { title="", subTitle="", closeModal } = props;

    const onClickStart = (e) => {
        e.preventDefault();

        playAudio("remove");
        const { onClickStart } = props;
        onClickStart && onClickStart();
        closeModal && closeModal(false);
    };

    return (
        <div className="take-assessment-item-container">
            <div className="take-assessment-item-info">
                <p className="take-assessment-item-info--title">{t(title)}</p>
                <p className="take-assessment-item-info--subTitle">{t(subTitle)}</p>
            </div>
            <div>
                <a
                    rel="noopener noreferrer"
                    href={"!#"}
                    onClick={onClickStart}
                    className="take-assessment-item-btn"
                >
                        <Trans i18nKey="common.actions.click"/>
                </a>
            </div>
        </div>
    )
}

export default TakeAssessmentItem;