import { createAsyncThunk } from "@reduxjs/toolkit";
import ExamService from "../../services/ExamService";

export const fetchEditAssessmentDataAndInitData = createAsyncThunk(
    'assessment/fetchData',
    async (payload, thunkAPI) => {
        try {
            const response = await ExamService.getEditAssessmentData(payload);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);