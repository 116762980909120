import React from "react";

const SwitchButton = ({onChangeHandler, isChecked}) => {
    return (
        <div className="mmh-switch-btn d-flex align-items-center">
            <label>FRONT</label>
            <span className="kt-switch kt-switch--success kt-switch--sm">
                <label>
                    <input type="checkbox"
                           name="switchFrontBack"
                           onChange={onChangeHandler}
                           checked={!!isChecked}/>
                    <span/>
                </label>
            </span>
            <label>BACK</label>
        </div>
    )
}

export default SwitchButton;