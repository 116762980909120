import React from "react";
import { Modal, ModalBody } from "reactstrap";

import GLOBAL from "../../../global";
import crossBtn from "../../../assets/img/utils/cross.svg";
import ChiefComplaint from "./ChiefComplaint";

const ChiefComplaintWithBodyPart = (props) => {
    const { isOpen, chat, onSubmit, onClose } = props;

    const toggleChiefComplaintModal = () => {
        onClose && onClose();

        if(GLOBAL.ISANYCHIEFCOMPLAINTANSWEDED) {
            onSubmit(chat, chat?.answers[0]?.referenceId);
        }
    }

    return (
        <Modal isOpen={isOpen}
               toggle={toggleChiefComplaintModal}
               backdrop={'static'}
               className="modal-xl h-100 kt-m0"
               modalClassName="mmh-movement-modal cc-with-body-part">
            <div className="modal-header align-items-center">
                <h5 className="">CHIEF COMPLAINTS &nbsp;&nbsp;</h5>
                <img className="cursor-pointer" src={crossBtn} onClick={toggleChiefComplaintModal} alt="close" />

            </div>
            <ModalBody className="d-flex justify-content-center align-items-center">
                <div className="h-80vh cc-container">
                    <ChiefComplaint
                        testId={(GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['TestId']) || ''}
                        onSubmit={toggleChiefComplaintModal}
                    />
                </div>

            </ModalBody>
        </Modal>
    )
}

export default ChiefComplaintWithBodyPart;