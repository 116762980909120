import React, {useEffect, useRef, useState} from 'react';
import {_getStatusName, getExerciseIcon, getExerciseStatusStyle,} from "../../../utils/Movement";
import StartOrRetakeBtn from './StartOrRetakeBtn';
import RenderContent from './RenderBtn';
import ButtonComponent from './ButtonComponent';
import {Lightbox} from "react-modal-image";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {getExercises, getTotalCompleted} from './Services/MovementService';
import CONSTANT from '../../../constants/Constant';
import SelectedMovementBox from './SelectedMovementBox';
import {getBodyRegionNames} from '../../../utils/Utils';
import {canUserSkipExercise} from "./utilities/Utility";

const LeftSidePanel = ({exerciseList, filteredExerciseList, setFilteredExerciseList, onClickRetakeExercise,
     selectedExercise, isShowSelectMovementBox, toggleShowMovementBox, childExerciseExerciseCodeNameList, selectedRegions}) => {

    const [annotationImageUrl, setAnnotationImageUrl] = useState('');
    const [measurementVideoUrl, setMeasurementVideoUrl] = useState('');
    const [totalCompleted, setTotalCompleted] = useState(0);
    const hideRetakeBtnOfExerciseIfChildExercise = useRef([]);

    useEffect(()=>{
        async function fetchTotalCompleted(){
            let total = await getTotalCompleted(exerciseList, CONSTANT.EXERCISE_STATUS.COMPLETED.key, 
                CONSTANT.EXERCISE_STATUS.RETAKE_HUMAN_EXPERT.key,
                CONSTANT.EXERCISE_STATUS.SKIPPED.key);
            setTotalCompleted(total);
        }
        fetchTotalCompleted().then(() => {
            let tempExercises = getExercises(exerciseList, ['calibration']);
            if(tempExercises.length > 0) {
                hideRetakeBtnOfExerciseIfChildExercise.current = tempExercises.flatMap(exercise => exercise?.MergeExercises || []);
            }
        });
    }, [exerciseList, filteredExerciseList]);

    const onClickViewMeasurementImage = (exercise) => {
        if(exercise && Object.keys(exercise).length > 0){
            setAnnotationImageUrl(exercise['RenderImageUrl']);
        }
    };

    const onClickViewMeasurementVideo = (exercise) => {
        setMeasurementVideoUrl(exercise['RawVideoURL']);
    }

    const onClickCloseMeasurementImage = () => {
        setAnnotationImageUrl('');
    }

    const onClickVideoModalClose = () => {
        setMeasurementVideoUrl('');
    }

    const onChangeFilterExercises = (e) => {
        let filteredExercises = exerciseList.filter((exercise) => exercise.ExerciseDisplayName.toLowerCase().trim().match(e.target.value.toLowerCase()));
        
        if(filteredExercises){
            setFilteredExerciseList(prev => filteredExercises.slice());
        }
    }

    const hideMovementBox = () => {
        toggleShowMovementBox(false);
    }

    const shouldBlurExerciseCard = () => {
        return (!canUserSkipExercise(selectedExercise) ||(isShowSelectMovementBox && selectedExercise?.ExerciseCodeName));
    }

    return (
        <>
        <div>
        <div className="movement-searchbar">
            <div className="movement-searchbar-icon">
                <span className="movement-searchbar-span">
                    <i className="flaticon-search"/>
                </span>
            </div>
            <div className="movement-searchbar-vertical-line"></div>
            <div className="movement-searchbar-input">
                <input type="text" className="" placeholder="Find a movement" onChange={(e)=> onChangeFilterExercises(e)}/>
            </div>
        </div>
        <div className="movement-modal movement-body-region">
            Body Region <span>:</span>{getBodyRegionNames(selectedRegions?.bodyRegionList)}
        </div>
        <div className="movement-completed-of">
            {totalCompleted} completed of {exerciseList.length} movements
        </div>
        </div>
        <div className="position-relative" style={{height: "59.5vh"}}>
            <div className="overflow-auto selected-movementbox-container h-100-percent">
                {isShowSelectMovementBox && selectedExercise?.ExerciseCodeName && (
                    <SelectedMovementBox selectedExercise={selectedExercise} hideMovementBox={hideMovementBox}/>
                )}

                {filteredExerciseList.map((exercise,index) => {
                    return (
                        <>
                            <div className={`row bg-E1F4FF m-b-2 kt-widget2__item justify-content-between align-items-center ${shouldBlurExerciseCard() ? "movement-exercise-shadow-box" : ""}`}>
                                {/*<div className={`d-flex justify-content-between w-100-percent align-items-center ${exercise.IsInMergeExercise ? "kt-margin-l-10" : ""}`}>{console.log(childExerciseExerciseCodeNameList)}*/}
                                <div className={`d-flex justify-content-between w-100-percent align-items-center ${childExerciseExerciseCodeNameList.includes(exercise.ExerciseCodeName) ? "kt-margin-l-10" : ""}`}>
                                    <div className='d-flex align-items-center'>
                                        <span className="kt-widget2__icon">
                                                <img src={getExerciseIcon(exercise['Value'])} alt="movement icon"/>
                                            </span>
                                        <div>
                                            {/*<span>{exercise.Name}</span>*/}
                                            <span>{exercise.ExerciseDisplayName}</span>
                                            <div className="d-flex">
                                            <span className={`kt-font-transform-c ${getExerciseStatusStyle(exercise.Status)}`}>
                                                <small>{_getStatusName(exercise.Status, exercise?.IsSkipExercise)}</small>
                                            </span>
                                                {_getStatusName(exercise.Status, exercise?.IsSkipExercise) === CONSTANT.EXERCISE_STATUS.SKIPPED.key
                                                    ?
                                                        (
                                                            <span className={`kt-font-transform-c ml-3 ${getExerciseStatusStyle(exercise.Status)}`}>
                                                                <small>Unable to Perform</small>
                                                            </span>
                                                        )
                                                    :
                                                    (
                                                        <>
                                                            {exercise['RenderImageUrl'] && (
                                                                <RenderContent
                                                                    onClickViewContent={onClickViewMeasurementImage}
                                                                    exercise={exercise}
                                                                    text="Annotation"
                                                                    cssClass="fas fa-image"
                                                                />
                                                            )}
                                                            {exercise['RawVideoURL'] && (
                                                                <RenderContent
                                                                    onClickViewContent={onClickViewMeasurementVideo}
                                                                    exercise={exercise}
                                                                    text="Video"
                                                                    cssClass="fas fa-image"
                                                                />
                                                            )}
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        {
                                            canUserSkipExercise(selectedExercise) &&
                                            !hideRetakeBtnOfExerciseIfChildExercise.current.includes(exercise?.ExerciseCodeName) &&

                                            (
                                                <StartOrRetakeBtn
                                                    onClickStartOrRetakeExercise={() => onClickRetakeExercise(exercise, false, true)}
                                                    exercise={exercise}
                                                />
                                            )}
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                })}
                {annotationImageUrl !== '' && (
                    <Lightbox
                        // medium={annotationImageUrl}
                        large={annotationImageUrl}
                        hideZoom={true}
                        alt="Measurement"
                        hideDownload={true}
                        onClose={onClickCloseMeasurementImage}
                    />
                )}
                <Modal isOpen={measurementVideoUrl !== ''} className="modal-xl" centered>
                    <ModalHeader>
                        Exercise Video &nbsp;&nbsp;
                        <ButtonComponent
                            btnClass="btn btn-danger btn-sm w-110 float-right kt-mr-30"
                            btnName="Close"
                            onClickHandler={onClickVideoModalClose}
                        />
                    </ModalHeader>
                    <ModalBody>
                        <video autoPlay controls src={measurementVideoUrl} className="w-100"/>
                    </ModalBody>
                </Modal>
            </div>
        </div>
    </>
    );
}

export default LeftSidePanel;