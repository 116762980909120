import { fetchEditAssessmentDataAndInitData } from './editAssessmentThunks';
import { EDIT_ASSESSMENT_STATUS } from "./editAssessmentConstants";
import { initEditAssessmentFeature } from "./editAssessmentFeatureService";

const editAssessmentExtraReducers = (builder) => {
    builder
        .addCase(fetchEditAssessmentDataAndInitData.pending, (state) => {
            state.status = EDIT_ASSESSMENT_STATUS.LOADING;
        })
        .addCase(fetchEditAssessmentDataAndInitData.fulfilled, (state, action) => {
            state.status = EDIT_ASSESSMENT_STATUS.SUCCEEDED;
            state.data = action.payload;
            initEditAssessmentFeature(state, action.payload)
        })
        .addCase(fetchEditAssessmentDataAndInitData.rejected, (state, action) => {
            state.status = EDIT_ASSESSMENT_STATUS.FAILED;
            state.error = action.payload;
        });
};

export default editAssessmentExtraReducers;