import React, { useState, useRef, useEffect } from 'react';

import dropDown from '../../assets/img/mmh-table/dropDown.svg'
import dropUp from "../../assets/img/mmh-table/dropUp.svg";


const SelectOption = ({ onChange, value, values }) => {
    const [ willOpenOption, setWillOpenOption ] = useState(false);
    const optionsRef = useRef(null);

    const getOptionName = () => {
        return values.find(val => val.key === value).name;
    }

    const toggleWillOpenOption = () => {
        setWillOpenOption(!willOpenOption);
    }

    function optionHandleClickOutside(event) {
        if (optionsRef.current && !optionsRef.current.contains(event.target)) {
            setWillOpenOption(false);
        }
    }

    const onClickOption = (value) => {
        toggleWillOpenOption();
        onChange(value)
    }

    useEffect(() => {
        document.addEventListener('mousedown', optionHandleClickOutside);

        return () => {
            document.removeEventListener('mousedown', optionHandleClickOutside);
        };
    }, [optionsRef]);

    return (
        <div className="wrapper" ref={optionsRef}>
            <div className="select-option filter_width_height kt-space-between" onClick={toggleWillOpenOption}>
                <span className="">{getOptionName(value)}</span>
                <div className="separator">
                    <div className=""></div>

                    <img src={willOpenOption? dropUp : dropDown} alt="arrowDownUp" />
                </div>

            </div>
            {willOpenOption && (
                <div className="w-300px d-flex justify-content-center position-relative">
                    <div className="content">
                        <ul className="options">
                            {values.map(val => {
                                return (
                                    <li onClick={() => onClickOption(val.key)} value={val.key} key={val.key}>
                                        {val.name}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>

            )}
        </div>
    )
}

export default SelectOption;