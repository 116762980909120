import Notification from "./Notification";
export const mapNotificationDataToLocalNotificationModal = (notificationData) => {
    const mappedData = []

    if(notificationData instanceof Array) {
        notificationData.forEach(notification => {
            mappedData.push(new Notification(notification))
        })
    }

    return mappedData
}