import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import AddPaymentCardForm from "./AddPaymentCardForm";
import UpgradePlan from "./UpgradePlan";

const OverallPlan = () => {

    const [openAddPaymentMethodModal, setOpenAddPaymentMethodModal] = useState(false);
    const [openUpgradePlanModal, setOpenUpgradePlanModal] = useState(false);

    const toggleAddPaymentMethodModal = (e) => {
        e.preventDefault();
        setOpenAddPaymentMethodModal(!openAddPaymentMethodModal);
    }

    const ToggleUpdatePlanModal = () => {
        setOpenUpgradePlanModal(!openUpgradePlanModal);
    }
    return (
        <>
            <div className="kt-portlet kt-portlet--height-fluid">
                <div className="kt-portlet__body kt-portlet__body--fluid">
                    <div className="kt-widget12">
                        <div className="kt-widget12__content">
                            <div className="alert alert-solid-warning alert-bold" role="alert">
                                <div className="alert-icon"><i className="flaticon-warning"/></div>
                                <div className="alert-text kt-font-dark">
                                    <h4>We need your attention!</h4>
                                    <span
                                        className="text-muted">Your payment was declined. To start using tools, please</span>
                                    <a href={"!#"} className="kt-link kt-font-boldest kt-ml-5"
                                       onClick={toggleAddPaymentMethodModal}>
                                        Add Payment Method
                                    </a>
                                </div>
                            </div>
                            <div className="kt-widget12__item kt-font-dark justify-content-between">
                                <div className="kt-widget12__info kt-mb-5">
                                    <span className="kt-widget12__value">Active until Dec 09, 2021</span>
                                    <span className="kt-widget12__desc">We will send you a notification upon Subscription expiration</span>
                                </div>
                                <div className="kt-widget12__info">
                                    <div className="d-flex justify-content-between">
                                        <span className="kt-widget12__value">Users</span>
                                        <span className="kt-widget12__value">86 of 100 Used</span>
                                    </div>
                                    <div className="progress kt-mb-5">
                                        <div className="progress-bar bg-success" role="progressbar"
                                             style={{ width: 25 + '%' }}
                                             aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                        </div>
                                    </div>
                                    <span className="kt-widget12__desc">14 Users remaining until your plan requires update</span>
                                </div>
                            </div>
                            <div className="kt-widget12__item kt-font-dark">
                                <div className="kt-widget12__info">
                                                <span className="kt-widget12__value">$24.99 <span
                                                    className="text-muted">Per Month</span></span>
                                    <span className="kt-widget12__desc">Extended Pro Package. Up to 100 Agents & 25 Projects</span>
                                </div>
                                <div className="kt-widget12__info">
                                    <button className="btn btn-success float-right" data-bs-toggle="modal"
                                            onClick={ToggleUpdatePlanModal}
                                            data-bs-target="#kt_modal_upgrade_plan">Upgrade Plan
                                    </button>
                                    <a href={"!#"} className="btn btn-secondary float-right kt-mr-5">Cancel
                                        Subscription</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openAddPaymentMethodModal && (
                <Modal isOpen={openAddPaymentMethodModal} className="modal-md">
                    <ModalHeader toggle={toggleAddPaymentMethodModal}>
                        Add New Card
                    </ModalHeader>
                    <ModalBody>

                        <AddPaymentCardForm/>
                    </ModalBody>
                </Modal>
            )}
            {openUpgradePlanModal && (
                <Modal isOpen={openUpgradePlanModal} className="modal-xl">
                    <ModalHeader toggle={ToggleUpdatePlanModal} className="border-0">

                    </ModalHeader>
                    <ModalBody>
                        <UpgradePlan/>
                    </ModalBody>
                </Modal>
            )}
        </>
    )
}

export default OverallPlan;