import CONSTANT from "../../constants/Constant";

const questionIdsDoesNotRequireValidation = [
    CONSTANT.OUTCOME_SCORE_QUESTIONS.Neck.PScore,
    CONSTANT.OUTCOME_SCORE_QUESTIONS.Neck.OSWScore,

    CONSTANT.OUTCOME_SCORE_QUESTIONS.Arms.None,
    CONSTANT.OUTCOME_SCORE_QUESTIONS.Arms.Mild,
    CONSTANT.OUTCOME_SCORE_QUESTIONS.Arms.Moderate,
    CONSTANT.OUTCOME_SCORE_QUESTIONS.Arms.Severe,
    CONSTANT.OUTCOME_SCORE_QUESTIONS.Arms.Extreme,
    CONSTANT.OUTCOME_SCORE_QUESTIONS.Arms.Score,

    CONSTANT.OUTCOME_SCORE_QUESTIONS.Back.OSWScore,
    CONSTANT.OUTCOME_SCORE_QUESTIONS.Back.PScore,

    CONSTANT.OUTCOME_SCORE_QUESTIONS.Legs.Score,
]

export {
    questionIdsDoesNotRequireValidation
}