import React from "react";

import CONSTANT from "../../../constants/Constant";
import { getBodyRegionNames } from "../../../utils/Utils";
import {timeConverterComponent} from "./business/services";

const NotificationInfo = ({ notificationData, children }) => {
  const { MeetingDate, InvitationTypeId, InvitationTypeName, BodyRegionList, TestId } = notificationData;
  const { date, startTime, endTime } = timeConverterComponent(MeetingDate || "");

  return (
      <div className='notification-info-item-container'>
        <table>
          <tbody>
          {TestId && (
              <tr className="">
                <td className='notification-item-content-info-level'>Test ID</td>
                <td className='notification-item-content-info-value'><strong className="mr-2">: </strong>{TestId}</td>
              </tr>
          )}
          {(MeetingDate !== "" && (InvitationTypeId === CONSTANT.INVITATION_TYPE.InClinic || InvitationTypeId === CONSTANT.INVITATION_TYPE.Telemedicine)) && (
              <>
                <tr className="">
                  <td className='notification-item-content-info-level'>Date</td>
                  <td className='notification-item-content-info-value'><strong className="mr-2">: </strong>{date}</td>
                </tr>
                <tr className="">
                  <td className='notification-item-content-info-level'>Time</td>
                  <td className='notification-item-content-info-value'><strong className="mr-2">: </strong>{`${startTime} - ${endTime}`}</td>
                </tr>
              </>
          )}
          {InvitationTypeId !== CONSTANT.INVITATION_TYPE.PainLog && (
              <tr className="">
                <td className='notification-item-content-info-level'>Region</td>
                <td className='notification-item-content-info-value'><strong className="mr-2">: </strong>{getBodyRegionNames(BodyRegionList)}</td>
              </tr>
          )}
          {InvitationTypeName && (
            <tr className="">
                <td className='notification-item-content-info-level'>Assessment Type</td>
                <td className='notification-item-content-info-value'><strong className="mr-2">: </strong> {InvitationTypeName}</td>
                <td>{children}</td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
  );
}

export default NotificationInfo;
