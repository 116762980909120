import React, {useEffect, useState} from "react";

const BodyPart = (props) => {
   const { isChiefComplain, vasQuestionHandler,imageSide, styleName, imageLink, showFirst } = props
   const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            if (innerWidth !== window.innerWidth) {
                setInnerWidth(window.innerWidth);
            }

        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div
            className={`w-90 body-height justify-content-around box mt-3 d-flex w-50 ${(showFirst || innerWidth > 720) ? ' row show ' : ' hide '}`}>
            <div className="col-lg-5 d-flex justify-content-center body_cont height-inherite position-relative">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                     onClick={(e) => vasQuestionHandler(e)}
                     xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 381 944"
                     className={`Body__Svg-ko2vkp-0 bewFWD skeleton_svg ${styleName} z-index-1 ${isChiefComplain ? "chief-complain-body" : ''}`}>
                    <image xlinkHref={imageLink}/>
                    {/*<image width="381" height="944" xlinkHref={imageLink}/>*/}
                    {props.children}
                </svg>
                <span
                    className={`d-flex justify-content-between ${isChiefComplain ? 'chief-complain-body-indicator' : ''}`}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        width: '100%',
                        transform: 'translateY(-50%)',
                    }}>
                <span className='body-point-text margin-left'>
                    {imageSide.toLowerCase() === 'front' ? 'RIGHT' : 'LEFT'}
                </span>
                <span className='body-point-text margin-right'>
                    {imageSide.toLowerCase() === 'front' ? 'LEFT' : 'RIGHT'}
                </span>
            </span>
            </div>

            <div className="col-lg-6 h-100 overflow-y-auto">
                {props.pointListComponent}
            </div>
        </div>
    )
}

export default BodyPart
