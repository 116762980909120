import CONSTANT from "../constants/Constant";
import GLOBAL from "../global";
import { storeUserInfoToLocalStorageFromGlobalConstant } from "../utils/Utils";

export const saveDemoInfoAtLocalStorage = (questionId, answer) => {
    if (questionId === CONSTANT.CHATBOT_ACCOUNT.HEIGHT) {
        GLOBAL.USER_INFO = {
            ...GLOBAL.USER_INFO,
            Height: +answer.AnswerValue
        };
    }
    if (questionId === CONSTANT.CHATBOT_ACCOUNT.WEIGHT) {
        GLOBAL.USER_INFO = {
            ...GLOBAL.USER_INFO,
            Weight: answer.AnswerValue
        };
    }
    if (questionId === CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH) {
        GLOBAL.USER_INFO = {
            ...GLOBAL.USER_INFO,
            DateofBirth: answer.AnswerValue
        };
    }
    //DOMINANT_HAND is not in use
    if (questionId === CONSTANT.CHATBOT_ACCOUNT.DOMINANT_HAND) {
        GLOBAL.USER_INFO = {
            ...GLOBAL.USER_INFO,
            DominantHand: answer.AnswerId,
            DominantHandName: answer.AnswerTitle
        };
    }
    if (questionId === CONSTANT.CHATBOT_ACCOUNT.GENDER) {
        GLOBAL.USER_INFO = {
            ...GLOBAL.USER_INFO,
            Gender: answer?.AnswerTitle || answer?.AnswerValue
        };
    }

    storeUserInfoToLocalStorageFromGlobalConstant();
}