import React from "react"
import reload from "../../assets/img/utils/reload.svg";
import lastIncompleteAssessment from "../../assets/img/utils/last-incomplete-assessment.svg";

const DashboardItem = (props) => {
    const {title, largeTitle, refreshHandler, loading = false, stepbar = null, imgUrl = null, hasItem = true, overflow = false, showOnlyOneImage = true} = props

    const handlerRefresh = () => {
        refreshHandler && refreshHandler()
    }

    return (
        <div>
            <div className="kt-portlet kt-portlet--height-fluid rounded-10 ">
                <div className="kt-portlet__head dashboard-item-wrapper">
                    <div className="kt-portlet__head-label">
                        <h3 className="heading-primary kt-m0">{title}</h3>
                    </div>
                    {refreshHandler && (
                        <div className="kt-portlet__head-border-none">
                            <div className="kt-portlet__head-label">
                                <div className="kt-portlet__head-title kt-font-transform-u">
                                    <img src={reload} alt="Reload Button" className="cursor-pointer responsive_refresh_btn" onClick={handlerRefresh} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className={`flex-column dashboard-item-content-minheight d-flex justify-content-center ${overflow ? 'overflow-auto' : " "}`} >
                    {loading && <div className="kt-spinner kt-spinner--lg kt-spinner--info center-element"/>}
                    {(!loading && hasItem) &&
                        <div className="d-flex justify-content-between position-relative">
                            <div className="dashboard-item-content-text">
                                <h1 className="heading-large dashboard-item-wrapper">{largeTitle}</h1>
                                <div className="flex-1">
                                    {props.children}
                                </div>
                            </div>
                            {/*{showOnlyOneImage && (*/}
                            {/*    <div className="dashboard-item-photo-container">*/}
                            {/*        <img src={lastIncompleteAssessment} alt="last incomplete assessment" />*/}
                            {/*    </div>*/}
                            {/*)}*/}

                        </div>
                    }
                    {!hasItem && (
                        <div className="d-flex justify-content-between h-100-percent">
                            <div className="d-flex align-items-center">
                                <h1 className="heading-large dashboard-item-wrapper">{largeTitle}</h1>
                            </div>
                            {/*<div className="h-100-percent ">*/}
                            {/*    <img className="h-100-percent object-fit-scale-down" src={imgUrl} alt="img" />*/}
                            {/*</div>*/}
                        </div>
                    )}
                </div>
                {hasItem && (
                    <>
                        {/*<div className="dashboard-item-spepbar-container" style={{height: stepbar === null ? "4rem" : "1rem"}}></div>*/}
                        {stepbar !== null && stepbar}
                    </>
                )}

            </div>
        </div>
    )
}

export default DashboardItem;