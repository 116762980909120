import { fetchResumeChatThunks } from "./chatbotThunks";
import { handleResumeChat } from "./chatbotFeatureService";
import { toast } from "react-toastify";

const chatbotExtraReducers = (builder) => {
    builder
        .addCase(fetchResumeChatThunks.pending, (state) => {
            const resumeChats = state?.resumeChats;
            const chatBotUIState = state?.chatBotUIState;

            resumeChats.isLoading = true;
            chatBotUIState.isFetchingChatQuestion = true;
            chatBotUIState.showWelcomePage = true;
        })
        .addCase(fetchResumeChatThunks.fulfilled, (state, action) => {
            const resumeChats = state?.resumeChats;
            const chatBotUIState = state?.chatBotUIState;

            handleResumeChat(state, action.payload);
            resumeChats.isLoading = false;
            chatBotUIState.isFetchingChatQuestion = false;
            chatBotUIState.showWelcomePage = false;
        })
        .addCase(fetchResumeChatThunks.rejected, (state, action) => {
            const resumeChats = state?.resumeChats;
            const chatBotUIState = state?.chatBotUIState;
            const error = action?.error;

            resumeChats.isLoading = false;
            chatBotUIState.isFetchingChatQuestion = false;
            chatBotUIState.showWelcomePage = false;

            toast.error("Internal Server Error");
            console.error(error);
        });
}

export default chatbotExtraReducers;