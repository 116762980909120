import styled from "styled-components";

export const ChatbotButton = styled.button`
          ${({ color, fill, disabled = false }) => color && (`
              ${fill === "true" ? `background-color: ${color}; color: #ffff !important` : ''};
              ${fill === "false" ? `color: ${color} !important` : 'color: #fff !important'};
              ${disabled === true ? `background-color: #666666 !important; color: #000 !important; cursor: not-allowed !important` : ''};
              border-color: ${color} !important;
              padding-left: '10px',
              padding-right: '10px',
              :hover {
                background-color: ${color} !important;
                border-color: ${color} !important;
                color: #FFFF !important;
              }
          `)}
    `;