import React from "react";
import { getColor } from './utils'
import MyAssessmentTooltip from "../../my-assessments/MyAssessmentTooltip";

const BodyPoints = ({points = [], answeredVasQuestion, side}) => {

    const prepareColorForBodyPoint = (bodyLocation) => {
        const location = answeredVasQuestion[bodyLocation]
        return getColor(location?.painValue)
    }

    return (
        <>
            {points.map(el => {
                if(el?.cx && el.cy && +el.side === side)
                    return (
                        <React.Fragment key={el.bodyLocation}>
                            <circle
                                id={el.bodyLocation}
                                data-locationname={el.subBodyRegion}
                                data-side={side}
                                data-name={el.name}
                                className={`cursor-pointer ${prepareColorForBodyPoint(el.bodyLocation)}`}
                                cx={el?.cx} cy={el?.cy} r="7"
                                fill={prepareColorForBodyPoint(el.bodyLocation)} />
                            <MyAssessmentTooltip target={el.bodyLocation} text={el.subBodyRegion} />
                        </React.Fragment>
                    )
            })}
        </>
    )
}

export default BodyPoints