import React, { useContext, useState } from "react";
import { isMobile } from "react-device-detect";

import AppContext from "../../../contexts/AppContext";
import LeftSideMenu from "./LeftSideMenu";
import Breadcrumb from "../Breadcrumb";
import TopSideMenu from "./TopSideMenu";

const BodyWithTopMenu = (props) => {
    const { children, pageName } = props;
    const { authenticated, showTopNavigationInMobile, toggleShowTopNavigationInMobile } = useContext(AppContext);

    const closeSideMenuInMobile = () => {
        if(showTopNavigationInMobile) {
            toggleShowTopNavigationInMobile();
        }
    }

    return (
        <div
            className="kt-body kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-grid--stretch">
            {/*<div className="kt-container  kt-container--fluid  kt-grid kt-grid--ver kt-padding-l-0">*/}
            <div className="">
                {authenticated && (
                    <>
                        <div
                            className={`${showTopNavigationInMobile ? " left-0 " : ""}`}
                            style={{zIndex: "11"}}
                        >
                            <div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid">
                                <div className="mb-3">
                                    <TopSideMenu />
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div
                    className={`kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor backdrop align-items-center justify-content-center`}
                    onClick={closeSideMenuInMobile}
                >
                    {/*<Breadcrumb pageName={pageName || ''} />*/}
                    <div
                        // className={`d-flex flex-wrap kt-container kt-container--fluid mt-2 ${isMobile ? '' : 'kt-padding-r-0'}`}>
                        className={`d-flex flex-wrap w-95vw mt-2 ${isMobile ? '' : 'kt-padding-r-0'}`}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BodyWithTopMenu
