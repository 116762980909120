import React, { Fragment, useEffect, useState } from 'react';
import {
    useTable,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
} from 'react-table';
import { Table} from 'reactstrap';
import { Filter, DefaultColumnFilter } from './filters';

const MmhTable = ({ columns, data,loading, renderRowSubComponent, flagForFilter, filterColumns }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        visibleColumns,
    } = useTable(
        {
            columns,
            data,
            loading,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: { pageIndex: 0, pageSize: 30 },
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
    );

    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(0);

    useEffect(() => {
        setStartIndex(0);
        setEndIndex(5);

    }, [startIndex, endIndex])

    const generateSortingIndicator = (column) => {
        return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
    };

    return (
        <div className="dataTables_wrapper dt-bootstrap4 mt-3">
            <div className="row">
                <div className="col-sm-12" >
                    {loading ? <div className="alert alert-secondary kt-mb-0 kt-mt-10" role="alert">
                        <div className="alert-icon">
                            <i className="flaticon-warning kt-font-brand"/>
                        </div>
                        <div className="alert-text">
                            Please wait, loading data &nbsp;&nbsp;
                            <i className="fa fa-spinner fa-spin"/>
                        </div>

                    </div> :
                        <Table
                            className="report-assessment-table table-checkable no-footer dtr-inline collapsed color-primary" {...getTableProps()}>
                            <thead className="kt-datatable__head">
                            {headerGroups.map((headerGroup) => (
                                <tr className="kt-datatable__row report-assessment-table-header"
                                    style={{ left: "0px" }} {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th className="kt-datatable__cell kt-datatable__cell--sort report-assessment-table-header__cell bg-color-ice-blue kt-font-bolder font-size-md" {...column.getHeaderProps()}>

                                            <div {...column.getSortByToggleProps()}>
                                                <div className="">
                                                    <div className="d-flex justify-content-center ">
                                                        {column.render('Header')}
                                                    </div>

                                                    {column?.HeaderSecLine && (
                                                        <div className="d-flex justify-content-center ">
                                                            {column?.HeaderSecLine}
                                                        </div>
                                                    )}
                                                </div>

                                                {generateSortingIndicator(column)}
                                            </div>

                                            {!flagForFilter && (
                                                <Filter column={column}/>
                                            )}

                                            {flagForFilter && filterColumns?.length > 0 && (
                                                filterColumns.map((item, index) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            {column.id === item && (
                                                                <Filter column={column}/>
                                                            )}
                                                        </Fragment>
                                                    )
                                                })
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>

                            <tbody className="kt-datatable__body" {...getTableBodyProps()}>

                            {/* Table Rows */}
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <Fragment key={row.getRowProps().key}>
                                        <tr className="kt-datatable__row kt-font-bold" style={{ left: "0px" }}>
                                            {row.cells.map((cell) => {
                                                return (
                                                    <td className="kt-datatable__cell table-cell" {...cell.getCellProps()}>
                                                        <div className={cell.column.style ? cell.column.style : 'w-100'}>
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                {cell.render('Cell')}
                                                            </div>
                                                        </div>
                                                    </td>
                                                );
                                            })}
                                        </tr>

                                        {row.isExpanded && (
                                            <tr className="kt-datatable__row" style={{ left: "0px" }}>
                                                <td className="kt-datatable__cell"
                                                    colSpan={visibleColumns.length}>
                                                    <span style={{ width: "118px" }}>
                                                        {renderRowSubComponent(row)}
                                                    </span>
                                                </td>
                                            </tr>
                                        )}

                                    </Fragment>
                                );
                            })}

                            </tbody>
                        </Table>}
                </div>
            </div>

        </div>
    );
};

export default MmhTable;
