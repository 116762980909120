import React, { useEffect } from "react";
import {useParams, useLocation, useHistory, Link} from "react-router-dom";
import { useSelector } from "react-redux";

import useEditAssessment from "./hooks/useEditAssessment";
import { EDIT_ASSESSMENT_STATUS } from "../../features/editAssessment/editAssessmentConstants";
import EditAssessmentHeader from "./EditAssessmentHeader";
import EditAssessmentDialogContainer from "./EditAssessmentDialogContainer";
import EditAssessmentQuestionBody from "./EditAssessmentQuestionBody";
import useScroll from "../../hooks/useScroll";
import CONSTANT from "../../constants/Constant";
import AssessmentNotFound from "../shared/AssessmentNotFound";

const EditAssessment = () => {
    let { testId } = useParams();
    let location = useLocation();
    const history = useHistory();
    const editAssessmentContainerRef = React.useRef(null);

    const setSelectedElementForScroll = useScroll(editAssessmentContainerRef);
    const { initEditAssessment, clearEditAssessment } = useEditAssessment();

    const queryParams = new URLSearchParams(location.search);
    let groupId = queryParams.get('groupId');

    useEffect(() => {
        //Ids of group those can be preselected
        const groupIdsToResume = [CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key, CONSTANT.ASSESSMENT_GROUP.ADL.key,
        CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.key, CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key];
        if(!groupId || !parseInt(groupId) || !groupIdsToResume.includes(parseInt(groupId))) {
            history.replace({ pathname: location.pathname });
            groupId = "";
        }

        initEditAssessment(testId, groupId);

        return () => {
            clearEditAssessment();
        }

    }, []);

    const editAssessmentState = useSelector(state => state.editAssessment);

    return (
        <div
            ref={editAssessmentContainerRef}
            className={"h-100-percent overflow-auto"}
        >
            {editAssessmentState?.status === EDIT_ASSESSMENT_STATUS.LOADING  && (
                <div className="edit-assessment-loading-message">
                    <div className="alert-text kt-ml-5 kt-mr-5 kt-font-xl kt-font-bold">
                        <h1>Please wait, loading your assessment... &nbsp;&nbsp;
                            <i className="fa fa-spinner fa-spin kt-font-primary"/>
                        </h1>
                    </div>
                </div>
            )}

            {(!editAssessmentState?.data && (editAssessmentState?.status !== EDIT_ASSESSMENT_STATUS.LOADING)) && (
                <AssessmentNotFound />
            )}

            {editAssessmentState?.data && (editAssessmentState?.status !== EDIT_ASSESSMENT_STATUS.LOADING) && (
                <EditAssessmentHeader/>
            )}

            <EditAssessmentQuestionBody
                setSelectedElementForScroll={setSelectedElementForScroll}
            />

            <EditAssessmentDialogContainer/>
        </div>
    );
}

export default EditAssessment;