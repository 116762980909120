import React, { useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";

import GLOBAL from "../../global";
import CONSTANT from "../../constants/Constant";
import routes from "../../constants/Routes";
import exerciseService from "../../services/ExerciseService";
import MyExercise from "../../models/MyExerise";
import Refresh from "../shared/Refresh";
import ExerciseDetails from "./ExerciseDetails";
import AssessmentNotFound from "../shared/AssessmentNotFound";

const MyExercisesDetails = (props) => {
    const { testId, isFromMyExercisesTable } = props;
    const [loadingExercise, setLoadingExercise] = useState(true);
    const subscription = useRef(true);
    const [exercises, setExercises] = useState([]);
    const [redirectToMyAssessments, setRedirectToMyAssessments] = useState(false);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        getMyExercises();

        return () => {
            subscription.current = false;
            GLOBAL.ASSESSMENT = null;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps,
    }, []);

    const callGetMyExercisesApi = async () => {
        const payload = {
            start: 0,
            TestId: testId,
            pageSize: 500
        };
        const response = await exerciseService.getMyExercises(payload);
        const { data, success, error } = response;

        if (subscription.current) {
            if (!success || !data) {
                console.error(error);
                return [];
            }

            let _exercises = [];
            const assessments = data || '[]';
            if (assessments?.length > 0) {
                assessments.forEach((assessment) => {
                    const myExercise = new MyExercise(assessment);
                    _exercises.push(myExercise);
                })
            }
            setEachExerciseStatus(_exercises);
            setExercises(_exercises);
        }
    }

    const getMyExercises = async () => {
        await callGetMyExercisesApi()
        setLoadingExercise(false);
    };

    const setEachExerciseStatus = (exercises) => {
        if (exercises && exercises.length > 0) {
            exercises.forEach((exercise) => {
                if (exercise.AssignedReps <= exercise.CompletedReps && exercise.AssignedSets <= exercise.CompletedSets) {
                    exercise.Status = CONSTANT.STATUS.COMPLETED;
                } else {
                    exercise.Status = CONSTANT.STATUS.PENDING;
                }
            })
        }
    };

    return (
        <>
            {redirectToMyAssessments && (
                <Redirect to={`${routes.myExercises}/${false}`}/>
            )}
            {!loadingExercise && exercises.length <= 0
                ?
                    <AssessmentNotFound />
                :
                (
                    <div className="kt-portlet kt-portlet--bordered">
                        <div className="kt-portlet__body">
                            <>
                                {isFromMyExercisesTable && (
                                    <div>
                                        <button className="btn btn-primary"
                                                onClick={() => setRedirectToMyAssessments(true)}>
                                            <i className="fa fa-angle-left"/>
                                            Back to My Assessments
                                        </button>
                                        <Refresh refreshFunction={getMyExercises}/>
                                    </div>
                                )}
                                {loadingExercise && (
                                    <div className="alert alert-secondary kt-mb-0 kt-mt-10" role="alert">
                                        <div className="alert-icon">
                                            <i className="flaticon-warning kt-font-brand"/>
                                        </div>
                                        <div className="alert-text">
                                            Please wait, loading data &nbsp;&nbsp;
                                            <i className="fa fa-spinner fa-spin"/>
                                        </div>
                                    </div>
                                )}
                            </>

                            {!loadingExercise && (
                                <div className="d-flex w-100 flex-wrap justify-content-between kt-mt-10">

                                    {exercises && exercises.length > 0 && exercises.map((item, index) => {
                                        return (
                                            <ExerciseDetails key={index} exercise={item}
                                                             getMyExercises={callGetMyExercisesApi}/>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        </>
    )

};

export default MyExercisesDetails;
