import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import cache from 'i18next-localstorage-cache';
import postProcessor from 'i18next-sprintf-postprocessor';
import languageDetector from 'i18next-browser-languagedetector';

import CONSTANT from "./constants/Constant";
import translationEN from './assets/lang/en';
import translationES from './assets/lang/es';

const resources = {
    en: {
        translation: translationEN
    },
    es: {
        translation: translationES
    }
};

const options = {
    debug: false,
    saveMissing: false,
    lng: CONSTANT.DEFAULT_LANGUAGE,
    fallbackLng: CONSTANT.DEFAULT_LANGUAGE,
    defaultNs: 'translation',
    preload: false,
    lowerCaseLng: false,
    cleanCode: false,
    interpolation: {
        escapeValue: false
    },
    react: {
        wait: true
    },
    resources: resources
};

i18n
    .use(initReactI18next)
    .use(cache)
    .use(languageDetector)
    .use(postProcessor)
    .init(options);

export default i18n;
