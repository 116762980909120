import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchResumeChatThunks } from "../../../../features/chat/chatbotThunks";
import { setTestId } from "../../../../features/chat/chatbotSlice";

const useResumeChatbot = () => {
    const dispatch = useDispatch();

    const botState = useSelector(state => state.chatBot);
    const botData = botState?.botData || {};
    const selectedBotInfoObj = botData?.botInfo || {};
    const testIdToResumeChat = botData?.testIdToResumeChat || "";

    const { options } = selectedBotInfoObj;

    const resumeChatBot = async () => {
        if(!testIdToResumeChat) {
            toast.error("Test Id is missing");
            console.error("Test Id is missing");
            return;
        }

        getChatBotData(testIdToResumeChat);
    }

    const getChatBotData = async (testId) => {
        if(!testId) return;

        if(!testId) {
            toast.error('Test Id is missing');
            console.error('Test Id is missing');
        }

        dispatch(setTestId(testId));
        const payload = {
            testId,
            botName: options?.name || ""
        };
        dispatch(fetchResumeChatThunks({ payload }));
    }

    return {
        resumeChatBot
    }
}

export default useResumeChatbot;