import React, { useEffect, useRef } from 'react';

const UserMediaWebcam = ({ videoConstraints, onUserMedia, onUserMediaError, webcamRef, onPlay, mirrored }) => {
    const streamRef = useRef(null);

    useEffect(() => {
        getMedia();

        return () => {
            if (streamRef.current) {
                const tracks = streamRef.current.getTracks();
                tracks.forEach(track => {
                    track.stop();
                });
                if (webcamRef.current) {
                    webcamRef.current.srcObject = null;
                }
            }
        };
    }, [videoConstraints?.deviceId, onUserMedia, onUserMediaError]);

    async function getMedia() {
        try {
            streamRef.current = await navigator.mediaDevices.getUserMedia({
                video: videoConstraints,
            });
            if (webcamRef.current) {
                webcamRef.current.srcObject = streamRef.current;
                onUserMedia();
            }
        } catch (e) {
            onUserMediaError(e);
        }
    }

    return (
        <video
            ref={webcamRef}
            style={{
                height: '100%',
                width: '100%',
                objectFit: 'fill',
                position: 'absolute',
                transform: mirrored ? 'scaleX(-1)' : 'none',
            }}
            autoPlay
            playsInline
            muted
            disablePictureInPicture
            // onPlay={onPlay}
        />
    );
};

export default UserMediaWebcam;
