import axios from './Axios';
import { error, success } from "./ResponseHandler";
import GLOBAL from "../global";
import CONSTANT from "../constants/Constant";

const RESPONSE_TYPE = { responseType: 'text' };
const { REACT_APP_BASE_API_URL, REACT_APP_CHAT_API_URL, REACT_APP_VA_API_URL } = process.env;
const EXERCISE_URL = REACT_APP_BASE_API_URL + "/api/exam/getExercises";
const EXAM_QUESTIONS_URL = REACT_APP_BASE_API_URL + "/api/question/GetExamQuestions";
const ASSESSMENT_CREATE_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.EXAM_CONTROLLER}/CreateAssessment`;
const ASSESSMENT_SAVE_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.EXAM_CONTROLLER}/SaveAssessments`;
const SINGLE_ASSESSMENT_SAVE_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.EXAM_CONTROLLER}/SaveAssessment`;
const SAVE_NO_CC_COMPLIANT = REACT_APP_BASE_API_URL + "/api/rom/UpdateWithNoChiefComplaint";
const ASSESSMENT_CLEAR_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.EXAM_CONTROLLER}/ResetQuestion`;
const MY_ROM_REPORT_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.EXAM_CONTROLLER}/MyROMsPagination`;
const MEASURED_EXERCISES_URL = REACT_APP_BASE_API_URL + "/api/exam/GetMeasuredExercises";
const WAITING_ROOM_BY_INVITATION_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.TELEMED_CONTROLLER}/GetWaitingRoomByInvitation`;
const SEND_TELEMED_EMAIL_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.TELEMED_CONTROLLER}/SendTelemedEmailForEMMA`;
const FOLLOWUP_URL = REACT_APP_BASE_API_URL + "/api/exam/Followup";
const ASSESSMENT_BY_ID_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.EXAM_CONTROLLER}/GetReportById`;
const UPDATE_INTAKE_URL = REACT_APP_BASE_API_URL + "/api/exam/UpdateIntake";
const ALL_QUESTIONS_URL = REACT_APP_CHAT_API_URL + "/questions/api/all-question";
const BODY_LOCATIONS_URL = REACT_APP_CHAT_API_URL + "/questions/api/getBodyLocations";
const RESPONSE_BY_EXAM_ID_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.EXAM_CONTROLLER}/GetReponseByExamId`;
const EDIT_ASSESSMENT_URL = REACT_APP_VA_API_URL + `/api/${CONSTANT.CONTROLLER.EXAMAPI}/GetResponseByExamId`;
const CLEAR_ALL_QUESTION_OF_A_POINT = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.EXAM_CONTROLLER}/ClearAllCCQuestion`;
const CREATE_EXERCISE_SYNC_URL = REACT_APP_BASE_API_URL + "/api/exam/CreateExerciseSync";
const FYSICAL_SCORE_URL = REACT_APP_BASE_API_URL + "/api/exam/GetFysicalScore";
const ANNOTATED_IMAGES_URL = REACT_APP_BASE_API_URL + "/api/exam/GetAnnotatedImagesByRegion";
const LAST_ASSESSMENT_URL = REACT_APP_BASE_API_URL + "/api/exam/GetMyLastAssessment";
const PATIENT_SCORE_URL = REACT_APP_BASE_API_URL + "/api/report/GetPatientScore";
const ASSESSMENT_STATISTIC_URL = REACT_APP_BASE_API_URL + "/api/report/GetAssessmentStatistic";
const GET_LAST_INCOMPLETED_ASSESSMENT = REACT_APP_BASE_API_URL + "/api/exam/GetMyLastIncompletedTest";
const GET_UPCOMING_EVENTS = REACT_APP_BASE_API_URL + "/api/eventscheduler/getUpcomingEvents";
const GET_MY_ALL_EXERCISE = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.EXERCISE_CONTROLLER}/getAllMyExercises`;
const GET_CC_KEY_POINT_QUESTION = REACT_APP_VA_API_URL + "/api/RomExerciseApi/GetCcKeyPointQuestions";
const GET_ENABLED_BODY_LOCATION_AND_ANSWERED_VAS_QUESTIONS = REACT_APP_VA_API_URL + "/api/RomExerciseApi/GetEnabledBodyLocationAndAnsweredVasQuestion";
const SKIP_EXERCISE_URL = REACT_APP_BASE_API_URL + "/api/rom/UpsertRomExerciseDataV1";

let cancelToken;

class ExamService {
    async getExercises(payload) {
        try {
            const response = await axios.post(EXERCISE_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getExamQuestions() {
        try {
            const payload = {};
            const response = await axios.post(EXAM_QUESTIONS_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async createAssessment(payload = {}) {
        try {
            payload.Source = GLOBAL.Source;
            if(payload.InvitationId === "") { payload.InvitationId = null; }
            const response = await axios.post(ASSESSMENT_CREATE_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async saveAssessments(payload = {}) {
        try {
            if(payload.InvitationId === "") { payload.InvitationId = null };
            const response = await axios.post(ASSESSMENT_SAVE_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async saveAssessment(payload = {}) {
        try {
            const response = await axios.post(SINGLE_ASSESSMENT_SAVE_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async saveNoCC_Complaint(payload = {}) {
        try {
            const response = await axios.post(SAVE_NO_CC_COMPLIANT, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async clearAssessments(payload = {}) {
        try {
            const response = await axios.post(ASSESSMENT_CLEAR_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getAssessments(payload = {}) {
        try {
            const response = await axios.post(MY_ROM_REPORT_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getAllMyExercises(payload = {}) {
        try {
            const response = await axios.post(GET_MY_ALL_EXERCISE, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getWaitingRoomByInvitation(payload = {}) {
        try {
            const response = await axios.post(WAITING_ROOM_BY_INVITATION_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async sendTelemedEmailForEmma(payload = {}) {
        try {
            const response = await axios.post(SEND_TELEMED_EMAIL_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async followUp(payload = {}) {
        try {
            const response = await axios.post(FOLLOWUP_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getAllQuestions(payload) {
        if (typeof cancelToken !== typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.");
        }

        cancelToken = axios.CancelToken.source();

        try {
            payload.tenant = GLOBAL.TENANT;
            const response = await axios.post(ALL_QUESTIONS_URL, payload, {
                ...RESPONSE_TYPE,
                cancelToken: cancelToken.token
            });

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getBodyLocations(payload) {
        if (typeof cancelToken !== typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.");
        }

        cancelToken = axios.CancelToken.source();
        try {
            payload.tenant = GLOBAL.TENANT;//BOT API
            const response = await axios.post(BODY_LOCATIONS_URL, payload, {
                ...RESPONSE_TYPE,
                cancelToken: cancelToken.token
            });

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getPreviousResponsesByTestId(payload) {
        try {
            const response = await axios.post(RESPONSE_BY_EXAM_ID_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getEditAssessmentData(payload) {
        try {
            payload.tenant = GLOBAL.TENANT;
            const response = await axios.post(EDIT_ASSESSMENT_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async clearCCQuestionOfAPoint(payload) {
        try {
            const response = await axios.post(CLEAR_ALL_QUESTION_OF_A_POINT, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getCCKeyPointQuestions(payload) {
        try {
            payload.Tenant = GLOBAL.TENANT;//VA API
            const response = await axios.post(GET_CC_KEY_POINT_QUESTION, payload);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getEnabledBodyLocationAndAnsweredVasQuestion(payload) {
        try {
            payload.Tenant = GLOBAL.TENANT;//VA API
            const response = await axios.post(GET_ENABLED_BODY_LOCATION_AND_ANSWERED_VAS_QUESTIONS, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async saveVideoArchiveLog(patientId, exercise, videoUrl) {//Will Delete
        try {
            const portal = GLOBAL.TENANT;//NOT USING THIS API
            const region = 0;
            const archiveId = `${region}-${patientId}-${exercise}`;

            const url = 'https://' + portal + '.' + CONSTANT.URLS.BASE_URL + '/api/webrtc/SaveVideoArchive?contactid=' + patientId + '&archiveid=' + archiveId + '&patientid=' + patientId + '&portal=' + portal + "&videoUrl=" + videoUrl + "&exercise=" + exercise;
            const response = await axios.get(url, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async createExerciseSync(payload) {
        try {
            const response = await axios.post(CREATE_EXERCISE_SYNC_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async GetFysicalScore(payload = {}) {
        try {
            const response = await axios.post(FYSICAL_SCORE_URL, payload, RESPONSE_TYPE);
            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getAnnotatedImagesByRegion(payload = {}) {
        try {
            const response = await axios.post(ANNOTATED_IMAGES_URL, payload, RESPONSE_TYPE);
            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getLastAssessment(payload = {}) {
        try {
            const response = await axios.post(LAST_ASSESSMENT_URL, payload, RESPONSE_TYPE);
            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getPatientScore(payload = {}) {
        try {
            const response = await axios.post(PATIENT_SCORE_URL, payload, RESPONSE_TYPE);
            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getAssessmentProgress(payload = {}) {
        try {
            const response = await axios.post(ASSESSMENT_STATISTIC_URL, payload, RESPONSE_TYPE);
            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getAssessmentById(payload = {}) {
        try {
            const response = await axios.post(ASSESSMENT_BY_ID_URL, payload, RESPONSE_TYPE);
            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async updateIntake(payload = {}) {
        try {
            const response = await axios.post(UPDATE_INTAKE_URL, payload, RESPONSE_TYPE);
            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async lastIncompletedAssessment(payload) {
        try {
            payload.Tenant = GLOBAL.TENANT;
            const response = await axios.post(GET_LAST_INCOMPLETED_ASSESSMENT, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getUpcomingEvents(payload) {
        try {
            const response = await axios.post(GET_UPCOMING_EVENTS, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async skipAMovement(payload) {
        try {
            const response = await axios.post(SKIP_EXERCISE_URL, payload, RESPONSE_TYPE);
            return success(response);
        } catch (e) {
            return error(e);
        }
    }
}

export default new ExamService();
