import React, { useState } from "react";
import { Formik } from "formik";
import { Trans } from "react-i18next";
import * as Yup from "yup";

import CONSTANT from "../../constants/Constant";
import SweetAlert from "react-bootstrap-sweetalert";
import { invalidFormElement } from "../../utils/DefaultInitialization";
import exerciseService from "../../services/ExerciseService";
import VideoModal from "../shared/VideoModal";
const firstQuestion = "10001000", secondQuestion = "10001001", thirdQuestion = "10001002"

const ExerciseTrackingForm = (props) => {
    const { exercise, onSave } = props
    const [alertMessage, setAlertMessage] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [alertType, setAlertType] = useState(CONSTANT.ALERT_TYPE.INFO);
    const [openVideoTutorial, setOpenVideoTutorial] = useState(false);
    let onConfirmAlert = () => {
    };
    const [onConfirmAlertFunction, setOnConfirmAlertFunction] = useState(() => () => {
    });

    const initialValuesForLogin = () => {
        return {
            ExerciseHistoryDate: new Date(Date.now()),
            NoOfSets: 0,
            NoOfReps: 0,
            Holds: 0,
            TotalNoOfWrongCount: 0,
            firstQuestion: "",
            secondQuestion: "",
            thirdQuestion: ""
        };
    };

    const validationSchema = () => {
        return Yup.object().shape({
            ExerciseHistoryDate: Yup.string()
                .required("This field is required"),
            NoOfSets: Yup.number()
                .max(parseInt(exercise.AssignSets) - parseInt(exercise.CompletedSets), 'Sets must be less than or equal to '
                    + (parseInt(exercise.AssignSets) - parseInt(exercise.CompletedSets))),
            NoOfReps: Yup.number()
                .max(parseInt(exercise.AssignReps) - parseInt(exercise.CompletedReps), 'Reps must be less than or equal to '
                    + (parseInt(exercise.AssignReps) - parseInt(exercise.CompletedReps))),
            Holds: Yup.number()
                .max(parseInt(exercise.HoldInSeconds), 'Holds must be less than or equal to '
                    + (parseInt(exercise.HoldInSeconds))),
            TotalNoOfWrongCount: Yup.number()
                .max(parseInt(exercise.AssignReps) - parseInt(exercise.TotalNoOfWrongCount), 'Wrong Count must be less than or equal to '
                    +(parseInt(exercise.AssignReps) - parseInt(exercise.TotalNoOfWrongCount))),
            firstQuestion: Yup.string().required("This field is required"),
            secondQuestion: Yup.string().required("This field is required"),
            thirdQuestion: Yup.string().required("This field is required")
        });
    };

    const onSubmitExerciseTrackingDetails = async (values, actions) => {
        // actions.setSubmitting(true);
        const payload = {
            TestId: exercise.TestId,
            ExerciseId: exercise.ExerciseId,
            ProtocolId: exercise.ProtocolId,
            NoOfSets: values.NoOfReps,
            NoOfReps: values.NoOfReps,
            Holds: values.Holds,
            TotalNoOfWrongCount: values.TotalNoOfWrongCount,
            ExerciseHistoryDate: values.ExerciseHistoryDate,
            Responses: [
                {
                    "QuestionId": firstQuestion,
                    "AnswerId": values.firstQuestion,
                    "AnswerValue": values.firstQuestion === "61890" ? "Yes" : "No"
                },
                {
                    "QuestionId": secondQuestion,
                    "AnswerId": values.secondQuestion,
                    "AnswerValue": values.firstQuestion === "61892" ? "Yes" : "No"
                },
                {
                    "QuestionId": thirdQuestion,
                    "AnswerId": values.thirdQuestion,
                    "AnswerValue": values.thirdQuestion === "61894" ? "Yes" : "No"
                }
            ]
        }

        const response = await exerciseService.saveExercisesTracking(payload);
        const { success, error } = response;

        // actions.setSubmitting(false);

        if (!success) {
            console.error(error);
            setAlertTitle(CONSTANT.ALERT_TITLE.DANGER);
            setAlertType(CONSTANT.ALERT_TYPE.DANGER);
            setAlertMessage('Data is not saved. Please try again...');
            return;
        }

        setAlertTitle(CONSTANT.ALERT_TITLE.SUCCESS);
        setAlertType(CONSTANT.ALERT_TYPE.SUCCESS);
        setAlertMessage('Successfully Saved');
        setOnConfirmAlertFunction(() => onSave);
    };

    onConfirmAlert = () => {
        setAlertMessage('');
        setAlertTitle('');
        setAlertType(CONSTANT.ALERT_TYPE.INFO);
    }

    const toggleVideoTutorialModal = () => {
        setOpenVideoTutorial(!openVideoTutorial);
    }

    return (
        <>
            <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
                <div className="kt-widget kt-widget--user-profile-3 text-center kt-mb-10">
                    <h5>{exercise.ExerciseName}</h5>
                    <div className="kt-widget__top">
                        <div className="kt-widget__media d-flex justify-content-end w-60">
                            <img src={exercise.ExerciseImageUrl} alt="ExerciseAlt"/>
                        </div>
                        <div className='d-flex flex-column justify-content-end w-40'>
                            <div className='align-self-end kt-mb-5'>
                                <span className='kt-font-bold'>Holds: </span>
                                <span>{exercise.HoldInSeconds}</span>
                            </div>
                            <button className='btn btn-primary w-50 align-self-end' onClick={toggleVideoTutorialModal}>Tutorial</button>
                        </div>
                    </div>
                </div>
                <Formik
                    initialValues={initialValuesForLogin()}
                    validationSchema={validationSchema()}
                    onSubmit={onSubmitExerciseTrackingDetails}>
                    {({
                          handleChange,
                          handleSubmit,
                          handleBlur,
                          values,
                          errors,
                          touched,
                          isSubmitting,
                      }) => (
                        <div className="kt-login__container">
                            <div className="kt-login__signin">
                                <form className="kt-form" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>Date</label>
                                        <input
                                            className={`kt-mt-0 ${invalidFormElement(
                                                "ExerciseHistoryDate",
                                                errors,
                                                values,
                                                touched
                                            )}`}
                                            // readOnly={true}
                                            type="datetime"
                                            name="ExerciseHistoryDate"
                                            autoComplete="off"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.ExerciseHistoryDate}
                                        />
                                        {errors.ExerciseHistoryDate && touched.ExerciseHistoryDate && (
                                            <div className="invalid-feedback">{errors.ExerciseHistoryDate}</div>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label>Reps</label>
                                        <div className="kt-input-icon kt-input-icon--right">
                                            <input
                                                className={`kt-mt-0 ${invalidFormElement(
                                                    "NoOfReps",
                                                    errors,
                                                    values,
                                                    touched
                                                )}`}
                                                type="number"
                                                placeholder="Enter Reps"
                                                name="NoOfReps"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.NoOfReps}
                                                min={0}
                                            />
                                            <span
                                                className="form-text text-muted">Reps {exercise.CompletedReps} of {exercise.AssignReps}</span>
                                            {errors.NoOfReps && touched.NoOfReps && (
                                                <div className="invalid-feedback">
                                                    {errors.NoOfReps}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/*{exercise.AssignReps > exercise.CompletedReps && (*/}
                                    {/*    */}
                                    {/*)}*/}
                                    <div className="form-group">
                                        <label>Holds</label>
                                        <div className="kt-input-icon kt-input-icon--right">
                                            <input
                                                className={`kt-mt-0 ${invalidFormElement(
                                                    "Holds",
                                                    errors,
                                                    values,
                                                    touched
                                                )}`}
                                                type="number"
                                                placeholder="Enter Holds"
                                                name="Holds"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.Holds}
                                                min={0}
                                            />
                                            <span
                                                className="form-text text-muted">out of {exercise.HoldInSeconds}</span>
                                            {errors.Holds && touched.Holds && (
                                                <div className="invalid-feedback">
                                                    {errors.Holds}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Sets</label>
                                        <div className="kt-input-icon kt-input-icon--right">
                                            <input
                                                className={`kt-mt-0 ${invalidFormElement(
                                                    "NoOfSets",
                                                    errors,
                                                    values,
                                                    touched
                                                )}`}
                                                type="number"
                                                placeholder="Enter Sets"
                                                readOnly={true}
                                                name="NoOfSets"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={parseInt((+values.NoOfReps + +exercise.CompletedReps) / exercise.AssignReps) + parseInt(exercise.CompletedSets)}
                                                min={0}
                                            />
                                            <span
                                                className="form-text text-muted">Sets {exercise.CompletedSets} of {exercise.AssignSets}</span>
                                            {errors.NoOfSets && touched.NoOfSets && (
                                                <div className="invalid-feedback">
                                                    {errors.NoOfSets}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Wrong Count</label>
                                        <div className="kt-input-icon kt-input-icon--right">
                                            <input
                                                className={`kt-mt-0 ${invalidFormElement(
                                                    "TotalNoOfWrongCount",
                                                    errors,
                                                    values,
                                                    touched
                                                )}`}
                                                type="number"
                                                placeholder="Enter Wrong Count"
                                                name="TotalNoOfWrongCount"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.TotalNoOfWrongCount}
                                                min={0}
                                            />
                                            <span className="form-text text-muted">Wrong Count {exercise.TotalNoOfWrongCount} of {exercise.AssignReps}</span>
                                            {errors.TotalNoOfWrongCount && touched.TotalNoOfWrongCount && (
                                                <div className="invalid-feedback">
                                                    {errors.TotalNoOfWrongCount}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/*{exercise.AssignSets > exercise.CompletedSets && (*/}
                                    {/*    */}
                                    {/*)}*/}
                                    <div className="form-group">
                                        <label>Did you find this exercise to be too difficult?</label>
                                        <div className="kt-radio-inline">
                                            <label className="kt-radio">
                                                <input type="radio" name="firstQuestion" value="61890"
                                                       onChange={handleChange}
                                                       onBlur={handleBlur} /> Yes
                                                    <span></span>
                                            </label>
                                            <label className="kt-radio">
                                                <input type="radio" name="firstQuestion" value="61889"
                                                       onChange={handleChange}
                                                       onBlur={handleBlur} /> No
                                                    <span></span>
                                            </label>
                                        </div>
                                        {errors.firstQuestion && touched.firstQuestion && (
                                            <span className="form-text color-red">{errors.firstQuestion}</span>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label>Was this exercise too easy?</label>
                                        <div className="kt-radio-inline">
                                            <label className="kt-radio">
                                                <input type="radio" name="secondQuestion" value="61892"
                                                       onChange={handleChange}
                                                       onBlur={handleBlur} /> Yes
                                                <span></span>
                                            </label>
                                            <label className="kt-radio">
                                                <input type="radio" name="secondQuestion" value="61891"
                                                       onChange={handleChange}
                                                       onBlur={handleBlur} /> No
                                                <span></span>
                                            </label>
                                        </div>
                                        {errors.secondQuestion && touched.secondQuestion && (
                                            <span className="form-text color-red">{errors.secondQuestion}</span>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label>Do you find this exercise to be too painful?</label>
                                        <div className="kt-radio-inline">
                                            <label className="kt-radio">
                                                <input type="radio" name="thirdQuestion" value="61894"
                                                       onChange={handleChange}
                                                       onBlur={handleBlur}/> Yes
                                                <span></span>
                                            </label>
                                            <label className="kt-radio">
                                                <input type="radio" name="thirdQuestion" value="61893"
                                                       onChange={handleChange}
                                                       onBlur={handleBlur}/> No
                                                <span></span>
                                            </label>
                                        </div>
                                        {errors.thirdQuestion && touched.thirdQuestion && (
                                            <span className="form-text color-red">{errors.thirdQuestion}</span>
                                        )}
                                    </div>
                                    <div className="kt-login__actions kt-pt15 kt-mb-15">
                                        <button
                                            type="submit"
                                            className="btn btn-brand btn-elevate kt-login__btn-primary float-right"
                                            disabled={isSubmitting}>
                                            {isSubmitting ? (
                                                <>
                                                    <i className="fa fa-spinner fa-spin"/>&nbsp;&nbsp;
                                                    <Trans i18nKey="common.message.info.please-wait"/>
                                                </>
                                            ) : (
                                                <>
                                                    <i className="fa fa-times"/>
                                                    <Trans i18nKey="common.form.save-&-close"/>
                                                </>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
            <SweetAlert
                show={alertMessage !== ""}
                title={alertTitle}
                type={alertType}
                onConfirm={() => {
                    onConfirmAlertFunction();
                    onConfirmAlert();
                }}
                closeOnClickOutside={false}>
                {alertMessage}
            </SweetAlert>
            <VideoModal
                visible={openVideoTutorial}
                url={exercise.Tutorial}
                closeModal={toggleVideoTutorialModal}
                headerTitle='Video Tutorial'/>
        </>
    )
}

export default ExerciseTrackingForm
