import CONSTANT from "../../../constants/Constant";
import InClinicBtn from "../../../assets/img/myAssessmensList/hospitalIcon.svg";
import React from "react";
import useHandleExpiredAndUpcomingMeetingPopupMsg from "../../../hooks/useHandleExpiredAndUpcomingMeetingPopupMsg";

const ResumeBtn = ({ InvitationTypeId, firstIncompleteGroupName, notificationHandler, notificationData, IsLastIncomplete, isLoading, MeetingStart, MeetingEnd, conformationAlertContentRef }) => {
    const { getAssessmentAvailabilityStatus } = useHandleExpiredAndUpcomingMeetingPopupMsg();

    return (
        <div className="d-flex justify-content-center align-items-center">
            {(
                InvitationTypeId === CONSTANT.INVITATION_TYPE.InClinic &&
                firstIncompleteGroupName?.key === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key &&
                !notificationData?.IsFollowup
            )
                ?
                    // In-Clinic and first incomplete group is Biometric and not a followup
                    <button className="pointer-none opacity-0_4 button-dashboard bg-color-orange-dark border-0">
                        <span className="d-flex justify-content-center align-items-center color-white">
                            <img className="mr-3" src={InClinicBtn} alt="In-Clinic" /> In-Clinic
                        </span>
                    </button>
                :
                    <button
                        className={`${isLoading ? " pointer-none opacity-0_4 button-dashboard bg-color-orange-dark border-0 " : " button button-dashboard " }`}
                        onClick={() => notificationHandler(notificationData, conformationAlertContentRef)}
                        disabled={isLoading}
                    >
                        {(IsLastIncomplete)
                            // Last Incomplete Assessment and follow up
                            ? <span>
                                    {
                                        isLoading
                                            ? <i className="fa fa-spinner fa-spin mr-1"/>
                                            : null
                                    }
                                {
                                    notificationData?.IsFollowup
                                        // Is a followup
                                        ? "Resume Assessment"
                                        :
                                        // Not a followup
                                        (
                                            notificationData?.InvitationTypeId === CONSTANT.INVITATION_TYPE.Telemedicine &&
                                            firstIncompleteGroupName?.key === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key
                                        )
                                            ?
                                                ((getAssessmentAvailabilityStatus(MeetingStart, MeetingEnd) === CONSTANT.ASSESSMENT_AVAILABILITY.UPCOMING.KEY) && (<span>{CONSTANT.ASSESSMENT_AVAILABILITY.UPCOMING.MESSAGE}</span>)) ||
                                                ((getAssessmentAvailabilityStatus(MeetingStart, MeetingEnd) === CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.KEY) && (<span>{CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.MESSAGE}</span>)) ||
                                                ((getAssessmentAvailabilityStatus(MeetingStart, MeetingEnd) === CONSTANT.ASSESSMENT_AVAILABILITY.AVAILABLE.KEY) && (<span>{CONSTANT.ASSESSMENT_AVAILABILITY.AVAILABLE.MESSAGES.JOIN_NOW}</span>))
                                            :
                                                `Resume ${firstIncompleteGroupName?.name}`
                                }
                                </span>
                            :
                            // Without Test Id(will start bot)
                            <span>Start</span>
                        }
                    </button>
            }
        </div>
    )
}

export default ResumeBtn;