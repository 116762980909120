import React from "react";
import {useSelector} from "react-redux";

export const SavingAnimation = () => {
    const chatBotState = useSelector(state => state.chatBot);
    const chatBotUIState = chatBotState?.chatBotUIState || {};
    const isFetchingChatQuestion = chatBotUIState?.isFetchingChatQuestion || false;

    return (
        <>
            {isFetchingChatQuestion && (
                <div
                    className={`chatbot-typing-container d-flex justify-content-start`}>
                    <div className="chatbot-typing">
                        <div className="dot"/>
                        <div className="dot"/>
                        <div className="dot"/>
                    </div>
                </div>
            )}
        </>
    )
}

export default SavingAnimation;