import React from "react";
import CONSTANT from "../../../../constants/Constant";
import CheckboxOption from "../../form-element/CheckboxOption";
import RadioBtnComponent from "./RadioBtnComponent";
import {shouldCheckboxOptionDisabled} from "../../../../utils/Utils";

const PainQuestion = ({ painQuestion, onChangeAnswer, isClickedOnSubmitBtn }) => {
    const isCheckBox = painQuestion?.QuestionType === CONSTANT.ELEMENT_TYPES.CHECKBOX;
    const answerList = painQuestion?.AnswerList || [];
    const singleSelectAnswerId = painQuestion?.SingleSelectAnswerId ? painQuestion?.SingleSelectAnswerId : 0;

    const isCurrentAnswerChecked = (answerList, currentAnswerId) => {
        return isCheckBox && answerList.includes(currentAnswerId) || false;
    }

    const isAnswered = (question) => {
        if(isCheckBox) {
            return !(question?.AnswerList?.length > 0);
        } else {
            return !question?.AnswerId;
        }
    }

    const handleOnClicked = (question, response) => {
        if(isCheckBox && shouldCheckboxOptionDisabled(singleSelectAnswerId, answerList, response?.AnswerId)) {
            return;
        }

        onChangeAnswer(question, response?.AnswerId);
    }

    return (
        <div className="mb-3 pain-question-modal-question" id={`pain-question-${painQuestion.QuestionId}`}>
            <div>
                <label className="pain-question-modal-question__title">{painQuestion.QuestionTitle}</label>
            </div>
            <div>
                {/*At pain question we only have two types of responses: Radio and Checkbox*/}

                {/*Response Type: Radio*/}
                {!isCheckBox && painQuestion?.Responses.map((response, key) => (
                    <RadioBtnComponent
                        className="kt-radio kt-radio--bold kt-radio--brand mr-3 pain-question-modal-question__options white-space-no-wrap"
                        type={isCheckBox ? CONSTANT.ELEMENT_TYPES.CHECKBOX : CONSTANT.ELEMENT_TYPES.BUTTON}
                        key={response?.AnswerId ? response?.AnswerId : key}
                        name={`pain${painQuestion?.QuestionId || ""}`}
                        value={response?.AnswerId}
                        checked={(isCheckBox && answerList.includes(response?.AnswerId)) || (!isCheckBox && (response?.AnswerId === painQuestion?.AnswerId))}
                        onClick={() => handleOnClicked(painQuestion, response)}
                        title={response?.AnswerTitle}
                    />
                ))}

                {/*Response Type: Checkbox*/}
                {isCheckBox && painQuestion.Responses.map((response, key) => {
                    return (
                        <CheckboxOption.OptionItem
                            key={response?.AnswerId ? response?.AnswerId : key}
                            isChecked={isCurrentAnswerChecked(answerList, response?.AnswerId)}
                            onClick={() => handleOnClicked(painQuestion, response)}
                            label={response?.AnswerTitle}
                            className={`kt-radio kt-radio--bold kt-radio--brand mr-3 pain-question-modal-question__options white-space-no-wrap ${
                                isCurrentAnswerChecked(answerList, response?.AnswerId) 
                                    ? "selected-option" 
                                    : "" 
                            }`}
                            iconClassName="mr-3"
                            disabled={shouldCheckboxOptionDisabled(singleSelectAnswerId, answerList, response?.AnswerId)}
                        />
                    );
                })}

                {isClickedOnSubmitBtn && isAnswered(painQuestion) && (
                    <div className="color-red d-block">* Please answer this question.</div>
                )}
            </div>
        </div>
    );
};

export default PainQuestion;