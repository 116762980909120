import GLOBAL from "../../../../global";

let startInferenceTime,
    numInferences = 0;
let inferenceTimeSum = 0,
    lastPanelUpdate = 0;
let totalFps = 0;
const CONFIDENCE_SCORE = 0.4;

const MOVENET_CONFIG = {
    maxPoses: 1,
    type: 'lightning',
    scoreThreshold: 0.3
};

const THRESHOLD_FOR_SHOULDERS_DISTANCE = 40;

export const NET_MODEL = MOVENET_CONFIG;
export const DEFAULT_LINE_WIDTH = 3;
export const DEFAULT_RADIUS = 3;
export const FULL_SCREEN_SKELETON_COLOR = "#5578EB";
export const MIN_SCREEN_SKELETON_COLOR = "#FFFFFF";
export const SPEECH_PROBABILITY_THRESHOLD = 0.90;

export const GUI_STATE = {
    detector: null,
    model: null,
    modelType: null,
    rafId: null,
    ctxFullScreen: null,
    ctxMinScreen: null
};

export const KEYPOINTS = Object.freeze({
    NOSE: { name: "nose", value: "nose", point: 0 },
    LEFTEYEINNER: { name: "leftEyeInner", value: "left_eye_inner", point: 1 },
    LEFTEYE: { name: "leftEye", value: "left_eye", point: 2 },
    LEFTEYEOUTER: { name: "leftEyeOuter", value: "left_eye_outer", point: 3 },
    RIGHTEYEINNER: { name: "rightEyeInner", value: "right_eye_inner", point: 4 },
    RIGHTEYE: { name: "rightEye", value: "right_eye", point: 5 },
    RIGHTEYEOUTER: { name: "rightEyeOuter", value: "right_eye_outer", point: 6 },
    LEFTEAR: { name: "leftEar", value: "left_ear", point: 7 },
    RIGHTEAR: { name: "rightEar", value: "right_ear", point: 8 },
    MOUTHLEFT: { name: "mouthLeft", value: "mouth_left", point: 9 },
    MOUTHRIGHT: { name: "mouthRight", value: "mouth_right", point: 10 },
    LEFTSHOULDER: { name: "leftShoulder", value: "left_shoulder", point: 11 },
    RIGHTSHOULDER: { name: "rightShoulder", value: "right_shoulder", point: 12 },
    LEFTELBOW: { name: "leftElbow", value: "left_elbow", point: 13 },
    RIGHTELBOW: { name: "rightElbow", value: "right_elbow", point: 14 },
    LEFTWRIST: { name: "leftWrist", value: "left_wrist", point: 15 },
    RIGHTWRIST: { name: "rightWrist", value: "right_wrist", point: 16 },
    LEFTPINKY: { name: "leftPinky", value: "left_pinky", point: 17 },
    RIGHTPINKY: { name: "rightPinky", value: "right_pinky", point: 18 },
    LEFTINDEX: { name: "leftIndex", value: "left_index", point: 19 },
    RIGHTINDEX: { name: "rightIndex", value: "right_index", point: 20 },
    LEFTTHUMB: { name: "leftThumb", value: "left_thumb", point: 21 },
    RIGHTTHUMB: { name: "rightThumb", value: "right_thumb", point: 22 },
    LEFTHIP: { name: "leftHip", value: "left_hip", point: 23 },
    RIGHTHIP: { name: "rightHip", value: "right_hip", point: 24 },
    LEFTKNEE: { name: "leftKnee", value: "left_knee", point: 25 },
    RIGHTKNEE: { name: "rightKnee", value: "right_knee", point: 26 },
    LEFTANKLE: { name: "leftAnkle", value: "left_ankle", point: 27 },
    RIGHTANKLE: { name: "rightAnkle", value: "right_ankle", point: 28 },
    LEFTHEEL: { name: "leftHeel", value: "left_heel", point: 29 },
    RIGHTHEEL: { name: "rightHeel", value: "right_heel", point: 30 },
    LEFTFOOTINDEX: { name: "leftFootIndex", value: "left_foot_index", point: 31 },
    RIGHTFOOTINDEX: { name: "rightFootIndex", value: "right_foot_index", point: 32 }
});

export const LEFT_FACING_EXERCISE_CODE_NAMES = ['postureAnalysis', 'lookCeiling', 'chinToChest',
    'shoulderFlexionLeft', 'shoulderExtensionLeft', 'shoulderInternalRotationLeft', 'shoulderExternalRotationLeft',
    'backFlexion', 'headToTopBend', 'kneeSquat', 'hipExtensionLeft'
];
// Posture Side View, Cervical Extension, Cervical Flexion, Left Shoulder Flexion, Left Shoulder Extension, Left Shoulder Internal Rotation,
//Left Shoulder External Rotation, Trunk Flexion, Trunk Extension, Hip Flexion (Squat), Left Hip Extension

export const RIGHT_FACING_EXERCISE_CODE_NAMES = ['shoulderFlexionRight', 'shoulderExtensionRight', 'shoulderInternalRotationRight', 'shoulderExternalRotationRight', 'hipExtensionRight']
//Right Shoulder Flexion, Right Shoulder Extension, Right Shoulder Internal Rotation, Right Shoulder External Rotation, Right Hip Extension

export const checkFullBodyInBox = (keyPoints) => {
    for(let i = 0; i < keyPoints.length; i++) {
        if(keyPoints[i].x <= GLOBAL.left || keyPoints[i].x >= GLOBAL.right
            || keyPoints[i].y <= GLOBAL.top || keyPoints[i].y >= GLOBAL.bottom) {
                return false;
        }
        // else if(keyPoints[i]?.visibility < CONFIDENCE_SCORE ) {
        //     return false;
        // }
    }

    return true;
}

export const getShoulderMidPoint = (keyPoints) => {
    let shoulderLeft = keyPoints[KEYPOINTS.LEFTSHOULDER.point]
    let shoulderRight = keyPoints[KEYPOINTS.RIGHTSHOULDER.point]

    if (shoulderLeft == null || shoulderRight == null) return null

    return {
        score: shoulderLeft.visibility > shoulderRight.visibility ? shoulderRight.visibility : shoulderLeft.visibility,
        x: shoulderLeft.x + (shoulderRight.x - shoulderLeft.x) / 2,
        y: shoulderLeft.y + (shoulderRight.y - shoulderLeft.y) / 2
    }
}

const isRightHeelLeftOfRightAnkle = (keyPoints) => {
    return keyPoints[KEYPOINTS.RIGHTHEEL.point]?.x < keyPoints[KEYPOINTS.RIGHTANKLE.point]?.x;
}

const isRightHeelLeftOfLeftAnkle = (keyPoints) => {
    return keyPoints[KEYPOINTS.RIGHTHEEL.point]?.x < keyPoints[KEYPOINTS.LEFTANKLE.point]?.x;
}

const isShoulderToShoulderDistanceValid = (keyPoints) => {
    return Math.abs(keyPoints[KEYPOINTS.LEFTSHOULDER.point]?.x - keyPoints[KEYPOINTS.RIGHTSHOULDER.point]?.x) < THRESHOLD_FOR_SHOULDERS_DISTANCE;
}

export const isUserFacingRight = (keyPoints) => {
    if(
        !checkNoseInLeftSideOfShoulderMidPoint(keyPoints) &&
        checkNoseInRightSideOfShoulderMidPoint(keyPoints) &&
        isShoulderToShoulderDistanceValid(keyPoints)) {
        return true;
    } else if(isRightHeelLeftOfRightAnkle(keyPoints) && isRightHeelLeftOfLeftAnkle(keyPoints)) {
        return true;
    }

    return false;
}

export const isUserFacingLeft = (keyPoints) => {
    if (
        !checkNoseInRightSideOfShoulderMidPoint(keyPoints) &&
        checkNoseInLeftSideOfShoulderMidPoint(keyPoints) &&
        isShoulderToShoulderDistanceValid(keyPoints)) {
        return true;
    } else if (!isRightHeelLeftOfRightAnkle(keyPoints) && !isRightHeelLeftOfLeftAnkle(keyPoints)) {
        return true;
    }

    return false;
}


export const checkNoseInRightSideOfShoulderMidPoint = (keyPoints) => {
    let nosePoint = keyPoints[KEYPOINTS.NOSE.point]
    let shoulderMidPoint = getShoulderMidPoint(keyPoints)

    if (nosePoint == null || shoulderMidPoint == null) return false

    return nosePoint.x >= shoulderMidPoint.x
}

export const checkNoseInLeftSideOfShoulderMidPoint = (keyPoints) => {
    let nosePoint = keyPoints[KEYPOINTS.NOSE.point]
    let shoulderMidPoint = getShoulderMidPoint(keyPoints)

    if (nosePoint == null || shoulderMidPoint == null) return false

    return nosePoint.x <= shoulderMidPoint.x
}