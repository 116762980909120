import React, {useState} from "react"
import { Trans } from "react-i18next";
const Popup = (props) => {
    const { id, deleteHandler, show, closeHandler } = props
    const [open, setOpen] = useState(false)
    const onClickStart = () => {}
    return (
        <div className='popup' id='popup' style={{
            opacity: show ? '1' : '0',
            visibility: show ? 'visible' : 'hidden'
        }}>
            <div className='popup__content' style={{
                opacity: show ? '1' : '0',
                transform: show ? 'translate(-50%, -50%) scale(1)' : 'translate(-50%, -50%) scale(1)',
                borderRadius: "20px"
            }}>
                {props.children}
            </div>
        </div>
    )
}

export default Popup