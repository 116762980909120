import axios from 'axios';
import CONSTANT from "../constants/Constant";
import storageService from "../utils/StorageService";
import AuthService from "./AuthService";
import { toast } from "react-toastify";
import { redirectTo } from "../components/shared/chatbotNew/ChatServices";

let axiosInstance = axios.create({
    responseType: "json",
    headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
    }
});

axiosInstance.CancelToken = axios.CancelToken;
axiosInstance.isCancel = axios.isCancel;

let loadingRefreshtoken = false;
//Request interceptor
axiosInstance.interceptors.request.use(function (config) {
    if(storageService.getItem(CONSTANT.JWT.TOKEN_KEY)) {
        config.headers.Authorization = `${CONSTANT.JWT.HEADER_PREFIX} ${storageService.getItem(CONSTANT.JWT.TOKEN_KEY)}`;
    }

    return config;
}, error => {
    console.log(error, 'From request');
    return Promise.reject(error);
});

//Response interceptor
axiosInstance.interceptors.response.use(response => {
    if (process.env.NODE_ENV === 'production') {
        const { Message, message } = response?.data;

        if (response.status !== CONSTANT.STATUS_CODE.CODE_200) {
            const detailsInfo = {
                errorMessage: Message || message || '',
                requestUrl: response.config.url,
                requestPayload: response.config.data
            }
            console.error(detailsInfo)
        }

        return response.data;
    }

    return response.data;
}, async (error) => {
    console.log(error, 'From response');
    const config = error?.config;

    if (error?.response?.status === 401) {
        try {
            let payload = {
                RefreshToken: storageService.getItem(CONSTANT.REFRESH_TOKEN.KEY_NAME),
                Platform: CONSTANT.PLATFORM.WEB
            }

            if (loadingRefreshtoken) {
                return;
            }
            loadingRefreshtoken = true;
            const { success, data} = await AuthService.refreshtoken(payload);
            loadingRefreshtoken = false;

            if(!success) {
                storageService.removeItem(CONSTANT.JWT.TOKEN_KEY);
                storageService.removeItem(CONSTANT.REFRESH_TOKEN.KEY_NAME);
                toast.error("Authentication failed. Please login.");
                redirectTo("/");

                return Promise.reject(error);
            }
            const { Token, RefreshToken } = data;
            if (Token && RefreshToken) {
                storageService.removeItem(CONSTANT.JWT.TOKEN_KEY);
                storageService.removeItem(CONSTANT.REFRESH_TOKEN.KEY_NAME);

                storageService.addItem(CONSTANT.JWT.TOKEN_KEY, Token);
                storageService.addItem(CONSTANT.REFRESH_TOKEN.KEY_NAME, RefreshToken);

                if(storageService.getItem(CONSTANT.JWT.TOKEN_KEY)) {
                    config.headers.Authorization = `${CONSTANT.JWT.HEADER_PREFIX} ${storageService.getItem(CONSTANT.JWT.TOKEN_KEY)}`;
                }

                return axiosInstance(config);
            } else {
                storageService.removeItem(CONSTANT.JWT.TOKEN_KEY);
                storageService.removeItem(CONSTANT.REFRESH_TOKEN.KEY_NAME);
                toast.error("Internal server error. Please login.");
                redirectTo("/");
            }
        } catch (refreshError) {
            return Promise.reject(refreshError);
        }
    }
    return Promise.reject(error);
});

export default axiosInstance;
