import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import InputMask from "react-input-mask";
import { Trans, useTranslation } from "react-i18next";

import GLOBAL from "../../../global";
import { invalidFormElement } from "../../../utils/DefaultInitialization";

const AddPaymentCardForm = () => {
    const { i18n } = useTranslation();
    const [cardType, setCardType] = useState('visa');
    const [cardInputMask, setCardInputMask] = useState('9999 9999 9999 9999');
    const [enableSaveCard, setEnableSaveCard] = useState(false)


    const initialValuesForAddCard = () => {
        return {
            CardHolderName: GLOBAL.URL_PARAMS.EMAIL_ADDRESS ? GLOBAL.URL_PARAMS.EMAIL_ADDRESS : '',
            CardNumber: "",
            ExpirationDate: "",
            ExpirationMonth: "",
            CVV: ""
        };
    };

    const validationSchema = () => {
        return Yup.object().shape({
            CardHolderName: Yup.string()
                .required(i18n.t("common.message.error.required-field")),
            CardNumber: Yup.string()
                .required(i18n.t("common.message.error.required-field")),
            ExpirationDate: Yup.string()
                .required(i18n.t("common.message.error.required-field")),
            ExpirationMonth: Yup.string()
                .required(i18n.t("common.message.error.required-field")),
            CVV: Yup.string()
                .required(i18n.t("common.message.error.required-field"))
        });
    };

    const onSubmit = async (values, actions) => {
        console.log({ values })
    }

    const onClickCard = (value) => (e) => {
        e.preventDefault();
        if (value === 'visa') {
            setCardType('visa')
            setCardInputMask("9999 9999 9999 9999")
        } else if (value === 'amex') {
            setCardType('amex');
            setCardInputMask("9999 999999 99999")
        } else if (value === 'master') {
            setCardType('master');
            setCardInputMask("9999 9999 9999")
        }
    }

    const getEnabledClass = (value) => {
        if (value === cardType) {
            return 'kt-font-success'
        } else {
            return ''
        }
    }

    const onChangeSaveCard = () => {
        setEnableSaveCard(!enableSaveCard)
    }

    return (
        <Formik
            initialValues={initialValuesForAddCard()}
            validationSchema={validationSchema()}
            onSubmit={onSubmit}>
            {({
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  values,
                  errors,
                  touched,
                  isSubmitting,
              }) => (
                <div className="kt-login__container">
                    <div className="kt-login__signin">
                        <form className="kt-form" onSubmit={handleSubmit}>
                            <div className="form-group ">
                                <label>Name On Card <span className="kt-font-danger"> *</span></label>
                                <div className="kt-input-icon kt-input-icon--right">
                                    <input
                                        className={`kt-mt-0 ${invalidFormElement(
                                            "CardHolderName",
                                            errors,
                                            values,
                                            touched
                                        )}`}
                                        type="text"
                                        placeholder="Please enter your name"
                                        name="CardHolderName"
                                        autoComplete="off"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.CardHolderName}
                                    />
                                    {errors.CardHolderName && touched.CardHolderName && (
                                        <div className="invalid-feedback">{errors.CardHolderName}</div>
                                    )}
                                </div>
                            </div>
                            <div className="form-group ">
                                <div className="d-flex justify-content-between">
                                    <label>Card Number <span className="kt-font-danger"> *</span></label>
                                    <div>
                                        <span className={`kt-ml-5 cursor-pointer ${getEnabledClass('visa')}`}
                                              onClick={onClickCard('visa')}>
                                            <i className="fab fa-cc-visa kt-font-xl"/>
                                        </span>
                                        <span className={`kt-ml-5 cursor-pointer ${getEnabledClass('amex')}`}
                                              onClick={onClickCard('amex')}>
                                            <i className="fab fa-cc-amex kt-font-xl"/>
                                        </span>
                                        <span className={`kt-ml-5 cursor-pointer ${getEnabledClass('master')}`}
                                              onClick={onClickCard('master')}>
                                            <i className="fab fa-cc-mastercard kt-font-xl"/>
                                        </span>
                                    </div>
                                </div>
                                <div className="kt-input-icon kt-input-icon--right">
                                    <InputMask
                                        className={`kt-mt-0 ${invalidFormElement(
                                            "CardNumber",
                                            errors,
                                            values,
                                            touched
                                        )}`}
                                        mask={cardInputMask}
                                        type="text"
                                        placeholder="Please enter your email"
                                        name="CardNumber"
                                        autoComplete="off"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.CardNumber}
                                    />

                                    {errors.CardNumber && touched.CardNumber && (
                                        <div className="invalid-feedback">{errors.CardNumber}</div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="form-group w-30">
                                    <label>Expiration Date <span className="kt-font-danger"> *</span></label>
                                    <div className="kt-input-icon kt-input-icon--right">
                                        <select className={`form-control kt-mt-0 ${invalidFormElement(
                                            "ExpirationDate",
                                            errors,
                                            values,
                                            touched
                                        )}`}
                                                id="exampleSelect1"
                                                name="ExpirationDate"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.ExpirationDate}>
                                            <option value="" disabled selected>Month</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>

                                        {errors.ExpirationDate && touched.ExpirationDate && (
                                            <div className="invalid-feedback">{errors.ExpirationDate}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group w-30">
                                    <label>Expiration Month <span className="kt-font-danger"> *</span></label>
                                    <div className="kt-input-icon kt-input-icon--right">
                                        <select className={`form-control kt-mt-0 ${invalidFormElement(
                                            "ExpirationMonth",
                                            errors,
                                            values,
                                            touched
                                        )}`}
                                                id="exampleSelect1"
                                                name="ExpirationMonth"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.ExpirationMonth}>
                                            <option value="" disabled selected>Year</option>
                                            <option>2021</option>
                                            <option>2022</option>
                                            <option>2023</option>
                                            <option>2024</option>
                                            <option>2025</option>
                                        </select>

                                        {errors.ExpirationMonth && touched.ExpirationMonth && (
                                            <div className="invalid-feedback">{errors.ExpirationMonth}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group w-30">
                                    <label>CVV <span className="kt-font-danger"> *</span></label>
                                    <div className="kt-input-icon kt-input-icon--right">
                                        <InputMask
                                            className={`kt-mt-0 ${invalidFormElement(
                                                "CVV",
                                                errors,
                                                values,
                                                touched
                                            )}`}
                                            mask="999"
                                            type="text"
                                            placeholder="CVV"
                                            name="CVV"
                                            autoComplete="off"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.CVV}
                                        />

                                        {errors.CVV && touched.CVV && (
                                            <div className="invalid-feedback">{errors.CVV}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h5>Save Card for further billing?</h5>
                                    <span>If you need more info, please check budget planning</span>
                                </div>
                                <span
                                    className="kt-switch kt-switch--sm kt-switch--outline kt-switch--icon kt-switch--success float-right">
                            <label className="kt-mb-0">
                                <input type="checkbox" onChange={onChangeSaveCard} checked={enableSaveCard}/>
                                <span/>
                            </label>
                        </span>
                            </div>
                            <div className="kt-login__actions kt-pt15 kt-mb-15">
                                <button
                                    type="submit"
                                    className="btn btn-brand btn-elevate kt-login__btn-primary w-25 float-right"
                                    disabled={isSubmitting}>
                                    {isSubmitting ? (
                                        <>
                                            <i className="fa fa-spinner fa-spin"/>&nbsp;&nbsp;
                                            <Trans i18nKey="common.message.info.please-wait"/>
                                        </>
                                    ) : (
                                        <>Submit</>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Formik>
    )
}

export default AddPaymentCardForm