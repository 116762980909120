import React from "react";

const BillingHistory = () => {
    return (
        <div className="kt-portlet kt-portlet--height-fluid">
            <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">
                        Billing History
                    </h3>
                </div>
            </div>
            <div className="kt-portlet__body kt-portlet__body--fit">
                <div className="kt-invoice-2 kt-padding-l-15">
                    <div className="kt-invoice__footer bg-white">
                        <div className="kt-invoice__container">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Description</th>
                                        <th>Amount</th>
                                        <th>Invoice</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Nov 01, 2020</td>
                                        <td>Invoice for Ocrober 2021</td>
                                        <td>$123.79</td>
                                        <td className="kt-font-danger kt-font-xl kt-font-boldest">
                                            <button className="btn btn-secondary btn-sm"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#kt_modal_upgrade_plan">PDF
                                            </button>
                                        </td>
                                        <td>
                                            <button className="btn btn-secondary btn-sm"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#kt_modal_upgrade_plan">View
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BillingHistory;