export const getMovements = (biometricExercisesGuideline, bodyRegionList) => {
    const filteredArray = biometricExercisesGuideline.filter((item) => {
        return item.BodyRegionList.some((region) => bodyRegionList.includes(region));
    });

    return filteredArray;
}

export const getBgColorOfExerciseName = (exercise, selectedExercise) => {
    if(exercise?.ExerciseName === selectedExercise?.ExerciseName) {
        return ' bg-color-primary color-white ';
    }
}


export const getGuidelineContainerStyleClass = (isTabOrMobile, showFullGuidelineInTabOrMobile, isFromTrainingMovement) => {
    if(!isTabOrMobile || isFromTrainingMovement) {
        return ' movement-guideline ';
    } else if(isTabOrMobile && showFullGuidelineInTabOrMobile) {
        return ' movement-guideline--mobile ';
    } else {
        return ' movement-guideline--bottom-right-position ';
    }
}

export const getElementStyle = (isTabOrMobile, showFullGuidelineInTabOrMobile, isFromTrainingMovement) => {
    if(!isTabOrMobile || isFromTrainingMovement) {
        return " ";
    } else if(isTabOrMobile && showFullGuidelineInTabOrMobile) {
        return " ";
    } else {
        return " d-none ";
    }
}
