import React from 'react';

import bob from '../../../assets/img/chat/bob.svg';
import "../../../assets/sass/components/Chatbot/_chatWelcome.scss";
import GLOBAL from "../../../global";
import NotificationDefaultIcon from "../../../assets/img/utils/notification-default-icon.svg";
import SavingAnimation from "./SavingAnimation";
import { useSelector } from "react-redux";

const ChatWelcome = ({ botName }) => {
    const chatBotState = useSelector(state => state.chatBot);
    const chatBotUIState = chatBotState?.chatBotUIState || {};
    const showWelcomePage = chatBotUIState?.showWelcomePage || false;

    return (
        showWelcomePage ? (
            <div className="chat-welcome">
                <div className="chat-welcome__container">
                    <div className="chat">
                        <div className="chat-container">
                            <div className="img">
                                <div className="img-container">
                                    <img className="mx-auto bob-pic" src={bob} alt="welcome" />
                                    <img className="profile-pic" alt="Pic"
                                         src={GLOBAL.USER_INFO['ProfilePic'] ? GLOBAL.USER_INFO['ProfilePic'] : NotificationDefaultIcon} />
                                </div>
                            </div>
                            <div className="chat-container--details">
                                <h1>Welcome</h1>
                                <p>to your {botName}</p>
                            </div>
                        </div>

                        <SavingAnimation  />
                    </div>
                </div>
            </div>
        ) : null
    );
};

export default ChatWelcome;
