import { ChatbotButton } from "../ButtonComponent";
import React, { useEffect } from "react";
import { getBodyRegion, getMarginStyle } from "../ChatServices";
import {playAudio} from "../../../../utils/Utils";
import CONSTANT from "../../../../constants/Constant";
import {useSelector} from "react-redux";
import useCurrentQuestionResponse from "../hooks/useCurrentQuestionResponse";

const LabelQuestionsAnswers = () => {
    const { onClickButtonAnswer } = useCurrentQuestionResponse();
    const chatBotState = useSelector(state => state.chatBot);
    const botData = chatBotState?.botData || {};
    const chats = botData?.chats || [];
    const currentChatIndex = botData?.currentChatIndex ?? -1;

    const chat = chats[currentChatIndex];

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (chat.intent === CONSTANT.CHATBOT_INTENT.CLICK_AUTOMATIC) {
               await playAudio(chat.audioUrl)
               await onClickButtonAnswer(chat, {id: 0});
               clearTimeout(timer);
            }
        }, 300);
    }, []);

    return (
        <div className="">
            {chat && !chat.answered && (
                <div className="answers">
                    <div className="kt-widget3">
                        <div className="kt-widget3__item">
                            <div className="kt-widget3__header d-flex justify-content-end">
                                <div className="">
                                    <ChatbotButton
                                        id={`btn-${chat.questionId}-ClickAutomatic`}
                                        type=""
                                        fill={'true'}
                                        className={`d-none`}
                                    >
                                        <div className="d-flex">
                                            <span className={`m-auto ${getMarginStyle(chat)}`} >
                                                {getBodyRegion(chats)}
                                            </span>
                                        </div>
                                    </ChatbotButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {chat && chat.answered && (
                <div className="answers">
                    <div className="kt-widget3">
                        <div className="kt-widget3__item">
                            <div className="kt-widget3__header d-flex justify-content-end">
                                <div className="kt-widget3__user-img">
                                    <ChatbotButton
                                        id={`btn-${chat.questionId}-ClickAutomatic`}
                                        type=""
                                        fill={'true'}
                                        className={'d-none'}>
                                        <div className="d-flex">
                                            <span className={`m-auto ${getMarginStyle(chat)}`}>
                                                {getBodyRegion(chats)}
                                            </span>
                                        </div>
                                    </ChatbotButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
};

export default LabelQuestionsAnswers;