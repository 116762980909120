import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Field } from "formik";

const DatePickerComponent = (props) => {
    const { name, design = "" } = props;
    return (
        <Field name={name}>
            {({ form, field }) => {
                const { setFieldValue } = form;
                const { value } = field;
                return (
                    <DatePicker
                        className={`form-control ${design}`}
                        id={name}
                        {...field}
                        selected={value}
                        onChange={(val) => setFieldValue(name, val)}
                        dateFormat="MM/dd/yyyy"
                    />
                );
            }}
        </Field>
    );
};

export default DatePickerComponent;
