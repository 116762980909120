// import React from 'react';
// import {Modal , ModalBody} from 'reactstrap';
//
//
// const AppointmentConfirmPopup =(props)=>{
//
//   let handleClose = (e)=>{
//     e.preventDefault();
//     props.submitCloseFunc(false);
//   };
//   return <>
//   <Modal isOpen={props.submitStatus}   className="modal-container d-flex justify-content-center align-items-center w-100">
//         <ModalBody className='modal-wrapper fw-normal text-center p-0 '>
//           Thank you for requesting an appointment. An email has been sent to your provider. They will reach out to you shorty to schedule you.
//           </ModalBody>
//         <div className='closeButton mx-auto text-white text-center w-100 py-1 mt-3' onClick={handleClose}>
//           Close
//         </div>
//       </Modal>
//   </>
//
// };
//
// export default AppointmentConfirmPopup;


import React from 'react';
import {Modal , ModalBody} from 'reactstrap';


const AppointmentConfirmPopup =(props)=>{

    let handleClose = (e)=>{
        e.preventDefault();
        props.submitCloseFunc(false);
    };
    return <>
        <Modal isOpen={props.submitStatus}   className="modal-container d-flex justify-content-center align-items-center w-100">
            <ModalBody className='text-center p-0'>
                Thank you for requesting an appointment. An email has been sent to your provider. Your provider will reach out to you shorty to confirm your appointment.
            </ModalBody>
            <div className='closeButton mx-auto text-white text-center w-100 py-1 mt-3' onClick={handleClose}>
                Close
            </div>
        </Modal>
    </>

};

export default AppointmentConfirmPopup;
