import React from 'react'
import IconCloseButton from "../IconCloseButton";

const CCHeader = (props) => {
    const {bodyLocation, closeModal, submitHandler, clearAnswer, isSubmiting, isAnyQuestionAnswered, isReportAvailable = false} = props
    return (
        <div className="cc-modal-header">
            <div className="cc-modal-header__container mr-3">
                <div className="cc-modal-header-title">{bodyLocation}</div>
                <div>
                    <button
                        type="button"
                        className="button rounded-btn bg-color-bright-light-green"
                        disabled={isSubmiting || isReportAvailable}
                        onClick={submitHandler}>
                        {isSubmiting && (<>Please wait</>)}
                        {!isSubmiting && ( <>Save & Close</>)}
                    </button>
                    <button
                        type="button"
                        className="kt-ml-20 button rounded-btn bg-color-red"
                        disabled={isSubmiting || !isAnyQuestionAnswered || isReportAvailable}
                        onClick={clearAnswer}
                    >
                        Clear
                    </button>
                </div>
            </div>
            <IconCloseButton closeHandler={() => closeModal(!true)} styleClass="cc-cross-btn"/>
        </div>
    )
}

export default CCHeader;