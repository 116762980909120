import CONSTANT from "../constants/Constant";
import { ensureUTC, truncateTime } from "../utils/Utils";
import useSweetAlert from "../components/shared/sweetAlertComponent/useSweetAlert";

const useHandleExpiredAndUpcomingMeetingPopupMsg = () => {
    const { showSweetAlert, hideSweetAlert } = useSweetAlert();

    const getAssessmentAvailabilityStatus = (meetingStartDateUTC, meetingEndDateUTC) => {
        if(!meetingStartDateUTC || !meetingEndDateUTC) {
            return CONSTANT.ASSESSMENT_AVAILABILITY.AVAILABLE.KEY;
        }

        let currentTime = new Date();
        currentTime = truncateTime(new Date(currentTime.toISOString())); // Ensure current time is in UTC and truncated

        // Parse meeting times in UTC, truncate them, and ensure they're handled in UTC
        const meetingStartLocal = truncateTime(new Date(ensureUTC(meetingStartDateUTC)));
        const meetingEndLocal = truncateTime(new Date(ensureUTC(meetingEndDateUTC)));

        // 1 hour in milliseconds
        const oneHourMs = 60 * 60 * 1000;

        // Check if current time is within 1 hour before the meeting start and 1 hour after the meeting end
        if (currentTime >= new Date(meetingStartLocal.getTime() - oneHourMs) && currentTime <= new Date(meetingEndLocal.getTime() + oneHourMs)) {
            return CONSTANT.ASSESSMENT_AVAILABILITY.AVAILABLE.KEY;
        } else if (currentTime < new Date(meetingStartLocal.getTime() - oneHourMs)) {
            // If the current time is more than 1 hour before the meeting start
            return CONSTANT.ASSESSMENT_AVAILABILITY.UPCOMING.KEY;
        } else {
            // If the current time is more than 1 hour after the meeting end
            return CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.KEY;
        }
    }

    const handleOpenUpcomingOrExpiredPopupMessage = (telemedicineStatus, callBackBeforeOpeningPopup, callBackOnClickOkAtPopup) =>{
        if(!telemedicineStatus) return;

        let message = '';
        if(telemedicineStatus === CONSTANT.ASSESSMENT_AVAILABILITY.UPCOMING.KEY) {
            message = CONSTANT.ASSESSMENT_AVAILABILITY.UPCOMING.POPUP_MESSAGE;
        } else if(telemedicineStatus === CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.KEY) {
            message = CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.POPUP_MESSAGE;
        }

        callBackBeforeOpeningPopup && callBackBeforeOpeningPopup();
        showSweetAlert({
            title: '',
            message: message,
            type: CONSTANT.ALERT_TYPE.INFO,
            closeOnClickOutside: false,
            showOkBtn: true,
            onConfirm: () => {
                hideSweetAlert();
                callBackOnClickOkAtPopup && callBackOnClickOkAtPopup();
            }
        })
    }

    return {
        getAssessmentAvailabilityStatus,
        handleOpenUpcomingOrExpiredPopupMessage,
    }
}

export default useHandleExpiredAndUpcomingMeetingPopupMsg;