import React, { Fragment, useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal } from "reactstrap";

import CCHeader from './CCHeader';
import Answer from './Modals/Answer';
import CCQuestion from "./CCQuestion";
import Question from './Modals/Question';
import useScroll from "../../../hooks/useScroll";
import QuestionGroup from './Modals/QuestionGroup'
import examService from "../../../services/ExamService";
import ConfirmationDialog from "../Popups/ConfirmationDialog";
import {
    getFirstUnAnsweredQuestion,
    createErrorForBodyQuestions,
    prepareAnswer,
    preparePayload,
    isAnsweringLastCCQuestion
} from "./services/chief-complaint";
import { prepareVasQuestionFormate, confirmationModalConfiguration, getBlurStyle } from "./utils";
import GLOBAL from "../../../global";
import CONSTANT from "../../../constants/Constant";
import useSweetAlert from "../sweetAlertComponent/useSweetAlert";

const ChiefComplaintQuestion = (props) => {
    const {bodyLocation, vasQestionHandler, closeModal, locationName, testId, onSubmit, side, isReportAvailable = false, updateAnswer, ccQuestion} = props
    const { showSweetAlert, hideSweetAlert } = useSweetAlert();

    const [allAnswers, setAllAnswers] = useState({})
    const [allQuestions, setAllQuestions] = useState([])
    const [error, setError] = useState({})
    const [loading, setLoading] = useState(true)
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [showMessage, setShowMessage] = useState('')
    const [currentSelectedQuestionId, setCurrentSelectedQuestionId] = useState(0)
    const [confirmationModalCredential, setConfirmationModalCredential] = useState(confirmationModalConfiguration);
    const [ firstUnAnsweredQuestion, setFirstUnAnsweredQuestion ] = useState({});

    const modalRef = useRef(null);
    const vasQuestionAnswer = useRef(null)
    const selectedBodyPointsQuestionId = useRef([])
    const isAllQuestionAreAnsweres = useRef(false)
    /// Custom Hooks
    const setSelectedElement = useScroll(modalRef);

    useEffect(() => {
        (async () => {
            await fetchData()
            setBorder(-1, true)
        })()

        return () => {
            setAllAnswers({})
            setAllQuestions([])
            setError({})
            setLoading(false)
            setFirstUnAnsweredQuestion({});
        }
    }, [])

    const setBorder = (questionId, setBorderToFirstQuestion = false) => {
        const index = selectedBodyPointsQuestionId.current.indexOf(questionId)
        if(setBorderToFirstQuestion) {
            setCurrentSelectedQuestionId(selectedBodyPointsQuestionId.current[0])
            return;
        }
        if(index >= 0 && index < selectedBodyPointsQuestionId.current.length-1) {
            setCurrentSelectedQuestionId(selectedBodyPointsQuestionId.current[index+1]);
        }
    }

    const mapDBQuestionToLocalModalAndCollectAllQuestionsId = (allQuestions = []) => {
        const allQuestion = []
        let singleGroupFormatedQuestions = []
        const allQuestionIds = [];
        allQuestions instanceof Array &&  allQuestions.forEach(questions => {
            const groupQuestion = new QuestionGroup(questions)
            groupQuestion.questions.map(question => {
                const formatedQuestion = new Question(question)
                if(formatedQuestion.dialogue && formatedQuestion.responses.length > 0) {
                    singleGroupFormatedQuestions.push(formatedQuestion)
                    allQuestionIds.push(formatedQuestion.questionId)
                }
            })
            allQuestion.push({header: groupQuestion.header, questions: singleGroupFormatedQuestions })
            singleGroupFormatedQuestions = []
        })
        selectedBodyPointsQuestionId.current = allQuestionIds
        return allQuestion
    }

    const mapDBAnswerToLocalModal = (answers = []) => {
        const formatedAnswers = {};
        answers instanceof Array && answers.map(answer => {
            const mappedAnswer = new Answer(answer)
            formatedAnswers[mappedAnswer.QuestionId] = mappedAnswer
        })
        return formatedAnswers
    }

    const fetchData = async () => {
        const payload = {
            TestId: testId,
            BodyLocation: bodyLocation,
        };

        const { data, success, error } = await examService.getCCKeyPointQuestions(payload);

        if (!success) {
            console.error(error);
            return;
        }

        const questions = mapDBQuestionToLocalModalAndCollectAllQuestionsId(data?.AllQuestions)
        const answers = mapDBAnswerToLocalModal(data?.Answers)

        setAllAnswers(answers);
        setAllQuestions(questions);
        setLoading(false)
        setFirstUnAnsweredQuestion(getFirstUnAnsweredQuestion(selectedBodyPointsQuestionId.current, questions, answers));
    }

    const getResponseStyle = (questionId, optionId, color) => {
        const answer = allAnswers[questionId]

        if(color && answer && answer?.AnswerId === optionId) {
            return { backgroundColor: color, border: `2px solid ${color}` }
        } else if(color) {
            return {border: `2px solid ${color}`}
        } else if(answer && answer?.AnswerId === optionId) {
            return { backgroundColor: "#3BA7E2", color: "white !important"}
        } else {
            return { backgroundColor: "white" }
        }
    }

    const isResponseSelected = (questionId, optionId, color) => {
        const answer = allAnswers[questionId]

        if(color && answer && answer?.AnswerId === optionId) {
            return true;
        } else if(answer && answer?.AnswerId === optionId) {
            return true;
        }

        return false;
    }

    const onChangeCollectResponse = async (event, question, option) => {
        if(isReportAvailable) {
            return;
        }

        setSelectedElement(event.target);
        const newAnswer = prepareAnswer(question, option, bodyLocation);
        setAllAnswers(prevState => ({...prevState, [question.questionId]: newAnswer}));
        setBorder(question.questionId);
        setError(prevState => ({...prevState, [question.questionId]: ''}))

        if(question.isVasQuestion) {
            vasQuestionAnswer.current = newAnswer;
        }
        let firstUnaAnsweredQuestion = getFirstUnAnsweredQuestion(selectedBodyPointsQuestionId.current, allQuestions, { ...allAnswers, [question.questionId]: newAnswer });
        setFirstUnAnsweredQuestion(firstUnaAnsweredQuestion);
        //if from bot and click on last question and all questions are answered
        if(onSubmit && isAnsweringLastCCQuestion(selectedBodyPointsQuestionId.current, question.questionId) && Object.keys(firstUnaAnsweredQuestion).length === 0) {//onSubmit is only for bot
            setConfirmationModalCredential({
                isSaving: true,
                isShowConfirmationModal: true,
            })

            await saveAllAnswers({ ...allAnswers, [question.questionId]: newAnswer});

            setConfirmationModalCredential({
                isShowConfirmationModal: true,
                headerText: "Confirmation",
                yesAction: () => closeModal(false),
                noAction: () => {
                    onSubmit && onSubmit();
                    setConfirmationModalCredential(confirmationModalConfiguration);
                },
                message: "Do you have any other Chief Complaint?",
                confirmationButtonText: "Saving...",
            })
        }
    }

    const saveAllAnswers = async (tempAnswers = undefined) => {
        setIsSubmiting(true)
        const payload = preparePayload(testId, tempAnswers ? tempAnswers : allAnswers);
        const response = await examService.saveAssessments(payload);
        const { success, data } = response;

        if(!success) {
            setIsSubmiting(false)
            setShowMessage('Failed to save your response.Please try again.')
            return;
        }
        GLOBAL.ISANYCHIEFCOMPLAINTANSWEDED = true;//only for bot
        if(vasQuestionAnswer.current) {
            vasQestionHandler(prevState => ({...prevState, [bodyLocation]: prepareVasQuestionFormate(locationName, vasQuestionAnswer.current, side)}))
        }
        setIsSubmiting(false);

        const ChiefComplaintsQuestionStatus = data?.ChiefComplaintsQuestionStatus;
        updateAnswer && ChiefComplaintsQuestionStatus && updateAnswer(ChiefComplaintsQuestionStatus?.AnswerId, ChiefComplaintsQuestionStatus?.AnswerValue);
    }

    const submitResponse =  async () => {
        const errors = createErrorForBodyQuestions(selectedBodyPointsQuestionId.current, allAnswers)
        setError(errors)

        if(Object.keys(allAnswers).length < selectedBodyPointsQuestionId.current.length) {
            showSweetAlert({
                title: 'Attention',
                message: "It is required to fill in all mandatory fields in order to submit.",
                type: CONSTANT.ALERT_TYPE.ERROR,
                onConfirm: hideSweetAlert,
                showCancelBtn: false,
                showConfirmBtn: false,
                showOkBtn: true,
                confirmBtnText: 'OK',
            });

            return;
        }

        setConfirmationModalCredential({
            isShowConfirmationModal: true,
            yesAction: handleSaveAndClose,
            noAction: () => setConfirmationModalCredential(confirmationModalConfiguration),
            message: "Do you want to save?",
            confirmationButtonText: "Saving...",
        })
    }

    const handleSaveAndClose = async () => {
        await saveAllAnswers();
        closeModal(false); //to close chief complaint question modal
    }

    const sendRequestToClearAllAnswers = async () => {
        setIsSubmiting(true)
        // const QuestionIds = [...selectedBodyPointsQuestionId.current]
        // const payload = {
        //     TestId: testId,
        //     QuestionIds
        // }
        // const response = await examService.clearAssessments(payload)

        let payload = {
            TestId: testId,
            BodyPoints: [bodyLocation]
        }

        const response = await examService.clearCCQuestionOfAPoint(payload);
        // const { data, success, error } = response;
        const { success} = response;

        if(!success) {
            setIsSubmiting(false)
            setShowMessage('Failed to Clear questions.Please try again.')
            return;
        }
        const data = response.data;
        const ChiefComplaintsQuestionStatus = data?.ChiefComplaintsQuestionStatus;
        updateAnswer && ChiefComplaintsQuestionStatus && updateAnswer(ChiefComplaintsQuestionStatus?.AnswerId, ChiefComplaintsQuestionStatus?.AnswerValue);
        setAllAnswers({})
        vasQuestionAnswer.current = {}
        setIsSubmiting(false)
        vasQestionHandler(prevState => {
            let newState = {...prevState}
            delete newState[bodyLocation]
            return newState;
        })
        setBorder(-1, true)
        setError({})

        let domElement = null;
        if(allQuestions.length > 0) {
            domElement = document.getElementById(allQuestions[0].questionId)
        }
        if(domElement) {
            setSelectedElement(domElement)
        }
        isAllQuestionAreAnsweres.current = false
        setShowMessage('All questions has been clear successfully.');
        setConfirmationModalCredential(confirmationModalConfiguration)
    }

    const clearAllAnswers = async () => {
        setConfirmationModalCredential({
            isShowConfirmationModal: true,
            yesAction: sendRequestToClearAllAnswers,
            noAction: () => setConfirmationModalCredential(confirmationModalConfiguration),
            message: "Are you sure to clear this body location?",
            confirmationButtonText: "Clearing...",
        })
    }

    return (
        <Modal
            isOpen={true}
            data-testid="my-component"
            backdrop={'static'}
            className="modal-xl kt-m0 height-100vh"
            modalClassName="mmh-movement-modal overflow-none">
            <>
                {loading ? <div className="kt-spinner kt-spinner--lg kt-spinner--info center-element"/> :
                    selectedBodyPointsQuestionId.current.length <= 0 ?
                        <div className='position-center d-flex justify-content-center align-items-center flex-column'>
                            <p className='heading-primary'>This body point has no question. Please try different one.</p>
                            <button className='edit-assessment-button btn-hover-bg-primary-light-1' onClick={() => closeModal(!true)}>Close</button>
                        </div> :
                    <>
                        <CCHeader
                            bodyLocation={locationName}
                            // isAllQuestionsAnswered={Object.keys(allAnswers).length >= selectedBodyPointsQuestionId.current.length}
                            isSubmiting={isSubmiting}
                            submitHandler={submitResponse}
                            closeModal={closeModal}
                            clearAnswer={clearAllAnswers}
                            isAnyQuestionAnswered={Object.keys(allAnswers).length > 0}
                            isReportAvailable={isReportAvailable}
                            isResponseSelected={isResponseSelected}
                        />
                        <div className="position-relative kt-portlet__body kt-padding-b-0">
                            {Object.keys(firstUnAnsweredQuestion).length !== 0 && (
                                <div className="position-absolute top-0 bg-white z-10 w-100-percent">
                                    <CCQuestion
                                        question={firstUnAnsweredQuestion}
                                        currentSelectedQuestionId={currentSelectedQuestionId}
                                        error={error}
                                        getResponseStyle={getResponseStyle}
                                        onChangeCollectResponse={onChangeCollectResponse}
                                        setFirstUnAnsweredQuestion={setFirstUnAnsweredQuestion}
                                        isReportAvailable={isReportAvailable}
                                        isResponseSelected={isResponseSelected}
                                    />
                                </div>
                            )}
                            <div className="p-2 m-2" ref={modalRef} style={{height: "92.4vh", overflow: "auto", marginBottom: '2rem', position: "relative"}}>
                                <div className={`chief-complaint-question-container ${getBlurStyle(firstUnAnsweredQuestion)}`}>
                                    {allQuestions.map((groupQuestion, index) =>
                                        <Fragment key={index}>
                                            <div className='font-size-1_3rem kt-section__title kt-font-boldest kt-margin-0 kt-mb-10 pl-2'>{groupQuestion.header}</div>
                                            {groupQuestion.questions.map((question, idx) =>
                                                <CCQuestion
                                                    key={`questions-${idx}`}
                                                    question={question}
                                                    currentSelectedQuestionId={currentSelectedQuestionId}
                                                    error={error}
                                                    getResponseStyle={getResponseStyle}
                                                    onChangeCollectResponse={onChangeCollectResponse}
                                                    isReportAvailable={isReportAvailable}
                                                    isResponseSelected={isResponseSelected}
                                                />
                                            )}
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                }
                {showMessage && (
                    <SweetAlert
                        confirmBtnCssClass="btn btn-sm btn-success"
                        showCancel={false}
                        show={!!showMessage}
                        title='Information'
                        onConfirm={() => setShowMessage('')}
                        confirmBtnText="OK"
                        closeOnClickOutside={false}>
                        {showMessage}
                    </SweetAlert>
                )}
                <ConfirmationDialog
                    confirmText={isSubmiting ? `${confirmationModalCredential?.confirmationButtonText}`: `Yes`}
                    headerText={confirmationModalCredential?.headerText ? confirmationModalCredential?.headerText : null}
                    isSaving={confirmationModalCredential?.isSaving || false}
                    cancelText="No"
                    show={confirmationModalCredential?.isShowConfirmationModal}
                    onClickHeaderClose={() => setConfirmationModalCredential(confirmationModalConfiguration)}
                    toggleDialog={confirmationModalCredential?.noAction ? confirmationModalCredential?.noAction : () => setConfirmationModalCredential(confirmationModalConfiguration)}
                    onConfirm={confirmationModalCredential?.yesAction}
                    showLoadingInConform={isSubmiting}
                    isYesInLeft={true}>
                    {confirmationModalCredential?.message}
                </ConfirmationDialog>
            </>
        </Modal>
    )
}

export default ChiefComplaintQuestion
