import { createSlice } from "@reduxjs/toolkit";
import { initialIntakeFormState } from "./intakeFormConstant";

const IntakeFormSlice = createSlice({
    name: 'intakeAndJoinTelemedicinePopup',
    initialState: initialIntakeFormState,
    reducers: {
        initIntakeForm(state, action) {
            const bodyRegionList = action.payload?.bodyRegionList || [];
            const invitationTypeName = action.payload?.invitationTypeName || '';
            const showSubmitBtn = action.payload?.showSubmitBtn || false;
            const submitBtnCallbackFnKey = action.payload?.submitBtnCallbackFnKey || '';
            const joinLaterBtnCallbackFnKey = action.payload?.joinLaterBtnCallbackFnKey || '';
            const joinBtnCallbackFnKey = action.payload?.joinBtnCallbackFnKey || '';
            const isFromCalendar = action.payload?.isFromCalendar || false;


            state.showIntakeForm = true;
            state.showSubmitBtn = showSubmitBtn;
            state.bodyRegionList = bodyRegionList;
            state.invitationTypeName = invitationTypeName.toLowerCase();
            state.isFromCalendar = isFromCalendar;

            state.submitBtnConfig.showSubmitBtn = showSubmitBtn;
            state.submitBtnConfig.submitBtnCallbackFnKey = submitBtnCallbackFnKey;
            state.joinNowBtnConfig.joinBtnCallbackFnKey = joinBtnCallbackFnKey;
            state.joinLaterBtnConfig.joinLaterBtnCallbackFnKey = joinLaterBtnCallbackFnKey;
        },
        toggleShowPolicy(state) {
            state.showPolicy = !state.showPolicy;
        },
        toggleShowTermsAndConditionModal(state) {
            state.showTermsAndConditionModal = !state.showTermsAndConditionModal;
        },
        closeIntakeForm() {
            return {
                ...initialIntakeFormState
            }
        },
        setJoiningTelemedicineAtIntakeForm(state, action) {
            return {
                ...state,
                joinNowBtnConfig: {
                    ...state.joinNowBtnConfig,
                    joiningTelemedicine: action.payload
                }
            }
        },
        setJoiningLaterTelemedicineAtIntakeForm(state, action) {
            return {
                ...state,
                joinLaterBtnConfig: {
                    ...state.joinLaterBtnConfig,
                    joiningLaterTelemedicine: action.payload
                }
            }
        },
    }
});

export const {
    closeIntakeForm,
    toggleShowTermsAndConditionModal,
    setJoiningTelemedicineAtIntakeForm,
    setJoiningLaterTelemedicineAtIntakeForm,
    toggleShowPolicy,
    initIntakeForm,
} = IntakeFormSlice.actions;

export default IntakeFormSlice.reducer;