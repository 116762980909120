import GLOBAL from "../../../global";
import examService from "../../../services/ExamService";
import { storeUserInfoToLocalStorageFromGlobalConstant } from "../../../utils/Utils";

export const saveIntake = async (values, testId = "") => {
    const isIntakeSigned = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['IsIntakeSigned']) || '';

    if (isIntakeSigned) return;
    let tempTestId = (GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['TestId']) || "";

    const payload = {
        TestId: testId ? testId : tempTestId,
        IntakeData: {
            PatientOrRepresentativeSignature: values?.PersonalRepresentative,
            PatientOrRepresentativeSignatureDate: values?.Date,
            PatientSignature: values?.PatientSignature,
            PatientSignatureDate: values?.Today,
            IsIntakeSigned: true,
            IsConsentAgreed: true
        }
    };

    const response = await examService.updateIntake(payload);
    const { data, success, error } = response;

    if (!success) {
        console.error(error);
        return response;
    }

    const intake = data || '{}';
    GLOBAL.USER_INFO = {
        ...GLOBAL.USER_INFO,
        IsIntakeSigned: intake.IsIntakeSigned
    };
    storeUserInfoToLocalStorageFromGlobalConstant();
    return response;
}