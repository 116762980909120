
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import SweetAlert from "react-bootstrap-sweetalert";
import { Trans, useTranslation } from "react-i18next";

import AppContext from "../../contexts/AppContext";
// import GLOBAL from "../../global";
import routes from "../../constants/Routes";

import authService from "../../services/AuthService";
// import LogoPng from "../../assets/img/logo/logo.png";
import { initialValuesForForgot, invalidFormElement } from "../../utils/DefaultInitialization";
import { getUserIdentification } from "../../utils/Utils";
import leftIcon from "../../assets/img/signin/leftIcon.svg";
import doctorIcon from "../../assets/img/signin/doctor-icon.svg";
import topRoundIcon from "../../assets/img/signin/topRound.svg";
import bottomRoundIcon from "../../assets/img/signin/bottomRound.svg";
import mmhColorLogo from "../../assets/img/signin/mmhLogo.svg";
import ArrowLeftBack from "../../assets/img/utils/arrowLeftBack.svg"

function Forgot() {
    const { authenticated } = useContext(AppContext);
    const [redirectToDashboard, setRedirectToDashboard] = useState(false);
    const [redirectToSignUp] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const { i18n } = useTranslation();
    const subscription = useRef(true);
    // let history = useHistory();

    const validationSchema = () => {
        return Yup.object().shape({
            Email: Yup.string()
                .email('Please enter a valid email')
                .required(i18n.t("common.message.error.required-field"))
        });
    };

    const onSubmitRequest = async (values, actions) => {
        actions.setSubmitting(true);
        setErrorMessage('');

        const tempValues = { ...values };
        tempValues.EmailAddress = tempValues.Email;
        delete tempValues.Email;

        const response = await authService.forgot(tempValues);
        const { success } = response;

        if (!success) {
            setErrorMessage('Your email or user id is incorrect');
            actions.setSubmitting(false);
            return;
        }

        actions.setSubmitting(false);
        setShowAlert(true);
    };

    const onConfirmAlert = () => {
        setShowAlert(false);
        window.location.replace(`/#${routes.signIn}`);
    };

    useEffect(() => {
        if (subscription.current) {
            if (authenticated) {
                setRedirectToDashboard(true);
            }
        }

        return () => {
            subscription.current = false;
        };
    }, [authenticated]);

    if (redirectToDashboard) {
        return <Redirect to={`${routes.dashboard}`}/>;
    }

    if (redirectToSignUp) {
        return <Redirect to={`${routes.signUp}`}/>;
    }

    return (
        <>
            <div className="forgot_password">
                <img src={mmhColorLogo} className="color_logo" height="65" alt="MMH Logo"/>)}
                <img src={leftIcon} className='left_icon' />
                <img src={doctorIcon} className='doctorimg'/>
                <img src={topRoundIcon} className='halfround'/>
                <img src={bottomRoundIcon} className='roundBottom'/>
                <div className="forgot_password_form">
                    {/*<Link to={routes.landing}><img src={ArrowLeft} alt="Back Button" className="arrowLeft margin-right-3-rem" /><span>Back</span></Link>*/}
                    <Formik
                        initialValues={initialValuesForForgot()}
                        validationSchema={validationSchema()}
                        onSubmit={onSubmitRequest}>
                        {({
                              handleChange,
                              handleSubmit,
                              handleBlur,
                              values,
                              errors,
                              touched,
                              isSubmitting,
                          }) => (
                            <div>
                                {/*<div className="color_logo_mmh">*/}
                                {/*    <a href={"!#"}>*/}
                                {/*        {!GLOBAL.LOGO_URL ? (*/}
                                {/*            <img src={GLOBAL.LOGO_URL} height="65" alt="MMH Logo"/>) : (*/}
                                {/*            <img src={mmhColorLogo} height="65" alt="MMH Logo"/>)}*/}
                                {/*    </a>*/}
                                {/*</div>*/}
                                <div className="header">
                                    <div className="d-inline-block">
                                        <Link to={routes.landing}><img src={ArrowLeftBack} alt="Back Button" className="arrowLeftBack"/></Link>
                                    </div>
                                    <div className="d-inline-block ml-5">
                                        <h3 className="mb-0 ml-4"> Forgotten Password ? </h3>
                                    </div>
                                </div>
                                <div  className="forgot_password_text">
                                    <span>Enter your email or user id to reset your password:</span>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="paddingX_4_rem margin_bootom_5rem">
                                        <div className="input-container">
                                            <input
                                                className={`signup_signin_input-field ${invalidFormElement(
                                                    "Email",
                                                    errors,
                                                    values,
                                                    touched
                                                )}`}
                                                type="email"
                                                placeholder="Enter Your Email Address or User Id"
                                                name="Email"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.Email}
                                            />
                                        </div>
                                        {errorMessage && (
                                            <div className="kt-login__account">
                                                    <span className="kt-login__account-msg text-danger">
                                                        {errorMessage}
                                                    </span>
                                            </div>
                                        )}
                                        {errors.Email && touched.Email && (
                                            <div className="invalid-feedback">{errors.Email}</div>
                                        )}
                                    </div>
                                    <div className="d-flex forgot_password_margin_bottom_2rem button__container" >
                                        <button
                                            type="submit"
                                            className="button__request"
                                            disabled={isSubmitting}>
                                            {isSubmitting ? (
                                                <>
                                                    <i className="fa fa-spinner fa-spin"/>&nbsp;&nbsp;
                                                    <Trans i18nKey="common.message.info.please-wait"/>
                                                </>
                                            ) : (
                                                'Submit'
                                            )}
                                        </button>
                                        &nbsp;&nbsp;
                                        {/*<Link to={routes.landing}>*/}
                                        {/*        <button*/}
                                        {/*            className="button__cancel">Cancel*/}
                                        {/*        </button>*/}
                                        {/*</Link>*/}
                                    </div>
                                </form>
                                <div className="forgot_footer">
                                    <span>
                                        Are you a returning patient? Click here
                                    </span>
                                    &nbsp;
                                    <Link to={routes.landing}>
                                        <a
                                            href={"!#"}
                                            rel="noopener noreferrer"
                                            className="kt-login__account-link" style={{fontSize:"1.40rem"}}
                                        >
                                            <Trans i18nKey="common.form.sign-in"/>!
                                        </a>
                                    </Link>
                                </div>
                                <div className="kt-login__account d-none">
                                <span className="kt-login__account-msg">
                                    Don't have a {getUserIdentification() === 'patient' ? 'provider' : 'patient'} login yet?
                                </span>
                                    <br/>
                                    <a
                                        href="/"
                                        rel="noopener noreferrer"
                                        className="btn btn-primary kt-mt-10">
                                        GO BACK!
                                    </a>
                                </div>
                            </div>
                        )}
                    </Formik>
                </div>
            </div>
            <SweetAlert show={showAlert} title='' success onConfirm={onConfirmAlert}>
                An email has been sent to the supplied email address.
            </SweetAlert>
        </>
    );
}

export default Forgot;
