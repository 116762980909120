import React, { useState } from "react";
import InputMask from "react-input-mask";
import {toast} from "react-toastify";
import {formatDateToMMDDYYYY} from "../../../utils/Utils";

const MaskInput = ({ label, value, question, error, placeHolder, onChange, mask, disabled = false }) => {

    const [inputValue, setInputValue] = useState(value);

    const onChangeInputText = (e) => {
        if(disabled) {
            toast.error("Report already ready can not change the response");
            return;
        }

        const value = e.target.value;
        setInputValue(value);
        onChange && onChange({ question: question, answerText: value });
    }

    const onBlurInput = (e) => {
        if(disabled) {
            toast.error("Report already ready can not change the response");
            return;
        }

        const value = e.target.value;
        setInputValue(value);
        onChange && onChange({ question: question, answerText: value });
    }

    return (
        <div className="form-group row">
            <label className="col-xl-4 col-lg-4 col-form-label heading-primary">{label}</label>
            <div className="col-lg-8 col-xl-8">
                <InputMask
                    id={'input-box'}
                    mask={mask}
                    type="text"
                    autoComplete="off"
                    className="text-input"
                    value={formatDateToMMDDYYYY(inputValue)}
                    placeholder={placeHolder}
                    onChange={onChangeInputText}
                    onBlur={(e) => onBlurInput(e)}
                    disabled={disabled}
                />
                {error && error.questionId === question.questionId && (
                    <span className="form-text text-muted kt-font-danger">* {error.message}</span>
                )}
            </div>
        </div>
    )
}

export default MaskInput;