import { useDispatch, useSelector } from "react-redux";
import {
    closeIntakeForm,
    initIntakeForm, setJoiningLaterTelemedicineAtIntakeForm,
    setJoiningTelemedicineAtIntakeForm
} from "../../../features/intakeForm/intakeFormSlice";
import { useCallbackContext } from "../../../contexts/CallbackContext";

const useIntakeFrom = () => {
    const dispatch = useDispatch();
    const { registerCallback, unregisterCallback } = useCallbackContext();

    const intakeFormState = useSelector(state => state.intakeForm);
    const submitBtnConfig = intakeFormState?.submitBtnConfig || {};
    const submitBtnCallbackFnKey = submitBtnConfig.submitBtnCallbackFnKey || '';

    const handleOpenIntakeForm = ({
                                bodyRegionList,
                                invitationTypeName,
                                onClickSubmitIntakeForm,
                                showSubmitBtn = false,
                                onClickJoinTelemedicineNow,
                                onClickJoinTelemedicineLater,
                                isFromCalendar = false,
    }) => {
        let submitBtnKey = '';
        let joinNowBtnKey = '';
        let joinLaterBtnKey = '';

        if(onClickSubmitIntakeForm) {
            submitBtnKey = `submit-${Date.now()}`;
            registerCallback(submitBtnKey, onClickSubmitIntakeForm);
        }

        if(onClickJoinTelemedicineNow) {
            joinNowBtnKey = `joinNow-${Date.now()}`;
            registerCallback(joinNowBtnKey, onClickJoinTelemedicineNow);
        }

        if(onClickJoinTelemedicineLater) {
            joinLaterBtnKey = `joinLater-${Date.now()}`;
            registerCallback(joinLaterBtnKey, onClickJoinTelemedicineLater);
        }

        dispatch(initIntakeForm({
            bodyRegionList: bodyRegionList || [],
            invitationTypeName: invitationTypeName || '',
            showSubmitBtn: showSubmitBtn || false,
            submitBtnCallbackFnKey: submitBtnKey || '',
            joinLaterBtnCallbackFnKey: joinLaterBtnKey || '',
            joinBtnCallbackFnKey: joinNowBtnKey || '',
            isFromCalendar: isFromCalendar || false,
        }));

    }

    const handleCloseIntakeForm = () => {
        if(submitBtnCallbackFnKey) {
            unregisterCallback(submitBtnCallbackFnKey);
        }

        dispatch(closeIntakeForm());
    }

    const handleSetJoiningTelemedicine = (value) => {
        dispatch(setJoiningTelemedicineAtIntakeForm(value));
    }

    const handleSetJoiningLaterTelemedicine = (value) => {
        dispatch(setJoiningLaterTelemedicineAtIntakeForm(value));
    }

    return {
        handleOpenIntakeForm,
        handleCloseIntakeForm,
        handleSetJoiningTelemedicine,
        handleSetJoiningLaterTelemedicine,
    }
}

export default useIntakeFrom;