import CONSTANT from "../../constants/Constant";
import { isEmptyObject } from "../../utils/Utils";
import StepBar from "../shared/StepBar";
import React from "react";

export const _getCompleted = (responseData) => {
    const isCompleted = [
        false,
        responseData?.["DemographicsCompleted"] || false,
        responseData?.["MedicalHistoryCompleted"] || false,
        responseData?.["ADLCompleted"] || false,
        responseData?.["ChiefComplaintsCompleted"] || false,
        responseData?.["BiometricCompleted"] || false,
        false,
        false,
        responseData?.["OswestryCompleted"] || false
    ]
    return isCompleted;
}

export const _getGroups = ({ BodyRegionStr }) => {
    let groups = [];
    for (const [, value] of Object.entries(CONSTANT.ASSESSMENT_GROUP)) {
        if(BodyRegionStr !== CONSTANT.BODY_REGIONS.POSTURE.value) {
            groups.push(value);
        } else {
            if(value.key !== CONSTANT.ASSESSMENT_GROUP.ADL.key) {
                groups.push(value);
            }
        }
    }

    return groups;
}

export const _getInCompleteAssessmentType = (event) => {
    if(!event?.DemographicsCompleted) return CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS;
    else if(!event?.ADLCompleted && event.BodyRegionStr !== CONSTANT.BODY_REGIONS.POSTURE.value ) return CONSTANT.ASSESSMENT_GROUP.ADL;
    else if(!event?.MedicalHistoryCompleted) return CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY;
    else if(!event?.ChiefComplaintsCompleted) return CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS;
    else if(!event?.BiometricCompleted) return CONSTANT.ASSESSMENT_GROUP.BIOMETRIC;
}

export const preparePayload = (BodyRegionList, InvitationId) => {
    return {
        BodyRegionList,
        InvitationId
    }
}

export const getStepBarComponent = (lastIncompletedAssessments, assessment = {}) => {
    const tempAssessment = isEmptyObject(assessment) ? lastIncompletedAssessments : assessment;

    return (
        <div className="padding-bottom-1-rem">
            <div className="margin-bottom-1-rem"></div>
            <StepBar groups={_getGroups({BodyRegionStr: tempAssessment?.BodyRegionList})}
                     isCompleted={_getCompleted(tempAssessment?.AssessmentStatus)}
                     isShowFullName={false}
            />
        </div>
    )
}