export const prepareVasQuestionFormate = (locationName, vasQuestion, side) => {
    const question = {
        color: vasQuestion?.Color || getColor(vasQuestion?.AnswerValue),
        painValue: vasQuestion?.PainValue || +vasQuestion?.AnswerValue,
        side: parseInt(side) || 1,
        name: locationName,
        bodyLocation: vasQuestion?.BodyLocation
    }

    return question
}

export const getColor = (painValue) => {
    if (+painValue >= 0 && +painValue <= 3) {
        return "radioGreen";

    } else if (+painValue >= 4 && +painValue <= 7) {
        return "radioOrange";

    } else if (+painValue >= 8 && +painValue <= 10) {
        return "radioRed";
    } else {
        return "normal"
    }
}

export const confirmationModalConfiguration = {
    isShowConfirmationModal: false,
    yesAction: null,
    noAction: null,
    message: "",
    confirmationButtonText: "Yes"
}

export const getBlurStyle = (firstNotAnsweredQuestion) => {
    return Object.keys(firstNotAnsweredQuestion).length !== 0 ? " pointer-none blurry " : "";
}

export const getStyle = (question, currentSelectedQuestionIndex, firstUnAnsweredQuestion) => {
    let style = {};
    if (question?.questionId === currentSelectedQuestionIndex) {
        style = { border: "3px solid rgb(0, 117, 182) ", borderRadius: "5px" };
    }
    else if (question?.questionId === firstUnAnsweredQuestion?.questionId) {
        style = { border: "3px solid rgb(0, 117, 182) ", borderRadius: "5px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" };
    }

    else {
        style = { border: " 3px solid transparent " };
    }

    return style;
}