import React from "react";
import { Modal, ModalHeader, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";

const PrivacyPolicy = (props) => {
    return (
        <Modal
            isOpen={props.show}
            toggle={props.handler}
            backdrop={"static"}
            className="modal-xl"
        >
            <ModalHeader toggle={props.handler}>
                Privacy Policy &nbsp;&nbsp;
            </ModalHeader>
            <div className="p-4">
                <h1 className="text-center">Privacy Policy</h1>
                <p>Last Updated/Effective Date: March 21, 2020</p>
                <p>
                    This Privacy Policy describes how My Medical Hub Corporation
                    (“MyMedicalHub” or “We” or “Us” or “Our”) uses and discloses the
                    Personal Information you provide to us or that we collect when you use
                    our websites, mobile applications, software, platforms and services
                    (“Services”). Before providing Personal Information to us, please
                    review this Privacy Policy carefully.
                </p>
                <p>
                    Certain MyMedicalHub Services may use a different privacy policy to
                    provide notice to you about how we us and disclose the Personal
                    Information we collect in the context of that Service. To the extent
                    that we post or reference a different privacy policy, that different
                    privacy policy, not this Privacy Policy, will apply to your Personal
                    Information collected in the context of that Service.
                </p>
                <h2>1. Note to our Registered Members</h2>
                <p>
                    This Privacy Policy does not apply to the Personal Information we may
                    collect about your patients, customers, and clients (“Clients”) in the
                    context of providing services to you. Our treatment of Client Personal
                    Information is governed by our agreements with you, including our{" "}
                    <Link to="https://emma.injurycloud.com/Content/home/MMH%20Terms%20of%20service.pdf">
                        {" "}
                        Terms of Service
                    </Link>{" "}
                    and HIPAA{" "}
                    <Link to="https://emma.injurycloud.com/Content/baa/AGREEMENT_WITH_BUSINESS_ASSOCIATE.pdf">
                        Business Associate Agreement
                    </Link>{" "}
                    , as applicable (our “Agreement”). If any provision in our agreement
                    conflicts with any provision in this Privacy Policy, the provision in
                    our Agreement will control to the extent of such conflict.
                </p>
                <p>
                    We do not have direct relationships with your clients. If we receive
                    any inquiries or requests from your Clients about their Personal
                    Information, we will direct those inquiries or requests to you.
                </p>
                <h2>2. How We Collect and Use Your Personal Information</h2>
                <p>
                    “Personal Information” is information that identifies, relates to,
                    describes, is capable of being associated with, or could reasonably be
                    linked, directly or indirectly, with you or your household, such as
                    your name, email address, IP address, telephone number, and broader
                    categories of information such as your professional, educational or
                    health information, commercial information and internet activity.
                </p>
                <p>
                    In the course of our business and in providing our Services to you, we
                    may collect Personal Information directly from you and automatically
                    through our use of cookies and other data collection technologies. We
                    may also collect your Personal Information from third-party sources,
                    such as social media platforms (if you interact with us through your
                    social media account). We will treat Personal Information collected
                    from third-party sources in accordance with this Privacy Policy but we
                    are not responsible for the accuracy of information provided by third
                    parties or for their policies or practices.{" "}
                </p>
                <p>
                    The categories of Personal Information we collect from you depend on
                    your interactions with us. For example, we may collect:
                </p>
                <ul>
                    <li>
                        Identifiers and contact information, such as your name, email
                        address, mailing address, phone numbers, social security numbers,
                        government-issued IDs (such as driver’s licenses), IP addresses, and
                        unique identifiers such as your usernames and passwords. We collect
                        this information directly from you and from third-party sources for
                        the purpose of creating and managing your MyMedicalHub account
                        (“Account”), for communicating with you, verifying your identity,
                        and providing our Services to you.
                    </li>
                    <li>
                        Professional and employment-related information, such as your
                        business name, your license number, calendar and scheduling
                        information, and other information related to your business. We
                        collect this information directly from you for the purpose of
                        administering your Account and providing you, our Services.
                    </li>
                    <li>
                        Transactional information, such as credit or debit card numbers and
                        tax IDs. We collect this information directly from you in order to
                        process your payments for our Services. We also may collect your
                        insurance information in order to process payments made to you by
                        your clients.
                    </li>
                    <li>
                        Audio, electronic and visual information, such as your photograph or
                        image, your voice and other similar information. We process this
                        information to enable your use of our Telehealth service.
                    </li>
                    <li>
                        Internet and other electronic network activity information, such as
                        your browsing history, search history, and your interactions with
                        our Services and advertisements. We collect this information through
                        our cookies and other tracking technologies to conduct business
                        analytics in order to improve our business functionality and
                        Services to you. Please review the “Tracking Technologies and
                        Cookies” section below to learn more about our use of cookies and
                        tracking technologies.
                    </li>
                    <li>
                        Commercial information, such as products and services you have
                        purchased from us. We collect this information to maintain
                        registered member records, identify trends in our registered member
                        relationships, and conduct business analytics.
                    </li>
                    <li>
                        Profile information and inferences, such as information about your
                        preferences and characteristics. We collect profile information by
                        drawing inferences from the above categories of Personal Information
                        in order to understand your preferences and tailor our services and
                        communications to you.
                    </li>
                </ul>
                <p>
                    In addition to the purposes for collection described above, we also
                    collect your Personal Information for the following general purposes:
                </p>
                <ul>
                    <li>
                        To maintain and service your Account, including to fulfill your
                        orders, to confirm your orders, to send you requested product and
                        Service information, and to send you product and Service updates;
                    </li>
                    <li>
                        To respond to your customer service requests and address your
                        questions and concerns;
                    </li>
                    <li>To send you newsletters and marketing communications;</li>
                    <li>
                        To administer and improve our Services and marketing efforts,
                        including measuring the effectiveness of the websites, diagnosing
                        problems with our servers, and analyzing traffic;
                    </li>
                    <li>
                        To detect security incidents, to protect against malicious,
                        deceptive, fraudulent or illegal activity, and to comply with our
                        policies and procedures;
                    </li>
                    <li>
                        To comply with our legal, regulatory and risk management
                        obligations, including establishing, exercising and/or defending
                        legal claims.
                    </li>
                </ul>
                <h2>3. How We Share and Disclose Your Personal Information</h2>
                <p>We may share your Personal Information in the following circumstances:</p>
                <ul>
                    <li>Publicly, but only with your permission: We may share your Personal Information publicly with
                        your permission. For example, with your permission, we may publicly post your photograph, your
                        name, professional titles, and comments on the “Reviews” section of our websites.
                    </li>
                    <li>To Service Providers: We may share your Personal Information with companies that provide
                        services to us, such as for hosting, marketing and communication services, and payment
                        processing (“Service Providers”). Our policy is to authorize these Service Providers to use your
                        Personal Information only as necessary to provide services for us, and require that they not use
                        or disclose your Personal Information for any other purpose.
                    </li>
                    <li>To third parties outside of MyMedicalHub:
                        <ul>
                            <li>From time to time, we may be required to provide Personal Information to a third party
                                in order to comply with a subpoena, court order, government investigation, or similar
                                legal process.
                            </li>
                            <li>We may also share your Personal Information to third parties, such as law enforcement
                                agencies, when we, in good faith, believe that disclosure is necessary to protect our
                                rights, protect your safety or the safety of others, investigate fraud, or respond to a
                                government request.
                            </li>
                            <li>To any other third party with your prior consent to do so.</li>
                        </ul>
                    </li>
                    <li>In a corporate transaction: If MyMedicalHub is involved in a bankruptcy, merger, acquisition,
                        reorganization, or sale of all or a portion of its assets, we may share or transfer your
                        Personal Information as part of any such transaction.
                    </li>
                </ul>
                <p>We do not sell your Personal Information.</p>
                <h2>4. Access and Choice</h2>
                <p><strong>Account Settings</strong></p>
                <p>If your Personal Information changes, or if you no longer desire our Services, you may modify or
                    remove it by logging into your Account and making the changes in your Account settings.</p>
                <p><strong>Marketing Opt-out Preferences</strong></p>
                <p>You can opt out of receiving marketing emails by using the unsubscribe link contained in the email.
                    We may still send you emails about your relationship with us and your transactions, including
                    Account information and alerts, purchase confirmations, and updates to our products, services and
                    policies.</p>
                <h2>5. Tracking Technologies and Cookies</h2>
                <p>We may use cookies and other tracking technologies (including from <Link
                    to='https://policies.google.com/privacy?hl=en-US'>Google ,</Link> Facebook and LinkedIn) to keep,
                    and sometimes track, information about you on our Services. Cookies are small data files that are
                    sent to your browser from a web server and stored on your computer’s hard drive. Cookies track where
                    you travel on the Services and what you view. In doing so, a cookie may enable us to relate your use
                    of the Services to your Personal Information.</p>
                <p>We use information gathered from these tracking technologies so that we can analyze trends,
                    administer the Services, track users’ movements around the Services, and gather demographic
                    information about our user base as a whole. We may combine information we obtain through tracking
                    technologies with other Personal Information that we have collected about you in order to make our
                    Services, communications and advertisements more targeted to your interests.</p>
                <p>Most web browsers can be set to inform you when a cookie has been sent to you and provide you with
                    the opportunity to refuse that cookie. If you reject cookies, you may still use our Services, but
                    your ability to use some areas of our Services will be limited. You may also opt out of targeted
                    advertisements by visiting the <Link to='https://optout.networkadvertising.org/?c=1'>Network
                        Advertising Initiative opt out page.</Link></p>
                <p><strong>Do Not Track</strong></p>
                <p>Please note that the Services are not presently configured to respond to DNT or “do not track”
                    signals from web browsers or mobile devices. As such, we do not recognize or respond to Do Not Track
                    requests.</p>
                <h2>6. Retention and Security</h2>
                <p>We will retain your Personal Information for as long as your Account is active, as needed to provide
                    you Services, and as necessary to comply with our legal obligations, resolve disputes, and enforce
                    our agreements.</p>
                <p>We follow generally accepted standards to protect the Personal Information submitted to us, both
                    during transmission and once we receive it. For example, when you enter sensitive information (such
                    as your login credentials), we encrypt the transmission of that information using secure socket
                    layer technology (SSL). However, no method of transmission over the Internet, or method of
                    electronic storage, is 100% secure. Therefore, we cannot guarantee its absolute security.</p>
                <h2>7. California Privacy Statement</h2>
                <p>California residents have certain rights under the California Shine the Light law and the California
                    Consumer Privacy Act (“CCPA”).</p>
                <p><strong>CCPA Disclosures</strong></p>
                <p>In general, within the preceding 12 months:</p>
                <ul>
                    <li>We have collected the categories of Personal Information listed in Section 2 above.</li>
                    <li>We have collected these categories of Personal Information directly from you, when you use our
                        Services, and from third parties for the purposes described in Section 2 above.
                    </li>
                    <li>We have disclosed the following categories of Personal Information for business purposes:
                        Identifiers and contact information; professional and employment-related information; commercial
                        information; transactional information; and internet and network activity information.
                    </li>
                    <li>We have not sold your Personal Information.</li>
                </ul>
                <p><strong>CCPA Privacy Rights</strong></p>
                <p>As of January 1, 2020, certain California residents are entitled to privacy rights under the CCPA.
                    Customers who wish to exercise these rights should direct their requests to the registered member
                    who controls their Personal Information. </p>
                <ul>
                    <li>The right to know. You have the right to request to know (i) the specific pieces of Personal
                        Information we have about you; (ii) the categories of Personal Information we have collected
                        about you in the last 12 months; (iii) the categories of sources from which that Personal
                        Information was collected; (iv) the categories of your Personal Information that we sold or
                        disclosed in the last 12 months; (v) the categories of third parties to whom your Personal
                        Information was sold or disclosed in the last 12 months; and (vi) the purpose for collecting and
                        selling your Personal Information.
                    </li>
                    <li>The right to deletion. You have the right to request that we delete the Personal Information
                        that we have collected or maintain about you. We may deny your request under certain
                        circumstances, such as if we need to comply with our legal obligations or complete a transaction
                        for which your Personal Information was collected. If we deny your request for deletion, we will
                        let you know the reason why.
                    </li>
                    <li>The right to equal service. If you choose to exercise any of these rights, we will not
                        discriminate against you in anyway. If you exercise certain rights, understand that you may be
                        unable to use or access certain features of our Services.
                    </li>
                </ul>
                <p>You may exercise your right to know and your right to deletion twice a year free of charge. To
                    exercise your right to know or your right to deletion, please contact us at info@MyMedicalHub.com
                    . </p>
                <p>We will take steps to verify your identity before processing your request to know or request to
                    delete. We will not fulfill your request unless you have provided sufficient information for us to
                    verify you are the individual about whom we collected Personal Information. If you have an Account
                    with us, we will use our existing Account authentication practices to verify your identity. If you
                    do not have an Account with us, we may request additional information about you to verify your
                    identity. We will only use the Personal Information provided in the verification process to verify
                    your identity or authority to make a request and to track and document request responses, unless you
                    initially provided the information for another purpose.</p>
                <p>You may use an authorized agent to submit a request to know or a request to delete. When we verify
                    your agent’s request, we may verify both your and your agent’s identity and request a signed
                    document from you that authorizes your agent to make the request on your behalf. To protect your
                    Personal Information, we reserve the right to deny a request from an agent that does not submit
                    proof that they have been authorized by you to act on their behalf.</p>
                <p><strong>Shine the Light</strong></p>
                <p>Our California customers are also entitled to request and obtain from MyMedicalHub once per calendar
                    year information about any of your Personal Information shared with third parties for their own
                    direct marketing purposes, including the categories of information and the names and addresses of
                    those businesses with which we have shared such information. To request this information please
                    contact us at info@MyMedicalHub.com .</p>
                <h2>8. Additional Information</h2>
                <p><strong>Information for Visitors from Outside of the United States</strong></p>
                <p>We are committed to complying with this Privacy Policy and the data protection laws that apply to our
                    collection and use of your Personal Information. We are located in the United States, where the laws
                    may
                    be different and, in some cases, less protective than the laws of other countries. By providing us
                    with
                    your Personal Information and using the Services, you acknowledge that your Personal Information
                    will be
                    transferred to and processed in the United States and other countries where we and our vendors
                    operate.</p>
                <p><strong>Social Media Widgets</strong></p>
                <p>Our Services may include social media features, such as the Facebook Like button, and widgets, such
                    as the
                    “Share this” button, or interactive mini-programs that run on our websites (collectively referred to
                    as
                    “Features”). These Features may collect your IP address, which page you are visiting, and may set a
                    cookie
                    to enable the Feature to function properly. The Features are either hosted by a third party or
                    hosted
                    directly on our Services. Your interactions with these Features on a third-party site are governed
                    by the
                    privacy policy of the company providing it.</p>
                <p><strong>Links to Other Sites</strong></p>
                <p>The Services may contain links to other sites that are not owned or controlled by MyMedicalHub.
                    Please be
                    aware that we are not responsible for the privacy practices of such other sites. We encourage you to
                    be
                    aware when you leave our site and to read the privacy statements of each and every website that
                    collects
                    Personal Information. This Privacy Policy applies only to information collected by our Services</p>
                <p><strong>Children’s Privacy</strong></p>
                <p>Our Services are not directed to, and we do not knowingly collect any Personal Information from
                    children
                    under 13.</p>
                <p><strong>Changes to This Policy</strong></p>
                <p>We may update this Privacy Policy to reflect changes to our information practices. If we make any
                    material
                    changes, we will notify you by email (sent to the email address specified in your Account) or by
                    means of
                    a notice on our websites prior to or upon the change becoming effective. We encourage you to review
                    this
                    page periodically for the latest information on our privacy practices.</p>
                <h2>9. Contact Us</h2>
                <p>For help with matters not related to exercising your rights under the aforementioned privacy laws,
                    please contact us at <Link to='https://mymedicalhub.com/contact-us/'>MyMedicalHub Support.</Link>
                </p>
            </div>
            <ModalFooter>
                <button type="button" onClick={props.handler} className="btn btn-outline-secondary">Cancel</button>
                <button type="button" onClick={props.handler} className="btn btn-success">Ok</button>
            </ModalFooter>
        </Modal>
    );
};

export default PrivacyPolicy;
