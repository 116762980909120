import React,{useEffect, useState} from "react";
import wifiRed from '../../../assets/img/utils/wifiRed.png';
import wifiGreen from '../../../assets/img/utils/wifiGreen.png';

const NetworkCheck = () => {
    const [isOnline, setNetwork] = useState(window.navigator.onLine);
    const [isShowOnlineOffline, setIsShowOnlineOffline] = useState(false);

    useEffect(() => {
       window.addEventListener("offline", updateNetwork);
       window.addEventListener("online", updateNetwork);
       return () => {
          window.removeEventListener("offline", updateNetwork);
          window.removeEventListener("online", updateNetwork);
       };
    });

    const updateNetwork = () => {
        let online = window.navigator.onLine;
        let prevIsOnline = isOnline;
        if(!online || (online && !prevIsOnline)){
            setIsShowOnlineOffline(true);
        }
        setNetwork(online);

        setTimeout(() => {
            setIsShowOnlineOffline(false);
        }, 3000);
     };

    return(
        <>
            {isOnline && isShowOnlineOffline && (
                <div className='network-container'
                        role="alert">
                    <div className="mr-4">
                        <strong>Your connection is stored successfully</strong><br/>
                    </div>
                    <img src={wifiGreen} alt="wifi back" />
                </div>
                )
            }
            {!isOnline && isShowOnlineOffline && (
                <div className='network-container'
                        role="alert">
                    <div className="mr-4">
                        <strong>Looks like we lost your connection</strong><br/>
                        <span>Trying to re-connect</span>
                    </div>
                    <img src={wifiRed} alt="wifi gone" />
                </div>
                )
            }
        </>
    );
 };

 export default NetworkCheck;