import React from "react";
import {AsyncPaginate} from "react-select-async-paginate";
import {loadOptions} from "../../../utils/Utils";
import {toast} from "react-toastify";

const DropdownItem = ({ label, value, question, placeHolder, onChange, mask, checkedOption, errorMessage, error, disabled = false }) => {
    const onChangeInputText = (e) => {
        if(disabled) {
            toast.error("Report already ready can not change the response");
            return;
        }

        const answerId = e?.id;
        const answerText = e?.name;
        onChange && onChange({ question: question, answerId: answerId, answerText: answerText, willSave: true });
    }

    const getCheckedOption = (checkAnswersId) => {
        const response = question?.responses;
        let answerOption = response.filter((answer) => answer.id === checkAnswersId) || {};
        if(answerOption.length > 0){
            answerOption = answerOption[0];
        }

        if (answerOption) {
            let temp ={};
            temp['value'] = answerOption['id'];
            temp['label'] = answerOption['name'];
            return temp;
        } else {
            return ''
        }
    }

    return (
        <div className="form-group row">
            <label className="col-xl-4 col-lg-4 col-form-label heading-primary">{label}</label>
            <div className="col-lg-8 col-xl-8 dropdown-wrapper">
                <AsyncPaginate
                    menuPlacement="top"
                    isClearable={false}
                    isSearchable={true}
                    isRtl={false}
                    backspaceRemovesValue={false}
                    closeMenuOnSelect={true}
                    name="chatbotAutoComplete"
                    className="mmh-multi-select kt-p0"
                    optionClassName="needsclick"
                    value={getCheckedOption(checkedOption)}
                    loadOptions={loadOptions(value)}
                    onChange={onChangeInputText}
                    classNamePrefix="mmh"
                    isDisabled={disabled}
                />
                {errorMessage && (<div className="invalid-feedback d-block">* This field is required</div>)}
                {error && error.questionId === question.questionId && (
                    <span className="form-text text-muted kt-font-danger">* {error.message}</span>
                )}
            </div>
        </div>
    )
}

export default DropdownItem;