import React, { createContext, useContext, useState } from 'react';

const CallbackContext = createContext();

export const CallbackProvider = ({ children }) => {
    const [callbacks, setCallbacks] = useState({});

    const registerCallback = (key, callback) => {
        setCallbacks(prev => ({ ...prev, [key]: callback }));
    };

    const unregisterCallback = (key) => {
        setCallbacks(prev => {
            const { [key]: _, ...rest } = prev;
            return rest;
        });
    };

    const getCallback = key => callbacks[key];

    return (
        <CallbackContext.Provider value={{ registerCallback, unregisterCallback, getCallback }}>
            {children}
        </CallbackContext.Provider>
    );
};

export const useCallbackContext = () => useContext(CallbackContext);
