import React, {useEffect, useState, useRef, useCallback, useMemo} from "react";
// import * as poseDetection from "@tensorflow-models/pose-detection";
// import * as tf from '@tensorflow/tfjs';
import { getFullBodyInBox } from "./Services/MovementService";
import {
    // beginEstimatePosesStats,
    // endEstimatePosesStats,
    // DEFAULT_LINE_WIDTH,
    // DEFAULT_RADIUS,
    FULL_SCREEN_SKELETON_COLOR,
    // NET_MODEL
} from "../../../utils/Exercise/ExerciseUtils";
import GLOBAL from "../../../global";
import UserMediaWebcam from "./UserMediaWebcam";
import {Pose, POSE_CONNECTIONS} from "@mediapipe/pose";
import { getShoulderMidPoint, isUserFacingLeft, isUserFacingRight,
    // KEYPOINTS,
    // LEFT_FACING_EXERCISE_CODE_NAMES, RIGHT_FACING_EXERCISE_CODE_NAMES
} from "./utilities/ExerciseUtils";
import {doesUserNeedFacingLeft, doesUserNeedFacingRight} from "./utilities/Utility";
import VideoRecorder from "./Services/VideoRecorder";

const VideoPanel = ({startRecording, onStopVideoRecording, savePoses, webcamConstraints, messageOnVideo, setMessageOnVideo, totalSeconds = 0, selectedExercise, isShowCountDown, selectedExerciseState }) => {

    // const [recordedChunks, setRecordedChunks] = useState([]);
    const [count, setCount] = useState(1);

    const webcamRef = useRef(null);
    const canvasRef = useRef(null);
    // const mediaRecorderRef = useRef(null);
    // const poseDetector = useRef(null);
    // const poseModel = useRef(null);
    const ctxRef = useRef(null);
    // const canvasShapeRef = useRef(null);
    // const ctxShapeRef = useRef(null);
    // const isBodyInTheBox = useRef(false);
    const requestRef = useRef(null);
    const exerciseCodeNameRef = useRef(selectedExercise?.current);

    useEffect(() => {
        if (startRecording) {
            startCaptureHandler();
        }
    }, [startRecording])

    // useEffect(() => {
    //     if (recordedChunks && recordedChunks.length) {
    //         onStopVideoRecording(recordedChunks);
    //         setTimeout(()=>{
    //             setRecordedChunks([]);
    //         },5000);
    //     }
    // }, [recordedChunks]);

    useEffect(() => {
        exerciseCodeNameRef.current = selectedExercise?.current;
    }, [selectedExercise.current]);

    // const dataAvailableHandler = ({ data }) => {
    //     if (data.size > 0) {
    //         setRecordedChunks((prev) => prev.concat(data));
    //     }
    // };

    const startCaptureHandler = () => {
        // mediaRecorderRef.current = new MediaRecorder(webcamRef.current.srcObject, {
        //     //mimeType: "video/webm"
        //     videoMaximizeFrameRate: true
        // });
        // mediaRecorderRef.current.addEventListener(
        //     "dataavailable",
        //     dataAvailableHandler
        // );
        //
        // let duration = 5000;
        // let seconds = parseInt(totalSeconds);
        // if(seconds && seconds > 0){
        //     duration = (seconds+1) * 1000;
        // }
        //
        // mediaRecorderRef.current.onstart = () => {
        //     setTimeout(() => {
        //         if (mediaRecorderRef.current?.state === 'recording') {
        //             mediaRecorderRef.current.stop();
        //         }
        //     }, duration);
        // }
        //
        // mediaRecorderRef.current.start();

        if (webcamRef.current && webcamRef.current.srcObject) {
            VideoRecorder.init(webcamRef.current.srcObject, onStopVideoRecording, totalSeconds);
            VideoRecorder.startRecording();
        }
    };

    const drawCtx = (video) => {
        if (canvasRef.current) {
            let ctx = canvasRef.current.getContext('2d');

            const videoWidth = video.videoWidth;
            const videoHeight = video.videoHeight;

            video.width = videoWidth;
            video.height = videoHeight;

            canvasRef.current.width = videoWidth;
            canvasRef.current.height = videoHeight;

            ctx.translate(videoWidth, 0);
            ctx.scale(-1, 1);

            ctx.beginPath();
            let canvasMidWidth = canvasRef.current.width / 2;
            let canvasMidHeight = canvasRef.current.height / 2;
            if(GLOBAL.DEVICE_WIDTH < 1000) {
                if(GLOBAL.DRAW_BOX) {
                    GLOBAL.left = canvasMidWidth-(canvasMidWidth * 3 / 4);
                }
                else {
                    GLOBAL.left = 2;
                }
            }
            else{
                if(GLOBAL.DRAW_BOX) {
                    GLOBAL.left = canvasMidWidth-(canvasMidWidth/2);
                } else {
                    GLOBAL.left = 2;
                }
            }
            let right, bottom;
            if(GLOBAL.DRAW_BOX) {
                right = 2*(canvasMidWidth - GLOBAL.left);
                GLOBAL.top = canvasMidHeight-(canvasMidHeight*19/20);
                bottom = 2*(canvasMidHeight - GLOBAL.top);
            } else {
                right = videoWidth - GLOBAL.left;
                GLOBAL.top = 2;
                bottom = videoHeight - GLOBAL.top;
            }
            GLOBAL.right = right + GLOBAL.left;

            GLOBAL.bottom = bottom;
            if(GLOBAL.DRAW_BOX) {
                ctx.rect(GLOBAL.left, GLOBAL.top, right, bottom);
                ctx.lineWidth = 5;
                ctx.strokeStyle = GLOBAL.IsColorRedForFullBox ? "#E10600" : "#AAFF00";
                ctx.stroke();

                ctx.beginPath();
                ctx.moveTo(canvasMidWidth, 0);
                ctx.lineTo(canvasMidWidth, canvasRef.current.height);
                ctx.stroke();

                ctx.beginPath();
                ctx.moveTo(0, canvasMidHeight);
                ctx.lineTo(canvasRef.current.width, canvasMidHeight);
                ctx.stroke();
            }
            ctxRef.current = ctx;
        }
    };

    async function loadMediaPipe() {
        const poseModel = new Pose({
            locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`
        });

        poseModel.setOptions({
            modelComplexity: 1,
            smoothLandmarks: true,
            enableSegmentation: false,
            smoothSegmentation: true,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5
        });

        poseModel.onResults(onResults);

        function animate() {
            let videoElement = webcamRef.current;

            poseModel.send({ image: videoElement }).then(() => {
                requestRef.current = requestAnimationFrame(animate);
            }).catch(error => console.error('Pose model failed to process image:', error));

        }

        requestRef.current = requestAnimationFrame(animate);
    }

    const onResults = (results) => {
        const canvasCtx = canvasRef.current.getContext('2d');
        canvasCtx.save();
        canvasCtx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

        drawCtx(webcamRef.current)

        // Draw the segmentation mask.
        if (results.segmentationMask) {
            canvasCtx.drawImage(results.segmentationMask, 0, 0,
                canvasRef.current.width, canvasRef.current.height);
        }

        let fillColour = FULL_SCREEN_SKELETON_COLOR || "#5578EB";

        // Draw the pose landmarks and connections.
        if (results.poseLandmarks) {
            const landmarks = results.poseLandmarks.map((landmark) => ({
                x: landmark.x * canvasRef.current.width,
                y: landmark.y * canvasRef.current.height,
                visibility: landmark.visibility
            }));

            drawConnectorsCustom(canvasCtx, landmarks, POSE_CONNECTIONS,
                fillColour, 2);
            drawLandmarksCustom(canvasCtx, landmarks,
                fillColour, 2, 3);

            GLOBAL.IsColorRedForFullBox = !getFullBodyInBox(landmarks);
            GLOBAL.IS_PATIENT_IN_BOX = !GLOBAL.IsColorRedForFullBox;
            const shouldUserFaceLeft = doesUserNeedFacingLeft(selectedExercise.current);
            const shouldUserFaceRight = doesUserNeedFacingRight(selectedExercise.current);
            // if(!GLOBAL.IS_PATIENT_IN_BOX) {
            setMessageOnVideo('');
            // }

            if(shouldUserFaceLeft && !GLOBAL.IsColorRedForFullBox) {
                GLOBAL.IsColorRedForFullBox = !isUserFacingLeft(landmarks);
                if(GLOBAL.IsColorRedForFullBox && !isShowCountDown) {
                    setMessageOnVideo('Please stand with your left side facing the camera.');
                    GLOBAL.IS_PATIENT_FACING_LEFT = false;
                } else {
                    setMessageOnVideo('');
                    GLOBAL.IS_PATIENT_FACING_LEFT = true;
                }
            } else if(shouldUserFaceRight && !GLOBAL.IsColorRedForFullBox) {
                GLOBAL.IsColorRedForFullBox = !isUserFacingRight(landmarks);
                if(GLOBAL.IsColorRedForFullBox && !isShowCountDown) {
                    setMessageOnVideo('Please stand with your right side facing the camera.');
                    GLOBAL.IS_PATIENT_FACING_RIGHT = false;
                } else {
                    setMessageOnVideo('');
                    GLOBAL.IS_PATIENT_FACING_RIGHT = true;
                }

            }
            // setTimeout(()=>{
            //     savePoses(GLOBAL.IsColorRedForFullBox);
            // }, 1000);
        } else {
            GLOBAL.IsColorRedForFullBox = true;
            GLOBAL.IS_PATIENT_IN_BOX = false;
            GLOBAL.IS_PATIENT_FACING_LEFT = false;
            GLOBAL.IS_PATIENT_FACING_RIGHT = false;

            if(messageOnVideo) setMessageOnVideo('');
        }
        savePoses();
        canvasCtx.restore();
    }

    function drawConnectorsCustom(ctx, landmarks, connections, color, lineWidth) {
        ctx.strokeStyle = color;
        ctx.lineWidth = lineWidth;
        ctx.beginPath();

        connections.forEach(([startIdx, endIdx]) => {
            const start = landmarks[startIdx];
            const end = landmarks[endIdx];
            if(start?.visibility < 0.5 || end?.visibility < 0.5) return;
            if (start && end) {
                ctx.moveTo(start.x, start.y);
                ctx.lineTo(end.x, end.y);
            }
        });
        ctx.stroke();
    }

    function drawLandmarksCustom(ctx, landmarks, color, lineWidth, radius) {
        ctx.fillStyle = color;

        landmarks.forEach((landmark, index) => {
            if(landmark.visibility < 0.5) return;
            ////TODO: REMOVE BEFORE PRODUCTION
            // if(index === KEYPOINTS.NOSE.point || index === KEYPOINTS.LEFTSHOULDER.point || index === KEYPOINTS.RIGHTSHOULDER.point ||
            //     index === KEYPOINTS.RIGHTHEEL.point || index === KEYPOINTS.LEFTFOOTINDEX.point || index === KEYPOINTS.RIGHTFOOTINDEX.point) {
            //     ctx.fillStyle = 'red';
            //     ctx.strokeStyle = 'red';
            // } else {
            ctx.fillStyle = color;
            ctx.strokeStyle = color;
            // }

            const circle = new Path2D();
            circle.arc(
                landmark.x,
                landmark.y,
                radius, 0, 2 * Math.PI
            );
            ctx.stroke(circle);
        });

        const sholderMidPoint = getShoulderMidPoint(landmarks);
        const circle = new Path2D();
        circle.arc(
            sholderMidPoint.x,
            sholderMidPoint.y,
            radius, 0, 2 * Math.PI
        );
        ctx.stroke(circle);
    }


    const onUserMediaStream = async (e) => {
        GLOBAL.IS_CAMERA_READY_FOR_BIOMETRIC = true;
        await loadMediaPipe();
    }

    const onUserMediaError = (e) => {
        console.error('error', e);
        //to resolve "error DOMException: Could not start video source" occurs sometime while initiate camera
        setCount(prevState => prevState + 1);
        // setForceUpdate(true);
    }

    const webcamNewComponent = useMemo(()=> {
        return <UserMediaWebcam
            webcamRef={webcamRef}
            style={{
                height: "100%",
                width: "100%",
                objectFit: "fill",
                position: "absolute"
            }}
            mirrored={true}
            // onPlay={onVideoPlay}
            videoConstraints={webcamConstraints}
            onUserMedia={onUserMediaStream}
            onUserMediaError={onUserMediaError}
        />
        //added count to resolve "error DOMException: Could not start video source" occurs sometime while initiate camera
    },[webcamConstraints, count]);

    return (
        <>
            {webcamNewComponent}
            <canvas
                style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    objectFit: "fill",
                    zIndex: 0,
                    // transform: "scaleX(-1)"
                }
                }
                ref={canvasRef}
            />
        </>
    );
}

export default VideoPanel;