import { toast } from "react-toastify";

class VideoRecorder {
    constructor() {
        if (VideoRecorder.instance) {
            return VideoRecorder.instance;
        }

        this.stream = null;
        this.totalSeconds = -1;
        this.recordedChunks = [];
        this.mediaRecorder = null;
        this.onRecordingStop = null;

        VideoRecorder.instance = this;
    }

    init(stream, onRecordingStop, totalSeconds = -1) {
        if (!stream || !onRecordingStop) {
            toast.error("Stream and onRecordingStop callback are required");
            return;
        }

        this.stream = stream;
        this.totalSeconds = totalSeconds;
        this.onRecordingStop = onRecordingStop;
    }

    dataAvailableHandler = ({ data }) => {
        if (data.size > 0) {
            this.recordedChunks.push(data);
        }
    };

    startRecording = () => {
        if (!this.stream) {
            throw new Error("Stream is not initialized");
        }

        this.mediaRecorder = new MediaRecorder(this.stream, {
            videoMaximizeFrameRate: true
        });

        this.mediaRecorder.addEventListener(
            "dataavailable",
            this.dataAvailableHandler
        );

        this.mediaRecorder.onstop = async () => {
            await this.onRecordingStop(this.recordedChunks);
            this.clearVideoRecorder();
        };

        this.mediaRecorder.onstart = () => {
            if (this.totalSeconds && this.totalSeconds > 0) {
                const duration = (this.totalSeconds + 1) * 1000;
                setTimeout(() => {
                    if (this.mediaRecorder.state === 'recording') {
                        this.stopRecording();
                    }
                }, duration);
            }
        };

        this.mediaRecorder.start();
    };

    stopRecording = () => {
        if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
            this.mediaRecorder.stop();
        }
    };

    getChunks = () => {
        return this.recordedChunks;
    };

    clearVideoRecorder = () => {
        this.stream = null;
        this.totalSeconds = -1;
        this.recordedChunks = [];
        this.mediaRecorder = null;
        this.onRecordingStop = null;
    };
}

const instance = new VideoRecorder();

export default instance;
