import React from 'react';
import GLOBAL from "../global";
import CONSTANT from "../constants/Constant";

const initialState = {
    authenticated: false,
    userInfo: null,
    isOpenPrecallTestBot: false,
    isAudioPlayEnabled: GLOBAL.AUDIO_PLAY_ENABLED,
    testId: "",
    isFullScreenVideo: false,
    isVideoUnmute: true,
    isAudioUnmute: false,
    biometricSelectedExercise: null,
    blockedUI: false,
    selectedNextMovement: false,
    startMovement: false,
    retakeLastMovement: false,
    isVideoElementDisabled: false,
    videoRecordingPurpose: CONSTANT.VIDEO_RECORDING_PURPOSE.OTHER,
    isNextMovementSelected: false,
    exerciseGifUrl: '',
    exerciseGuideline: ''
};

const AppContext = React.createContext(initialState);

export default AppContext;
