export const initialIntakeFormState = {
    joinNowBtnConfig: {
        joinBtnText: 'JOIN NOW',
        joinBtnLoadingText: 'JOINING...',
        joiningTelemedicine: false,
        joinBtnClassName: 'btn btn-success btn-wide',
        joinBtnCallbackFnKey: '',
    },
    joinLaterBtnConfig: {
        joinLaterBtnText: 'JOIN LATER',
        joinLaterBtnLoadingText: 'JOIN LATER',
        joiningLaterTelemedicine: false,
        joinLaterBtnClassName: 'btn btn-danger btn-wide kt-ml-20',
        joinLaterBtnCallbackFnKey: '',
    },
    submitBtnConfig: {
        submitBtnText: 'Submit',
        submitBtnLoadingText: 'Submitting...',
        submitBtnDisabled: false,
        submitBtnClassName: 'btn btn-success btn-wide',
        submitBtnCallbackFnKey: '',
        showSubmitBtn: false,
    },
    showIntakeForm: false,
    showInclinicMessage: false,
    showPolicy: false,
    showTermsAndConditionModal: false,
    invitationId: '',
    waitingRoomId: '',
    bodyRegionList: [],
    invitationTypeName: '',
    isFromCalendar: false,
}