import React from "react";

const BiometricLoader = () => {
    return (
        <div className="d-flex vh-100 justify-content-center align-items-center alert-text" role="alert">
            <div className="alert-icon kt-ml-5 kt-mr-5">
                <i className="flaticon-warning kt-font-brand kt-font-xl kt-font-bold"/>
            </div>
            <div className="alert-text kt-ml-5 kt-mr-5 kt-font-xl kt-font-bold">
                <h1>Please wait, the system is preparing to take your biometric measurements. &nbsp;&nbsp;
                    <i className="fa fa-spinner fa-spin kt-font-primary"/>
                </h1>
            </div>
        </div>
    );
}

export default BiometricLoader;