class VerifyInvitationDto {
    constructor(
        EmailAddress,
        FirstName,
        LastName,
        VerifyStatus
    ) {
        this.EmailAddress = EmailAddress || '';
        this.FirstName = FirstName || '';
        this.LastName = LastName || '';
        this.VerifyStatus = VerifyStatus || 0;
    }
}

export default VerifyInvitationDto;