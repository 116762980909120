import psl from 'psl';
import { format, isValid } from "date-fns";
import { isMobile, isTablet } from "react-device-detect";
import { toast } from "react-toastify";
import CONSTANT from "../constants/Constant";
import GLOBAL from "../global";
import storageService from "./StorageService";
import examService from "../services/ExamService";
import exerciseService from "../services/ExerciseService";
import answerValidationMessagesConstant from "../services/answerValidation/answerValidationMessagesConstant";

export const playAudio = (url) => {
    return new Promise((resolve) => {
        try {
            if (!url) {
                resolve();
                return;
            }

            const audioLength = document.getElementsByTagName("audio").length;
            if (audioLength > 0) {
                const previousAudio = document.getElementsByTagName("audio")[0];
                previousAudio.pause();
                previousAudio.remove();
            }

            function removeAudioAndResolve() {
                audio.remove();
                resolve('Audio removed manually');
            }


            if (url === 'remove') {
                const audioElements = document.getElementsByTagName("audio");
                if (audioElements.length > 0) {
                    const previousAudio = audioElements[0];
                    previousAudio.pause();
                } else {
                    resolve('No audio to remove');
                }
                return;
            }

            if (!GLOBAL.AUDIO_PLAY_ENABLED) {
                resolve('audio is disabled');
                return;
            }

            const audio = document.createElement('audio');
            audio.style.height = "0px";
            audio.style.width = "0px";

            url = url + "?t=" + new Date().getTime();
            audio.setAttribute('src', url);
            document.body.append(audio);

            audio.load();
            audio.muted = false;
            audio.currentTime = 0;
            audio.autoplay = true;

            audio.addEventListener('pause', function() {
                if (audio.paused && !audio.ended) {
                    removeAudioAndResolve();
                }
            });

            audio.onended = function () {
                audio.remove(); //Remove when played.
                resolve('Audio has been played');
            };

            const playPromise = audio.play();
            if (playPromise !== undefined) {
                playPromise.then(function () {
                    // Automatic playback started!
                }).catch(function () {
                    resolve("Audio is missed");
                    // Automatic playback failed.
                    // Show a UI element to let the user manually start playback.
                });
            }

        } catch (error) {
            resolve("Audio is missed");
        }
    });
};

export const getMarkup = (text) => {
    return { __html: text };
};

export const replaceCharactersWithAsterisk = (text) => {
    return text.replace(/./g, "*");
};

export const calculateAge = (dateOfBirth) => {
    dateOfBirth = new Date(dateOfBirth);
    const diff = Date.now() - dateOfBirth.getTime();
    const ageDate = new Date(diff);

    return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const dateInPast = (date) => {
    const today = new Date();

    const userDate = date.split('/');
    const formatedDate = new Date(`${userDate[2]}-${userDate[0]}-${userDate[1]}`);

    today.setHours(0, 0, 0, 0);

    return formatedDate < today;

};

export const getSubdomain = () => {
    const parsed = psl.parse(window.location.hostname);
    return parsed && parsed.subdomain;
};

export const formatDate = (date, formatString = CONSTANT.DATE_FORMAT.MM_DD_YYYY) => {
    return format(date, formatString);
};
export const formatDatetime = (date) => {
    return format(date, CONSTANT.DATE_FORMAT.MM_DD_YYYY_HH_MM);
};

export const capitalizeFirstLetter = (value) => {
    if (!value) return '';

    return value.charAt(0).toUpperCase() + value.slice(1);
}

export const roundToNearestMinutes = (dateTime, nearest = 5) => {
    const coefficient = 1000 * 60 * nearest;
    const date = dateTime || new Date();  //or use any other date

    return new Date(Math.round(date.getTime() / coefficient) * coefficient);
};

export const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onloadend = function () {
            resolve(reader.result);
        }
        reader.readAsDataURL(file);
    });
};

export const convertToF = (celsius) => {
    return (celsius * 9 / 5 + 32).toFixed(2);
};

export const kphToMph = (speed) => {
    return (speed * 0.62).toFixed(2);
};

export const checkCameraAvailability = (userMediaError) => {
    if (userMediaError) {
        navigator.mediaDevices.getUserMedia({ video: true })
            .catch(e => {
                if (e.toString() === 'NotReadableError: Could not start video source') {
                    if (isMobile || isTablet) {
                        toast.dark('Video isn\'t working. We could not use your camera');
                    } else {
                        toast.dark('Video isn\'t working. We could not use your webcam');
                    }
                }
            });
    }
}

export const getOrdinalSuffixes = (day) => {
    let ordinalSuffixes = "th"
    if(+day === 1) ordinalSuffixes = "st"
    else if(+day === 2) ordinalSuffixes = "nd"
    else if(+day === 3) ordinalSuffixes = "rd"

    return ordinalSuffixes
}

export const formateDate = (date) => {
    date = new Date(date).toISOString().split('T')[0]
    const dateArr = date.split('-');

    return `${dateArr.length >=3 && `${dateArr[2]}${getOrdinalSuffixes(dateArr[2])} ${CONSTANT.MONTHS[parseInt(dateArr[1])-1]}`} `
}

export const getDateFormat = (date, dayFirst = false) => {
    let selectedDate = new Date(date.getTime());
    const year = selectedDate.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);

    if(dayFirst) {
        return [day, month, year].join("/");
    }
    else {
        return [month, day, year].join("/");
    }
};

export const swapDayWithMonthOfDate = (date) => {
    if(!date) return;

    const inputDateString = date;
    const parts = inputDateString.split("/");
    const day = parts[1];
    const month = parts[0];
    const year = parts[2];

    return`${day}/${month}/${year}`;
}

const range = (start, end) => {
    const ans = [];
    for (let i = start; i < end; i++) {
        ans.push(i);
    }

    return ans;
};

export const years = range(1900, new Date().getFullYear() + 1, 1);

function propComparator(propertyName) {
    return function (a, b) {
        return a[propertyName] > b[propertyName] ? 1 : -1;
    }
}

export const sortBy = (arrayList = [], propertyName) => {
    return arrayList.sort(propComparator(propertyName));
};

export const getUrlParameterByName = (name) => {
    const url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    name = name.toLowerCase();

    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url.toLowerCase());

    if (!results) return '';
    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const getUserIdentification = () => {
    let loginHeaderText;

    if (storageService.getItem('providerLogin') === 'true') {
        loginHeaderText = 'Provider';
    } else {
        loginHeaderText = 'Patient';
    }

    return loginHeaderText;
}

const checkLeapYear = (year) => {
    if (((0 === year % 4) && (0 !== year % 100)) || (0 === year % 400)) {
        return true;
    }

    return false;
}

const dayValidation = (month, day, year) => {
    const monthThirtyOne = [1, 3, 5, 7, 8, 10, 12]; // Months with 31 days
    const parsedMonth = parseInt(month);
    const parsedDay = parseInt(day);
    const parsedYear = parseInt(year);

    // February handling
    if (parsedMonth === 2) {
        const maxDay = checkLeapYear(parsedYear) ? 29 : 28;
        return parsedDay >= 1 && parsedDay <= maxDay;
    }

    // Months with 31 days
    if (monthThirtyOne.includes(parsedMonth)) {
        return parsedDay >= 1 && parsedDay <= 31;
    }

    // All other months (which have 30 days)
    return parsedDay >= 1 && parsedDay <= 30;
};

export const  dateValidator = (message) => {
    let valid = true;
    let validationMessage = '';

    valid = isValid(new Date(message));

    const dateArray = message.split("/");
    const year = dateArray[2];
    const month = dateArray[0];
    const day = dateArray[1];
    const number = /^[0-9]+$/;

    if (!valid) {
        validationMessage = answerValidationMessagesConstant.DATE.INVALID;
    } else if(!dayValidation(month, day, year)) {
        valid = false;
        validationMessage = answerValidationMessagesConstant.DATE.INVALID;
    } else if (year.length !== 4) {
        valid = false;
        validationMessage = answerValidationMessagesConstant.DATE.INVALID_YEAR_LESS_THAN_FOUR_DIGIT;
    } else if (!year.match(number)) {
        valid = false;
        validationMessage = answerValidationMessagesConstant.DATE.INVALID_YEAR_NOT_DIGIT;
    }

    return { valid: valid, message: validationMessage }
}

export const checkClientValidation = (questionId, message, chats) => {
    let valid = true;
    let validationMessage = '';

    if (questionId === CONSTANT.CHATBOT_ACCOUNT.PASSWORD) {
        if (message.length > 7 && /[a-z]/.test(message) && /[A-Z]/.test(message) && /[0-9]/.test(message)) {
            valid = true;
        } else {
            valid = false;
            validationMessage = answerValidationMessagesConstant.PASSWORD.TOO_SHORT;
        }
    }

    if (questionId === CONSTANT.CHATBOT_ACCOUNT.CONFIRM_PASSWORD) {
        chats = chats || [];
        const index = chats.findIndex(item => item.questionId === CONSTANT.CHATBOT_ACCOUNT.PASSWORD);

        if (index > -1) {
            const tempChat = chats[index];
            const password = tempChat.answers && tempChat.answers[0].name;

            if (password !== message) {
                valid = false;
                validationMessage = answerValidationMessagesConstant.CONFIRM_PASSWORD.MISMATCH;
            }
        }
    }

    if (questionId === CONSTANT.CHATBOT_ACCOUNT.EMAIL) {
        // eslint-disable-next-line
        const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!regExp.test(message)) {
            valid = false;
            validationMessage = answerValidationMessagesConstant.EMAIL.INVALID;
        }
    }

    if (questionId === CONSTANT.CHATBOT_ACCOUNT.HEIGHT) {
        const height = +message;

        if (isNaN(message)) {
            valid = false;
            validationMessage = answerValidationMessagesConstant.HEIGHT.INVALID;

        } else if (Number(height) === height && height % 1 !== 0) {
            valid = false;
            validationMessage = answerValidationMessagesConstant.HEIGHT.NOT_INTEGER
        } else if (height < 12 || height > 107) {
            valid = false;
            validationMessage = answerValidationMessagesConstant.HEIGHT.OUT_OF_RANGE;
        }
    }

    if (questionId === CONSTANT.CHATBOT_ACCOUNT.WEIGHT) {
        const weight = +message;

        if (isNaN(message)) {
            valid = false;
            validationMessage = answerValidationMessagesConstant.WEIGHT.INVALID;

        } else if (Number(weight) === weight && weight % 1 !== 0) {
            valid = false;
            validationMessage = answerValidationMessagesConstant.WEIGHT.NOT_INTEGER;
        } else if (weight < 50 || weight > 500) {
            valid = false;
            validationMessage = answerValidationMessagesConstant.WEIGHT.OUT_OF_RANGE;
        }
    }

    if (questionId === CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH) {
        // noinspection JSUnresolvedFunction

        const isDateValid = dateValidator(message);
        valid = isDateValid.valid;
        validationMessage = isDateValid.message;
        const age = calculateAge(message);
        if (valid && (age < 7 || age > 120)) {
            valid = false;
            validationMessage = answerValidationMessagesConstant.DATE_OF_BIRTH.OUT_OF_RANGE;
        }
    }

    // if(questionId === CONSTANT.CHATBOT_ACCOUNT.ACCIDENT_DIRECT_TRAUMA) {
    //     const isDateValid = dateValidator(message);
    //     valid = isDateValid.valid;
    //     validationMessage = isDateValid.message;
    //
    //     if(valid && !dateInPast(message)) {
    //         valid = false;
    //         validationMessage = 'Date must be in past';
    //     }
    // }

    return { valid: valid, message: validationMessage };
}

export const clearUrlParams = () => {
    GLOBAL.URL_PARAMS.IS_INTAKE_SIGNED = false;
    GLOBAL.URL_PARAMS.REDIRECT_TYPE = '';
    GLOBAL.URL_PARAMS.INVITATION_ID = '';
    GLOBAL.URL_PARAMS.BODY_REGION_LIST = [];
    GLOBAL.URL_PARAMS.EMAIL_ADDRESS = '';
    GLOBAL.URL_PARAMS.AUTO_LOGIN = false;
    GLOBAL.URL_PARAMS.PATIENT_ID = '';
    GLOBAL.URL_PARAMS.ROLE = 2;
};

export const getCurrentYear = () => {
    return new Date().getFullYear();
};

const getAutoCompleteOptions = (responses) => {
    return responses.map(item => {
        item['value'] = item['id'];
        item['label'] = item['name'];
        return item;
    });
};

export const loadOptions = (allOptions) => async (search, prevOptions) => {
    let options = getAutoCompleteOptions(allOptions);
    let filteredOptions;

    if (!search) {
        filteredOptions = options;
    } else {
        const searchLower = search.toLowerCase();

        filteredOptions = options.filter(({ label }) =>
            label.toLowerCase().includes(searchLower)
        );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
        prevOptions.length,
        prevOptions.length + 10
    );

    return {
        options: slicedOptions,
        hasMore
    };
};

export const deepClone = (obj) => {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (Array.isArray(obj)) {
        const arrCopy = [];
        obj.forEach((item, index) => {
            arrCopy[index] = deepClone(item);
        });
        return arrCopy;
    }

    const objCopy = {};
    Object.keys(obj).forEach(key => {
        objCopy[key] = deepClone(obj[key]);
    });
    return objCopy;
};

// export const deepClone = (obj, seen = new WeakMap()) => {
//     // Handle null, undefined, and primitive types
//     if (obj === null || typeof obj !== 'object') {
//         return obj;
//     }
//
//     // Handle circular references
//     if (seen.has(obj)) {
//         return seen.get(obj);
//     }
//
//     // Handle Date objects
//     if (obj instanceof Date) {
//         return new Date(obj.getTime());
//     }
//
//     // Handle Array objects
//     if (Array.isArray(obj)) {
//         const arrCopy = [];
//         seen.set(obj, arrCopy);
//         obj.forEach((item, index) => {
//             arrCopy[index] = deepClone(item, seen);
//         });
//         return arrCopy;
//     }
//
//     // Handle Maps
//     if (obj instanceof Map) {
//         const mapCopy = new Map();
//         seen.set(obj, mapCopy);
//         obj.forEach((value, key) => {
//             mapCopy.set(key, deepClone(value, seen));
//         });
//         return mapCopy;
//     }
//
//     // Handle Sets
//     if (obj instanceof Set) {
//         const setCopy = new Set();
//         seen.set(obj, setCopy);
//         obj.forEach(value => {
//             setCopy.add(deepClone(value, seen));
//         });
//         return setCopy;
//     }
//
//     // Handle regular objects
//     const objCopy = Object.create(Object.getPrototypeOf(obj)); // Maintain prototype
//     seen.set(obj, objCopy);
//
//     // Handle non-enumerable properties if needed
//     Object.getOwnPropertyNames(obj).forEach(key => {
//         const descriptor = Object.getOwnPropertyDescriptor(obj, key);
//         if (descriptor && descriptor.value !== undefined) {
//             descriptor.value = deepClone(descriptor.value, seen);
//         }
//         Object.defineProperty(objCopy, key, descriptor);
//     });
//
//     return objCopy;
// };

export const downloadReportOnCurrentTab = (url) => {
    const anchorTagOfDownloadReport = document.createElement('a');
    anchorTagOfDownloadReport.href = url;
    anchorTagOfDownloadReport.download = url.substr(url.lastIndexOf('/') + 1);
    document.body.append(anchorTagOfDownloadReport);
    anchorTagOfDownloadReport.click();
    document.body.removeChild(anchorTagOfDownloadReport);
};

export const getPatientPortalType = (tenantFeatures) => {
    let patientPortalType = '';
    tenantFeatures?.length > 0 && tenantFeatures.forEach((tenantItem) => {
        if (tenantItem['IsEnabledForPatient']) {
            if (tenantItem.Name === 'PatientPortalType') {
                patientPortalType = tenantItem.Value;
            }
        }
    });

    return patientPortalType;
};

export const prepareEnabledSubscribedFeatures = (features) => {
    const arrayOfFeatureEnable = Object.entries(CONSTANT.FEATURE_ENABLE);
    let permissions = {}

    for (const [key] of arrayOfFeatureEnable) {
        for (let i = 0; i < features.length; i++) {
            if (key === features[i].Name) {
                permissions = {
                    ...permissions,
                    [key]: features[i]['IsEnabled'] && CONSTANT.FEATURE_ENABLE[key]
                }
            }
        }
    }

    // permissions['EnableFysicalScore'] = true;
    // permissions['IsPostureAnalyzeEnabled'] = true;

    return permissions;
};

export const _getWaitingRoomByInvitationInfo = async (testId, invId) => {
    const payload = {
        TestId: testId,
        InvitationId: invId
    };

    const response = await examService.getWaitingRoomByInvitation(payload);
    const { success, data, error } = response;

    if (!success) {
        console.error(error);
    }

    const invitationInfo = (data && Object.keys(data)?.length > 0) && (data || '{}');
    return {
        meetingRoomUrl: invitationInfo?.['MeetingRoomUrl'] || '',
        invitationId: invitationInfo?.['InvitationId'] || '',
        isMeetingInFutureDate: invitationInfo?.['IsMeetingInFutureDate'] || false,
        waitingRoomId: invitationInfo?.['WaitingRoomId'] || ''
    }
}

export const getArrayFromObject = (object) => {
    let array = [];
    if (Object.keys(object).length > 0) {
        for (const [, value] of Object.entries(object)) {
            array.push(value);
        }
        return array;
    }
    return array;
}

export const getBodyRegionNames = (bodyRegionList) => {
    let bodyRegionName = '';

    if (bodyRegionList && bodyRegionList.length > 0) {
        for (const [, value] of Object.entries(CONSTANT.BODY_REGIONS)) {
            if (bodyRegionList.includes(value.key)) {
                if (!bodyRegionName) {
                    bodyRegionName = value.name;
                } else {
                    bodyRegionName += ',' + value.name;
                }
            }
        }
    }

    return bodyRegionName;
}

export const getAudioUrl = (key) => {
    let generalAudioURL = '';
    const generalAudio = (GLOBAL.GENERAL_AUDIOS).find((audio) => audio['KeyText'] === key);

    if (generalAudio) {
        generalAudioURL = generalAudio['AudioUrl'];
    }

    return generalAudioURL;
}

export const convertRecordedChunksToBase64 = async (recordedChunks) => {
    const chunks = recordedChunks || [];
    const blob = new Blob(chunks, { 'type': CONSTANT.VIDEO_TYPE.VIDEO_MP4 });
    // noinspection JSUnusedAssignment
    let base64data = 'noise';
    base64data = await convertToBase64(blob);

    return base64data;
}

export const storeRecordedVideo = async (patientId, aiType, base64data, selectedMovement, filename) => {//ROM API
    const storePayload = {
        patientId: patientId,//ROM API
        exerciseName: aiType,
        filename: filename,
        blobBase64: base64data
    };

    const response = await exerciseService.storeRecordedVideo(storePayload);
    if (!response.success) {
        console.error('storeRecordedVideo:', response.error);
    }

    return response;
}

export const _convertHourMinFromMin = (value) => {
    let finalValue;
    const hour = Math.floor(value / 60);
    const min = value % 60;
    if (hour > 0) {
        finalValue = `${hour}.${min}${hour > 1 || min > 0 ? ' hours' : ' hour'}`;
    } else {
        finalValue = min + ' min';
    }

    return finalValue;
}

export const _getHourMinValuesOptionsMinValues = (values) => {
    const finalValues = values?.map((item) => _convertHourMinFromMin(item));
    return finalValues?.map((item, index) => {
        return {
            value: values[index],
            label: item
        }
    });
}

export const downloadFile = (url) => {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const getBodyRegionsValueLabelObjectArray = () => {
    const bodyRegions = getArrayFromObject(CONSTANT.BODY_REGIONS);
    const options = bodyRegions?.map((item) => {
        return {
            value: item.key,
            label: item.name
        }
    });

    return options;
}

// export const storeInvitationInfoInGlobalVariable = (inv, patientId, referType = "refer") => {
//     GLOBAL.URL_PARAMS.IS_INTAKE_SIGNED = inv["IsIntakeSigned"];
//     GLOBAL.URL_PARAMS.PATIENT_ID = patientId;
//     GLOBAL.URL_PARAMS.REDIRECT_TYPE = referType;
//     GLOBAL.URL_PARAMS.INVITATION_ID = inv.InvitationId;
//     GLOBAL.URL_PARAMS.BODY_REGION_LIST = inv.BodyRegionList;
//     GLOBAL.URL_PARAMS.EMAIL_ADDRESS = inv.EmailAddress;
//     GLOBAL.URL_PARAMS.REFER_TYPE = inv["RedirectTypeText"];
//     GLOBAL.URL_PARAMS.IS_FOLLOWUP = inv["IsFollowup"];
//     GLOBAL.URL_PARAMS.TEST_ID = inv.TestId;
// }

export const isEmptyObject = (obj) => {
    if(!obj) return true;

    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export const storeUserInfoToLocalStorageFromGlobalConstant = () => {
    localStorage.removeItem("userInfo");
    localStorage.setItem("userInfo", JSON.stringify(GLOBAL.USER_INFO));
}

export function convertRgbToRgba(rgbString, alpha = 0.2) {
    try {
        // Extract the RGB values from the string using a regular expression
        const rgbValues = rgbString.match(/(\d+), (\d+), (\d+)/);

        if (!rgbValues) {
            console.error(rgbString)
            throw new Error('Invalid RGB string provided.');
        }

        // Construct the RGBA string using the extracted RGB values and the provided alpha value
        const rgbaString = `rgb(${rgbValues[1]}, ${rgbValues[2]}, ${rgbValues[3]}, ${alpha})`;

        return rgbaString;
    }
    catch (e) {
        throw new Error(`An error occurred while converting the RGB string to an RGBA string: ${e.message}`);
    }
}

export function makeSerializable(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export const getAssessmentCompletion = (responseData) => {
    let groups = [];
    for (const [, value] of Object.entries(CONSTANT.ASSESSMENT_GROUP)) {
        let tempGroup = {};
        if(value.key !== CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key) {
            let isCurrentGroupCompleted = false;
            if(value.key === CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key) {
                isCurrentGroupCompleted = responseData?.["DemographicsCompleted"] || false
            } else if(value.key === CONSTANT.ASSESSMENT_GROUP.ADL.key) {
                isCurrentGroupCompleted = responseData?.["ADLCompleted"] || false
            } else if(value.key === CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.key) {
                isCurrentGroupCompleted = responseData?.["MedicalHistoryCompleted"] || false
            } else if(value.key === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key) {
                isCurrentGroupCompleted = responseData?.["BiometricCompleted"] || false
            } else if(value.key === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) {
                isCurrentGroupCompleted = responseData?.["ChiefComplaintsCompleted"] || false
            }

            tempGroup = {
                ...value,
                groupCompleted: isCurrentGroupCompleted
            }
            groups.push(tempGroup);
        }
    }

    return groups;
}

export const shouldCheckboxOptionDisabled = (singleSelectAnswerId, answerList, answerId) => {
    if (singleSelectAnswerId) {
        if (answerList.length > 0) {
            if (answerList.includes(singleSelectAnswerId) && answerId !== singleSelectAnswerId) {
                return true;
            } else if (!answerList.includes(singleSelectAnswerId) && answerId === singleSelectAnswerId) {
                return true;
            }
        }
    }
    return false;
}

export const truncateTime = (date) => {
    // Remove the seconds and milliseconds, ensuring it remains in UTC
    date.setUTCSeconds(0, 0);
    return date;
}

export const ensureUTC = (dateString) => {
    // Enforce a standardized UTC format, ensuring compatibility across browsers
    if (!dateString.toLowerCase().endsWith('z')) {
        return dateString + 'Z';
    }
    return dateString;
}

export function formatDateTimeIntoISO8601(dateStr, timeStr) {
    // Combine date and time strings
    const dateTimeStr = `${dateStr} ${timeStr}`;

    // Parse the combined string into a Date object
    const months = {
        "January": 0, "February": 1, "March": 2, "April": 3, "May": 4, "June": 5,
        "July": 6, "August": 7, "September": 8, "October": 9, "November": 10, "December": 11
    };
    const parts = dateTimeStr.match(/(\w+) (\d+), (\d+) (\d+):(\d+) ([AP]M)/);
    const year = parseInt(parts[3], 10);
    const month = months[parts[1]];
    const day = parseInt(parts[2], 10);
    let hours = parseInt(parts[4], 10) + (parts[6] === "PM" && parts[4] !== "12" ? 12 : 0);
    if (parts[6] === "AM" && parts[4] === "12") hours = 0; // Convert 12 AM to 00
    const minutes = parseInt(parts[5], 10);

    // Create a Date object
    const date = new Date(year, month, day, hours, minutes);

    // Format to YYYY-MM-DDTHH:MM:SS
    const pad = (num) => num.toString().padStart(2, '0');
    const formattedDate = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;

    return formattedDate;
}

export function formatDateToMMDDYYYY(dateInput) {
    const date = new Date(dateInput);

    // Check if the date is valid
    if (isNaN(date)) {
        throw new Error("Invalid date format");
    }

    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
}