import notificationService from '../../../../services/NotificationService'
import examService from "../../../../services/ExamService";

export const getNotificationInformation = async (payload) => {
    const { data, success, error } =  await notificationService.getNotification(payload)

    if(!success) {
        return;
    }

    return data instanceof Array ? data: [];
}

export const createAssessment = async (payload) => {
    const responseAssessment = await examService.createAssessment(payload);
    const { success } = responseAssessment;

    if (!success) {
        return '';
    }

    const assessment = responseAssessment?.data || '{}';
    return assessment?.TestId || ''
}