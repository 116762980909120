import axios from './Axios';
import { error, success } from "./ResponseHandler";
import GLOBAL from "../global";
import CONSTANT from "../constants/Constant";

const RESPONSE_TYPE = { responseType: 'text' };
const { REACT_APP_BASE_API_URL } = process.env;
const GET_APPOINTMENTS_URL = REACT_APP_BASE_API_URL + `/api/${CONSTANT.CONTROLLER.EVENT_SCHEDULER_CONTROLLER}/GetAppointments`;

class EventSchedulerService {
    async getAppointments(payload) {
        try {
            const response = await axios.post(GET_APPOINTMENTS_URL, payload, RESPONSE_TYPE);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }
}

export default new EventSchedulerService();
