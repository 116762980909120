import React from 'react';
import throttle from "lodash/throttle";

import refreshBtn from '../../assets/img/mmh-table/refresh.svg'

const Refresh = (props) => {
    const { buttonTitle, refreshFunction } = props
    return (
        <button className="btn float-right mt-4" onClick={throttle(refreshFunction, 500)}>
            {/*<i className="flaticon-refresh"/>*/}
            {/*{buttonTitle || "Refresh"}*/}
            <img src={refreshBtn} alt="refresh" />
        </button>
    )
};

export default Refresh