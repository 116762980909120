import React from 'react';
import AnimatedLogo from '../../../assets/img/logo/logo-gray.gif';
import './AppLoading.scss';

const AppLoading = () => {
    return (
        <div className="app-loading">
            <h2 className="kt-mb-25">Welcome to MyMedicalHUB</h2>
            <img src={AnimatedLogo} className="kt-mt-25" alt="My pain log logo" height="140"/>
        </div>
    )
};

export default AppLoading;
