import './MyCalendar.scss'

import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { startOfWeek, endOfWeek } from "date-fns";
import { Modal, ModalHeader } from "reactstrap";

import {formatDateTimeIntoISO8601, getDateFormat} from "../../utils/Utils";
import eventSchedulerService from "../../services/EventSchedulerService";
import AppointmentEventDetails from "./AppointmentEventDetails";
import {timeConverterComponent} from "../dashboard/notification/business/services";

const MyCalenderDetails = () => {
    const today = new Date();
    today.setDate(today.getDate() - 15);
    const startOfWeek1 = startOfWeek(today);
    const endOfWeek1 = endOfWeek(new Date());

    const [events, setEvents] = useState([]);
    const [eventsRawData, setEventsRawData] = useState([]);
    const subscription = useRef(true);
    const [openEventDetailsModal, setOpenEventDetailsModal] = useState(false);
    const [selectedAppointmentDetails, setSelectedAppointmentDetails] = useState({});

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchEvents();

        return () => {
            subscription.current = false;
        };

        // eslint-disable-next-line
    }, []);

    const fetchEvents = async () => {
        const payload = {
            "From": getDateFormat(startOfWeek1),
            "To": getDateFormat(endOfWeek1)
        };

        const response = await eventSchedulerService.getAppointments(payload);
        const { data, success, error } = response;

        if (!success) {
            console.error(error);
            return;
        }

        if (subscription.current) {
            const tempData = data || '[]';
            const tempArray = [];
            setEventsRawData(tempData);

            for (const ev of tempData) {
                const { date, startTime, endTime} = timeConverterComponent(ev?.MeetingDateTxt || "");
                let startDateTime = formatDateTimeIntoISO8601(date, startTime);
                let endDateTime = formatDateTimeIntoISO8601(date, endTime);
                const calEv = {
                    id: ev.Id,
                    title: ev['Title'],
                    meetingRoomUrl: ev['MeetingRoomUrl'],
                    providerName: ev['ProviderName'],
                    providerEmail: ev['ProviderEmail'],
                    start: startDateTime,
                    end: endDateTime,
                    className: "fc-event-light fc-event-solid-primary",
                    data: ev
                };
                tempArray.push(calEv);
            }

            setEvents(tempArray);
        }
    };

    const onClickEvent = (eventInfo) => {
        setSelectedAppointmentDetails(eventInfo);
        const eventRawData = eventsRawData.filter((item) => item.Id === eventInfo.event.id);

        const appointmentDetails = {
            id: eventInfo.event.id,
            MeetingDateTxt: eventInfo.event.extendedProps.data.MeetingDateTxt,
            meetingTitle: eventInfo.event.title,
            providerName: eventInfo.event.extendedProps.providerName,
            providerEmail: eventInfo.event.extendedProps.providerEmail,
            meetingRoomUrl: eventInfo.event.extendedProps.meetingRoomUrl,
            startTime: eventInfo.event.start,
            endTime: eventInfo.event.end,
            meetingDate: eventInfo.event.extendedProps.data.MeetingDate,
            data: eventRawData && eventRawData.length > 0 && eventRawData[0]
        }

        setSelectedAppointmentDetails(appointmentDetails);
        toggleEventDetailsModal();
    }

    const toggleEventDetailsModal = () => {
        setOpenEventDetailsModal(!openEventDetailsModal);
    }

    const renderEventContent = (eventInfo) => {
        return (
            <>
                <div className="fc-daygrid-event-dot"/>
                <div className="cursor-pointer" onClick={() => onClickEvent(eventInfo)}>
                    <div className="fc-event-time">{eventInfo.timeText}</div>
                    <div className="fc-event-title fc-event-title-truncate">{eventInfo.event.title}</div>
                </div>
            </>
        )
    };
    return (
        <>
            <div className="kt-portlet">
                <div className="kt-portlet__body">
                    <FullCalendar
                        eventClassNames="fc-unthemed"
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                        }}
                        initialView='dayGridMonth'
                        height={700}
                        contentHeight={780}
                        aspectRatio={3}
                        nowIndicator={true}
                        editable={false}
                        selectable={false}
                        selectMirror={false}
                        dayMaxEvents={2}
                        weekends={true}
                        events={events}
                        eventContent={renderEventContent}
                    />
                </div>
            </div>
            {openEventDetailsModal && (
                <Modal
                    isOpen={openEventDetailsModal}
                    backdrop={'static'}
                    className="modal-md report">
                    <ModalHeader toggle={toggleEventDetailsModal}>
                        Appointment Details &nbsp;&nbsp;
                    </ModalHeader>
                    <AppointmentEventDetails appointmentDetails={selectedAppointmentDetails}
                                             close={toggleEventDetailsModal}/>
                </Modal>
            )}
        </>
    )
}

export default MyCalenderDetails;
