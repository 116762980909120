class VasQuestion {
    constructor(answer = {}) {
        this.color = answer?.Color || ''
        this.painValue = answer?.PainValue || ''
        this.side = answer.side || 1
        this.name = answer.name || ""
        this.bodyLocation = answer.bodyLocation
    }
}

export default VasQuestion;