import React, { useCallback, useState } from "react";
import { Lightbox } from "react-modal-image";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { format } from "date-fns";

import personLogo from "../../assets/img/users/user-base64/default";
import CONSTANT from "../../constants/Constant";
import ExerciseTrackingForm from "./ExerciseTrackingForm";
import ExercisesPlanningForm from "./ExercisesPlanningForm";
import VideoModal from "../shared/VideoModal";
import ExercisesTrackingDetails from "./ExercisesTrackingDetails";

const ExerciseDetails = (props) => {
    const { exercise, getMyExercises } = props;
    const [openExerciseTrackingFormModal, setOpenExerciseTrackingFormModal] = useState(false);
    const [openViewTrackingDetailsModal, setOpenViewTrackingDetailsModal] = useState(false)
    const [showExerciseImage, setShowExerciseImage] = useState(false);
    const [selectedExerciseImage, setSelectedExerciseImage] = useState('');
    const [openVideoTutorial, setOpenVideoTutorial] = useState(false);
    const [videoTutorialUrl, setVideoTutorialUrl] = useState('');
    const [openExercisePlanningFormModal, setOpenExercisePlanningFormModal] = useState(false);

    const toggleExerciseTrackingFormModal = () => {
        if (exercise.ExercisePlan.StartDate && exercise.ExercisePlan.EndDate) {
            setOpenExerciseTrackingFormModal(!openExerciseTrackingFormModal);
        } else {
            toggleExercisePlanningFormModal();
        }
    }

    const toggleExercisePlanningFormModal = () => {
        setOpenExercisePlanningFormModal(!openExercisePlanningFormModal);
    }

    const toggleViewTrackingDetails = () => {
        setOpenViewTrackingDetailsModal(!openViewTrackingDetailsModal);
    }

    const onSaveExerciseTracking = async () => {
        toggleExerciseTrackingFormModal()
        await getMyExercises();
    }

    const onClickExerciseImage = (exerciseImage) => {
        setSelectedExerciseImage(exerciseImage);
        toggleExerciseImage();
    }

    const toggleExerciseImage = () => {
        setShowExerciseImage(!showExerciseImage);
    }

    const toggleVideoTutorialModal = () => {
        setOpenVideoTutorial(!openVideoTutorial);
    }

    const onclickOpenTutorial = (tutorialUrl) => {
        setVideoTutorialUrl(tutorialUrl)
        toggleVideoTutorialModal();
    }

    const onSaveExercisePlan = useCallback(async () => {
        toggleExercisePlanningFormModal()
        await getMyExercises();
        // eslint-disable-next-line react-hooks/exhaustive-deps,
    }, [openExercisePlanningFormModal]);

    // const isExerciseCompleted = () => {
    //     return exercise.Status === CONSTANT.STATUS.COMPLETED;
    // }

    // const getBadgeProgressColor = () => {
    //     return isExerciseCompleted() ? 'kt-badge--success' : 'kt-badge--warning'
    // }
    //
    // const getBgProgressColor = () => {
    //     return isExerciseCompleted() ? 'kt-bg-success' : 'kt-bg-warning'
    // }
    //
    // const getProgressWidth = () => {
    //     return isExerciseCompleted() ? 'w-100' : 'w-50'
    // }

    return (
        <>
            <div className="kt-portlet w-49-percent flex-grow-0">
                <div className="kt-portlet__body">
                    <div className="kt-widget kt-widget--user-profile-3">
                        <div className="kt-widget__top">
                            <div onClick={() => onClickExerciseImage(exercise?.ExerciseImageUrl || personLogo)}
                                 className="kt-widget__media kt-hidden- cursor-pointer">
                                <img src={exercise?.ExerciseImageUrl || personLogo} alt="exercise"/>
                            </div>
                            <div className="kt-widget__content">
                                <div className="kt-widget__head">
                                    <label className="kt-widget__username">
                                        {exercise?.ExerciseName}
                                    </label>
                                    <div className="kt-widget__action">
                                        <button
                                            type="button"
                                            className="btn btn-brand"
                                            disabled={!exercise['LPTVideoUrl']}
                                            onClick={() => onclickOpenTutorial(exercise['LPTVideoUrl'])}>Tutorial
                                        </button>
                                    </div>
                                </div>
                                <div className="kt-widget__subhead">
                                    <span className='kt-mr-15'>{exercise?.TestId}</span>
                                    {exercise?.ProviderName && (
                                        <span>
                                            <i className="flaticon2-calendar-3"/>
                                            {exercise.ProviderName}
                                        </span>
                                    )}
                                </div>
                                <div className="kt-widget__info flex-row justify-content-between">
                                    {/*<div className="kt-widget__progress flex-column align-items-start w-49">*/}
                                    {/*    <div className="kt-widget__text kt-mb-10">*/}
                                    {/*    <span*/}
                                    {/*        className={`kt-badge ${getBadgeProgressColor()} kt-badge--md kt-badge--inline`}>*/}
                                    {/*        {exercise?.Status}*/}
                                    {/*    </span>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="progress h-5px w-100">*/}
                                    {/*        <div*/}
                                    {/*            className={`progress-bar ${getBgProgressColor()} ${getProgressWidth()}`}*/}
                                    {/*            role="progressbar"/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className='d-flex flex-row justify-content-end w-49'>
                                        <div className="d-flex flex-column kt-mr-10">
                                            <span className="kt-font-primary">Start Date</span>
                                            <span
                                                className="kt-badge kt-badge--unified-primary kt-badge--lg kt-badge--rounded kt-badge--bold w-auto kt-padding-5">
                                                    {exercise.ExercisePlan.StartDate && format(new Date(exercise.ExercisePlan.StartDate || null), CONSTANT.DATE_FORMAT.MMM_DD_YYYY)}
                                            </span>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <span className="kt-font-primary">End Date</span>
                                            <span
                                                className="kt-badge kt-badge--unified-danger kt-badge--lg kt-badge--rounded kt-badge--bold w-auto kt-padding-5">
                                                    {exercise.ExercisePlan.EndDate && format(new Date(exercise.ExercisePlan.EndDate || null), CONSTANT.DATE_FORMAT.MMM_DD_YYYY)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-widget__bottom kt-mt-5">
                            <div className="kt-widget__item kt-padding-t-10">
                                <div className="kt-widget__icon kt-mr-5">
                                    <i className="flaticon2-notepad kt-font-primary"/>
                                </div>
                                <span className='kt-font-primary'>Assigned</span>
                            </div>
                            <div className="kt-widget__item kt-padding-t-10">
                                <div className="kt-widget__details text-center">
                                    <span className="kt-widget__title kt-font-primary">Reps</span>
                                    <span>{exercise?.AssignReps}</span>
                                </div>
                            </div>
                            <div className="kt-widget__item kt-padding-t-10">
                                <div className="kt-widget__details text-center">
                                    <span className="kt-widget__title kt-font-primary">Sets</span>
                                    <span>{exercise?.AssignSets}</span>
                                </div>
                            </div>
                            <div className="kt-widget__item kt-padding-t-10">
                                <div className="kt-widget__details text-center">
                                    <span className="kt-widget__title kt-font-primary">HoldInSeconds</span>
                                    <span>{exercise?.HoldInSeconds}</span>
                                </div>
                            </div>
                            {/*<div className="kt-widget__item kt-padding-t-10">*/}
                            {/*    <div className="kt-widget__details text-center">*/}
                            {/*        <span className="kt-widget__title kt-font-primary">Frequency</span>*/}
                            {/*        <span>{exercise?.FrequencyName}</span>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="kt-widget__bottom kt-mt-5">
                            <div className="kt-widget__item kt-padding-t-10">
                                <div className="kt-widget__icon kt-mr-5">
                                    {/*<i className="fa fa-spinner fa-spin"/>*/}
                                    <i className="fa fa-check-circle kt-font-success"/>
                                </div>
                                <span className='kt-font-success'>{+exercise?.AssignSets === +exercise?.CompletedSets ? `Completed` : `In Progress`}</span>
                            </div>
                            <div className="kt-widget__item kt-padding-t-10">
                                <div className="kt-widget__details text-center">
                                    <span className="kt-widget__title kt-font-primary kt-font-primary">Reps</span>
                                    <span>{exercise?.CompletedSets}</span>
                                </div>
                            </div>
                            <div className="kt-widget__item kt-padding-t-10">
                                <div className="kt-widget__details text-center">
                                    <span className="kt-widget__title kt-font-primary">Sets</span>
                                    <span>{exercise?.CompletedSets}</span>
                                </div>
                            </div>
                            <div className="kt-widget__item kt-padding-t-10">
                                <div className="kt-widget__details text-center">
                                    <span className="kt-widget__title kt-font-primary">Wrong Count</span>
                                    <span>{exercise?.['TotalNoOfWrongCount'] || 0}</span>
                                </div>
                            </div>
                        </div>
                        <div className="kt-widget__bottom kt-mt-5">
                            <div className="kt-widget__item kt-padding-t-10">
                                <button
                                    className={`btn btn-primary kt-mr-10 ${+exercise?.AssignSets === +exercise?.CompletedSets ? 'd-none': ''}`}
                                    disabled={exercise.Status === CONSTANT.STATUS.COMPLETED}
                                    onClick={toggleExerciseTrackingFormModal}>
                                    Start Tracking
                                </button>
                                <button
                                    className='btn btn-primary'
                                    onClick={toggleViewTrackingDetails}
                                    disabled={+exercise.CompletedReps <= 0}>
                                    View Tracking Details
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={openExerciseTrackingFormModal}
                backdrop={'static'}
                className="modal-md">
                <ModalHeader toggle={toggleExerciseTrackingFormModal}>
                    Exercise Tracking &nbsp;&nbsp;
                </ModalHeader>
                <ModalBody>
                    <ExerciseTrackingForm exercise={exercise} onSave={onSaveExerciseTracking}/>
                </ModalBody>
            </Modal>
            <Modal
                isOpen={openExercisePlanningFormModal}
                backdrop={'static'}
                className="modal-md">
                <ModalHeader toggle={toggleExercisePlanningFormModal}>
                    Exercise Plan &nbsp;&nbsp;
                </ModalHeader>
                <ModalBody>
                    <ExercisesPlanningForm exercise={exercise} onSave={onSaveExercisePlan}/>
                </ModalBody>
            </Modal>
            <VideoModal
                visible={openVideoTutorial}
                url={videoTutorialUrl}
                closeModal={toggleVideoTutorialModal}
                headerTitle='Video Tutorial'/>
            <Modal
                isOpen={openViewTrackingDetailsModal}
                backdrop={'static'}
                className="modal-xl">
                <ModalHeader toggle={toggleViewTrackingDetails}>
                    View tracking details &nbsp;&nbsp;
                </ModalHeader>
                <ModalBody>
                    <ExercisesTrackingDetails exercise={exercise}/>
                </ModalBody>
            </Modal>
            {showExerciseImage && selectedExerciseImage && (
                <Lightbox
                    medium={selectedExerciseImage}
                    large={selectedExerciseImage}
                    alt="Exercise Image"
                    hideDownload={true}
                    onClose={toggleExerciseImage}
                />
            )}
        </>
    )
};

export default ExerciseDetails;
