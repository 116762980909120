import React, { useEffect, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import GLOBAL from "../../../global";

import subscriptionService from "../../../services/SubscriptionService";
import Address from "../../../models/Address";
import AddAddress from "./AddAddress";

const BillingAddress = () => {

    const [openAddAddressModal, setOpenAddAddressModal] = useState(false);
    const patientId = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['ContactId']) || '';
    const [addresses, setAddresses] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            await getBillingAddresses();
        };

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps,
    }, [])

    const getBillingAddresses = async () => {
        const payload = {
            PatientId: patientId,
        };
        const response = await subscriptionService.getBillingAddress(payload);
        const { data, success, error } = response;

        if (!success) {
            console.error(error);
            return;
        }

        const addresses = [];
        data.forEach((item) => {
            const address = new Address(item);
            addresses.push(address);
        })

        console.log({ addresses })
        setAddresses(addresses);
    }

    const getBotWidthStyle = () => {
        if (isMobile || isTablet) {
            return 'w-100';
        } else {
            return 'w-49 kt-mr-10';
        }
    };

    const toggleAddAddressModal = () => {
        setOpenAddAddressModal(!openAddAddressModal);
    }

    return (
        <>
            <div className="kt-portlet kt-portlet--height-fluid">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            Billing Address
                        </h3>
                    </div>
                </div>
                <div className="kt-portlet__body kt-portlet__body--fluid">
                    <div className="kt-widget5 w-100 d-flex flex-wrap">
                        {addresses && addresses.length > 0 && (
                            addresses.map((address, index) => {
                                return (
                                    <div key={index} className={`kt-widget5__item flex-column kt-padding-20 ${getBotWidthStyle()}`}
                                         style={{ border: '1px dashed #e4e6ef', borderRadius: 5 }}>
                                        <div className="w-100">
                                            <h5>Address {index}
                                                <span
                                                    className="kt-badge kt-badge--success kt-badge--md kt-badge--inline kt-ml-5 kt-font-transform-c"> {address.Status}</span>
                                            </h5>
                                        </div>
                                        <div className="d-flex w-100 flex-row">
                                            <div className="kt-widget5__content kt-mr-25">
                                                <div className="kt-widget5__section">
                                                    <p className="kt-widget5__desc text-muted">
                                                        {address.Address}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="kt-widget5__content">
                                                <button className="btn btn-secondary btn-sm kt-mr-5"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#kt_modal_upgrade_plan">Edit
                                                </button>
                                                <button className="btn btn-secondary btn-sm" data-bs-toggle="modal"
                                                        data-bs-target="#kt_modal_upgrade_plan">Delete
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        )}
                        <div className={`kt-widget5__item flex-column kt-padding-20 bg-secondary ${getBotWidthStyle()}`}
                             style={{ border: '1px dashed #e4e6ef', borderRadius: 5 }}>
                            <div className="d-flex w-100 flex-row">
                                <div className="kt-widget5__content kt-mr-25">
                                    <div className="kt-widget5__section">
                                        <h5>This is a very important note!</h5>
                                        <p className="kt-widget5__desc ">
                                            Writing headlines for blog posts is much science and probably cool audience
                                        </p>
                                    </div>
                                </div>
                                <div className="kt-widget5__content">
                                    <button className="btn btn-success btn-sm kt-mr-5"
                                            data-bs-toggle="modal" onClick={toggleAddAddressModal}
                                            data-bs-target="#kt_modal_upgrade_plan">New Address
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openAddAddressModal && (
                <Modal isOpen={openAddAddressModal} className="modal-md">
                    <ModalHeader toggle={toggleAddAddressModal}>
                        Add New Address
                    </ModalHeader>
                    <ModalBody>
                        <AddAddress/>
                    </ModalBody>
                </Modal>
            )}
        </>
    )
}

export default BillingAddress;