import React from "react";
import CONSTANT from "../../../../constants/Constant";

const RadioBtnComponent = ({className,type,key,name,value,checked,onClick,title,painQuestion, disabled = false}) => {
    return(
        <>
            <button
                className={className + `${checked ? ' selected-option' : ""}`}
                key={key}
                onClick={() => onClick(painQuestion, value)}
                disabled={disabled}
            >
                {(type === CONSTANT.ELEMENT_TYPES.CHECKBOX && checked) && <i className="fa fa-check mr-2"/>}
            {title}
        </button>
        </>
    )
}

export default RadioBtnComponent;