import { useRef, useState } from "react";
import CONSTANT from "../../constants/Constant";
import { toast } from "react-toastify";

const useEditAssessmentService = (testId, setRedirectToMyAssessments, _updateAnswer, allAnswers, showCCQuestions) => {
    const [isSavingNoCC, setIsSavingNoCC] = useState(false);

    const doesHaveOswestryQuestion = (questions) => {
        let doesHaveOswestryQuestion = false;

        questions.forEach((question) => {
            if(question?.typeId === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key) {
                doesHaveOswestryQuestion = true;
            }
        });

        return doesHaveOswestryQuestion;
    }

    const doesCCQuestionAnswered = (ccQuestionRef) => {
        let isCCQuestionAnswered = false;
        if(!ccQuestionRef.current) return false;
        let ccQuestionAnswerIndex = -1;
        allAnswers.map((answer, index) => {
            if(answer?.QuestionId === ccQuestionRef.current?.QuestionId) {
                ccQuestionAnswerIndex = index;
            }
        })

        if(ccQuestionAnswerIndex > -1) {
            let ccQuestionAnswer = allAnswers[ccQuestionAnswerIndex];
            if(ccQuestionAnswer?.AnswerId) {
                isCCQuestionAnswered = true;
            }
        }

        return isCCQuestionAnswered;
    }

    const getNoCCQuestion = (responses) => {
        let noCCQuestion = null;
        responses.forEach((response) => {
            if(response?.name?.toString()?.toLowerCase().includes("no")) {
                noCCQuestion = response;
            }
        });

        return noCCQuestion;
    }

    const handleShouldShowCCQuestionConfirmDialog = (ccQuestionRef, currentGroupId) => {
        const responses = ccQuestionRef.current?.responses;
        const noCCQuestion = getNoCCQuestion(responses);
        if(!noCCQuestion) {
            toast.error('No CC question not found');
            return;
        }

        if(!doesCCQuestionAnswered(ccQuestionRef) && currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) {
            showCCQuestions && showCCQuestions(false, true);
        }
        else {
            setRedirectToMyAssessments(true);
        }
    }

    return {
        isSavingNoCC,
        setIsSavingNoCC,
        handleShouldShowCCQuestionConfirmDialog,
        doesHaveOswestryQuestion,
        doesCCQuestionAnswered,
        getNoCCQuestion
    }
}

export default useEditAssessmentService;