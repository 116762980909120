import CONSTANT from "../../../constants/Constant";
import Assessment from "../../../models/Assessment";
import GLOBAL from "../../../global";
import examService from "../../../services/ExamService";
import ExamService from "../../../services/ExamService";
import { deepClone } from "../../../utils/Utils";
import { childQuestionIdsAlreadyInChats, getChildQuestionIds, getChildQuestionIdsNotInSelectedResponse } from "./ChatUtils";
import { sliceChatAtIndex } from "../../../features/chat/chatbotSlice";

export const getButtonMarginRightStyle = (chat) => {
    if(chat?.responses.length < 4) {
        return ' mr-5'
    } else {
        return ' mr-2';
    }
}

//Only used in ButtonQuestionsAnswers.jsx
export const getButtonMinWidth = (chat) => {
    // if(chat?.responses.length >= 10) return ' xs-margin-padding '
    if(chat?.responses.length >= 7) return ' sm-margin-padding '

}

export const getButtonDisplayStyle = (chat) => {
    if(chat?.responses.length >= 6 ) {
        return 'd-flex flex-wrap'
    } else if(chat?.responses.length >= 4)
        return 'd-flex justify-content-between flex-wrap';
    else return '';
}

export const defaultColor = (color) => {
    if (!color) {
        return '#3BA7E2';
    } else {
        return color;
    }
};

export const painActiveColor = (chat, name) => {
    if (chat.answers
        && chat.answers.length > 0
        && chat.answers[0].name === name
        && chat.mode) {
        return "true";
    } else {
        return "false";
    }
};

export const getImageWithButton = (chat, item) => {
    if (chat.intent === CONSTANT.CHATBOT_INTENT.PROVIDER || item.icon) {
        return 'btn-image kt-padding-0';
    } else {
        return '';
    }
};

export const getMarginStyle = (chat) => {
    if (chat.intent === CONSTANT.CHATBOT_INTENT.PROVIDER) {
        return "kt-mr-10";
    } else {
        return "";
    }
};

export const getBodyRegion = (chats) => {
    if(!chats || chats.length <= 0) {
        return false;
    };


    const index = chats.findIndex(item => item.intent === CONSTANT.CHATBOT_INTENT.BODY_REGION);

    if (index > -1) {
        const selectedBodyRegionChat = chats[index];
        return (selectedBodyRegionChat.answers && selectedBodyRegionChat.answers.length > 0) ?
            selectedBodyRegionChat.answers.map(item => item.name).join(',') : false;
    }

    return false;
};

export const getInputType = (chat) => {
    if (chat.questionId === CONSTANT.CHATBOT_ACCOUNT.PASSWORD ||
        chat.questionId === CONSTANT.CHATBOT_ACCOUNT.CONFIRM_PASSWORD) {
        return "password";
    } else {
        return "text";
    }
};

export const getWillShowPrevQuestion = (chat) => {
    if(chat?.botName === CONSTANT.CHAT_BOTS.PAIN_BOT && chat?.responseType === CONSTANT.ELEMENT_TYPES.BODYPART) return false;//chief complain
    else if(chat?.botName === CONSTANT.CHAT_BOTS.PAIN_BOT && chat?.questionId === 100010) return false;//question "Do you have any other areas of pain today?"
    return true;
}

export const isAlreadyAnsweredCCOrBiometricMovementQuestion = (chat) => {
    //10000870 => CC
    //10000017 => "Now let's take your biometric measurements. You’ll need to remain still for some and move to specific positions for others. We’ll walk you through each measurement, one at a time. Are you ready?"
    //10000674 => Now let's take your posture measurements. You’ll need to remain still for some and move to specific positions for others. We’ll walk you through each measurement, one at a time. Are you ready?
    const questionIds = [10000870, 10000674, 10000017];
    // return chat?.intent === CONSTANT.CHATBOT_INTENT.CHIEF_COMPLAINTS &&
    return chat?.answers[0]?.referenceId !== 2 && questionIds.includes(chat?.questionId);
}

export const getPreviousEditableChatInd = (chats, chatInd, isLeftArrowClicked = false, currentChatInd = 0) => {//left arrow at previous chat
    while(chatInd >= 0 && (
        chats[chatInd].responseType === CONSTANT.ELEMENT_TYPES.LABEL ||
        chats[chatInd].questionId === 10000012 || //I'd of question => Welcome to the MyMedicalHub Fysical Score Screening. Which region of your body would you like to assess today?
        !chats[chatInd].editable ||
        (isLeftArrowClicked && chats[chatInd].questionId === chats[currentChatInd].questionId)
    )) chatInd--;

    return chatInd;
}


export const onSaveSingleClick = async ({ questionId, answers, intent, botName, chatEnded, responseType = '' }) => {
    const saveAssessment = async () => {
        const payload = new Assessment();
        delete payload.PatientId;
        delete payload.Tenant;
        payload.chatEnded = chatEnded;
        payload.TestId = (GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['TestId']) || '';
        if((responseType === CONSTANT.ELEMENT_TYPES.CHECKBOX) || (responseType === CONSTANT.ELEMENT_TYPES.MULTISELECT)) {
            let answerList = answers.map(answer => {
                return answer?.id || null;
            })

            payload.Answers = [
                {
                    QuestionId: questionId,
                    AnswerList: answerList,
                }
            ]
        } else {
            payload.Answers = [{
                QuestionId: questionId,
                AnswerId: answers && answers.length > 0 ? (answers[0].id || null) : "",
                AnswerValue: answers && answers.length > 0 ? answers[0].name : "",
                AnswerTitle: answers && answers.length > 0 ? answers[0].name : "",
            }];
        }
        const response = await examService.saveAssessment(payload);
        const { success, data, error } = response;

        if (!success) {
            console.error(error);
        }
        return response;
    }

    if ((intent !== CONSTANT.CHATBOT_INTENT.BODY_REGION) &&
        (intent !== CONSTANT.CHATBOT_INTENT.INITIAL) &&
        (intent !== CONSTANT.CHATBOT_INTENT.CLICK_AUTOMATIC) &&
        (botName === CONSTANT.CHAT_BOTS.MSK_BOT || botName === CONSTANT.CHAT_BOTS.INTAKE_BOT || botName === CONSTANT.CHAT_BOTS.POSTURE_BOT)) {
        if(questionId === CONSTANT.CC_QUESTION.ID && answers[0]?.referenceId === CONSTANT.REFERENCE_RESPONSE.NO) {
            const response = await saveNoCC_Complaint();
            if(response) GLOBAL.ISANYCHIEFCOMPLAINTANSWEDED = false;
            return response;
        } else {
            return await saveAssessment();
        }
    }
}

export const saveNoCC_Complaint = async (testId = "") => {
    let payload = {};
    payload.TestId = testId || (GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['TestId']);
    const response = await examService.saveNoCC_Complaint(payload);
    const { success, error } = response;

    if (!success) {
        console.error(error);
    }
    return response;
}


export const redirectTo = (route) => {
    console.log('redirectTo', route);
    window.location.replace(`/#${route}`);
}

export const _focusChatElement = (chat) => {
    setTimeout(() => {
        const inputElement = document.getElementById('input-box-' + chat.id);
        inputElement && inputElement.focus && inputElement.focus();
    }, 0);
}


export const toggleCheckboxChatResponse = (chat, chatResponse) => {
    const deepClonedChat = deepClone(chat);
    if (chatResponse) {
        const { id } = chatResponse;
        const index = deepClonedChat.answers.findIndex(item => item['id'] === id);

        if (index === -1) {
            deepClonedChat.answers.push({ ...chatResponse });
        } else {
            deepClonedChat.answers.splice(index, 1);
        }
    }

    return deepClonedChat;
}

export const removeChildQuestions = async(chats, childQuestions, mode, dispatch) => {
    if(childQuestions.length > 0) {
        const testId = GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['TestId'];
        await ExamService.clearAssessments({ TestId: testId, QuestionIds: childQuestions });

        childQuestions.forEach((childQuestionId) => {
            let childQuestionIndex = chats.findIndex((chat) => chat.questionId === childQuestionId);
            if(childQuestionIndex > -1) {
                chats.splice(childQuestionIndex, 1);
                dispatch(sliceChatAtIndex(childQuestionIndex));
            }
        });

        const parentQuestionId = GLOBAL?.parentAndChildQuestionIds?.parentQuestionId;
        const parentQuestionInd = chats.findIndex(chat => chat.questionId === parentQuestionId);
        if(parentQuestionInd !== -1 && parentQuestionInd + 1 === chats.length) {
            mode = false;
        }

        GLOBAL.parentAndChildQuestionIds = {};
        return { chats: chats, mode: mode };
    }
}

export const setChildQuestionIdsToRemoveAndParentAndChildQuestionIds = (chats, chat, chatResponse) => {
    if (!chat?.responses?.length) return; // For label type questions
    let removeChildQuestionIds = [];

    const allChildQuestionIds = getChildQuestionIds(chat.responses);
    const selectedChildQuestionIds = chatResponse?.conditionalQuestionList || [];

    if (selectedChildQuestionIds.length > 0) {
        GLOBAL.parentAndChildQuestionIds = { parentQuestionId: chat.questionId, childQuestionIds: selectedChildQuestionIds };////TODO: will take from chatContext
        removeChildQuestionIds = getChildQuestionIdsNotInSelectedResponse(allChildQuestionIds, selectedChildQuestionIds);
    } else if (selectedChildQuestionIds.length === 0 && allChildQuestionIds.length > 0) {
        removeChildQuestionIds = childQuestionIdsAlreadyInChats(deepClone(chats), allChildQuestionIds);
    }

    return removeChildQuestionIds;
};