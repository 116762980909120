import React, { Fragment, useEffect, useState } from 'react';
import {
    useTable,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
} from 'react-table';
import { Table } from 'reactstrap';
import { Filter, DefaultColumnFilter } from './filters';

// const MmhDataTable = ({ columns, data, renderRowSubComponent, flagForFilter, filterColumns, setFilterData, textAlign='' }) => {
const MmhDataTable = ({ columns, data, renderRowSubComponent, flagForFilter, filterColumns, textAlign='' }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        visibleColumns,
        // canPreviousPage,
        // canNextPage,
        // pageOptions,
        // pageCount,
        gotoPage,
        // nextPage,
        // previousPage,
        setPageSize,
        // state: { pageIndex, pageSize, filters },
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: { pageIndex: 0, pageSize: 30 },
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
    );

    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(0);

    useEffect(() => {
        setStartIndex(0);
        setEndIndex(5);

    }, [startIndex, endIndex])

    // useEffect(() => {
    //     if(setFilterData) {
    //         setFilterData(filters);
    //     }
    // }, [filters]);

    // const generateSortingIndicator = (column) => {
    //     return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
    // };

    // const onChangeInSelect = (event) => {
    //     setPageSize(Number(event.target.value));
    // };

    // const onChangeInInput = (event) => {
    //     event.preventDefault();
    //     const page = event.target.value ? Number(event.target.value) - 1 : 0;
    //     gotoPage(page);
    // };


    return (
        <div className="dataTables_wrapper dt-bootstrap4">
            <div className="row">
                <div className="col-sm-12">
                    <Table
                        // className="table table-striped- table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed" {...getTableProps()}>
                        className="report-assessment-table table-checkable no-footer dtr-inline collapsed color-primary" {...getTableProps()}>
                        <thead className="kt-datatable__head">
                        {headerGroups.map((headerGroup) => (
                            <tr className="kt-datatable__row report-assessment-table-header"
                                style={{ left: "0px" }} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th className={`kt-datatable__cell kt-datatable__cell--sort report-assessment-table-header__cell bg-color-ice-blue kt-font-bolder font-size-md ${column.Header === 'Room' ? 'joinBtnMinWidth' : ''}`} {...column.getHeaderProps()}>
                                        <div {...column.getSortByToggleProps()}>
                                            <div className="d-flex justify-content-center ">
                                                {column.render('Header')}
                                            </div>
                                            {/*{generateSortingIndicator(column)}*/}
                                        </div>
                                        {!flagForFilter && (
                                            <Filter column={column}/>
                                        )}
                                        {flagForFilter && filterColumns?.length > 0 && (
                                            filterColumns.map((item, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        {column.id === item && (
                                                            <Filter column={column}/>
                                                        )}
                                                    </Fragment>
                                                )
                                            })
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody className="kt-datatable__body" {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <Fragment key={row.getRowProps().key}>
                                    <tr className="kt-datatable__row kt-font-bold" style={{ left: "0px" }}>
                                        {row.cells.map((cell, index) => {
                                            return (
                                                <td className="kt-datatable__cell table-cell" {...cell.getCellProps()}>
                                                    <div className={cell.column.style ? cell.column.style : 'w-100'}>
                                                        <div className={`d-flex align-items-center ${(textAlign === '' && index===0)  ? "justify-content-left" : 'justify-content-center'}`}>
                                                            {cell.render('Cell')}
                                                        </div>
                                                    </div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                    {row.isExpanded && (
                                        <tr className="kt-datatable__row" style={{ left: "0px" }}>
                                            <td className="kt-datatable__cell"
                                                colSpan={visibleColumns.length}>
                                                    <span style={{ width: "118px" }}>
                                                        {renderRowSubComponent(row)}
                                                    </span>
                                            </td>
                                        </tr>
                                    )}
                                </Fragment>
                            );
                        })}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default MmhDataTable;
